import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import {
  changeGameData,
  saveEditingActivity,
  removeContentFiles
} from "actions/flinkMakeActions";
import {
  SelectModal,
  InstructionsBlock,
  MultiLocaleControls
} from "components/flink-components";
import {
  StringAssignField,
} from "components/form-components";

import { withGetStringAudio } from "components/hocs";

import classes from "./MultiLocaleInstructions.module.scss";

export class MultiLocaleInstructions extends Component {
  state = {
    selectLocaleModalOptions: null
  };

  componentDidMount() {
    this.setDefaultLocale();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.problemPath !== this.props.problemPath) {
      this.setDefaultLocale();
    }
  }

  setDefaultLocale = () => {
    const problem = this.getProblem();

    const { multiLocaleInstructions } = problem;

    this.setState({
      selectedLocale: multiLocaleInstructions
        ? multiLocaleInstructions.defaultLocale
        : "",
      selectLocaleModalOptions: null
    });
  };

  getProblem = () => {
    const { gameData, problemPath } = this.props;
    return (problemPath ? _.get(gameData, problemPath) : gameData) || {};
  };

  makeMultiHandler = locale => {
    const { changeGameData, textPath, audioPath, stringPath } = this.props;
    const problem = this.getProblem();

    this.setState({
      selectLocaleModalOptions: null,
      selectedLocale: locale
    });

    this.changeMultiLocaleInstructions({
      [locale]: {
        [textPath]: problem[textPath],
        [audioPath]: problem[audioPath],
        [stringPath]: problem[stringPath],
      },
      defaultLocale: locale
    });

    const pathPrefix = this.getPathPrefix();

    changeGameData(pathPrefix + textPath, "");
    changeGameData(pathPrefix + audioPath, "");
    changeGameData(pathPrefix + stringPath, "");
  };

  makeSingleHandler = locale => {
    const {
      changeGameData,
      removeContentFiles,
      audioPath,
      textPath,
      stringPath,
    } = this.props;

    const problem = this.getProblem();
    const { multiLocaleInstructions } = problem;

    this.setState({
      selectLocaleModalOptions: null
    });

    const audioFilesToDelete = _.chain(multiLocaleInstructions)
      .map((val, key) => {
        if (key === "defaultLocale" || key === locale) return null;
        return val && val[audioPath];
      })
      .compact()
      .value();

    // Add audio files to remove list
    removeContentFiles(audioFilesToDelete);

    const textValue =
      multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][textPath];
    const audioValue =
      multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][audioPath];
    const stringValue =
      multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][stringPath];

    const pathPrefix = this.getPathPrefix();

    changeGameData(pathPrefix + textPath, textValue || "");
    changeGameData(pathPrefix + audioPath, audioValue || "");
    changeGameData(pathPrefix + stringPath, stringValue || "");

    this.changeMultiLocaleInstructions(null);
  };

  closeSelectLocaleModal = () => {
    this.setState({
      selectLocaleModalOptions: null
    });
  };

  changeInstructions = (path, data) => {
    const { selectedLocale } = this.state;
    const { changeGameData } = this.props;

    const problem = this.getProblem();

    const { multiLocaleInstructions } = problem;

    if (multiLocaleInstructions) {
      if (!selectedLocale) {
        return alert("No selected locale");
      }

      // const dataToSet = {
      //   ...multiLocaleInstructions,
      //   [selectedLocale]: {
      //     ...multiLocaleInstructions[selectedLocale],
      //     [path]: data
      //   }
      // };
      // this.changeMultiLocaleInstructions(dataToSet);

      changeGameData(this.getPathPrefix() + `multiLocaleInstructions.${selectedLocale}.${path}`, data);

    } else {
      changeGameData(this.getPathPrefix() + path, data);
    }
  };

  getPathPrefix = () => {
    const { problemPath } = this.props;
    const pathPrefix = problemPath ? problemPath + "." : "";
    return pathPrefix;
  };

  changeLocaleHandler = locale => {
    this.setState({ selectedLocale: locale });
  };

  changeMultiLocaleInstructions = data => {
    const { changeGameData } = this.props;
    changeGameData(this.getPathPrefix() + "multiLocaleInstructions", data);
  };

  setCurrentLocaleAsDefault = () => {
    const { selectedLocale } = this.state;

    const problem = this.getProblem();

    this.changeMultiLocaleInstructions({
      ...problem.multiLocaleInstructions,
      defaultLocale: selectedLocale
    });
  };

  render() {
    const {
      translate,
      getStringAudio,
      textPath,
      audioPath,
      stringPath,
      withoutAudio,
      maxCharacters,
      withStringsPicker,
    } = this.props;

    const { selectLocaleModalOptions, selectedLocale } = this.state;

    const problem = this.getProblem();

    const { multiLocaleInstructions } = problem;

    const currentLocaleInstructions =
      multiLocaleInstructions &&
      (multiLocaleInstructions[selectedLocale] || {});

    // current path value (if using strings, for audio)
    let currentPathValue;

    let currentTextValue = problem[textPath];
    let currentAudioValue = problem[audioPath];
    let currentStringValue = problem[stringPath];

    if (currentLocaleInstructions) {
      currentTextValue = currentLocaleInstructions[textPath];
      currentAudioValue = currentLocaleInstructions[audioPath];
      currentStringValue = currentLocaleInstructions[stringPath];
    }

    if (currentStringValue) {
      const stringAudio = getStringAudio(currentStringValue);
      currentTextValue = translate(currentStringValue, '', true);
        
      currentAudioValue = stringAudio.audio;
      currentPathValue = stringAudio.path;
    }

    return (
      <div className={classes.wrapper}>
        <label className={classes.label}>
          {translate(432, "Instructions")}
        </label>

        {withStringsPicker && !multiLocaleInstructions && (
          <StringAssignField
            value={currentStringValue}
            changeAudioHandler={filename => this.changeInstructions(audioPath, filename)}
            changeTextHandler={string => {
              this.changeInstructions(textPath, `${string || ""}`);
              this.changeInstructions(stringPath, string);
            }}
            withoutAudio={withoutAudio}
            localeOverride={selectedLocale}
            isTooltip
          />
        )}

        <MultiLocaleControls
          selectedLocale={selectedLocale}
          multiLocaleData={multiLocaleInstructions}
          stringData={currentStringValue}
          changeLocaleHandler={this.changeLocaleHandler}
          makeDefaultHandler={this.setCurrentLocaleAsDefault}
          makeSingleHandler={this.makeSingleHandler}
          makeMultiHandler={this.makeMultiHandler}
          makeSingleDialogText={translate(
            0,
            "Which instructions to use as single? Warning all other instructions will be deleted including audio files"
          )}
          makeMultiDialogText={translate(
            0,
            "Select language of current instructions"
          )}
          classes={{ wrapper: classes.multiLocaleControls }}
        />

        <InstructionsBlock
          maxCharacters={maxCharacters}
          withoutAudio={withoutAudio}
          textValue={currentTextValue}
          stringValue={currentStringValue}
          onTextChange={val => this.changeInstructions(textPath, val)}
          audioValue={currentAudioValue}
          pathValue={currentPathValue}
          onAudioChange={filename =>
            this.changeInstructions(audioPath, filename)
          }
          disabled={withStringsPicker && !!currentStringValue}
        // label={translate(432, "Instructions")}
        />

        {selectLocaleModalOptions && (
          <SelectModal
            show
            title={translate(251, "Select a locale")}
            options={selectLocaleModalOptions.options}
            selectHandler={selectLocaleModalOptions.cb}
            html={selectLocaleModalOptions.html}
            onClose={this.closeSelectLocaleModal}
          />
        )}
      </div>
    );
  }
}

MultiLocaleInstructions.defaultProps = {
  textPath: "instructionText",
  audioPath: "instructionAudio",
  stringPath: "instructionString",
  withoutAudio: false,
  withStringsPicker: false,
};

MultiLocaleInstructions.propTypes = {
  withoutAudio: PropTypes.bool,
  withStringsPicker: PropTypes.bool,
  gameData: PropTypes.object.isRequired,
  textPath: PropTypes.string,
  audioPath: PropTypes.string,
  problemPath: PropTypes.string,
  saveEditingActivity: PropTypes.func.isRequired,
  removeContentFiles: PropTypes.func.isRequired,
  changeGameData: PropTypes.func.isRequired,
  getStringAudio: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = ({ status, common, flinkMake: { activity } }) => ({
  gameData: activity.editingActivity.data.gameData,
  translate: status.translate
});

export default compose(
  withGetStringAudio,
  connect(mapStateToProps, {
    changeGameData,
    saveEditingActivity,
    removeContentFiles
  })
)(MultiLocaleInstructions);
