import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './GuideBox.module.scss';

class GuideBox extends Component {
  contentRef = React.createRef();

  showPrevGuide = () => {
    const { onChangeActive, activeGuideIdx } = this.props;
    const isFirstGuide = activeGuideIdx === 0;

    if (!isFirstGuide) {
      onChangeActive(activeGuideIdx - 1);
    }
  };

  showNextGuide = () => {
    const { onChangeActive, activeGuideIdx, guides } = this.props;

    const isLastPage = guides.length === activeGuideIdx + 1;

    if (!isLastPage) {
      onChangeActive(activeGuideIdx + 1);
    }
  };

  render() {
    const {
      guides,
      activeGuideIdx,
      onPasteStarter,
      templateGraphicsFolder
    } = this.props;

    const prevBtnImg = templateGraphicsFolder + 'arrow_left.png';
    const nextBtnImg = templateGraphicsFolder + 'arrow_right.png';
    const pasteBtnImg = templateGraphicsFolder + 'Insert.png';

    const isFirstGuide = activeGuideIdx === 0;
    const isLastPage = guides.length === activeGuideIdx + 1;

    const currentGuide = guides[activeGuideIdx];

    const guideHeight =
      this.contentRef &&
      this.contentRef.current &&
      this.contentRef.current.offsetHeight;

    return (
      <div className={classes.wrapper}>
        <div className={classes.actions}>
          <button
            onClick={this.showPrevGuide}
            className={classnames({
              [classes.hidden]: isFirstGuide || !guides.length
            })}
          >
            <img src={prevBtnImg} alt="Prev" />
          </button>

          <button
            onClick={this.showNextGuide}
            className={classnames({
              [classes.hidden]: isLastPage || !guides.length
            })}
          >
            <img src={nextBtnImg} alt="Next" />
          </button>

          <button
            onClick={() => onPasteStarter(currentGuide && currentGuide.starter)}
            className={classnames({
              [classes.hidden]:
                !guides.length || !currentGuide || !currentGuide.starter
            })}
          >
            <img src={pasteBtnImg} alt="Starter" />
          </button>
        </div>

        <div className={classes.content} ref={this.contentRef}>
          {guideHeight && (
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: currentGuide && currentGuide.text
              }}
            ></div>
          )}
        </div>
        <div className={classes.bottom}>
          {activeGuideIdx + 1} of {guides.length}
        </div>
      </div>
    );
  }
}

GuideBox.propTypes = {
  guides: PropTypes.array.isRequired,
  activeGuideIdx: PropTypes.number.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  onPasteStarter: PropTypes.func.isRequired
};

export default GuideBox;
