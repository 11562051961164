import MatchIt from './MatchIt';
import convertOldActivity from './convertOldMatchIt';
import validateActivity from './validateMatchIt';

export default {
  Template: MatchIt,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'randomOrder',
    'questionsLimit',
    'showAnswer'
  ]
};
