/* eslint eqeqeq: 0 */

import shortId from 'shortid';
import { convert } from 'activity-templates/utils';
import { IMAGE_IN_RIGHT, IMAGE_IN_TOP, BIG_TEXT } from './layoutTypes';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {
    ProblemOrder: 1,
    ShowAnswer: 2,
    LengthOfTime: 3,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const {
    PicknClick: { SegmentData },
  } = gameData;

  const problems = SegmentData.map((data) => {
    const { FontName, FontSize, GraphicFnm, Layout, PartData } = data;

    let html = '<p>';
    const incorrectAnswers = [];

    PartData.forEach((part) => {
      console.log(part);
      const { Type, Text } = part;

      if (Type == '-1') {
        // Usual text
        html += Text;
      } else if (Type == '0') {
        // Correct answer
        html += `<u data-word-id="${shortId.generate()}">${Text}</u>`;
      } else if (Type == '-1') {
        // Incorrect Answer
        incorrectAnswers.push({ text: Text, id: shortId.generate() });
      } else if (Type == '5') {
        // New line
        html += '</p><p>';
      }
    });

    // Close P tag
    html += '</p>';

    let layoutType;

    Layout == '0' && (layoutType = IMAGE_IN_RIGHT);
    Layout == '1' && (layoutType = IMAGE_IN_RIGHT);
    Layout == '2' && (layoutType = BIG_TEXT);
    Layout == '3' && (layoutType = IMAGE_IN_TOP);

    const problem = {
      id: shortId.generate(),
      fontFamily: FontName || 'Roboto',
      fontSize: FontSize || '18',
      layoutType: layoutType || (GraphicFnm ? IMAGE_IN_RIGHT : BIG_TEXT),
      image: GraphicFnm || '',
      html,
      incorrectAnswers,
    };

    return problem;
  });

  result.gameData = { problems };
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
