import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default ({ input, label, disabled = false }) => (
  <FormControlLabel
    control={
      <Checkbox
        disabled={disabled}
        checked={input.value ? true : false}
        onChange={input.onChange}
        color="primary"
      />
    }
    label={label}
  />
);
