import React from 'react';
import styled, { css } from 'styled-components';
import FieldError from './FieldError';

export default ({
  input,
  label,
  meta: { touched, error, dirty } = {},
  width = '100%',
  ...custom
}) => (
  <StyledInputGroup width={width}>
    <label>
      <StyledLabel error={error} touched={touched} dirty={dirty}>
        {label}
      </StyledLabel>
      <StyledInput
        error={error}
        touched={touched}
        dirty={dirty}
        {...input}
        {...custom}
      />
    </label>
    {touched && error && <FieldError>{error}</FieldError>}
  </StyledInputGroup>
);

const StyledInputGroup = styled.div`
  margin-bottom: 1.5rem;
  ${({ width }) =>
    width &&
    css`
      width: ${width}${typeof width === 'number' ? 'px' : ''}!important;
    `};
`;

const StyledLabel = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 0;

  ${({ touched, error, dirty }) =>
    touched &&
    (error
      ? css`
          color: #df2a26;
        `
      : dirty &&
        css`
          color: green;
        `)};
`;

const StyledInput = styled.input`
  width: 100%;
  height: 3.8rem;
  padding: 0.6rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }

  ${({ touched, error, dirty }) =>
    touched &&
    (error
      ? css`
          border-color: #df2a26;
          outline-color: #df2a26;
        `
      : dirty &&
        css`
          border-color: green;
          outline-color: green;
        `)};
`;
