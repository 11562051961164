import React, { lazy, Suspense, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { debounce, find } from 'lodash';

import { MuiThemeProvider } from '@material-ui/core/styles';
import Home from 'pages/Home/Home';
import Register from 'pages/Register/Register';
import FinishPaidRegistration from 'pages/Register/FinishPaidRegistration';
import Login from 'pages/Login/Login';

import LoginClever from 'pages/LoginClever/LoginClever';
import GuestActivityPreview from 'pages/GuestActivityPreview';

import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';

import { Loader, Dialogs, Spinner } from 'components/common';
import RefreshScreen from 'components/common/RefreshScreen';
import { renderRoutes, setAuthToken, muiTheme } from 'utils';
import { updateToken } from 'actions/authActions';
import { getCommonData } from 'actions/commonActions';
import { changeLocale, setAspectRatio } from 'actions/statusActions';
import store from 'store';
import { allRoles } from 'consts/user-roles';

import './utils/audioPlayer';
import './settings';
import 'normalize.css';
import 'animate.css';
import 'react-datepicker/dist/react-datepicker.css';

const Admin = lazy(() => import('pages/Admin/Admin'));
const FlinkAdmin = lazy(() => import('pages/FlinkAdmin/FlinkAdmin'));
const FlinkPlay = lazy(() => import('pages/FlinkPlay/FlinkPlay'));
const FlinkMake = lazy(() => import('pages/FlinkMake/FlinkMake'));

const routes = [
  ['/', true, Home],
  ['/register-by-code/:code', true, Register],
  ['/finish-registration/:pendingRegistration', true, FinishPaidRegistration],
  ['/login', true, Login],
  ['/login/clever', true, LoginClever],
  ['/admin', false, Admin, allRoles],
  ['/flink-admin', false, FlinkAdmin, { apps: { flinkAdmin: true } }],
  ['/flink-make', false, FlinkMake, { apps: { flinkMake: true } }],
  ['/play', true, FlinkPlay, 'learner'],

  ['/:learningCenterUrl', true, Login],

  // preview activity as guest
  ['/preview-activity/:activityId', true, GuestActivityPreview],

  // 404 Page
  [null, false, NotFoundPage],
];

class App extends Component {
  constructor(props) {
    super(props);

    // Check for token
    if (localStorage.jwtToken) {
      // Set token from local storage
      try {
        setAuthToken(localStorage.jwtToken);
        // Update token from server
        updateToken(localStorage.jwtToken)(store.dispatch);
      } catch (err) {
        localStorage.removeItem('jwtToken');
      }
    }

    this.state = { redirectTo: '/' };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { learner, auth } = this.props;

    if (prevProps.learner !== learner || prevProps.auth.user !== auth.user) {
      /*
      If the learner (flink play) or the user (flink admin) has changed, we should update redirect which will be fired
      if the user doesn't have enough permissions to view some page (see CustomRoute.js)

      The renderRoute function takes care of rendering the CustomRoute component and passing
      that log-out redirect URI to it
      */

      // this.setLanguage();
      this.setRedirectTo();
    }
  }

  init = async () => {
    const { getCommonData } = this.props;

    if (this.state.error) {
      this.setState({ error: '' });
    }

    const success = await getCommonData();

    if (!success) {
      return this.setState({
        error: 'Error connecting to server.',
      });
    }

    this.checkAspectRatio();
    window.addEventListener('resize', debounce(this.checkAspectRatio, 100));

    this.setLanguage();
    this.setRedirectTo();
  };

  setRedirectTo = () => {
    /*
    Look at line 80 comment!
    */

    const { learner, auth, common } = this.props;

    if (!common) return;

    const { learningCenters } = common;

    const isCleverLogin = auth.cleverLogin;
    const learningCenterId =
      (learner && learner.learningCenterId) ||
      (auth.user && auth.user.learningCenterId);

    let redirectTo = '/';

    if (isCleverLogin) {
      /* 
      If we're logged in with clever, our log-out redirect must be clever website
      */

      redirectTo = new URL('https://clever.com'); // look at comment in the CustomRoute
    } else if (learningCenterId) {
      const learningCenter = find(learningCenters, { _id: learningCenterId });

      if (learningCenter && learner) {
        const familyParams = new URLSearchParams({ family: learner.familyId });
        redirectTo = `${learningCenter.url}?${familyParams.toString()}`;
        // redirectTo = learningCenter.url;
      }
    }

    console.log(`Setting logout redirect to ${redirectTo.toString()}`);
    this.setState({ redirectTo });
  };

  checkAspectRatio = () => {
    const { setAspectRatio } = this.props;
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;

    setAspectRatio(aspectRatio);
  };

  setLanguage = () => {
    const { changeLocale } = this.props;

    // Set language from Local Storage if exists
    const localeCode = localStorage.getItem('localeCode') || 'en';
    changeLocale(localeCode);
  };

  render() {
    const { auth, common } = this.props;
    const { error, redirectTo } = this.state;

    if (error) {
      return (
        <RefreshScreen
          refreshHandler={this.init}
          text="Error connecting to server."
        />
      );
    }

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Loader />

        {common && <Dialogs />}

        <Suspense fallback={<Spinner />}>
          {common && <Switch>{renderRoutes(routes, auth, redirectTo)}</Switch>}
        </Suspense>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  getCommonData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  learner: PropTypes.object,
};

const mapStateToProps = ({ auth, common, flinkPlay }) => ({
  auth,
  learner: flinkPlay.learner,
  common,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getCommonData,
    changeLocale,
    setAspectRatio,
  })
)(App);
