import _ from "lodash";
import shortId from "shortid";

import { getInstructionsAudioFilesFromProblem, stringProblemsMapper } from "activity-templates/utils";
import { isUsingStrings } from "utils";

export const validateItem = item =>
  !!(item && (item.text || item.image || item.audio));

export const validateProblem = problem => {
  const { questions, correctAnswers } = problem;
  if (
    !(questions && questions.length && correctAnswers && correctAnswers.length)
  ) {
    return false;
  }

  const validQuestions = questions.filter(validateItem);
  const validAnswers = correctAnswers.filter(validateItem);

  if (
    !validQuestions.length ||
    !validAnswers.length ||
    validQuestions.length < 2 ||
    validQuestions.length !== validAnswers.length
  ) {
    return false;
  }

  for (let i = 0; i < questions.length; i++) {
    const isValidQuestion = validateItem(questions[i]);
    const isValidAnswer = validateItem(correctAnswers[i]);

    if (isValidQuestion && !isValidAnswer) return false;
  }

  return true;
};

export const createProblem = () => ({
  id: shortId.generate(),
  questions: [],
  correctAnswers: [],
  incorrectAnswers: [],
  fontColor: "#000",
  fontFamily: "Arial",
  questionItemsShown: 4
});

export const getContentFilesFromItem = item => {
  const files = [];
  if (!item) return files;

  const { text, image, audio } = item;
  !isUsingStrings(text) && image && files.push(image);
  !isUsingStrings(text) && audio && files.push(audio);

  return files;
};

export const getContentFilesFromProblem = problem => {
  let files = [];
  const { questions, correctAnswers, incorrectAnswers } = problem;

  const instructionsAudioFiles = getInstructionsAudioFilesFromProblem(problem);
  files.push(...instructionsAudioFiles);

  _.concat(questions, correctAnswers, incorrectAnswers).forEach(item => {
    files = _.concat(files, getContentFilesFromItem(item));
  });
  console.log(files);
  return files;
};

export const prepareProblem = (problem, maxItems, { translate, getStringAudio }) => {
  if (!problem) return null;

  const {
    questions,
    correctAnswers,
    incorrectAnswers,
    questionItemsShown
  } = problem;

  const mapper = stringProblemsMapper({
    translate,
    getStringAudio,
  });

  const validQuestions = questions.filter(validateItem).map(mapper);
  const validCorrectAnswers = correctAnswers.filter(validateItem).map(mapper);
  const validIncorrectAnswers = incorrectAnswers.filter(validateItem).map(mapper);

  let preparedCorrectAnswers = [];

  let preparedQuestionItems = validQuestions.map((q, idx) => {
    const id = shortId.generate();

    preparedCorrectAnswers.push({
      id: shortId.generate(),
      data: validCorrectAnswers[idx],
      correct: true,
      relatedToId: id
    });

    return {
      id,
      data: q
    };
  });

  preparedQuestionItems = _.shuffle(preparedQuestionItems);

  if (questionItemsShown < preparedQuestionItems.length) {
    preparedQuestionItems = preparedQuestionItems.slice(0, questionItemsShown);
  }

  if (preparedQuestionItems.length + validIncorrectAnswers.length > maxItems) {
    preparedQuestionItems = preparedQuestionItems.slice(
      0,
      8 - validIncorrectAnswers.length
    );
  }

  preparedCorrectAnswers = preparedCorrectAnswers.filter(ans => {
    const question = _.find(preparedQuestionItems, { id: ans.relatedToId });
    return !!question;
  });

  const preparedIncorrectAnswers = validIncorrectAnswers.map(ans => ({
    id: shortId.generate(),
    data: ans,
    correct: false
  }));

  let items = [...preparedCorrectAnswers, ...preparedIncorrectAnswers];

  const startPositions = _.chain(items.length)
    .times()
    .shuffle()
    .value();

  return {
    questions: preparedQuestionItems,
    items: _.chain(items)
      .map((item, idx) => ({
        ...item,
        boxIdx: startPositions[idx],
        inSlot: false
      }))
      .shuffle()
      .value()
  };
};
