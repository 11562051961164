// Auth Types
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Common Types
export const SET_COMMON_DATA = 'SET_COMMON_DATA';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';

// Flink Admin Types
export const CLEAR_FLINK_ADMIN_DATA = 'CLEAR_FLINK_ADMIN_DATA';
export const SET_TEACHER_LEARNING_CENTER = 'SET_TEACHER_LEARNING_CENTER';
export const SET_TEACHER_REPORT_GROUPS = 'SET_TEACHER_REPORT_GROUPS';
export const CREATE_REPORT_GROUP = 'CREATE_REPORT_GROUP';
export const EDIT_REPORT_GROUP = 'EDIT_REPORT_GROUP';
export const DELETE_REPORT_GROUP = 'DELETE_REPORT_GROUP';
export const CHANGE_SELECTED_REPORT_GROUP = 'CHANGE_SELECTED_REPORT_GROUP';
export const CHANGE_SELECTED_FAMILY = 'CHANGE_SELECTED_FAMILY';

// Admin Types
export const CLEAR_ADMIN_DATA = 'CLEAR_ADMIN_DATA';

export const ADD_DATA = 'ADD_DATA';
export const SET_DATA = 'SET_DATA';
export const EDIT_DATA = 'EDIT_DATA';
export const DELETE_DATA = 'DELETE_DATA';

export const IMPORT_TEACHERS = 'IMPORT_TEACHERS';
export const IMPORT_WORDS = 'IMPORT_WORDS';
export const IMPORT_GIFT_CARDS = 'IMPORT_GIFT_CARDS';

export const SET_TABLE_STATE = 'SET_TABLE_STATE';
export const ADD_REGION = 'ADD_REGION';
export const ADD_LEARNING_CENTER = 'ADD_LEARNING_CENTER';
export const ADD_MASTER_ORGANIZATION = 'ADD_MASTER_ORGANIZATION';

// Status Types
export const LOADING = 'LOADING';
export const UPLOADING = 'UPLOADING';
export const SET_ASPECT_RATIO = 'SET_ASPECT_RATIO';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const START_REQUEST = 'START_REQUEST';
export const END_REQUEST = 'END_REQUEST';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_INFO = 'SHOW_INFO';
export const SHOW_PROMPT = 'SHOW_PROMPT';
export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const TOGGLE_HELP = 'TOGGLE_HELP';
export const SET_HELP_COORDS = 'SET_HELP_COORDS';
export const CHANGE_CURRENT_PAGE_TITLE = 'CHANGE_CURRENT_PAGE_TITLE';

// FlinkMake Types
// -- Edit List
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const SET_ACTIVE_ACTIVITY = 'SET_ACTIVE_ACTIVITY';
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
export const ADD_ACTIVITY_TO_EDIT_LIST = 'ADD_ACTIVITY_TO_EDIT_LIST';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const UNDO_DELETE_ACTIVITY = 'UNDO_DELETE_ACTIVITY';
export const CLEAR_ACTIVITIES_DELETE_LIST = 'CLEAR_ACTIVITIES_DELETE_LIST';
export const SET_MY_EDIT_ACTIVITIES_LIST = 'SET_MY_EDIT_ACTIVITIES_LIST';
export const REMOVE_FROM_EDIT_LIST = 'REMOVE_FROM_EDIT_LIST';
export const SET_ACTIVITIES_UNSAVED_CHANGES = 'SET_ACTIVITIES_UNSAVED_CHANGES';

// -- Activity
export const SET_DELETED_PROBLEMS = 'SET_DELETED_PROBLEMS';
export const SET_EDITING_ACTIVITY = 'SET_EDITING_ACTIVITY';
export const EDITING_ACTIVITY_SAVED = 'EDITING_ACTIVITY_SAVED';
export const CHANGE_ACTIVITY_DATA = 'CHANGE_ACTIVITY_DATA';
export const ADD_CONTENT_FILES_TO_DELETE = 'ADD_CONTENT_FILES_TO_DELETE';
export const RESTORE_CONTENT_FILES = 'RESTORE_CONTENT_FILES';

// -- Clipart
export const SET_CLIPART_IMAGES = 'SET_CLIPART_IMAGES';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';

// -- Wordlists
export const SET_WORDLISTS = 'SET_WORDLISTS';
export const EDIT_WORDLIST = 'EDIT_WORDLIST';
export const DELETE_WORDLIST = 'DELETE_WORDLIST';
export const UNDO_DELETE_WORDLIST = 'UNDO_DELETE_WORDLIST';
export const CLEAR_WORDLISTS_DELETE_LIST = 'CLEAR_WORDLISTS_DELETE_LIST';
export const REMOVE_FROM_MY_WORDLISTS = 'REMOVE_FROM_MY_WORDLISTS';
export const ADD_WORDLIST = 'ADD_WORDLIST';

// -- Flink Make Common Data
export const SET_FLINK_MAKE_COMMON_DATA = 'SET_FLINK_MAKE_COMMON_DATA';

// -- Flink make polly tts
export const SET_TTS_VOICE = 'SET_TTS_VOICE';
export const SET_TTS_LOCALE = 'SET_TTS_LOCALE';

// Game Types
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY';
export const OPEN_ACTIVITY_HELP = 'OPEN_ACTIVITY_HELP';
export const CLOSE_ACTIVITY_HELP = 'CLOSE_ACTIVITY_HELP';
export const OPEN_TEAM_ACTIVITY_HELP = 'OPEN_TEAM_ACTIVITY_HELP';
export const CLOSE_TEAM_ACTIVITY_HELP = 'CLOSE_TEAM_ACTIVITY_HELP';
export const RESTART_ACTIVITY = 'RESTART_ACTIVITY';
export const GAME_INITIALIZED = 'GAME_INITIALIZED';
export const SHOW_STATS = 'SHOW_STATS';
export const SHOW_FEEDBACK = 'SHOW_FEEDBACK';
export const CLOSE_FEEDBACK = 'CLOSE_FEEDBACK';
export const SHOW_GAME_PARENT_ADVICE = 'SHOW_GAME_PARENT_ADVICE';
export const SHOW_GAME_MULTIPLAYER_ADVICE = 'SHOW_GAME_MULTIPLAYER_ADVICE';
export const ADD_IDLE_TIME = 'ADD_IDLE_TIME';

// Flink Play Types
export const SET_FLINK_PLAY_DATA = 'SET_FLINK_PLAY_DATA';
export const SHOW_HELP = 'SHOW_HELP';
export const SHOW_SETTINGS = 'SHOW_SETTINGS';
export const SHOW_INITIAL_SETTINGS = 'SHOW_INITIAL_SETTINGS';
export const CLOSE_SETTINGS = 'CLOSE_SETTINGS';
export const FAMILY_UPDATE = 'FAMILY_UPDATE';
export const SET_MENU_BUTTONS = 'SET_MENU_BUTTONS';
export const SET_PREV_MENU_BUTTONS = 'SET_PREV_MENU_BUTTONS';
export const SET_SOLUTION = 'SET_SOLUTION';
export const RESET_FLINK_PLAY = 'RESET_FLINK_PLAY';
export const SHOW_VIDEO = 'SHOW_VIDEO';
export const SET_CURRENT_MENU = 'SET_CURRENT_MENU';
export const SET_ACTIVITY_GROUP = 'SET_ACTIVITY_GROUP';
export const SET_ACTIVITY_TO_PLAY = 'SET_ACTIVITY_TO_PLAY';
export const UPDATE_LEARNER = 'UPDATE_LEARNER';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA';
export const GO_BACK_ACTION = 'GO_BACK_ACTION';
export const SAVE_MAIN_MENU_SCROLL_POSITION = 'SAVE_MAIN_MENU_SCROLL_POSITION';
export const SET_ACTIVITY_PROGRESS = 'SET_ACTIVITY_PROGRESS';
export const CLOSE_ACTIVITY = 'CLOSE_ACTIVITY';
export const SHOW_FAMILY_REPORTS = 'SHOW_FAMILY_REPORTS';
export const SHOW_PARENT_ADVICE = 'SHOW_PARENT_ADVICE';
export const SHOW_QUICKSTART_VIDEOS = 'SHOW_QUICKSTART_VIDEOS';
export const SET_PARENT_ADVICE_URL = 'SET_PARENT_ADVICE_URL';
export const SET_FEEDBACK_COORDS = 'SET_FEEDBACK_COORDS';
export const SET_PARENT_ADVICE_COORDS = 'SET_PARENT_ADVICE_COORDS';
export const SET_FAMILY_POINTS = 'SET_FAMILY_POINTS';
export const SET_PREVIOUS_WEEK_TIME = 'SET_PREVIOUS_WEEK_TIME';

// Audio Types
export const SET_PLAYING = 'SET_PLAYING';
export const SET_CORRECT_SOUNDS_PLAYED = 'SET_CORRECT_SOUNDS_PLAYED';
export const SET_INCORRECT_SOUNDS_PLAYED = 'SET_INCORRECT_SOUNDS_PLAYED';

// Families
export const CLEAR_FAMILIES = 'CLEAR_FAMILIES';
export const SET_FAMILIES_AND_MEMBERS = 'SET_FAMILIES_AND_MEMBERS';
export const ADD_FAMILY = 'ADD_FAMILY';
export const EDIT_FAMILY = 'EDIT_FAMILY';
export const DELETE_FAMILY = 'DELETE_FAMILY';
export const DELETE_FAMILY_MEMBER = 'DELETE_FAMILY_MEMBER';

// Multiplayer (flink play)
export const CHANGE_PLAYER_TURN = 'CHANGE_PLAYER_TURN';
export const INCREMENT_PLAYER_POINTS = 'INCREMENT_PLAYER_POINTS';
export const SET_PLAYER_POWER_LEVEL = 'SET_PLAYER_POWER_LEVEL';

// Writing feedback (flink play)
export const OPEN_WRITING_FEEDBACK = 'OPEN_WRITING_FEEDBACK';
export const CLOSE_WRITING_FEEDBACK = 'CLOSE_WRITING_FEEDBACK';

// Family points history (flink play)
export const SHOW_FAMILY_POINTS_HISTORY = 'SHOW_FAMILY_POINTS_HISTORY';
export const CLOSE_FAMILY_POINTS_HISTORY = 'CLOSE_FAMILY_POINTS_HISTORY';
