import React from 'react';
import CustomRoute from './CustomRoute';

// Routes must be array of arrays
// ['/path', isExact(Bool), Component, [User, Roles]]

export default (routes, auth, redirectTo) =>
  routes.map((route, index) => (
    <CustomRoute
      redirectTo={redirectTo}
      key={index}
      path={route[0]}
      exact={route[1]}
      component={route[2]}
      permissions={route[3]}
      auth={auth}
    />
  ));
