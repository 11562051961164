import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import renderCheckbox from './renderCheckbox';
import renderSelectField from './renderSelectField';
import { getSorting } from 'utils';

class CheckboxFieldsGroup extends Component {
  state = {
    selected: [],
    default: null,
  };

  componentDidMount() {
    if (!this.props.initialValues) {
      return;
    }

    const {
      defaultSelect,
      initialValues,
      name,
      fields,
      change,
      form,
    } = this.props;

    // Get values from initialValues
    const values = _.get(initialValues, name);

    // Get default value from initialValues
    let defaultValue = defaultSelect
      ? _.get(initialValues, defaultSelect.name)
      : null;

    // find selected checkboxes
    const selected = values && fields.filter((field) => values[field.value]);

    // if default values exists but selected not includes it,
    // then clear that field from redux
    if (
      defaultValue &&
      (!selected || !_.some(selected, { value: defaultValue }))
    ) {
      change(form, defaultSelect.name, null);
      defaultValue = null;
    }

    this.setState({
      selected: selected || [],
      default: defaultValue,
    });
  }

  componentDidUpdate() {
    const {
      change,
      form,
      name,
      hidden,
      defaultSelect,
      initialValues,
    } = this.props;

    const { selected } = this.state;

    if (!selected.length) {
      const value = _.get(initialValues, name);

      if (value !== null && value !== undefined) {
        change(form, name, null);
      }

      if (defaultSelect) {
        const defaultSelectValue = _.get(initialValues, defaultSelect.name);

        if (defaultSelectValue !== null || defaultSelectValue !== undefined) {
          change(form, defaultSelect.name, null);
        }
      }
    }

    hidden && change(form, `${name}[${hidden}]`, !!this.state.selected.length);
  }

  handleCheckboxClick = (e, field) => {
    if (e.target.checked) {
      this.setState({
        selected: [...this.state.selected, field],
      });

      return;
    }

    // If there is default select, and it's value
    // match with current unchecked field
    const isDefaultUnchecked =
      this.props.defaultSelect && this.state.default === field.value;

    if (isDefaultUnchecked) {
      const { defaultSelect, form, change } = this.props;
      // Clear this field in redux-form
      change(form, defaultSelect.name, null);
    }

    this.setState({
      selected: this.state.selected.filter(
        (selectedField) => selectedField.value !== field.value
      ),
      default: isDefaultUnchecked ? null : this.state.default,
    });
  };

  handleDefaultSelect = (e) => {
    this.setState({
      default: e.target.value,
    });
    return e.target.value;
  };

  renderCheckboxes = () => {
    const { disabled, category, fields, name, cols, sort } = this.props;
    const { selected } = this.state;

    let categories;

    if (category) {
      categories = _.chain(fields).map('category').uniq().sort().value();
    }

    const sortedFields = sort ? fields.sort(getSorting(sort, 'label')) : fields;

    return category ? (
      <>
        {categories.map((cat, index) => (
          <StyledColsContainer cols={cols} key={index}>
            {cat && <StyledGroupLabel>{cat}</StyledGroupLabel>}
            {_.chain(sortedFields)
              .filter({ category: cat })
              .map((field, index) => (
                <Field
                  disabled={disabled}
                  key={index}
                  name={`${name}[${field.value}]`}
                  label={field.label}
                  component={renderCheckbox}
                  onChange={(e) => this.handleCheckboxClick(e, field)}
                />
              ))
              .value()}
          </StyledColsContainer>
        ))}
      </>
    ) : (
      <StyledColsContainer cols={cols}>
        {sortedFields.map((field, index) => {
          const isSelected = selected.find((f) => f.value === field.value);
          return (
            <React.Fragment key={field.value}>
              <Field
                disabled={disabled}
                name={`${name}[${field.value}]`}
                label={field.label}
                component={renderCheckbox}
                onChange={(e) => this.handleCheckboxClick(e, field)}
              />
              {isSelected && field.renderOnChecked}
            </React.Fragment>
          );
        })}
      </StyledColsContainer>
    );
  };

  render() {
    const {
      groupLabel,
      name,
      defaultSelect,
      column,
      hidden,
      required,
      min = 1,
      style = {},
    } = this.props;

    const { selected } = this.state;

    return (
      <StyledWrapper style={style} column={column}>
        {hidden && (
          <Field
            name={`${name}[${hidden}]`}
            value={!!this.state.selected.length}
            component="input"
            type="checkbox"
            hidden
          />
        )}

        {groupLabel && <StyledGroupLabel>{groupLabel}</StyledGroupLabel>}

        {this.renderCheckboxes()}

        {required && selected.length < min && (
          <StyledError>At least {min} must be selected</StyledError>
        )}

        {defaultSelect && (
          <Field
            name={defaultSelect.name}
            label={defaultSelect.label}
            component={renderSelectField}
            value={this.state.default}
            width="auto"
            onChange={this.handleDefaultSelect}
          >
            {this.state.selected.map(({ value, label }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </Field>
        )}
      </StyledWrapper>
    );
  }
}

CheckboxFieldsGroup.propTypes = {
  category: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  groupLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  required: PropTypes.bool,
  min: PropTypes.number,
  form: PropTypes.string.isRequired,
  disabled: PropTypes.bool,

  hidden: PropTypes.string,

  defaultSelect: PropTypes.exact({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

export default connect(null, { change })(CheckboxFieldsGroup);

const StyledWrapper = styled.div`
  ${(props) =>
    props.column &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `};
`;

const StyledGroupLabel = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
`;

const StyledError = styled.p`
  font-size: 1.2rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  color: #df2a26;
`;

const StyledColsContainer = styled.div`
  ${({ cols }) =>
    cols
      ? `
    column-count: ${cols};
    column-gap: 10;

    > * {
      width: 100%;
    }
    `
      : ''}
`;
