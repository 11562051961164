import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withTranslate } from 'components/hocs';
import { imagesURL } from 'config';

const move = (array = [], currentIndex, newIndex, changeOrderHandler) => {
  if (newIndex >= array.length || newIndex < 0) return null;

  const elementToMove = array[currentIndex];
  const newArray = [...array];
  newArray[currentIndex] = newArray[newIndex];
  newArray[newIndex] = elementToMove;

  changeOrderHandler(newArray);
};

const OrderButtons = ({ list, active, changeOrderHandler, translate }) => {
  const index = _.findIndex(list || [], active);
  return (
    <>
      <StyledOrderButtons>
        <button
          title={translate(601, 'Move Up', true)}
          onClick={() => move(list, index, index - 1, changeOrderHandler)}
        >
          <img src={`${imagesURL}/FlinkMake/arrowup.png`} alt="Move Up" />
        </button>
        <button
          title={translate(602, 'Move Down', true)}
          onClick={() => move(list, index, index + 1, changeOrderHandler)}
        >
          <img src={`${imagesURL}/FlinkMake/arrowdown.png`} alt="Move Down" />
        </button>
      </StyledOrderButtons>
      <StyledIndex>
        {index + 1} {translate(293, 'of')} {(list && list.length) || 0}
      </StyledIndex>
    </>
  );
};

OrderButtons.defaultProps = {
  list: []
};

OrderButtons.propTypes = {
  list: PropTypes.array,
  active: PropTypes.object,
  changeOrderHandler: PropTypes.func.isRequired
};

export default withTranslate(OrderButtons);

const StyledIndex = styled.p`
  font-size: 18px;
  text-align: center;
  margin: 0;
`;

const StyledOrderButtons = styled.div`
  padding-top: 12px;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 8px;

  button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;
