import { change } from 'redux-form';
import { get } from 'lodash';

export default (arrayOfFields, lists, formName, initialValues) => dispatch =>
  arrayOfFields.map(field => {
    // Get current Value from initialValues of form
    const currentValue =
      initialValues && field.name && get(initialValues, field.name);

    // Find List in Lists collecion
    const list = lists.filter(list => list.name === field.list)[0];

    // Get default value
    const defaultValue = list ? list.default : null;

    // if form mode is not 'edit' and default value exists, set it to form
    if (!currentValue && defaultValue && formName && field.name) {
      dispatch(change(formName, field.name, defaultValue));
    }

    // Organize options for Select Field of String Description
    field.options =
      list &&
      list.values &&
      list.values.split(',').map(value => ({
        value: value,
        label: value
      }));

    return field;
  });
