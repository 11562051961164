import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { get } from "lodash";

const CheckboxGroup = ({ label, name, changeHandler, current, disabled, defaultChecked = false }) => {
  return (
    <div>
      <StyledLabel>
        {label}
        <input
          disabled={disabled}
          type="checkbox"
          name={name}
          checked={get(current, name) ?? defaultChecked}
          onChange={e => {
            changeHandler(name, e.target.checked);
          }}
        />
      </StyledLabel>
    </div>
  );
};

CheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  current: PropTypes.object.isRequired,
  defaultChecked: PropTypes.bool,
};

export default CheckboxGroup;

const StyledLabel = styled.label`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;

  input {
    margin-left: 5px;
  }
`;
