/* eslint no-useless-escape: 0 */
/* eslint no-eval: 0 */
/* eslint no-unused-vars: 0 */

import _ from 'lodash';
import API from 'api';

export const findFileEndWith = (fileList, str) =>
  _.find(fileList, (obj) =>
    _.endsWith(obj.key.toLowerCase(), str.toLowerCase())
  );

export const convertOldOptions = (options) => {
  const newOptions = {
    delayBeforeNext: options.LengthOfTime,
    fontFamily: options.FontType,
    fontSize: options.FontSize,
    fontColor: options.FontColor,
    randomOrder: options.ProblemOrder === 1 || options.ProblemOrder === '1',
    showQuestion: options.ShowQuestion,
    showAnswer: options.ShowAnswer,
    animationSpeed: options.AnimationSpeed,
    questionsLimit: options.QuestionsLimit,
  };

  newOptions.fontFamily === 'ComicSans' &&
    (newOptions.fontFamily = 'Comic Sans MS');

  return _.omitBy(newOptions, (val) => val === undefined);
};

export const getStringValue = (oldVal) => {
  let value = oldVal;

  if (!value) return '';

  if (!typeof value !== 'string') {
    value = value + '';
  }

  if (value.match(/^0+$/)) {
    return '';
  }

  return value;
};

export const convertOldHelp = (helpHtml) => {
  // Format html
  const getFontSize = (size) => {
    switch (size) {
      case '1':
        return '10px';
      case '2':
        return '13px';
      case '3':
        return '16px';
      case '4':
        return '18px';
      case '5':
        return '24px';
      case '6':
        return '32px';
      case '7':
        return '48px';
      default:
        return '16px';
    }
  };

  // console.log('Origin help', helpHtml);

  const withoutCommentsAndLineBreaks = helpHtml
    .replace(/<!--[\s\S]*?-->/g, '')
    .replace(/\n/g, ' ')
    .trim();

  if (!withoutCommentsAndLineBreaks) {
    return '';
  }

  let htmlArray = withoutCommentsAndLineBreaks.match(/([\s\S])+?(?:>|(?=<))/g);

  const formattedHtml = htmlArray.map((node, indx) => {
    if (node.indexOf('<font') !== -1) {
      const font = node.match(/face="([\s\S]+?)"/);
      const color = node.match(/color="([\s\S]+?)"/);
      const size = node.match(/size="([\s\S]+?)"/);

      let span = '<span style="';

      if (font && font[1]) span += `font-family: ${font[1]}; `;
      if (color && color[1]) span += `color: ${color[1]}; `;
      if (size && size[1]) span += `font-size: ${getFontSize(size[1])}; `;

      span += '">';

      return span;
    }

    if (node.indexOf('<b ') !== -1) {
      const styles = node.match(/style="([\s\S]*?)"/)[1];

      return `<span style="${styles}"><strong>`;
    }

    if (node.indexOf('<b>') !== -1) {
      return `<span><strong>`;
    }

    if (node.indexOf('Apple-style-span') !== -1) {
      return `<span>`;
    }

    return node;
  });

  const emptyTags = /<[^>\/]+><\/[^>]+>/gm;

  const formattedHtmlHelp =
    '<div>' +
    formattedHtml
      .join('')
      .replace('font-size: x-small;', 'font-size: 10px;')
      .replace('font-size: small;', 'font-size: 13px;')
      .replace('font-size: medium;', 'font-size: 16px;')
      .replace('font-size: large;', 'font-size: 18px;')
      .replace('font-size: x-large;', 'font-size: 24px;')
      .replace('<br>', '')
      .replace(/<\/b>/g, '</strong></span>')
      .replace(/<\/font>/g, '</span>')
      .replace(emptyTags, '')
      .replace(emptyTags, '') +
    '</div>';

  return formattedHtmlHelp;
};

export const convert = async (s3FilesList, defaultOldOptions = {}) => {
  // Mockup needed for eval js files - gameData.js and optionsData_activity.js, cause there is used jQuery inside
  let Game = {};
  let $ = () => ({ trigger() {} });

  const result = {};

  const bindedFindFilesEndWith = findFileEndWith.bind(null, s3FilesList);

  // Get Game Data
  const gameDataFile = bindedFindFilesEndWith('gameData.js');
  const gameDataJsTxt = await API.storage.getFile(gameDataFile.key, true);

  if (!gameDataJsTxt) {
    throw new Error('Error fetching gameData.js file');
  }

  eval(gameDataJsTxt.replace('var', ''));
  result.gameData = Game.data.data || Game.data;

  // Get Actvity Options
  const activityOptionsObj = bindedFindFilesEndWith('optionsData_activity.js');

  const optionsJsTxt = await API.storage.getFile(activityOptionsObj.key, true);

  if (!optionsJsTxt) {
    throw new Error('Error fetching optionsData_activity.js file');
  }

  let oldOptions = {};

  if (typeof optionsJsTxt === 'object') {
    oldOptions = optionsJsTxt || {};
  } else if (typeof optionsJsTxt === 'string') {
    // JS File
    eval(optionsJsTxt.replace('var', ''));
    oldOptions = Game.options || {};
  }

  result.oldOptions = { ...defaultOldOptions, ...oldOptions };
  result.options = convertOldOptions(result.oldOptions);

  // Get Help Html
  const helpHtmlObj = bindedFindFilesEndWith('help.html');
  const helpHtml = await API.storage.getFile(helpHtmlObj.key, true);

  if (helpHtml) {
    result.helpHtml = convertOldHelp(helpHtml);
  }

  // Get Help Audio
  const helpAudioObj = bindedFindFilesEndWith('helpaudio.txt');

  if (helpAudioObj) {
    const helpAudio = await API.storage.getFile(helpAudioObj.key, true);

    if (helpAudio) result.helpAudio = helpAudio === 'empty' ? null : helpAudio;
  }

  return result;
};
