import { axiosAPI } from 'utils';

export default {
  getAvailableProducts(id) {
    return axiosAPI
      .get(`/api/admin/learningCenters/get-assigned-products/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return [];
      });
  },
};
