import React from 'react';
import styled, { css } from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core';

const styles = {
  radioGroup: {
    flexDirection: 'row'
  }
};

const renderRadioGroup = ({
  input,
  label,
  classes,
  options,
  meta: { touched, error, dirty },
  disabled,
  ...custom
}) => (
  <StyledInputGroup>
    <label>
      <StyledLabel error={error} touched={touched} dirty={dirty}>
        {label}
      </StyledLabel>
      <RadioGroup
        className={classes.radioGroup}
        options={options}
        {...input}
        {...custom}
        valueselected={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        {options.map((option, index) => (
          <FormControlLabel
            disabled={disabled}
            key={index}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </label>
    {touched && error && <StyledError>{error}</StyledError>}
  </StyledInputGroup>
);

export default withStyles(styles)(renderRadioGroup);

const StyledInputGroup = styled.div`
  margin-bottom: 1.5rem;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`;

const StyledLabel = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;

  ${({ touched, error, dirty }) =>
    touched &&
    (error
      ? css`
          color: #df2a26;
        `
      : dirty &&
        css`
          color: green;
        `)};
`;

const StyledError = styled.p`
  font-size: 1.2rem;
  margin-top: 2px;
  margin-bottom: 0;
  color: #df2a26;
`;
