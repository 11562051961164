import _ from 'lodash';
import shortid from 'shortid';
import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {};

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  gameData.problems = gameData.record || gameData.entry;
  delete gameData.record;
  delete gameData.entry;

  gameData.problems = gameData.problems.map((problem) => {
    const { letters, checked_letters, options } = problem;

    const syllables = _.compact(letters.split(','));
    let mask = checked_letters.split(',');
    mask.length = syllables.length;
    mask = mask.join('');

    const formattedProblem = {
      ...problem,
      id: shortid.generate(),
      syllables,
      mask,
      options: options ? options.split(',') : [],
    };

    delete formattedProblem.letters;
    delete formattedProblem.checked_letters;
    return formattedProblem;
  });

  result.gameData = gameData;
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
