import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  showHelp,
  closeSettings,
  saveSettingsAndCloseDialog,
  setMenuButtons,
  setPrevMenuButtons,
  familyUpdate,
  showQuickstartVideos,
} from 'actions/flinkPlayActions';
import { getFamilyMembersByFamily } from 'actions/familiesActions';

import { WhiteBox, ManagePrepaid } from 'components/flink-play';
import SettingsButton from './SettingsButton/SettingsButton';

import SettingsFamilyForm from './SettingsFamilyForm/SettingsFamilyForm';
import SettingsBigButtons from './SettingsBigButtons/SettingsBigButtons';
import SettingsManagePurchase from './SettingsManagePurchase/SettingsManagePurchase';
import SelectCharacter from './SelectCharacter/SelectCharacter';
import SettingsSelectSolution from './SettingsSelectSolution/SettingsSelectSolution';
import SelectTheme from './SelectTheme/SelectTheme';
import { audioURL } from 'config';
import { LOGOUT, HELP, PARENT_ADVICE } from 'consts/buttons';
import classes from './Settings.module.scss';
import SupportPhone from 'components/common/SupportPhone';

const defaultSettings = {
  bySolution: {},
  autoAnimation: true,
  teamHelp: true,
  activityHelp: true,
};

const Content = Object.freeze({
  MAIN: 'MAIN',
  FAMILY: 'FAMILY',
  CHARACTERS: 'CHARACTERS',
  THEMES: 'THEMES',
  ACCOUNT: 'ACCOUNT',
  SOLUTION: 'SOLUTION',
});
export class Settings extends Component {
  state = {
    isVisible: false,
    content: Content.MAIN,
    settings: {},
  };

  componentDidMount() {
    const {
      saveSettingsAndCloseDialog,
      closeSettings,
      learner: { settings, _id: learnerId, type: learnerType },
      setMenuButtons,
      locale,
      product,
      isInitialSettings,
      location,
    } = this.props;

    // Set menu buttons
    setMenuButtons([
      LOGOUT,
      PARENT_ADVICE,
      { type: HELP, onClick: () => this.showSettingsHelp() },
    ]);

    const {
      solutions,
      defaultSolutionsForAdmins,
      defaultSolutionsForMembers,
    } = product;

    const changedSettings = settings ? { ...settings } : { ...defaultSettings };
    const querySolutionId = location.state && location.state.solution;

    const preferSolutionId =
      querySolutionId || changedSettings.selectedSolutionId;

    let selectedSolution =
      preferSolutionId &&
      _.find(solutions, {
        _id: preferSolutionId,
      });

    if (!selectedSolution) {
      const defaultSolutions =
        (learnerType === 'adult'
          ? defaultSolutionsForAdmins
          : defaultSolutionsForMembers) || {};

      changedSettings.selectedSolutionId =
        defaultSolutions[locale.code] || (solutions[0] && solutions[0]._id);

      selectedSolution = _.find(solutions, {
        _id: changedSettings.selectedSolutionId,
      });
    }

    if (!selectedSolution) {
      return alert('No solutions in the product');
    }

    if (isInitialSettings && settings) {
      if (querySolutionId) {
        return saveSettingsAndCloseDialog(learnerId, {
          ...settings,
          selectedSolutionId: selectedSolution._id,
          bySolution: {
            ...(settings.bySolution || {}),
            [selectedSolution._id]: (settings.bySolution &&
              settings.bySolution[selectedSolution._id]) || {
              theme: selectedSolution.defaultTheme,
              character: selectedSolution.defaultCharacter,
            },
          },
        });
      } else {
        return closeSettings();
      }
    }

    if (isInitialSettings && !settings) {
      changedSettings.selectedSolutionId = selectedSolution._id;
      changedSettings.bySolution[selectedSolution._id] = {
        theme: selectedSolution.defaultTheme,
        character: selectedSolution.defaultCharacter,
      };

      saveSettingsAndCloseDialog(learnerId, changedSettings);
      // if (isAdmin) {
      //   this.showSettingsHelp(() =>
      //     saveSettingsAndCloseDialog(learnerId, changedSettings)
      //   );
      // } else {
      //   saveSettingsAndCloseDialog(learnerId, changedSettings);
      // }

      return;
    }

    this.setState({
      isVisible: true,
      settings: changedSettings,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      settings: { selectedSolutionId },
    } = this.state;

    if (prevState.settings.selectedSolutionId !== selectedSolutionId) {
      this.onSelectedSolutionChanged();
    }
  }

  componentWillUnmount() {
    const { isInitialSettings, setPrevMenuButtons } = this.props;
    setPrevMenuButtons();

    if (isInitialSettings) {
      const event = new CustomEvent('initialSettingsClosed');
      document.dispatchEvent(event);
    }
  }

  showSettingsHelp = (cb) => {
    const { learner, showHelp, locale, translate } = this.props;

    const { isAdmin } = learner;

    const audioHelp = `${audioURL}/Generic/SettingsHelp/${
      locale.name
    }/InitialHelp${isAdmin ? 'Admin' : 'Member'}.mp3`;

    showHelp({
      title: translate(196, 'Settings Help'),
      stringNumber: isAdmin ? 77 : 49,
      cb,
      // stringNumber: !!settings ? 196 : isAdmin ? 77 : 49,
      audioHelp,
      customTranslate: translate,
    });
  };

  openFamilyForm = async () => {
    const { getFamilyMembersByFamily, family } = this.props;

    const familyMembers = await getFamilyMembersByFamily(family._id);

    if (!familyMembers) return;

    const familyFormInitialValues = {
      ...family,
      // Family admin should be first one
      familyMembers: familyMembers.sort((a) => (a.isAdmin ? -1 : 1)),
    };

    this.setState({ content: Content.FAMILY, familyFormInitialValues });
  };

  familyFormOnSuccess = (family) => {
    const { familyUpdate } = this.props;
    familyUpdate(family);
    this.closeFamilyForm();
  };

  closeFamilyForm = () => {
    this.setState({ content: Content.MAIN, familyFormInitialValues: null });
  };

  changeSettingsInCurrentSolution = (prop, value) => {
    const { settings } = this.state;
    const { selectedSolutionId, bySolution = {} } = settings;

    const currentSolutionSettings = bySolution[selectedSolutionId] || {};
    currentSolutionSettings[prop] = value;

    this.setState({
      settings: {
        ...settings,
        bySolution: {
          ...bySolution,
          [selectedSolutionId]: currentSolutionSettings,
        },
      },
    });
  };

  changeSettings = (prop, value) => {
    const { settings } = this.state;
    this.setState({ settings: { ...settings, [prop]: value } });
  };

  onSelectedSolutionChanged = () => {
    const { settings } = this.state;
    const { selectedSolutionId, bySolution = {} } = settings;

    const { product } = this.props;

    const selectedSolution = _.find(product.solutions, {
      _id: selectedSolutionId,
    });

    const bySolutionSettings =
      (bySolution[selectedSolutionId] && {
        ...bySolution[selectedSolutionId],
      }) ||
      {};

    const themesOptions = _.chain(selectedSolution.themes)
      .map((theme) => ({ value: theme._id, label: theme.name }))
      .sortBy('label')
      .value();

    if (bySolutionSettings.theme) {
      const theme = _.find(selectedSolution.themes, {
        _id: bySolutionSettings.theme,
      });

      if (!theme) {
        bySolutionSettings.theme = selectedSolution.defaultTheme;
      }
    } else if (bySolutionSettings.theme === undefined) {
      bySolutionSettings.theme = selectedSolution.defaultTheme;
    }

    const charactersOptions = _.chain(selectedSolution.characters)
      .map((character) => ({ value: character._id, label: character.name }))
      .sortBy('label')
      .value();

    if (bySolutionSettings.character) {
      const character = _.find(selectedSolution.characters, {
        _id: bySolutionSettings.character,
      });

      if (!character) {
        bySolutionSettings.character = selectedSolution.defaultCharacter;
      }
    } else if (bySolutionSettings.character === undefined) {
      bySolutionSettings.character = selectedSolution.defaultCharacter;
    }

    this.setState({
      themesOptions,
      charactersOptions,
      settings: {
        ...settings,
        bySolution: { ...bySolution, [selectedSolutionId]: bySolutionSettings },
      },
    });
  };

  saveSettings = () => {
    const { saveSettingsAndCloseDialog, learner } = this.props;
    const { settings } = this.state;

    saveSettingsAndCloseDialog(learner._id, settings);
  };

  openContent = (content) => this.setState({ content });
  closeContent = () => this.setState({ content: Content.MAIN });

  render() {
    const {
      closeSettings,
      showQuickstartVideos,
      locale,
      translate,
      family,
      product,
      learner: { settings: currentSettings },
    } = this.props;

    const {
      content,
      isVisible,
      themesOptions,
      charactersOptions,
      settings,
      familyFormInitialValues,
    } = this.state;

    const { selectedSolutionId, bySolution } = settings;

    const currentSolutionSettings =
      (bySolution && bySolution[selectedSolutionId]) || {};

    if (!isVisible) return null;

    const showCharactersButton =
      charactersOptions && !!charactersOptions.length;
    const showThemesButton = themesOptions && !!themesOptions.length;
    const showSolutionsButton =
      product.solutions && product.solutions.length > 1;

    return (
      <div className={classes.overlay}>
        <WhiteBox
          outerClass={classes.settingsOuter}
          innerClass={classes.settingsInner}
        >
          {content === Content.MAIN && (
            <>
              <SettingsBigButtons
                openAccount={() => this.openContent(Content.ACCOUNT)}
                showQuickstartVideos={showQuickstartVideos}
                currentLocaleCode={locale.code}
                currentLocaleName={locale.name}
                openFamilyForm={this.openFamilyForm}
                translate={translate}
                settings={settings}
                changeHandler={this.changeSettings}
                openCharacters={
                  showCharactersButton
                    ? () => this.openContent(Content.CHARACTERS)
                    : undefined
                }
                openThemes={
                  showThemesButton
                    ? () => this.openContent(Content.THEMES)
                    : undefined
                }
                openSolutions={
                  showSolutionsButton
                    ? () => this.openContent(Content.SOLUTION)
                    : undefined
                }
              />

              <div className={classes.bottom}>
                <SupportPhone className={classes.phone} />

                <div className={classes.settingsActions}>
                  {currentSettings && (
                    <SettingsButton onClick={closeSettings}>
                      {translate(52, 'Cancel')}
                    </SettingsButton>
                  )}
                  <SettingsButton
                    onClick={this.saveSettings}
                    disabled={
                      currentSettings &&
                      JSON.stringify(currentSettings) ===
                        JSON.stringify(settings)
                    }
                  >
                    {translate(298, 'Save')}
                  </SettingsButton>
                </div>
              </div>
            </>
          )}

          {content === Content.FAMILY && (
            <SettingsFamilyForm
              onSuccess={this.familyFormOnSuccess}
              closeHandler={this.closeFamilyForm}
              translate={translate}
              initialValues={familyFormInitialValues}
            />
          )}

          {content === Content.ACCOUNT && (
            <div className={classes.popup}>
              <div className={classes.popupViewport}>
                {family.isPrepaidCustomer ? (
                  <ManagePrepaid translate={translate} />
                ) : (
                  <SettingsManagePurchase translate={translate} />
                )}
              </div>

              <div className={classes.popupActions}>
                <SettingsButton onClick={this.closeContent}>
                  {translate(58, 'Go Back')}
                </SettingsButton>
              </div>
            </div>
          )}

          {content === Content.CHARACTERS && (
            <>
              <SelectCharacter
                options={charactersOptions}
                value={currentSolutionSettings.character}
                onChange={(value) =>
                  this.changeSettingsInCurrentSolution('character', value)
                }
              />
              {this.renderCloseContentButton()}
            </>
          )}

          {content === Content.THEMES && (
            <>
              <SelectTheme
                options={themesOptions}
                value={currentSolutionSettings.theme}
                onChange={(value) =>
                  this.changeSettingsInCurrentSolution('theme', value)
                }
              />
              {this.renderCloseContentButton()}
            </>
          )}

          {content === Content.SOLUTION && (
            <>
              <SettingsSelectSolution
                translate={translate}
                solutionLists={product.solutionLists}
                value={selectedSolutionId}
                solutions={product.solutions}
                onChange={(solutionId) =>
                  this.changeSettings('selectedSolutionId', solutionId)
                }
              />
              {this.renderCloseContentButton()}
            </>
          )}
        </WhiteBox>
      </div>
    );
  }

  renderCloseContentButton = () => {
    const { translate } = this.props;
    return (
      <div className={classes.bottom}>
        <SettingsButton onClick={this.closeContent}>
          {translate(58, 'Go Back')}
        </SettingsButton>
      </div>
    );
  };
}

Settings.propTypes = {
  isInitialSettings: PropTypes.bool,
  closeSettings: PropTypes.func.isRequired,
  familyUpdate: PropTypes.func.isRequired,
  getFamilyMembersByFamily: PropTypes.func.isRequired,
  saveSettingsAndCloseDialog: PropTypes.func.isRequired,
  showHelp: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  learner: PropTypes.object.isRequired,
  setMenuButtons: PropTypes.func.isRequired,
  setPrevMenuButtons: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  flinkPlay: {
    product,
    family,
    isInitialSettings,
    solutionTranslate,
    solutionLocale,
    learner,
  },
  status,
}) => ({
  product,
  family,
  learner,
  isInitialSettings,
  translate: solutionTranslate || status.translate,
  locale: solutionLocale || status.lang,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    showHelp,
    closeSettings,
    familyUpdate,
    saveSettingsAndCloseDialog,
    getFamilyMembersByFamily,
    setMenuButtons,
    setPrevMenuButtons,
    showQuickstartVideos,
  })
)(Settings);
