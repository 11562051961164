import React from 'react';
import styled, { keyframes } from 'styled-components';

import Portal from './Portal';

const getPercents = uploading =>
  Math.round((uploading.loaded / uploading.total) * 100);

export default ({ uploading = false }) => (
  <Portal>
    <Container>
      <Overlay />

      <SpinnerBox>
        <CircleOuter />
        <CircleInner />
      </SpinnerBox>
      {uploading && (
        <StyledProgress>Uploading: {getPercents(uploading)}%</StyledProgress>
      )}
    </Container>
  </Portal>
);

const Container = styled.div`
  position: fixed;
  overflow: hidden;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div`
  background: black;
  opacity: 0.6;

  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const SpinnerBox = styled.div`
  position: relative;
  z-index: 1;
  width: auto;
  display: inline-block;
`;

const StyledProgress = styled.div`
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 30px;
  margin-top: 20px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const rotateReverse = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const CircleOuter = styled.div`
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid rgba(0, 183, 229, 0.9);
  opacity: 0.9;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  box-shadow: 0 0 35px #2187e7;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  animation: ${rotate} 0.5s infinite linear;
`;

const CircleInner = styled.div`
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid rgba(0, 183, 229, 0.9);
  opacity: 0.9;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-radius: 60px;
  box-shadow: 0 0 15px #2187e7;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 15px;
  top: 15px;
  animation: ${rotateReverse} 0.5s infinite linear;
`;
