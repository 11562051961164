import MatchingDefinitions from './MatchingDefinitions';
import {
  validateWords,
  generateActivityFromWords
} from './matchingDefinitionsHelpers';
import convertOldActivity from './convertOldMatchingDefinitions';
import validateActivity from './validateMatchingDefinitions';

export default {
  Template: MatchingDefinitions,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'fontFamily',
    'fontColor',
    'fontSize',
    'delayBeforeNext',
    'randomOrder',
    'questionsLimit',
    'showAnswer'
  ],
  validateWords,
  generateActivityFromWords
};
