import {
  SET_PLAYING,
  SET_CORRECT_SOUNDS_PLAYED,
  SET_INCORRECT_SOUNDS_PLAYED,
} from 'actions/types';
import { audioURL } from 'config';
const filter = 'audio';

const NUMBER_OF_CORRECT_SOUNDS = 7;
const NUMBER_OF_INCORRECT_SOUNDS = 6;

export const setPlaying = (audioSrc, isPlaying) => ({
  filter,
  type: SET_PLAYING,
  payload: { audioSrc, isPlaying },
});

export const playAudio = (src, options) => {
  window.audioPlayer.playSrc(src, options);
};

export const toggleSequence = (src, options) => {
  window.audioPlayer.toggleSequence(src, options);
};

export const toggleAudio = (src, options) => {
  window.audioPlayer.togglePlaySrc(src, options);
};

export const stopAudio = (src) => {
  window.audioPlayer.stop(src);
};

const setCorrectSoundsPlayedCount = (count) => ({
  filter,
  type: SET_CORRECT_SOUNDS_PLAYED,
  payload: count,
});

const setIncorrectSoundsPlayedCount = (count) => ({
  filter,
  type: SET_INCORRECT_SOUNDS_PLAYED,
  payload: count,
});

export const playApplause = (forAnimation = false) => async (
  dispatch,
  getState
) => {
  const {
    flinkPlay: { solutionLocale },
  } = getState();

  const localeName = solutionLocale ? solutionLocale.name : 'English';

  const audioFilename = forAnimation ? 'applause1.mp3' : 'applause2.mp3';

  const src = `${audioURL}/Generic/ActivityResults/${localeName}/${audioFilename}`;
  playAudio(src);
};

export const playCorrectSound = () => (dispatch, getState) => {
  const {
    audio,
    flinkPlay: { solutionLocale },
  } = getState();

  const correctSoundsPlayed =
    NUMBER_OF_CORRECT_SOUNDS === audio.correctSoundsPlayed
      ? 1
      : audio.correctSoundsPlayed + 1;

  const src = `${audioURL}/Generic/Feedback/${
    solutionLocale ? solutionLocale.name : 'English'
  }/correct${correctSoundsPlayed}.mp3`;

  playAudio(src);

  dispatch(setCorrectSoundsPlayedCount(correctSoundsPlayed));
};

export const playIncorrectSound = () => (dispatch, getState) => {
  const {
    audio,
    flinkPlay: { solutionLocale },
  } = getState();

  const incorrectSoundsPlayed =
    NUMBER_OF_INCORRECT_SOUNDS === audio.incorrectSoundsPlayed
      ? 1
      : audio.incorrectSoundsPlayed + 1;

  const src = `${audioURL}/Generic/Feedback/${
    solutionLocale ? solutionLocale.name : 'English'
  }/incorrect${incorrectSoundsPlayed}.mp3`;

  playAudio(src);

  dispatch(setIncorrectSoundsPlayedCount(incorrectSoundsPlayed));
};

export const playWatch = () => (dispatch, getState) => {
  const {
    flinkPlay: { solutionLocale },
  } = getState();

  const src = `${audioURL}/Generic/Feedback/${
    solutionLocale ? solutionLocale.name : 'English'
  }/watch.mp3`;

  playAudio(src);
};
