import MissingElements from './MissingElements';
import convertOldActivity from './convertOldMissingElements';
import validateActivity from './validateMissingElements';
import {
  validateWords,
  generateActivityFromWords
} from './missingElementsHelpers';

export default {
  Template: MissingElements,
  validateActivity,
  convertOldActivity,
  optionsArray: [
    'delayBeforeNext',
    'randomOrder',
    'showAnswer',
    'questionsLimit'
  ],
  validateWords,
  generateActivityFromWords
};
