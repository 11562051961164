import { validateProblem } from './spellingConnectablesHelpers';

const validProblemExists = problems => {
  for (let i = 0; i < problems.length; i++) {
    if (validateProblem(problems[i])) return true;
  }

  return false;
};

export default ({ options, gameData }) => {
  return !(
    !options ||
    !gameData ||
    !gameData.problems ||
    !gameData.problems.length ||
    !validProblemExists(gameData.problems)
  );
};
