import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';

import classes from './Draggable.module.scss';

class Draggable extends Component {
  constructor(props) {
    super(props);

    const { size, position, resizable } = props;

    const enableResizing = resizable
      ? {
          bottom: true,
          bottomRight: true,
          bottomLeft: true,
          right: true,
          topRight: true,
          topLeft: true,
        }
      : {};

    this.state = {
      enableResizing,
      size,
      position,
    };
  }

  componentDidMount() {
    this.pushToCenter();
  }

  componentDidUpdate() {
    this.pushToCenter();
  }

  pushToCenter = () => {
    if (
      !this.rnd ||
      this.state.centered ||
      (this.props.position && (this.props.position.x || this.props.position.y))
    )
      return;

    const { size } = this.rnd.resizable;

    const x = document.documentElement.clientWidth / 2 - size.width / 2;
    const y = document.documentElement.clientHeight / 2 - size.height / 2;

    const position = { x, y: y > 0 ? y : 50 };

    this.setState({
      position,
      centered: true,
    });
  };

  updateSize = (e, direction, ref, delta, position) => {
    const size = {
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    };

    this.setState({ size, position });
  };

  updatePosition = (e, d) => {
    this.setState({
      position: d,
    });
  };

  render() {
    const {
      children,
      onClose,
      withOverlay,
      resizable,
      zIndex,
      size: omitSize,
      position: omitPosition,
      ...otherProps
    } = this.props;

    const { size, position, enableResizing } = this.state;

    return (
      <div className={classes['modal-wrapper']} style={{ zIndex }}>
        {withOverlay && (
          <div className={classes['modal-overlay']} onClick={onClose} />
        )}
        <Rnd
          ref={(c) => {
            this.rnd = c;
          }}
          size={size}
          onResizeStop={this.updateSize}
          position={position}
          onDragStop={this.updatePosition}
          enableResizing={enableResizing}
          bounds="#root"
          minHeight="100"
          minWidth="100"
          {...otherProps}
        >
          {children}
        </Rnd>
      </div>
    );
  }
}

Draggable.propTypes = {
  onClose: PropTypes.func,
  withOverlay: PropTypes.bool,
};

export default Draggable;
