import { axiosAPI } from 'utils';

export default {
  getForMenu() {
    return axiosAPI
      .get('api/activity-groups/get-for-menu')
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return [];
      });
  },
  getGroupElementsById(id) {
    return axiosAPI
      .get(`api/activity-groups/get-group-elements-by-id/${id}`)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return [];
      });
  },
  checkIfGroupsCanBeAdded(parentId, groupsIds) {
    return axiosAPI
      .post(`api/activity-groups/check-if-groups-can-be-added-to-group`, {
        parentId,
        groupsIds
      })
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err.response.data;
      });
  }
};
