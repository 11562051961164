import { axiosAPI } from 'utils';

export default {
  getByLearningCenter(id) {
    return axiosAPI
      .get(`api/solutions/get-by-learning-center/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return err.response.data;
      });
  },
};
