import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Clipart } from 'components/flink-components';
import { Portal } from 'components/common';
import { uploadsURL, filesURL } from 'config';
import classes from './PictureBox.module.scss';

const clipartIcon = `${filesURL}/Images/TemplateGraphics/StoryStudio/clipArtLibrary.png`;

const PictureBox = ({
  paths,
  images = [],
  learnerAssetsList = [],
  onChangeImage,
  onRemoveImage,
  currentImage,
  showList,
  withClipart = false,
}) => {
  const [showClipart, setShowClipart] = useState(false);
  const {
    activityAssetsKey,
    activityAssetsFolder,
    learnerAssetsFolder,
  } = paths;

  return (
    <>
      <div className={classes.wrapper}>
        {showList && (
          <ul className={classes.list}>
            {_.times(12).map((idx) => {
              const imageFilename = images[idx];
              const key =
                imageFilename && `${activityAssetsKey}/${imageFilename}`;
              const url = key && `${uploadsURL}/${key}`;

              return (
                <li key={idx}>
                  {imageFilename && imageFilename !== currentImage && (
                    <button
                      onClick={() => {
                        onChangeImage(imageFilename, key);
                      }}
                    >
                      <img src={url} alt="" />
                    </button>
                  )}
                   
                  {idx === 11 && withClipart && (
                    <button
                      onClick={() => {
                        setShowClipart(true);
                      }}
                    >
                      <img src={clipartIcon} alt="" />
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
        )}

        <div className={classes.currentImageWrapper}>
          {currentImage && (
            <>
              <img
                src={
                  (learnerAssetsList.includes(currentImage)
                    ? learnerAssetsFolder
                    : activityAssetsFolder) + currentImage
                }
                alt=""
              />
              {showList && (
                <button
                  className={classes.removeImageBtn}
                  onClick={onRemoveImage}
                >
                  &times;
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <Portal>
        <Clipart
          show={showClipart}
          onClose={setShowClipart.bind(null, false)}
          onPick={(fileInfo) => {
            onChangeImage(fileInfo.filename, fileInfo.key, true);
            setShowClipart(false);
          }}
        />
      </Portal>
    </>
  );
};

PictureBox.propTypes = {
  paths: PropTypes.object.isRequired,
  learnerAssetsList: PropTypes.array,
  onChangeImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  currentImage: PropTypes.string,
  withClipart: PropTypes.bool,
  images: PropTypes.array,
};

export default PictureBox;
