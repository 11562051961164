import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers, { RootState } from 'reducers';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const composeEnhancers = composeWithDevTools({});

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
);

if (process.env.NODE_ENV !== 'production' && 'hot' in module) {
  // @ts-ignore
  module.hot.accept('./reducers', () => store.replaceReducer(reducers));
}

export default store;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
