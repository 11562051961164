import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DraggableModal from 'components/flink-components/DraggableModal/DraggableModal';
import classes from './ActivityEdusModal.module.scss';
import EduColumn from './EduColumn/EduColumn';

const addEdu = (edus, scopeId, changeHandler) => {
  changeHandler([...edus, { scopeId }]);
};

const ActivityEdusModal = ({ scope, edus, show, onClose }) => {
  const [stateEdus, setStateEdus] = useState(edus);

  if (!stateEdus.length) {
    addEdu(stateEdus, scope._id, setStateEdus);
  }

  const singleFirstMenu = scope.getSingleFirstMenuTitle();

  return (
    <DraggableModal
      show={show}
      onClose={onClose.bind(
        null,
        _.filter(stateEdus, edu => !!edu[singleFirstMenu])
      )}
      title={'Multiple Attributes'}
      withOverlay
    >
      <div className={classes.wrapper}>
        {stateEdus.map((edu, idx) => (
          <EduColumn
            key={idx}
            scope={scope}
            edu={edu}
            notRemovable={stateEdus.length === 1}
            changeHandler={edu => {
              const changedEdus = [...stateEdus];
              if (edu) {
                changedEdus[idx] = edu;
                setStateEdus(changedEdus);
              } else {
                _.pullAt(changedEdus, idx);
                setStateEdus(changedEdus);
              }
            }}
          />
        ))}
        {stateEdus.length < 6 && (
          <button
            className={classes.addButton}
            type="button"
            onClick={() => addEdu(stateEdus, scope._id, setStateEdus)}
          >
            +
          </button>
        )}
      </div>
    </DraggableModal>
  );
};

ActivityEdusModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ActivityEdusModal;
