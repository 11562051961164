import { axiosAPI as axios, withLoading } from 'utils';

const apiPrefix = 'api/solutions';

export const getSolutions = (params = {}) => (dispatch) =>
  withLoading(dispatch, () => {
    return axios
      .get(apiPrefix, { params })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error('Error fetching solutions');
        return err && err.response && err.response.data;
      });
  });

export const getAnimationsList = (types) => (dispatch) =>
  withLoading(dispatch, async () => {
    return axios
      .get(`${apiPrefix}/get-animations-list`, {
        params: { types: types.join(',') },
      })
      .then((res) => res.data.data)
      .catch((err) => {
        console.error('Error fetching animations list');
        return Promise.resolve({});
      });
  });

export const getHelpActivities = () => (dispatch) =>
  withLoading(dispatch, async () => {
    return axios
      .get(`${apiPrefix}/get-help-activities`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.error('Error fetching help activities');
        return Promise.resolve([]);
      });
  });
