import _ from 'lodash';
import shortId from 'shortid';

import { addIdToFilename, isUsingStrings } from 'utils';
import { getWordPictureFilename } from 'utils/wordlistHelpers';

// {
//   "problems": [
//     {
//       "id": "qfdsaf2",
//        "items": [
//         "text": "Text",
//         "audio": "audio.mp3",
//         "image": "image.png",
//       ]
//     },
//     {
//       "id": "qfdsaf2",
//       "items": [
//         "text": "Text",
//         "audio": "audio.mp3",
//         "image": "image.png",
//       ]
//     }
//   ],
//   "matchTypes": [
//     {
//       "title": "Text Title",
//       "type": "text",
//       "isCommon": true
//     },
//     {
//       "title": "Audio Title",
//       "type": "audio",
//       "isCommon": false
//     },
//     {
//       "title": "Image Title",
//       "type": "image",
//       "isCommon": false
//     }
//   ],
//   "cardsPerLevel": [
//     12,
//     16,
//     18
//   ]
// }

export const validateItem = (item) =>
  !!(item && (item.text || item.audio || item.image));

export const validateProblem = (matchTypes, problem) => {
  const matchesTypesCount = matchTypes.filter((m) => !!m.type).length;
  return !!(
    problem.id &&
    problem.items &&
    problem.items &&
    problem.items.filter(validateItem).length === matchesTypesCount
  );
};

export const prepareProblem = mapper => problem => ({
  ...problem,
  items: problem.items?.map(mapper),
});

export const createProblem = () => ({
  id: shortId.generate(),
  items: [],
});

export const getContentFilesFromItem = (item) => {
  const files = [];
  if (!item) return files;

  const { text, image, audio } = item;
  !isUsingStrings(text) && image && files.push(image);
  !isUsingStrings(text) && audio && files.push(audio);

  return files;
};

export const getContentFilesFromProblem = (problem) => {
  let files = [];
  const { items } = problem;

  items.forEach((item) => {
    files = _.concat(files, getContentFilesFromItem(item));
  });
  console.log(files);
  return files;
};

export const validateWords = (words, templateDBrecord) => {
  // Should be minimum 3 words
  if (words.length < 6) {
    return { success: false, error: 'Should be minimum 6 words' };
  }

  const missingAudio = [];

  for (let i = 0; i < words.length; i++) {
    if (!words[i].wordAudio) {
      missingAudio.push(words[i].word);
    }
  }

  if (missingAudio.length) {
    return {
      success: false,
      error: `Missing audio for words - ${missingAudio.join(', ')}`,
    };
  }

  return { success: true, filteredWords: words };
};

export const generateActivityFromWords = ({ words, template }) => {
  const { imageRequired } = template;

  const wordsImages = [];
  const wordsAudio = [];

  const problems = words.map((word) => {
    const wordAudio = word.wordAudio && addIdToFilename(word.wordAudio);

    wordsAudio.push({
      src: word.wordAudio,
      filename: wordAudio,
    });

    const problem = {
      id: shortId.generate(),
      items: [{ text: word.word }, { audio: wordAudio }],
    };

    if (imageRequired) {
      const imageSrcFilename = getWordPictureFilename(word);
      const imageFilename = addIdToFilename(imageSrcFilename);

      imageFilename &&
        wordsImages.push({
          src: imageSrcFilename,
          filename: imageFilename,
        });

      problem.items = [{ image: imageFilename }, { audio: wordAudio }];
    } else {
      problem.items = [{ text: word.word }, { audio: wordAudio }];
    }

    return problem;
  });

  const cardsPerLevel = [12];

  if (problems.length >= 8) {
    cardsPerLevel.push(16);
  }

  if (problems.length >= 10) {
    cardsPerLevel.push(20);
  }

  const gameData = {
    problems,
    matchTypes: [
      {
        title: imageRequired ? 'Image' : 'Text',
        type: imageRequired ? 'image' : 'text',
        isCommon: false,
      },
      {
        title: 'Audio',
        type: 'audio',
        isCommon: false,
      },
    ],
    cardsPerLevel,
  };

  return { gameData, wordsAudio, wordsImages };
};
