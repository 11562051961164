import { axiosAPI, responseHandler } from 'utils';

const errorHandler = (err) => {
  if (err.response) return err.response.data;
  return { success: false, message: err.message };
};

export default {
  getBraintreeToken({ familyId, customerId }) {
    return axiosAPI
      .get(`api/purchases/client-token`, { params: { familyId, customerId } })
      .then((res) => res.data)
      .catch(errorHandler);
  },
  buyHours({ familyId, hours, paymentNonce, amount, phone }) {
    return responseHandler(
      axiosAPI.post(`api/purchases/prepaid/buy-hours`, {
        familyId,
        hours,
        paymentNonce,
        amount,
        phone,
      })
    );
  },
  buyout({ familyId, paymentNonce, amount, phone }) {
    return responseHandler(
      axiosAPI.post(`api/purchases/prepaid/buyout`, {
        familyId,
        paymentNonce,
        amount,
        phone,
      })
    );
  },
  getShopifyProducts() {
    return axiosAPI
      .get(`api/purchases/shopify-products`)
      .then((res) => res.data)
      .catch(errorHandler);
  },
  getBraintreePlans() {
    return axiosAPI
      .get(`api/purchases/braintree-plans`)
      .then((res) => res.data)
      .catch(errorHandler);
  },
  getPurchaseInfo(id) {
    return axiosAPI
      .get(`api/purchases/${id}`)
      .then((res) => res.data)
      .catch(errorHandler);
  },
  retryPastDueTransaction(id, data) {
    return axiosAPI
      .post(`api/purchases/${id}/retry-transaction`, data)
      .then((res) => res.data)
      .catch(errorHandler);
  },
  resumeSubscription(id, data) {
    return axiosAPI
      .post(`api/purchases/${id}/resume-subscription`, data)
      .then((res) => res.data)
      .catch(errorHandler);
  },
  cancelSubscription(id) {
    return axiosAPI
      .delete(`api/purchases/${id}/cancel-subscription`)
      .then((res) => res.data)
      .catch(errorHandler);
  },
  requestRefund(id, message) {
    return axiosAPI
      .post(`api/purchases/${id}/request-refund`, { message })
      .then((res) => res.data)
      .catch(errorHandler);
  },
  generateCodes(title, number) {
    return axiosAPI
      .post('api/purchases/generate-codes', { title, number })
      .then((res) => res.data)
      .catch(errorHandler);
  },
};
