import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form';
import _ from 'lodash';

import {
  renderTextField,
  StyledError,
  renderRadioGroup,
  // renderSelectField,
} from 'components/form-components';
import SettingsButton from '../SettingsButton/SettingsButton';
import { editFamily } from 'actions/familiesActions';
import { showError } from 'actions/statusActions';
import { FAMILY_DEFAULT_MEMBERS_NUMBER } from 'consts/settings';
import classes from './SettingsFamilyForm.module.scss';

// const requiredFields = [
//   "familyMembers[0].firstname",
//   "familyMembers[0].lastname",
//   "familyMembers[0].username"
// ];

// const validate = reduxFormValidator(requiredFields);

const CustomField = ({ ...props }) => (
  <Field margin="dense" component={renderTextField} width={null} {...props} />
);

class SettingsFamilyForm extends Component {
  // constructor(props) {
  //   super(props);

  //   const { product } = props;

  //   const solutionsOptions =
  //     product.solutions &&
  //     product.solutions.map((sol) => ({
  //       label: sol.name,
  //       value: sol._id,
  //     }));

  //   this.state = { solutionsOptions };
  // }

  submit = async (values) => {
    const { onSuccess, editFamily, translate } = this.props;

    const familyMembersErrors = {};
    const memberRequiredFields = ['username', 'firstname', 'lastname'];

    const requiredTranslate = translate(695, 'Required for member');

    const validateMember = (member, idx) => {
      if (_.isEmpty(member)) return;

      memberRequiredFields.forEach((field) => {
        !member[field] &&
          (familyMembersErrors[idx] = {
            ...familyMembersErrors[idx],
            [field]: requiredTranslate,
          });
      });
    };

    _.forEach(values.familyMembers, validateMember);

    if (!_.isEmpty(familyMembersErrors)) {
      throw new SubmissionError({ familyMembers: familyMembersErrors });
    }

    const result = await editFamily(values);

    if (result.success) {
      onSuccess(result.data.family);
    } else {
      throw new SubmissionError(result.errors);
    }
  };

  render() {
    const {
      closeHandler,
      translate,
      addons,
      error,
      handleSubmit,
      pristine,
      submitting,
      initialValues,
    } = this.props;
    // const { solutionsOptions } = this.state;

    const familyEmailTranslate = translate(984, 'Family Email Address');
    const familyPhoneTranslate = translate(994, 'Family Phone');
    const familyAdminTranslate = translate(535, 'Family Admin');
    const familyMemberTranslate = translate(537, 'Family Member');
    const firstNameTranslate = translate(912, 'First Name');
    const lastNameTranslate = translate(913, 'Last Name');
    const locationTranslate = translate(0, 'Location');

    return (
      <form onSubmit={handleSubmit(this.submit)} className={classes.form}>
        <div className={classes.formViewport}>
          <div className={classes.familyFields}>
            <CustomField
              name="email"
              label={familyEmailTranslate}
            />
          </div>
          <div className={classes.familyFields}>
            <CustomField
              name="phone"
              label={familyPhoneTranslate}
              required={initialValues.agreeReceiveTextMessages}
              disabled
            />
          </div>
          <div className={classes.familyFields}>
            <CustomField
              name="location"
              label={locationTranslate}
              disabled
            />
          </div>
          <div className={classes.membersWrapper}>
            {_.times(FAMILY_DEFAULT_MEMBERS_NUMBER + (addons || 0)).map(
              (idx) => {
                const memberTranslate =
                  idx === 0
                    ? familyAdminTranslate
                    : `${familyMemberTranslate} ${idx + 1}`;

                return (
                  <div key={idx} className={classes.memberFields}>
                    <CustomField
                      name={`familyMembers[${idx}].firstname`}
                      label={`${memberTranslate} ${firstNameTranslate}`}
                    />
                    <CustomField
                      name={`familyMembers[${idx}].lastname`}
                      label={`${memberTranslate} ${lastNameTranslate}`}
                    />
                    <CustomField
                      name={`familyMembers[${idx}].username`}
                      label={translate(102, 'Username')}
                      // normalize={(val) => val && val.replace(/[^\w.-]/g, '')}
                    />
                    <CustomField
                      name={`familyMembers[${idx}].yob`}
                      label={translate(1317, 'Year of Birth')}
                    />
                    {/* <CustomField
                      name={`familyMembers[${idx}].password`}
                      label={translate(498, 'Password')}
                    /> */}
                    <CustomField
                      component={renderRadioGroup}
                      options={[
                        { value: 'adult', label: translate(1319, 'Adult') },
                        { value: 'child', label: translate(1318, 'Child') },
                      ]}
                      name={`familyMembers[${idx}].type`}
                      label={translate(698, 'Select')}
                    />
                    {/* <CustomField
                      component={renderSelectField}
                      options={solutionsOptions || []}
                      name={`familyMembers[${idx}].reportSolution`}
                      label={translate(499, 'Report Solution')}
                    /> */}
                  </div>
                );
              }
            )}
          </div>

          {!pristine && error && <StyledError>{error}</StyledError>}
        </div>

        <div className={classes.formButtonsWrapper}>
          <SettingsButton type="button" onClick={closeHandler}>
            {translate(52, 'Cancel')}
          </SettingsButton>

          <SettingsButton disabled={pristine || submitting} type="submit">
            {translate(298, 'Save')}
          </SettingsButton>
        </div>
      </form>
    );
  }
}

SettingsFamilyForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  showError: PropTypes.func.isRequired,
  editFamily: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

const selector = formValueSelector('SettingsFamilyForm');

const mapStateToProps = (state) => ({
  familyMembers: selector(state, 'familyMembers'),
  addons: selector(state, 'addons'),
  product: state.flinkPlay.product,
});

export default compose(
  reduxForm({
    form: 'SettingsFamilyForm',
    enableReinitialize: true,
    // validate
  }),
  connect(mapStateToProps, {
    showError,
    editFamily,
  })
)(SettingsFamilyForm);
