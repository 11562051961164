import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';

import { WhiteBox } from 'components/flink-play';
import itemType from '../draggable-item-type';
import DraggableItem from '../DraggableItem/DraggableItem';

const boardTarget = {
  drop(props, monitor) {
    return { isBoard: true };
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const BoardWithDraggableItems = ({
  refs,
  isResolved,
  ItemComponent,
  customClasses,
  items,
  onDrop,
  boxesCount,
  textStyle,
  connectDropTarget
}) => {
  return connectDropTarget(
    <div style={textStyle}>
      <WhiteBox
        outerClass={customClasses.boardOuter}
        innerClass={customClasses.boardInner}
      >
        {_.times(boxesCount, idx => {
          /* const itemInThatBox = _.find(items, {
            boxIdx: idx,
            inSlot: false
          }); */

          const itemInThatBox = _.find(items, {
            boxIdx: idx
          });

          return (
            <div className={customClasses.box} key={idx} ref={refs[idx]}>
              {itemInThatBox && !itemInThatBox.inSlot && (
                <DraggableItem
                  key={itemInThatBox.id}
                  isResolved={isResolved}
                  onDropHandler={onDrop}
                  itemData={itemInThatBox}
                  ItemComponent={ItemComponent}
                />
              )}
              <div
                style={{
                  height: '0px',
                  overflow: 'hidden'
                }}
              >
                <ItemComponent data={itemInThatBox.data} />
              </div>
            </div>
          );
        })}
      </WhiteBox>
    </div>
  );
};

BoardWithDraggableItems.propTypes = {
  boxesCount: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  refs: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired
};

export default DropTarget(itemType, boardTarget, collect)(
  BoardWithDraggableItems
);
