export default {
  fontSize: [12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 60, 72],
  animationSpeed: [
    { value: "slow", label: "Slow", stringNumber: 246 },
    { value: "medium", label: "Medium", stringNumber: 130 },
    { value: "fast", label: "Fast", stringNumber: 131 }
  ],
  showAnswer: [
    { label: "n/a", value: "n/a", stringNumber: 163 },
    "1",
    "2",
    "3",
    "4",
    "5"
  ],
  delayBeforeNext: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  fontFamily: [
    "Arial",
    "Arial Black",
    "Bookman",
    "Comic Sans MS",
    "Courier",
    "Courier New",
    "Garamond",
    "Georgia",
    "Helvetica",
    "Impact",
    "Palatino",
    "Roboto",
    "Times New Roman",
    "Times",
    "Trebuchet MS",
    "Verdana"
  ],
  questionsLimit: [
    { label: "no limit", value: 0, stringNumber: 230 },
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    15,
    20
  ]
};
