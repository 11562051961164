import { axiosAPI, responseHandler } from 'utils';

export default {
  getReports(params) {
    return responseHandler(axiosAPI.post(`api/reports`, params));
  },
  getMasteryAndUsageReport(params) {
    return responseHandler(
      axiosAPI.post(`api/reports/mastery-and-usage`, params)
    );
  },
  getFamilyReport(params) {
    return responseHandler(axiosAPI.post(`api/reports/family`, params));
  },
  geByPurchaseConfig(id, params) {
    return responseHandler(
      axiosAPI.get(`api/reports/by-purchase-config/${id}`, { params })
    );
  },
  getRevenueReport(params) {
    return responseHandler(axiosAPI.get('api/reports/revenue', { params }));
  },
  getTranslatorWorkReport(params) {
    return responseHandler(axiosAPI.get('api/reports/translator-work', { params }));
  },
  getFinancialReport(params) {
    return responseHandler(axiosAPI.get('api/reports/financial', { params }));
  },
  byReportGroup(reportGroupId, params) {
    return responseHandler(
      axiosAPI.post(`api/reports/by-report-group/${reportGroupId}`, params)
    );
  },
  byFamily(familyId, params) {
    return responseHandler(
      axiosAPI.get(`api/reports/by-family/${familyId}`, { params })
    );
  },
  getDataExportOptions() {
    return responseHandler(axiosAPI.get('api/reports/data-export/get-options'));
  },
  getPaginatedDataExportReports(params) {
    return responseHandler(axiosAPI.get('api/reports/data-export', { params }));
  },
  getDataExportReportById(id) {
    return responseHandler(axiosAPI.get(`api/reports/data-export/${id}`));
  },
  createDataExportReport(values) {
    return responseHandler(axiosAPI.post('api/reports/data-export', values));
  },
  updateDataExportReport(id, values) {
    return responseHandler(
      axiosAPI.put(`api/reports/data-export/${id}`, values)
    );
  },
  deleteDataExportReport(id) {
    return responseHandler(axiosAPI.delete(`api/reports/data-export/${id}`));
  },
  async downloadDataExportCsv(id, filename) {
    try {
      const response = await axiosAPI.get(`api/reports/data-export/${id}/csv`, {
        responseType: 'blob',
      });

      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename || 'file.csv');
      document.body.appendChild(link);
      link.click();

      // Clean-up
      link.remove();
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
    }
  },
};
