import * as React from "react";

// redux
import { connect } from "react-redux";

// components
import { Portal } from "components/common";
import { GameModal } from "components/flink-play";
import HistoryRecord from "./HistoryRecord";

// classes
import classes from "./History.module.scss";

// actions
import { closeFamilyPointsHistory } from "actions/flinkPlayActions";
import { showInfo } from "actions/statusActions";

const FamilyPointsHistory = (props) => {
    const { 
        translate, 
        modalOpened, 
        closeFamilyPointsHistory, 
        showInfo,
        history,
    } = props;

    const title = React.useMemo(() => translate(-1, 'Family Points History (newest to oldest)'), [translate]);
    const buttons = React.useMemo(() => {
        return [{ 
            title: translate(80, 'Close'), 
            clickHandler: closeFamilyPointsHistory,
        }];
    }, [translate, closeFamilyPointsHistory]);

    return (
        <Portal>
            {modalOpened && (
            <GameModal title={title} buttons={buttons}>
                <div className={classes.modalBody}>
                    {history.map(r => (
                        <HistoryRecord 
                            key={r._id} 
                            record={r}
                            showInfo={showInfo} 
                        />
                    ))}
                </div>
            </GameModal>)}
        </Portal>
    );
}

const mapStateToProps = ({
    flinkPlay: { 
        solutionTranslate, 
        familyPointsHistory: { open, history },
    },
    status: { translate },
}) => ({
    translate: solutionTranslate || translate,
    modalOpened: open,
    history: history,
});

export default connect(mapStateToProps, {
    closeFamilyPointsHistory,
    showInfo,
})(FamilyPointsHistory);
