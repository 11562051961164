/* eslint eqeqeq: 0 */

import _ from 'lodash';
import { convert } from 'activity-templates/utils';

import { defaultData } from './storyStudioHelpers';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {};

  const { gameData, helpHtml, helpAudio, oldOptions } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const { ImageSets = [], TextSets = [] } = gameData;

  const images = _.chain(ImageSets)
    .map((val) => (val && val.Images) || [])
    .flatten()
    .compact()
    .value();

  const words = _.chain(TextSets)
    .map((val) => (val && val.Text) || [])
    .flatten()
    .compact()
    .take(10)
    .value();

  result.gameData = { ...defaultData, images, words };

  const editorOptions = {};

  const {
    FontType = [],
    FontSize = [],
    FontColor = [],
    FontStyle = [],
    Alignment = [],
    Features = [],
    Pages,
  } = oldOptions;

  // Fonts
  editorOptions.fonts = _.chain(FontType)
    .map((v) => {
      switch (v) {
        case 'Arial':
        case 'Arial Black':
        case 'Courier New':
        case 'Helvetica':
        case 'Georgia':
          return v;
        default:
          return null;
      }
    })
    .compact()
    .uniq()
    .value();

  if (!editorOptions.fonts.length) {
    editorOptions.fonts = ['Arial'];
  }

  // font sizes
  editorOptions.fontSizes = _.chain(FontSize)
    .map((f) => {
      if (f == '1' || f == '2') return 'normal';
      if (f == '3' || f == '4') return 'h1';
      if (f == '5' || f == '6') return 'h2';
      if (f == '7') return 'h3';
      return null;
    })
    .compact()
    .uniq()
    .value();

  if (!editorOptions.fontSizes.length) {
    editorOptions.fontSizes = ['normal'];
  }

  // Font colors
  editorOptions.fontColors = _.chain(FontColor)
    .map((v) => {
      switch (v) {
        case 'Black':
        case 'Red':
        case 'Green':
        case 'Blue':
          return v.toLowerCase();
        default:
          return null;
      }
    })
    .compact()
    .uniq()
    .value();

  if (!editorOptions.fontColors.length) {
    editorOptions.fontColors = ['black'];
  }

  // Font styles
  editorOptions.fontStyles = _.chain(FontStyle)
    .map((v) => {
      switch (v) {
        case 'Bold':
        case 'Italic':
        case 'Underline':
          return v.toLowerCase();
        default:
          return null;
      }
    })
    .compact()
    .uniq()
    .value();

  // Font colors
  editorOptions.alignments = _.chain(Alignment)
    .map((v) => {
      switch (v) {
        case 'Left':
        case 'Center':
          return v.toLowerCase();
        default:
          return null;
      }
    })
    .compact()
    .uniq()
    .value();

  if (!editorOptions.alignments.length) {
    editorOptions.alignments = ['left'];
  }

  if (Features.includes('Auto Insert Art')) {
    result.gameData.autoInsertArt = true;
    result.gameData.images = _.take(result.gameData.images, 12);
  } else if (
    Features.includes('Show Clipart Library') ||
    Features.includes('Import Graphics')
  ) {
    result.gameData.importClipart = true;
    result.gameData.images = _.take(result.gameData.images, 8);
  } else {
    result.gameData.autoInsertArt = false;
    result.gameData.importClipart = false;
    result.gameData.images = _.take(result.gameData.images, 8);
  }

  result.gameData.pagesCount = isNaN(Pages)
    ? result.gameData.pagesCount
    : +Pages;

  result.options = { editorOptions };
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
