import { axiosAPI, responseHandler } from 'utils';

export default {
  async fetch(type, params) {
    return responseHandler(axiosAPI.get(`/api/admin/${type}`, { params }));
  },
  async fetchById(type, id) {
    return responseHandler(axiosAPI.get(`/api/admin/${type}/${id}`));
  },
  async fetchList(type, id) {
    return responseHandler(axiosAPI.get(`/api/admin/${type}/list`));
  },
};
