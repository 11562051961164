import _ from "lodash";
import shortid from "shortid";

import { getInstructionsAudioFilesFromProblem } from "activity-templates/utils";
import { isUsingStrings } from "utils";

export const validateItem = item =>
  !!(item && (item.text || item.image || item.audio));

export const validateProblem = problem => {
  return !!(
    problem.correctAnswers &&
    problem.correctAnswers.length &&
    _.filter(problem.correctAnswers, item => validateItem(item)).length &&
    problem.incorrectAnswers &&
    problem.incorrectAnswers.length &&
    _.filter(problem.incorrectAnswers, item => validateItem(item)).length
  );
};

export const createProblem = () => ({
  id: shortid.generate(),
  questions: [],
  correctAnswers: [],
  incorrectAnswers: [],
  fontColor: "#000",
  fontFamily: "Arial",
  answerBelongs: true,
  correctItemsShown: 2,
  incorrectItemsShown: 2
});

export const getContentFilesFromItem = item => {
  const files = [];
  if (!item) return files;

  const { text, image, audio } = item;
  !isUsingStrings(text) && image && files.push(image);
  !isUsingStrings(text) && audio && files.push(audio);

  return files;
};

export const getContentFilesFromProblem = problem => {
  let files = [];
  const { questions, correctAnswers, incorrectAnswers } = problem;

  const instructionsAudioFiles = getInstructionsAudioFilesFromProblem(problem);
  files.push(...instructionsAudioFiles);

  _.concat(questions, correctAnswers, incorrectAnswers).forEach(item => {
    files = _.concat(files, getContentFilesFromItem(item));
  });
  console.log(files);
  return files;
};

export const prepareProblem = mapper => problem => ({
  ...problem,
  questions: problem.questions?.map(mapper),
  correctAnswers: problem.correctAnswers?.map(mapper),
  incorrectAnswers: problem.incorrectAnswers?.map(mapper),
});
