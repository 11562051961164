import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { WhiteBox } from 'components/flink-play';
import ItemSlot from './ItemSlot/ItemSlot';
import classes from './ItemSlots.module.scss';

const ItemSlots = ({
  refs,
  count,
  items,
  options,
  animation,
  slotClassname,
  isResolved,
  joinOnResolve,
  onDrop,
  animationSpeed,
}) => {
  const { delayBeforeNext } = options;

  const delayBeforeExit = delayBeforeNext * 1000 - 1000;

  const sets = Array.isArray(count) ? count : [count];

  return (
    <div className={classes.positionedBox}>
      <WhiteBox outerClass={classes.wrapper} innerClass={classes.innerWrapper}>
        {sets.map((count, idx) => {
          const offset = sets.slice(0, idx).reduce((offset, current) => {
            offset += current;
            return offset;
          }, 0);

          return (
            <div className={classes.set} key={idx}>
              {_.times(count, (idx) => {
                const itemInThatBox = _.find(items, { slotIdx: offset + idx });
                const isBreaker = itemInThatBox && itemInThatBox.breaker;

                if (isBreaker) return null;

                return (
                  <CSSTransition
                    appear
                    mountOnEnter
                    in={!isResolved}
                    key={idx}
                    timeout={{
                      appear: animationSpeed,
                      enter: animationSpeed,
                      exit: animationSpeed + delayBeforeExit,
                    }}
                    classNames={{
                      appearActive: animation.in,
                      enterActive: animation.in,
                      exitActive: animation.out,
                    }}
                  >
                    {(state) => (
                      <div
                        className={classnames('animated')}
                        style={{
                          animationDelay:
                            (state === 'exiting' ? delayBeforeExit : 0) + 'ms',
                          animationDuration: animationSpeed + 'ms',
                        }}
                      >
                        <ItemSlot
                          first={idx === 0}
                          last={idx === count - 1}
                          isResolved={isResolved}
                          joinOnResolve={joinOnResolve}
                          slotRef={refs[offset + idx]}
                          className={classnames(slotClassname, {
                            [classes.fadeIn]: state === 'entering',
                            [classes.fadeOut]:
                              state === 'exiting' || state === 'exited',
                          })}
                          onDropHandler={onDrop}
                          key={idx}
                          item={itemInThatBox}
                          slotIdx={offset + idx}
                        />
                      </div>
                    )}
                  </CSSTransition>
                );
              })}
            </div>
          );
        })}
      </WhiteBox>
    </div>
  );
};

ItemSlots.propTypes = {
  items: PropTypes.array.isRequired,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
  onDrop: PropTypes.func.isRequired,
  slotClassname: PropTypes.string.isRequired,
  joinOnResolve: PropTypes.bool,
};

export default ItemSlots;
