import React, { Fragment } from 'react';

import { RecordAudio, generateNumber } from 'utils';
import { imagesURL } from 'config';

const Button = ({ title, text, image, clickHandler }) => (
  <button title={title} type="button" className="btn-effect" onClick={clickHandler}>
    <img src={`${imagesURL}/${image}`} alt={title} width={32} height={32} />
    {text}
  </button>
);

const _Record = ({ addRecordedFileHandler, translate }) => {
  return (
    <RecordAudio>
      {({
        recorder,
        processing,
        isRecording,
        isPlaying,
        audioBlob,
        stopRecordHandler,
        startRecordHandler,
        stopPlayHandler,
        playHandler
      }) => {
        return (
          <div>
            {recorder ? (
              <Fragment>
                {isRecording ? (
                  <Button
                    disabled={processing}
                    title={translate(497, 'Stop Record', true)}
                    text={translate(497, 'Stop Record')}
                    image="FrontendUtilities/stop1.png"
                    clickHandler={stopRecordHandler}
                  />
                ) : audioBlob ? (
                  <Button
                    title={translate(442, 'Restart', true)}
                    text={translate(442, 'Restart')}
                    image="FlinkMake/undodelete.png"
                    clickHandler={startRecordHandler}
                  />
                ) : (
                  <Button
                    title={translate(496, 'Start Record', true)}
                    text={translate(496, 'Start Record')}
                    image="FrontendUtilities/Recorder/microphone.png"
                    clickHandler={startRecordHandler}
                  />
                )}

                {audioBlob && (
                  <Fragment>
                    {isPlaying ? (
                      <Button
                        title={translate(515, 'Stop Play', true)}
                        text={translate(515, 'Stop Play')}
                        image="FrontendUtilities/stop1.png"
                        clickHandler={() => stopPlayHandler()}
                      />
                    ) : (
                      <Button
                        title={translate(514, 'Play', true)}
                        text={translate(514, 'Play')}
                        image="Images/Audio/audio_make.png"
                        clickHandler={() => playHandler()}
                      />
                    )}
                    <Button
                      title={translate(447, 'Use Recorded File', true)}
                      text={translate(447, 'Use Recorded File')}
                      image="FlinkMake/add.png"
                      clickHandler={() =>
                        addRecordedFileHandler(
                          new File(
                            [audioBlob],
                            `record_${generateNumber(5)}.mp3`
                          )
                        )
                      }
                    />
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <p>{translate(445, 'Microphone not found')}</p>
            )}
          </div>
        );
      }}
    </RecordAudio>
  );
};

export default _Record;
