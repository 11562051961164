import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import API from 'api';
import SettingsButton from '../SettingsButton/SettingsButton';
import classes from './SettingsManagePurchase.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showPromptPromise, showError, showInfo } from 'actions/statusActions';
import { logout } from 'actions/authActions';

const SettingsManagePurchase = ({
  logout,
  translate,
  productId,
  learningCenterId,
  familyPurchases,
  showInfo,
  showError,
  showPromptPromise,
}) => {
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(true);
  const [purchaseInfo, setPurchaseInfo] = useState(null);
  const [error, setError] = useState('');

  // Get current purchase
  const purchase = useMemo(() => {
    return _.find(familyPurchases, {
      product: productId,
      learningCenter: learningCenterId,
    });
  }, [productId, learningCenterId, familyPurchases]);

  const retryPastDueTransaction = useCallback(async () => {
    // Cancel Subscription
    setProcessing(true);
    const result = await API.purchase.retryPastDueTransaction(purchase._id);
    setProcessing(false);

    if (result.success) {
      setPurchaseInfo(result.data.purchaseInfo);
    } else if (result.message) {
      window.Modals.showError({ message: result.message });
    }
  }, [purchase._id]);

  const cancelLevelSubscription = useCallback(async () => {
    const isConfirmed = await window.Modals.showConfirm(
      translate(759, 'You sure you want to cancel subscription?', true)
    );

    if (!isConfirmed) return;

    // Cancel Subscription
    setProcessing(true);
    const result = await API.purchase.cancelSubscription(purchase._id);
    setProcessing(false);

    if (result.success) {
      setPurchaseInfo({ ...purchaseInfo, status: 'Canceled' });
    }
  }, [purchaseInfo, purchase, translate]);

  const cancelSubscription = useCallback(async () => {
    const isConfirmed = await window.Modals.showConfirm({
      message: translate(
        770,
        'If you cancel now, before having paid all installments, you account will be blocked.'
      ),
    });

    if (!isConfirmed) return;

    // Cancel Subscription
    setProcessing(true);
    const result = await API.purchase.cancelSubscription(purchase._id);
    setProcessing(false);

    if (result.success) {
      // Account deleted -> logout
      dispatch(logout());
    }
  }, [dispatch, purchase, logout, translate]);

  const resumeSubscription = useCallback(() => {
    const isConfirmed = window.confirm(
      translate(760, 'Resume subscription?', true)
    );
    if (!isConfirmed) return;

    // Resume Subscription
    setProcessing(true);

    API.purchase.resumeSubscription(purchase._id).then((result) => {
      setProcessing(false);
      result.success && setPurchaseInfo(result.data.purchaseInfo);
    });
  }, [purchase, translate]);

  const requestRefund = useCallback(async () => {
    const requestMessage = await showPromptPromise({
      textarea: true,
      translate,
      message: translate(761, 'Tell us why you want to refund?'),
      html: true,
    });

    if (!requestMessage) return;

    setProcessing(true);

    const showErrorMessage = () => {
      showError({
        html: true,
        message: translate(
          0,
          'Error. Please try again or send us email at <a href="mailto:support@familylearningcompany.com">support@familylearningcompany.com</a>"'
        ),
      });
    };

    API.purchase
      .requestRefund(purchase._id, requestMessage)
      .then((result) => {
        setProcessing(false);

        if (result.success) {
          showInfo({
            message: translate(
              762,
              'You request was sent. We will consider it soon.'
            ),
          });
        } else {
          showErrorMessage();
        }

        // result.success && setPurchaseInfo(result.data.purchaseInfo);
      })
      .catch((err) => {
        showErrorMessage();
      });
  }, [showInfo, showError, showPromptPromise, translate, purchase]);

  // Get purchase info
  useEffect(() => {
    if (!purchase) return;

    API.purchase.getPurchaseInfo(purchase._id).then((result) => {
      console.log(result);
      setProcessing(false);

      if (result.success) {
        setPurchaseInfo(result.data.purchaseInfo);
      } else {
        setError("Can't fetch purchase info.");
      }
    });
  }, [purchase]);

  if (!purchase) {
    return <p>Purchase not found.</p>;
  }

  let content;

  if (purchase.isLevelSubscription) {
    content = (
      <ManageLevelSubscription
        data={purchaseInfo}
        translate={translate}
        retryHandler={retryPastDueTransaction}
        resumeHandler={resumeSubscription}
        cancelHandler={cancelLevelSubscription}
      />
    );
  } else if (purchase.isSubscription) {
    content = (
      <ManageSubscription
        data={purchaseInfo}
        translate={translate}
        resumeHandler={resumeSubscription}
        cancelHandler={cancelSubscription}
      />
    );
  } else {
    content = (
      <ManageEntireProductPurchase
        data={purchaseInfo}
        free={purchase.paymentServiceName === 'free'}
        refundHandler={requestRefund}
        translate={translate}
      />
    );
  }

  if (processing) {
    content = <CircularProgress />;
  } else {
  }

  return (
    <div className={classes.wrapper}>
      <h4>{translate(852, 'Manage')}</h4>

      {processing ? <CircularProgress /> : content}

      {error && <p>{error}</p>}
    </div>
  );
};

const ManageEntireProductPurchase = ({
  free,
  data,
  refundHandler,
  translate,
}) => {
  if (!data) return null;

  const { active } = data;

  // const isActive = status === 'Active' || status === 'Pending';
  // const isExpired = status === 'Expired';
  // const isCanceled = status === 'Canceled';
  // const isPastDue = status === 'Past Due';

  return (
    <>
      <table>
        <tbody>
          <tr>
            <th>{translate(747, 'Status')}</th>
            <td>{translate(750, 'Entire Product')}</td>
          </tr>
        </tbody>
      </table>

      {active && !free && (
        <SettingsButton onClick={refundHandler}>
          {translate(755, 'Refund')}
        </SettingsButton>
      )}
    </>
  );
};

const ManageLevelSubscription = ({
  data,
  translate,
  retryHandler,
  cancelHandler,
  resumeHandler,
  disabled,
}) => {
  if (!data) return null;

  const {
    status,
    nextBillAmount,
    nextBillingDate,
    availableLevels,
    levelsLeft,
  } = data;

  const isActive = status === 'Active' || status === 'Pending';
  const isExpired = status === 'Expired';
  const isCanceled = status === 'Canceled';
  const isPastDue = status === 'Past Due';

  let statusTranslateNumber = 0;

  if (isActive) statusTranslateNumber = 748;
  if (isCanceled) statusTranslateNumber = 749;

  return (
    <>
      <table>
        <tbody>
          <tr>
            <th>{translate(747, 'Status')}</th>
            <td>
              <div className={classes.statusCell}>
                {translate(statusTranslateNumber, status)}{' '}
                {isPastDue && (
                  <button disabled={disabled} onClick={retryHandler}>
                    {translate(0, 'Retry')}
                  </button>
                )}
              </div>
            </td>
          </tr>

          <tr>
            <th>{translate(757, 'Purchased Levels')}</th>
            <td>{availableLevels.join(', ')}</td>
          </tr>

          <tr>
            <th>{translate(758, 'Levels Left')}</th>
            <td>{levelsLeft.join(', ')}</td>
          </tr>

          {(isActive || isPastDue) && (
            <>
              <tr>
                <th>{translate(751, 'Next Charge Date')}</th>
                <td>
                  {nextBillingDate &&
                    new Date(nextBillingDate).toLocaleDateString()}
                </td>
              </tr>

              <tr>
                <th>{translate(752, 'Next Charge')}</th>
                <td>${nextBillAmount}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      {(isActive || isPastDue) && (
        <SettingsButton disabled={disabled} onClick={cancelHandler}>
          {translate(756, 'Cancel Account')}
        </SettingsButton>
      )}

      {isCanceled && (
        <>
          <p>
            {translate(
              753,
              'You can use your current levels. If you want additional levels, click the Buy Level button below.'
            )}
          </p>

          <SettingsButton disabled={disabled} onClick={resumeHandler}>
            {translate(754, 'Resume')}
          </SettingsButton>
        </>
      )}

      {isExpired && levelsLeft.length === 0 && 'You have purchased all levels.'}
    </>
  );
};

const ManageSubscription = ({ data, translate, cancelHandler, disabled }) => {
  if (!data) return null;

  const {
    paid,
    status,
    nextBillAmount,
    nextBillingDate,
    successfulTransactionsNumber,
    numberOfBillingCycles,
  } = data;

  const isActive = status === 'Active' || status === 'Pending';
  const isExpired = status === 'Expired';
  const isPastDue = status === 'Past Due';

  const formattedStatus = paid
    ? translate(775, 'Permanent Access')
    : translate(773, 'Installment Plan');

  return (
    <>
      <table>
        <tbody>
          <tr>
            <th>{translate(747, 'Status')}</th>
            <td>{formattedStatus}</td>
          </tr>

          {(isActive || isPastDue) && (
            <>
              <tr>
                <th>{translate(774, 'Payments made')}</th>
                <td>
                  {successfulTransactionsNumber} {translate(293, 'of')}{' '}
                  {numberOfBillingCycles}
                </td>
              </tr>

              <tr>
                <th>{translate(751, 'Next Charge Date')}</th>
                <td>
                  {nextBillingDate &&
                    new Date(nextBillingDate).toLocaleDateString()}
                </td>
              </tr>

              <tr>
                <th>{translate(752, 'Next Charge')}</th>
                <td>${nextBillAmount}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      {isActive && (
        <SettingsButton disabled={disabled} onClick={cancelHandler}>
          {translate(756, 'Cancel Account')}
        </SettingsButton>
      )}

      {isExpired && paid && 'You have purchased all levels.'}
    </>
  );
};

SettingsManagePurchase.propTypes = {
  logout: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  showPromptPromise: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  familyPurchases: PropTypes.array.isRequired,
  productId: PropTypes.string.isRequired,
  learningCenterId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ flinkPlay }) => ({
  familyPurchases: flinkPlay.family.purchases,
  productId: flinkPlay.product._id,
  learningCenterId: flinkPlay.learningCenter._id,
});

export default connect(mapStateToProps, {
  logout,
  showPromptPromise,
  showError,
  showInfo,
})(SettingsManagePurchase);
