/* eslint eqeqeq: 0 */

import _ from 'lodash';
import shortid from 'shortid';
import {
  convert,
  findFileEndWith,
  getStringValue,
} from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {
    ProblemOrder: 1,
    ShowAnswer: 2,
    LengthOfTime: 3,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  let oldTemplate = null;

  findFileEndWith(s3FilesList, '/AllTogether.xml') &&
    (oldTemplate = 'allTogether');
  findFileEndWith(s3FilesList, '/BigPicture.xml') &&
    (oldTemplate = 'bigPicture');

  const {
    Worksheet: { SegmentData },
  } = gameData;

  const problems = SegmentData.map((data) => {
    const {
      InstrText,
      FontColor,
      InstrFontName,
      InstrAudio,
      SegmentType,
      GroupData,
    } = data;

    if (oldTemplate === 'allTogether') {
      const [correctGroup, incorrectGroup] = GroupData;

      const answerBelongs = !(SegmentType == '1');

      const correctItemsShown =
        correctGroup.GroupMaxDisplay && !isNaN(+correctGroup.GroupMaxDisplay)
          ? +correctGroup.GroupMaxDisplay
          : 2;

      const incorrectItemsShown =
        incorrectGroup.GroupMaxDisplay &&
        !isNaN(+incorrectGroup.GroupMaxDisplay)
          ? +incorrectGroup.GroupMaxDisplay
          : 2;

      const [correctAnswers, incorrectAnswers] = GroupData.map((group) => {
        return group.ItemData.map(({ ItemType, ItemData, ItemAudio }) => {
          return {
            text: ItemType == 1 ? ItemData : '',
            image: ItemType == 0 ? getStringValue(ItemData) : '',
            audio: getStringValue(ItemAudio),
          };
        });
      });

      return {
        id: shortid.generate(),
        questions: [],
        correctAnswers,
        incorrectAnswers,
        instructionText: InstrText,
        instructionAudio: getStringValue(InstrAudio),
        answerBelongs,
        correctItemsShown,
        incorrectItemsShown,
        fontFamily:
          InstrFontName && InstrFontName != '0' ? InstrFontName : 'Arial',
        fontColor: FontColor && FontColor != '0' ? FontColor : '#000',
      };
    } else if (oldTemplate === 'bigPicture') {
      if (SegmentType == '1') {
        // Problems with "Answer is count only" option enabled, don't support in current project
        return null;
      }
      const [, correctGroup, incorrectGroup] = GroupData;

      const answerBelongs = true;

      const correctItemsShown =
        correctGroup.GroupMaxDisplay && !isNaN(+correctGroup.GroupMaxDisplay)
          ? +correctGroup.GroupMaxDisplay
          : 2;

      const incorrectItemsShown =
        incorrectGroup.GroupMaxDisplay &&
        !isNaN(+incorrectGroup.GroupMaxDisplay)
          ? +incorrectGroup.GroupMaxDisplay
          : 2;

      const [questions, correctAnswers, incorrectAnswers] = GroupData.map(
        (group) => {
          return group.ItemData.map
            ? group.ItemData.map(({ ItemType, ItemData, ItemAudio }) => {
                return {
                  text: ItemType == 1 ? ItemData : '',
                  image: ItemType == 0 && getStringValue(ItemData),
                  audio: getStringValue(ItemAudio),
                };
              })
            : [
                {
                  text:
                    group.ItemData.ItemType == 1 ? group.ItemData.ItemData : '',
                  image:
                    group.ItemData.ItemType == 0 &&
                    getStringValue(group.ItemData.ItemData),
                  audio: getStringValue(group.ItemData.ItemAudio),
                },
              ];
        }
      );

      return {
        id: shortid.generate(),
        questions,
        correctAnswers,
        incorrectAnswers,
        instructionText: InstrText,
        instructionAudio: getStringValue(InstrAudio),
        answerBelongs,
        correctItemsShown,
        incorrectItemsShown,
        fontFamily:
          InstrFontName && InstrFontName != '0' ? InstrFontName : 'Arial',
        fontColor: FontColor && FontColor != '0' ? FontColor : '#000',
      };
    }

    return null;
  });

  result.gameData = { problems: _.compact(problems) };
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
