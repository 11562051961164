import { ClassAttributes, useEffect } from 'react';
import _ from 'lodash';
import { useMachine } from '@xstate/react';
import cs from 'classnames';
import styled from 'styled-components';

import { showStats } from 'actions/gameActions';
import { GO_BACK, HELP, SPEAK, NEXT } from 'consts/buttons';
import {
  toggleAudio,
  playCorrectSound,
  playIncorrectSound,
} from 'actions/audioActions';
import { imagesURL } from 'config';
import { validateProblem } from './ngeliHelpers';
import {
  ActivityButtons,
  WhiteBox,
  ProblemsProgress,
} from 'components/flink-play';
import { NgeliProblem } from './ngeli.types';
import { createNgeliMachine, NgeliStep } from './ngeli.machine';
import { useAppDispatch, useAppSelector } from 'store';
import classes from './Ngeli.module.scss';

type TemplateProps = {
  activity: {
    activity: { _id: string };
    data: {
      gameData: { problems: NgeliProblem[] };
      options: { delayBeforeNext: string; randomOrder: boolean };
    };
  };
  contentFolder: string;
};

const Ngeli = (props: TemplateProps) => {
  const appDispatch = useAppDispatch();
  const { activity, contentFolder } = props;
  const { gameData, options } = activity.data;
  const [state, send] = useMachine(createNgeliMachine, {
    actions: {
      correctAnswerFeedback: () => {
        appDispatch(playCorrectSound());
      },
      incorrectAnswerFeedback: () => {
        appDispatch(playIncorrectSound());
      },
      playSentence: ({ currentProblem }) => {
        if (!currentProblem?.sentenceAudio) return;
        toggleAudio(contentFolder + currentProblem.sentenceAudio);
      },
      showResults: ({ problems, problemsAnsweredCorrectly }) => {
        const showStatsAction = showStats({
          withScore: true,
          data: {
            allProblemsCount: problems.length,
            problemsAnsweredCorrectly: problemsAnsweredCorrectly,
            problemsAnsweredIncorrectly:
              problems.length - problemsAnsweredCorrectly,
          },
        });
        appDispatch(showStatsAction);
      },
    },
    context: {
      delay: isNaN(+options.delayBeforeNext)
        ? 3000
        : +options.delayBeforeNext * 1000,
    },
  });

  useEffect(() => {
    const init = () => {
      let validProblems = gameData.problems.filter(validateProblem);
      if (options.randomOrder) validProblems = _.shuffle(validProblems);
      send('START', { data: validProblems });
    };
    document.addEventListener('startGame', init);
    return () => document.removeEventListener('startGame', init);
  }, [gameData.problems, options.randomOrder, send]);

  const { currentProblem } = state.context;

  const getButtonProps = (ans: string) => {
    const isSelected = ans === state.context.selectedAnswer;
    return {
      key: ans,
      className: cs(classes.button, {
        [classes.selected]: isSelected,
        [classes.correct]: isSelected && state.context.isCorrectAnswer,
        [classes.wrong]: isSelected && !state.context.isCorrectAnswer,
      }),
      onClick: () => send('ANSWER', { data: ans }),
      children: ans,
    };
  };

  const activityButtons = [
    GO_BACK,
    {
      type: NEXT,
      onClick: () => send('NEXT'),
      dontShow: !state.can('NEXT'),
    },
    {
      type: SPEAK,
      onClick: () => send('PLAY_SENTENCE'),
      disabled: !state.matches(NgeliStep.REVIEW),
    },
    HELP,
  ];

  return (
    <>
      <ProblemsProgress
        problemsNumber={state.context.problems.length || 0}
        problemsLeft={
          (state.context.problems.length || 0) - (state.context.currentIdx + 1)
        }
      />

      {currentProblem && !state.matches(NgeliStep.IDLE) && (
        <WhiteBox outerClass={classes.topOuter} innerClass={classes.topInner}>
          {[
            NgeliStep.NOUN_INTRO,
            NgeliStep.NOUN_CHOICE,
            NgeliStep.NOUN_ANSWERED,
          ].some(state.matches) && (
            <>
              <div>{currentProblem.noun.text}</div>
              <Definition
                text={currentProblem.noun.definition}
                image={currentProblem.noun.image}
                audio={currentProblem.noun.audio}
                contentFolder={contentFolder}
              />
            </>
          )}

          {[
            NgeliStep.ADJECTIVE_INTRO,
            NgeliStep.ADJECTIVE_CHOICE,
            NgeliStep.ADJECTIVE_ANSWERED,
          ].some(state.matches) && (
            <>
              <div>
                {currentProblem.noun.text}
              </div>
              <div>{currentProblem.adjective.text}</div>
              <Definition
                text={currentProblem.adjective.definition}
                image={currentProblem.adjective.image}
                audio={currentProblem.adjective.audio}
                contentFolder={contentFolder}
              />
            </>
          )}

          {[
            NgeliStep.VERB_INTRO,
            NgeliStep.VERB_CHOICE,
            NgeliStep.VERB_ANSWERED,
          ].some(state.matches) && (
            <>
              <div>
                {currentProblem.noun.text}
              </div>
              <div>
                {currentProblem.adjective.prefixes[0]}+
                {currentProblem.adjective.text}
              </div>
              <div>{currentProblem.verb.text}</div>
              <Definition
                text={currentProblem.verb.definition}
                image={currentProblem.verb.image}
                audio={currentProblem.verb.audio}
                contentFolder={contentFolder}
              />
            </>
          )}

          {[NgeliStep.REVIEW].some(state.matches) && (
            <>
              <div>
                {currentProblem.noun.text}
              </div>
              <div>
                {currentProblem.adjective.prefixes[0]}+
                {currentProblem.adjective.text}
              </div>
              <div>
                {currentProblem.verb.prefixes[0]}+{currentProblem.verb.text}
              </div>
            </>
          )}
        </WhiteBox>
      )}

      {[NgeliStep.NOUN_CHOICE, NgeliStep.NOUN_ANSWERED].some(state.matches) && (
        <AnswersCols
          buttonProps={getButtonProps}
          cols={_.chunk(
            state.context.nounAnswers,
            Math.ceil((currentProblem?.noun.categories.length || 0) / 2)
          )}
        />
      )}

      {[NgeliStep.ADJECTIVE_CHOICE, NgeliStep.ADJECTIVE_ANSWERED].some(
        state.matches
      ) && (
        <AnswersCols
          buttonProps={getButtonProps}
          cols={_.chunk(
            state.context.adjectiveAnswers,
            Math.ceil((currentProblem?.adjective.prefixes.length || 0) / 2)
          )}
        />
      )}

      {[NgeliStep.VERB_CHOICE, NgeliStep.VERB_ANSWERED].some(state.matches) && (
        <AnswersCols
          buttonProps={getButtonProps}
          cols={_.chunk(
            state.context.verbAnswers,
            Math.ceil((currentProblem?.verb.prefixes.length || 0) / 2)
          )}
        />
      )}

      <ActivityButtons buttons={activityButtons} />
    </>
  );
};

export default Ngeli;

const Definition = ({
  text,
  image,
  audio,
  contentFolder,
}: {
  text: string;
  image: string;
  audio: string;
  contentFolder: string;
}) => {
  return (
    <StyledDefinitionWrapper>
      {image ? (
        <StyledDefinitionImage src={contentFolder + image} alt="" />
      ) : (
        <div>{text}</div>
      )}

      {audio && (
        <StyledAudioButtonDefPosition>
          <PlayAudioButton src={contentFolder + audio} />
        </StyledAudioButtonDefPosition>
      )}
    </StyledDefinitionWrapper>
  );
};

const PlayAudioButton = ({ src }: { src: string }) => {
  const audioStore = useAppSelector((state) => state.audio);

  return (
    <PlayAudioButtonStyled
      size="2.5vmin"
      // disabled={isResolved || state === 'entering'}
      onClick={() => toggleAudio(src)}
    >
      <img
        src={`${imagesURL}/Images/Audio/${
          audioStore.audioSrc === src && audioStore.isPlaying
            ? 'audiostop'
            : 'audio_icon'
        }.png`}
        alt=""
      />
    </PlayAudioButtonStyled>
  );
};

const AnswersCols = ({
  cols,
  buttonProps,
}: {
  cols: string[][];
  buttonProps: (ans: string) => ClassAttributes<HTMLButtonElement>;
}) => (
  <WhiteBox outerClass={classes.mainOuter} innerClass={classes.mainInner}>
    {cols.map((chunk, idx) => (
      <div key={idx} className={classes.answersCol}>
        {chunk.map((c) => (
          <button {...buttonProps(c)} />
        ))}
      </div>
    ))}
  </WhiteBox>
);

const StyledDefinitionWrapper = styled.div`
  position: relative;
`;

const StyledDefinitionImage = styled.img`
  width: 6vmin;
  display: block;
`;

const StyledAudioButtonDefPosition = styled.div`
  position: absolute;
  bottom: 0;
  right: -1vmin;
  transform: translateX(100%);
`;

const PlayAudioButtonStyled = styled.button<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: block;

  img {
    display: block;
  }
`;
