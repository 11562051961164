import * as React from "react";

// classes
import classes from "./HistoryRecord.module.scss";

// classnames
import classNames from "classnames";

const HistoryRecord = ({ record, showInfo }) => {
    const formattedDelta = record.delta > 0 ? `+${record.delta}` : record.delta;
    const date = new Date(Date.parse(record.eventDate)).toLocaleString();

    const showAdvanced = React.useCallback(() => {
        const { pointsAmountAfterEvent, cumulativePointsAfterEvent } = record;
        const message = [
            `Points balance after event: <b>${pointsAmountAfterEvent}</b>`,
            `<br>`,
            `Total points after event: <b>${cumulativePointsAfterEvent}</b>`
        ].join('\n');

        showInfo({ message, html: true });
    }, [showInfo, record]);

    const colored = classNames(classes.colored, {
        [classes.positive]: record.delta >= 0,
        [classes.negative]: record.delta < 0,
    });

    return (
        <div className={classes.record}>
            <div className={classes.column}>
                <div className={colored}>{date}</div>
                <div className={colored}>{formattedDelta}</div>
            </div>
            <button className={colored} onClick={showAdvanced}>
                ?
            </button>
            <div className={classes.description}>
                {record.description}
            </div>
        </div>
    );
};

export default HistoryRecord;
