import storageAPI from './storageAPI';

import { usersKey, filesURL } from 'config';

export default {
  saveLearnerActivityData({ learnerId, activityId, data }) {
    return this.uploadToLearnerActivityAssets({
      learnerId,
      activityId,
      files: [{ filename: 'data.json', file: JSON.stringify(data) }],
    });
  },
  getLearnerActivityData({ learnerId, activityId }) {
    const key = `${usersKey}/${learnerId}/${activityId}/data.json`;

    return storageAPI.getFile(key, true).then((data) => {
      return data || null;
    });
  },
  getLearnerActivityAssetsList({ learnerId, activityId }) {
    const key = `${usersKey}/${learnerId}/${activityId}/`;

    return storageAPI.listFiles(key, true).then((data) => {
      return data.map((d) => d.unprefixed);
    });
  },
  uploadToLearnerActivityAssets({ learnerId, activityId, files = [] }) {
    const formatParams = (learnerId, f) => ({
      key: `${usersKey}/${learnerId}/${activityId}/${f.filename}`,
      file: f.file,
    });

    let array = [];

    if (Array.isArray(learnerId)) {
      learnerId.forEach((learnerId) => {
        array.push(...files.map((f) => formatParams(learnerId, f)));
      });
    } else {
      array = files.map((f) => formatParams(learnerId, f));
    }

    return storageAPI.uploadFiles(array).then((results) => {
      let allSuccess = true;

      results.forEach((success, idx) => {
        if (success) return;

        console.log(
          'Error uploading to learner activity assets folder',
          array[idx]
        );

        allSuccess = false;
      });

      return allSuccess;
    });
  },
  copyToLearnerActivityFolder({ learnerId, activityId, array = [] }) {
    // Array of { srcKey: String, uploadBucket: Boolean, filename: String }
    const formatParams = (learnerId, { filename, srcKey, uploadBucket }) => {
      return {
        src: { uploadBucket, key: srcKey },
        dest: {
          uploadBucket: true,
          key: `${usersKey}/${learnerId}/${activityId}/${filename}`,
        },
      };
    };

    let files = [];

    if (Array.isArray(learnerId)) {
      learnerId.forEach((learnerId) => {
        files.push(...array.map((f) => formatParams(learnerId, f)));
      });
    } else {
      files = array.map((f) => formatParams(learnerId, f));
    }

    return storageAPI.copyFiles(files).then((results) => {
      console.log(results);
      return results;
    });
  },
  deleteLearnerActivityFolder({ learnerId, activityId }) {
    return this.getLearnerActivityAssetsList({ learnerId, activityId }).then(
      (filenames) =>
        this.deleteLearnerActivityAssets({
          learnerId,
          activityId,
          filenames,
        })
    );
  },
  deleteLearnerActivityAssets({ learnerId, activityId, filenames = [] }) {
    let keys = [];

    if (Array.isArray(learnerId)) {
      learnerId.forEach((learnerId) => {
        keys.push(
          ...filenames.map((f) => `${usersKey}/${learnerId}/${activityId}/${f}`)
        );
      });
    } else {
      keys = filenames.map(
        (f) => `${usersKey}/${learnerId}/${activityId}/${f}`
      );
    }

    return storageAPI.deleteFiles(keys).then((success) => {
      if (!success) {
        console.log("Error deleting learner's assets");
      }

      return success;
    });
  },
  async getApplauseAudioFiles(localeName) {
    const key = `Audio/Generic/ActivityResults/${localeName}/`;

    const list = await storageAPI.listFiles(key);

    return list.map((f) => `${filesURL}/${f.key}`);
  },
};
