import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

import { MaterialFaIcon } from 'utils';
import { withTranslate } from 'components/hocs';

const styles = (theme) => ({
  clearButton: {
    marginTop: '2rem',
  },
  submitButton: {
    marginTop: '2rem',
    color: '#fff',
    borderColor: '#5cb85c',
    backgroundColor: '#5cb85c',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
});

const FormButtons = ({
  translate,
  pristine,
  submitting,
  alignRight,
  invalid,
  handleReset,
  classes,
}) => {
  return (
    <ButtonsWraper alignRight={alignRight}>
      <Button
        disabled={pristine || submitting || invalid}
        className={classes.submitButton}
        color="secondary"
        variant="contained"
        type="submit"
        size="medium"
      >
        <MaterialFaIcon icon="save" className={classes.leftIcon} />
        {translate(298, 'Save')}
      </Button>

      <Button
        disabled={pristine || submitting}
        className={classes.clearButton}
        color="primary"
        variant="contained"
        size="medium"
        onClick={() => handleReset()}
        type="button"
      >
        <MaterialFaIcon icon="eraser" className={classes.leftIcon} />
        {translate(664, 'Clear')}
      </Button>
    </ButtonsWraper>
  );
};

FormButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default compose(withTranslate, withStyles(styles))(FormButtons);

const ButtonsWraper = styled.div`
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')}
  > *:first-child {
    margin-right: 2rem;
  }
`;
