import { startRequest, endRequest } from 'actions/statusActions';
import shortid from 'shortid';

const withLoading = async (dispatch, cb) => {
  const requestId = shortid.generate();

  dispatch(startRequest(requestId));

  try {
    const res = await cb();

    return res;
  } finally {
    dispatch(endRequest(requestId));
  }
};

export default withLoading;
