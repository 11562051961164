import { axiosAPI as axios, withLoading } from 'utils';

import { SET_COMMON_DATA, UPDATE_CONFIG } from './types';
import API from 'api';

const filter = 'common';

export const getCommonData = () => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get('/api/common/all');

      dispatch({
        filter,
        type: SET_COMMON_DATA,
        payload: res.data,
      });

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  });

export const updateConfig = (values) => async (dispatch) => {
  const result = await API.config.update(values);

  if (result.success) {
    dispatch({
      filter,
      type: UPDATE_CONFIG,
      payload: result.data,
    });
  }

  return result;
};

export const viewLoginPageDocument = (key, localeCode, documentId) => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(`/api/config/login-page-documents`, {
        params: {
          key,
          localeCode,
          documentId,
        },
      });
      window.open(res.data);
    } catch (err) {
      console.log(err);
    }
  });

export const uploadFiles = (data) => (dispatch) =>
  withLoading(dispatch, () => API.storage.uploadFiles(data));

export const uploadFile = (key, file) => (dispatch) =>
  withLoading(dispatch, () => API.storage.uploadFile(key, file));

export const deleteFiles = (keys) => (dispatch) =>
  withLoading(dispatch, () => API.storage.deleteFiles(keys));

export const getMasterOrganizationById = (id) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(
        `/api/common/get-master-organization-by-id/${id}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  });
