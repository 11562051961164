import _ from 'lodash';
import store from 'store';

export default (requiredFields, customValidations) => (values) => {
  const errors = {};

  const {
    status: { translate },
  } = store.getState();

  const requiredTranslate = translate(695, 'Required');

  requiredFields.forEach((field) => {
    const val = _.get(values, field);

    if (!val) {
      _.set(errors, field, requiredTranslate);
    }
  });

  if (customValidations) {
    Object.entries(customValidations).forEach(([propName, validators]) => {
      validators = Array.isArray(validators) ? validators : [validators];
      const val = _.get(values, propName);

      validators.forEach((fn) => {
        const error = fn(val, values);
        if (error && !_.get(errors, propName)) {
          _.set(errors, propName, error);
        }
      });
    });
  }

  return errors;
};
