import { validateProblem } from './concentrationHelpers';

export default ({ options, gameData }) => {
  if (!options || !gameData) return false;

  const { matchTypes, problems, cardsPerLevel } = gameData;

  if (!matchTypes || !problems || !cardsPerLevel) return false;

  const validCards = problems.filter(validateProblem.bind(null, matchTypes));
  if (validCards.length < 6) return false;

  const matchTypesCount = matchTypes.filter(m => !!m.type);
  if (matchTypesCount < 2) return false;

  return true;
};
