import Concentration from './Concentration';
import {
  validateWords, // if needed for wordlists
  generateActivityFromWords // if needed for wordlists
} from './concentrationHelpers';
import convertOldActivity from './convertOldConcentration'; // if needed for converting old activities
import validateActivity from './validateConcentration';

export default {
  Template: Concentration,
  convertOldActivity, // if needed for converting old activities
  validateActivity,
  optionsArray: ['fontFamily', 'fontColor'],
  generateActivityFromWords, // if needed to generate from wordlists
  validateWords // if needed to generate from wordlists
};
