import { debounce } from 'lodash';
import { useEffect } from 'react';

const FIVE_MINS = 5 * 60 * 1000;

type IdleTrackerProps = {
  debounceMs?: number;
  triggerMs?: number;
  triggerHandler?: (idleMs: number) => void;
};

const IdleTracker = ({
  debounceMs = 1000,
  triggerMs = FIVE_MINS,
  triggerHandler,
}: IdleTrackerProps) => {
  useEffect(() => {
    let lastUserActionDate = Date.now();

    const callback = debounce(() => {
      const now = Date.now();
      const diff = now - lastUserActionDate;
      if (diff >= triggerMs) triggerHandler && triggerHandler(diff);
      lastUserActionDate = Date.now();
    }, debounceMs);

    window.addEventListener('mousemove', callback);
    window.addEventListener('keypress', callback);
    window.addEventListener('touchstart', callback);
    window.addEventListener('touchmove', callback);

    return () => {
      window.removeEventListener('mousemove', callback);
      window.removeEventListener('keypress', callback);
      window.removeEventListener('touchstart', callback);
      window.removeEventListener('touchmove', callback);
    };
  }, [debounceMs, triggerHandler, triggerMs]);

  return null;
};

export default IdleTracker;
