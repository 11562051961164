import React from 'react';
import { Field } from 'redux-form';

import renderTextField from './renderTextField';

const formatDate = (date) => {
  try {
    return new Date(date).toISOString().split('T')[0];
  } catch(err) {
    console.log(err);

    return ''
  }
}

const DateField = props => {
  return (
    <Field
      component={renderTextField}
      type="date"
      format={val => val ? formatDate(val) : ''}
      parse={val => val ? formatDate(val) : ''}
      {...props}
    />
  );
};

export default DateField;
