import _ from "lodash";

export default (problem, audioPath = "instructionAudio") => {
  const { multiLocaleInstructions } = problem;

  if (!multiLocaleInstructions) {
    return problem[audioPath] ? [problem[audioPath]] : [];
  }

  const audioFiles = _.chain(multiLocaleInstructions)
    .map((val, key) => {
      if (key === "defaultLocale") return null;
      return val && val[audioPath];
    })
    .compact()
    .value();

  return audioFiles;
};
