import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Modal from './Modal';

const styles = {
  btnWrapper: { textAlign: 'center', marginTop: '2rem' },
  btn: { fontSize: '1.4rem', margin: '0 1rem' },
  input: {
    padding: '0.5rem 1rem',
    border: '1px solid #e8e8e8',
    borderRadius: '2px',
    width: '100%'
  }
};

const ConfirmDialog = props => {
  const { confirm, classes, showConfirm, translate } = props;

  if (!confirm) return null;

  return (
    <Modal
      on={!!confirm}
      toggle={() => {
        showConfirm(null);
        confirm.cb(false);
      }}
      bigZIndex={confirm.bigZIndex}
    >
      {confirm.html ? (
        <div dangerouslySetInnerHTML={{ __html: confirm.message }} />
      ) : (
        <p>{confirm.message}</p>
      )}

      <div className={classes.btnWrapper}>
        <Button
          onClick={() => {
            confirm.cb && confirm.cb(true);
            showConfirm(null);
          }}
          className={classes.btn}
          variant="contained"
          color="primary"
        >
          {translate(191, 'Ok', true)}
        </Button>
        <Button
          onClick={() => {
            confirm.cb && confirm.cb(false);
            showConfirm(null);
          }}
          className={classes.btn}
          variant="contained"
          color="inherit"
        >
          {translate(52, 'Cancel', true)}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmDialog.propTypes = {
  confirm: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showConfirm: PropTypes.func.isRequired
};

export default withStyles(styles)(ConfirmDialog);
