import { validateQuestion } from './movingAnswersHelpers';

const validQuestionExists = problems => {
  for (let i = 0; i < problems.length; i++) {
    if (validateQuestion(problems[i])) return true;
  }

  return false;
};

export default ({ options, gameData }) => {
  return !(
    !options ||
    !gameData ||
    !gameData.problems ||
    !gameData.problems.length ||
    !validQuestionExists(gameData.problems)
  );
};
