import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import _ from 'lodash';

import { toggleAudio } from 'actions/audioActions';
import { formatHtmlForPlay } from 'utils';
import { imagesURL, audioURL } from 'config';

class LoginHelp extends Component {
  state = {
    helpHtml: '',
    helpAudio: '',
  };

  componentDidMount() {
    this.setHelpHtml();
    this.setHelpAudio();
  }

  componentDidUpdate(prevProps) {
    const { translate, strings } = this.props;

    if (prevProps.translate !== translate) {
      this.setHelpHtml();
    }

    if (prevProps.strings !== strings) {
      this.setHelpAudio();
    }
  }

  setHelpHtml = () => {
    const { translate } = this.props;

    const helpHtml = formatHtmlForPlay({
      html: translate(1, '', false, true),
      units: 'vw',
      calc: (size) => (size / 1360) * 100,
    });
    this.setState({ helpHtml });
  };

  setHelpAudio = () => {
    const { lang, strings } = this.props;
    
    const langCode = lang?.code || 'en';
    const langName = lang?.name || 'English';

    const string = _.find(strings, { stringNumber: 1 }); // login help string
    const helpAudio = _.get(string, `data.${langCode}.stringAudio`);

    if (helpAudio) {
      console.debug('Should set custom login help audio', helpAudio);
      this.setState({ 
        helpAudio: `${audioURL}/Generic/Strings/${langName}/${helpAudio}`,
      });
    }
  }

  render() {
    const { lang, classes, stateAudioSrc, isPlaying } = this.props;
    const { helpHtml, helpAudio } = this.state;

    const audioSrc = helpAudio || `${audioURL}/Generic/LoginHelp/${
      lang?.name || 'English'
    }/Online/LoginHelp.mp3`;

    const playBtnIcon = `${imagesURL}/Images/Login/play-button-purple.png`;

    return (
      <div className={classes.helpWrapper}>
        <div
          className={classes.helpText}
          dangerouslySetInnerHTML={{ __html: helpHtml }}
        />

        <div
          className={classnames(classes.playButton, {
            [classes.playing]: audioSrc === stateAudioSrc && isPlaying,
          })}
          onClick={() => toggleAudio(audioSrc)}
          title="Audio help"
        >
          <img src={playBtnIcon} alt="" />
          <span className={classes.pauseIcon}>&times;</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ status, audio, common }) => ({
  translate: status.translate,
  strings: common.strings,
  isPlaying: audio.isPlaying,
  stateAudioSrc: audio.audioSrc,
});

export default connect(mapStateToProps)(LoginHelp);
