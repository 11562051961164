import React, { Component } from 'react';
import styled from 'styled-components';

export class NotFoundPage extends Component {
  componentDidMount() {
    document.title = '404 Not Found';
  }

  render() {
    return (
      <StyledWrapper>
        <h2>404 - Page not found</h2>
        <button onClick={this.props.history.goBack}>Back</button>
      </StyledWrapper>
    );
  }
}

export default NotFoundPage;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
