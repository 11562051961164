import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';

import { ManagePrepaid, WhiteBox } from 'components/flink-play';
import { Portal } from 'components/common';
import { logout } from 'actions/authActions';
import SettingsButton from '../Settings/SettingsButton/SettingsButton';
import classes from './PrepaidTracker.module.scss';
import api from 'api';

const TRACK_STEP_IN_MINUTES = 1;
const TRACK_STEP_IN_MS = TRACK_STEP_IN_MINUTES * 60 * 1000;

export const useTrackFamilyPrepaidTime = (familyId) => {
  const [enabled, setEnabled] = useState(true);

  const query = useQuery(
    ['familyPrepaidStatus', familyId],
    () => api.family.getPrepaidStatus(familyId),
    { refetchInterval: TRACK_STEP_IN_MS, enabled }
  );

  useEffect(() => {
    if (!query.data) return;
    if (query.data.fullyPaid && enabled) {
      setEnabled(false);
    }
    if (!query.data.fullyPaid && !enabled) {
      setEnabled(true);
    }
  }, [query.data, enabled]);

  return query;
};

const PrepaidTracker = ({ family }) => {
  const { data: status, refetch } = useTrackFamilyPrepaidTime(family._id);
  const dispatch = useDispatch();
  const isActive = status ? status.isActive : true;
  const isFullyPaid = status ? status.fullyPaid : false;

  useEffect(() => {
    if (!status) return;

    if (!status) return;
    const minutesLeft = Math.round(status.prepaidHoursRemaining * 60);

    if (minutesLeft === 10 || minutesLeft === 5 || minutesLeft === 1) {
      window.Modals.showInfo({
        message: `You have ${minutesLeft} minute(s) left`,
        title: 'Warning',
      });
    }
  }, [status]);

  useEffect(() => {
    const root = document.getElementById('root');

    if (isActive) {
      root.style.filter = 'none';
    } else {
      root.style.filter = 'blur(4px)';
    }

    return () => {
      root.style.filter = 'none';
    };
  }, [isActive]);

  useEffect(() => {
    if (!isActive || isFullyPaid) return;

    console.log('SET INTERVAL');
    const interval = setInterval(async () => {
      await api.family.trackPlayTimeForPrepaidPlan(
        family._id,
        TRACK_STEP_IN_MINUTES
      );
      refetch();
    }, [TRACK_STEP_IN_MS]);

    return () => {
      console.log('REMOVE INTERVAL');
      clearInterval(interval);
    };
  }, [family._id, refetch, isActive, isFullyPaid]);

  if (!isActive) {
    return (
      <Portal>
        <div className={classes.blocker}>
          <WhiteBox
            outerClass={classes.settingsOuter}
            innerClass={classes.settingsInner}
          >
            <div className={classes.popup}>
              <div className={classes.popupViewport}>
                <ManagePrepaid />

                <div className={classes.logoutWrapper}>
                  <SettingsButton onClick={() => dispatch(logout())}>
                    Logout
                  </SettingsButton>
                </div>
              </div>
            </div>
          </WhiteBox>
        </div>
      </Portal>
    );
  }

  return null;
};

const PrepaidTrackerWrapper = () => {
  const { family } = useSelector(({ flinkPlay }) => ({
    family: flinkPlay.family,
  }));

  if (!family.isPrepaidCustomer) {
    return null;
  }

  return <PrepaidTracker family={family} />;
};

export default PrepaidTrackerWrapper;
