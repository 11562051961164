import { validateProblem } from './matchingDefinitionsHelpers';

const isTwoValidProblemsExists = problems => {
  let count = 0;

  for (let i = 0; i < problems.length; i++) {
    if (validateProblem(problems[i])) {
      count++;
    };
  }

  return count >= 2;
};

export default ({ options, gameData }) => {
  return !(
    !options ||
    !gameData ||
    !gameData.problems ||
    !gameData.problems.length ||
    !isTwoValidProblemsExists(gameData.problems)
  );
};
