import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RSC from 'react-scrollbars-custom';

import { imagesURL } from 'config';

import classes from './CustomScrollbar.module.scss';
const arrowsImgSrc = `${imagesURL}/FlinkPlay/scrollbararrowspng.png`;

class CustomScrollbar extends Component {
  componentDidMount() {
    const { initialValue, startFromBottom } = this.props;
    const scrollbar = this.scrollbarRef.current;

    if (scrollbar && initialValue) {
      const { x, y } = initialValue;
      scrollbar.scrollTo(x, y);
    }

    if (!initialValue && startFromBottom) {
      this.scrollToBottom();
    }
  }

  onScrollStop = scrollValues => {
    const { onChange } = this.props;
    const { scrollTop: y, scrollLeft: x } = scrollValues;
    onChange && onChange({ x, y });
  };

  scrollToBottom = () => {
    const scrollbar = this.scrollbarRef.current;
    if (!scrollbar) return;

    scrollbar.scrollToBottom();
  };

  scrollbarRef = React.createRef();

  render() {
    const { children, style } = this.props;

    return (
      <RSC
        rtl
        style={style}
        ref={this.scrollbarRef}
        noDefaultStyles
        // onUpdate={this.onUpdate}
        onScrollStop={this.onScrollStop}
        wrapperProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;

            return (
              <div
                {...restProps}
                ref={elementRef}
                className={classes.wrapper}
              />
            );
          }
        }}
        trackYProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div {...restProps} ref={elementRef} className={classes.track} />
            );
          }
        }}
        thumbYProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div {...restProps} ref={elementRef} className={classes.thumb}>
                <img src={arrowsImgSrc} alt="" />
              </div>
            );
          }
        }}
      >
        {children}
      </RSC>
    );
  }
}

CustomScrollbar.defaultProps = {
  startFromBottom: true
};

CustomScrollbar.propTypes = {
  startFromBottom: PropTypes.bool,
  value: PropTypes.number,
  onChange: (props, propName) => {
    if (
      props.value !== undefined &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide a onChange function!');
    }
  }
};

export default CustomScrollbar;
