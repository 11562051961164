import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import { WhiteBox } from 'components/flink-play';
import classes from './Grid.module.scss';

const Grid = ({
  isResolved = false,
  grid = [],
  clickHandler,
  selected,
  showGrid = false,
  highlightRelative = false,
}) => {
  return (
    <WhiteBox
      outerClass={classes.wrapper}
      innerStyle={{ padding: 0 }}
      innerClass={cs(classes.grid, {
        [classes.withBorder]: showGrid,
        [classes.isResolved]: isResolved,
      })}
    >
      {grid.map((row, y) => (
        <div
          key={y}
          className={classes.row}
          style={{ height: 100 / grid.length + '%' }}
        >
          {row.map((item, x) => (
            <div
              key={x}
              className={cs(classes.itemWrapper, {
                [classes.selected]: selected.includes([x, y].toString()),
                [classes.highlighted]:
                  highlightRelative && item && item.isRelative,
              })}
            >
              {item && (
                <button onClick={() => clickHandler(item, [x, y])}>
                  <img src={item.imageUrl} alt="" />
                </button>
              )}
            </div>
          ))}
        </div>
      ))}
    </WhiteBox>
  );
};

Grid.propTypes = {
  grid: PropTypes.array,
  clickHandler: PropTypes.func,
};

export default Grid;
