import FindTheOne from './FindTheOne';
import convertOldActivity from './convertOldFindTheOne';
import validateActivity from './validateFindTheOne';
import { validateWords, generateActivityFromWords } from './findTheOneHelpers';

export default {
  Template: FindTheOne,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'randomOrder',
    'questionsLimit',
    'showAnswer'
  ],
  validateWords,
  generateActivityFromWords
};
