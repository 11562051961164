import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withTranslate } from 'components/hocs';
import { renderSelect } from 'components/form-components';

import classes from './LoginFieldsGroup.module.scss';

const renderField = ({
  input,
  label,
  type = 'text',
  meta: { touched, error },
  translate,
  ...otherProps
}) => {
  const errorMessage =
    typeof error === 'object'
      ? translate(error.stringNumber, error.message)
      : error;

  return (
    <div
      className={classnames(classes.inputGroup, {
        [classes.errorState]: !!(touched && errorMessage),
      })}
    >
      <input {...input} {...otherProps} type={type} placeholder={label} />
      {touched && errorMessage && (
        <span className={classes.error}>{errorMessage}</span>
      )}
    </div>
  );
};

const renderSelectField = (props) => {
  const SelectComponent = renderSelect;
  const familyLoginSelectStyles = {
    control: base => ({
      ...base,
      borderRadius: '1.5vw',
    })
  };

  // we have select only on family login 
  // and that's the only case we need to disable padding
  return (
    <SelectComponent 
      {...props}
      marginBottom='0' 
      isSearchable={false}
      blurInputOnSelect={false}
      styles={familyLoginSelectStyles}
      defaultMenuIsOpen={props.input.name === 'username'}
      isClearable
    />
  );
}

const LoginFieldsGroup = ({ number, primary, translate, family, learningCenter, noTitle }) => {
  const isFamilyLogin = !!family.id;

  const fieldComponent = isFamilyLogin ? renderSelectField : renderField;
  const familyLoginsValues = useMemo(() => {
    return isFamilyLogin ? family.members.map(m => ({
      label: m.firstname,
      value: m.username,
    })) : null;
  }, [isFamilyLogin, family]);

  const loginGroupClasses = classnames(classes.loginGroup, {
    [classes.familyLogin]: isFamilyLogin,
  });

  return (
    <div className={loginGroupClasses}>
      {!noTitle && (
        <b className={classes.loginGroupTitle}>
          {translate(537)} #{primary ? 1 : number}
        </b>
      )}

      <Field
        name={`username${primary ? '' : number}`}
        autoFocus={primary}
        component={fieldComponent}
        options={familyLoginsValues}
        label={!isFamilyLogin ? translate(102, 'Username') : null}
        translate={translate}
      />

      {(!isFamilyLogin && !learningCenter && primary) && (<Field
        name={`password${primary ? '' : number}`}
        type="password"
        component={renderField}
        label={translate(103, 'Password')}
        translate={translate}
      />) }
    </div>
  );
};

LoginFieldsGroup.propTypes = {
  number: PropTypes.string,
  primary: PropTypes.bool,
  family: PropTypes.object.isRequired,
};

export default withTranslate(LoginFieldsGroup);
