import { SET_CLIPART_IMAGES, SET_ACTIVE_CATEGORY } from 'actions/types';

const INITIAL_STATE = {};

const filter = 'flinkMake/clipart';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_CLIPART_IMAGES:
      return {
        ...state,
        list: action.payload
      };
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload
      };
    default:
      return state;
  }
}
