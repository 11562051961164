import { HTMLProps, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import SupportPhone from 'components/common/SupportPhone';

import useRegisterLocales from '../use-register-locales';
import classes from './RegisterForm.module.scss';

type Values = {
  email: string;
  parentFirstName: string;
  parentLastName: string;
  parentUsername: string;
  childFirstName: string;
  childLastName: string;
  childUsername: string;
  childYob?: string;
  parentYob?: string;
  agreeReceiveTextMessages: boolean;
  phone?: string;
  messageLocale: string;
  registerLocale: string;
  location?: string;
};

type RegisterFormProps = {
  initialValues?: Partial<Values>;
  disabledFields?: Partial<Record<keyof Values, boolean>>;
  locations?: Partial<Array<string>>;
  submit: (
    values: Values
  ) => Promise<
    | {
        success: true;
        redirectTo?: string;
      }
    | {
        success: false;
        errors?: Record<string, string>;
        error?: string;
      }
  >;
};

const RegisterForm = ({
  initialValues,
  submit,
  disabledFields,
  locations,
}: RegisterFormProps) => {

  const translate = useSelector((state: any) => state.status.translate);

  const currentLanguage = useSelector((state: any) => state.status.lang);
  const localesOptions = useRegisterLocales();

  const {
    watch,
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
    control,
  } = useForm<Values>({ defaultValues: initialValues });

  const watchAgreeReceiveTextMessages = watch('agreeReceiveTextMessages');
  const watchParentUsername = watch('parentUsername');
  const watchPhone = watch('phone');

  useEffect(() => {
    if (!watchAgreeReceiveTextMessages) {
      if (watchPhone) {
        setValue('phone', '');
      }
      return;
    }

    if (!watchPhone || !watchPhone.match(/^\+1/)) {
      setValue('phone', '+1');
    }
  }, [setValue, watchPhone, watchAgreeReceiveTextMessages]);

  const onSubmit = async (values: Values) => {
    const result = await submit({
      ...values,
      registerLocale: currentLanguage.code,
    });

    if (result.success) {
      result.redirectTo && (window.location.href = result.redirectTo);
      return;
    }

    if (result.errors) {
      if (result.errors._error) {
        alert(result.errors._error);
      }

      Object.entries({ ...result.errors, _error: '' }).forEach(
        ([path, message]) => {
          message &&
            setError(path as keyof Values, { message: message as string });
        }
      );

      return;
    } else {
      alert(result.error || 'Something goes wrong');
    }
  };

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>{translate(866, 'Register')}</h1>
      <div className={classes.subtitle}>{translate(864)}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.row}>
          <div className={classes.selectLocale}>
            <p>{translate(936)}</p>
            <div>
              {localesOptions.map((opt) => (
                <label key={opt.value}>
                  <input
                    type="radio"
                    value={opt.value}
                    {...register('messageLocale')}
                  />
                  <span>{opt.label}</span>
                </label>
              ))}
            </div>
          </div>

          <SupportPhone className={classes.phone} />
        </div>

        <div className={classes.row}>
          <Field
            label={translate(867, 'Email:')}
            error={errors.email?.message}
            inputProps={{
              readOnly: disabledFields?.email,
              type: 'email',
              ...register('email', { required: translate(695, 'Required') }),
            }}
          />

          {watchAgreeReceiveTextMessages && (
            <Field
              label={translate(874, 'Phone:')}
              error={errors.phone?.message}
              inputProps={{
                type: 'tel',
                readOnly: disabledFields?.phone,
                ...register('phone', {
                  pattern: {
                    value: /\+1\d{10}/,
                    message: 'Wrong phone number',
                  },
                  required: watchAgreeReceiveTextMessages
                    ? translate(695, 'Required')
                    : '',
                }),
              }}
            />
          )}
        </div>

        {locations && (
          <div className={classes.row}>
            <Controller
              name="location"
              control={control}
              defaultValue=""
              rules={{ required: translate(695, 'Required') }}
              render={({ field, fieldState: { error } }) => (
                <div className={classes.inputGroup}>
                  <label>{translate(1302, 'Select location')}</label>
                  <select {...field}>
                    <option value="">
                      {translate(698, 'Select')}
                    </option>
                    {locations.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                  {error && <span className={classes.inputError}>{error?.message}</span>}
                </div>
              )}
            />
          </div>
        )}

        <div className={classes.inputGroup}>
          <label>
            <input type="checkbox" {...register('agreeReceiveTextMessages')} />
            <span>{translate(937)}</span>
          </label>
        </div>

        <div className={classes.row}>
          <div>
            <Field
              label={`
            ${translate(868, 'Parent First Name:')}
            ${translate(872, '(at least 3 chars)')}`}
              error={errors.parentFirstName?.message}
              inputProps={{
                readOnly: disabledFields?.parentFirstName,
                ...register('parentFirstName', {
                  required: translate(695, 'Required'),
                  minLength: {
                    value: 3,
                    message: 'Min length 3 characters',
                  },
                }),
              }}
            />

            <Field
              label={`
            ${translate(869, 'Parent Last Name:')}
            ${translate(872, '(at least 3 chars)')}`}
              error={errors.parentLastName?.message}
              inputProps={{
                readOnly: disabledFields?.parentLastName,
                ...register('parentLastName', {
                  required: translate(695, 'Required'),
                  minLength: {
                    value: 3,
                    message: 'Min length 3 characters',
                  },
                }),
              }}
            />

            <Field
              label={translate(1315, 'Parent Year of Birth:')}
              error={errors.parentYob?.message}
              inputProps={{
                readOnly: disabledFields?.parentYob,
                ...register('parentYob', {
                  validate: (val) =>
                    val && !parseInt(val)
                      ? 'Should be a number'
                      : undefined,
                  minLength: {
                    value: 4,
                    message: 'Min length 4 characters',
                  },
                  maxLength: {
                    value: 4,
                    message: 'Max length 4 characters',
                  }
                }),
              }}
            />

            <Field
              label={`
            ${translate(102, 'Parent Username:')}
            ${translate(872, '(at least 3 chars)')}`}
              error={errors.parentUsername?.message}
              inputProps={{
                readOnly: disabledFields?.parentUsername,
                ...register('parentUsername', {
                  required: translate(695, 'Required'),
                  minLength: {
                    value: 3,
                    message: 'Min length 3 characters',
                  },
                }),
              }}
            />
          </div>
          <div>
            <Field
              label={`
            ${translate(870, 'Child First Name:')}
            ${translate(872, '(at least 3 chars)')}`}
              error={errors.childFirstName?.message}
              inputProps={{
                readOnly: disabledFields?.childFirstName,
                ...register('childFirstName', {
                  required: translate(695, 'Required'),
                  minLength: {
                    value: 3,
                    message: 'Min length 3 characters',
                  },
                }),
              }}
            />

            <Field
              label={`
            ${translate(871, 'Child Last Name:')}
            ${translate(872, '(at least 3 chars)')}`}
              error={errors.childLastName?.message}
              inputProps={{
                readOnly: disabledFields?.childLastName,
                ...register('childLastName', {
                  required: translate(695, 'Required'),
                  minLength: {
                    value: 3,
                    message: 'Min length 3 characters',
                  },
                }),
              }}
            />

            <Field
              label={translate(1316, 'Child Year of Birth:')}
              error={errors.childYob?.message}
              inputProps={{
                readOnly: disabledFields?.childYob,
                ...register('childYob', {
                  validate: (val) =>
                    val && !parseInt(val)
                      ? 'Should be a number'
                      : undefined,
                  minLength: {
                    value: 4,
                    message: 'Min length 4 characters',
                  },
                  maxLength: {
                    value: 4,
                    message: 'Max length 4 characters',
                  }
                }),
              }}
            />

            <Field
              label={`
            ${translate(102, 'Child Username:')}
            ${translate(872, '(at least 3 chars)')}`}
              error={errors.childUsername?.message}
              inputProps={{
                readOnly: disabledFields?.childUsername,
                ...register('childUsername', {
                  required: translate(695, 'Required'),
                  validate: (val) =>
                    val === watchParentUsername
                      ? 'Should be unique'
                      : undefined,
                  minLength: {
                    value: 3,
                    message: 'Min length 3 characters',
                  },
                }),
              }}
            />
          </div>
        </div>

        <div className={classes.beforeSubmit}>{translate(865)}</div>

        <button
          disabled={isSubmitting}
          className={classes.submitBtn}
          type="submit"
        >
          {translate(873, 'Submit')}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;

const Field = ({
  label,
  error,
  inputProps,
}: {
  label: string;
  inputProps: HTMLProps<HTMLInputElement>;
  error?: string;
}) => {
  return (
    <div className={classes.inputGroup}>
      <label>{label}</label>
      <input {...inputProps} />
      {error && <span className={classes.inputError}>{error}</span>}
    </div>
  );
};
