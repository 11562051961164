import _ from "lodash";

export default ({ solutionMenu, reports, templates, learningCenterConfig, isTeam }) => {
  const newReports = JSON.parse(JSON.stringify(reports));

  const templateDefaultForReports = _.find(templates, {
    defaultForReports: true,
  });

  const { activities, activityGroups, menuGroups } = solutionMenu;
  const { percentActivities = "50%" } = learningCenterConfig;

  const numberPercentActivities = parseInt(percentActivities);

  const getItemFeedback = (itemId, isGroup) => {
    let feedback = reports[itemId];

    if (isGroup) {
      const group = _.find(activityGroups, { _id: itemId });
      const elementsFeedbacks = group.elements.map(({ _id, isGroup }) =>
        getItemFeedback(_id, isGroup)
      );

      const elemsCount = group.elements.length;

      const masteredCount = elementsFeedbacks.filter((f) => f && f.mastered)
        .length;
      const partialCount = elementsFeedbacks.filter(
        (f) => f && (f.partial || f.mastered)
      ).length;

      const percentPartial = (partialCount / elemsCount) * 100;
      const percentMastered = (masteredCount / elemsCount) * 100;

      if (percentPartial >= numberPercentActivities) {
        feedback = { ...(feedback || {}), partial: true };
      }

      if (percentMastered >= numberPercentActivities) {
        feedback = { ...(feedback || {}), partial: true, mastered: true };
      }

      if (
        templateDefaultForReports &&
        group.generatedFromWordlist &&
        (!feedback || !feedback.mastered)
      ) {
        const activitiesInGroup = group.elements.map(({ _id }) =>
          _.find(activities, { _id })
        );

        const activityDefaultForReports = _.find(activitiesInGroup, {
          templateId: templateDefaultForReports._id,
        });

        const defaultActivityReports =
          (activityDefaultForReports &&
            reports[activityDefaultForReports._id]) ||
          {};

        if (defaultActivityReports.partial) {
          feedback = { ...(feedback || {}), partial: true };
        }

        if (defaultActivityReports.mastered) {
          feedback = { ...(feedback || {}), partial: true, mastered: true };
        }
      }
    }

    if (feedback) {
      // if is mastered, then add date when it was mastered
      if (feedback.mastered && !feedback.dateMastered) {
        feedback.dateMastered = Date.now();
      }

      if (feedback.mastered && isTeam) {
        feedback.masteredTogether = true;
      }

      newReports[itemId] = feedback;
    }

    return feedback;
  };

  menuGroups.forEach((group) => getItemFeedback(group.groupId, true));

  return newReports;
};
