import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Spinner from './Spinner';

class Loader extends Component {
  render() {
    const { isLoading, uploading } = this.props.status;

    return (
      <Fragment>
        {isLoading && <Spinner />}
        {uploading && <Spinner uploading={uploading} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ status }) => ({ status });

export default connect(mapStateToProps)(Loader);
