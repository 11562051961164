import _ from 'lodash';
import { axiosAPI as axios, withLoading } from 'utils';
import {
  FAMILY_UPDATE,
  SHOW_HELP,
  SHOW_SETTINGS,
  CLOSE_SETTINGS,
  SET_MENU_BUTTONS,
  SET_PREV_MENU_BUTTONS,
  SHOW_INITIAL_SETTINGS,
  SET_FLINK_PLAY_DATA,
  SET_CURRENT_MENU,
  SET_TEMPLATE_DATA,
  SET_SOLUTION,
  SHOW_VIDEO,
  SET_ACTIVITY_GROUP,
  SAVE_MAIN_MENU_SCROLL_POSITION,
  UPDATE_LEARNER,
  UPDATE_TEAM,
  GO_BACK_ACTION,
  SHOW_FAMILY_REPORTS,
  RESET_FLINK_PLAY,
  SET_ACTIVITY_PROGRESS,
  CLOSE_ACTIVITY,
  SET_ACTIVITY_TO_PLAY,
  SHOW_PARENT_ADVICE,
  SHOW_QUICKSTART_VIDEOS,
  SET_PARENT_ADVICE_URL,
  SET_PARENT_ADVICE_COORDS,
  SET_FEEDBACK_COORDS,
  OPEN_WRITING_FEEDBACK,
  CLOSE_WRITING_FEEDBACK,
  SHOW_FAMILY_POINTS_HISTORY,
  CLOSE_FAMILY_POINTS_HISTORY,
  SET_FAMILY_POINTS,
  SET_PREVIOUS_WEEK_TIME,
} from 'actions/types';
import { getTranslateFunc, calculateReports, removeParenthesis } from 'utils';
import { audioURL, filesURL } from 'config';
import { CONCENTRATION } from 'consts/activity-templates';
import { GO_BACK } from 'consts/buttons';
import { logout } from 'actions/authActions';

const filter = 'flinkPlay';

export const getFlinkPlayData = () => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .get('/api/flink-play/get-data')
      .then((response) => {
        dispatch({
          filter,
          type: SET_FLINK_PLAY_DATA,
          payload: response.data,
        });
        // console.log(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      })
  );

export const showHelp = (payload) => ({
  filter,
  type: SHOW_HELP,
  payload,
});

export const showSettings = () => ({
  filter,
  type: SHOW_SETTINGS,
});

export const showInitialSettings = () => ({
  filter,
  type: SHOW_INITIAL_SETTINGS,
});

export const setParentAdviceUrl = (payload) => ({
  filter,
  type: SET_PARENT_ADVICE_URL,
  payload,
});

export const setActivityPartial = (additionalData = {}) => ({
  filter,
  type: SET_ACTIVITY_PROGRESS,
  payload: { partial: true, ...additionalData },
});

export const setActivityMastered = (additionalData = {}) => ({
  filter,
  type: SET_ACTIVITY_PROGRESS,
  payload: { mastered: true, partial: true, ...additionalData },
});

export const setFamilyPoints = (points) => ({
  filter,
  type: SET_FAMILY_POINTS,
  payload: points,
});

export const closeActivity = () => async (dispatch, getState) => {
  const {
    flinkPlay,
    game,
    common: { activityTemplates: templates },
  } = getState();

  if (game.isPreview) return;

  const {
    activity,
    activityProgress,
    learner,
    team,
    groupsBreadcrumbs,
    currentActivityGroup,
    solutionMenu,
    learningCenter,
  } = flinkPlay;

  const out = () => {
    dispatch({
      filter,
      type: CLOSE_ACTIVITY,
    });

    if (
      !groupsBreadcrumbs.length &&
      !currentActivityGroup &&
      solutionMenu.menuGroups &&
      solutionMenu.menuGroups.length === 1
    ) {
      // if no groups then logout
      dispatch(logout());
    }
  };

  if (
    learningCenter &&
    learningCenter.config &&
    learningCenter.config.noReports
  ) {
    return out();
  }

  // Calculate Reports
  const updateLearnerReports = (learner) => {
    const reports = { ...learner.reports } || {};

    reports[activity] = {
      ...(reports[activity] || {}),
      ...(activityProgress || {}),
    };

    const newReports = calculateReports({
      templates,
      solutionMenu,
      reports,
      learningCenterConfig: learningCenter.config,
      isTeam: !!(team && team.length),
    });

    const learnerUpdate = { reports: newReports };
    return { learnerId: learner._id, data: learnerUpdate };
  };

  const addTimeInApp = game.gameStartedAt ?
    (Date.now() - game.gameStartedAt - game.idleTime) : null;

  try {

    const { learnerId, data } = updateLearnerReports(learner);
    await dispatch(updateLearner(learnerId, data));

    if (team && team.length) {
      await updateTeam(team.map(updateLearnerReports))(dispatch);
    }

    // Track time in app
    await trackTime(addTimeInApp)(dispatch);

    out();
  } catch (err) {
    alert('Error while saving family member record data');
  }
};

export const removeLearnerReports = () => async (dispatch, getState) => {
  const {
    flinkPlay: { learner },
  } = getState();

  const learnerUpdate = {
    reports: {},
  };

  try {
    await dispatch(updateLearner(learner._id, learnerUpdate));
  } catch (err) {
    alert('Error while saving family member record data');
  }
};

const settingsClosedHandler = async (dispatch, getState) => {
  const {
    common: { locales, strings },
    flinkPlay: {
      solution,
      product,
      learner: { settings, type: learnerType },
    },
    status: { lang },
  } = getState();

  if (!solution || (solution && solution._id !== settings.selectedSolutionId)) {
    const { defaultSolutionsForAdmins, defaultSolutionsForMembers } = product;
    const defaultSolutions =
      (learnerType === 'adult'
        ? defaultSolutionsForAdmins
        : defaultSolutionsForMembers) || {};

    console.debug('settingsClosedHandler', {
      defaultSolutions,
      learnerType,
      lang,
    });

    const solution = _.find(product.solutions, { _id: settings.selectedSolutionId }) ||
      _.find(product.solutions, { _id: defaultSolutions[lang.code] }) ||
      _.first(product.solutions);

    if (!solution)
      return alert('Product does not have solutions');

    const solutionLocale = _.find(locales, { code: solution.locale || 'en' });
    const solutionTranslate = getTranslateFunc(strings, solutionLocale.code);

    const { solutionMenu } = solution || {};

    if (solution && !solutionMenu) {
      return alert('Solution does not have menu');
    }

    try {
      const solutionMenu = await getSolutionMenu(solution._id)(
        dispatch,
        getState
      );

      // console.log("-> ACTIONS: Set Solution Menu", solutionMenu);

      dispatch({
        filter,
        type: SET_SOLUTION,
        payload: {
          solution,
          solutionMenu,
          solutionLocale,
          solutionTranslate,
        },
      });
    } catch (err) {
      alert(err || 'Error while fetching solution`s menu');
      dispatch({
        filter,
        type: SET_SOLUTION,
        payload: { solution: null, solutionMenu: null },
      });
    }
  }
};

export const getSolutionMenu = (solutionId, params) => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .get(`/api/flink-play/get-solution-menu/${solutionId}`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      })
  );

export const closeSettings = () => (dispatch, getState) => {
  settingsClosedHandler(dispatch, getState);

  dispatch({
    filter,
    type: CLOSE_SETTINGS,
  });
};

export const setMenu = (menu) => (dispatch, getState) => {
  const {
    flinkPlay: { learner },
  } = getState();

  dispatch({
    filter,
    type: SET_CURRENT_MENU,
    payload: menu,
  });

  if (menu && !learner.menusShown.includes(menu._id)) {
    // dispatch(showMenuHelp());

    dispatch(
      updateLearner(learner._id, {
        menusShown: [...learner.menusShown, menu._id],
      })
    );
  }

  dispatch(setMenuButtons((menu && menu.globalButtons) || [GO_BACK]));
};

export const showMultiplayerHelp = () => async (dispatch, getState) => {
  const {
    flinkPlay: { solutionLocale },
    status: { translate },
    flinkPlay: { solutionTranslate },
  } = getState();

  console.debug('Showing multiplayer help for locale', solutionLocale);
  const audioHelp = `${audioURL}/Generic/ActivityHelp/${solutionLocale.name}/multiplayermodehelp.mp3`;

  dispatch(
    showHelp({
      data: {},
      stringNumber: 987,
      audioHelp,
      customTranslate: solutionTranslate || translate,
    })
  );
};

export const showLoginFlinkPlayHelp = () => async (dispatch, getState) => {
  const {
    status: { translate, lang },
    common: { strings },
  } = getState();

  const langName = lang?.name || 'English';
  const langCode = lang?.code || 'en';

  const string = _.find(strings, { stringNumber: 988 });
  const stringAudio = _.get(string, `data.${langCode}.stringAudio`);

  const audioHelp = stringAudio ?
    `${audioURL}/Generic/Strings/${langName}/${stringAudio}` :
    `${audioURL}/Generic/LoginHelp/${langName}/Online/loginhelpdialog.mp3`;

  dispatch(
    showHelp({
      data: {},
      stringNumber: 988,
      audioHelp,
      customTranslate: translate,
    })
  );
}

export const showFamilyPointsHistory = () => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .get('/api/flink-play/get-family-points-history')
      .then((response) => {
        dispatch({
          filter,
          type: SHOW_FAMILY_POINTS_HISTORY,
          payload: response.data,
        })
      })
  );

export const closeFamilyPointsHistory = () => ({
  filter,
  type: CLOSE_FAMILY_POINTS_HISTORY,
});

export const checkWriting = (writing) => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .post('/api/ai/check-writing', { writing })
      .then((response) => {
        dispatch({
          filter,
          type: OPEN_WRITING_FEEDBACK,
          payload: response.data.feedback,
        });
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err.response.data);
      })
  );

export const closeWritingFeedback = () => ({
  filter,
  type: CLOSE_WRITING_FEEDBACK,
});

export const showMenuHelp = () => async (dispatch, getState) => {
  const {
    flinkPlay: {
      currentMenu,
      solutionLocale,
      currentActivityGroup,
      solutionMenu: { activityGroups },
    },
    common: { strings },
  } = getState();

  if (!currentMenu) return;

  const { helpAudio, helpString } = currentMenu;
  console.log(currentMenu);

  const data = {};

  if (currentActivityGroup && activityGroups) {
    const group = _.find(activityGroups, { _id: currentActivityGroup });

    if (group && group.words) {
      try {
        const result = await axios.post(
          '/api/flink-make/wordlists/get-words-by-names-and-locale',
          {
            words: group.words,
            locale: solutionLocale.code,
          }
        );

        data.wordsInWordlist = result.data
          .map((w) => removeParenthesis(w.word))
          .join(', ');
      } catch (e) {
        data.wordsInWordlist = group.words.map(removeParenthesis).join(', ');
      }
    }
  }

  const string = _.find(strings, { stringNumber: +helpString });
  const stringAudio = _.get(string, `data.${solutionLocale.code}.stringAudio`);

  const audioHelp =
    stringAudio ?
    `${audioURL}/Generic/Strings/${solutionLocale.name}/${stringAudio}` :
    helpAudio &&
    `${audioURL}/Generic/MenuHelp/${solutionLocale.name}/${helpAudio}`;

  dispatch(
    showHelp({
      data,
      stringNumber: helpString,
      audioHelp,
    })
  );
};

export const resetFlinkPlay = () => ({
  filter,
  type: RESET_FLINK_PLAY,
});

export const showVideo = (payload) => ({
  filter,
  type: SHOW_VIDEO,
  payload,
});

export const showHelpPromise = (payload) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      filter,
      type: SHOW_HELP,
      payload: { ...payload, cb: resolve },
    });
  });
};

export const familyUpdate = (family) => ({
  filter,
  type: FAMILY_UPDATE,
  payload: family,
});

export const setMenuButtons = (payload) => ({
  filter,
  type: SET_MENU_BUTTONS,
  payload,
});

export const showFamilyReports = (payload) => ({
  filter,
  type: SHOW_FAMILY_REPORTS,
  payload,
});

export const showParentAdvice = (payload) => ({
  filter,
  type: SHOW_PARENT_ADVICE,
  payload,
});

export const showQuickstartVideos = (payload) => ({
  filter,
  type: SHOW_QUICKSTART_VIDEOS,
  payload,
});

export const setPrevMenuButtons = () => ({
  filter,
  type: SET_PREV_MENU_BUTTONS,
});

export const setMainMenuScroll = (scrollPosition) => ({
  filter,
  type: SAVE_MAIN_MENU_SCROLL_POSITION,
  payload: scrollPosition,
});

export const saveSettingsAndCloseDialog = (learnerId, settings) => async (
  dispatch,
  getState
) => {
  const {
    flinkPlay: { team },
  } = getState();

  await dispatch(updateLearner(learnerId, { settings }));

  if (team && team.length) {
    await updateTeam(
      team.map((learner) => ({
        learnerId: learner._id,
        data: {
          settings: {
            ...learner.settings,
            // selectedSolutionId: settings.selectedSolutionId,
          },
        },
      }))
    )(dispatch);
  }
  dispatch(closeSettings());
};

export const updateLearner = (learnerId, data) => async (
  dispatch,
  getState
) => {
  const {
    flinkPlay: { learner, learningCenter },
  } = getState();

  if (
    learningCenter &&
    learningCenter.config &&
    learningCenter.config.noReports
  ) {
    dispatch({
      filter,
      type: UPDATE_LEARNER,
      payload: { ...learner, ...data },
    });

    return { success: true };
  }

  return withLoading(dispatch, () =>
    axios
      .post('/api/flink-play/update-learners', [
        {
          learnerId,
          data,
        },
      ])
      .then((response) => {
        if (response.data.success) {
          dispatch({
            filter,
            type: UPDATE_LEARNER,
            payload: response.data.data[0],
          });

          return { success: true };
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error while saving family member data');
        return { success: false };
      })
  );
};

export const getPreviousWeekTime = (familyId) => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .get(`/api/reports/previous-week-time/${familyId}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            filter,
            type: SET_PREVIOUS_WEEK_TIME,
            payload: response.data.time,
          });
          return { success: true }
        }
      })
      .catch((err) => {
        console.log(err);
        return { success: false };
      })
  );

export const trackTime = (addTimeInApp) => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .post('/api/flink-play/track-time', { addTimeInApp })
      .then((response) => {
        if (response.data.success) {
          const { points } = response.data;
          if (points !== undefined) {
            dispatch(setFamilyPoints(points));
          }

          return { success: true };
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error while tracking time');
        return { success: false };
      })
  );

export const updateTeam = (array) => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .post('/api/flink-play/update-learners', array)
      .then((response) => {
        if (response.data.success) {
          const { data } = response.data;
          dispatch({
            filter,
            type: UPDATE_TEAM,
            payload: data,
          });

          return { success: true };
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error while saving family member data');
        return { success: false };
      })
  );

export const getThemeImages = (themeId) => (dispatch) =>
  withLoading(dispatch, () =>
    axios
      .get(`/api/flink-play/get-theme-images/${themeId}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log('Error while getting theme images', err);
      })
  );

export const setTemplateData = (payload) => ({
  filter,
  type: SET_TEMPLATE_DATA,
  payload,
});

export const getConcentrationAssetsList = () => (dispatch, getState) => {
  const {
    flinkPlay: { currentSettings },
  } = getState();

  const themeId = (currentSettings && currentSettings.theme) || '';

  return withLoading(dispatch, () =>
    axios
      .get(`/api/flink-play/get-concentration-assets-list/${themeId}`)
      .then((response) => {
        const data = { ...response.data };

        data.cardbacks = _.chain(data.cardbacks || [])
          .map((f) => `${filesURL}/${f}`)
          .shuffle()
          .value();
        data.rewardGraphics = _.chain(data.rewardGraphics || [])
          .map((f) => `${filesURL}/${f}`)
          .shuffle()
          .value();

        data.numberOfPlays = 0;

        dispatch(setTemplateData({ [CONCENTRATION]: data }));
        return data;
      })
      .catch((err) => {
        console.error('Error while getting theme images', err);
        return {};
      })
  );
};

export const setActivityGroup = (group) => ({
  filter,
  type: SET_ACTIVITY_GROUP,
  payload: group,
});

export const setActivityToPlay = (activity) => ({
  filter,
  type: SET_ACTIVITY_TO_PLAY,
  payload: activity,
});

export const openMenuItem = (itemId, isItemGroup) => (dispatch, getState) => {
  const {
    flinkPlay: {
      solutionMenu: { activityGroups, activities },
    },
  } = getState();

  const getItem = (id, isGroup) => {
    if (isGroup) {
      const activityGroup = _.find(activityGroups, { _id: id });

      if (!activityGroup) {
        return alert("Unexpected Error - Activity Group doesn't exists");
      }

      if (!activityGroup.elements || !activityGroup.elements.length) {
        return alert('Activity Group is empty');
      }

      if (activityGroup.elements.length === 1) {
        const elem = activityGroup.elements[0];
        return getItem(elem._id, elem.isGroup);
      } else {
        return { _id: activityGroup._id, isGroup: true };
      }
    } else {
      const activity = _.find(activities, { _id: id });

      if (!activity) {
        return alert("Unexpected Error - Activity doesn't exists");
      }

      return { _id: id, templateId: activity.templateId, isGroup: false };
    }
  };

  const itemToSet = getItem(itemId, isItemGroup);

  // console.info('-> MENU: Item to set', itemToSet);

  if (!itemToSet) {
    return alert('No Item To Set');
  }

  // @TODO: Check if activity exists

  if (itemToSet.isGroup) {
    dispatch(setActivityGroup(itemToSet._id));
  } else {
    dispatch(setActivityToPlay(itemToSet._id));
  }
};

export const goBack = () => ({
  filter,
  type: GO_BACK_ACTION,
});

export const setParentAdviceCoords = (e, draggableEvent) => {
  return {
    filter,
    type: SET_PARENT_ADVICE_COORDS,
    payload: {
      x: draggableEvent.x,
      y: draggableEvent.y,
    },
  };
};

export const setFeedbackCoords = (e, draggableEvent) => {
  return {
    filter,
    type: SET_FEEDBACK_COORDS,
    payload: {
      x: draggableEvent.x,
      y: draggableEvent.y,
    },
  };
};
