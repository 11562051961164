import React from 'react';
import PropTypes from 'prop-types';
import { CirclePicker } from 'react-color';

const ColorPicker = ({ currentValue, changeHandler, show }) => {
  if (!show) return null;

  return (
    <CirclePicker
      color={currentValue}
      onChangeComplete={changeHandler}
      colors={[
        '#f44336',
        '#000000',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#4caf50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
        '#ff9800',
        '#ff5722',
        '#795548',
        '#607d8b',
      ]}
    />
  );
};

ColorPicker.propTypes = {
  currentValue: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ColorPicker;
