import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { shuffle } from "lodash";

import { filesURL } from "config";
import { getThemeImages } from "actions/flinkPlayActions";

const ThemeBackground = ({
  solution,
  settings,
  getThemeImages,
  currentActivity
}) => {
  const [images, setImages] = useState();
  const [currentIndex, setIndex] = useState(-1);

  useEffect(() => {
    setIndex(currentIndex + 1);
    // console.info("-> THEME: Current Background Index - ", currentIndex + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivity]);

  const style = useMemo(() => {
    const backgroundSrc =
      images && `${filesURL}/${images[currentIndex % images.length]}`;

    return {
      backgroundImage: `url(${backgroundSrc})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "fixed",
      zIndex: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };
  }, [images, currentIndex]);

  const selectedThemeId =
    (settings &&
      solution &&
      settings.bySolution &&
      settings.bySolution[solution._id] &&
      settings.bySolution[solution._id].theme) ||
    "";

  useEffect(() => {
    if (!selectedThemeId) return;
    // console.info("-> THEME: Setting Theme Backgrounds");

    getThemeImages(selectedThemeId).then(images => {
      setImages(shuffle(images.map(i => i.key)));
    });
  }, [selectedThemeId, getThemeImages]);

  return <div style={style} />;
};

ThemeBackground.propTypes = {
  solution: PropTypes.object,
  settings: PropTypes.object,
  getThemeImages: PropTypes.func.isRequired
};

const mapStateToProps = ({ flinkPlay, game }) => ({
  currentActivity: game.currentActivity,
  solution: flinkPlay.solution,
  settings: flinkPlay.learner.settings
});

export default connect(mapStateToProps, { getThemeImages })(ThemeBackground);
