import { axiosAPI, responseHandler } from 'utils';

export default {
  getById(id) {
    return responseHandler(axiosAPI.get(`api/pending-registrations/${id}`));
  },
  finishRegistration(pendingRegistrationId, values) {
    return responseHandler(
      axiosAPI.post(
        `api/pending-registrations/${pendingRegistrationId}/finish`,
        values
      )
    );
  },
};
