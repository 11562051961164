import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import shortId from 'shortid';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  toggleAudio,
  playCorrectSound,
  playIncorrectSound,
  playWatch
} from 'actions/audioActions';
import { animations } from 'activity-templates/utils';
import { s3bucketPublicURL } from 'config';
import { validateProblem } from './sentenceConnectablesHelpers';
import {
  ActivityButtons,
  ProblemsProgress,
  ItemsDragLayer,
  ItemSlots,
  BoardWithDraggableItems,
  MultiplayerOverlay,
} from 'components/flink-play';
import classes from './SentenceConnectables.module.scss';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

const boxesCount = 12;
const animationSpeed = 1000;

const getWords = problem => {
  if (!problem) return null;
  const { sentence, wordsMask, options } = problem;

  const startPositions = _.chain(boxesCount)
    .times()
    .shuffle()
    .value();

  const formattedWords = _.chain(sentence)
    .trim()
    .split(' ')
    .map((word, idx) => {
      const isFixed = wordsMask.charAt(idx) === '1';

      return {
        text: word,
        inSlot: isFixed,
        fixed: isFixed,
        slotIdx: isFixed ? idx : null
      };
    })
    .concat(
      options.filter(word => word).map(word => ({ text: word, inSlot: false }))
    )
    .map((item, idx) => ({
      ...item,
      id: shortId.generate(),
      boxIdx: startPositions[idx]
    }))
    .value();

  return formattedWords;
};

class SentenceConnectables extends Component {
  constructor(props) {
    super(props);

    const { options, gameData } = props.activity.data;

    // Remove invalid problems
    let problems = gameData.problems.filter(validateProblem);

    const { questionsLimit, audioBefore, randomOrder } = options;

    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    this.state = {
      audioBefore,
      problems,
      currentProblem: null
    };
  }

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('audioEnded', this.setupNextQuestionWithAudio);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { problems, audioBefore } = this.state;
    const currentProblem = problems[0];
    const words = getWords(currentProblem);

    this.refs = {
      boxes: _.times(boxesCount).map(idx => React.createRef()),
      slots: _.times(
        currentProblem.sentence.trim().split(' ').length
      ).map(idx => React.createRef())
    };

    this.setState({
      currentProblem,
      words,
      animations: _.shuffle(animations),
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length
    });

    audioBefore && this.playProblemAudio(currentProblem);
  };

  onDrop = ({ itemId, slotIdx }) => {
    if (slotIdx !== undefined) {
      this.moveToSlot(itemId, slotIdx);
    } else {
      this.moveToBoard(itemId);
    }
  };

  moveToSlot = (wordId, slotIdx) => {
    const { words } = this.state;

    const updatedWords = words.map(word => {
      if (word.id === wordId) {
        return { ...word, slotIdx: slotIdx, inSlot: true };
      }

      if (word.slotIdx === slotIdx) {
        return { ...word, slotIdx: undefined, inSlot: false };
      }

      return word;
    });

    this.setState({ words: updatedWords });

    this.checkAnswer();
  };

  moveToBoard = wordId => {
    const { words } = this.state;

    this.setState({
      words: words.map(word => {
        if (word.id !== wordId) return word;
        return { ...word, inSlot: false, slotIdx: null };
      })
    });
  };

  checkAnswer = () => {
    const { words, currentProblem, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const { sentence } = currentProblem;
    const wordsInSlot = _.chain(words)
      .filter({ inSlot: true })
      .sortBy(w => w.slotIdx)
      .value();

    if (wordsInSlot.length !== sentence.trim().split(' ').length) return;

    const wordInSlots = wordsInSlot.map(w => w.text).join(' ');

    if (wordInSlots === sentence.trim()) {
      this.setState({
        isResolved: true,
        shouldChangeTurn: true,
      });

      this.props.playCorrectSound();
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      }

      document.addEventListener('audioEnded', this.setupNextQuestionWithAudio, {
        once: true
      });

      return;
    }

    this.props.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState(state => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly
    }));
  };

  setupNextQuestionWithAudio = () => {
    const { currentProblem } = this.state;

    if (currentProblem.audio) {
      this.playProblemAudio();

      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true
      });
    } else {
      this.setupNextQuestion();
    }
  };

  playProblemAudio = problem => {
    let problemToPlay = problem || this.state.currentProblem;

    if (!problemToPlay || !problemToPlay.audio) return;

    const {
      activity: {
        activity: { contentFolder }
      }
    } = this.props;

    const audioUrl = `${s3bucketPublicURL}/${contentFolder}/gamedata/${problemToPlay.audio}`;
    toggleAudio(audioUrl);
  };

  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly
      }
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState(state => {
        const nextProblem = state.problems[state.currentIndex + 1];
        const words = getWords(nextProblem);

        this.refs.slots = _.times(
          nextProblem.sentence.trim().split(' ').length
        ).map(idx => React.createRef());

        state.audioBefore && this.playProblemAudio(nextProblem);

        return {
          words,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1
        };
      });

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  showAnswer = () => {
    const {
      words,
      currentProblem: { sentence }
    } = this.state;

    const sentenceArray = sentence.trim().split(' ');

    let changedWords = _.map(words, w => ({
      ...w,
      isCorrect: w.inSlot && w.text === sentenceArray[w.slotIdx]
    }));

    _.chain(sentenceArray.length)
      .times()
      .forEach(idx => {
        const wordInThatSlot = _.find(changedWords, { slotIdx: idx });

        if (wordInThatSlot && wordInThatSlot.isCorrect) return;

        const shouldBeWord = sentenceArray[idx];

        const wordForThatSlot = _.find(changedWords, {
          text: shouldBeWord,
          isCorrect: false
        });

        wordForThatSlot.moveFrom = wordForThatSlot.inSlot
          ? this.refs.slots[wordForThatSlot.slotIdx].current
          : this.refs.boxes[wordForThatSlot.boxIdx].current;
        wordForThatSlot.inSlot = true;
        wordForThatSlot.slotIdx = idx;
        wordForThatSlot.isCorrect = true;
      })
      .value();

    changedWords.forEach(w => {
      if (!w.isCorrect && w.inSlot) {
        w.moveFrom = this.refs.slots[w.slotIdx].current;
        w.inSlot = false;
        w.slotIdx = null;
      }
    });

    this.props.playWatch();

    this.setState(state => ({
      words: [...changedWords],
      isResolved: true,
      shouldChangeTurn: false,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1
    }));

    document.addEventListener('audioEnded', this.setupNextQuestionWithAudio, {
      once: true
    });
  };

  render() {
    const {
      isResolved,
      problems,
      problemsLeft,
      currentProblem,
      attemptsOnCurrentProblem
    } = this.state;

    const { textStyle, activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    return (
      <div style={textStyle}>
        {this.renderBlocks()}

        {problems && (
          <ProblemsProgress
            problemsNumber={problems.length}
            problemsLeft={problemsLeft}
          />
        )}

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem
            },
            {
              type: buttonsTypes.SPEAK,
              onClick: () => this.playProblemAudio(),
              dontShow: !currentProblem || !currentProblem.audio || isResolved
            },
            buttonsTypes.HELP
          ]}
        />
      </div>
    );
  }

  renderBlocks = () => {
    const {
      words,
      isResolved,
      animations,
      currentIndex,
      currentProblem
    } = this.state;

    if (!currentProblem || !words) return null;

    const {
      textStyle,
      activity: {
        data: { options }
      }
    } = this.props;

    return (
      <Fragment>
        <ItemsDragLayer />
        <ItemSlots
          slotClassname={classes.wordSlot}
          options={options}
          animationSpeed={animationSpeed}
          isResolved={isResolved}
          animation={animations[currentIndex % animations.length]}
          count={currentProblem.sentence.trim().split(' ').length}
          onDrop={this.onDrop}
          items={words}
          refs={this.refs.slots}
        />
        <BoardWithDraggableItems
          isResolved={isResolved}
          boxesCount={boxesCount}
          onDrop={this.onDrop}
          items={words}
          textStyle={textStyle}
          refs={this.refs.boxes}
        />
      </Fragment>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(SentenceConnectables);
