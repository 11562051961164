import React from 'react';
import PropTypes from 'prop-types';

import CheckboxFieldsGroup from './CheckboxFieldsGroup';

const CheckboxGroups = ({ groups, name, ...rest }) => {
  return (
    <>
      {groups.map((group, index) => {
        const groupName =
          name && group.groupName
            ? `${name}[${group.groupName}]`
            : name
            ? name
            : group.groupName;

        return (
          <CheckboxFieldsGroup
            style={group.style || {}}
            key={index}
            name={groupName}
            fields={group.checkboxes}
            groupLabel={group.groupLabel}
            {...rest}
          />
        );
      })}
    </>
  );
};

CheckboxGroups.propTypes = {
  name: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.exact({
      style: PropTypes.object,
      groupLabel: PropTypes.string,
      groupName: PropTypes.string,
      checkboxes: PropTypes.arrayOf(
        PropTypes.exact({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default CheckboxGroups;
