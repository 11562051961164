import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import { imagesURL } from "config";
import { showConfirm } from "actions/statusActions";
import { withTranslate } from "components/hocs";
import classes from "./WordsList.module.scss";

class WordsList extends Component {
  onInputChange = e => {
    const { showConfirm, currentWords, translate } = this.props;

    const file = e.target.files[0];

    if (!file) return;

    if (currentWords && currentWords.length) {
      showConfirm({
        message: translate(
          182,
          "It will replace current words. Do you want to continue?",
          true
        ),
        cb: isConfirmed =>
          isConfirmed ? this.parseWords(file) : (this.input.current.value = "")
      });
    } else {
      this.parseWords(file);
    }
  };

  parseWords = file => {
    const { onImport, withoutSpaces } = this.props;

    const reader = new FileReader();

    reader.onload = event => {
      const file = event.target.result;
      const words = file.split(/\r\n|\r|\n/g);

      this.input.current.value = "";

      const uniqWords = _.chain(words)
        .filter(w => w && w.trim())
        .map(w => (withoutSpaces ? w.replace(/\s/g, "") : w.trim()))
        .uniq()
        .compact()
        .value();

      onImport(uniqWords);
    };

    reader.onerror = event => {
      alert(event.target.error.name);
    };

    reader.readAsText(file);
  };

  changeWordHandler = (idx, value) => {
    const { onChange, currentWords } = this.props;

    const modifiedWords = [...currentWords];

    modifiedWords[idx] = value;
    onChange && onChange(modifiedWords);
  };

  deleteAllWords = () => {
    const { showConfirm, deleteAllHandler, translate } = this.props;

    showConfirm({
      message: translate(
        0,
        "Are you sure you want to delete all words from list?",
        true
      ),
      cb: isConfirmed => isConfirmed && deleteAllHandler()
    });
  };

  input = React.createRef();

  render() {
    const {
      translate,
      children,
      currentWords,
      deleteAllHandler,
      wordsCount = 20,
      withTitles = true
    } = this.props;

    const firstColumnCount = Math.ceil(wordsCount / 2);

    return (
      <>
        <div className={classes.header}>
          {children}

          <label className={classes.btn}>
            <span>{translate(331, "Import")}</span>
            <img src={`${imagesURL}/FlinkMake/import_large.png`} alt="" />
            <input
              ref={this.input}
              type="file"
              accept="text/plain"
              onChange={this.onInputChange}
            />
          </label>

          {deleteAllHandler && (
            <button
              disabled={!currentWords || !currentWords.length}
              className={classes.btn}
              onClick={this.deleteAllWords}
            >
              <span>{translate(98, "Delete All")}</span>
              <img src={`${imagesURL}/FlinkMake/deleteall_large.png`} alt="" />
            </button>
          )}
        </div>

        <div className={classes.listsWrapper}>
          <div>
            {/* {withTitles && <p>{translate(281, "Words 1-10")}</p>} */}
            {withTitles && (
              <div>
                {translate(405, "Words")} 1-{firstColumnCount}
              </div>
            )}
            <ul className={classes.wordsList}>
              {this.renderWordField(0, firstColumnCount)}
            </ul>
          </div>

          <div>
            {/* {withTitles && <p>{translate(282, "Words 11-20")}</p>} */}
            {withTitles && (
              <div>
                {translate(405, "Words")} {firstColumnCount + 1}-{wordsCount}
              </div>
            )}
            <ul className={classes.wordsList}>
              {this.renderWordField(firstColumnCount, wordsCount)}
            </ul>
          </div>
        </div>
      </>
    );
  }

  renderWordField = (idxFrom, idxTo) => {
    const { currentWords = [], editable, withoutSpaces } = this.props;

    return _.range(idxFrom, idxTo).map(i =>
      editable ? (
        <input
          className={classes.wordField}
          onChange={({ target: { value } }) =>
            this.changeWordHandler(
              i,
              withoutSpaces ? value.replace(/\s/g, "") : value
            )
          }
          value={currentWords[i] || ""}
          key={i}
        />
      ) : (
        <li className={classes.wordField} key={i}>
          {currentWords[i] || ""}
        </li>
      )
    );
  };
}

WordsList.defaultProps = {
  withoutSpaces: false
};

WordsList.propTypes = {
  translate: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  deleteAllHandler: PropTypes.func,
  currentWords: PropTypes.array.isRequired
};

export default compose(
  withTranslate,
  connect(null, { showConfirm })
)(WordsList);
