import _ from 'lodash';

const removeAssignFromLists = (
  lists,
  dependencyProp,
  dependencyItemId,
  firstMenu,
  selectedMenuLevel
) => {
  const listsClone = JSON.parse(JSON.stringify(lists));

  return _.map(listsClone, list => {
    if (
      dependencyProp &&
      dependencyItemId &&
      list[dependencyProp] !== dependencyItemId
    ) {
      return list;
    }

    return {
      ...list,
      items: list.items.map(item => {
        if (item[firstMenu] && item[firstMenu].includes(selectedMenuLevel)) {
          _.pull(item[firstMenu], selectedMenuLevel);
        }
        return item;
      })
    };
  });
};

export default (
  data,
  itemId,
  selected,
  categoryName,
  checked,
  changeHandler
) => {
  const { firstCategory, secondCategory, thirdCategory, firstMenu } = data;

  if (!selected.menuLevel) {
    return alert('You must select value in left column firstly');
  }

  const changedFirstCategoryItems = JSON.parse(
    JSON.stringify(firstCategory.items)
  );

  if (categoryName === 'firstCategory') {
    const firstCategoryItem = _.find(changedFirstCategoryItems, {
      id: itemId
    });
    if (checked) {
      if (firstCategoryItem[firstMenu]) {
        firstCategoryItem[firstMenu].push(selected.menuLevel);
      } else {
        firstCategoryItem[firstMenu] = [selected.menuLevel];
      }
    } else {
      _.pull(firstCategoryItem[firstMenu], selected.menuLevel);
      // Remove all second category items assigned to this menu level and this first category
      const changedSecondCategoryLists = removeAssignFromLists(
        secondCategory.lists,
        'firstCategoryId',
        itemId,
        firstMenu,
        selected.menuLevel
      );

      // Remove all third category items assigned to this menu level and this first category
      const changedThirdCategoryLists = removeAssignFromLists(
        thirdCategory.lists,
        'firstCategoryId',
        itemId,
        firstMenu,
        selected.menuLevel
      );
      changeHandler({
        'secondCategory.lists': changedSecondCategoryLists,
        'thirdCategory.lists': changedThirdCategoryLists
      });
    }
  }

  if (categoryName === 'secondCategory') {
    const changedSecondCategoryLists = JSON.parse(
      JSON.stringify(secondCategory.lists)
    );

    const secondCategoryList = _.find(changedSecondCategoryLists, {
      firstCategoryId: selected.firstCategory
    });

    const secondCategoryItem = _.find(secondCategoryList.items, {
      id: itemId
    });

    if (checked) {
      if (secondCategoryItem[firstMenu]) {
        secondCategoryItem[firstMenu].push(selected.menuLevel);
      } else {
        secondCategoryItem[firstMenu] = [selected.menuLevel];
      }

      // Check if firstCategory has this menu level
      const firstCategoryItem = _.find(changedFirstCategoryItems, {
        id: secondCategoryList.firstCategoryId
      });

      if (!firstCategoryItem[firstMenu]) {
        firstCategoryItem[firstMenu] = [selected.menuLevel];
      } else if (!firstCategoryItem[firstMenu].includes(selected.menuLevel)) {
        firstCategoryItem[firstMenu].push(selected.menuLevel);
      }
    } else {
      // Remove menu level from second category item
      _.pull(secondCategoryItem[firstMenu], selected.menuLevel);

      // Remove menu level from third category items related to that category
      const changedThirdCategoryLists = removeAssignFromLists(
        thirdCategory.lists,
        'secondCategoryId',
        itemId,
        firstMenu,
        selected.menuLevel
      );

      changeHandler({ 'thirdCategory.lists': changedThirdCategoryLists });
    }

    changeHandler({ 'secondCategory.lists': changedSecondCategoryLists });
  }

  if (categoryName === 'thirdCategory') {
    const changedThirdCategoryLists = JSON.parse(
      JSON.stringify(thirdCategory.lists)
    );

    const thirdCategoryList = _.find(changedThirdCategoryLists, {
      firstCategoryId: selected.firstCategory,
      secondCategoryId: selected.secondCategory
    });

    const thirdCategoryItem = _.find(thirdCategoryList.items, {
      id: itemId
    });

    if (checked) {
      if (thirdCategoryItem[firstMenu]) {
        thirdCategoryItem[firstMenu].push(selected.menuLevel);
      } else {
        thirdCategoryItem[firstMenu] = [selected.menuLevel];
      }

      // Check if firstCategory has this menu level
      const firstCategoryItem = _.find(changedFirstCategoryItems, {
        id: thirdCategoryList.firstCategoryId
      });

      if (!firstCategoryItem[firstMenu]) {
        firstCategoryItem[firstMenu] = [selected.menuLevel];
      } else if (!firstCategoryItem[firstMenu].includes(selected.menuLevel)) {
        firstCategoryItem[firstMenu].push(selected.menuLevel);
      }

      // Check if secondCategory has this menu level
      const changedSecondCategoryLists = JSON.parse(
        JSON.stringify(secondCategory.lists)
      );

      const secondCategoryList = _.find(changedSecondCategoryLists, {
        firstCategoryId: selected.firstCategory
      });

      const secondCategoryItem = _.find(secondCategoryList.items, {
        id: thirdCategoryList.secondCategoryId
      });

      if (!secondCategoryItem[firstMenu]) {
        secondCategoryItem[firstMenu] = [selected.menuLevel];
      } else if (!secondCategoryItem[firstMenu].includes(selected.menuLevel)) {
        secondCategoryItem[firstMenu].push(selected.menuLevel);
      }

      changeHandler({ 'secondCategory.lists': changedSecondCategoryLists });
    } else {
      _.pull(thirdCategoryItem[firstMenu], selected.menuLevel);
    }

    changeHandler({
      'thirdCategory.lists': changedThirdCategoryLists,
    });
  }

  changeHandler({ 'firstCategory.items': changedFirstCategoryItems });
};
