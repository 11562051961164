/* eslint eqeqeq: 0 */

import { convert, convertOldHelp } from 'activity-templates/utils';

import {
  createProblem,
  createText,
  createFullsizeText,
} from './readingDeluxeHelpers';

import { EBOOK_WIDTH, EBOOK_HEIGHT } from 'consts/ebook-consts';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {};

  const { gameData, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const pdf = (gameData.pdf && gameData.pdf.name) || '';

  const pages = (gameData.pages || []).map((oldPage) => {
    const {
      image,
      imageFnm,
      audioFnm,
      audio,
      background,
      textBlock,
      // images,
      text,
      label,
      instructions,
      timerduration,
    } = oldPage || {};

    const imageToUse = image || imageFnm || background;
    const audioToUse = audio || audioFnm;

    const textOnly = !!(!imageToUse && textBlock && textBlock.length);

    let instructionText = '';

    if (label && label.text) {
      const labelTextWithoutTags = label.text.replace(/<[^>]*>/gm, '').trim();

      instructionText += labelTextWithoutTags;
    }

    if (instructions && instructions.text) {
      const instructionsTextWithoutTags = instructions.text
        .replace(/<[^>]*>/gm, '')
        .trim();

      instructionText +=
        (instructionText.length ? ': ' : '') + instructionsTextWithoutTags;
    }

    const hasTimer = timerduration !== '-1';

    const textToUse =
      textBlock && textBlock.length
        ? [{ ...createFullsizeText(), text: convertOldHelp(textBlock) }]
        : (text || []).map((oldTextObj) => {
            const { dim, text } = oldTextObj;
            const formattedText = convertOldHelp(text);

            const newTextObj = {
              ...createText(),
              dim: {
                width: dim.width + 30 + 'px', // 30px padding
                height: dim.height + 24 + 'px', // 24px padding
                left: (EBOOK_WIDTH / 960) * dim.left + 'px',
                top: (EBOOK_HEIGHT / 710) * dim.top + 'px',
              },
              text: formattedText,
            };

            return newTextObj;
          });

    const newPage = {
      ...createProblem(),
      image: imageToUse,
      audio: audioToUse,
      text: textToUse,
      textOnly,
      instructionText,
      hasTimer,
    };

    return newPage;
  });

  result.gameData = { problems: pages, pdf };

  result.options = {};
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
