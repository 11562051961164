import {
  SHOW_HELP,
  SET_FLINK_PLAY_DATA,
  SET_TEMPLATE_DATA,
  SHOW_SETTINGS,
  FAMILY_UPDATE,
  SHOW_INITIAL_SETTINGS,
  SET_MENU_BUTTONS,
  CLOSE_SETTINGS,
  SET_CURRENT_MENU,
  RESET_FLINK_PLAY,
  SHOW_VIDEO,
  SET_SOLUTION,
  SET_PREV_MENU_BUTTONS,
  SET_ACTIVITY_GROUP,
  UPDATE_LEARNER,
  UPDATE_TEAM,
  GO_BACK_ACTION,
  SAVE_MAIN_MENU_SCROLL_POSITION,
  SET_ACTIVITY_TO_PLAY,
  SET_ACTIVITY_PROGRESS,
  CLOSE_ACTIVITY,
  SHOW_FAMILY_REPORTS,
  SHOW_PARENT_ADVICE,
  SET_PARENT_ADVICE_URL,
  SET_FEEDBACK_COORDS,
  SET_PARENT_ADVICE_COORDS,
  EDIT_FAMILY,
  OPEN_WRITING_FEEDBACK,
  CLOSE_WRITING_FEEDBACK,
  SHOW_FAMILY_POINTS_HISTORY,
  CLOSE_FAMILY_POINTS_HISTORY,
  SET_FAMILY_POINTS,
  SET_PREVIOUS_WEEK_TIME,
  SHOW_QUICKSTART_VIDEOS,
} from 'actions/types';

const INITIAL_STATE = {
  activity: null,
  activityProgress: null,
  learner: null,
  help: null,
  family: null,
  solution: null,
  solutionMenu: null,
  solutionTranslate: null,
  solutionLocale: null,
  currentSettings: null,
  product: null,
  parentAdvice: false,
  parentAdviceUrl: '',
  quickStartVideos: false,
  currentMenu: null,
  currentActivityGroup: null,
  familyReportsOpened: false,
  groupsBreadcrumbs: [],
  isDataLoaded: false,
  showSettings: false,
  isInitialSettings: false,
  templatesData: {},
  mainMenuScroll: null,
  writingFeedback: {
    open: false,
    feedback: null,
  },
  familyPointsHistory: {
    open: false,
    history: null,
  },
  menuButtons: [],
  prevMenuButtons: [],
  previousWeekTime: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_FLINK_PLAY:
      return INITIAL_STATE;
    case OPEN_WRITING_FEEDBACK: 
      return {
        ...state,
        writingFeedback: {
          feedback: action.payload,
          open: true,
        }
      }
    case CLOSE_WRITING_FEEDBACK:
      return {
        ...state,
        writingFeedback: {
          feedback: null,
          open: false,
        }
      }
    case SHOW_FAMILY_POINTS_HISTORY: 
      return {
        ...state,
        familyPointsHistory: {
          history: action.payload,
          open: true,
        }
      }
    case CLOSE_FAMILY_POINTS_HISTORY: 
      return {
        ...state,
        familyPointsHistory: {
          history: null,
          open: false,
        }
      }
    case SET_FAMILY_POINTS:
      return {
        ...state,
        family: {
          ...state.family,
          points: action.payload,
        },
      }
    case SET_PREVIOUS_WEEK_TIME:
      return {
        ...state,
        previousWeekTime: action.payload,
      }
    case SHOW_HELP:
      return {
        ...state,
        help: action.payload,
      };
    case SET_FLINK_PLAY_DATA:
      return {
        ...state,
        ...action.payload,
        isDataLoaded: true,
      };
    case SHOW_VIDEO:
      return {
        ...state,
        video: action.payload,
      };
    case SET_TEMPLATE_DATA: {
      return {
        ...state,
        templatesData: { ...state.templatesData, ...action.payload },
      };
    }
    case FAMILY_UPDATE:
      return {
        ...state,
        family: action.payload,
      };
    case SHOW_SETTINGS:
      return {
        ...state,
        showSettings: true,
      };
    case SAVE_MAIN_MENU_SCROLL_POSITION:
      return {
        ...state,
        mainMenuScroll: action.payload,
      };
    case SET_PARENT_ADVICE_COORDS:
      return {
        ...state,
        parentAdviceCoords: action.payload,
      };
    case SET_FEEDBACK_COORDS:
      return {
        ...state,
        feedbackCoords: action.payload,
      };
    case SHOW_INITIAL_SETTINGS:
      return {
        ...state,
        showSettings: true,
        isInitialSettings: true,
      };
    case CLOSE_SETTINGS:
      const {
        currentSettings: prevSettings,
        learner: { settings },
      } = state;

      const currentSettings = settings.bySolution?.[settings.selectedSolutionId];

      const templatesData =
        prevSettings && prevSettings.theme !== currentSettings.theme
          ? []
          : state.templatesData;

      return {
        ...state,
        currentSettings,
        showSettings: false,
        isInitialSettings: false,
        templatesData,
      };
    case SET_SOLUTION:
      return {
        ...state,
        ...action.payload,
        mainMenuScroll: null,
        currentMenu: null,
        currentActivityGroup: null,
      };
    case SET_ACTIVITY_TO_PLAY:
      return {
        ...state,
        activity: action.payload,
      };
    case CLOSE_ACTIVITY:
      return {
        ...state,
        activity: null,
        previousActivityId: state.activity,
        activityProgress: null,
      };
    case SET_ACTIVITY_PROGRESS:
      return {
        ...state,
        activityProgress: {
          ...(state.activityProgress || {}),
          ...action.payload,
        },
      };
    case SET_CURRENT_MENU:
      return {
        ...state,
        currentMenu: action.payload,
      };
    case SET_ACTIVITY_GROUP: {
      const { currentActivityGroup, groupsBreadcrumbs } = state;

      const modifiedBreadcrumbs = [...groupsBreadcrumbs];

      if (currentActivityGroup) {
        modifiedBreadcrumbs.push(currentActivityGroup);
      }

      return {
        ...state,
        currentActivityGroup: action.payload,
        groupsBreadcrumbs: modifiedBreadcrumbs,
      };
    }
    case UPDATE_LEARNER: {
      return {
        ...state,
        learner: action.payload,
      };
    }
    case UPDATE_TEAM: {
      return {
        ...state,
        team: action.payload,
      };
    }
    case GO_BACK_ACTION: {
      // const groupsBreadcrumbs = state.groupsBreadcrumbs.splice(0, -1);
      // const currentActivityGroup = groupsBreadcrumbs.length
      //   ? state.currentActivityGroup
      //   : null;

      const { groupsBreadcrumbs } = state;

      if (!groupsBreadcrumbs.length) {
        return { ...state, currentActivityGroup: null };
      }

      const previousGroup = groupsBreadcrumbs[groupsBreadcrumbs.length - 1];
      const modifiedCurrentActivityGroup = groupsBreadcrumbs.slice(0, -1);

      return {
        ...state,
        currentActivityGroup: previousGroup,
        groupsBreadcrumbs: modifiedCurrentActivityGroup,
      };
    }
    case SET_MENU_BUTTONS:
      return {
        ...state,
        menuButtons: action.payload,
        prevMenuButtons: state.menuButtons,
      };
    case SET_PREV_MENU_BUTTONS:
      return {
        ...state,
        menuButtons: state.prevMenuButtons,
        prevMenuButtons: [],
      };
    case SHOW_FAMILY_REPORTS:
      return {
        ...state,
        familyReportsOpened: action.payload,
      };
    case SHOW_PARENT_ADVICE:
      return {
        ...state,
        parentAdvice: action.payload,
      };
    case SHOW_QUICKSTART_VIDEOS:
      return {
        ...state,
        quickStartVideos: action.payload,
      };
    case SET_PARENT_ADVICE_URL:
      return {
        ...state,
        parentAdviceUrl: action.payload,
      };
    case EDIT_FAMILY: {
      if (!state.learner || !state.team) return state;

      const stateCopy = { ...state };

      for (const member of action.payload.familyMembers || []) {
        if (member._id === stateCopy.learner._id) {
          stateCopy.learner = { ...stateCopy.learner, ...member };
        }

        stateCopy.team = stateCopy.team.map((m) =>
          m._id === member._id ? { ...m, ...member } : m
        );
      }

      return stateCopy;
    }
    default:
      return state;
  }
}