import { axiosAPI, responseHandler } from 'utils';

export default {
  getPrepaidStatus: (familyId) =>
    responseHandler(axiosAPI.get(`api/families/prepaid-status/${familyId}`)),
  trackPlayTimeForPrepaidPlan: (familyId, timeInMinutes) =>
    responseHandler(
      axiosAPI.post(`api/families/prepaid-status/${familyId}/add-play-time`, {
        timeInMinutes,
      })
    ),
};
