import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { toggleHelp, setHelpCoords } from 'actions/statusActions';
import { imagesURL } from 'config';
import { withTranslate } from 'components/hocs';

import './Help.scss';

class Help extends Component {
  state = {
    btnPosition: null
  };

  componentDidMount() {
    const btnPosition = ReactDOM.findDOMNode(
      this.refs['button']
    ).getBoundingClientRect();

    this.setState({ btnPosition });
  }

  render() {
    const { btnPosition } = this.state;
    const {
      showHelp,
      helpCoords,
      toggleHelp,
      setHelpCoords,
      translate
    } = this.props;

    return (
      <div className="global-help">
        <button
          title={translate(66, 'Help')}
          className="global-help__button"
          onClick={toggleHelp}
          ref={'button'}
        >
          <img
            src={`${imagesURL}/Images/Help/HelpButton.png`}
            alt="Help Button"
          />
        </button>

        {btnPosition && (
          <div className="global-help__content-wrapper">
            <Draggable
              bounds="#root"
              onDrag={setHelpCoords}
              defaultPosition={
                helpCoords || { x: btnPosition.x, y: btnPosition.bottom }
              }
            >
              <div>
                {showHelp && (
                  <div className="global-help__content">
                    {this.props.children}
                  </div>
                )}
              </div>
            </Draggable>
          </div>
        )}
      </div>
    );
  }
}

Help.propTypes = {
  showHelp: PropTypes.bool.isRequired,
  helpCoords: PropTypes.object,
  toggleHelp: PropTypes.func.isRequired,
  setHelpCoords: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = ({ status }) => ({
  showHelp: status.showHelp,
  helpCoords: status.helpCoords
});

export default compose(
  connect(
    mapStateToProps,
    { toggleHelp, setHelpCoords }
  ),
  withTranslate
)(Help);
