import React, { Component } from 'react';
import { validateEmail } from 'validation';
import { reduxForm, SubmissionError, Field } from 'redux-form';
import styled from 'styled-components';
import { axiosAPI as axios } from 'utils';
import PropTypes from 'prop-types';

import { withLoading } from 'utils';
import { renderTextField } from 'components/form-components';

class ForgotPasswordForm extends Component {
  state = {
    success: false,
  };

  handleSubmit = (values) => {
    console.log(values);

    const error = validateEmail(values.email);

    if (error) {
      throw new SubmissionError({ email: error });
    }

    return withLoading(this.props.dispatch, async () => {
      try {
        const response = await axios.post('/api/users/forgot-password', values);
        if (response.data.success) {
          this.setState({ success: true });
        }
      } catch (err) {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.status !== 500
        ) {
          throw new SubmissionError(err.response.data.errors);
        } else {
          console.log(err);
        }
      }
    });
  };

  render() {
    const { success } = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      onSuccess,
      translate,
    } = this.props;

    return success ? (
      <StyledWrapper>
        <p>
          {translate(
            834,
            'The email has been sent. Please check your email and follow the instructions.'
          )}
        </p>
        <StyledButton type="button" onClick={() => onSuccess && onSuccess()}>
          Close
        </StyledButton>
      </StyledWrapper>
    ) : (
      <StyledWrapper>
        <StyledText>
          {translate(
            738,
            'Did you forget your password? Enter your email and we will send you an email with instructions.'
          )}
        </StyledText>

        <form action="#" onSubmit={handleSubmit(this.handleSubmit)}>
          <Field name="email" type="email" component={renderTextField} />

          <StyledButton
            disabled={pristine || submitting}
            type="button"
            onClick={handleSubmit(this.handleSubmit)}
          >
            {translate(835, 'Send')}
          </StyledButton>
        </form>
      </StyledWrapper>
    );
  }
}

ForgotPasswordForm.propTypes = {
  translate: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default reduxForm({
  form: 'ForgotPasswordForm',
})(ForgotPasswordForm);

const StyledWrapper = styled.div`
  min-width: 20rem;
  max-width: 32rem;
`;

const StyledText = styled.p`
  margin-top: 0;
`;

const StyledButton = styled.button`
  background: green;
  color: #fff;
  border: none;
  padding: 8px 30px;
  border-radius: 2px;
  margin-top: 10px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
