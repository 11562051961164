import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Papa from 'papaparse';
import _ from 'lodash';
import { MaterialFaIcon } from 'utils';
import { showError } from 'actions/statusActions';

const styles = theme => ({
  button: {
    marginLeft: theme.spacing()
  },
  leftIcon: {
    marginRight: theme.spacing()
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  iconSmall: {
    fontSize: 20
  },
  hidden: {
    display: 'none'
  }
});

export class ImportCSV extends Component {
  state = {
    selectedFile: ''
  };

  fileSelectedHandler = e => {
    const file = e.currentTarget.files[0] || '';

    e.currentTarget.value = '';
    
    if (!file) {
      return this.setState({ selectedFile: null });
    }

    const { callback, parse, validate, showError } = this.props;
    try {
      if (file && parse) {
        Papa.parse(file, {
          encoding: 'ISO-8859-1',
          complete: results => {
            const data = _.reject(results.data, arr =>
              _.isEmpty(_.compact(arr))
            );

            if (validate) {
              const error = validate(data);

              if (error) {
                return showError({ message: error });
              }
            }

            this.setState({
              selectedFile: file
            });

            callback && callback(data);
          }
        });
      } else {
        this.setState({
          selectedFile: file
        });

        callback && callback(file);
      }
    } catch (err) {
      console.log(err);

      this.setState({
        selectedFile: ''
      });
    }
  };

  render() {
    const { classes, multiple, title, label } = this.props;
    const { selectedFile } = this.state;

    return (
      <Fragment>
        <Typography variant="subtitle1" gutterBottom color="inherit">
          {title}
        </Typography>

        <Typography variant="subtitle1" gutterBottom color="inherit">
          {label}
        </Typography>

        <UploadControlsWrapper>
          <input
            type="file"
            accept=".csv"
            id="contained-button-file"
            multiple={multiple}
            filename={selectedFile && selectedFile.filename}
            onChange={this.fileSelectedHandler}
            className={classes.hidden}
            component="input"
          />
          <StyledLabelWrapper htmlFor="contained-button-file">
            <StyledField>
              {this.state.selectedFile && (
                <span>{this.state.selectedFile.name}</span>
              )}
            </StyledField>
            <Button
              variant="contained"
              className={classes.button}
              component="span"
              size="small"
            >
              Browse
              <MaterialFaIcon
                icon="folder-open"
                className={classes.rightIcon}
              />
            </Button>
          </StyledLabelWrapper>
        </UploadControlsWrapper>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    null,
    { showError }
  )
)(ImportCSV);

const UploadControlsWrapper = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
`;

const StyledField = styled.div`
  width: 200px;
  padding: 0.6rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07);
`;

const StyledLabelWrapper = styled.label`
  display: flex;
  flex-grow: 1;
  min-width: 25rem;
  margin-bottom: 8px;
`;
