import { axiosAPI } from 'utils';

import {
  SET_FAMILIES_AND_MEMBERS,
  ADD_FAMILY,
  CLEAR_FAMILIES,
  EDIT_FAMILY,
  DELETE_FAMILY,
  DELETE_FAMILY_MEMBER
} from './types';
import { withLoading } from 'utils';

const filter = 'families';

export const getFamilies = () => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.get('/api/families/all');

      console.log(response);

      dispatch({
        filter,
        type: SET_FAMILIES_AND_MEMBERS,
        payload: response.data
      });
    } catch (err) {
      console.log(err);
    }
  });

export const getFamiliesAndMembersByLearningCenterId = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.get(
        `/api/families/get-by-learning-center-id/${id}`
      );

      dispatch({
        filter,
        type: SET_FAMILIES_AND_MEMBERS,
        payload: response.data
      });
    } catch (err) {
      console.log(err);
    }
  });

export const getFamiliesAndMembersByTeacherId = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.get(
        `/api/families/get-by-teacher-id/${id}`
      );

      dispatch({
        filter,
        type: SET_FAMILIES_AND_MEMBERS,
        payload: response.data
      });
    } catch (err) {
      console.log(err);
    }
  });

export const getFamilyMembersByFamily = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.get(
        `/api/families/get-family-members-by-family/${id}`
      );

      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  });

export const clearFamilies = () => ({
  filter,
  type: CLEAR_FAMILIES
});

export const addFamily = values => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.post(`/api/families/add`, values);

      console.log(response);

      dispatch({
        filter,
        type: ADD_FAMILY,
        payload: response.data
      });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { success: false, errors: err.response.data };
    }
  });

export const editFamily = values => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.put(`/api/families/edit`, values);

      dispatch({
        filter,
        type: EDIT_FAMILY,
        payload: response.data
      });

      return { success: true, data: response.data };
    } catch (err) {
      console.log(err);
      return { success: false, errors: err.response.data };
    }
  });

export const deleteFamily = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.delete(`/api/families/delete/${id}`);

      if (response.data.success) {
        dispatch({
          filter,
          type: DELETE_FAMILY,
          payload: id
        });
      }
    } catch (err) {
      console.log(err);
    }
  });

export const deleteFamilyMember = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const response = await axiosAPI.delete(
        `/api/families/delete-member/${id}`
      );

      if (response.data.success) {
        dispatch({
          filter,
          type: DELETE_FAMILY_MEMBER,
          payload: id
        });

        return true;
      }
    } catch (err) {
      console.log(err);
    }
  });
