import _ from "lodash";

import {
  ADD_DATA,
  SET_DATA,
  EDIT_DATA,
  DELETE_DATA,
  IMPORT_WORDS,
  IMPORT_TEACHERS,
  IMPORT_GIFT_CARDS,
  CLEAR_ADMIN_DATA,
  ADD_REGION,
  SET_TABLE_STATE,
  ADD_LEARNING_CENTER,
  ADD_MASTER_ORGANIZATION
} from "actions/types";

const INITIAL_STATE = {};

const filter = "admin";

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case CLEAR_ADMIN_DATA:
      return INITIAL_STATE;
    case ADD_DATA:
      return {
        ...state,
        [action.payloadType]: state[action.payloadType] && [
          ...state[action.payloadType],
          action.payload
        ]
      };
    case EDIT_DATA:
      return {
        ...state,
        [action.payloadType]: state[action.payloadType] && [
          ...state[action.payloadType].map(data =>
            data._id === action.payload._id ? action.payload : data
          )
        ]
      };
    case SET_DATA:
      return {
        ...state,
        [action.payloadType]: [...action.payload]
      };
    case DELETE_DATA:
      if (typeof action.payload === "string") {
        return {
          ...state,
          [action.payloadType]: state[action.payloadType].filter(
            data => data._id !== action.payload
          )
        };
      }

      const deletedData = action.payload;
      const stateCopy = { ...state };

      _.map(deletedData, (deletedIDs, dataName) => {
        if (!stateCopy[dataName]) return;

        stateCopy[dataName] = stateCopy[dataName].filter(
          data => !deletedIDs.includes(data._id)
        );
      });

      return stateCopy;
    case IMPORT_WORDS:
      return {
        ...state,
        words: action.payload
      };
    case IMPORT_GIFT_CARDS:
      return {
        ...state,
        giftCards: action.payload
      };
    case IMPORT_TEACHERS:
      return {
        ...state,
        users: state.users && [...state.users, ...action.payload]
      };
    case ADD_MASTER_ORGANIZATION:
      return {
        ...state,
        users: state.users && [
          ...state.users,
          action.payload.masterOrganizationAdmin
        ],
        masterOrganizations: state.masterOrganizations && [
          ...state.masterOrganizations,
          action.payload.masterOrganization
        ]
      };
    case ADD_REGION:
      return {
        ...state,
        users: state.users && [...state.users, action.payload.regionAdmin],
        regions: state.regions && [...state.regions, action.payload.region]
      };
    case ADD_LEARNING_CENTER:
      return {
        ...state,
        users: state.users && [
          ...state.users,
          action.payload.learningCenterAdmin
        ],
        learningCenters: state.learningCenters && [
          ...state.learningCenters,
          action.payload.learningCenter
        ]
      };
    case SET_TABLE_STATE: {
      return {
        ...state,
        tablesStates: { ...state.tablesStates, ...action.payload }
      };
    }
    default:
      return state;
  }
}
