import { CleverIntegration } from 'shared/types';
import { axiosAPI, responseHandler } from 'utils';

export default {
  getListNames() {
    return responseHandler(
      axiosAPI.get('api/admin/masterOrganizations/list-names')
    );
  },

  getPlansStatus(masterOrganizationId: string) {
    return responseHandler(
      axiosAPI.get(
        `/api/admin/masterOrganizations/get-plans-status/${masterOrganizationId}`
      )
    );
  },

  getCleverIntegration(
    masterOrganizationId: string
  ): Promise<{ success: true; data: CleverIntegration | null }> {
    return responseHandler(
      axiosAPI.get(
        `api/admin/masterOrganizations/${masterOrganizationId}/clever`
      )
    );
  },

  integrateWithClever(
    masterOrganizationId: string,
    data: {
      cleverDistrictId: string;
      schoolAdminRoleId: string;
      teacherRoleId: string;
      productId: string;
    }
  ): Promise<
    { success: true } | { success: false; errors?: Record<string, string> }
  > {
    return responseHandler(
      axiosAPI.post(
        `api/admin/masterOrganizations/${masterOrganizationId}/integrate-clever`,
        data
      )
    );
  },

  deleteCleverIntegration(
    masterOrganizationId: string
  ): Promise<
    { success: true } | { success: false; errors?: Record<string, string> }
  > {
    return responseHandler(
      axiosAPI.post(
        `api/admin/masterOrganizations/${masterOrganizationId}/delete-clever-integration`
      )
    );
  },

  syncClever(
    masterOrganizationId: string
  ): Promise<{ success: true; data: { msg: string } } | { success: false }> {
    return responseHandler(
      axiosAPI.get(
        `api/admin/masterOrganizations/${masterOrganizationId}/sync-clever`
      )
    );
  },
};
