import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({

  palette: {
    primary: blue,
    secondary: green,
    background: {
      paper: '#fff',
      default: '#fff'
    },
    action: {
      selected: '#2196F3'
    }
  },
  typography: {
    useNextVariants: true,
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10 
  }
});

export default theme;
