import React, { Component } from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  reset,
} from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  renderCheckbox,
  // ReportRateFields,
  CheckboxFieldsGroup,
  FieldSet,
  renderSelect,
  formatCheckboxesInArray,
  StyledError,
  StyledSuccess,
  FormButtons,
  FamilyPointsField,
  getOptionsFromLists,
} from 'components/form-components';
import { editData } from 'actions/adminActions';
import { reduxFormValidator } from 'validation';
import { formatLocalesToOptions } from 'utils';
import { MASTER_ADMIN, SUPER } from 'consts/user-roles';

const requiredFields = [
  'config.percentActivities',
  'config.percentAnswers',
  'config.percentBooks',
  // 'config.reportsRate',
  'config.locales',
  'config.defaultLocale',
];

const validate = reduxFormValidator(requiredFields);

export class OrganizationConfigForm extends Component {
  state = { success: false };

  componentDidMount() {
    const { locales, lists, getOptionsFromLists, initialValues } = this.props;

    const localesOptions = formatLocalesToOptions(locales);

    const [
      percentActivities,
      percentAnswers,
      percentBooks,
    ] = getOptionsFromLists(
      [
        {
          name: 'config.percentActivities',
          list: 'Percents',
        },
        {
          name: 'config.percentAnswers',
          list: 'Percents',
        },
        {
          name: 'config.percentBooks',
          list: 'Percents',
        },
      ],
      lists,
      'OrganizationConfigForm',
      initialValues
    );

    this.setState({
      percentActivitiesOptions: percentActivities.options,
      percentAnswersOptions: percentAnswers.options,
      percentBooksOptions: percentBooks.options,
      localesOptions,
    });
  }

  submit = async (values, dispatch) => {
    const { editData, onSuccess, type } = this.props;

    const formattedValues = JSON.parse(JSON.stringify(values));

    formattedValues.config.locales = [
      ...formatCheckboxesInArray(formattedValues.config.locales),
    ];

    // At least one language must be selected
    if (!formattedValues.config.locales.length) {
      throw new SubmissionError({
        _error: '* - At least one language must be selected!',
      });
    }

    const result = await editData(type, formattedValues);

    if (!result.success) {
      this.setState({ success: false });

      console.log('Server response:', result);
      throw new SubmissionError(result.err);
    } else {
      dispatch(reset('OrganizationConfigForm'));
      this.setState({ success: true });
      onSuccess && onSuccess();
    }
  };

  render() {
    const {
      error,
      handleSubmit,
      pristine,
      submitting,
      reset,
      // reportsRate,
      initialValues,
      noReports,
      userRoleAlias,
      // type,
    } = this.props;

    const {
      success,
      localesOptions,
      percentActivitiesOptions,
      percentAnswersOptions,
      percentBooksOptions,
    } = this.state;

    const isSuperOrMasterAdmin = [SUPER, MASTER_ADMIN].includes(userRoleAlias);
    const successMessage = 'Successfully updated';

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <FieldSet>
          {localesOptions && (
            <CheckboxFieldsGroup
              name="config.locales"
              fields={localesOptions}
              groupLabel={'Locales *'}
              form="OrganizationConfigForm"
              initialValues={initialValues}
              defaultSelect={{
                name: 'config.defaultLocale',
                label: 'Default Language',
              }}
            />
          )}

          <Field
            disabled={!isSuperOrMasterAdmin}
            name="config.noFamilyVideo"
            label="Dont show family video"
            component={renderCheckbox}
          />
          <Field
            disabled={!isSuperOrMasterAdmin}
            name="config.noSupportPhone"
            label="Hide support phone"
            component={renderCheckbox}
          />
          <Field
            disabled={!isSuperOrMasterAdmin}
            name="config.noSettings"
            label="Hide Settings global button"
            component={renderCheckbox}
          />
          <Field
            disabled={!isSuperOrMasterAdmin}
            name="config.noLogout"
            label="Hide Logout global button"
            component={renderCheckbox}
          />

          <Field
            disabled={!isSuperOrMasterAdmin}
            name="config.noReports"
            label="Not save record-keeping data"
            component={renderCheckbox}
          />
          <Field
            disabled={!isSuperOrMasterAdmin}
            name="config.offline"
            label="Offline"
            component={renderCheckbox}
          />

          {initialValues.plansType === 'PREPAID' && (
            <CheckboxFieldsGroup
              disabled={!isSuperOrMasterAdmin}
              name="config.myAccountValues"
              fields={[
                { value: 'totalHoursPaid', label: 'Total Hours Paid' },
                { value: 'costPerHour', label: 'Cost Per Hour' },
                {
                  value: 'remainingHoursPrice',
                  label: 'Remaining Hours Price',
                },
                { value: 'hoursRemaining', label: 'Hours Remaining' },
                { value: 'buyHours', label: 'Buy Hours' },
                { value: 'buyout', label: 'Buyout' },
              ]}
              groupLabel={'My Account Values'}
              form="OrganizationConfigForm"
              initialValues={initialValues}
            />
          )}

          {!noReports && (
            <>
              {/* <ReportRateFields
                reportsRate={reportsRate}
                formName={'OrganizationConfigForm'}
              /> */}

              <Field
                name="config.percentAnswers"
                label="% correct answers for an activity to get a green star"
                component={renderSelect}
                options={percentAnswersOptions || []}
              />

              <Field
                name="config.percentActivities"
                label="% activities for a group of activities to get a green star"
                component={renderSelect}
                options={percentActivitiesOptions || []}
              />

              <Field
                name="config.percentBooks"
                label="% books in a group of books for the group to get a green star"
                component={renderSelect}
                options={percentBooksOptions || []}
              />
            </>
          )}
        </FieldSet>

        <FamilyPointsField />

        {!pristine && error && <StyledError>{error}</StyledError>}
        {pristine && success && <StyledSuccess>{successMessage}</StyledSuccess>}

        <FormButtons
          pristine={pristine}
          submitting={submitting}
          isEdit
          handleReset={reset}
        />
      </form>
    );
  }
}

OrganizationConfigForm.propTypes = {
  noReports: PropTypes.bool,
  // reportsRate: PropTypes.string,
  lists: PropTypes.array.isRequired,
  locales: PropTypes.array.isRequired,
  getOptionsFromLists: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
};

const selector = formValueSelector('OrganizationConfigForm');

const mapStateToProps = (state) => ({
  userRoleAlias: state.auth.userRole.alias,
  // reportsRate: selector(state, 'config.reportsRate'),
  noReports: selector(state, 'config.noReports'),
});

export default compose(
  reduxForm({
    form: 'OrganizationConfigForm',
    enableReinitialize: true,
    initialValues: {},
    validate,
  }),
  connect(mapStateToProps, { getOptionsFromLists, editData })
)(OrganizationConfigForm);
