import { combineReducers, StateFromReducersMapObject } from 'redux';
import { reducer as formReducer } from 'redux-form';
import admin from './adminReducer';
import audio from './audioReducer';
import auth from './authReducer';
import common from './commonReducer';
import families from './familiesReducer';
import flinkAdmin from './flinkAdminReducer';
import flinkPlay from './flinkPlayReducer';
import status, { INITIAL_STATE as INITIAL_STATUS_STATE } from './statusReducer';
import flinkMake from './flinkMakeReducer';
import game from './gameReducer';

import { LOGOUT } from 'actions/types';

const reducersMap = {
  audio,
  auth,
  admin,
  common,
  flinkAdmin,
  status,
  families,
  flinkMake,
  flinkPlay,
  game,
  form: formReducer,
};

export type RootState = StateFromReducersMapObject<typeof reducersMap>;

const appReducer = combineReducers(reducersMap);

const rootReducer: typeof appReducer = (state, action) => {
  console.log(action.type);
  if (action.type === LOGOUT) {
    // This resets the entire application state, except common data (LCs, Regions, etc)
    // And status: lang and the translate function
    state = { 
      common: state?.common,
      status: {
        ...INITIAL_STATUS_STATE,
        lang: (state?.status as any).lang,
        translate: (state?.status as any).translate,
      } 
    } as RootState;
  }

  return appReducer(state, action);
};

export default rootReducer;
// export default appReducer;
