import AllTogetherNow from './AllTogetherNow';
import convertOldActivity from './convertOldAllTogetherNow';
import validateActivity from './validateAllTogetherNow';

export default {
  Template: AllTogetherNow,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'randomOrder',
    'questionsLimit',
    'showAnswer'
  ]
};
