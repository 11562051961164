import adminData from './adminDataAPI';
import activity from './activityAPI';
import game from './gameAPI';
import config from './configAPI';
import currencies from './currenciesAPI';
import storage from './storageAPI';
import products from './productsAPI';
import reports from './reportsAPI';
import purchase from './purchaseAPI';
import activityGroups from './activityGroupsAPI';
import solutions from './solutionsAPI';
import family from './familyAPI';
import familyMembers from './familyMembersAPI';
import templates from './templatesAPI';
import pendingRegistration from './pendingRegistrationAPI';
import organizationPurchase from './organizationPurchaseAPI';
import teachers from './teachersAPI';
import learningCenters from './learningCentersAPI';
import masterOrganizations from './masterOrganizationsAPI';
import polly from './pollyAPI';
import strings from './stringsAPI';

const api = {
  adminData,
  activity,
  game,
  config,
  currencies,
  storage,
  products,
  reports,
  purchase,
  activityGroups,
  solutions,
  family,
  familyMembers,
  templates,
  organizationPurchase,
  pendingRegistration,
  teachers,
  learningCenters,
  masterOrganizations,
  polly,
  strings,
};

export default api;
