import classes from './QuickStartVideos.module.scss';

// actions
import { showVideo, showQuickstartVideos } from 'actions/flinkPlayActions';

// redux
import { connect } from 'react-redux';

//
import PropTypes from 'prop-types';
import GameModal from '../GameModal/GameModal';
import { filesURL } from 'config';

const QuickStartVideos = ({ translate, language, allVideos, ...props }) => {
  const { showQuickstartVideos, showVideo } = props;
  const videos = allVideos[language.code] || [];

  const showQuickStartVideo = (filename) => {
    showVideo({
      src: `${filesURL}/Files/animations/QuickStart/${language.name}/${filename}`,
      callback: (ref) => {
        if (!ref)
          return showVideo(null);
        return ref.current?.showPreview();
      },
    });
  }

  return (
    <GameModal
      title={translate(1320, 'QuickStart Videos')}
      buttons={[
        {
          title: translate(80, 'Close'),
          clickHandler: showQuickstartVideos.bind(this, false),
          // icon: `${imagesURL}/Images/Help/Close50.png`,
        },
      ]}
      innerClassnames={[classes.fontWrapper, classes.modal]}
    >
      <div className={classes.grid}>
        {videos?.map((video) => (
          <li key={video.value} onClick={showQuickStartVideo.bind(this, video.value)}>
            {translate(video.label, '', true, false, '', true)}
          </li>
        ))}
      </div>
    </GameModal>
  );
}

QuickStartVideos.propTypes = {
  language: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  allVideos: PropTypes.object.isRequired,
};

const mapStateToProps = ({ 
  status, 
  flinkPlay,
  common: {
    config: {
      loginPageDocuments: { quickStart }
    }
  },
}) => ({
  translate: flinkPlay.solutionTranslate || status.translate,
  allVideos: quickStart || {},
});

const mapDispatchToProps = {
  showVideo,
  showQuickstartVideos,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickStartVideos);
