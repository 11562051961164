import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import GameModal from '../GameModal/GameModal';
import { toggleAudio, stopAudio } from 'actions/audioActions';
import { showHelp } from 'actions/flinkPlayActions';
import { formatHtmlForPlay } from 'utils';
import classes from './Help.module.scss';
import { imagesURL } from 'config';

const Help = ({ showHelp, help, translate, audioSrc, isPlaying }) => {
  const { title, stringNumber, cb, customTranslate, audioHelp, data } = help;

  const translateFn = customTranslate || translate;

  const html = translateFn && translateFn(stringNumber, '', false, true);

  const formattedHelp = useMemo(() => formatHtmlForPlay({ html, data }), [
    html,
    data,
  ]);

  useEffect(() => {
    if (audioHelp) {
      toggleAudio(audioHelp);
    }
  }, [audioHelp]);

  const audioIcon = `${imagesURL}/Images/Audio/${
    audioSrc === audioHelp && isPlaying ? 'audiostop' : 'audio_icon1'
  }.png`;

  return (
    <GameModal
      title={title || translateFn(66, 'Help')}
      buttons={[
        {
          // title: translateFn(224, 'Listen'),
          clickHandler: (e) => toggleAudio(audioHelp),
          dontShow: !audioHelp,
          icon: audioIcon,
        },
        {
          // title: translateFn(80, 'Close'),
          icon: `${imagesURL}/Images/Help/Close50.png`,
          clickHandler: () => {
            stopAudio();
            cb && cb();
            showHelp(null);
          },
        },
      ]}
    >
      <div
        className={classes.wrapper}
        dangerouslySetInnerHTML={{ __html: formattedHelp }}
      />
    </GameModal>
  );
};

Help.propTypes = {
  audioSrc: PropTypes.string,
  isPlaying: PropTypes.bool,
  customTranslate: PropTypes.func,
  translate: PropTypes.func,
  showHelp: PropTypes.func.isRequired,
  audioHelp: PropTypes.string,
  help: PropTypes.object.isRequired,
};

const mapStateToProps = ({ flinkPlay, audio }) => ({
  audioSrc: audio.audioSrc,
  isPlaying: audio.isPlaying,
  help: flinkPlay.help,
  translate: flinkPlay.solutionTranslate,
});

export default compose(connect(mapStateToProps, { showHelp }))(Help);
