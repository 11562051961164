export default (ms, opts = {}) => {
  const { translate, withoutDays } = opts;

  const daysString = translate ? translate(724) : 'day(s)';
  const hoursString = translate ? translate(722) : 'hour(s)';
  const minsString = translate ? translate(723) : 'minute(s)';

  let days, hours;
  let seconds = Math.round(ms / 1000);

  if (!withoutDays) {
    days = Math.floor(seconds / (60 * 60 * 24));
    seconds = seconds % (60 * 60 * 24);
  }

  hours = Math.floor(seconds / (60 * 60));
  seconds = seconds % (60 * 60);

  let minutes = Math.floor(seconds / 60);

  let str = '';

  if (days) str += `${days} ${daysString} `;
  if (hours) str += `${hours} ${hoursString} `;

  str += `${minutes} ${minsString}`;

  return str;
};
