import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

import { WhiteBox } from 'components/flink-play';
import { ItemSlot } from 'components/flink-play/dnd-components';
import classes from './LayoutBox.module.scss';

function getMatches(string, regex, index) {
  index || (index = 1); // default to the first capturing group
  var matches = [];
  var match;

  while ((match = regex.exec(string))) {
    matches.push(match[index]);
  }
  return matches;
}

const parseHtml = html => {
  const regExp = /<(?:p|div)>(?<block>[\s\S]+?)<\/(?:p|div)>/gi;
  const matched = getMatches(html, regExp);

  const blocks = matched.map(m => {
    const parts = m.split(/<\/?u>?/);
    return parts.map(p => {
      if (p.indexOf('data-word-id') === -1) {
        return { text: p };
      }

      const [, id, word] = p.match(/data-word-id="(\S+?)">([\s\S]+?)$/);
      return { id, word };
    });
  });
  return blocks;
};

const LayoutBox = ({
  contentPath,
  problem,
  refs,
  items,
  ItemComponent,
  isResolved,
  onDrop
}) => {
  const { html, layoutType } = problem;

  const parsedHtmlBlocks = useMemo(() => parseHtml(html), [html]);

  const imgSrc = problem.image ? `${contentPath}/${problem.image}` : null;

  // const longestWord = useMemo(() => {
  //   return _.maxBy(items, i => i.data.text.length).data.text;
  // }, [items]);

  let slotIdx = -1;

  return (
    <WhiteBox
      outerClass={classes.wrapper + ' ' + classes[layoutType]}
      innerClass={classes.layout}
    >
      <div className={classes.textWrapper}>
        {parsedHtmlBlocks.map((block, idx) => {
          return (
            <div key={idx} className={classes.textBlock}>
              {block.map((part, idx) => {
                if (part.text !== undefined)
                  return (
                    <div
                      key={idx}
                      dangerouslySetInnerHTML={{ __html: part.text }}
                    ></div>
                  );

                slotIdx++;

                const { id, word } = part;

                const itemInThatSlot = _.find(items, { slotIdx });

                return (
                  <div
                    className={classes.slotWrapper}
                    key={id}
                    data-word={word}
                  >
                    <ItemSlot
                      key={id}
                      isResolved={isResolved}
                      slotRef={refs[slotIdx]}
                      className={classnames(classes.slot)}
                      onDropHandler={onDrop}
                      item={itemInThatSlot}
                      slotIdx={slotIdx}
                      ItemComponent={ItemComponent}
                    />

                    <span className={classes.spacer}>{word}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className={classes.imageWrapper}>
        {imgSrc && <img  src={imgSrc} alt="" />}
      </div>

      {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
      {/* {_.times(count, idx => {
        const itemInThatBox = _.find(items, { slotIdx: idx });

        return (
          <div className={classes.slotWrapper} key={idx}>
            {parsedHtmlBlocks.map(block => (
              <p>
                {block.map(
                  part =>
                    part.text || (
                      <ItemSlot
                        isResolved={isResolved}
                        slotRef={refs[idx]}
                        className={classnames(classes.slot)}
                        onDropHandler={onDrop}
                        item={itemInThatBox}
                        slotIdx={idx}
                        ItemComponent={ItemComponent}
                      />
                    )
                )}
              </p>
            ))}
          </div>
        );
      })} */}
    </WhiteBox>
  );
};

LayoutBox.propTypes = {
  items: PropTypes.array.isRequired,
  onDrop: PropTypes.func.isRequired
};

export default LayoutBox;
