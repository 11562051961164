import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

import { getAnimationsList } from 'actions/solutionsActions';
import { renderSelect } from 'components/form-components';

const ParentVideosControls = ({
  locales,
  getAnimationsList,
  name = 'parentVideos',
  title = 'Parent Videos:',
}) => {
  const [parentVideos, setParentVideos] = useState(null);
  useEffect(() => {
    const getList = async () => {
      const result = await getAnimationsList(['ParentVideos']);
      setParentVideos(result.ParentVideos);
    };

    getList();
  }, [getAnimationsList]);

  return (
    <div>
      <div style={{ margin: '2rem 0' }}>
        <b>{title}</b>
      </div>

      {parentVideos &&
        locales.map((loc) => {
          const byLocale = parentVideos[loc.name] || [];

          const parentVideosOptions = byLocale.map((a) => ({
            label: a.filename,
            value: a.filename,
          }));

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 15,
              }}
              key={loc._id}
            >
              <div style={{ width: '80px', marginRight: '2rem' }}>
                <p style={{ textAlign: 'right', margin: 0 }}>
                  {loc.name}
                </p>
              </div>

              <div style={{ width: '200px' }}>
                <Field
                  name={`${name}[${loc.code}]`}
                  label="Parent Video"
                  component={renderSelect}
                  isClearable
                  options={parentVideosOptions}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

ParentVideosControls.propTypes = {
  getAnimationsList: PropTypes.func.isRequired,
  locales: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  locales: state.common.locales,
});

export default connect(mapStateToProps, { getAnimationsList })(
  ParentVideosControls
);
