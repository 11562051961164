import React from 'react';
import ReactDatePicker from 'react-datepicker';

import TextField from '@material-ui/core/TextField';
import classes from './Datepicker.module.scss';

const DatePicker = ({ calendarClassName, ...props }) => {
  return (
    <ReactDatePicker
      customInput={
        <TextField variant="outlined" size="small" />
      }
      calendarClassName={`${classes.datePicker} ${calendarClassName || ''}`}
      {...props}
    />
  );
};

export default DatePicker;
