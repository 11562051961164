import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTranslate } from 'components/hocs';
import { prepareButtons } from 'utils';

import WhiteBox from '../WhiteBox/WhiteBox';
import classes from './Buttons.module.scss';

const Buttons = ({
  buttons,
  globalButtons,
  translate,
  solutionTranslate
}) => {
  const translateToUse = useMemo(() => solutionTranslate || translate, [
    solutionTranslate,
    translate
  ]);

  const preparedButtons = useMemo(
    () => prepareButtons(buttons, globalButtons, translateToUse),
    [buttons, globalButtons, translateToUse]
  );

  return (
    <WhiteBox outerClass={classes.outerClass} innerClass={classes.innerClass}>
      {preparedButtons.map((btn, index) => (
        <div key={index}>{btn}</div>
      ))}
    </WhiteBox>
  );
};

Buttons.propTypes = {
  buttons: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  solutionTranslate: PropTypes.func
};

const mapStateToProps = ({ flinkPlay, common }) => ({
  globalButtons: common.globalButtons,
  solutionTranslate: flinkPlay.solutionTranslate
});

export default compose(
  withTranslate,
  connect(mapStateToProps)
)(Buttons);
