import { axiosAPI } from 'utils';

export default {
  getList(params) {
    return axiosAPI
      .get(`api/products`, { params })
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return err.response.data;
      });
  },

  getProductSolutionsList(id) {
    return axiosAPI
      .get(`api/products/${id}/solutions-list`)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return err.response.data;
      });
  },

  getUserAvailableProducts() {
    return axiosAPI
      .get('api/products/get-user-available')
      .then((res) => res.data)
      .catch((err) => err.response.data);
  },
  
  getProductsNames() {
    return axiosAPI
      .get('api/products/names')
      .then((res) => res.data)
      .catch((err) => err.response.data);
  },
};
