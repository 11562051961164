import React from "react";
import PropTypes from "prop-types";

const WritingAssistantEditorToolbar = ({ id, clipartButton, importArtButton, writingFeedbackButton }) => {
  return (
    <div data-toolbar-id={id}>

      <span className="ql-writing-feedback">
        {writingFeedbackButton}
      </span>
      
      <select className="ql-size" defaultValue="18px">
        <option value="8px">8</option>
        <option value="10px">10</option>
        <option value="12px">12</option>
        <option value="13px">13</option>
        <option value="14px">14</option>
        <option value="16px">16</option>
        <option value="18px">18</option>
        <option value="20px">20</option>
        <option value="22px">22</option>
        <option value="24px">24</option>
        <option value="30px">30</option>
        <option value="32px">32</option>
        <option value="36px">36</option>
        <option value="48px">48</option>
      </select>

      {/* <select className="ql-font" defaultValue="Arial">
        <option value="Arial">Arial</option>
        <option value="Arial Black">Arial Black</option>
        <option value="Bookman">Bookman</option>
        <option value="Comic Sans MS">Comic Sans MS</option>
        <option value="Courier">Courier</option>
        <option value="Courier New">Courier New</option>
        <option value="Garamond">Garamond</option>
        <option value="Georgia">Georgia</option>
        <option value="Helvetica">Helvetica</option>
        <option value="Impact">Impact</option>
        <option value="Palatino">Palatino</option>
        <option value="Roboto">Roboto</option>
        <option value="Times New Roman">Times New Roman</option>
        <option value="Times">Times</option>
        <option value="Tahoma">Tahoma</option>
        <option value="Verdana">Verdana</option>
      </select> */}

      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </span>

      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
        {/* <select className="ql-background" /> */}
      </span>

      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        {/* <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" /> */}
      </span>

      <span className="ql-formats ql-formats--images">
        {importArtButton}
        {clipartButton}
      </span>
    </div>
  );
};

WritingAssistantEditorToolbar.propTypes = {
  id: PropTypes.string.isRequired,
  withImage: PropTypes.bool,
  withVideo: PropTypes.bool,
  withLink: PropTypes.bool
};

export default WritingAssistantEditorToolbar;
