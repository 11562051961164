import _ from 'lodash';

import { axiosAPI as axios, withLoading } from 'utils';
import {
  SHOW_FEEDBACK,
  CLOSE_FEEDBACK,
  SHOW_STATS,
  SET_CURRENT_ACTIVITY,
  OPEN_ACTIVITY_HELP,
  CLOSE_ACTIVITY_HELP,
  OPEN_TEAM_ACTIVITY_HELP,
  CLOSE_TEAM_ACTIVITY_HELP,
  GAME_INITIALIZED,
  RESTART_ACTIVITY,
  SHOW_GAME_PARENT_ADVICE,
  SHOW_GAME_MULTIPLAYER_ADVICE,
  ADD_IDLE_TIME,
  CHANGE_PLAYER_TURN,
  INCREMENT_PLAYER_POINTS,
  SET_PLAYER_POWER_LEVEL,
} from 'actions/types';
import API from 'api';

const getActivityTemplate = (activity, activityTemplates) => {
  return _.find(activityTemplates, { _id: activity.templateId });
};

const filter = 'game';

export const setCurrentActivity = (
  activity,
  activityTemplates,
  options = {}
) => (dispatch) =>
  withLoading(dispatch, async () => {
    // if null passed
    if (!activity) {
      return dispatch({
        filter,
        type: SET_CURRENT_ACTIVITY,
        payload: null,
      });
    }

    // if activity object passed
    if (typeof activity === 'object') {
      const { 
        activity: _activity, // extract activity value from activity object
        template: _template, // extract template value from activity object
      } = activity;

      // if template is set, use it, otherwise extract activity template
      // using activity
      const { multiplayer } = _template ?? getActivityTemplate(_activity, activityTemplates);
      const multiplayerModeEnabled = multiplayer && options.players.length > 1;

      const multiplayerMixin = multiplayerModeEnabled ? 
        prepareMultiplayerState(options.players) :
        {};

      return dispatch({
        filter,
        type: SET_CURRENT_ACTIVITY,
        payload: Object.assign({ 
          currentActivity: activity, 
          multiplayerModeEnabled,
          ...options,
        }, multiplayerMixin),
      });
    }

    // if activity ID passed
    if (typeof activity === 'string') {
      const payload = await getActivityData(activity, activityTemplates);
      const { template: { multiplayer } } = payload;
      const multiplayerModeEnabled = multiplayer && options.players.length > 1;

      const multiplayerMixin = multiplayerModeEnabled ? 
        prepareMultiplayerState(options.players) :
        {};

      return dispatch({
        filter,
        type: SET_CURRENT_ACTIVITY,
        payload: Object.assign({ 
          currentActivity: payload, 
          multiplayerModeEnabled,
          ...options 
        }, multiplayerMixin),
      });
    }
  });

export const getActivityData = (id, activityTemplates) => {
  const result = {};

  return axios
    .get(`api/flink-play/activities/${id}`)
    .then((res) => {
      result.activity = res.data;
      result.template = getActivityTemplate(res.data, activityTemplates);

      return API.activity.getActivityData(id, result.template);
    })
    .then((data) => {
      if (!data) return null;

      result.data = data;

      return result;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const prepareMultiplayerState = (players) => {
  const pointEntries = players.map(({ _id }) => [_id, 0]);
  const powerLevelEntries = players.map(({ _id }) => [_id, 1]);

  return {
    turningPlayerId: _.first(players)._id,
    powerLevels: Object.fromEntries(powerLevelEntries),
    points: Object.fromEntries(pointEntries),
  };
};

export const openActivityHelp = () => ({
  filter,
  type: OPEN_ACTIVITY_HELP,
});

export const closeActivityHelp = () => ({
  filter,
  type: CLOSE_ACTIVITY_HELP,
});

export const openActivityTeamHelp = () => ({
  filter,
  type: OPEN_TEAM_ACTIVITY_HELP,
});

export const closeActivityTeamHelp = () => ({
  filter,
  type: CLOSE_TEAM_ACTIVITY_HELP,
});

export const restartActivity = () => ({
  filter,
  type: RESTART_ACTIVITY,
});

export const setGameInitialized = () => ({
  filter,
  type: GAME_INITIALIZED,
});

export const showStats = (payload) => ({
  filter,
  type: SHOW_STATS,
  payload,
});

export const showGameParentAdvice = (payload) => ({
  filter,
  type: SHOW_GAME_PARENT_ADVICE,
  payload,
});

export const showGameMultiplayerAdvice = (payload) => ({
  filter,
  type: SHOW_GAME_MULTIPLAYER_ADVICE,
  payload,
});

export const showFeedback = (payload) => ({
  filter,
  type: SHOW_FEEDBACK,
  payload,
});

export const closeFeedback = () => ({
  filter,
  type: CLOSE_FEEDBACK,
});

export const addIdleTime = (payload) => ({
  filter,
  type: ADD_IDLE_TIME,
  payload,
});

export const changePlayerTurn = () => {
  return {
    filter,
    type: CHANGE_PLAYER_TURN,
  }
};

export const incrementPlayerPoints = (numGuesses) => {
  return {
    filter,
    type: INCREMENT_PLAYER_POINTS,
    payload: { numGuesses },
  }
};

export const setPlayerPowerLevel = (playerId, powerLevel) => {
  return {
    filter,
    type: SET_PLAYER_POWER_LEVEL,
    payload: {
      id: playerId,
      powerLevel: powerLevel,
    },
  }
};
