import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Portal from './Portal';

class Modal extends Component {
  render() {
    const { on, toggle, children, bigZIndex } = this.props;

    return on ? (
      <Portal>
        <Overlay bigZIndex={bigZIndex} onClick={toggle} />
        <ModalWindow bigZIndex={bigZIndex}>
          <CloseButton title="Close" onClick={toggle}>
            &times;
          </CloseButton>
          {children}
        </ModalWindow>
      </Portal>
    ) : null;
  }
}

Modal.propTypes = {
  on: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default Modal;

const Overlay = styled.div`
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ bigZIndex }) => (bigZIndex ? 9000 : 1500)};
`;

const ModalWindow = styled.div`
  background-color: #fff;
  padding: 4rem 3rem 3rem;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: ${({ bigZIndex }) => (bigZIndex ? 9010 : 1510)};
  transform: translate(-50%, -50%);
  max-width: 90vw;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 3rem;
  line-height: 0.5;
  padding: 0;
  border-radius: 50%;
  padding: 0.5rem 0.5rem 0.6rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
`;
