import _ from 'lodash';
import shortid from 'shortid';

import { addIdToFilename } from 'utils';
import { getWordPictureFilename } from 'utils/wordlistHelpers';

export const validateItemHasFeedback = (item) => {
  return Boolean(item.feedbackAudio || item.feedbackText);
};

export const validateAnswer = (answer) =>
  !!(answer && (answer.text || answer.image));

export const getValidItemsWithoutFeedback = (problem) => {
  const { correct, incorrectAnswers } = problem || {};
  const itemsWithoutFeedback = [correct, ...(incorrectAnswers || [])].filter(
    (item) => validateAnswer(item) && !validateItemHasFeedback(item)
  );
  return itemsWithoutFeedback;
};

export const validateQuestion = (question) => {
  return !!(
    (question.problem || question.audio) &&
    validateAnswer(question.correct) &&
    _.filter(question.incorrectAnswers, (answer) => validateAnswer(answer))
      .length &&
    (!question.specificFeedback ||
      getValidItemsWithoutFeedback(question).length === 0)
  );
};

export const createProblemFromTextImport = (textArray) => {
  const [questionText, correctAnswer, ...incorrectAnswers] = textArray;

  const newQuestion = createQuestion();
  newQuestion.problem = questionText;
  newQuestion.correct = { text: correctAnswer };
  newQuestion.incorrectAnswers = _.chain(incorrectAnswers)
    .map((ans) => ({ text: ans }))
    .take(4)
    .value();

  return newQuestion;
};

export const addMediaToProblems = (problems = [], media) => {
  const problemsCopy = JSON.parse(JSON.stringify(problems));

  media.forEach((m) => {
    const { fileType, problemIdx, itemType, itemIdx, filename } = m;

    switch (itemType) {
      case 'q':
        // Question
        _.set(problemsCopy, `[${problemIdx}].${fileType}`, filename);
        break;
      case 'ca':
        // Question
        _.set(problemsCopy, `[${problemIdx}].correct.${fileType}`, filename);
        break;
      case 'ica':
        // Question
        _.set(
          problemsCopy,
          `[${problemIdx}].incorrectAnswers[${itemIdx}].${fileType}`,
          filename
        );
        break;
      default:
    }
  });

  return problemsCopy;
};

export const createQuestion = () => ({
  id: shortid.generate(),
  problem: '',
  correct: {},
  incorrectAnswers: [],
});

export const getContentFilesFromProblem = (problem) => {
  const files = [];
  const { correct, incorrectAnswers } = problem;

  problem.audio && files.push(problem.audio);

  if (correct) {
    correct.image && files.push(correct.image);
    correct.audio && files.push(correct.audio);
    correct.feedbackAudio && files.push(correct.feedbackAudio);
  }

  incorrectAnswers.forEach((answer) => {
    if (!answer) return;
    answer.image && files.push(answer.image);
    answer.audio && files.push(answer.audio);
    answer.feedbackAudio && files.push(answer.feedbackAudio);
  });

  return files;
};

export const importQuestionsHandler = (cb) => {
  const fileInput = document.createElement('input');

  fileInput.type = 'file';
  fileInput.accept = '.txt';

  fileInput.addEventListener('change', (e) => {
    const { files } = e.target;

    if (!files || !files[0] || files[0].type !== 'text/plain') {
      fileInput.remove();
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const file = event.target.result;

      let questions = file.split(/(\r\n|\r|\n){2,}/g);

      questions = _.chain(questions)
        .map((problem) => {
          const formattedProblem = problem.trim();

          if (!formattedProblem) return null;

          const [
            questionText,
            correctAnswer,
            ...incorrectAnswers
          ] = formattedProblem
            .split(/(\r\n|\r|\n)/g)
            .filter((ans) => !!(ans && ans.trim()));

          const newQuestion = createQuestion();
          newQuestion.problem = questionText;
          newQuestion.correct = { text: correctAnswer };
          newQuestion.incorrectAnswers = _.chain(incorrectAnswers)
            .map((ans) => ({ text: ans }))
            .take(4)
            .value();

          return newQuestion;
        })
        .compact()
        .value();

      console.log(questions);
      cb(questions);

      fileInput.remove();
    };

    reader.onerror = (event) => {
      alert(event.target.error.name);
      fileInput.remove();
    };

    reader.readAsText(files[0]);
  });

  fileInput.click();
};

export const validateWords = (words, templateDBrecord) => {
  const { audioRequired } = templateDBrecord;

  if (words.length < 3) {
    return { success: false, error: 'Should be minimum 3 words' };
  }

  if (audioRequired) {
    const wordsWithMissingAudio = words.filter((w) => !w.wordAudio);

    if (wordsWithMissingAudio.length) {
      return {
        success: false,
        filteredWords: words,
        error: `Audio required for the template and following words don't have it - ${wordsWithMissingAudio
          .map((w) => w.word)
          .join(',')}`,
      };
    }
  }

  return { success: true, filteredWords: words };
};

export const generateActivityFromWords = ({
  words,
  template,
  includeDefinitionAudio,
}) => {
  const { imageRequired, audioRequired } = template;

  const wordsImages = [];
  const wordsAudio = [];
  const definitionsAudio = [];

  const gameData = { problems: [] };

  words.forEach((w) => {
    let problem;

    const incorrectWords = _.chain(words)
      .filter((word) => word._id !== w._id)
      .shuffle()
      .take(2)
      .value();

    if (imageRequired) {
      // Moving Pictures
      gameData.useImages = true;

      const correctImageSrcFilename = getWordPictureFilename(w);
      const correctImageFilename = addIdToFilename(correctImageSrcFilename);

      const correct = { image: correctImageFilename };

      correctImageFilename &&
        wordsImages.push({
          src: correctImageSrcFilename,
          filename: correctImageFilename,
        });

      const incorrectAnswers = incorrectWords.map((w) => {
        const srcFilename = getWordPictureFilename(w);
        const image = addIdToFilename(srcFilename);

        image &&
          wordsImages.push({
            src: srcFilename,
            filename: image,
          });

        return { image };
      });

      problem = {
        id: shortid.generate(),
        problem: w.word,
        correct,
        incorrectAnswers,
      };

      if (audioRequired) {
        const audioFilename = addIdToFilename(w.wordAudio);

        wordsAudio.push({
          src: w.wordAudio,
          filename: audioFilename,
        });

        delete problem.problem;
        problem.audio = audioFilename;
      }
    } else {
      gameData.withAudio = true;

      let definitionAudio = null;

      if (includeDefinitionAudio) {
        definitionAudio =
          w.definitionAudio && addIdToFilename(w.definitionAudio);

        definitionAudio &&
          definitionsAudio.push({
            src: w.definitionAudio,
            filename: definitionAudio,
          });
      }

      problem = {
        id: shortid.generate(),
        problem: w.definition,
        audio: definitionAudio,
        correct: { text: w.word },
        incorrectAnswers: incorrectWords.map((w) => ({
          text: w.word,
        })),
      };
    }

    gameData.problems.push(problem);
  });

  return { gameData, definitionsAudio, wordsImages, wordsAudio };
};
