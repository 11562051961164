import shortid from "shortid";

import { addIdToFilename, removeIdFromFilename } from "utils";

export const AUDIO_SCRIPT_HEIGHT = 524;
export const AUDIO_SCRIPT_WIDTH = 280;

export const createProblem = () => ({
  id: shortid.generate(),
  image: "",
  video: "",
  audio: "",
  audioScript: "",
  text: []
});

export const createText = () => ({
  id: shortid.generate(),
  dim: {
    width: "400px",
    height: "100px",
    left: "100px",
    top: "80px"
  },
  text: ""
});

export const validateProblem = problem => {
  return !!(problem.video || problem.image || problem.text.length);
};

export const getContentFilesFromProblem = problem => {
  let files = [];
  const { image, audio, video } = problem;

  image && files.push(image);
  audio && files.push(audio);
  video && files.push(video);

  return files;
};

export const copyProblem = problem => {
  let files = [];
  const { image, audio, video } = problem;

  const copy = JSON.parse(JSON.stringify(problem));
  copy.id = shortid.generate();

  if (image) {
    const newImageFilename = addIdToFilename(removeIdFromFilename(image));
    files.push({ src: image, dest: newImageFilename });
    copy.image = newImageFilename;
  }

  if (video) {
    const newVideoFilename = addIdToFilename(removeIdFromFilename(image));
    files.push({ src: video, dest: newVideoFilename });
    copy.video = newVideoFilename;
  }

  if (audio) {
    const newAudioFilename = addIdToFilename(removeIdFromFilename(audio));
    files.push({ src: audio, dest: newAudioFilename });
    copy.audio = newAudioFilename;
  }

  return [copy, files];
};
