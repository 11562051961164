import { SET_TABLE_STATE } from "actions/types";

export * from "./documents";
export * from "./data";
export * from "./masterOrganizations";
export * from "./learningCenters";
export * from "./regions";
export * from "./teachers";
export * from "./clever";

const filter = "admin";

export const setTableState = (dataName, state) => ({
  filter,
  type: SET_TABLE_STATE,
  payload: { [dataName]: state }
});
