import React from "react";
import { DragLayer } from "react-dnd";

import Item from "../Item/Item";

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

function getItemStyles(props) {
  const { initialOffset, currentOffset } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: "none"
    };
  }
  let { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}
const ItemsDragLayer = props => {
  const { item, isDragging, style = {} } = props;

  if (!isDragging) {
    return null;
  }

  return (
    <div style={{ ...layerStyles, ...style }}>
      <div style={getItemStyles(props)}>
        <div
          style={{
            display: "inline-flex",
            lineHeight: "1",
            padding: "0.1vw 0.4vw",
            borderRadius: "8px",
            backgroundColor: "rgba(215,215,215, .8)"
          }}
        >
          <Item data={item.itemData.item} />
        </div>
      </div>
    </div>
  );
};
export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
}))(ItemsDragLayer);
