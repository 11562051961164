import React from "react";
import PropTypes from "prop-types";
import { times } from "lodash";
import classnames from "classnames";

import classes from "./Slots.module.scss";

const Slots = ({ number, render, onSelect, selectedIdx, list }) => {
  return (
    <div className={classes.wrapper}>
      {times(number).map(idx => {
        const item = list[idx];

        return (
          <button
            key={idx}
            onClick={() => onSelect(item, idx)}
            className={classnames(classes.slot, {
              [classes.active]: selectedIdx === idx
            })}
          >
            {render(item, idx)}
          </button>
        );
      })}
    </div>
  );
};

Slots.propTypes = {
  number: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired,
  selectedIdx: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired
};

export default Slots;
