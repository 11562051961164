import React from 'react';
import PropTypes from 'prop-types';

import { DraggableModal } from 'components/flink-components';

// const wrapperStyles = {
//   display: 'flex',
//   justifyContent: 'center',
//   flexDirection: 'column',
//   height: '100%'
// }

const PreviewImage = ({ imageSrc, closeHandler, height = '100%', width, show, ...otherProps }) => {
  return (
    <DraggableModal
      lockAspectRatio
      resizable
      noGutters
      show={!!(imageSrc && show)}
      onClose={closeHandler}
      {...otherProps}
    >
        <img
          style={{ width, height, display: 'block' }}
          onDragStart={e => e.preventDefault()}
          src={imageSrc}
          alt=""
        />
    </DraggableModal>
  );
};

PreviewImage.propTypes = {
  show: PropTypes.bool,
  imageSrc: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  closeHandler: PropTypes.func.isRequired
};

export default PreviewImage;
