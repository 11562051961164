export type Filter = {
  value?: string;
  operator: 'equal' | 'contains';
};

export function formatPaginationParams<O extends {}>({
  page,
  limit,
  orderBy,
  order,
  filters,
}: {
  page: number;
  limit: number;
  orderBy?: keyof O;
  order?: 'desc' | 'asc';
  filters: Record<keyof O, Filter>;
}) {
  const params = {
    page: page + 1,
    limit,
    sort: !orderBy ? undefined : `${order === 'desc' ? '-' : ''}${orderBy}`,
  } as const;

  const formattedFilters: Partial<Record<keyof O, {}>> = {};

  for (const entry of Object.entries(filters)) {
    const [key, filter] = entry as [keyof O, Filter];
    if (filter.value === null || filter.value === undefined) continue;

    const isEqualOperator = filter.value[0] === '=';

    if (typeof filter.value !== 'string') {
      formattedFilters[key] = filter.value;
    } else if (isEqualOperator) {
      formattedFilters[key] = {
        regExp: '^' + filter.value.slice(1) + '$',
        flags: 'i',
      };
    } else {
      formattedFilters[key] = { regExp: filter.value, flags: 'i' };
    }
  }

  return { ...params, ...formattedFilters };
}
