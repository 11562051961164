import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { options as selectOptions } from 'activity-templates/utils';
import RadioGroup from './RadioGroup';
import SelectGroup from './SelectGroup';
import CheckboxGroup from './CheckboxGroup';
import Checkboxes from './Checkboxes';
import ParentVideosControls from './ParentVideosControls';
import { ColorPickerField } from 'components/common';
import { PRESENTATION, MANUAL, BOTH } from 'consts/ebook-consts';
import { INSTRUCTION_TYPES } from 'activity-templates/directions-grid/directionsGridHelpers';
import * as StoryStudioModes from 'consts/story-studio-consts';

const ActivityOptions = ({
  options,
  changeHandler,
  translate,
  adminMode,
  defaultOptions = {},
  optionsToRender,
}) => {
  return (
    <StyledOptionsForm>
      <div style={{ flexGrow: 1 }}>
        {!optionsToRender.length && <StyledLegend>No options</StyledLegend>}

        {optionsToRender.includes('storyStudio') && (
          <>
            <StyledLegend>{translate(0, 'Mode:')}</StyledLegend>

            <RadioGroup
              name="storyStudioMode"
              changeHandler={changeHandler}
              current={options}
              options={[
                {
                  label: translate(0, 'Editor Only'),
                  value: StoryStudioModes.EDITOR_ONLY,
                },
                {
                  label: translate(0, 'Recorder Only'),
                  value: StoryStudioModes.RECORDER_ONLY,
                },
                {
                  label: translate(0, 'Both'),
                  value: StoryStudioModes.EDITOR_AND_RECORDER,
                },
              ]}
            />

            <CheckboxGroup
              label={translate(0, 'Approval Checkbox')}
              name="needApproval"
              current={options}
              changeHandler={changeHandler}
            />

            <CheckboxGroup
              label={translate(0, 'Feedback')}
              name="aiFeedbackEnabled"
              current={options}
              changeHandler={changeHandler}
              defaultChecked
            />

            <FlexContainer>
              <Checkboxes
                legend={translate(303, 'Fonts:')}
                required
                options={[
                  'Arial',
                  'Arial Black',
                  'Courier New',
                  'Helvetica',
                  'Georgia',
                ]}
                name="editor.fonts"
                current={options}
                changeHandler={changeHandler}
              />

              <Checkboxes
                legend={translate(329, 'Sizes:')}
                required
                options={[
                  { label: translate(330, 'Normal'), value: 'normal' },
                  { label: translate(332, 'Header 1'), value: 'h1' },
                  { label: translate(334, 'Header 2'), value: 'h2' },
                  { label: translate(335, 'Header 3'), value: 'h3' },
                ]}
                // options={[12, 18, 24, 36]}
                name="editor.fontSizes"
                current={options}
                changeHandler={changeHandler}
              />

              <Checkboxes
                required
                legend={translate(342, 'Colors:')}
                options={[
                  { label: translate(344, 'Black'), value: 'black' },
                  { label: translate(345, 'Red'), value: 'red' },
                  { label: translate(354, 'Green'), value: 'green' },
                  { label: translate(355, 'Blue'), value: 'blue' },
                ]}
                name="editor.fontColors"
                current={options}
                changeHandler={changeHandler}
              />

              <Checkboxes
                legend={translate(356, 'Styles:')}
                options={[
                  { label: translate(357, 'Bold'), value: 'bold' },
                  { label: translate(305, 'Italic'), value: 'italic' },
                  { label: translate(366, 'Underline'), value: 'underline' },
                ]}
                name="editor.fontStyles"
                current={options}
                changeHandler={changeHandler}
              />

              <Checkboxes
                required
                legend={translate(370, 'Alignment:')}
                options={[
                  { label: translate(371, 'Left'), value: 'left' },
                  { label: translate(373, 'Center'), value: 'center' },
                ]}
                name="editor.alignments"
                current={options}
                changeHandler={changeHandler}
              />
            </FlexContainer>
          </>
        )}

        {optionsToRender.includes('wordSearch') && (
          <>
            <StyledLegend>{translate(24, 'Word Directions:')}</StyledLegend>
            <CheckboxGroup
              label={translate(25, 'Horizontal')}
              name="wordDirections.horizontal"
              current={options}
              changeHandler={changeHandler}
            />
            <CheckboxGroup
              label={translate(188, 'Vertical')}
              name="wordDirections.vertical"
              current={options}
              changeHandler={changeHandler}
            />
            <CheckboxGroup
              label={translate(231, 'Diagonal')}
              name="wordDirections.diagonal"
              current={options}
              changeHandler={changeHandler}
            />

            <StyledLegend>
              {translate(235, 'Word Search Options:')}
            </StyledLegend>
            <CheckboxGroup
              label={translate(239, 'Shared Letters')}
              name="wordSearch.sharedLetters"
              current={options}
              changeHandler={changeHandler}
            />
            <CheckboxGroup
              label={translate(241, 'Show Hints')}
              name="wordSearch.showHints"
              current={options}
              changeHandler={changeHandler}
            />
            <CheckboxGroup
              label={translate(244, 'Alphabetize Wordlist')}
              name="wordSearch.alphabetize"
              current={options}
              changeHandler={changeHandler}
            />
          </>
        )}

        {optionsToRender.includes('ebook') && (
          <>
            <RadioGroup
              name="ebookMode"
              changeHandler={(name, val) => {
                if (val === PRESENTATION) {
                  changeHandler('displayTimer', false);
                  changeHandler('autoPlayAudioInManual', false);
                }
                changeHandler(name, val);
              }}
              current={options}
              options={[
                {
                  label: translate(296, 'Presentation Only'),
                  value: PRESENTATION,
                },
                {
                  label: translate(297, 'Manual Only'),
                  value: MANUAL,
                },
                {
                  label: translate(299, 'Both'),
                  value: BOTH,
                },
              ]}
            />

            <CheckboxGroup
              label={translate(301, 'Display Timer:')}
              name="displayTimer"
              disabled={options && options.ebookMode === PRESENTATION}
              current={options}
              changeHandler={changeHandler}
            />

            <CheckboxGroup
              label={translate(735, 'Auto Play audio in Manual mode:')}
              disabled={options && options.ebookMode === PRESENTATION}
              name="autoPlayAudioInManual"
              current={options}
              changeHandler={changeHandler}
            />

            <CheckboxGroup
              label={translate(0, 'End video when text ends')}
              // disabled={options && options.ebookMode === PRESENTATION}
              name="endVideoWhenAudioEnds"
              current={options}
              changeHandler={changeHandler}
            />

            {/* <CheckboxGroup
            label={translate(0, "With Presentation Mode")}
            name="withPresentationMode"
            current={options}
            changeHandler={changeHandler}
          /> */}
          </>
        )}

        {optionsToRender.includes('delayBeforeNext') && (
          <>
            <StyledLegend>
              {translate(
                456,
                'Length of time before continuing to next question:'
              )}
            </StyledLegend>
            <SelectGroup
              translate={translate}
              label={translate(460, 'Seconds')}
              options={selectOptions.delayBeforeNext}
              current={options}
              name="delayBeforeNext"
              changeHandler={changeHandler}
            />
          </>
        )}

        {optionsToRender.includes('directionsGrid') && (
          <>
            <StyledLegend>{translate(821, 'Instruction Types:')}</StyledLegend>

            <RadioGroup
              name="directionsGrid.instructionType"
              changeHandler={changeHandler}
              current={options}
              options={[
                {
                  label: translate(822, INSTRUCTION_TYPES.AUDIO),
                  value: INSTRUCTION_TYPES.AUDIO,
                },
                {
                  label: translate(823, INSTRUCTION_TYPES.TEXT),
                  value: INSTRUCTION_TYPES.TEXT,
                },
                {
                  label: translate(824, INSTRUCTION_TYPES.AUDIO_AND_TEXT),
                  value: INSTRUCTION_TYPES.AUDIO_AND_TEXT,
                },
              ]}
            />
          </>
        )}

        {(optionsToRender.includes('fontFamily') ||
          optionsToRender.includes('fontColor') ||
          optionsToRender.includes('fontSize')) && (
          <>
            <StyledLegend>{translate(369, 'Font:')}</StyledLegend>
            <StyledRow>
              {optionsToRender.includes('fontFamily') && (
                <SelectGroup
                  translate={translate}
                  label={translate(453, 'Type:')}
                  options={selectOptions.fontFamily}
                  current={options}
                  name="fontFamily"
                  changeHandler={changeHandler}
                />
              )}

              {optionsToRender.includes('fontSize') && (
                <>
                  {adminMode && (
                    <SelectGroup
                      translate={translate}
                      label="Max Size:"
                      options={selectOptions.fontSize}
                      current={options}
                      name="maxFontSize"
                      changeHandler={(fieldName, maxFontSize) => {
                        changeHandler(fieldName, maxFontSize);

                        if (+maxFontSize < +options.fontSize) {
                          changeHandler('fontSize', maxFontSize);
                        }
                      }}
                    />
                  )}

                  <SelectGroup
                    translate={translate}
                    label={translate(454, 'Size:')}
                    options={selectOptions.fontSize.filter((size) => {
                      if (adminMode) {
                        return options.maxFontSize
                          ? +size <= +options.maxFontSize
                          : size;
                      }
                      return defaultOptions.maxFontSize
                        ? +size <= +defaultOptions.maxFontSize
                        : size;
                    })}
                    current={options}
                    name="fontSize"
                    changeHandler={changeHandler}
                  />
                </>
              )}

              {optionsToRender.includes('fontColor') && (
                <ColorPickerField
                  label={translate(455, 'Color:')}
                  currentColor={options.fontColor}
                  changeHandler={(color) => {
                    changeHandler('fontColor', color.hex);
                  }}
                />
              )}
            </StyledRow>
          </>
        )}

        {optionsToRender.includes('coloredAnswerBoxes') && (
          <CheckboxGroup
            label={translate(458, 'Colored Answer Boxes:')}
            name="coloredAnswerBoxes"
            current={options}
            changeHandler={changeHandler}
          />
        )}

        {optionsToRender.includes('audioBefore') && (
          <CheckboxGroup
            label={translate(519, 'Audio Before:')}
            name="audioBefore"
            current={options}
            changeHandler={changeHandler}
          />
        )}

        {optionsToRender.includes('randomOrder') && (
          <CheckboxGroup
            label={translate(457, 'Random Problem Order:')}
            name="randomOrder"
            current={options}
            changeHandler={changeHandler}
          />
        )}

        {optionsToRender.includes('displayWords') && (
          <CheckboxGroup
            label={translate(0, 'Display Words:')}
            name="displayWords"
            current={options}
            changeHandler={changeHandler}
          />
        )}

        {optionsToRender.includes('showQuestion') && (
          <CheckboxGroup
            label={translate(464, 'Show question:')}
            name="showQuestion"
            current={options}
            changeHandler={changeHandler}
          />
        )}

        {optionsToRender.includes('showAnswer') && (
          <>
            <StyledLegend>{translate(462, 'Show Answer:')}</StyledLegend>
            <SelectGroup
              translate={translate}
              label={translate(463, 'Number of tries:')}
              options={selectOptions.showAnswer}
              current={options}
              name="showAnswer"
              changeHandler={changeHandler}
            />
          </>
        )}

        {optionsToRender.includes('animationSpeed') && (
          <SelectGroup
            translate={translate}
            label={translate(451, 'Animation speed:')}
            options={selectOptions.animationSpeed}
            current={options}
            name="animationSpeed"
            changeHandler={changeHandler}
          />
        )}

        {optionsToRender.includes('questionsLimit') && (
          <SelectGroup
            translate={translate}
            label={translate(461, 'Questions Limit:')}
            options={selectOptions.questionsLimit}
            current={options}
            name="questionsLimit"
            changeHandler={changeHandler}
            numeric
          />
        )}
      </div>

      <div style={{ marginLeft: 15 }}>
        {optionsToRender.includes('parentVideos') && (
          <ParentVideosControls
            value={options.parentVideos}
            onChange={(parentVideos) =>
              changeHandler('parentVideos', parentVideos)
            }
          />
        )}
      </div>
    </StyledOptionsForm>
  );
};

ActivityOptions.propTypes = {
  translate: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  optionsToRender: PropTypes.array.isRequired,
  defaultOptions: PropTypes.object,
};

const mapStateToProps = ({ status: { translate } }) => ({ translate });

export default connect(mapStateToProps)(ActivityOptions);

const StyledOptionsForm = styled.div`
  display: flex;
  justify-content: space-between;

  > div label {
    margin-right: 10px;
    color: #333;
    font-size: 16px;
  }

  p {
    margin: 10px 0;
  }

  input,
  select {
    margin-right: 10px;
    cursor: pointer;
  }
`;

const StyledLegend = styled.b`
  display: block;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
