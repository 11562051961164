import _ from 'lodash';

export default (group, activityGroups, activities, translate) => {
  if (!group || !group.elements) return [];

  const groupElements = _.chain(group.elements)
    .map((i) => {
      const item = _.find(i.isGroup ? activityGroups : activities, {
        _id: i._id,
      });

      if (!item) return null;

      let formattedName = i.isGroup ? item.name : item.activityName;

      if (i.isGroup && item.nameString) {
        formattedName = translate(item.nameString, '', true, false);
      } else if (formattedName.indexOf('~') !== -1) {
        formattedName = formattedName.split('~')[1];
      }

      return { ...item, formattedName, isGroup: i.isGroup };
    })
    .compact()
    .value();

  return groupElements;
};
