import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import shortid from 'shortid';
import classnames from 'classnames';
import styled from 'styled-components';

import { WritingFeedbackButton } from 'components/flink-play';

import './StoryStudioEditor.scss';

const formats = [
  'align',
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'color',
  'style'
];

const Spacer = styled.div`
  display: flex;
  flex: 10;
`;

class StoryStudioEditor extends Component {
  constructor(props) {
    super(props);

    Quill.register(Quill.import('attributors/style/color'), true);
    Quill.register(Quill.import('attributors/style/align'), true);

    const quillFonts = Quill.import('attributors/style/font');
    quillFonts.whitelist = [
      'Arial',
      'Arial Black',
      'Courier New',
      'Georgia',
      'Helvetica'
    ];
    Quill.register(quillFonts, true);

    const toolbarID = shortid.generate();

    const modules = {
      clipboard: {
        matchers: [['image', null]],
        matchVisual: false
      },
      toolbar: {
        container: `[data-toolbar-id="${toolbarID}"]`
      }
    };

    const { fontSizes = [] } = props.toolbar || {};
    const sortedFontSizes = fontSizes
      .filter(size => typeof size === 'string')
      .sort((a, b) => (a > b ? -1 : 1));

    const Block = Quill.import('blots/block');
    Block.tagName = fontSizes.includes('normal') ? 'P' : sortedFontSizes[0];
    Quill.register(Block, true);

    this.state = {
      editorHtml: '',
      toolbarID,
      formats,
      modules
    };
  }

  editor = React.createRef();

  componentDidMount() {
    this.setInitialStyles();
    this.setInitialValue();
  }

  setInitialStyles = () => {
    const { fonts = [], fontColors = [], alignments = [] } =
      this.props.toolbar || {};

    const editor = this.editor.current.getEditor();

    if (alignments.length === 1 && alignments[0] === 'center') {
      editor.format('align', 'center');
    }

    editor.format(
      'color',
      fontColors.includes('black') ? 'black' : fontColors[0]
    );
    editor.format('font', fonts[0]);
  };

  setInitialValue = () => {
    const { initialValue } = this.props;

    this.setState({
      editorHtml: initialValue || ''
    });
  };

  onSizeClick = type => {
    const editor = this.editor.current.getEditor();
    editor.format('header', type);
  };

  onColorClick = color => {
    const editor = this.editor.current.getEditor();
    editor.format('color', color);
  };

  onAlignmentClick = value => {
    const editor = this.editor.current.getEditor();
    editor.format('align', value);
  };

  onWordClick = word => {
    const editor = this.editor.current.getEditor();
    const range = editor.getSelection();

    const cursorPosition = range ? range.index : 0;
    editor.insertText(cursorPosition, ' ' + word);
    editor.setSelection(cursorPosition + word.length + 1);
  };

  renderToolbar = () => {
    const { 
      toolbar = {}, 
      words = [], 
      readOnly, 
      writingFeedbackEnabled,
      getPagesText,
    } = this.props;

    const { toolbarID } = this.state;

    const {
      fonts = [],
      fontSizes = [],
      fontColors = [],
      fontStyles = [],
      alignments = []
    } = toolbar;

    const dontDisplay =
      readOnly ||
      (fonts.length < 2 &&
        fontSizes.length < 2 &&
        fontColors.length < 2 &&
        fontStyles.length < 1 &&
        alignments.length < 2 &&
        !words.length);

    return (
      <div
        data-toolbar-id={toolbarID}
        style={{ display: dontDisplay ? 'none' : 'block' }}
      >
        <div className="story-studio-editor__toolbar-top">
          {writingFeedbackEnabled && (<span className="writing-feedback">
            <WritingFeedbackButton getContent={getPagesText} />
          </span>)}

          <Spacer />

          {fontSizes.length !== 1 && (
            <ul className="story-studio-editor__size-controls">
              {fontSizes.includes('h1') && (
                <li onClick={this.onSizeClick.bind(null, 'h1')}>
                  <h1>
                    <small>A</small>
                  </h1>
                </li>
              )}
              {fontSizes.includes('h2') && (
                <li onClick={this.onSizeClick.bind(null, 'h2')}>
                  <h2>
                    <small>A</small>
                  </h2>
                </li>
              )}
              {fontSizes.includes('h3') && (
                <li onClick={this.onSizeClick.bind(null, 'h3')}>
                  <h3>
                    <small>A</small>
                  </h3>
                </li>
              )}
              {fontSizes.includes('normal') && (
                <li onClick={this.onSizeClick.bind(null, '')}>
                  <span>
                    <small>A</small>
                  </span>
                </li>
              )}
            </ul>
          )}

          {fontColors.length !== 1 && (
            <ul className="story-studio-editor__color-controls">
              {fontColors.map(color => (
                <li
                  key={color}
                  style={{ backgroundColor: color }}
                  onClick={this.onColorClick.bind(null, color)}
                ></li>
              ))}
            </ul>
          )}

          {!!fontStyles.length && (
            <span className="ql-formats">
              {fontStyles.includes('bold') && <button className="ql-bold" />}
              {fontStyles.includes('italic') && (
                <button className="ql-italic" />
              )}
              {fontStyles.includes('underline') && (
                <button className="ql-underline" />
              )}
            </span>
          )}

          {fonts.length !== 1 && (
            <select className="ql-font" defaultValue={fonts[0]}>
              {fonts.map(font => (
                <option value={font} key={font}>
                  {font}
                </option>
              ))}
            </select>
          )}

          {alignments.length !== 1 && (
            <div className="story-studio-editor__alignment-controls">
              {alignments.includes('left') && (
                <button onClick={this.onAlignmentClick.bind(null, '')}>
                  <svg viewBox="0 0 18 18">
                    <line
                      className="ql-stroke"
                      x1="3"
                      x2="15"
                      y1="9"
                      y2="9"
                    ></line>
                    <line
                      className="ql-stroke"
                      x1="3"
                      x2="13"
                      y1="14"
                      y2="14"
                    ></line>
                    <line
                      className="ql-stroke"
                      x1="3"
                      x2="9"
                      y1="4"
                      y2="4"
                    ></line>
                  </svg>
                </button>
              )}

              {alignments.includes('center') && (
                <button onClick={this.onAlignmentClick.bind(null, 'center')}>
                  <svg viewBox="0 0 18 18">
                    <line
                      className="ql-stroke"
                      x1="15"
                      x2="3"
                      y1="9"
                      y2="9"
                    ></line>
                    <line
                      className="ql-stroke"
                      x1="14"
                      x2="4"
                      y1="14"
                      y2="14"
                    ></line>
                    <line
                      className="ql-stroke"
                      x1="12"
                      x2="6"
                      y1="4"
                      y2="4"
                    ></line>
                  </svg>
                </button>
              )}
            </div>
          )}

          <Spacer />

          {/* <span className="ql-formats">
            <button className="ql-clean" />
          </span> */}
        </div>

        <div className="story-studio-editor__toolbar-bottom">
          <ul className="story-studio-editor__words">
            {words.map(w => (
              <li onClick={this.onWordClick.bind(null, w)} key={w}>
                {w}
              </li>
            ))}
          </ul>

        </div>
      </div>
    );
  };

  handleChange = (html, delta, source, editor) => {
    const text = editor.getText();

    if (!text.length || text.length === 1) {
      this.setState({ editorHtml: html }, this.setInitialStyles);
    } else {
      this.setState({ editorHtml: html });
    }
    this.props.changeHandler(html);
  };

  render() {
    const { editorHtml, modules, formats } = this.state;
    const { isLandscape, readOnly } = this.props;

    return (
      <div
        id="story-studio-editor"
        className={classnames({ landscape: isLandscape })}
      >
        {this.renderToolbar()}

        <ReactQuill
          readOnly={readOnly}
          ref={this.editor}
          theme={'snow'}
          onChange={this.handleChange}
          value={editorHtml}
          modules={modules}
          formats={formats}
          preserveWhitespace
        />
      </div>
    );
  }
}

StoryStudioEditor.defaultProps = {};

StoryStudioEditor.propTypes = {
  initialValue: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  writingFeedbackEnabled: PropTypes.bool.isRequired,
};

export default StoryStudioEditor;
