import React from 'react';
import PropTypes from 'prop-types';
import { withTranslate } from 'components/hocs';

function CategoryColumn({
  title,
  classes,
  items,
  changeHandler,
  translate,
  value
}) {
  return (
    <div className={classes.categoryColumn}>
      {/* <label>{title && title.substring(0, title.length - 1)}:</label> */}
      {title ? <label>{title}:</label> : <label>&nbsp;</label>}

      <select
        value={value || ''}
        size="6"
        onChange={e => changeHandler(e.target.value)}
      >
        <option value="">{translate(486, '- All -')}</option>
        {/* <option value="">- All {title} -</option> */}

        {items.map(item => {
          if (typeof item === 'object') {
            return (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            );
          }
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
}

CategoryColumn.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withTranslate(CategoryColumn);
