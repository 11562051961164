import WritingAssistant from "./WritingAssistant";
import {
  validateWords,
  generateActivityFromWords
} from "./writingAssistantHelpers";
import validateActivity from "./validateWritingAssistant";

export default {
  Template: WritingAssistant,
  validateActivity,
  optionsArray: [],
  generateActivityFromWords,
  validateWords
};
