import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import DraggableModal from "../DraggableModal/DraggableModal";

const SelectModal = ({
  show,
  onClose,
  translate,
  title,
  selectHandler,
  options,
  html = ""
}) => {
  const [selected, setSelected] = useState("");

  return (
    <DraggableModal show={show} onClose={onClose} title={title} withOverlay>
      <StyledContent>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>

        <select onChange={e => setSelected(e.target.value)} value={selected}>
          <option value=""></option>

          {options.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>

        <button disabled={!selected} onClick={() => selectHandler(selected)}>
          {translate(191, "OK")}
        </button>
      </StyledContent>
    </DraggableModal>
  );
};

SelectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  selectHandler: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  html: PropTypes.string,
  options: PropTypes.array.isRequired
};

const mapStateToProps = ({ status: { translate } }) => ({
  translate
});

export default connect(mapStateToProps)(SelectModal);

const StyledContent = styled.div`
  width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  select {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  button {
    cursor: pointer;
    padding: 3px 6px;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    text-align: center;
    align-self: center;
    width: 100px;

    &:hover:not(:disabled) {
      background-color: #0080ff;
      color: #fff;
    }
  }
`;
