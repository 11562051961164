import React from 'react';
import PropTypes from 'prop-types';

import classes from './Item.module.scss';

function Item({ text }) {
  return (
    <span className={classes.item}>
      {text}
    </span>
  );
}

Item.propTypes = {
  text: PropTypes.string
};

export default Item;
