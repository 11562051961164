import React from 'react';
import _ from 'lodash';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
      width: 250
    }
  }
};

const MaterialSelect = ({
  options = [],
  input,
  label,
  multiple,
  renderValue,
  meta: { touched, error },
  ...custom
}) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div style={{ marginBottom: '15px' }}>
      <FormControl
        margin="dense"
        fullWidth
        {...custom}
        variant="outlined"
        error={touched && error ? true : false}
      >
        <InputLabel ref={inputLabel}>{label}</InputLabel>
        <Select
          input={<OutlinedInput labelWidth={labelWidth} />}
          multiple={multiple}
          renderValue={
            renderValue ||
            (val => {
              if (multiple) {
                return val.map(value => {
                  const option = _.find(options, { value });
                  return option && option.label;
                }).join(', ')
              } else {
                const option = _.find(options, { value: val });

                return option && option.label;
              }
            })
          }
          MenuProps={MenuProps}
          inputProps={{ ...input, value: input.value || (multiple ? [] : '') }}
          value={input.value}
          fullWidth
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={
                  multiple
                    ? input.value.indexOf(option.value) !== -1
                    : input.value === option.value
                }
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default MaterialSelect;
