import _ from 'lodash';
import shortId from 'shortid';

import { getInstructionsAudioFilesFromProblem, stringProblemsMapper } from 'activity-templates/utils';
import { isUsingStrings } from 'utils';

export const validateItem = (item) =>
  !!(item && (item.text || item.image || item.audio));

export const validateProblem = (problem) => {
  const { items } = problem;
  if (!(items && items.length)) {
    return false;
  }

  const validItems = items.filter(validateItem);

  if (!validItems.length || validItems.length < 2) {
    return false;
  }

  return true;
};

export const createProblem = () => ({
  id: shortId.generate(),
  items: [],
  fontColor: '#000',
  fontFamily: 'Arial',
});

export const getContentFilesFromItem = (item) => {
  const files = [];
  if (!item) return files;

  const { text, image, audio } = item;
  !isUsingStrings(text) && image && files.push(image);
  !isUsingStrings(text) && audio && files.push(audio);

  return files;
};

export const getContentFilesFromProblem = (problem) => {
  let files = [];
  const { items } = problem;

  const instructionsAudioFiles = getInstructionsAudioFilesFromProblem(problem);
  files.push(...instructionsAudioFiles);

  _.chain(items).forEach((item) => {
    files = _.concat(files, getContentFilesFromItem(item));
  });
  console.log(files);
  return files;
};

export const prepareProblem = (problem, { translate, getStringAudio }) => {
  if (!problem) return null;

  const { items } = problem;
  const mapper = stringProblemsMapper({
    translate,
    getStringAudio,
  });

  const validItems = items.filter(validateItem).map(mapper);

  const startPositions = _.chain(validItems.length).times().shuffle().value();

  return {
    items: _.chain(validItems)
      .map((item, idx) => ({
        id: shortId.generate(),
        data: item,
        shouldBeSlot: idx,
        boxIdx: startPositions[idx],
        inSlot: false,
      }))
      .value(),
  };
};
