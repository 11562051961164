import React, { useState } from 'react';
import cs from 'classnames';

import { imagesURL } from 'config';
import keyClickSound from './key-click.mp3';
import classes from './Keyboard.module.scss';

const imagesSrcBase = `${imagesURL}/TemplateGraphics/SpellingKeyboard`;
const backgroundSrc = `${imagesSrcBase}/Background.png`;
const deleteKeySrc = `${imagesSrcBase}/DeleteKeyWhite.png`;
const normalKeySrc = `${imagesSrcBase}/NormalKey.png`;
const mediumKeySrc = `${imagesSrcBase}/MediumKey.png`;
const largeKeySrc = `${imagesSrcBase}/LargeKey.png`;

const keyboards = {
  en: {
    top: 'QWERTYUIOP',
    middle: 'ASDFGHJKL',
    bottom: 'ZXCVBNM',
    beforeSpace: '',
    afterSpace: "'.-",
    shiftText: 'Shift',
    spaceText: 'Space',
  },
  es: {
    top: 'QWERTYUIOP',
    middle: 'ASDFGHJKL',
    bottom: 'ZXCVBNM',
    beforeSpace: 'áéíó',
    afterSpace: 'úüñ',
    shiftText: 'Mayús',
    spaceText: 'Espacio',
  },
};

const audio = new Audio(keyClickSound);
audio.volume = 0.5;

const Keyboard = ({ locale, onLetterClick, onDeleteClick, disabled }) => {
  const [shiftPressed, setShiftPressed] = useState(false);

  const keyboard = keyboards[locale] || keyboards.en;

  const playClickSound = () => {
    audio.currentTime = 0;
    audio.play();
  };

  const handleLetterClick = (e) => {
    e.preventDefault();
    if (disabled) return;
    playClickSound();

    const { letter } = e.target.dataset;

    const properCase = shiftPressed
      ? letter.toUpperCase()
      : letter.toLowerCase();

    onLetterClick(properCase);
  };

  const handleShiftClick = (e) => {
    e.preventDefault();
    if (disabled) return;
    playClickSound();
    setShiftPressed(!shiftPressed);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    if (disabled) return;
    playClickSound();
    onDeleteClick();
  };

  const renderLetters = (letters = '') => {
    return letters.split('').map((letter) => (
      <button
        className={classes.key}
        style={{ backgroundImage: `url(${normalKeySrc})` }}
        key={letter}
        data-letter={letter}
        onClick={handleLetterClick}
      >
        {shiftPressed ? letter.toUpperCase() : letter.toLowerCase()}
      </button>
    ));
  };

  return (
    <div
      className={cs(classes.wrapper, { [classes.disabled]: disabled })}
      style={{ backgroundImage: `url(${backgroundSrc})` }}
    >
      <div className={cs(classes.keysRow, classes['--top'])}>
        {renderLetters(keyboard.top)}
      </div>

      <div className={cs(classes.keysRow, classes['--top'])}>
        {renderLetters(keyboard.middle)}
      </div>

      <div className={cs(classes.keysRow, classes['--top'])}>
        <button
          style={{ backgroundImage: `url(${mediumKeySrc})` }}
          className={cs(classes.key, classes['--shift'])}
          onClick={handleShiftClick}
        >
          {keyboard.shiftText}
        </button>
        {renderLetters(keyboard.bottom)}
        <button
          style={{ backgroundImage: `url(${mediumKeySrc})` }}
          className={cs(classes.key, classes['--delete'])}
          onClick={handleDeleteClick}
        >
          <img src={deleteKeySrc} alt="" />
        </button>
      </div>

      <div className={cs(classes.keysRow, classes['--top'])}>
        <div className={classes.nextToSpace}>
          {renderLetters(keyboard.beforeSpace)}
        </div>
        <button
          style={{ backgroundImage: `url(${largeKeySrc})` }}
          className={cs(classes.key, classes['--space'])}
          data-letter=" "
          onClick={handleLetterClick}
        >
          {keyboard.spaceText}
        </button>
        <div className={classes.nextToSpace}>
          {renderLetters(keyboard.afterSpace)}
        </div>
      </div>
    </div>
  );
};

export default Keyboard;
