import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    '&:focus': {
      outline: 'none'
    }
  },
  deleteButton: {
    color: '#e00000',
    borderColor: '#e00000',
    marginRight: theme.spacing(),
    marginLeft: theme.spacing()
  },
  cancelButton: {
    color: '#555',
    borderColor: '#555',
    marginRight: theme.spacing(),
    marginLeft: theme.spacing()
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  }
});

class InfoModal extends React.Component {
  render() {
    const { classes, isOpen, text, handleClose } = this.props;

    return (
      <Modal open={isOpen} onClose={handleClose}>
        <div className={classes.paper}>
          <Typography variant="subtitle1" id="simple-modal-description">
            {text}
          </Typography>
          <div className={classes.buttonsWrapper}>
            <Button onClick={handleClose}>Ok</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

InfoModal.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InfoModal);
