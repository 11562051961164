import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, SubmissionError, Field } from 'redux-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import { login } from 'actions/authActions';
import { validateLogins } from 'validation';
import LoginFieldsGroup from './loginForm/LoginFieldsGroup';
import ForgotPasswordForm from './loginForm/ForgotPasswordForm';
import { Modal } from 'components/common';
import classes from './LoginForm.module.scss';
import SupportPhone from '../../../components/common/SupportPhone';
import HelpControls from '../HelpControls/HelpControls';
import { Help } from "../../../components/flink-play";

const StyledHelpWrapper = styled.div`
  font-family: 'Roboto';
  font-weight: initial
`;

class LoginForm extends Component {
  state = { 
    forgotPasswordOpened: false, 
  };

  async componentDidMount() {
    const { initialValues, handleSubmit } = this.props;

    if (initialValues && initialValues.autoLogin) {
      handleSubmit(this.submitHandler.bind(this, initialValues))();
    }
  }

  submitHandler = async (values, dispatch) => {
    const { learningCenter, login } = this.props;

    if (values.username2 || values.username3) {
      values.isTeam = true;
    } else {
      values.isTeam = false;
    }

    const learningCenterId = (learningCenter && learningCenter._id) || null;

    const result = await login({ ...values, learningCenterId });

    if (!result.success) {
      this.props.onError && this.props.onError(result.data);

      if (result.data && result.data.errors) {
        throw new SubmissionError(result.data.errors);
      }
    } else if (learningCenter?.config?.offline) {
      values.username && localStorage.setItem('flc-username', values.username);
      values.username2 &&
        localStorage.setItem('flc-username2', values.username2);
      values.username3 &&
        localStorage.setItem('flc-username3', values.username3);
    }
  };

  setForgotPasswordOpened = (state) => {
    this.setState({ forgotPasswordOpened: state });
  };

  renderLogins = () => {
    const { learningCenter, family } = this.props;

    const allLoginsFields = !!learningCenter;

    return (
      <div className={classes.loginsWrapper}>
        <LoginFieldsGroup 
          primary 
          noTitle={!allLoginsFields} 
          family={family}
          learningCenter={learningCenter} 
        />

        {allLoginsFields && (
          <>
            <LoginFieldsGroup number="2" family={family} />
            <LoginFieldsGroup number="3" family={family} />
          </>
        )}
      </div>
    );
  };

  renderLanguages = () => {
    const { availableLocales, onChangeLocale, lang: currentLang } = this.props;

    return (
      <div className={classes.radioWrapper}>
        {availableLocales.length === 1
          ? null
          : availableLocales.map((locale) => {
              const checked = locale.code === currentLang.code;

              return (
                <label
                  className={classnames(classes.radioLabel, {
                    [classes.checked]: checked,
                  })}
                  key={locale._id}
                >
                  <Field
                    name="lang"
                    checked={checked}
                    component="input"
                    type="radio"
                    value={locale.code}
                    onChange={onChangeLocale}
                  />{' '}
                  {locale.displayName}
                </label>
              );
            })}
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      // pristine,
      submitting,
      translate,
      learningCenter,
      // family,
      help,
    } = this.props;

    const { forgotPasswordOpened } = this.state;

    if (!translate) return null;

    return (
      <>
        <form
          className={classes.loginForm}
          autoComplete="off"
          onSubmit={handleSubmit(this.submitHandler)}
        >
          <div className={classes.formContent}>
            {this.renderLanguages()}
            {this.renderLogins()}
          </div>

          <div className={classes.buttonsWrapper}>
            <button
              className={classes.button + ' ' + classes.purple}
              disabled={submitting}
              type="submit"
            >
              {translate(104, 'Login')}
            </button>

            {/* (!learningCenter || (!learningCenter.config.offline && !family.id)) */}
            {!learningCenter && (
              <button
                className={classes.button + ' ' + classes.blue}
                type="button"
                onClick={() => this.setForgotPasswordOpened(true)}
              >
                {translate(105, 'Forgot Password')}
              </button>
            )}

            <div className={classes.helpAndPhoneWrapper}>
              {!!learningCenter && (<HelpControls />)}
              {!learningCenter?.config?.noSupportPhone && (
                <SupportPhone className={classes.phone} />
              )}
            </div>
          </div>
        </form>

        {help && (
          <StyledHelpWrapper>
            <Help />
          </StyledHelpWrapper>
        )}

        <Modal
          on={forgotPasswordOpened}
          toggle={() => this.setForgotPasswordOpened(false)}
        >
          <ForgotPasswordForm
            translate={translate}
            onSuccess={() => this.setForgotPasswordOpened(false)}
          />
        </Modal>
      </>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  lang: PropTypes.object,
  availableLocales: PropTypes.array.isRequired,
  family: PropTypes.object.isRequired,
};

const mapStateProps = ({ status, flinkPlay }) => ({
  lang: status.lang,
  translate: status.translate,
  help: flinkPlay.help,
});

export default compose(
  connect(mapStateProps, { login }),
  reduxForm({
    form: 'loginForm',
    validate: validateLogins,
  })
)(LoginForm);
