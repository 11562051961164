import React from 'react';
import styled from 'styled-components';

const LoadMessage = ({ children }) => {
  return <StyledLoadMessage>{children}</StyledLoadMessage>;
};

export default LoadMessage;

const StyledLoadMessage = styled.p`
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 4vw;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  color: #fff;
  margin: 0;
`;
