import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCurrentPage } from 'actions/statusActions';
import { withRouter } from 'react-router';

class WithPageTitle extends Component {
  constructor(props) {
    super(props);

    const { setCurrentPage, match } = props;
    setCurrentPage(match);
  }

  render() {
    const { setCurrentPage, Component, children, ...otherProps } = this.props;

    return Component ? <Component {...otherProps} /> : children;
  }
}

WithPageTitle.propTypes = {
  match: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { setCurrentPage })(WithPageTitle));
