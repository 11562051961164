import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  playCorrectSound,
  playIncorrectSound,
  playWatch
} from 'actions/audioActions';
import { animations, getInstruction } from 'activity-templates/utils';
import { validateProblem, prepareProblem } from './matchingDefinitionsHelpers';
import {
  ActivityButtons,
  Instructions,
  WhiteBox,
  ProblemsProgress,
  MultiplayerOverlay,
} from 'components/flink-play';
import {
  ItemsDragLayer,
  ItemSlots,
  BoardWithDraggableItems
} from 'components/flink-play/dnd-components';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

import classes from './MatchingDefinitions.module.scss';
import Item from './Item/Item';

const maxItems = 4;
const animationSpeed = 1000;

class MatchingDefinitions extends Component {
  state = {};

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { options, gameData } = this.props.activity.data;
    const { translate, getStringAudio } = this.props;
    const { questionsLimit, randomOrder } = options;

    // Remove invalid problems
    let problems = gameData.problems.filter(validateProblem);
    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    const preparedProblems = problems.map(p => prepareProblem(p, maxItems, {
      translate,
      getStringAudio,
    }));

    const allQuestionsStats = _.chain(preparedProblems)
      .map(p =>
        p.questions.map(q => ({ id: q.id, answerId: q.answerId, attempts: 0 }))
      )
      .flatten()
      .value();

    const currentProblem = preparedProblems[0];
    const { items, questions } = currentProblem;

    this.refs = {
      boxes: _.times(items.length).map(idx => React.createRef()),
      slots: _.times(questions.length).map(idx => React.createRef())
    };

    this.setState({
      allQuestionsStats,
      currentProblem,
      items,
      problems: preparedProblems,
      questions,
      slotsAnsweredCorrectly: [],
      animations: _.shuffle(animations),
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length
    });
  };

  onDrop = ({ itemId, slotIdx }) => {
    if (slotIdx !== undefined) {
      this.moveToSlot(itemId, slotIdx);
    } else {
      this.moveToBoard(itemId);
    }
  };

  moveToSlot = (itemId, slotIdx) => {
    const { items, allQuestionsStats, questions } = this.state;

    const updatedItems = items.map(item => {
      if (item.id === itemId) {
        return { ...item, slotIdx: slotIdx, inSlot: true };
      }

      if (item.slotIdx === slotIdx) {
        return { ...item, slotIdx: undefined, inSlot: false };
      }

      return item;
    });

    const questionID = questions[slotIdx].id;

    const changedAllQuestionsStats = allQuestionsStats.map(q => {
      if (q.id !== questionID) return q;
      const isAnsweredCorrectly = q.answerId === itemId;
      // const isFirstAttempt = q.attempts === 0;

      return {
        ...q,
        attempts: q.attempts + 1,
        isAnsweredCorrectly
        // isAnsweredCorrectlyInFirstAttempt: isFirstAttempt
        //   ? isAnsweredCorrectly
        //   : q.isAnsweredCorrectlyInFirstAttempt
      };
    });

    this.setState({
      items: updatedItems,
      allQuestionsStats: changedAllQuestionsStats
    });
    this.checkAnswer();
  };

  moveToBoard = itemId => {
    const { items } = this.state;

    this.setState({
      items: items.map(item => {
        if (item.id !== itemId) return item;
        return { ...item, inSlot: false, slotIdx: null };
      })
    });
  };

  checkAnswer = () => {
    const { items, questions, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const itemsInSlot = _.filter(items, { inSlot: true });

    if (itemsInSlot.length < questions.length) return;

    let allCorrect = true;

    itemsInSlot.forEach(item => {
      if (!allCorrect) return;

      if (!item.correct) {
        allCorrect = false;
        return;
      }

      const relatedQuestionIdx = _.findIndex(questions, {
        id: item.relatedToId
      });
      if (item.slotIdx !== relatedQuestionIdx) {
        allCorrect = false;
      }
    });

    if (allCorrect) {
      this.setState({ isResolved: true, shouldChangeTurn: true });
      this.props.playCorrectSound();
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true
      });
      return;
    }
  
    this.props.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState(state => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly
    }));
  };

  finishGame = () => {
    const { allQuestionsStats } = this.state;

    const allProblemsCount = allQuestionsStats.length;
    const problemsAnsweredCorrectly = allQuestionsStats.filter(
      q => q.isAnsweredCorrectly && q.attempts === 1
    ).length;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount,
        problemsAnsweredCorrectly,
        problemsAnsweredIncorrectly:
          allProblemsCount - problemsAnsweredCorrectly
      }
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState(state => {
        const nextProblem = state.problems[state.currentIndex + 1];
        const { items, questions } = nextProblem;

        this.refs.boxes = _.times(items.length).map(idx => React.createRef());
        this.refs.slots = _.times(questions.length).map(idx =>
          React.createRef()
        );

        return {
          items,
          questions,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1
        };
      });

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  showAnswer = () => {
    const { items, questions } = this.state;
    let changedItems = [...items];

    changedItems = changedItems.map(item => {
      if (!item.correct && item.inSlot) {
        return {
          ...item,
          moveFrom: this.refs.slots[item.slotIdx].current,
          inSlot: false,
          slotIdx: null
        };
      }

      const relatedQuestionIdx = _.findIndex(questions, {
        id: item.relatedToId
      });

      if (item.correct && !item.inSlot) {
        return {
          ...item,
          moveFrom: this.refs.boxes[item.boxIdx].current,
          inSlot: true,
          slotIdx: relatedQuestionIdx
        };
      }

      if (item.correct && item.inSlot && item.slotIdx !== relatedQuestionIdx) {
        return {
          ...item,
          moveFrom: this.refs.slots[item.slotIdx].current,
          inSlot: true,
          slotIdx: relatedQuestionIdx
        };
      }

      return item;
    });

    this.props.playWatch();
    this.setState(state => ({
      items: [...changedItems],
      isResolved: true,
      shouldChangeTurn: false,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1
    }));
    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true
    });
  };

  render() {
    const { solutionLocale, translate, getStringAudio } = this.props;
    const {
      isResolved,
      problems,
      problemsLeft,
      currentProblem,
      attemptsOnCurrentProblem
    } = this.state;

    const instruction = getInstruction(
      currentProblem, 
      solutionLocale,
      undefined, undefined, undefined,
      { translate, getStringAudio },
    );

    const { activity, textStyle, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    return (
      <div style={textStyle}>
        <Instructions problem={currentProblem} />

        {this.renderBlocks()}

        {problems && (
          <ProblemsProgress
            problemsNumber={problems.length}
            problemsLeft={problemsLeft}
          />
        )}

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem
            },
            {
              type: buttonsTypes.SPEAK,
              dontShow: !instruction.audio || isResolved
            },
            buttonsTypes.HELP
          ]}
        />
      </div>
    );
  }

  renderBlocks = () => {
    const {
      items,
      questions,
      isResolved,
      animations,
      currentIndex,
      currentProblem
    } = this.state;

    if (!currentProblem || !items || !questions) return null;

    const {
      activity: {
        data: { options }
      }
    } = this.props;

    return (
      <>
        <ItemsDragLayer ItemComponent={Item} />

        <WhiteBox
          outerClass={classes.boardOuter}
          innerClass={classes.boardInner}
        >
          <ItemSlots
            questions={questions}
            questionBefore
            customClasses={{
              positionedBox: classes.slotsWrapper,
              wrapper: classes.slotsWrapper,
              question: classes.slotQuestion,
              inner: classes.slotsInner,
              slot: classes.slot,
              slotWrapper: classes.slotWrapper,
              fixedSlot: classes.fixedSlot
            }}
            options={options}
            animationSpeed={animationSpeed}
            isResolved={isResolved}
            animation={animations[currentIndex % animations.length]}
            count={questions.length}
            onDrop={this.onDrop}
            items={items}
            refs={this.refs.slots}
            ItemComponent={Item}
          />

          <BoardWithDraggableItems
            customClasses={{
              boardOuter: classes.answersWrapper,
              boardInner: classes.answersInner,
              box: classes.boardBox
            }}
            isResolved={isResolved}
            boxesCount={items.length}
            onDrop={this.onDrop}
            items={items}
            refs={this.refs.boxes}
            ItemComponent={Item}
          />
        </WhiteBox>
      </>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(MatchingDefinitions);
