export default (arg, injectStyles) => {
  const elem = typeof arg === "string" ? document.querySelector(arg) : arg;

  const mywindow = window.open();
  mywindow.document.write("<html><head></head><body>");
  mywindow.document.write(elem.outerHTML);

  if (!!injectStyles) {
    mywindow.document.write(`<style type="text/css">${injectStyles}</style>`);
  }

  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.print();

  mywindow.onafterprint = function () {
    mywindow.close();
  };
};
