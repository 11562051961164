import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { WhiteBox } from 'components/flink-play';
import classes from './BoardWithAnswers.module.scss';
import Item from '../Item/Item';

const BoardWithAnswers = ({ answers, clickHandler }) => {
  // determine font size of answers based on max letter count
  let maxTextLength;

  if (answers) {
    maxTextLength = Math.max(...answers.map(i => (i.text ? i.text.length : 0)));
  }

  return (
    <div
      className={classnames(classes.positionedBox, classes.text, {
        [classes.small]:
          answers.length <= 3 || answers.length === 5 || answers.length === 6,
        [classes.big]: answers.length > 6 || answers.length === 4,
        [classes.textUpTo2]: maxTextLength <= 2,
        [classes.textUpTo4]: maxTextLength > 2 && maxTextLength <= 4,
        [classes.textUpTo6]: maxTextLength > 4 && maxTextLength <= 6,
        [classes.textUpTo10]: maxTextLength > 6 && maxTextLength <= 10,
        [classes.textUpTo20]: maxTextLength > 10 && maxTextLength <= 20,
      })}
    >
      <WhiteBox
        outerClass={classes.wrapper}
        innerStyle={{ padding: 0 }}
        innerClass={classes.inner}
      >
        {answers &&
          answers.map(ans => (
            <Item key={ans.id} data={ans} clickHandler={clickHandler} />
          ))}
      </WhiteBox>
    </div>
  );
};

BoardWithAnswers.propTypes = {
  answers: PropTypes.array,
  clickHandler: PropTypes.func
};

export default BoardWithAnswers;
