import { axiosAPI as axios, withLoading } from 'utils';

export default url => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const { data } = await axios.get(url);

      return data;
    } catch (err) {
      console.log(err);
    }
  });
