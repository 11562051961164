import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isUsingStrings } from 'utils';
import { withTranslate, withGetStringAudio } from 'components/hocs';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  changeGameData,
  saveEditingActivity,
  // removeContentFilesInBucket
} from 'actions/flinkMakeActions';
import classes from './SelectItemBlock.module.scss';
import { imagesURL, s3bucketPublicURL } from 'config';
// import { getContentFilesFromItem } from 'activity-templates/find-the-one/findTheOneHelpers';

const SelectItemBlock = ({
  count,
  label,
  selectedItem,
  pathPrefix,
  activeProblem,
  nextSetPathPrefix,
  selectHandler,
  width,
  contentFolder,
  verticalTab,
  lastBlock,
  translate,
  getStringAudio,
}) => {
  const textStyles = activeProblem && {
    color: activeProblem.fontColor,
    fontFamily: activeProblem.fontFamily,
  };

  useEffect(() => {
    const tabClickHandler = (e) => {
      if (e.keyCode !== 9 || !_.startsWith(selectedItem, pathPrefix)) return;

      const [, currentIdx] = selectedItem.match(/\[(\d+)\]/);

      const nextIdx = +currentIdx + 1;

      if (verticalTab && nextSetPathPrefix) {
        if (lastBlock) {
          if (nextIdx < count) {
            selectHandler(`${nextSetPathPrefix}[${nextIdx}]`);
          } else {
            selectHandler(`${nextSetPathPrefix}[0]`);
          }
        } else {
          selectHandler(`${nextSetPathPrefix}[${currentIdx}]`);
        }
      } else {
        if (nextIdx < count) {
          selectHandler(`${pathPrefix}[${nextIdx}]`);
        } else if (nextSetPathPrefix) {
          selectHandler(`${nextSetPathPrefix}[0]`);
        }
      }
    };

    window.addEventListener('keydown', tabClickHandler);

    return () => {
      window.removeEventListener('keydown', tabClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <div>
      <p className={classes.label}>{label}</p>
      <ul className={classes.itemsGroup}>
        {_.range(count).map((idx) => {
          const currentPath = `${pathPrefix}[${idx}]`;
          const activeItem = _.get(activeProblem, currentPath) || {};
          const {
            text: activeItemText,
            audio: activeItemAudio,
            image,
            feedbackText,
            feedbackAudio,
          } = activeItem;

          const hasFeedback = Boolean(feedbackAudio || feedbackText);
          const usingStrings = isUsingStrings(activeItemText);

          const text = usingStrings ? translate(activeItemText) : activeItemText;
          const audio = usingStrings ? getStringAudio(activeItemText).audio : activeItemAudio;

          return (
            <li
              key={idx}
              style={{ width }}
              className={classnames(classes.slot, {
                [classes.active]: selectedItem === currentPath,
              })}
            >
              <div
                className={classes.slotInner}
                onClick={() => {
                  if (selectedItem !== currentPath) {
                    selectHandler(currentPath);
                  }
                }}
              >
                {text && (
                  <span
                    style={textStyles}
                    className={classnames(classes.textValue, {
                      [classes.upTo2]: text.length <= 2,
                      [classes.upTo4]: text.length > 2 && text.length <= 4,
                      [classes.upTo6]: text.length > 4 && text.length <= 6,
                      [classes.upTo10]: text.length > 6 && text.length <= 10,
                    })}
                  >
                    {text}
                  </span>
                )}
                {image && (
                  <img
                    src={`${s3bucketPublicURL}/${contentFolder}/gamedata/${image}`}
                    alt=""
                    className={classes.img}
                  />
                )}
                {audio && (
                  <div
                    className={classnames({
                      [classes.audioSmall]: text || image,
                      [classes.audioBig]: !text && !image,
                    })}
                  >
                    <img
                      src={`${imagesURL}/Images/Audio/audio_make.png`}
                      alt="audio-icon"
                    />
                  </div>
                )}
                {hasFeedback && (
                  <div className={classes.feedbackMark}>
                    {!!feedbackAudio && <span>FA</span>}
                    {!!feedbackText && <span>FT</span>}
                  </div>
                )}
              </div>
              {/* {(text || audio || image) && (
                <button
                  className={classes.deleteBtn}
                  onClick={() => {
                    const filesToDelete = getContentFilesFromItem(activeItem);

                    changeGameData(
                      `problems[${activeProblemIdx}].${currentPath}`,
                      {}
                    );

                    if (filesToDelete.length) {
                      removeContentFilesInBucket({
                        filesToDelete,
                        path: contentFolder + '/gamedata'
                      });
                      saveEditingActivity();
                    }
                  }}
                >
                  <img
                    src={`${imagesURL}/FrontendUtilities/delete1.png`}
                    alt="delete"
                  />
                </button>
              )} */}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SelectItemBlock.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string,
  selectedItem: PropTypes.string,
  activeProblemIdx: PropTypes.number,
  pathPrefix: PropTypes.string.isRequired,
  activeProblem: PropTypes.object,
  selectHandler: PropTypes.func.isRequired,
  changeGameData: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  getStringAudio: PropTypes.func.isRequired,
};

const mapStateToProps = ({ flinkMake: { activity } }) => ({
  contentFolder: activity.editingActivity.activity.contentFolder,
});

export default compose(
  withTranslate,
  withGetStringAudio,
  connect(mapStateToProps, {
    changeGameData,
    saveEditingActivity,
  })
)(SelectItemBlock);
