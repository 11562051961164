import shortid from "shortid";

import { addIdToFilename, removeIdFromFilename } from "utils";

export const createProblem = () => {
  const text = createFullsizeText();

  return {
    id: shortid.generate(),
    image: "",
    audio: "",
    text: [text],
    benchmark: "",
    totalWords: "",
    hasTimer: false,
    textFrom: "",
    textOnly: true
  };
};

export const createText = () => ({
  id: shortid.generate(),
  dim: {
    width: "400px",
    height: "100px",
    left: "100px",
    top: "80px"
  },
  text: ""
});

export const createFullsizeText = () => ({
  ...createText(),
  dim: { width: "100%", height: "100%", left: "0", top: "0" }
});

export const validateProblem = problem => {
  const { image, text, benchmark, totalWords, hasTimer, textFrom } = problem;

  return !!(
    (image || text.length || textFrom !== null) &&
    (!hasTimer || (benchmark && totalWords))
  );
};

export const getContentFilesFromProblem = problem => {
  let files = [];
  const { image, audio } = problem;

  image && files.push(image);
  audio && files.push(audio);

  return files;
};

export const copyProblem = problem => {
  let files = [];
  const { image, audio } = problem;

  const copy = JSON.parse(JSON.stringify(problem));
  copy.id = shortid.generate();

  if (image) {
    const newImageFilename = addIdToFilename(removeIdFromFilename(image));
    files.push({ src: image, dest: newImageFilename });
    copy.image = newImageFilename;
  }

  if (audio) {
    const newAudioFilename = addIdToFilename(removeIdFromFilename(audio));
    files.push({ src: audio, dest: newAudioFilename });
    copy.audio = newAudioFilename;
  }

  return [copy, files];
};
