import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import shortId from 'shortid';
import cs from 'classnames';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  toggleAudio,
  playCorrectSound,
  playIncorrectSound,
  playWatch,
} from 'actions/audioActions';
import { prepareProblems } from 'activity-templates/utils';
import { s3bucketPublicURL } from 'config';
import { validateProblem } from './spellingKeyboardHelpers';
import {
  ActivityButtons,
  ProblemsProgress,
  ConnectablesProblemDefinition,
  WhiteBox,
  MultiplayerOverlay,
} from 'components/flink-play';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

import Keyboard from './Keyboard/Keyboard';
import classes from './SpellingKeyboard.module.scss';

const getSlots = (problem) => {
  if (!problem) return null;
  const { word, letters } = problem;

  const startLetters = _.chain(word)
    .split('')
    .map((l, idx) => {
      const isFixed = letters.charAt(idx) === '1';

      return {
        id: shortId.generate(),
        text: l,
        entered: isFixed ? l : '',
        fixed: isFixed,
      };
    })
    .value();

  return startLetters;
};

class SpellingKeyboard extends Component {
  constructor(props) {
    super(props);

    const { options, gameData } = props.activity.data;

    const problems = prepareProblems(
      gameData.problems,
      options,
      validateProblem
    );

    this.state = {
      problems,
      currentProblem: null,
    };
  }

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { problems } = this.state;
    const currentProblem = problems[0];
    const slots = getSlots(currentProblem);

    const allWords = problems.map((p) => ({ word: p.word, isResolved: false }));

    this.setState({
      allWords,
      correctAnswerVisible: false,
      currentProblem,
      slots,
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length,
    });

    this.playProblemAudio(currentProblem);
  };

  checkAnswer = () => {
    const { slots, currentProblem, allWords, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const { word } = currentProblem;

    const enteredWord = slots.map((slot) => slot.entered).join('');

    if (enteredWord.length !== word.length) return;

    if (enteredWord === word) {
      this.setState({
        isResolved: true,
        shouldChangeTurn: true,
        allWords: allWords.map((w) =>
          w.word === enteredWord ? { ...w, isResolved: true } : w
        ),
      });
      this.props.playCorrectSound();
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true,
      });
      return;
    }

    this.props.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState((state) => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly,
    }));
  };

  playProblemAudio = (problem) => {
    let problemToPlay = problem || this.state.currentProblem;

    if (!problemToPlay || !problemToPlay.audio) return;

    const {
      activity: {
        activity: { contentFolder },
      },
    } = this.props;

    const audioUrl = `${s3bucketPublicURL}/${contentFolder}/gamedata/${problemToPlay.audio}`;
    toggleAudio(audioUrl);
  };

  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft,
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState((state) => {
        const nextProblem = state.problems[state.currentIndex + 1];
        const slots = getSlots(nextProblem);

        this.playProblemAudio(nextProblem);

        return {
          slots,
          correctAnswerVisible: false,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1,
        };
      });

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  showAnswer = () => {
    // const {
    //   slots,
    //   currentProblem: { word },
    // } = this.state;

    this.setState({ correctAnswerVisible: true, shouldChangeTurn: false });
    this.props.playWatch();

    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true,
    });
  };

  render() {
    const {
      isResolved,
      problems,
      problemsLeft,
      currentProblem,
      attemptsOnCurrentProblem,
    } = this.state;

    const { textStyle, activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    let fontSize = textStyle.fontSize;

    const wordLength =
      currentProblem && currentProblem.word && currentProblem.word.length;
    if (wordLength > 16) {
      fontSize = parseFloat(fontSize) * 0.8 + 'vmin';
    }
    if (wordLength > 25) {
      fontSize = parseFloat(fontSize) * 0.8 + 'vmin';
    }

    return (
      <div style={{ ...textStyle, fontSize }}>
        {this.renderBlocks()}

        {problems && (
          <ProblemsProgress
            problemsNumber={problems.length}
            problemsLeft={problemsLeft}
          />
        )}

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem,
            },
            {
              type: buttonsTypes.SPEAK,
              onClick: () => this.playProblemAudio(),
              dontShow: !currentProblem || !currentProblem.audio || isResolved,
            },
            buttonsTypes.HELP,
          ]}
        />
      </div>
    );
  }

  getActiveOrLastSlot = () => {
    const { slots } = this.state;

    for (let i = 0; i < slots.length; i++) {
      const currentSlot = slots[i];
      if (!currentSlot.entered) return currentSlot;
    }

    return slots[slots.length - 1];
  };

  letterClickHandler = (enteredLetter) => {
    const activeSlot = this.getActiveOrLastSlot();

    const updatedSlots = this.state.slots.map((slot) => {
      if (slot.id === activeSlot.id) {
        return { ...slot, entered: enteredLetter };
      }

      return slot;
    });

    this.setState({ slots: updatedSlots }, this.checkAnswer);
  };

  deleteClickHandler = () => {
    const { slots } = this.state;
    const lastIdx = slots.length - 1;

    let deleteIdx;

    for (let i = lastIdx; i >= 0; i--) {
      const slot = slots[i];
      if (slot.entered && !slot.fixed) {
        deleteIdx = i;
        break;
      }
    }

    if (deleteIdx === undefined) return;

    const updatedSlots = this.state.slots.map((slot, idx) => {
      if (idx === deleteIdx) {
        return { ...slot, entered: '' };
      }

      return slot;
    });

    this.setState({ slots: updatedSlots });
  };

  renderBlocks = () => {
    const {
      slots,
      isResolved,
      currentProblem,
      allWords,
      correctAnswerVisible,
    } = this.state;
    const { solutionLocale, activity } = this.props;

    if (!currentProblem || !slots) return null;
    const { textStyle } = this.props;

    // const wordLength = currentProblem.word.length;

    const activeSlot = this.getActiveOrLastSlot();

    return (
      <>
        <WhiteBox
          outerClass={classes.slotsWrapperOuter}
          innerClass={classes.slotsWrapperInner}
        >
          <div
            className={cs(classes.slots, {
              [classes.correctAnswerVisible]: correctAnswerVisible,
            })}
          >
            {slots.map((slot) => {
              return (
                <div
                  key={slot.id}
                  data-letter={slot.text}
                  className={cs(classes.slot, {
                    [classes.fixed]: slot.fixed,
                    [classes.correct]:
                      correctAnswerVisible && slot.entered === slot.text,
                    [classes.incorrect]:
                      correctAnswerVisible && slot.entered !== slot.text,
                    [classes.active]: activeSlot.id === slot.id,
                  })}
                >
                  <span>{slot.entered}</span>
                </div>
              );
            })}
          </div>
        </WhiteBox>

        <Keyboard
          disabled={isResolved || correctAnswerVisible}
          locale={solutionLocale && solutionLocale.code}
          onLetterClick={this.letterClickHandler}
          onDeleteClick={this.deleteClickHandler}
        />

        {activity.data.options.displayWords && (
          <WhiteBox
            outerClass={classes.wordsBoxOuter}
            innerClass={classes.wordsBoxInner}
          >
            <ul className={classes.wordsList}>
              {allWords.map((w, idx) => (
                <li
                  key={idx}
                  className={cs({
                    [classes.resolved]: w.isResolved,
                  })}
                >
                  {w.word}
                </li>
              ))}
            </ul>
          </WhiteBox>
        )}

        <ConnectablesProblemDefinition
          text={currentProblem.definition}
          textStyle={textStyle}
        />
      </>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(SpellingKeyboard);
