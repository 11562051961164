import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import cs from 'classnames';

import SettingsButton from '../Settings/SettingsButton/SettingsButton';
import BraintreePaymentForm from '../BraintreePaymentForm/BraintreePaymentForm';
import { showPromptPromise, showError, showInfo } from 'actions/statusActions';
import { useTrackFamilyPrepaidTime } from '../PrepaidTracker/PrepaidTracker';
import classes from './ManagePrepaid.module.scss';
import api from 'api';

const BUYOUT_TYPE = 'BUYOUT_TYPE';
const BUY_HOURS_TYPE = 'BUY_HOURS_TYPE';

const hoursToString = (hours) => {
  const seconds = hours * 60 * 60;
  const numdays = Math.floor((seconds % 31536000) / 86400);
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);

  let result = '';
  numdays && (result += `${numdays} day(s) `);
  numhours && (result += `${numhours} hour(s) `);
  result += `${numminutes} minute(s)`;
  return result;
};

const ManagePrepaid = ({
  translate,
  family,
  showInfo,
  showError,
  myAccountValues,
}) => {
  const [processing, setProcessing] = useState(false);
  const [openPayment, setOpenPayment] = useState();
  const [hoursToBuy, setHoursToBuy] = useState(0);
  const [buyType, setBuyType] = useState();
  const familyId = family._id;
  const { data, isLoading, isFetching, refetch } = useTrackFamilyPrepaidTime(
    familyId
  );

  const buyHoursAmount = useMemo(() => {
    if (!hoursToBuy || !data) return 0;
    return Number((hoursToBuy * data.costPerHour).toFixed(2));
  }, [hoursToBuy, data]);

  const paymentAmount = useMemo(() => {
    if (!buyType || !data) return 0;
    if (buyType === BUYOUT_TYPE) return data.buyoutCost;
    if (buyType === BUY_HOURS_TYPE) return buyHoursAmount;
  }, [buyType, buyHoursAmount, data]);

  useEffect(() => {
    if (data && hoursToBuy === 0) {
      const hoursLeft = Number(
        (data.totalHours - data.totalHoursPaid).toPrecision(2)
      );
      setHoursToBuy(Math.min(data.hoursInIncrement, hoursLeft));
    }
  }, [data, hoursToBuy]);

  const makeBraintreePayment = async (paymentNonce) => {
    setOpenPayment(false);
    setProcessing(true);

    let result;

    if (buyType === BUY_HOURS_TYPE) {
      result = await api.purchase.buyHours({
        familyId,
        paymentNonce,
        amount: paymentAmount,
        hours: hoursToBuy,
      });
    } else if (buyType === BUYOUT_TYPE) {
      result = await api.purchase.buyout({
        familyId,
        paymentNonce,
        amount: paymentAmount,
      });
    }

    setProcessing(false);

    if (!result || !result.success) {
      showError({
        message: result.error || 'Failed process payment. Try again please',
      });
      return;
    }

    // Success
    refetch();
    showInfo({ message: 'Success' });
  };

  const changeHoursToBuy = (e) => {
    setHoursToBuy(+e.target.value);
  };

  const submitMpesaPaymentForm = async (e) => {
    e.preventDefault();
    const phone = e.target.elements.phone.value;

    // Make request
    let result;

    setProcessing(true);

    if (buyType === BUY_HOURS_TYPE) {
      result = await api.purchase.buyHours({
        familyId,
        phone,
        amount: paymentAmount,
        hours: hoursToBuy,
      });
    } else if (buyType === BUYOUT_TYPE) {
      result = await api.purchase.buyout({
        familyId,
        phone,
        amount: paymentAmount,
      });
    }

    setProcessing(false);
    setOpenPayment(false);

    if (result.success) {
      showInfo({
        message:
          'Request Successfully Processed. Click refresh button when you will approve the payment',
      });
    } else {
      showError({
        message: result.message,
      });
    }
  };

  const remainingHoursPrice =
    data &&
    ((data.totalHours - data.totalHoursPaid) * data.costPerHour).toFixed(2);

  const currency = data && data.currencyIsoCode;

  return (
    <div className={classes.wrapper}>
      <h4>{translate(852, 'Manage')}</h4>

      {isLoading || processing ? (
        <CircularProgress />
      ) : (
        <>
          <div>
            <table>
              <tbody>
                {data.fullyPaid && (
                  <tr>
                    <th>{translate(747, 'Status:')}</th>
                    <td>
                      {data.fullyPaid
                        ? translate(0, 'Fully-paid')
                        : translate(773, 'Installment Plan')}
                    </td>
                  </tr>
                )}

                {!data.fullyPaid && (
                  <>
                    {/* <tr>
                <th>{translate(0, 'Total Hours:')}</th>
                <td>{data.totalHours}</td>
              </tr> */}
                    {myAccountValues.totalHoursPaid && (
                      <tr>
                        <th>{translate(853, 'Total hours paid:')}</th>
                        <td>{data.totalHoursPaid}</td>
                      </tr>
                    )}

                    {myAccountValues.costPerHour && (
                      <tr>
                        <th>{translate(854, 'Cost per hour:')}</th>
                        <td>
                          {data.costPerHour} {currency}
                        </td>
                      </tr>
                    )}

                    {myAccountValues.remainingHoursPrice && (
                      <tr>
                        <th>{translate(855, 'Remaining hours price:')}</th>
                        <td>
                          {remainingHoursPrice} {currency}
                        </td>
                      </tr>
                    )}

                    {myAccountValues.hoursRemaining && (
                      <tr
                        className={cs({
                          [classes.blink]: data.prepaidHoursRemaining < 0.5,
                        })}
                      >
                        <th>{translate(856, 'Hours remaining:')}</th>
                        <td>
                          <span>
                            {data.prepaidHoursRemaining &&
                              hoursToString(data.prepaidHoursRemaining)
                                .replace('hour(s)', translate(862, 'hour(s)'))
                                .replace(
                                  'minute(s)',
                                  translate(863, 'minute(s)')
                                )}
                          </span>
                        </td>
                      </tr>
                    )}

                    {myAccountValues.buyHours && (
                      <tr>
                        <th>{translate(857, 'Buy hours:')}</th>
                        <td>
                          <div className={classes.cellWithAction}>
                            <span>
                              <input
                                className={classes.hoursInput}
                                value={String(hoursToBuy)}
                                onChange={changeHoursToBuy}
                                onKeyDown={(e) => e.preventDefault()}
                                type="number"
                                min={Math.min(
                                  data.hoursInIncrement,
                                  data.totalHours - data.totalHoursPaid
                                )}
                                max={data.totalHours - data.totalHoursPaid}
                                step={data.hoursInIncrement}
                              />
                            </span>

                            <SettingsButton
                              disabled={hoursToBuy <= 0}
                              onClick={() => {
                                setBuyType(BUY_HOURS_TYPE);
                                setOpenPayment(true);
                              }}
                            >
                              {translate(859, 'Buy')}{' '}
                              {buyHoursAmount
                                ? `- ${buyHoursAmount} ${currency}`
                                : ''}
                            </SettingsButton>
                          </div>
                        </td>
                      </tr>
                    )}

                    {myAccountValues.buyout && (
                      <tr>
                        <th>
                          {translate(858, 'Buyout price:')}
                          <div className={classes.note}>
                            {data.discountPercents
                              ? `(${translate(861, 'Discount - ')}
                          ${data.discountPercents}%)`
                              : ''}
                          </div>
                        </th>
                        <td>
                          <div className={classes.cellWithAction}>
                            <span>
                              {data.buyoutCost} {currency}
                            </span>{' '}
                            <SettingsButton
                              onClick={() => {
                                setBuyType(BUYOUT_TYPE);
                                setOpenPayment(true);
                              }}
                            >
                              {translate(860, 'Buyout')}
                            </SettingsButton>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            {currency === 'KES' && (
              <div style={{ textAlign: 'center' }}>
                <SettingsButton onClick={refetch} disabled={isFetching}>
                  Refresh
                </SettingsButton>
              </div>
            )}
          </div>

          {openPayment && (
            <div className={classes.paymentFormWrapper}>
              <b className={classes.paymentAmount}>
                Amount - {paymentAmount} {currency}
              </b>

              {currency === 'USD' && (
                <BraintreePaymentForm
                  amount={paymentAmount}
                  onSuccess={makeBraintreePayment}
                  onCancel={() => setOpenPayment(false)}
                  familyId={family._id}
                />
              )}

              {currency === 'KES' && (
                <form
                  className={classes.mpesaPaymentBlock}
                  onSubmit={submitMpesaPaymentForm}
                >
                  <label htmlFor="phone">
                    Enter your phone number for MPESA payment:
                  </label>

                  <span className={classes.helper}>Format: 07XX XXX XXX</span>
                  <input
                    onKeyDown={(e) => {
                      if (e.key.length === 1 && !e.key.match(/[0-9]/)) {
                        e.preventDefault();
                      }
                    }}
                    maxLength="10"
                    minLength="`0"
                    required
                    pattern="07[0-9]{8}"
                    type="text"
                    name="phone"
                    id="phone"
                  />

                  <SettingsButton type="submit">
                    {translate(873, 'Submit')}
                  </SettingsButton>

                  <SettingsButton
                    type="button"
                    onClick={() => setOpenPayment(false)}
                    color="secondary"
                  >
                    {translate(52, 'Cancel')}
                  </SettingsButton>
                </form>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

ManagePrepaid.propTypes = {
  translate: PropTypes.func.isRequired,
  showPromptPromise: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  myAccountValues: PropTypes.object.isRequired,
};

const mapStateToProps = ({ flinkPlay, status }, ownProps) => ({
  translate: ownProps.translate || flinkPlay.translate || status.translate,
  family: flinkPlay.family,
  myAccountValues: flinkPlay.learningCenter.config.myAccountValues || {},
});

export default connect(mapStateToProps, {
  showPromptPromise,
  showError,
  showInfo,
})(ManagePrepaid);
