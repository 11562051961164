import _ from "lodash";
import WS from "@blex41/word-search";

import { addIdToFilename } from "utils";

const getDisabledDirections = wordDirections => {
  const { diagonal, horizontal, vertical } = wordDirections;

  // Directions - "N", "S", "E", "W", "NE", "NW", "SE", "SW"
  let disabledDirections = ["N", "W", "NE", "NW", "SW"];

  !diagonal && disabledDirections.push("SE"); // Top left - bottom right
  !horizontal && disabledDirections.push("E"); // Left - Right
  !vertical && disabledDirections.push("S"); // Top - Bottom

  if (disabledDirections.length === 8) {
    // All directions are disabled, allow horizontal
    console.log("Use default horizontal direction");
    disabledDirections = disabledDirections.filter(d => d !== "E");
  }

  return disabledDirections;
};

export const generatePuzzle = (problem, activityOptions) => {
  const { wordSearch = {}, wordDirections = {} } = activityOptions;
  const { sharedLetters } = wordSearch;

  const { words } = problem;

  const wordsCount = words.length;
  const maxWordLength = Math.max(...words.map(w => w.length));

  let gridSize = Math.max(maxWordLength);

  // If an option is missing, it will be given a default value
  const options = {
    disabledDirections: getDisabledDirections(wordDirections),
    dictionary: words,
    maxWords: 20,
    backwardsProbability: 0,
    upperCase: true,
    diacritics: true
  };

  let iterationsCount = 0;

  let puzzle,
    currentSize = gridSize,
    iterationsCountBeforeIncreaseSize = 10;

  outer: do {
    iterationsCount++;

    if (iterationsCount === 10000) {
      alert("Cant generate puzzle");
      return;
    }

    if (iterationsCountBeforeIncreaseSize-- === 0) {
      iterationsCountBeforeIncreaseSize = 10;
      currentSize++;
    }

    const currentOptions = { ...options, cols: currentSize, rows: currentSize };

    if (sharedLetters || words.length < 9) {
      puzzle = new WS(currentOptions);
    } else {
      let hasSharedLetters = false;
      let iterationsCountBeforeBreak = 20;

      // Generate puzzle until there will be no shared letters
      do {
        if (iterationsCountBeforeBreak-- === 0) {
          iterationsCountBeforeIncreaseSize = 0;
          continue outer;
        }

        puzzle = new WS(currentOptions);

        const allPaths = _.chain(puzzle.words)
          .map(w => w.path)
          .flatten()
          .map(p => JSON.stringify(p))
          .value();

        const uniquePaths = _.uniq(allPaths);

        // All paths should be uniq
        hasSharedLetters = uniquePaths.length !== allPaths.length;
      } while (hasSharedLetters);
    }
  } while (puzzle.words.length !== wordsCount);

  return puzzle;
};

export const checkStep = (prevPath, nextPath) => {
  const { x: prevX, y: prevY } = prevPath;
  const { x: nextX, y: nextY } = nextPath;

  const deltaX = prevX - nextX;
  const deltaY = prevY - nextY;

  if (deltaX > 1 || deltaX < -1) {
    return false;
  }

  if (deltaY > 1 || deltaY < -1) {
    return false;
  }

  return true;
};

export const getDirection = (prevPath, nextPath) => {
  const { x: prevX, y: prevY } = prevPath;
  const { x: nextX, y: nextY } = nextPath;

  let direction = "";

  if (nextY > prevY) {
    direction += "S";
  } else if (nextY < prevY) {
    direction += "N";
  }

  if (nextX > prevX) {
    direction += "E";
  } else if (nextX < prevX) {
    direction += "W";
  }

  return direction;
};

const filterWords = words => {
  return words.filter(w => w && w.word.length >= 3 && w.word.length <= 12);
};

export const validateWords = (words, templateDBrecord) => {
  const filteredWords = filterWords(words);

  // Should be minimum 3 words
  if (filteredWords.length < 3) {
    return { success: false, error: "Should be minimum three words" };
  }

  return { success: true, filteredWords };
};

export const generateActivityFromWords = ({ words, template }) => {
  const wordsAudio = [];
  const audio = {};

  const filteredWords = filterWords(words);

  filteredWords.forEach(w => {
    if (!w.wordAudio) return;

    const filename = addIdToFilename(w.wordAudio);

    wordsAudio.push({
      src: w.wordAudio,
      filename
    });

    audio[w.word] = filename;
  });

  // Word Search has only single problem
  const problem = {
    words: filteredWords.map(w => w.word),
    wordsAudio: audio
  };

  const gameData = {
    problems: [problem]
  };

  return { gameData, wordsAudio };
};
