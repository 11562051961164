import React from 'react';
import styled from 'styled-components';

import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';

const RefreshScreen: React.FC<{
  text: string;
  refreshHandler: () => void;
}> = ({ text, refreshHandler }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <h2>{text}</h2>

      <StyledRefreshButton
        color="primary"
        onClick={refreshHandler}
        style={{ fontSize: 20 }}
      >
        <RefreshIcon />
      </StyledRefreshButton>
    </div>
  );
};

export default RefreshScreen;

const StyledRefreshButton = styled(IconButton)`
  svg {
    font-size: 80px;
  }
`;
