import * as React from "react";
import { imagesURL } from 'config';

const WritingFeedbackButton = ({ getContent, checkWriting, isOpen, translate }) => {
  const check = React.useCallback(() => {
    const content = getContent();
    checkWriting(content);
  }, [getContent, checkWriting]);

  return (
    <button type="button" onClick={check} disabled={isOpen}>
      <img
        src={`${imagesURL}/TemplateGraphics/WritingAssistant/Feedback.png`}
        alt={translate(1015, 'Writing Feedback')}
        width={45}
        height={45}
      />
      <span>{translate(950, 'Feedback')}</span>
    </button>
  );
}

export default WritingFeedbackButton;
