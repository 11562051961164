import Quill from "quill";
const Parchment = Quill.import("parchment");

const multiplier = 1;

class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    return super.add(node, `${value * multiplier}em`);
  }

  value(node) {
    return parseFloat(super.value(node)) / multiplier || undefined;
  }
}

const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const IndentStyle = new IndentAttributor("indent", "padding-left", {
  scope: Parchment.Scope.BLOCK,
  whitelist: levels.map(value => `${value * multiplier}em`)
});

Quill.register(IndentStyle, true);