import WritingFeedback from "./WritingFeedback";

// actions
import { closeWritingFeedback } from "actions/flinkPlayActions";

// redux
import { connect } from "react-redux";

const mapStateToProps = ({ status, flinkPlay: { writingFeedback, solutionTranslate } }) => ({
  feedback: writingFeedback,
  translate: solutionTranslate || status.translate,
});

const mapDispatchToProps = {
  close: closeWritingFeedback,
}

export default connect(mapStateToProps, mapDispatchToProps)(WritingFeedback);
