import { DraggableModal } from 'components/flink-components';

const WritingFeedback = ({ feedback, close, translate }) => (
  <DraggableModal 
    title={translate(1015, 'Writing Feedback')}
    show={feedback.open} 
    onClose={close}>
    <div 
      style={{ minWidth: "25rem", maxWidth: "45rem" }} 
      dangerouslySetInnerHTML={{ __html: feedback.feedback }} 
    />
  </DraggableModal>
);

export default WritingFeedback;
