import { axiosAPI, responseHandler } from 'utils';

export default {
  getList: async (learningCenterId) => {
    return responseHandler(
      axiosAPI.get(`/api/organization-purchase/get-list/${learningCenterId}`)
    );
  },
  addLearningCenterPurchase: async (learningCenterId, values) => {
    return responseHandler(
      axiosAPI.post(
        `/api/organization-purchase/add-learning-center-purchase/${learningCenterId}`,
        values
      )
    );
  },
  addPlansToPurchase: async (purchaseId, number) => {
    return responseHandler(
      axiosAPI.post(
        `/api/organization-purchase/add-plans-to-purchase/${purchaseId}`,
        { number }
      )
    );
  },
  subtractPlansFromPurchase: async (purchaseId, number) => {
    return responseHandler(
      axiosAPI.post(
        `/api/organization-purchase/subtract-plans-from-purchase/${purchaseId}`,
        { number }
      )
    );
  },
  deleteLearningCenterPurchase: async (id) => {
    return responseHandler(
      axiosAPI.delete(
        `/api/organization-purchase/delete-learning-center-purchase/${id}`
      )
    );
  },
  getUnusedCodes: async (purchaseId) =>
    responseHandler(
      axiosAPI.get(`/api/organization-purchase/get-codes/${purchaseId}/unused`)
    ),
  getActivatedCodes: async (purchaseId) =>
    responseHandler(
      axiosAPI.get(
        `/api/organization-purchase/get-codes/${purchaseId}/activated`
      )
    ),
  checkCodeAvailability: async (code) =>
    responseHandler(
      axiosAPI.get(`/api/organization-purchase/check-code-availability/${code}`)
    ),
  registerByCode: async (code, values) =>
    responseHandler(
      axiosAPI.post(
        `/api/organization-purchase/register-by-code/${code}`,
        values
      )
    ),
};
