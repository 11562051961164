/* eslint eqeqeq: 0 */

import shortid from 'shortid';
import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {
    ProblemOrder: 1,
    ShowAnswer: 2,
    LengthOfTime: 3,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const {
    Worksheet: { SegmentData },
  } = gameData;

  const firstProblem = SegmentData[0];

  const fontColor =
    firstProblem.FontColor && firstProblem.FontColor != '0'
      ? firstProblem.FontColor
      : '#000';
  const fontFamily =
    firstProblem.InstrFontName && firstProblem.InstrFontName != '0'
      ? firstProblem.InstrFontName
      : 'Arial';

  const problems = SegmentData.map((data) => {
    const { InstrText, InstrAudio, GroupData } = data;

    let [questions, correctAnswers] = GroupData.map((group) => {
      return group.ItemData.map(({ ItemType, ItemData, ItemAudio }) => {
        return {
          text: ItemType == 1 ? ItemData : '',
          image: ItemType == 0 ? ItemData : '',
          audio: ItemAudio,
        };
      });
    });

    questions.length > 4 && (questions.length = 4);
    correctAnswers.length > 4 && (correctAnswers.length = 4);

    return {
      id: shortid.generate(),
      questions,
      correctAnswers,
      instructionText: InstrText,
      instructionAudio: InstrAudio,
    };
  });

  result.gameData = { problems };
  result.options = { ...options, fontFamily, fontColor };
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
