import React from 'react';
import classNames from 'classnames';

import { imagesURL } from 'config';
import SettingsSlider from '../SettingsSlider/SettingsSlider';
import classes from '../Settings.module.scss';

const charactersFolder = `${imagesURL}/SettingsDialog/characters`;

const SelectCharacter = ({ value, onChange, options }) => {
  return (
    <SettingsSlider slidesToShow={4}>
      <div>
        <button
          className={classNames(classes.itemButton, {
            [classes.selectedItem]: value === '',
          })}
          onClick={() => onChange('')}
          title="No Character"
        >
          <div
            className={classes.characterImgWrapper}
            style={{
              backgroundImage: `url(${charactersFolder}/NoCharacter.png)`,
            }}
          />
        </button>
      </div>
      {options.map((opt) => {
        const filename = opt.label.replace(/\s/g, '') + '.png';

        return (
          <div key={opt.value}>
            <button
              className={classNames(classes.itemButton, {
                [classes.selectedItem]: value === opt.value,
              })}
              onClick={() => onChange(opt.value)}
            >
              <div
                title={opt.label}
                className={classes.characterImgWrapper}
                style={{
                  backgroundImage: `url(${charactersFolder}/${filename})`,
                }}
              />
            </button>
          </div>
        );
      })}
    </SettingsSlider>
  );
};

export default SelectCharacter;
