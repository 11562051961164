import _ from 'lodash';

import { REP } from 'consts/user-roles';
import getName from './getName';

export default users => {
  return _.chain(users)
    .filter({ roleAlias: REP })
    .map(rep => ({ ...rep, name: getName(rep.firstname, rep.lastname) }))
    .value();
};
