import { SET_FLINK_MAKE_COMMON_DATA } from 'actions/types';

const INITIAL_STATE = { loaded: false };

const filter = 'flinkMake/common';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_FLINK_MAKE_COMMON_DATA:
      return { ...action.payload, loaded: !!action.payload } || INITIAL_STATE;
    default:
      return state;
  }
}
