import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { s3bucketPublicURL } from 'config';

import StopItAnswers from './StopItAnswers/StopItAnswers';
import KeepGoingAnswers from './KeepGoingAnswers/KeepGoingAnswers';

const getNumAnimationSpeed = speed => {
  const animationSpeed = {
    slow: 3000,
    medium: 2000,
    fast: 1000
  };

  return animationSpeed[speed] || 2000;
};

const colors = [
  { backgroundColor: '#f0d8ff', borderColor: '#d897ff', borderWidth: '2px' },
  { backgroundColor: '#ffced0', borderColor: '#FF8B90', borderWidth: '2px' },
  { backgroundColor: '#ffe7c1', borderColor: '#FFC972', borderWidth: '2px' },
  { backgroundColor: '#ccf2ff', borderColor: '#47D2FF', borderWidth: '2px' },
  { backgroundColor: '#e8fff3', borderColor: '#B7FAD8', borderWidth: '2px' }
];

const AnswersBlock = ({
  activity,
  question,
  resolved,
  multiplayer,
  animation,
  textStyle,
  answerClickHandler
}) => {
  const { options, gameData } = activity.data;
  const { contentFolder } = activity.activity;
  const { withAudio, useImages, longAnswers } = gameData;

  const isKeepGoingAnimations = !longAnswers && !withAudio;

  const animationSpeed = useMemo(
    () => getNumAnimationSpeed(options.animationSpeed),
    [options]
  );

  if (!question) return null;

  const contentPath = `${s3bucketPublicURL}/${contentFolder}/gamedata/`;

  return isKeepGoingAnimations ? (
    <KeepGoingAnswers
      animation={animation}
      useImages={useImages}
      question={question}
      isResolved={resolved}
      isMultiplayer={multiplayer}
      animationSpeed={animationSpeed}
      contentPath={contentPath}
      colors={colors}
      textStyle={textStyle}
      coloredAnswerBoxes={options.coloredAnswerBoxes}
      visibleAfterResolvedTime={options.delayBeforeNext}
      clickHandler={answerClickHandler}
    />
  ) : (
    <StopItAnswers
      animation={animation}
      useImages={useImages}
      withAudio={withAudio}
      longAnswers={longAnswers}
      question={question}
      isResolved={resolved}
      isMultiplayer={multiplayer}
      animationSpeed={animationSpeed}
      contentPath={contentPath}
      colors={colors}
      textStyle={textStyle}
      coloredAnswerBoxes={options.coloredAnswerBoxes}
      visibleAfterResolvedTime={options.delayBeforeNext}
      clickHandler={answerClickHandler}
    />
  );
};

AnswersBlock.propTypes = {
  activity: PropTypes.object.isRequired,
  question: PropTypes.object,
  resolved: PropTypes.bool,
  multiplayer: PropTypes.bool,
  textStyle: PropTypes.object,
  answerClickHandler: PropTypes.func.isRequired
};

export default AnswersBlock;
