import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { Editor } from 'components/flink-components';
import renderTextField from './renderTextField';
import { MaterialFaIcon } from 'utils';

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
  },
  popup: {
    position: 'absolute',
    zIndex: 10,
    background: '#fff',
    width: '100%',
    border: '1px solid #eee',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingBottom: '4rem',
  },
  editorWrapper: {},
  editor: {
    height: '250px',
    padding: '1rem',
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 11,
    display: 'flex',
  },
  finishButton: {
    marginRight: 10,
  },
});

class FieldWysiwyg extends Component {
  state = {
    inputFocused: false,
  };

  inputField = React.createRef();

  onInputChange = (e) => {
    e.preventDefault();
  };

  onFocus = () => {
    this.setState({ inputFocused: true });
  };

  onClear = () => {
    const { change, formName, name } = this.props;
    this.setState({ inputFocused: false }, () => {
      change(formName, name, null);
    });
  };

  onFinish = () => {
    this.setState({ inputFocused: false });
  };

  onEditorChange = (html) => {
    const { change, formName, name } = this.props;
    change(formName, name, html);
  };

  render() {
    const { inputFocused } = this.state;
    const { name, classes, label, addAudioButton } = this.props;

    const initialValue =
      this.inputField &&
      this.inputField.current &&
      this.inputField.current.value;

    return (
      <div className={classes.wrapper}>
        <Field
          ref={this.inputField}
          name={name}
          component={renderTextField}
          label={label}
          onChange={this.onInputChange}
          onFocus={this.onFocus}
        />
        {inputFocused && (
          <div className={classes.popup}>
            <Editor
              className={classes.editor}
              initialValue={initialValue || ''}
              changeHandler={this.onEditorChange}
              withAddAudio={addAudioButton}
            />
            <div className={classes.actions}>
              <Fab
                className={classes.finishButton}
                onClick={this.onFinish}
                color="primary"
                size="medium"
              >
                <MaterialFaIcon icon="check" />
              </Fab>
              <Fab onClick={this.onClear} color="secondary" size="medium">
                <MaterialFaIcon icon="eraser" />
              </Fab>
            </div>
          </div>
        )}
      </div>
    );
  }
}

FieldWysiwyg.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(null, { change }),
  withStyles(styles)
)(FieldWysiwyg);
