import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { imagesURL } from 'config';
import { formatHtmlForPlay } from 'utils';
import classes from './EbookComponent.module.scss';
import { WhiteBox } from 'components/flink-play';

import { EBOOK_WIDTH, EBOOK_HEIGHT } from 'consts/ebook-consts';

const prevBtnImg = `${imagesURL}/TemplateGraphics/e-Book/arrow_left.png`;
const nextBtnImg = `${imagesURL}/TemplateGraphics/e-Book/arrow_right.png`;
const replayBtnImg = `${imagesURL}/Images/Audio/audio_replay.png`;

const getPercents = (size, base) => {
  if (size.indexOf && size.indexOf('%') !== -1) return size;
  return (parseFloat(size) / base) * 100 + '%';
};

class EbookComponent extends Component {
  constructor(props) {
    super(props);

    const { smallWidth, largeWidth } = props;

    const portraitEbookStyles = {
      width: smallWidth + 'vmin',
      height: (EBOOK_HEIGHT / EBOOK_WIDTH) * smallWidth + 'vmin',
    };

    const landscapeEbookStyles = {
      width: largeWidth + 'vmin',
      height: (EBOOK_HEIGHT / EBOOK_WIDTH) * largeWidth + 'vmin',
    };

    this.state = {
      ebookStyles: portraitEbookStyles,
      landscapeEbookStyles,
      portraitEbookStyles,
    };
  }

  componentDidMount() {
    this.onChangeAspectRatio();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.aspectRatio !== this.props.aspectRatio) {
      this.onChangeAspectRatio();
    }
  }

  onImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;

    let width = (naturalWidth / EBOOK_WIDTH) * 100;
    let height = (naturalHeight / EBOOK_HEIGHT) * 100;

    if (width > 100 && width > height) {
      width = '100%';
      height = 'auto';
    } else if (height > 100 && height > width) {
      height = '100%';
      width = 'auto';
    } else {
      height = height + '%';
      width = width + '%';
    }

    this.setState({
      imageStyles: {
        width: width,
        height: height,
      },
    });
  };

  onChangeAspectRatio = () => {
    const { aspectRatio } = this.props;
    const { landscapeEbookStyles, portraitEbookStyles } = this.state;

    this.setState({
      ebookStyles:
        aspectRatio >= 1.25 ? landscapeEbookStyles : portraitEbookStyles,
    });
  };

  onVideoEnded = () => {
    const { onVideoEnded } = this.props;
    onVideoEnded && onVideoEnded();
  };

  videoRef = React.createRef();

  replayVideo = () => {
    const { replayHandler } = this.props;

    const reactPlayer = this.videoRef.current;
    if (!reactPlayer || !replayHandler) return;

    reactPlayer.seekTo(0);
    replayHandler();
  };

  render() {
    const { imageStyles, ebookStyles } = this.state;
    const {
      page,
      assetsFolder,
      prevClickHandler,
      nextClickHandler,
      videoPlaying = false,
      wrapperStyles = {},
      showVideReplayBtn,
      showPrev = false,
      showNext = false,
    } = this.props;
    const { image, video, text = [] } = page || {};

    const imageUrl = image && assetsFolder + image;
    const videoUrl = video && assetsFolder + video;

    return (
      <WhiteBox outerClass={classes.ebookWrapper} outerStyle={wrapperStyles}>
        <div className={classes.ebook} style={ebookStyles}>
          {image && (
            <img
              style={imageStyles}
              onLoad={this.onImageLoad}
              ref={this.imageRef}
              className={classes.ebookImage}
              src={imageUrl}
              alt=""
            />
          )}

          {video && (
            <ReactPlayer
              ref={this.videoRef}
              playing={videoPlaying}
              width="100%"
              height="100%"
              url={videoUrl}
              onEnded={this.onVideoEnded}
            />
          )}

          {video && showVideReplayBtn && (
            <button className={classes.replayBtn} onClick={this.replayVideo}>
              <img src={replayBtnImg} alt="" />
            </button>
          )}

          {showPrev && (
            <button
              onClick={prevClickHandler}
              className={classnames(classes.navBtn, classes.prevBtn)}
            >
              <img src={prevBtnImg} alt="Previous" />
            </button>
          )}

          {showNext && (
            <button
              onClick={nextClickHandler}
              className={classnames(classes.navBtn, classes.nextBtn)}
            >
              <img src={nextBtnImg} alt="Next" />
            </button>
          )}

          {text.map((t) => {
            if (!t) return null;

            const { width, height, left, top } = t.dim;

            return (
              <div
                style={{
                  padding: '1.5vmin 1.875vmin',
                  width: getPercents(width, EBOOK_WIDTH),
                  height: getPercents(height, EBOOK_HEIGHT),
                  left: getPercents(left, EBOOK_WIDTH),
                  top: getPercents(top, EBOOK_HEIGHT),
                }}
                className={'ql-editor ' + classes.text}
                key={t.id}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatHtmlForPlay({
                      html: t.text,
                      calc: (size) =>
                        (size /
                          ((EBOOK_HEIGHT / parseFloat(ebookStyles.height)) *
                            100)) *
                        100,
                    }),
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </WhiteBox>
    );
  }
}

EbookComponent.defaultProps = {
  smallWidth: 94,
  largeWidth: 113,
};

EbookComponent.propTypes = {
  aspectRatio: PropTypes.number,
  smallWidth: PropTypes.number,
  largeWidth: PropTypes.number,
  page: PropTypes.object.isRequired,
  assetsFolder: PropTypes.string.isRequired,
  prevClickHandler: PropTypes.func,
  nextClickHandler: PropTypes.func,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
};

const mapStateToProps = ({ status: { aspectRatio } }) => ({ aspectRatio });

export default connect(mapStateToProps)(EbookComponent);
