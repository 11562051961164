import _ from 'lodash';

export default class Scope {
  constructor(scope) {
    if (!scope || !scope._id) {
      throw new Error('Scope not valid');
    }

    this._data = scope;
    this._id = scope._id;
  }

  getFirstMenu() {
    return this._data.firstMenu;
  }

  getSingleFirstMenuTitle() {
    const { firstMenu } = this._data;
    return firstMenu && firstMenu.substring(0, firstMenu.length - 1);
  }

  getFirstCategoryTitle() {
    return this._data.firstCategory.title;
  }

  getFirstCategoryTitleById(id) {
    const firstCategoryItem = _.find(this.getFirstCategoryItems(), { id });

    return firstCategoryItem ? firstCategoryItem.title : '';
  }

  getSecondCategoryTitle() {
    return this._data.secondCategory.title;
  }

  getSecondCategoryTitleById(id) {
    const item = _.find(this.getSecondCategoryItems(), { id });

    return item ? item.title : '';
  }

  getThirdCategoryTitle() {
    return this._data.thirdCategory.title;
  }

  getThirdCategoryTitleById(id) {
    const item = _.find(this.getThirdCategoryItems(), { id });

    return item ? item.title : '';
  }

  getFirstMenuItems() {
    return this._data[this._data.firstMenu];
  }

  getFirstCategoryItems(firstMenuItem) {
    if (!firstMenuItem) return this._data.firstCategory.items;

    return _.filter(this._data.firstCategory.items, item =>
      item[this._data.firstMenu] && item[this._data.firstMenu].includes(firstMenuItem)
    );
  }

  getSecondCategoryItems(dependencies = {}, firstMenuItem) {
    const lists = _.filter(this._data.secondCategory.lists, dependencies);

    if (!lists.length) return [];

    const items = [];

    lists.forEach(list => items.push(...list.items));

    if (!firstMenuItem) {
      return items;
    }

    return items.filter(
      item =>
        item[this._data.firstMenu] &&
        item[this._data.firstMenu].includes(firstMenuItem)
    );
  }

  getThirdCategoryItems(dependencies = {}, firstMenuItem) {
    const lists = _.filter(this._data.thirdCategory.lists, dependencies);

    if (!lists.length) return [];

    const items = [];

    lists.forEach(list => items.push(...list.items));

    if (!firstMenuItem) {
      return items;
    }

    return items.filter(
      item =>
        item[this._data.firstMenu] &&
        item[this._data.firstMenu].includes(firstMenuItem)
    );
  }
}
