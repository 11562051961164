import { axiosAPI } from 'utils';

export default {
  getDistinctValues(params) {
    return axiosAPI
      .get(`api/admin/strings/distinct-values`, { params })
      .then((res) => res.data.map(function (value) {
        return { label: value, value };
      }))
      .catch((err) => {
        console.error(err);
        return err.response.data;
      });
  },
}