import {
  ADD_DATA,
  SET_DATA,
  EDIT_DATA,
  DELETE_DATA,
  CLEAR_ADMIN_DATA,
  IMPORT_WORDS,
  IMPORT_GIFT_CARDS,
} from '../types';
import { axiosAPI as axios, withLoading } from 'utils';

const filter = 'admin';

const commonData = [
  'learningCenters',
  'activityTemplates',
  'locales',
  'strings',
];

export const clearAdminData = () => ({
  filter,
  type: CLEAR_ADMIN_DATA,
});

export const addData = (type, data) => (dispatch) =>
  withLoading(dispatch, async () => {
    const result = {};

    try {
      const res = await axios.post(`/api/admin/${type}/add`, data);

      dispatch({
        filter,
        type: ADD_DATA,
        payloadType: type,
        payload: res.data,
      });

      // If data is common -> change in common reducer
      if (commonData.includes(type)) {
        dispatch({
          filter: 'common',
          type: ADD_DATA,
          payloadType: type,
          payload: res.data,
        });
      }

      result.success = true;
      result.data = res.data;

      return result;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { _error: 'No response from server' };
      }

      console.log(result);

      return result;
    }
  });

export const editData = (type, data) => (dispatch) =>
  withLoading(dispatch, async () => {
    const result = {};

    try {
      const res = await axios.post(`/api/admin/${type}/edit`, data);

      dispatch({
        filter,
        payloadType: type,
        type: EDIT_DATA,
        payload: res.data,
      });

      // If data is common -> change in common reducer
      if (commonData.includes(type)) {
        dispatch({
          filter: 'common',
          type: EDIT_DATA,
          payloadType: type,
          payload: res.data,
        });
      }

      result.success = true;
      result.data = res.data;

      return result;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { _error: 'No response from server' };
      }

      console.log(result);

      return result;
    }
  });

export const deleteData = (type, id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/admin/${type}/byId?id=${id}`);

    if (!res.data.success) return false;

    dispatch({
      filter,
      type: DELETE_DATA,
      payloadType: type,
      payload: res.data.deletedData || id,
    });

    // If data is common -> change in common reducer
    if (commonData.includes(type)) {
      dispatch({
        filter: 'common',
        type: DELETE_DATA,
        payloadType: type,
        payload: res.data.deletedData || id,
      });
    }

    return true;
  } catch (err) {
    console.log(err);

    return false;
  }
};

export const fetchData = (type) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(`/api/admin/${type}/list`);

      dispatch({
        filter,
        type: SET_DATA,
        payloadType: type,
        payload: res.data,
      });

      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  });

export const importWords = (data) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.post(`/api/admin/words/import`, data);

      dispatch({
        filter,
        type: IMPORT_WORDS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      const result = (err && err.response && err.response.data) || {
        _error: 'No response from server',
        success: false,
      };

      return result;
    }
  });

export const importGiftCards = (data) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.post(`/api/admin/giftCards/import`, data);

      dispatch({
        filter,
        type: IMPORT_GIFT_CARDS,
        payload: res.data.giftCards,
      });

      return res.data;
    } catch (err) {
      const result = (err && err.response && err.response.data) || {
        _error: 'No response from server',
        success: false,
      };

      return result;
    }
  });
