import shortId from 'shortid';

export default filename => {
  const matchResults = filename.match(/\/?([^/]+)\.(\S+)$/);

  if (!matchResults) {
    return `${filename}_${shortId.generate()}`;
  }
  const [, originFileName, extension] = matchResults;

  return `${originFileName}_${shortId.generate()}.${extension}`;
};
