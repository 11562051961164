/* eslint eqeqeq: 0 */

import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {};

  const { gameData, options, oldOptions, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const problems = gameData.problems.map((data) => {
    const { words, title, audio, instructions } = data;

    const filteredWords = words.filter(
      (w) => w && w.length >= 3 && w.length <= 12
    );

    return {
      words: filteredWords,
      title,
      instructionText: instructions,
      instructionAudio: audio,
    };
  });

  result.gameData = { problems };

  const {
    WordSearchHorizontal,
    WordSearchVertical,
    WordSearchDiagonal,
    WordSearchSharedLetters = true,
    WordSearchHints = true,
    WordSearchAlphabetize = true,
  } = oldOptions;

  const wordDirections = {
    horizontal: WordSearchHorizontal === 'forward',
    vertical: WordSearchVertical === 'forward',
    diagonal: WordSearchDiagonal === 'forward',
  };

  const wordSearch = {
    sharedLetters: WordSearchSharedLetters,
    showHints: WordSearchHints,
    alphabetize: WordSearchAlphabetize,
  };

  result.options = { ...options, wordDirections, wordSearch };
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
