import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { renderCheckbox, renderTextField } from 'components/form-components';

export const FormCheckbox = props => {
  return <Field component={renderCheckbox} {...props} />;
};

export const FormTextfield = props => {
  return <Field component={renderTextField} {...props} />;
};

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

FormTextfield.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
