export default {
  getType: function (elem) {
    return Object.prototype.toString.call(elem).slice(8, -1);
  },
  isObject: function (elem) {
    return this.getType(elem) === 'Object';
  },
  isArray: function (elem) {
    return this.getType(elem) === 'Array';
  }
};