import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import { MaterialFaIcon } from 'utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    '&:focus': {
      outline: 'none',
    },
  },
  deleteButton: {
    color: '#e00000',
    borderColor: '#e00000',
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
  },
  cancelButton: {
    color: '#555',
    borderColor: '#555',
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
}));

const ConfirmDeleteModal = ({ handleClose, handleDelete, text, isOpen }) => {
  const classes = useStyles();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.paper}>
        <Typography variant="subtitle1" id="simple-modal-description">
          Are you sure you want to delete <b>{text}</b>?
        </Typography>

        <div className={classes.buttonsWrapper}>
          <Button
            // variant="outlined"
            className={classes.deleteButton}
            onClick={handleDelete}
          >
            <MaterialFaIcon icon="trash-alt" className={classes.leftIcon} />
            Delete
          </Button>
          <Button
            // variant="outlined"
            className={classes.cancelButton}
            onClick={handleClose}
          >
            <MaterialFaIcon icon="ban" className={classes.leftIcon} />
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  text: PropTypes.string,
};

export default ConfirmDeleteModal;
