import {
  SET_PLAYING,
  SET_CORRECT_SOUNDS_PLAYED,
  SET_INCORRECT_SOUNDS_PLAYED,
} from 'actions/types';

const INITIAL_STATE = {
  audioSrc: '',
  isPlaying: false,
  correctSoundsPlayed: 0,
  incorrectSoundsPlayed: 0,
};

const filter = 'audio';

export default function (state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_PLAYING: {
      return {
        ...state,
        audioSrc: action.payload.audioSrc,
        isPlaying: action.payload.isPlaying,
      };
    }
    case SET_CORRECT_SOUNDS_PLAYED: {
      return {
        ...state,
        correctSoundsPlayed: action.payload,
      };
    }
    case SET_INCORRECT_SOUNDS_PLAYED: {
      return {
        ...state,
        incorrectSoundsPlayed: action.payload,
      };
    }
    default:
      return state;
  }
}
