import * as templates from 'consts/activity-templates';

import AllTogetherNow from './all-together-now';
import Concentration from './concentration';
import DirectionsGrid from './directions-grid';
import FindTheOne from './find-the-one';
import MatchingDefinitions from './matching-definitions';
import MissingElements from './missing-elements';
import MatchIt from './match-it';
import MovingAnswers from './moving-answers';
import Ngeli from './ngeli';
import OrderIt from './order-it';
import ReadingDeluxe from './reading-deluxe';
import StoryStudio from './story-studio';
import SpellingConnectables from './spelling-connectables';
import SpellingKeyboard from './spelling-keyboard';
import SentenceConnectables from './sentence-connectables';
import WordConnectables from './word-connectables';
import WordSearch from './word-search';
import WritingAssistant from './writing-assistant';
import Ebook from './ebook';

export default {
  [templates.ALL_TOGETHER_NOW]: AllTogetherNow,
  [templates.CONCENTRATION]: Concentration,
  [templates.DIRECTIONS_GRID]: DirectionsGrid,
  [templates.EBOOK]: Ebook,
  [templates.FIND_THE_ONE]: FindTheOne,
  [templates.MATCHING_DEFINITIONS]: MatchingDefinitions,
  [templates.MISSING_ELEMENTS]: MissingElements,
  [templates.MATCH_IT]: MatchIt,
  [templates.MOVING_ANSWERS]: MovingAnswers,
  [templates.NGELI]: Ngeli,
  [templates.ORDER_IT]: OrderIt,
  [templates.READING_DELUXE]: ReadingDeluxe,
  [templates.STORY_STUDIO]: StoryStudio,
  [templates.SPELLING_CONNECTABLES]: SpellingConnectables,
  [templates.SPELLING_KEYBOARD]: SpellingKeyboard,
  [templates.SENTENCE_CONNECTABLES]: SentenceConnectables,
  [templates.WORD_CONNECTABLES]: WordConnectables,
  [templates.WORD_SEARCH]: WordSearch,
  [templates.WRITING_ASSISTANT]: WritingAssistant,
};
