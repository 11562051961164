import {
  CLEAR_FLINK_ADMIN_DATA,
  SET_TEACHER_LEARNING_CENTER,
  SET_TEACHER_REPORT_GROUPS,
  CREATE_REPORT_GROUP,
  EDIT_REPORT_GROUP,
  DELETE_REPORT_GROUP,
  CHANGE_SELECTED_REPORT_GROUP,
  CHANGE_SELECTED_FAMILY
} from 'actions/types';

const INITIAL_STATE = {
  learningCenter: null,
  reportGroups: null,
  selectedReportGroupId: null,
  selectedFamilyId: null
};

const filter = 'flinkAdmin';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case CLEAR_FLINK_ADMIN_DATA:
      return INITIAL_STATE;
    case SET_TEACHER_LEARNING_CENTER:
      return {
        ...state,
        learningCenter: action.payload
      };
    case SET_TEACHER_REPORT_GROUPS:
      return {
        ...state,
        reportGroups: action.payload
      };
    case CREATE_REPORT_GROUP:
      return {
        ...state,
        reportGroups: [...state.reportGroups, action.payload]
      };
    case EDIT_REPORT_GROUP:
      return {
        ...state,
        reportGroups: state.reportGroups.map(rp =>
          rp._id === action.payload._id ? action.payload : rp
        )
      };
    case DELETE_REPORT_GROUP:
      return {
        ...state,
        reportGroups: state.reportGroups.filter(
          rp => rp._id !== action.payload
        ),
        selectedReportGroupId: null
      };
    case CHANGE_SELECTED_REPORT_GROUP:
      return {
        ...state,
        selectedReportGroupId: action.payload
      };
    case CHANGE_SELECTED_FAMILY:
      return {
        ...state,
        selectedFamilyId: action.payload
      };
    default:
      return state;
  }
}
