// import { axiosAPI } from 'utils';
import storageAPI from './storageAPI';
import { activitiesKey } from 'config';
import { convertOldActivity } from 'activity-templates/utils';
import { prepareActivity } from 'activity-templates/utils';

class ActivityAPI {
  saveActivityData = (activityId, activityData) => {
    // const key = `${activitiesKey}/${activityId}/data.json`;

    return storageAPI.saveActivityData(activityId, activityData);
  };

  getActivityData = async (activityId, template) => {
    const key = `${activitiesKey}/${activityId}/data.json`;

    try {
      const data = await storageAPI.getFile(key, true);
      if (data) {
        return data;
      }

      console.log('data.json file not found');

      // Old Activity
      const convertedData = await this._getDataFromOldActivity(
        activityId,
        template
      );

      if (convertedData) {
        const preparedActivity = prepareActivity({
          data: convertedData,
          template,
        });

        console.log(
          `Convertation of activity with ID ${activityId} finished`,
          preparedActivity
        );
        await this.saveActivityData(activityId, preparedActivity.data);
        return preparedActivity.data;
      } else {
        throw new Error('Error converting old activity');
      }
    } catch (err) {
      console.log(err);
    }
  };

  _getDataFromOldActivity = (activityId, template) => {
    console.log(
      `Start converting activity with ID ${activityId} and template "${template.alias}"`
    );

    return storageAPI
      .listFiles(`public/Activities/${activityId}`, true)
      .then((list) => {
        const convertFunc = convertOldActivity[template.alias];

        if (!convertFunc) {
          throw new Error('Template convert func not found');
        }

        // Mockup needed for eval js files - gameData.js and optionsData_activity.js, cause there is used jQuery inside
        let Game = {};
        let $ = () => ({ trigger() {} });

        return convertFunc(list, $, Game);
      })
      .then((activityData) => {
        return {
          ...activityData,
          templateAlias: template.alias,
          templateId: template._id,
        };
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };
}

export default new ActivityAPI();
