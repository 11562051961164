import { LOGIN } from 'actions/types';

const INITIAL_STATE = {
  isAuthenticated: false,
  userRole: null,
  learner: null,
  user: null,
  team: null,
  isTeam: false,
  cleverLogin: false
};

const filter = 'auth';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
        isTeam: !!action.payload.team,
        isAuthenticated: true
      };
    default:
      return state;
  }
}
