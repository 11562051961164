import {
  LOADING,
  UPLOADING,
  SET_CURRENT_PAGE,
  CHANGE_LOCALE,
  SET_ASPECT_RATIO,
  START_REQUEST,
  END_REQUEST,
  SHOW_ERROR,
  SHOW_INFO,
  SHOW_PROMPT,
  SHOW_CONFIRM,
  TOGGLE_HELP,
  CHANGE_CURRENT_PAGE_TITLE,
  SET_HELP_COORDS,
} from './types';
import { getTranslateFunc, axiosAPI } from 'utils';

export const loading = (state) => ({
  filter: 'status',
  type: LOADING,
  payload: state,
});

export const changePageTitle = (payload) => ({
  filter: 'status',
  type: CHANGE_CURRENT_PAGE_TITLE,
  payload: payload,
});

export const uploading = (state) => ({
  // For s3 daownloads
  filter: 'status',
  type: UPLOADING,
  payload: state,
});

export const startRequest = (requestId) => ({
  filter: 'status',
  type: START_REQUEST,
  payload: requestId,
});

export const endRequest = (requestId) => ({
  filter: 'status',
  type: END_REQUEST,
  payload: requestId,
});

export const setCurrentPage = (match) => ({
  filter: 'status',
  type: SET_CURRENT_PAGE,
  payload: match,
});

export const changeLocale = (localeCode) => (dispatch, getState) => {
  const { common } = getState();
  const { strings, locales } = common;

  const locale = locales.filter((loc) => loc.code === localeCode)[0];
  const translate = getTranslateFunc(strings, locale.code);

  localStorage.setItem('localeCode', localeCode);

  axiosAPI.defaults.headers['User-Locale'] = localeCode;

  dispatch({
    filter: 'status',
    type: CHANGE_LOCALE,
    payload: { locale, translate },
  });
};

export const showError = (error) => ({
  filter: 'status',
  type: SHOW_ERROR,
  payload: error,
});

export const showInfo = (info) => ({
  filter: 'status',
  type: SHOW_INFO,
  payload: info,
});

export const showPrompt = (params) => ({
  filter: 'status',
  type: SHOW_PROMPT,
  payload: params,
});

export const showPromptPromise = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      filter: 'status',
      type: SHOW_PROMPT,
      payload: { ...params, cb: resolve },
    });
  });
};

export const showConfirm = (params) => ({
  filter: 'status',
  type: SHOW_CONFIRM,
  payload: params,
});

export const showConfirmPromise = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      filter: 'status',
      type: SHOW_CONFIRM,
      payload: { ...params, cb: resolve },
    });
  });
};

export const toggleHelp = () => ({
  filter: 'status',
  type: TOGGLE_HELP,
});

export const setHelpCoords = (e, draggableEvent) => {
  return {
    filter: 'status',
    type: SET_HELP_COORDS,
    payload: {
      x: draggableEvent.x,
      y: draggableEvent.y,
    },
  };
};

export const setAspectRatio = (aspectRatio) => {
  // const body = document.querySelector("body");

  // if (aspectRatio >= 1.25) {
  //   body.classList.add("landscape");
  // } else {
  //   body.classList.remove("landscape");
  // }

  return {
    filter: 'status',
    type: SET_ASPECT_RATIO,
    payload: aspectRatio,
  };
};
