import React from "react";
import _ from "lodash";
import { AudioString } from "components/flink-components";

export default (strings, locale) => (
  stringNumber,
  defaultText,
  isTooltip,
  forHelp,
  localeOverride,
  isAudio,
) => {
  const search = _.find(strings, { stringNumber: +stringNumber });
  const string = _.get(search?.data, localeOverride || locale);

  if (!string) {
    return defaultText || "Translate not found";
  }

  if (isAudio && string.stringAudio) {
    return <AudioString string={string} locale={localeOverride || locale} />;
  }

  if (isTooltip) {
    return string.stringValue;
  }

  if (forHelp) {
    return string.prettyValue || string.stringValue;
  }

  return string.prettyValue ? (
    <span dangerouslySetInnerHTML={{ __html: string.prettyValue }} />
  ) : (
    string.stringValue
  );
};
