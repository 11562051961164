import lamejs from 'lamejs';

export default async wavBlob => {
  const audioData = await new Response(wavBlob).arrayBuffer();

  const wav = lamejs.WavHeader.readHeader(new DataView(audioData));
  console.log(wav);
  const samples = new Int16Array(audioData, wav.dataOffset, wav.dataLen / 2);

  const mp3Blob = encodeMono(wav.channels, wav.sampleRate, samples);

  return mp3Blob;
};

function encodeMono(channels, sampleRate, samples) {
  const buffer = [];
  var mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 64);
  let remaining = samples.length;
  var maxSamples = 1152;

  for (var i = 0; remaining >= maxSamples; i += maxSamples) {
    const mono = samples.subarray(i, i + maxSamples);
    const mp3buf = mp3enc.encodeBuffer(mono);

    if (mp3buf.length > 0) {
      buffer.push(new Int8Array(mp3buf));
    }
    remaining -= maxSamples;
  }

  const d = mp3enc.flush();

  if (d.length > 0) {
    buffer.push(new Int8Array(d));
  }

  const blob = new Blob(buffer, { type: 'audio/mp3' });
  return blob;
}
