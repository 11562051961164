import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import _ from 'lodash';

import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

class ScopeDataColumn extends Component {
  addDataItem = () => {
    const { data, categoryName, handleSelect } = this.props;
    const newItem = { id: shortId.generate(), title: 'Title' };

    this.changeItems([...data, newItem]);

    handleSelect(categoryName, newItem.id);
    this.itemTitleField.current.focus();
  };

  changeItemTitle = e => {
    const { data, selected } = this.props;

    this.changeItems(
      data.map(item =>
        item.id === selected ? { ...item, title: e.target.value } : item
      )
    );
  };

  changeItems = items => {
    const { categoryName, changeDataHandler } = this.props;
    changeDataHandler(categoryName, items);
  };

  moveItem = direction => {
    const { selected, data } = this.props;
    const selectedIndex = _.findIndex(data, { id: selected });
    if (selectedIndex === -1) return;

    const newData = [...data];
    const itemToMove = data[selectedIndex];

    if (direction === 'up') {
      if (selectedIndex - 1 < 0) return;

      newData[selectedIndex - 1] = itemToMove;
      newData[selectedIndex] = data[selectedIndex - 1];
    }

    if (direction === 'down') {
      if (selectedIndex + 1 >= data.length) return;

      newData[selectedIndex + 1] = itemToMove;
      newData[selectedIndex] = data[selectedIndex + 1];
    }

    this.changeItems(newData);
  };

  itemTitleField = React.createRef();

  render() {
    const {
      title,
      classes,
      data,
      selected,
      disabled,
      handleSelect,
      categoryName,
      deleteHandler,
      changeTitleHandler
    } = this.props;

    const selectedItem = _.find(data, { id: selected });

    return (
      <div className={classes.column}>
        <TextField
          label="Title"
          value={title}
          onChange={e => changeTitleHandler(categoryName, e.target.value)}
          margin="normal"
          variant="outlined"
        />

        <List className={classes.list}>
          {!disabled &&
            data.map(item => (
              <ListItem
                key={item.id}
                classes={{ selected: classes.selectedItem }}
                dense
                button
                selected={item.id === selected}
                onClick={() => handleSelect(categoryName, item.id)}
              >
                {item.title}
              </ListItem>
            ))}
        </List>

        <TextField
          inputRef={this.itemTitleField}
          margin="normal"
          variant="outlined"
          disabled={!selected}
          value={(selectedItem && selectedItem.title) || ''}
          onChange={this.changeItemTitle}
        />

        <div className={classes.controlBtns}>
          <Fab
            size="small"
            className={classes.addItemBtn}
            disabled={!selected}
            onClick={this.moveItem.bind(null, 'up')}
          >
            <ArrowUpwardIcon />
          </Fab>
          <Fab
            size="small"
            className={classes.addItemBtn}
            disabled={!selected}
            onClick={this.moveItem.bind(null, 'down')}
          >
            <ArrowDownwardIcon />
          </Fab>
          <Fab
            color="primary"
            size="small"
            onClick={this.addDataItem}
            className={classes.addItemBtn}
            disabled={disabled}
          >
            <AddIcon />
          </Fab>
          <Fab
            size="small"
            className={classes.deleteItemBtn}
            disabled={!selected}
            onClick={deleteHandler.bind(null, categoryName)}
          >
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  }
}

ScopeDataColumn.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  changeDataHandler: PropTypes.func.isRequired
};

export default ScopeDataColumn;
