import {
  ADD_ACTIVITY,
  EDIT_ACTIVITY,
  DELETE_ACTIVITY,
  UNDO_DELETE_ACTIVITY,
  REMOVE_FROM_EDIT_LIST,
  CLEAR_ACTIVITIES_DELETE_LIST,
  SET_ACTIVE_ACTIVITY,
  SET_ACTIVITIES_UNSAVED_CHANGES,
  SET_MY_EDIT_ACTIVITIES_LIST
} from 'actions/types';

const INITIAL_STATE = {
  editActivitiesList: null,
  activitiesToDelete: [],
  activeActivity: null,
  hasUnsavedChanges: false
};

const filter = 'flinkMake/editList';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case ADD_ACTIVITY:
      return {
        ...state,
        editActivitiesList: [...state.editActivitiesList, action.payload]
      };
    case DELETE_ACTIVITY:
      return {
        ...state,
        editActivitiesList: state.editActivitiesList.filter(
          activity => activity._id !== action.payload._id
        ),
        activitiesToDelete: [...state.activitiesToDelete, action.payload]
      };
    case UNDO_DELETE_ACTIVITY:
      const activityToRestore = state.activitiesToDelete.slice(-1)[0];

      if (!activityToRestore) return state;

      return {
        ...state,
        activitiesToDelete: state.activitiesToDelete.slice(0, -1),
        editActivitiesList: [...state.editActivitiesList, activityToRestore]
      };
    case CLEAR_ACTIVITIES_DELETE_LIST:
      return {
        ...state,
        activitiesToDelete: []
      };
    case SET_ACTIVE_ACTIVITY:
      return {
        ...state,
        activeActivity: action.payload
      };
    case EDIT_ACTIVITY:
      const { editActivitiesList, activeActivity } = state;

      const newActiveActivity =
        activeActivity && activeActivity._id === action.payload._id
          ? action.payload
          : activeActivity;

      return {
        ...state,
        activeActivity: newActiveActivity,
        editActivitiesList:
          editActivitiesList &&
          editActivitiesList.map(activity =>
            activity._id === action.payload._id ? action.payload : activity
          )
      };
    case SET_MY_EDIT_ACTIVITIES_LIST:
      return {
        ...state,
        activeActivity: null,
        editActivitiesList: action.payload
      };
    case REMOVE_FROM_EDIT_LIST:
      return {
        ...state,
        editActivitiesList: state.editActivitiesList.filter(
          activity => !action.payload.includes(activity._id)
        )
      };
    case SET_ACTIVITIES_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.payload
      };
    default:
      return state;
  }
}
