export default scope => {
  if (!scope.name) {
    return false;
  }

  if (!scope.grades.length && !scope.levels.length) {
    return false;
  }

  if (!scope.firstCategory.items.length) {
    return false;
  }

  if (scope.grades.length && scope.levels.length) {
    if (scope.levels.length !== scope.assignedLevels.length) {
      return false;
    }
  }

  return true;
};
