import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { PlayAudioButton } from 'components/flink-play';
import classes from './Item.module.scss';
import { s3bucketPublicURL } from 'config';

const Item = ({ data, style = {}, contentFolder, className }) => {
  const { text, audio, audioPath } = data;
  const contentPath = audioPath || `${s3bucketPublicURL}/${contentFolder}/gamedata/`;
  const textClasses = classnames(classes.textValue, {
    [classes.medium]: text?.length >= 50 && text?.length < 100,
    [classes.long]: text?.length >= 100,
  });

  return (
    <div
      className={classnames(classes.item + ' ' + className, {
        [classes.withAudio]: !!audio
      })}
      style={style}
    >
      {audio && (
        <PlayAudioButton
          url={contentPath + audio}
          className={classes.playAudioBtnSmall}
        />
      )}

      {text ? <span className={textClasses}>{text}</span> : null}
    </div>
  );
};

Item.propTypes = {
  text: PropTypes.string,
  contentFolder: PropTypes.string.isRequired
};

const mapStateToProps = ({ game }) => ({
  contentFolder: game.currentActivity.activity.contentFolder
});

export default connect(mapStateToProps)(Item);
