import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import classnames from 'classnames';

import DraggableItem from '../../DraggableItem/DraggableItem';
import itemType from '../../draggable-item-type';
import classes from './ItemSlot.module.scss';

const slotTarget = {
  drop(props, monitor) {
    const { slotIdx } = props;

    return { slotIdx, isSlot: true };
  },
  canDrop(props) {
    return !props.item || !props.item.fixed;
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const ItemSlot = ({
  isResolved,
  item,
  connectDropTarget,
  isOver,
  slotRef,
  onDropHandler,
  joinOnResolve,
  first,
  last,
  className,
}) => {
  const canDrop = !item || !item.fixed;

  return connectDropTarget(
    <div
      ref={slotRef}
      className={classnames(className, {
        [classes.canDrop]: isOver && canDrop,
        [classes.join]: joinOnResolve && isResolved,
        [classes.joinMiddle]: joinOnResolve && isResolved && !last && !first,
        [classes.joinFirst]: joinOnResolve && isResolved && first,
        [classes.joinLast]: joinOnResolve && isResolved && last,
        [classes.fixed]: item && item.fixed,
        [classes.hidden]: item && item.hidden,
      })}
    >
      {item && <DraggableItem onDropHandler={onDropHandler} itemData={item} />}
    </div>
  );
};

ItemSlot.propTypes = {
  item: PropTypes.object,
};

export default DropTarget(itemType, slotTarget, collect)(ItemSlot);
