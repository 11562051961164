import React from "react";
import PropTypes from "prop-types";

import { WhiteBox } from "components/flink-play";
import classes from "./InstructionsTextBlock.module.scss";

const InstructionsTextBlock = ({
  text,
  textStyle,
  className,
  wrapperStyle
}) => {
  if (!text) return null;

  return (
    <WhiteBox outerClass={classes.wrapper} outerStyle={wrapperStyle}>
      <p className={classes.text + " " + className} style={textStyle}>
        {text}
      </p>
    </WhiteBox>
  );
};

InstructionsTextBlock.propTypes = {
  text: PropTypes.string,
  textStyle: PropTypes.object,
  className: PropTypes.string
};

export default InstructionsTextBlock;
