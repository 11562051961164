import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withData } from 'components/hocs';
import classes from './AssignProductsField.module.scss';

const changeProductsAssigned = (
  value,
  type,
  currentProduct,
  AssignedProducts,
  onChange
) => {
  const purchasedProduct = _.find(AssignedProducts, {
    productId: currentProduct._id
  }) || { productId: currentProduct._id };

  const changedPurchasedProduct = { ...purchasedProduct, [type]: value };

  const changedAssignedProducts = _.reject(AssignedProducts, {
    productId: currentProduct._id
  });

  const isProductHavePlans = changedPurchasedProduct.plans;

  if (isProductHavePlans) {
    changedAssignedProducts.push(changedPurchasedProduct);
  }

  onChange(changedAssignedProducts);
};

const AssignProductsField = ({
  products,
  value,
  onChange,
  canBeAssigned,
  alreadyInUse = {},
  disabled,
  dynamic,
  id
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let initialValue = useMemo(() => [...value], [id]);
  initialValue = dynamic ? value : initialValue;

  return (
    <div>
      {_.map(canBeAssigned, (productPlans, productId) => {
        const product = _.find(products, { _id: productId });

        if (!product) return null;

        const assignedProduct = _.find(value, { productId });

        const initialAssignedProduct =
          _.find(initialValue, { productId }) || {};

        const plansAssigned = (assignedProduct && assignedProduct.plans) || '';

        // const addonsAssigned =
        //  (assignedProduct && assignedProduct.addons) || '';

        const maxPlansToBeAssigned =
          (productPlans.plans || 0) + (initialAssignedProduct.plans || 0);
        // const maxAddonsToBeAssigned =
        //  (productPlans.addons || 0) + (initialAssignedProduct.addons || 0);

        const minPlansToBeAssigned =
          (alreadyInUse[productId] && alreadyInUse[productId].plans) || 0;
        // const minAddonsToBeAssigned =
        // (alreadyInUse[productId] && alreadyInUse[productId].addons) || 0;

        return (
          <div className={classes.wrapper} key={product._id}>
            <b className={classes.productName}>{product.name}</b>

            <div className={classes.planColumn}>
              <div className={classes.fieldGroup}>
                <label>Plans Assigned:</label>
                <input
                  type="number"
                  min={minPlansToBeAssigned}
                  max={maxPlansToBeAssigned}
                  disabled={disabled}
                  value={plansAssigned}
                  onChange={e => {
                    let newVal = +e.target.value;

                    // Dont let to asign more than master organization has available
                    if (newVal > maxPlansToBeAssigned) {
                      alert(
                        'You cant assign more than master organization has not assigned'
                      );
                      newVal = maxPlansToBeAssigned;
                    }

                    // Dont let to asign less than organization already uses
                    if (newVal < minPlansToBeAssigned) {
                      alert(
                        'You cant assign less than organization already uses'
                      );
                      newVal = minPlansToBeAssigned;
                    }

                    if (newVal < 0) newVal = 0;

                    changeProductsAssigned(
                      newVal,
                      'plans',
                      product,
                      value,
                      onChange
                    );
                  }}
                />
              </div>
              <div className={classes.fieldGroup}>
                <label>Plans In Use:</label>
                <input disabled type="number" value={minPlansToBeAssigned} />
              </div>
            </div>

            {/* {!!(
              addonsAssigned ||
              minAddonsToBeAssigned ||
              maxAddonsToBeAssigned
            ) && (
              <div className={classes.planColumn}>
                <div className={classes.fieldGroup}>
                  <label>Add-ons Assigned:</label>
                  <input
                    type="number"
                    min={minAddonsToBeAssigned}
                    max={maxAddonsToBeAssigned}
                    disabled={disabled}
                    value={addonsAssigned}
                    onChange={e => {
                      let newVal = +e.target.value;

                      // Dont let to asign more than master organization has available
                      if (newVal > maxAddonsToBeAssigned) {
                        alert(
                          'You cant assign more than upper organization has not assigned'
                        );
                        newVal = maxAddonsToBeAssigned;
                      }

                      // Dont let to asign less than organization already uses
                      if (newVal < minAddonsToBeAssigned) {
                        alert(
                          'You cant assign less than organization already uses'
                        );
                        newVal = minAddonsToBeAssigned;
                      }

                      if (newVal < 0) newVal = 0;

                      changeProductsAssigned(
                        newVal,
                        'addons',
                        product,
                        value,
                        onChange
                      );
                    }}
                  />
                </div>
                <div className={classes.fieldGroup}>
                  <label>Add-ons In Use:</label>
                  <input disabled type="number" value={minAddonsToBeAssigned} />
                </div>
              </div> 
            )} */}
          </div>
        );
      })}
    </div>
  );
};

AssignProductsField.defaultProps = {
  products: [],
  value: [],
  available: []
};

AssignProductsField.propTypes = {
  products: PropTypes.array,
  value: PropTypes.array,
  available: PropTypes.array
};

export default withData(['products'])(AssignProductsField);
