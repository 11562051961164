import { take } from "lodash";

export const defaultData = {
  images: [],
  words: [],
  guides: [],
  starters: [],
  reading: "",
  multiLocaleGuides: null
};

export const GUIDE_HEIGHT = 524;
export const GUIDE_WIDTH = 280;

export const EDITOR_HEIGHT = 400;
export const EDITOR_WIDTH = 645;

export const validateWords = (words, templateDBrecord) => {
  return { success: true, filteredWords: words };
};

export const generateActivityFromWords = ({ words }) => {
  const textWords = take(
    words.map(w => w.word),
    12
  );

  const gameData = { ...defaultData, words: textWords };
  return { gameData };
};
