import React from 'react';
import PropTypes from 'prop-types';

import { WhiteBox } from 'components/flink-play';
import classes from './ProblemDefinition.module.scss';

const ProblemDefinition = ({ text, textStyle = {} }) => {
  if (!text) return null;

  return (
    <div className={classes.positionedBox}>
      <WhiteBox outerClass={classes.wrapper}>
        <p className={classes.text} style={textStyle}>
          {text}
        </p>
      </WhiteBox>
    </div>
  );
};

ProblemDefinition.propTypes = {
  text: PropTypes.string,
};

export default ProblemDefinition;
