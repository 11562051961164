import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DraggableModal } from 'components/flink-components';
import { VideoPlayer } from 'components/common';
import { filesURL } from 'config';
import {
  showParentAdvice,
  setParentAdviceUrl,
  setParentAdviceCoords,
} from 'actions/flinkPlayActions';
import { showGameParentAdvice, showGameMultiplayerAdvice } from 'actions/gameActions';
import { stopAudio } from 'actions/audioActions';

import classes from './ParentAdvice.module.scss';
// import { showError } from 'actions/statusActions';

class ParentAdvice extends Component {
  state = {
    nextLocale: null,
    locale: null,
  };

  componentDidMount() {
    this.setUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      // parentAdviceUrl,
      // isMenuAdviceOpened,
      isGameAdviceOpened,
      isGameMultiplayerAdviceOpened,
      currentActivity,
      currentMenu,
      locale,
      showSettings,
      familyReportsOpened,
      parentAdvicesEnabled,
    } = this.props;

    // if parent advice url is changed and locale is the same then
    // "screen" was changed and need to close dialog
    // if (
    //   prevProps.parentAdviceUrl &&
    //   parentAdviceUrl &&
    //   prevProps.parentAdviceUrl !== parentAdviceUrl &&
    //   prevProps.locale === locale &&
    //   prevState.locale === this.state.locale
    // ) {
    //   console.log(1)
    //   this.closeHandler();
    // }

    if (
      prevState.locale !== this.state.locale ||
      prevProps.locale !== locale ||
      prevProps.showSettings !== showSettings ||
      prevProps.familyReportsOpened !== familyReportsOpened ||
      // prevProps.isMenuAdviceOpened !== isMenuAdviceOpened ||
      prevProps.isGameAdviceOpened !== isGameAdviceOpened ||
      prevProps.isGameMultiplayerAdviceOpened !== isGameMultiplayerAdviceOpened ||
      prevProps.currentMenu !== currentMenu ||
      prevProps.currentActivity !== currentActivity ||
      prevProps.parentAdvicesEnabled !== parentAdvicesEnabled
    ) {
      this.setUrl();
    }
  }

  setUrl = () => {
    const {
      currentActivity,
      currentMenu,
      showSettings,
      configParentVideos,
      familyReportsOpened,
      parentAdvicesEnabled,
      isGameMultiplayerAdviceOpened,
    } = this.props;

    // Check if multiplayer advice is opened
    if (isGameMultiplayerAdviceOpened) {
      // Get settings multi-player video
      const videos = (configParentVideos && configParentVideos.multiplayer) || {};
      return this.setVideos(videos);
    }


    if (!parentAdvicesEnabled) {
      return this.resetHandler();
    }

    // Check if current settings is opened
    if (showSettings) {
      // Get settings parent video
      const videos = (configParentVideos && configParentVideos.settings) || {};
      this.setVideos(videos);
    }

    // Check if family reports is opened
    else if (familyReportsOpened) {
      // Get settings parent video
      const videos =
        (configParentVideos && configParentVideos.familyReports) || {};
      this.setVideos(videos);
    }

    // Check if activity is opened
    else if (currentActivity) {
      const { parentVideos } = currentActivity.template;
      const { parentVideos: gameParentVideos } = currentActivity.data.options;

      this.setVideos(gameParentVideos || parentVideos || {});
    }

    // Check if menu is opened
    else if (currentMenu) {
      this.setVideos(currentMenu.parentVideos || {});
    }
  };

  setVideos = (videos) => {
    const {
      locales,
      parentAdviceUrl,
      setParentAdviceUrl,
      parentAdvicesLocales,
    } = this.props;

    const locale = this.state.locale || this.props.locale;
    const localeCode = locale.code;
    const availableLocales = [];

    const solutionHasSelectedLocalesForParentAdvice = !!(
      parentAdvicesLocales && parentAdvicesLocales.length
    );

    Object.keys(videos).forEach((key) => {
      const value = videos[key];
      if (!value) return;

      const locale = locales.filter((l) => l.code === key)[0];

      if (solutionHasSelectedLocalesForParentAdvice) {
        if (parentAdvicesLocales.includes(locale.code)) {
          availableLocales.push(locale);
        }
      } else if (locale.code === this.props.locale.code) {
        availableLocales.push(locale);
      }
    });

    const parentVideo = videos[localeCode];

    if (!parentVideo) return this.resetHandler();

    let nextLocale;
    const idxOfLocale = _.findIndex(availableLocales, { code: localeCode });

    if (availableLocales.length > 1) {
      if (idxOfLocale === availableLocales.length - 1) {
        // last locale
        nextLocale = availableLocales[0];
      } else {
        nextLocale = availableLocales[idxOfLocale + 1];
      }
    }

    const url = `${filesURL}/Files/animations/ParentVideos/${locale.name}/${parentVideo}`;

    if (parentAdviceUrl !== url) {
      this.setState({ nextLocale });
      setParentAdviceUrl(url);
    }
  };

  changeLocale = () => {
    this.setState({ locale: this.state.nextLocale });
  };

  errorHandler = () => {
    // this.closeHandler();
  };

  onStartPlay = () => {
    stopAudio();
  };

  resetHandler = () => this.closeHandler(true);

  closeHandler = (resetUrl = false) => {
    const {
      parentAdviceUrl,
      isGameAdviceOpened,
      isGameMultiplayerAdviceOpened,
      isMenuAdviceOpened,
      setParentAdviceUrl,
      showParentAdvice,
      showGameParentAdvice,
      showGameMultiplayerAdvice,
    } = this.props;

    parentAdviceUrl && resetUrl && setParentAdviceUrl('');
    isMenuAdviceOpened && showParentAdvice(null);
    isGameAdviceOpened && showGameParentAdvice(null);
    isGameMultiplayerAdviceOpened && showGameMultiplayerAdvice(null);

    if (resetUrl) {
      this.setState({ locale: null, nextLocale: null });
    }
  };

  debouncedSetParentAdvice = _.debounce(this.props.setParentAdviceCoords, 100);

  render() {
    const {
      parentAdviceUrl,
      isMenuAdviceOpened,
      isGameAdviceOpened,
      isGameMultiplayerAdviceOpened,
      parentAdvicesEnabled,
      parentAdviceCoords,
    } = this.props;

    const { nextLocale } = this.state;

    if (!parentAdvicesEnabled && !isGameMultiplayerAdviceOpened) return null;
    if (!parentAdviceUrl) return null;

    if (!isMenuAdviceOpened && !isGameAdviceOpened && !isGameMultiplayerAdviceOpened) return null;

    return (
      <DraggableModal
        show
        noGutters
        resizable
        size={{ width: '50vmin' }}
        lockAspectRatio
        onClose={() => this.closeHandler()}
        className={classes.wrapper}
        onDrag={(...args) => this.debouncedSetParentAdvice(...args)}
        position={parentAdviceCoords}
      >
        <VideoPlayer
          // ref={this.videoRef}
          playing
          controls
          width="100%"
          height="100%"
          url={parentAdviceUrl}
          onPlay={this.onStartPlay}
          // onReady={() => setLoaded(true)}
          onError={this.errorHandler}
          // onEnded={callback}
        />

        {nextLocale && (
          <button
            className={classes.changeLangButton}
            onClick={this.changeLocale}
          >
            {nextLocale.displayName}
          </button>
        )}
      </DraggableModal>
    );
  }
}

ParentAdvice.propTypes = {
  locales: PropTypes.array.isRequired,
  isMenuAdviceOpened: PropTypes.bool,
  locale: PropTypes.object,
  currentMenu: PropTypes.object,
  showParentAdvice: PropTypes.func.isRequired,
  showGameParentAdvice: PropTypes.func.isRequired,
  setParentAdviceUrl: PropTypes.func.isRequired,
  parentAdviceUrl: PropTypes.string,
  showSettings: PropTypes.bool,
  configParentVideos: PropTypes.object,
  familyReportsOpened: PropTypes.bool,
  parentAdvicesLocales: PropTypes.array,
  // showError: PropTypes.func.isRequired,
};

const mapStateToProps = ({ flinkPlay, game, status, common }) => ({
  locales: common.locales,
  configParentVideos: common.config.parentVideos,
  showSettings: flinkPlay.showSettings,
  parentAdviceCoords: flinkPlay.parentAdviceCoords,
  familyReportsOpened: flinkPlay.familyReportsOpened,
  parentAdviceUrl: flinkPlay.parentAdviceUrl,
  currentActivity: game.currentActivity,
  currentMenu: flinkPlay.currentMenu,
  locale: flinkPlay.solutionLocale || status.lang,
  isMenuAdviceOpened: flinkPlay.parentAdvice,
  isGameAdviceOpened: game.gameParentAdvice,
  isGameMultiplayerAdviceOpened: game.gameMultiplayerAdvice,
  parentAdvicesEnabled:
    (flinkPlay.solution && flinkPlay.solution.parentAdvicesEnabled) ||
    game.isPreview,
  parentAdvicesLocales:
    (flinkPlay.solution && flinkPlay.solution.parentAdvicesLocales) || [],
});

export default connect(mapStateToProps, {
  showParentAdvice,
  showGameParentAdvice,
  showGameMultiplayerAdvice,
  setParentAdviceUrl,
  setParentAdviceCoords,
  // showError
})(ParentAdvice);
