import React from 'react';
import Slider from 'react-slick';

import classnames from 'classnames';
import { imagesURL } from 'config';
import 'slick-carousel/slick/slick.css';
import classes from './SettingsSlider.module.scss';

const prevButtonUrl = `${imagesURL}/SettingsDialog/dialogbox/LeftArrow.png`;
const nextButtonUrl = `${imagesURL}/SettingsDialog/dialogbox/RightArrow.png`;

const PrevButton = ({ onClick, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(classes.prevArrow, {
        [classes.hidden]: className.indexOf('slick-disabled') !== -1,
      })}
    >
      <img src={prevButtonUrl} alt="" />
    </button>
  );
};

const NextButton = ({ onClick, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(classes.nextArrow, {
        [classes.hidden]: className.indexOf('slick-disabled') !== -1,
      })}
    >
      <img src={nextButtonUrl} alt="" />
    </button>
  );
};

const getSettings = (slidesToShow) => ({
  dots: false,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  infinite: false,
  speed: 600,
  slidesToShow: slidesToShow,
  slidesToScroll: slidesToShow,
  draggable: false,
  // responsive: [
  //   {
  //     breakpoint: 767,
  //     settings: {
  //       slidesToShow: 4,
  //       slidesToScroll: 4
  //     }
  //   },
  //   {
  //     breakpoint: 575,
  //     settings: {
  //       slidesToShow: 3,
  //       slidesToScroll: 3
  //     }
  //   }
  // ]
});

const SettingsSlider = ({ children, slidesToShow }) => {
  return (
    <div className={classes.wrapper}>
      <Slider {...getSettings(slidesToShow)}>{children}</Slider>
    </div>
  );
};

SettingsSlider.propTypes = {};

export default SettingsSlider;
