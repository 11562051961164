import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";

import { WhiteBox } from "components/flink-play";
import classes from "./ProblemsProgress.module.scss";

const ProblemsProgress = ({ problemsNumber, problemsLeft }) => {
  const answered =
    problemsLeft !== undefined ? problemsNumber - problemsLeft : 0;

  const formattedAnswered = _.isFinite(answered)
    ? _.times(answered, _.constant(true))
    : [];

  return (
    <WhiteBox outerClass={classes.wrapper} innerClass={classes.inner}>
      <ul className={classes.list}>
        {_.times(problemsNumber, Number).map(num => (
          <li
            key={num}
            className={classnames({
              [classes["filled"]]: formattedAnswered[num]
            })}
          />
        ))}
      </ul>
    </WhiteBox>
  );
};

ProblemsProgress.propTypes = {
  problemsNumber: PropTypes.number.isRequired,
  problemsLeft: PropTypes.number
};

export default ProblemsProgress;
