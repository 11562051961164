let collator = {};

try {
  collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
} catch (err) {
  collator.compare = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };
}

// Example of usage
// [2,5,1].sort(getSorting('desc')) -> [5,2,1]
// [2,5,1].sort(getSorting()) -> [1,2,5]
// [{title: 'Mat'}, {title: 'Andrew'}].sort(getSorting('asc', 'title'))
//     -> [{title: 'Andrew'}, {title: 'Mat'}]

export const desc = (a = '', b = '', orderBy) => {
  if (orderBy) {
    return collator.compare(b[orderBy], a[orderBy]);
  } else {
    return collator.compare(b, a);
  }
};

export const getSorting = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};

export default getSorting;
