import React, { useMemo } from 'react';
import classes from '../AnswersBlock.module.scss';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import { connect } from 'react-redux';

import { toggleAudio } from 'actions/audioActions';
import { imagesURL } from 'config';

const INITIAL_DELAY = 1000;

const getDelays = (num, speed) =>
  _.shuffle(
    _.range(INITIAL_DELAY, (speed / 2) * num + INITIAL_DELAY, speed / 2)
  );

const StopItAnswers = ({
  audio,
  useImages,
  longAnswers,
  question,
  isResolved,
  isMultiplayer,
  animationSpeed,
  animation,
  coloredAnswerBoxes,
  contentPath,
  colors,
  textStyle,
  clickHandler,
  withAudio,
  visibleAfterResolvedTime,
}) => {
  const answersDelays = useMemo(
    () => getDelays(question.answers.length, animationSpeed),
    [question, animationSpeed]
  );

  return (
    <div
      className={classnames({
        [classes.imagesAnswersBlock]: useImages,
        [classes.textAnswersBlock]: !useImages && !longAnswers,
        [classes.longAnswersBlock]: longAnswers,
        [classes.multiplayer]: isMultiplayer,
      })}
    >
      {question.answers.map((answer, index) => {
        const animationDuration = !isResolved
          ? animationSpeed
          : animationSpeed / 2;

        const animationDelay = !isResolved
          ? answersDelays[index]
          : answer.correct && !question.fillIn
          ? visibleAfterResolvedTime * 1000 - 1
          : 0;

        const answerPostition = {
          top: (index + 1) % 2 === 0 ? 0 : 'auto',
          bottom: (index + 1) % 2 === 0 ? 'auto' : 0,
          left:
            (100 / question.answers.length) * index +
            100 / (question.answers.length * 2) +
            '%',
        };

        return (
          <CSSTransition
            appear
            unmountOnExit
            mountOnEnter
            in={!isResolved}
            key={answer.id}
            timeout={animationDuration + animationDelay}
            classNames={{
              appearActive: animation.in,
              exitActive: animation.out,
            }}
          >
            {(state) => (
              <div
                className={classnames('animated', {
                  [classes.imageAnswerWrapper]: useImages,
                  [classes.textAnswerWrapper]: !useImages,
                })}
                style={{
                  ...answerPostition,
                  animationDuration: animationDuration / 1000 + 's',
                  animationDelay: animationDelay / 1000 + 's',
                }}
              >
                <button
                  className={classnames(classes.answerBtn, {
                    [classes.fadeIn]: state === 'entering',
                    [classes.fadeOut]: state === 'exiting',
                  })}
                  disabled={isResolved || state === 'entering'}
                  style={{
                    ...textStyle,
                    ...(coloredAnswerBoxes ? colors[index] : {}),
                  }}
                  onClick={clickHandler.bind(null, answer)}
                >
                  {useImages ? (
                    <img src={contentPath + answer.image} alt="" />
                  ) : (
                    answer.text
                  )}
                </button>

                {withAudio && answer.audio && (
                  <button
                    className={classnames(classes.playAudioBtn, {
                      [classes.fadeIn]: state === 'entering',
                      [classes.fadeOut]: state === 'exiting',
                    })}
                    disabled={isResolved || state === 'entering'}
                    onClick={() => toggleAudio(contentPath + answer.audio)}
                  >
                    <img
                      src={`${imagesURL}/Images/Audio/${
                        audio.audioSrc === contentPath + answer.audio &&
                        audio.isPlaying
                          ? 'audiostop'
                          : 'audio_icon'
                      }.png`}
                      alt=""
                    />
                  </button>
                )}
              </div>
            )}
          </CSSTransition>
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ audio }) => ({ audio });

export default connect(mapStateToProps)(StopItAnswers);
