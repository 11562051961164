import React from 'react';
import PropTypes from 'prop-types';

import { PreviewImage } from 'components/flink-components';
import { s3bucketPublicURL } from 'config';
import { IMAGE_BASE_WIDTH, IMAGE_BASE_HEIGHT } from 'consts/moving-answers-consts';

const ActivityImage = ({ closeHandler, activity, show }) => {
  const { activityImage, gameData } = activity.data;
  const { contentFolder } = activity.activity;

  if (!activityImage) {
    return null;
  }

  const imageSrc = `${s3bucketPublicURL}/${contentFolder}/gamedata/${activityImage}`;
  const dimensionDivider = gameData.activityImageSize === "small" ? 2 : 1;

  // preload image
  const preloadedImage = new Image();
  preloadedImage.src = imageSrc;

  return (
    <PreviewImage
      width="100%"
      show={!!(imageSrc && show)}
      closeHandler={closeHandler}
      imageSrc={imageSrc}
      default={{
        x: 100, 
        y: 150,
        width: IMAGE_BASE_WIDTH / dimensionDivider,
        height: IMAGE_BASE_HEIGHT / dimensionDivider,
      }}
      maxWidth={IMAGE_BASE_WIDTH}
      maxHeight={IMAGE_BASE_HEIGHT}
      zIndex={0}
    />
  );
};

ActivityImage.propTypes = {
  show: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  activity: PropTypes.shape({
    data: PropTypes.shape({
      activityImage: PropTypes.string
    }).isRequired
  }).isRequired
};

export default ActivityImage;
