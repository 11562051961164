import OrderIt from './OrderIt';
import convertOldActivity from './convertOldOrderIt';
import validateActivity from './validateOrderIt';

export default {
  Template: OrderIt,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'randomOrder',
    'questionsLimit',
    'showAnswer'
  ]
};
