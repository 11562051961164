import React, { Component } from 'react';
import styled, {css} from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { MaterialFaIcon } from 'utils';

const styles = theme => ({
  button: {
    marginLeft: theme.spacing()
  },
  leftIcon: {
    marginRight: theme.spacing()
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  iconSmall: {
    fontSize: 20
  },
  hidden: {
    display: 'none'
  }
});

export class UploadField extends Component {
  state = {
    selectedFile: null
  };

  fileSelectedHandler = e => {
    const file = e.target.files[0];

    this.setState({
      selectedFile: file || null
    });

    this.props.input.onChange(file)
  };

  render() {
    const {
      classes,
      label,
      fileExtensions,
      meta: { touched, error, dirty },
    } = this.props;

    return (
      <StyledInputGroup>
        <StyledLabel error={error} touched={touched} dirty={dirty}>
          {label}
        </StyledLabel>

        <UploadControlsWrapper>
          <input
            onChange={this.fileSelectedHandler}
            type="file"
            accept={fileExtensions || ""}
            id="contained-button-file"
            className={classes.hidden}
          />
          <StyledLabelWrapper htmlFor="contained-button-file">
            <StyledField>
              {this.state.selectedFile && (
                <span>{this.state.selectedFile.name}</span>
              )}
            </StyledField>
            <Button
              variant="contained"
              className={classes.button}
              component="span"
              size="small"
            >
              Browse
              <MaterialFaIcon
                icon="folder-open"
                className={classes.rightIcon}
              />
            </Button>
          </StyledLabelWrapper>
        </UploadControlsWrapper>

        {touched && error && <StyledError>{error}</StyledError>}
      </StyledInputGroup>
    );
  }
}

export default withStyles(styles)(UploadField);

const StyledInputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const UploadControlsWrapper = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
`;

const StyledError = styled.p`
  font-size: 1.2rem;
  margin-top: 2px;
  margin-bottom: 0;
  color: #df2a26;
`;

const StyledLabel = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 0;

  ${({ touched, error, dirty }) =>
    touched &&
    (error
      ? css`
          color: #df2a26;
        `
      : dirty &&
        css`
          color: green;
        `)};
`;

const StyledField = styled.div`
  width: 200px;
  padding: 0.6rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07);
`;

const StyledLabelWrapper = styled.label`
  display: flex;
  flex-grow: 1;
  min-width: 25rem;
  margin-bottom: 8px;
`;
