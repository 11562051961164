import shortid from 'shortid';

export const GRID_OPTIONS = ['3,3', '4,4', '5,5', '6,6'];

export const INSTRUCTION_TYPES = {
  AUDIO: 'AUDIO',
  TEXT: 'TEXT',
  AUDIO_AND_TEXT: 'AUDIO_AND_TEXT',
};

Object.freeze(INSTRUCTION_TYPES);

export const DIRECTIONS = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BETWEEN_HORIZONTAL: 'BETWEEN_HORIZONTAL',
  BETWEEN_VERTICAL: 'BETWEEN_VERTICAL',
};

Object.freeze(DIRECTIONS);

export const HELPERS = {
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  ARE: 'ARE',
  ARE_NOT: 'ARE_NOT',
  AND: 'AND',
  CORRECT_ANSWER: 'CORRECT_ANSWER',
  INCORRECT_ANSWER: 'INCORRECT_ANSWER',
  SHOW_ANSWER: 'SHOW_ANSWER',
  CLICK_ONE_PICTURE: 'CLICK_ONE_PICTURE',
  CLICK_SOME_PICTURES: 'CLICK_SOME_PICTURES',
  CLICK_ONE_SHAPE: 'CLICK_ONE_SHAPE',
  CLICK_SOME_SHAPES: 'CLICK_SOME_SHAPES',
  CLICK_ONE_LETTER: 'CLICK_ONE_LETTER',
  CLICK_SOME_LETTERS: 'CLICK_SOME_LETTERS',
};

Object.freeze(HELPERS);

export const ASSETS = {
  FARM: 'FARM',
  FOOD: 'FOOD',
  LETTERS: 'LETTERS',
  SHAPES: 'SHAPES',
};

Object.freeze(ASSETS);

export const MIN_QUESTION_NUMBER = 1;
export const MAX_QUESTION_NUMBER = 5;

export const REQUIRED_ANSWERS = {
  SINGLE: 'SINGLE',
  ALL: 'ALL',
};

export const validateProblem = (problem) => {
  return !!(problem.direction && problem.assetsType && problem.questionsNumber);
};

export const createProblem = () => ({
  id: shortid.generate(),
  gridSize: GRID_OPTIONS[0],
  direction: Object.keys(DIRECTIONS)[0],
  questionsNumber: MIN_QUESTION_NUMBER,
  highlightItems: false,
  showGrid: false,
  requiredAnswers: Object.keys(REQUIRED_ANSWERS)[0],
  assetsType: Object.keys(ASSETS)[0],
});

export const getContentFilesFromProblem = (problem) => {
  // No assets
  return [];
};
