import { Component } from "react";
import MediaRecorder from "audio-recorder-polyfill";
import PropTypes from "prop-types";

import mpegEncoder from "./mpeg-encoder";

window.MediaRecorder = MediaRecorder;

const getRecorder = () =>
  new Promise(async (resolve, reject) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });

      const recorder = new MediaRecorder(stream);
      let audioChunks = [];

      recorder.addEventListener("dataavailable", event => {
        audioChunks.push(event.data);
      });

      const start = () => {
        audioChunks = [];
        recorder.start();
      };

      const stop = () =>
        new Promise(resolve => {
          recorder.addEventListener(
            "stop",
            async () => {
              const wavBlob = new Blob(audioChunks, {
                type: audioChunks[0].type
              });

              console.log("wav -", Math.round(wavBlob.size / 1000) + "kb");

              const mpegBlob = await mpegEncoder(wavBlob);

              console.log("mp3 -", Math.round(mpegBlob.size / 1000) + "kb");

              const audioUrl = URL.createObjectURL(mpegBlob);

              resolve({ audioBlob: mpegBlob, audioUrl });
            },
            { once: true }
          );

          // Stop recording
          recorder.stop();
        });

      const destroy = () => {
        // Stop recording
        recorder.stop();
        // Remove “recording” icon from browser tab
        recorder.stream.getTracks().forEach(i => i.stop());
      };

      resolve({ start, stop, destroy });
    } catch (err) {
      reject(err);
    }
  });

export default class RecordAudio extends Component {
  state = {
    recorder: null
  };

  audio = new Audio();

  componentDidMount() {
    if (navigator.mediaDevices) {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        this.refreshHandler
      );
    } else {
      console.error(
        "Microphone cant be accessible, cause website should be on https"
      );
    }

    this.refreshHandler();
  }

  componentWillUnmount() {
    const { isPlaying, recorder } = this.state;

    if (isPlaying) this.stopPlayHandler();

    recorder && recorder.destroy();

    if (navigator.mediaDevices) {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        this.refreshHandler
      );
    }
  }

  resetHandler = isRecording => {
    this.setState({
      isRecording,
      audioBlob: null,
      audioUrl: null,
      isPlaying: false
    });
  };

  startRecordHandler = () => {
    try {
      this.state.recorder.start();
      this.resetHandler(true);
    } catch (err) {
      console.log(err);
      this.refreshHandler();
    }
  };

  stopRecordHandler = async () => {
    if (this.state.processing) return;

    const { onEndRecord } = this.props;
    this.setState({ processing: true });

    const { audioUrl, audioBlob } = await this.state.recorder.stop();

    onEndRecord && await onEndRecord(audioUrl, audioBlob);

    this.setState({
      audioBlob,
      audioUrl,
      processing: false,
      isRecording: false
    });
  };

  playHandler = (src) => {
    this.setState({ isPlaying: true });

    this.audio.setAttribute("src", src || this.state.audioUrl);
    this.audio.setAttribute("type", "audio/mp3");
    this.audio.load();
    this.audio.play();

    // this.audio.onloadedmetadata = () => {
    //   console.log("DURATION: ", this.audio.duration + "s");
    // };

    this.audio.onended = () => {
      this.setState({ isPlaying: false });
    };

    this.audio.onerror = event => {
      console.log(event);
      this.setState({ isPlaying: false });
    };
  };

  stopPlayHandler = () => {
    this.audio.currentTime = 0;
    this.audio.pause();
    this.setState({ isPlaying: false });
  };

  refreshHandler = () => {
    getRecorder()
      .then(recorder => this.setState({ recorder }))
      .catch(err => {
        console.log(err);
        this.setState({ recorder: null });
      });
  };

  render() {
    const props = {
      ...this.state,
      startRecordHandler: this.startRecordHandler,
      stopRecordHandler: this.stopRecordHandler,
      playHandler: this.playHandler,
      stopPlayHandler: this.stopPlayHandler,
      resetHandler: this.resetHandler
    };

    return this.props.children(props);
  }
}

RecordAudio.propTypes = {
  onEndRecord: PropTypes.func
};
