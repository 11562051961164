import React from 'react';
import styled from 'styled-components';

const SettingsButton = ({ color = 'primary', ...props }) => {
  return <StyledButton color={color} {...props} />;
};

export default SettingsButton;

const StyledButton = styled.button`
  background-color: ${({ color }) =>
    color === 'primary' ? '#972bc9' : '#fff'};
  color: ${({ color }) => (color === 'primary' ? '#fff' : '#972bc9')};
  border-radius: 1vmin;
  padding: 1vmin 2vmin;
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.8;
  transition: all 0.2s ease;
  min-width: 15vmin;
  font-size: 1.4vmin;
  border: 1px solid ${({ color }) => (color === 'primary' ? '#fff' : '#972bc9')};

  &:hover:not(:disabled) {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;
