import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { PlayAudioButton } from 'components/flink-play';
import classes from './Item.module.scss';
import { s3bucketPublicURL } from 'config';

const Item = ({ data, contentFolder, clickHandler, bigger }) => {
  const { text, image, audio, audioPath } = data;
  const contentPath = `${s3bucketPublicURL}/${contentFolder}/gamedata/`;
  const itemStyle = data.checked
    ? { borderColor: data.checkedBorderColor }
    : {};

  const imageFilename = image && (image.match(/^0+$/) ? '' : image);

  return (
    <div
      className={classnames(classes.itemMixed, {
        [classes.question]: bigger
      })}
      style={itemStyle}
    >
      <div
        className={classes.inner}
        onClick={() => clickHandler && clickHandler(data.id)}
      >
        {audio && (
          <PlayAudioButton
            url={(audioPath || contentPath) + audio}
            className={classnames({
              [classes.playAudioBtn]: !text && !imageFilename,
              [classes.playAudioBtnSmall]: text || imageFilename
            })}
            beforeHandler={e => {
              if (text || imageFilename) {
                e.stopPropagation();
              }
            }}
          />
        )}

        {text ? (
          <span
            className={classnames(classes.textValue, {
              [classes.textUpTo2]: text.length <= 2,
              [classes.textUpTo4]: text.length > 2 && text.length <= 4,
              [classes.textUpTo6]: text.length > 4 && text.length <= 6,
              [classes.textUpTo10]: text.length > 6 && text.length <= 10
            })}
          >
            {text}
          </span>
        ) : imageFilename ? (
          <img
            className={classes.itemImg}
            src={contentPath + imageFilename}
            alt=""
          />
        ) : null}
      </div>
    </div>
  );
};

Item.propTypes = {
  text: PropTypes.string,
  contentFolder: PropTypes.string.isRequired
};

const mapStateToProps = ({ game }) => ({
  contentFolder: game.currentActivity.activity.contentFolder
});

export default connect(mapStateToProps)(Item);
