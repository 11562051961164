import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchData, deleteData } from 'actions/adminActions';
import customFetchData from 'utils/customFetchData';

const withData = (
  dataToLoad = [],
  additionalRequests = []
) => WrappedComponent => {
  if (!Array.isArray(dataToLoad)) {
    throw new Error(
      'First prop passed to withData should be type of Array[String, String...]'
    );
  }

  if (!Array.isArray(additionalRequests)) {
    throw new Error(
      'Second prop passed to withData should be type of Array[String, String...]'
    );
  }

  class Wrapper extends Component {
    state = { additionalData: {} };

    componentDidMount() {
      const { fetchData, admin, customFetchData } = this.props;

      dataToLoad.forEach(data => {
        !admin[data] && fetchData(data);
      });

      additionalRequests.forEach(async ({ name, url }) => {
        const data = await customFetchData(url);
        this.setState(state => ({
          additionalData: { ...state.additionalData, [name]: data }
        }));
      });
    }

    render() {
      const { admin, isLoading, ...otherProps } = this.props;
      const { additionalData } = this.state;

      const isDataLoaded = dataToLoad.reduce(
        (accum, d) => accum && !!admin[d],
        true
      );

      const isAddDataLoaded = additionalRequests.reduce(
        (accum, d) => accum && !!additionalData[d.name],
        true
      );

      const data = {};

      if (isDataLoaded) {
        dataToLoad.forEach(d => (data[d] = admin[d]));
      }

      return isDataLoaded && isAddDataLoaded ? (
        <WrappedComponent {...otherProps} {...data} {...additionalData} />
      ) : isLoading ? (
        <p>Loading...</p>
      ) : (
        <p>No response from server</p>
      );
    }
  }

  const mapStateToProps = ({ admin, status }) => ({
    admin,
    isLoading: status.isLoading
  });


  Wrapper.propTypes = {
    dataToLoad: PropTypes.array,
    admin: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchData: PropTypes.func.isRequired,
    deleteData: PropTypes.func.isRequired,
    customFetchData: PropTypes.func.isRequired
  };

  return connect(
    mapStateToProps,
    {
      fetchData,
      customFetchData,
      deleteData
    }
  )(Wrapper);
};

export default withData;
