import React from 'react';
import { useSelector } from 'react-redux';

const SupportPhone = ({ className = '' }) => {
  const phone = useSelector((state) => state.common.config?.phone);
  const noSupportPhone = useSelector(
    (state) => state.flinkPlay.learningCenter?.config?.noSupportPhone
  );
  const translate = useSelector(
    (state) => state.flinkPlay?.solutionTranslate || state.status.translate
  );

  if (!phone || noSupportPhone) return null;

  return (
    <div className={className}>
      {translate(953, 'Support')}:{' '}
      <a style={{ fontFamily: 'var(--font-base)' }} href={`tel:${phone}`}>
        {phone}
      </a>
    </div>
  );
};

export default SupportPhone;
