import React from "react";
import PropTypes from "prop-types";

import classes from "./InstructionsBlock.module.scss";
import { ActivityAddAudio } from "components/flink-components";

const InstructionsBlock = ({
  maxCharacters = 200,
  withoutAudio,
  label,
  onTextChange,
  onAudioChange,
  textValue,
  audioValue,
  stringValue,
  pathValue,
  disabled,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.textareaWrapper}>
        {label && <label className={classes.label}>{label}</label>}
        <textarea
          onChange={e => onTextChange(e.target.value)}
          value={textValue || ""}
          className={classes.textarea}
          maxLength={maxCharacters}
          disabled={disabled}
        />
      </div>

      {!withoutAudio && (
        <ActivityAddAudio
          current={audioValue}
          path={pathValue}
          onChangeHandler={onAudioChange}
          associatedText={textValue}
          readOnly={disabled}
          useFileBucket={!!stringValue}
        />
      )}
    </div>
  );
};

InstructionsBlock.defaultProps = {
  withoutAudio: false
};

InstructionsBlock.propTypes = {
  textValue: PropTypes.string,
  audioValue: PropTypes.string,
  stringValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pathValue: PropTypes.string,
  onAudioChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InstructionsBlock;
