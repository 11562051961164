import { connect } from 'react-redux';
import { compose } from 'redux';
import { useMemo } from 'react';

import classnames from 'classnames';
import { withTranslate } from 'components/hocs';

import WhiteBox from '../WhiteBox/WhiteBox';

import PowerLevelControls from './PowerLevelControls/PowerLevelControls';
import HelpControls from './HelpControls/HelpControls';

import classes from './MultiplayerOverlay.module.scss';

const { format: formatNumber } = new Intl.NumberFormat('en-US');
const MultiplayerOverlay = (props) => {
    const { players, points, turningPlayerId } = props;
    const isChild = (player) => player.type === 'child';

    const translateToUse = useMemo(() => {
        return props.solutionTranslate || props.translate;
    }, [props.solutionTranslate, props.translate]);

    return (
        <div className={classes.wrapper}>
            {players.map(({ firstname, type, _id }) => (
            <WhiteBox outerClass={classes.outerClass} innerClass={classes.innerClass} key={_id}>
                <div className={classes.items}>
                    <div className={classnames(classes.playerName, {
                        [classes.currentTurnIndicator]: _id === turningPlayerId,
                    })}>
                        {firstname}
                    </div>
                    <small>
                        {formatNumber(points[_id] ?? 0)} {translateToUse(989, 'points')}
                    </small>
                    
                    {isChild({ type }) && <PowerLevelControls playerId={_id} />}
                </div>
            </WhiteBox>
            ))}
            <HelpControls />
        </div>
    )
};

const mapStateToProps = ({ game, flinkPlay }) => { 
    const { players, turningPlayerId, powerLevels, points } = game;
    const { solutionTranslate } = flinkPlay;

    return {
        players,
        turningPlayerId,
        powerLevels,
        points,
        solutionTranslate,
    };
};

export default compose(
    withTranslate,
    connect(mapStateToProps)
)(MultiplayerOverlay);
