import {
  SET_WORDLISTS,
  ADD_WORDLIST,
  EDIT_WORDLIST,
  DELETE_WORDLIST,
  UNDO_DELETE_WORDLIST,
  CLEAR_WORDLISTS_DELETE_LIST,
  REMOVE_FROM_MY_WORDLISTS
} from "actions/types";

const INITIAL_STATE = {
  list: null,
  wordlistsToDelete: []
};

const filter = "flinkMake/wordlists";

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_WORDLISTS:
      return {
        ...state,
        list: action.payload
      };
    case ADD_WORDLIST:
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    case EDIT_WORDLIST:
      const { list } = state;

      return {
        ...state,
        list: list.map(w => (w._id === action.payload._id ? action.payload : w))
      };
    case DELETE_WORDLIST:
      return {
        ...state,
        list: state.list.filter(w => w._id !== action.payload._id),
        wordlistsToDelete: [...state.wordlistsToDelete, action.payload]
      };
    case UNDO_DELETE_WORDLIST:
      const wordlistToRestore = state.wordlistsToDelete.slice(-1)[0];

      if (!wordlistToRestore) return state;

      return {
        ...state,
        wordlistsToDelete: state.wordlistsToDelete.slice(0, -1),
        list: [...state.list, wordlistToRestore]
      };
    case CLEAR_WORDLISTS_DELETE_LIST:
      return {
        ...state,
        wordlistsToDelete: []
      };
    case REMOVE_FROM_MY_WORDLISTS:
      return {
        ...state,
        list: state.list.map(wordlist =>
          action.payload.includes(wordlist._id)
            ? { ...wordlist, inEditList: "" }
            : wordlist
        )
      };
    default:
      return state;
  }
}
