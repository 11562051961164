import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GameModal from '../GameModal/GameModal';
import {
  playCorrectSound,
  playIncorrectSound,
  playAudio,
  stopAudio,
  toggleAudio,
} from 'actions/audioActions';
import { s3bucketPublicURL, imagesURL } from 'config';
import { closeFeedback } from 'actions/gameActions';
import { setFeedbackCoords } from 'actions/flinkPlayActions';
import { debounce } from 'lodash';

const GameFeedback = () => {
  const translate = useSelector(
    ({ flinkPlay, status }) => flinkPlay.solutionTranslate || status.translate
  );
  const feedback = useSelector(({ game }) => game.feedback);
  const feedbackCoords = useSelector(
    ({ flinkPlay }) => flinkPlay.feedbackCoords
  );
  const contentFolder = useSelector(
    ({ game }) => game.currentActivity.activity.contentFolder
  );
  const audioState = useSelector(({ audio }) => audio);
  const dispatch = useDispatch();

  const contentPath = `${s3bucketPublicURL}/${contentFolder}/gamedata/`;
  const isCustomFeedback = feedback && !!(feedback.text || feedback.audio);
  const hasText = !!(feedback && feedback.text);

  let feedbackAudioSrc;
  let audioIcon;
  if (feedback && feedback.audio) {
    feedbackAudioSrc = contentPath + feedback.audio;
    audioIcon = `${imagesURL}/Images/Audio/${
      audioState.audioSrc === feedbackAudioSrc && audioState.isPlaying
        ? 'audiostop'
        : 'audio_icon1'
    }.png`;
  }

  const closeHandler = useCallback(() => {
    if (!feedback) return;
    dispatch(closeFeedback());
    if (feedbackAudioSrc) {
      stopAudio(feedbackAudioSrc);
    }
    feedback.onClose && feedback.onClose();
  }, [dispatch, feedback, feedbackAudioSrc]);

  useEffect(() => {
    if (!feedback) {
      return;
    }

    if (!isCustomFeedback) {
      dispatch(feedback.isCorrect ? playCorrectSound() : playIncorrectSound());
      if (feedback.onClose) {
        document.addEventListener('audioEnded', feedback.onClose, {
          once: true,
        });
      }
      return;
    }
    if (feedback.audio) {
      playAudio(contentPath + feedback.audio);
      document.addEventListener('audioEnded', closeHandler, { once: true });
    }

    return () => {
      feedback.onClose &&
        document.removeEventListener('audioEnded', feedback.onClose);
    };
  }, [
    feedback,
    dispatch,
    isCustomFeedback,
    contentFolder,
    hasText,
    contentPath,
    closeHandler,
  ]);

  const debouncedDragHandler = React.useMemo(
    () => debounce((...args) => dispatch(setFeedbackCoords(...args)), 300),
    [dispatch]
  );

  if (!feedback || !feedback.text) return null;

  return (
    <GameModal
      draggable
      draggableProps={{
        position: feedbackCoords,
        onDrag: debouncedDragHandler,
      }}
      title={translate(950, 'Feedback')}
      buttons={[
        {
          clickHandler: (e) => toggleAudio(feedbackAudioSrc),
          dontShow: !feedback.audio,
          icon: audioIcon,
        },
        {
          clickHandler: closeHandler,
          icon: `${imagesURL}/Images/Help/Close50.png`,
        },
      ]}
    >
      <div style={{ fontSize: '2vmin' }}>{feedback.text}</div>
    </GameModal>
  );
};

export default GameFeedback;
