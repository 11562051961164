import _ from 'lodash';
import shortId from 'shortid';

// import { addIdToFilename } from 'utils';
import { IMAGE_IN_RIGHT, SMALL_TEXT } from './layoutTypes';

export const validateProblem = problem => {
  const { html, incorrectAnswers } = problem;

  if (!html) {
    return false;
  }

  const correctAnswers = getCorrectAnswersFromHtml(html);

  const filteredIncorrectAnswers = filterIncorrectAnswers(incorrectAnswers);

  if (
    !correctAnswers.length ||
    (correctAnswers.length === 1 && !filteredIncorrectAnswers.length)
  ) {
    return false;
  }

  return true;
};

const filterIncorrectAnswers = (answers = []) =>
  answers.filter(ans => !!(ans.text && ans.text.trim()));

export const createProblem = () => ({
  id: shortId.generate(),
  fontFamily: 'Roboto',
  fontSize: '18',
  layoutType: IMAGE_IN_RIGHT,
  html: '',
  incorrectAnswers: []
});

export const getCorrectAnswersFromHtml = html => {
  const correctWordsTags =
    html.match(/(<u data-word-id="\S+">[\s\S]+?<\/u>)/g) || [];

  const correctWords = correctWordsTags.map(tag => {
    const [, id, text] = tag.match(/<u data-word-id="([\S]+)">([\s\S]+?)<\/u>/);
    return { id, text };
  });

  return correctWords;
};

export const getContentFilesFromProblem = problem => {
  let files = [];

  const { image } = problem;

  image && files.push(image);

  return files;
};

export const prepareProblem = problem => {
  if (!problem) return null;

  const { html, incorrectAnswers } = problem;

  const correctAnswers = getCorrectAnswersFromHtml(html);
  const filteredIncorrectAnswers = filterIncorrectAnswers(incorrectAnswers);

  const startPositions = _.chain(
    correctAnswers.length + filteredIncorrectAnswers.length
  )
    .times()
    .shuffle()
    .value();

  const items = _.concat(correctAnswers, filteredIncorrectAnswers);

  return {
    ...problem,
    correctAnswers,
    items: _.chain(items)
      .map((item, idx) => ({
        id: item.id,
        data: { ...item },
        boxIdx: startPositions[idx],
        inSlot: false
      }))
      .shuffle()
      .value()
  };
};

export const validateWords = (words, templateDBrecord) => {
  // Should be minimum 3 words
  if (words.length < 3) {
    return { success: false, error: 'Should be minimum 3 words' };
  }

  for (let i = 0; i < words.length; i++) {
    if (!words[i].sentence) {
      return {
        success: false,
        error: words[i].word + " - Didn't has a sentence "
      };
    }
  }

  return { success: true, filteredWords: words };
};

export const generateActivityFromWords = ({ words }) => {
  const problems = words.map(word => {
    const incorrectAnswers = _.chain(words)
      .filter(w => word._id !== w._id)
      .shuffle()
      .take(2)
      .map(w => ({ id: shortId.generate(), text: w.word }))
      .value();

    const wordWithoutParenthesis = word.word.replace(/\([\s\S]+?\)/g, '');

    const correntAnswer = `<u data-word-id="${shortId.generate()}">${wordWithoutParenthesis}</u>`;

    const wordRegExp = new RegExp(`${wordWithoutParenthesis}`, 'i');
    const html = `<p>${word.sentence.replace(wordRegExp, correntAnswer)}</p>`;

    const problem = {
      id: shortId.generate(),
      fontFamily: 'Roboto',
      fontSize: '32',
      layoutType: SMALL_TEXT,
      html,
      incorrectAnswers
    };

    return problem;
  });

  const gameData = { problems };

  return { gameData };
};
