import jwt_decode from "jwt-decode";
import { axiosAPI } from "utils";

const setAuthToken = token => {
  if (!token) {
    // Delete auth header
    delete axiosAPI.defaults.headers.common["Authorization"];
    return;
  }

  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;

  // Check for expired token
  if (decoded.exp < currentTime) {
    // Session expired
    // alert("Your session is expired, please re-login.");
    throw new Error("Session expired");
  }

  // Apply to every request
  axiosAPI.defaults.headers.common["Authorization"] = token;
};

export default setAuthToken;
