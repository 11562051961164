import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { uploadFiles } from 'actions/commonActions';
import { showError } from 'actions/statusActions';
import { saveEditingActivity } from 'actions/flinkMakeActions';
import { DraggableModal } from 'components/flink-components';
import { activitiesKey } from 'config';
import classes from './ImportProblems.module.scss';

export class ImportProblems extends Component {
  addFileHandler = async (e) => {
    const { onImport } = this.props;

    const { files } = e.target;

    if (!files || !files[0] || files[0].type !== 'text/plain') {
      this.fileInputRef.current.value = '';
      return;
    }

    try {
      const problems = await this.getProblemsFromTxtFile(files[0]);
      this.fileInputRef.current.value = '';

      onImport(problems);
    } catch (err) {
      this.fileInputRef.current.value = '';
    }
  };

  getProblemsFromTxtFile = (f) => {
    const { createProblemFn } = this.props;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const file = event.target.result;

        let problems = file.split(/(\r?\n){2,}/g);

        problems = _.chain(problems)
          .map((problem) => {
            const formattedProblem = problem.trim();

            if (!formattedProblem) return null;

            const textArray = formattedProblem
              .split(/(\r\n|\r|\n)/g)
              .filter((ans) => !!(ans && ans.trim()));

            return createProblemFn(textArray);
          })
          .compact()
          .value();

        resolve(problems);
      };

      reader.onerror = (event) => {
        alert(event.target.error.name);
        reject();
      };

      reader.readAsText(f);
    });
  };

  addMultipleHandler = async (e) => {
    const {
      showError,
      onImport,
      uploadFiles,
      activityId,
      addMediaToProblemsFn,
      saveEditingActivity,
    } = this.props;
    const { files } = e.target;

    const supportedTypes = ['audio/mpeg', 'text/plain'];

    const notSupportedFiles = [];
    const textFiles = [];

    // Supported files only *.txt and *.mp3
    const mediaFiles = _.filter(files, (f) => {
      if (!supportedTypes.includes(f.type)) {
        notSupportedFiles.push(f);
        return false;
      }

      if (f.type === 'text/plain') {
        textFiles.push(f);
        return false;
      }

      return true;
    });

    if (notSupportedFiles.length) {
      return showError({
        html: true,
        message: `
          Following files are not supported, please remove them from the set: <br>
          <ul>
            ${notSupportedFiles.map((f) => `<li>${f.name}</li>`)}
          </ul>
        `,
      });
    }

    if (!textFiles.length) {
      return showError({
        message: 'You must have a text file to import questions.',
      });
    }

    if (textFiles.length > 1) {
      return showError({
        message:
          'You have too many question files. You must have a single text file to import questions.',
      });
    }

    try {
      const problems = await this.getProblemsFromTxtFile(textFiles[0]);
      this.fileInputRef.current.value = '';

      const uploadData = [];
      const mediaData = [];

      const regExp = /^(\d+)([a-zA-Z]+)(\d+?)?\.(.+)$/;

      // Upload media
      _.forEach(mediaFiles, (file) => {
        const filename = file.name;
        const matchResult = filename.match(regExp);

        if (!matchResult) return;

        const problemIdx = matchResult[1] - 1;
        const itemType = matchResult[2];
        const itemIdx = matchResult[3] ? matchResult[3] - 1 : 1;
        const extension = matchResult[4];

        let fileType;

        if (['mp3'].includes(extension)) fileType = 'audio';
        if (['png', 'jpg', 'jpeg'].includes(extension)) fileType = 'image';

        mediaData.push({ fileType, problemIdx, itemType, itemIdx, filename });

        const key = `${activitiesKey}/${activityId}/gamedata/${filename}`;
        uploadData.push({ key, file });
      });

      const problemsWithMedia = addMediaToProblemsFn(problems, mediaData);

      await uploadFiles(uploadData);

      onImport(problemsWithMedia);
      saveEditingActivity();
    } catch (err) {
      this.fileInputRef.current.value = '';
    }
  };

  fileInputRef = React.createRef();
  multipleInputRef = React.createRef();

  render() {
    const { show, translate, onClose } = this.props;

    return (
      <DraggableModal
        title={translate(331, 'Import', true)}
        show={show}
        withOverlay
        onClose={onClose}
      >
        <div>
          <label className={classes.label}>
            <input
              ref={this.fileInputRef}
              type="file"
              accept=".txt"
              onChange={this.addFileHandler}
            />
            Questions Only
          </label>

          <label className={classes.label}>
            <input
              multiple
              type="file"
              accept=".txt, .mp3"
              ref={this.multipleInputRef}
              onChange={this.addMultipleHandler}
            />
            Questions and Media
          </label>
        </div>
      </DraggableModal>
    );
  }
}

ImportProblems.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createProblemFn: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  activityId: PropTypes.string.isRequired,

  translate: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  addMediaToProblemsFn: PropTypes.func.isRequired,
  saveEditingActivity: PropTypes.func.isRequired,
};

const mapStateToProps = ({ status }) => ({
  translate: status.translate,
});

export default connect(mapStateToProps, {
  showError,
  uploadFiles,
  saveEditingActivity,
})(ImportProblems);
