import shortid from 'shortid';
import { convert } from 'activity-templates/utils';

type OldQuestion = {
  audio: string;
  categories: string[];
  definition: string;
  image: string;
  prefix: string;
  word: string;
};

type OldProblem = {
  noun: OldQuestion & { correct_category: string };
  adjective: OldQuestion;
  verb: OldQuestion;
  sentence: string;
};

export default async (s3FilesList: any[]) => {
  const defaultOldOptions = {
    ProblemOrder: 1,
    LengthOfTime: 3,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const { record } = gameData;

  const problems = record.map((data: OldProblem) => {
    const { adjective, noun, sentence, verb } = data;

    return {
      id: shortid.generate(),
      noun: {
        text: noun.word,
        definition: noun.definition,
        image: noun.image,
        audio: noun.audio,
        categories: noun.categories,
        correctCategory: noun.correct_category,
      },
      adjective: {
        text: adjective.word,
        definition: adjective.definition,
        image: adjective.image,
        audio: adjective.audio,
        prefixes: adjective.categories,
      },
      verb: {
        text: verb.word,
        definition: verb.definition,
        image: verb.image,
        audio: verb.audio,
        prefixes: verb.categories,
      },
      sentenceAudio: sentence,
    };
  });

  return {
    gameData: { problems },
    options: options,
    helpHtml: helpHtml,
    helpAudio: helpAudio,
  };
};
