import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CategorySelect = ({
  disabled,
  items,
  label,
  value,
  changeHandler,
  defaultText
}) => {
  if (!label && (!items || !items.length)) return null;

  return (
    <Fragment>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect
        disabled={disabled}
        value={value || ''}
        onChange={e => changeHandler(e.target.value)}
      >
        {defaultText && <option value="">{defaultText}</option>}
        {items.map(item => {
          if (typeof item === 'object') {
            return (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            );
          }

          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </StyledSelect>
    </Fragment>
  );
};

CategorySelect.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  defaultText: PropTypes.string
};

export default CategorySelect;

const StyledLabel = styled.label`
  font-weight: 500;
  margin: 5px 0 2px 0;
  padding: 0;
  color: black;
  display: block;
`;

const StyledSelect = styled.select`
  border-radius: 4px;
  border: 1px solid gray;
  padding: 1px 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  display: block;
  font: inherit;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
`;
