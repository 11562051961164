export default [
  { in: 'bounce', out: 'bounce' },
  { in: 'swing', out: 'swing' },
  { in: 'bounceInDown', out: 'bounceOutUp' },
  { in: 'bounceInLeft', out: 'bounceOutRight' },
  { in: 'fadeInDownBig', out: 'fadeOutUpBig' },
  { in: 'fadeInLeftBig', out: 'fadeOutRightBig' },
  { in: 'fadeInDown', out: 'fadeOutUp' },
  { in: 'fadeInUp', out: 'fadeOutDown' },
  { in: 'fadeInLeft', out: 'fadeOutRight' },
  { in: 'fadeInRight', out: 'fadeOutLeft' },
  { in: 'flip', out: 'flip' },
  { in: 'lightSpeedIn', out: 'lightSpeedOut' },
  { in: 'rotateInDownLeft', out: 'rotateOutDownRight' },
  { in: 'rotateInDownRight', out: 'rotateOutDownLeft' },
  { in: 'rotateInUpLeft', out: 'rotateOutUpRight' },
  { in: 'rotateInUpRight', out: 'rotateOutUpLeft' },
  { in: 'slideInLeft', out: 'slideOutRight' },
  { in: 'slideInRight', out: 'slideOutLeft' },
  { in: 'slideInUp', out: 'slideOutDown' },
  { in: 'slideInDown', out: 'slideOutUp' },
  { in: 'zoomIn', out: 'zoomOut' },
  { in: 'zoomInDown', out: 'zoomOutUp' },
  { in: 'zoomInUp', out: 'zoomOutDown' },
  { in: 'rollIn', out: 'rollOut' }
];
