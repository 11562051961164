import { Filter, formatPaginationParams } from 'api/utils';
import { useDispatch } from 'react-redux';
import { setTableState } from 'actions/adminActions';
import useTableState from 'utils/hooks/useTableState';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function usePaginationParams<O extends {}>(table: string) {
  const dispatch = useDispatch();
  const params = useTableState(table);

  const [page, setPage] = useState<number>(params.page || 0);
  const [limit, setLimit] = useState<number>(params.limit || 10);
  const [order, setOrder] = useState<'asc' | 'desc'>(params.order || 'asc');
  const [orderBy, setOrderBy] = useState<keyof O>(params.orderBy);
  const [filters, setFilters] = useState<Record<keyof O, Filter>>(
    params.filters || Object
  );

  const rawApiParams = useMemo(
    () => ({
      page,
      limit,
      order,
      orderBy,
      filters,
    }),
    [filters, limit, order, orderBy, page]
  );

  const apiParams = useMemo(() => formatPaginationParams(rawApiParams), [
    rawApiParams,
  ]);

  useEffect(() => {
    const params = JSON.stringify(rawApiParams);
    dispatch(setTableState(table, params));
  }, [dispatch, table, rawApiParams]); // persist table state into store

  const toggleOrderBy = useCallback(
    (key: keyof O) => {
      const isAsc = orderBy === key && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(key);
    },
    [order, orderBy]
  );

  return useMemo(
    () => ({
      apiParams,
      page,
      setPage,
      limit,
      setLimit,
      order,
      setOrder,
      orderBy,
      setOrderBy,
      filters,
      setFilters,
      toggleOrderBy,
    }),
    [filters, limit, order, orderBy, page, apiParams, toggleOrderBy]
  );
}
