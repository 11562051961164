import DirectionsGrid from './DirectionsGrid';
import validateActivity from './validateDirectionsGrid';

export default {
  Template: DirectionsGrid,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'randomOrder',
    'questionsLimit',
    'showAnswer',
    'directionsGrid'
  ],
};
