import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ProgressButton = ({ loading, disabled, children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button disabled={loading || disabled} {...props}>
        {children}
      </Button>

      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default ProgressButton;
