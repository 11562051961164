import _ from 'lodash';

export default params => {
  const { activities, userId, filter, eduFilter, exclude = [] } = params;

  return _.chain(activities)
    .filter(act => {
      if (userId && act.inEditList === userId) {
        return false;
      }

      if (act.generatedFromWordlist) {
        return false;
      }

      if (exclude.includes(act._id)) {
        return false;
      }

      if (filter) {
        if (filter.templateId && act.templateId !== filter.templateId) {
          return false;
        }

        if (
          filter.keyword &&
          act.activityName
            .toLowerCase()
            .indexOf(filter.keyword.toLowerCase()) === -1
        ) {
          return false;
        }
      }

      if (!act.educationalCategories) {
        return false;
      }

      if (eduFilter && !_.filter(act.educationalCategories, eduFilter).length) {
        return false;
      }

      return true;
    })
    .value();
};
