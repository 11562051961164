import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { WhiteBox } from 'components/flink-play';
import { StarIcon } from 'components/flink-play/FlinkPlayIcons/FlinkPlayIcons';
import classes from './MenuGroupView.module.scss';

const MenuGroupView = ({
  title,
  items,
  learners,
  highlight,
  withoutStars,
  activityClickHandler,
  groupClickHandler,
}) => {
  return (
    <WhiteBox outerClass={classes.wrapper} innerClass={classes.innerWrapper}>
      {title && <h2 className={classes.title}>{title}</h2>}

      <ul className={classes.itemsList}>
        {items.map((ele) => {
          const { _id, isGroup } = ele;

          const clickHandler = isGroup
            ? groupClickHandler
            : activityClickHandler;

          const feedbacks = learners.map((learner) => {
            const report = learner.reports && learner.reports[_id];
            return report || {};
          });

          return (
            <li key={_id}>
              <button
                onClick={() => clickHandler && clickHandler(_id)}
                className={classnames(classes.item, {
                  [classes.clickable]: !!clickHandler,
                  [classes.itemGroup]: isGroup,
                  [classes.itemActivity]: !isGroup,
                  [classes.previous]: !isGroup && _id === highlight,
                })}
              >
                {withoutStars
                  ? null
                  : feedbacks.map((f, idx) => {
                      return (
                        <div key={idx}>
                          <StarIcon
                            idx={idx}
                            mastered={f.mastered}
                            partial={f.partial}
                          />
                        </div>
                      );
                    })}

                {ele.formattedName}
              </button>
            </li>
          );
        })}
      </ul>
    </WhiteBox>
  );
};

MenuGroupView.propTypes = {
  withoutStars: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  activityClickHandler: PropTypes.func,
  groupClickHandler: PropTypes.func,
  learners: PropTypes.array,
  highlight: PropTypes.string,
};

export default MenuGroupView;
