import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dropin from 'braintree-web-drop-in';

import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsButton from '../Settings/SettingsButton/SettingsButton';
import api from 'api';
import classes from './BraintreePaymentForm.module.scss';

const BraintreePaymentForm = ({ onCancel, onSuccess, familyId }) => {
  const [processing, setProcessing] = useState(false);
  const [dropipInstance, setDropinInstance] = useState();

  useEffect(() => {
    setProcessing(true);
    api.purchase
      .getBraintreeToken({ familyId })
      .then((result) => {
        console.log(result);
        setProcessing(false);

        if (result.success) {
          dropin.create(
            {
              authorization: result.data,
              selector: '#dropin-container',
            },
            function (err, instance) {
              if (err) {
                // An error in the create call is likely due to
                // incorrect configuration values or network issues
                return;
              }

              setDropinInstance(instance);
            }
          );
        } else {
          alert(result.message);
        }
      })
      .catch((err) => {
        alert(err.message);
        setProcessing(false);
      });
  }, [familyId]);

  const requestPaymentMethod = () => {
    setProcessing(true);

    dropipInstance.requestPaymentMethod(function (err, payload) {
      setProcessing(false);
      if (err) {
        // An appropriate error will be shown in the UI
        return;
      }

      onSuccess(payload.nonce);
    });
  };

  return (
    <div>
      <div id="dropin-container"></div>

      {processing && (
        <div className={classes.spinnerWrapper}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.footer}>
        {onCancel && (
          <SettingsButton onClick={onCancel} color="secondary">
            Cancel
          </SettingsButton>
        )}

        <SettingsButton
          onClick={requestPaymentMethod}
          disabled={!dropipInstance || processing}
        >
          Submit
        </SettingsButton>
      </div>
    </div>
  );
};

BraintreePaymentForm.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

export default BraintreePaymentForm;
