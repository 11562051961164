import React from 'react';
import TextField from '@material-ui/core/TextField';

const renderMaterialTextField = ({
  input,
  label,
  meta: { touched, error },
  fullWidth,
  ...custom
}) => (
  <div style={{ marginBottom: '15px' }}>
    <TextField
      margin="dense"
      variant="outlined"
      label={label}
      helperText={touched && error}
      error={touched && error ? true : false}
      placeholder={label}
      fullWidth={fullWidth}
      {...input}
      {...custom}
    />
  </div>
);

export default renderMaterialTextField;
