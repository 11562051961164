import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import renderSelect from './renderSelect';
import renderTextField from './renderTextField';

class StringValues extends Component {
  state = {
    values: [],
    default: null
  };

  inputField = React.createRef();
  selectField = React.createRef();

  getArrayFromString = string => string.split(',').map(string => string.trim());

  componentDidMount() {
    const stringValues = this.inputField.current.value;
    const defaultValue = this.selectField.current.value;

    if (stringValues) {
      this.setState({
        values: this.getArrayFromString(stringValues),
        default: defaultValue
      });
    }
  }

  handleDefaultSelect = (e, value) => {
    this.setState({ default: value });
  };

  handleFieldChange = e => {
    const newValues = this.getArrayFromString(e.target.value);

    if (this.props.defaultSelect && !newValues.includes(this.state.default)) {
      const { name, form } = this.props.defaultSelect;
      console.log(1);
      this.props.change(form, name, null);
      this.setState({ values: newValues, default: null });
    } else {
      this.setState({ values: newValues });
    }
  };

  render() {
    const { label, name, defaultSelect, column } = this.props;

    return (
      <StyledWrapper column={column}>
        <Field
          ref={this.inputField}
          name={name}
          label={label}
          component={renderTextField}
          onChange={this.handleFieldChange}
        />

        {defaultSelect && (
          <Field
            ref={this.selectField}
            name={defaultSelect.name}
            label={defaultSelect.label}
            component={renderSelect}
            onChange={this.handleDefaultSelect}
            options={this.state.values.map(value => ({ value, label: value }))}
            isClearable
          />
        )}
      </StyledWrapper>
    );
  }
}

StringValues.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,

  defaultSelect: PropTypes.exact({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired
  })
};

export default connect(
  null,
  { change }
)(StringValues);

const StyledWrapper = styled.div`
  ${props =>
    props.column &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `};
`;

// const StyledGroupLabel = styled.p`
//   font-size: 1.4rem;
//   font-weight: bold;
//   margin-bottom: 0.5rem;
//   margin-top: 1rem;
// `;
