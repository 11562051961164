import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withData } from 'components/hocs';
import classes from './AssignableProductsList.module.scss';

const AssignableProductsList = ({ products, available = [] }) => {
  return (
    <Fragment>
      <p className={classes.title}>Assignable Plans:</p>

      <table className={classes.table}>
        <tbody>
          <tr>
            <th>Product Name</th>
            <th>Plans</th>
            {/* <th>Add-ons</th> */}
          </tr>

          {_.map(available, (val, productId) => {
            const product = _.find(products, { _id: productId });

            if (!product) return null;

            return (
              <tr key={product._id}>
                <td>{product.name}</td>
                <td>{val.plans}</td>
                {/* <td>{val.addons}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

AssignableProductsList.propTypes = {
  products: PropTypes.array.isRequired,
  available: PropTypes.object
};

export default withData(['products'])(AssignableProductsList);
