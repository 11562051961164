import MovingAnswers from './MovingAnswers';
import {
  validateWords,
  generateActivityFromWords
} from './movingAnswersHelpers';
import convertOldActivity from './convertOldMovingAnswers';
import validateActivity from './validateMovingAnswers';

export default {
  Template: MovingAnswers,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'fontFamily',
    'fontColor',
    'fontSize',
    'coloredAnswerBoxes',
    'randomOrder',
    'showQuestion',
    'showAnswer',
    'animationSpeed',
    'questionsLimit'
  ],
  generateActivityFromWords,
  validateWords
};
