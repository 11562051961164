import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  playCorrectSound,
  playIncorrectSound,
  playWatch
} from 'actions/audioActions';
import { activitiesURL } from 'config';
import { validateProblem, prepareProblem } from './missingElementsHelpers';
import { ActivityButtons, ProblemsProgress } from 'components/flink-play';
import {
  ItemsDragLayer,
  BoardWithDraggableItems
} from 'components/flink-play/dnd-components';

import classes from './MissingElements.module.scss';
import LayoutBox from './LayoutBox/LayoutBox';
import Item from './Item/Item';

class MissingElements extends Component {
  state = {};

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentProblem, items } = this.state;

    if (prevState.currentProblem !== currentProblem) {
      this.setProblemFontProps();
    }

    if (prevState.items !== items) {
      this.setItemsInSlots();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  setProblemFontProps = () => {
    const currentProblem = this.state.currentProblem;

    if (!currentProblem) return;

    const { fontFamily, fontSize } = currentProblem;

    const problemStyles = {
      fontFamily,
      fontSize: (fontSize / 992) * 100 + 'vmin'
    };

    this.setState({ problemStyles });
  };

  startGame = () => {
    const { options, gameData } = this.props.activity.data;
    const { questionsLimit, randomOrder } = options;

    // Remove invalid problems
    let problems = gameData.problems.filter(validateProblem);

    // Shuffle problems if "randomOrder" options is true
    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim problems number?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    const preparedProblems = problems.map(prepareProblem);

    // const allCorrectAnswersInActivity = _.chain(preparedProblems)
    //   .map(prob => prob.correctAnswers)
    //   .flatten()
    //   .value();

    const currentProblem = preparedProblems[0];

    const { items, correctAnswers } = currentProblem;

    this.refs = {
      boxes: _.times(items.length).map(idx => React.createRef()),
      slots: _.times(correctAnswers.length).map(idx => React.createRef())
    };

    this.setState({
      // allCorrectAnswersInActivity,
      problems: preparedProblems,
      slotsStatistic: _.times(preparedProblems.length, () => []),
      itemsInSlots: [],
      correctAnswers,
      currentProblem,
      items,
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length
    });
  };

  onDrop = ({ itemId, slotIdx }) => {
    if (slotIdx !== undefined) {
      this.moveToSlot(itemId, slotIdx);
    } else {
      this.moveToBoard(itemId);
    }
  };

  setItemsInSlots = () => {
    const { itemsInSlots, items } = this.state;

    if (!items) {
      if (itemsInSlots.length) {
        this.setState({ itemsInSlots: [] });
      }

      return;
    }

    const inSlots = items.filter(i => i.inSlot);

    this.setState({ itemsInSlots: inSlots });
  };

  moveToSlot = (itemId, slotIdx) => {
    const {
      items,
      // allCorrectAnswersInActivity,
      slotsStatistic,
      currentIndex
    } = this.state;
    let itemText;

    const updatedItems = items.map(item => {
      if (item.id === itemId) {
        itemText = item.data.text;
        return {
          ...item,
          slotIdx: slotIdx,
          inSlot: true,
          attempts: (item.attempts || 0) + 1
        };
      }

      if (item.slotIdx === slotIdx) {
        return { ...item, slotIdx: undefined, inSlot: false };
      }

      return item;
    });

    const shouldBeText = this.getTextFromSlotRef(this.refs.slots[slotIdx]);

    const changedSlotsStatistic = [...slotsStatistic];
    const currentSlotStat = changedSlotsStatistic[currentIndex][slotIdx] || {
      attempts: 0
    };
    const isAnsweredCorrectly = shouldBeText === itemText;
    // const isFirstAttempt = currentSlotStat.attempts === 0;

    changedSlotsStatistic[currentIndex][slotIdx] = {
      ...currentSlotStat,
      attempts: currentSlotStat.attempts + 1,
      isAnsweredCorrectly
      // isAnsweredCorrectlyInFirstAttempt: isFirstAttempt
      //   ? isAnsweredCorrectly
      //   : currentSlotStat.isAnsweredCorrectlyInFirstAttempt
    };

    // const changedAllCorrectAnswersInActivity = allCorrectAnswersInActivity.map(
    //   item =>
    //     item.id === itemId
    //       ? { ...item, attempts: (item.attempts || 0) + 1 }
    //       : item
    // );

    this.setState({
      items: updatedItems,
      slotsStatistic: changedSlotsStatistic
      // allCorrectAnswersInActivity: changedAllCorrectAnswersInActivity
    });
  };

  moveToBoard = itemId => {
    const { items } = this.state;

    this.setState({
      items: items.map(item => {
        if (item.id !== itemId) return item;
        return { ...item, inSlot: false, slotIdx: null };
      })
    });
  };

  getTextFromSlotRef = ref => {
    if (!ref || !ref.current) return null;
    return ref.current.parentElement.dataset.word;
  };

  checkAnswer = () => {
    const { items, correctAnswers } = this.state;
    const itemsInSlot = _.filter(items, { inSlot: true });

    if (itemsInSlot.length < correctAnswers.length) return;

    let allCorrect = true;

    this.refs.slots.forEach((ref, idx) => {
      const itemInThatSlot = _.find(itemsInSlot, item => item.slotIdx === idx);

      const shouldBeText = this.getTextFromSlotRef(ref);
      const isEqual = shouldBeText === itemInThatSlot.data.text;

      if (!isEqual) {
        allCorrect = false;
      }
    });

    if (allCorrect) {
      this.setState({ isResolved: true });
      this.props.playCorrectSound();
      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true
      });
      return;
    }
    this.props.playIncorrectSound();
    this.setState(state => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly
    }));
  };

  finishGame = () => {
    const { slotsStatistic } = this.state;
    const allSlotsFlatten = _.flatten(slotsStatistic);
    const allProblemsCount = allSlotsFlatten.length;
    const problemsAnsweredCorrectly = allSlotsFlatten.filter(
      s => s.isAnsweredCorrectly && s.attempts === 1
    ).length;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount,
        problemsAnsweredCorrectly,
        problemsAnsweredIncorrectly:
          allProblemsCount - problemsAnsweredCorrectly
      }
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState(state => {
        const nextProblem = state.problems[state.currentIndex + 1];

        const { items, correctAnswers } = nextProblem;

        this.refs = {
          boxes: _.times(items.length).map(idx => React.createRef()),
          slots: _.times(correctAnswers.length).map(idx => React.createRef())
        };

        return {
          correctAnswers,
          items,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1
        };
      });
    }, delayBeforeNext * 1000);
  };

  showAnswer = () => {
    const { items } = this.state;
    let changedItems = [...items];
    let itemsLeft = [...items];

    this.refs.slots.forEach(({ current: slotElement }, idx) => {
      const { parentElement } = slotElement;
      const shouldBeText = parentElement.dataset.word;

      const itemInThatSlot = _.find(items, item => item.slotIdx === idx);
      if (shouldBeText === itemInThatSlot.data.text) {
        return (itemsLeft = _.reject(i => i.id === itemInThatSlot.id));
      }

      // Remove that item from slot
      itemsLeft = itemsLeft.map(i =>
        i.id === itemInThatSlot.id
          ? {
              ...i,
              moveFrom: slotElement,
              inSlot: false,
              slotIdx: null
            }
          : i
      );

      // Find item for that slot
      let appropriateItems = _.filter(
        itemsLeft,
        i => i.data.text === shouldBeText
      );

      // Check if there is item not in slot with correct text
      let neededItem = _.find(appropriateItems, { inSlot: false });

      if (!neededItem) {
        neededItem = appropriateItems[0];
      }

      changedItems = changedItems.map(i =>
        i.id === neededItem.id
          ? {
              ...i,
              moveFrom: i.inSlot
                ? this.refs.slots[i.slotIdx].current
                : this.refs.boxes[i.boxIdx].current,
              inSlot: true,
              slotIdx: idx
            }
          : i
      );

      itemsLeft = _.reject(itemsLeft, i => i.id === neededItem.id);
    });

    itemsLeft.forEach(itemLeft => {
      changedItems = changedItems.map(item =>
        item.id === itemLeft.id ? itemLeft : item
      );
    });

    this.props.playWatch();
    this.setState(state => ({
      items: [...changedItems],
      isResolved: true,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1
    }));
    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true
    });
  };

  render() {
    const {
      items,
      itemsInSlots,
      correctAnswers,
      isResolved,
      problems,
      problemsLeft,
      attemptsOnCurrentProblem
    } = this.state;

    const { activity, textStyle } = this.props;
    const { options } = activity.data;

    return (
      <>
        <div style={textStyle}>
          {this.renderBlocks()}

          {problems && (
            <ProblemsProgress
              problemsNumber={problems.length}
              problemsLeft={problemsLeft}
            />
          )}
        </div>
        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CHECK_ANSWER,
              onClick: this.checkAnswer,
              dontShow:
                isResolved ||
                !items ||
                !correctAnswers ||
                correctAnswers.length !== itemsInSlots.length // || !items.filter(i => i.checked).length
            },
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: this.showAnswer,
              dontShow:
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem
            },
            buttonsTypes.HELP
          ]}
        />
      </>
    );
  }

  renderBlocks = () => {
    const { items, isResolved, currentProblem, problemStyles } = this.state;
    const { activity } = this.props;
    if (!currentProblem || !items) return null;

    return (
      <div style={problemStyles}>
        <ItemsDragLayer ItemComponent={Item} />

        <LayoutBox
          contentPath={`${activitiesURL}/${activity.activity._id}/gamedata`}
          problem={currentProblem}
          isResolved={isResolved}
          onDrop={this.onDrop}
          items={items}
          refs={this.refs.slots}
          ItemComponent={Item}
        />

        <BoardWithDraggableItems
          customClasses={{
            boardOuter: classes.answersWrapper,
            boardInner: classes.answersInner,
            box: classes.boardBox
          }}
          isResolved={isResolved}
          boxesCount={items.length}
          onDrop={this.onDrop}
          items={items}
          refs={this.refs.boxes}
          ItemComponent={Item}
        />
      </div>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats
})(MissingElements);
