import { keyBy } from 'lodash';

import classes from './SettingsSelectSolution.module.scss';

const SettingsSelectSolution = ({
  solutionLists,
  value,
  onChange,
  translate,
  solutions,
}) => {
  const solutionsMap = keyBy(solutions, '_id');

  return (
    <div className={classes.listsWrapper}>
      {solutionLists.map((list) => (
        <div className={classes.radioListWrapper} key={list.id}>
          <p className={classes.radioListLabel}>
            {translate(list.titleStringNumber, 'Modules')}
          </p>

          <div className={classes.radioList}>
            {list.items.map((solutionId) => {
              const solution = solutionsMap[solutionId];
              if (!solution) return null;

              return (
                <label key={solutionId}>
                  <input
                    checked={solutionId === value}
                    value={solutionId}
                    onChange={() => onChange(solutionId)}
                    type="radio"
                  />
                  <span>{solution.displayName}</span>
                </label>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettingsSelectSolution;
