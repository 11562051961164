import React, { Component } from "react";
import { Field, FieldArray, clearFields, change } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { get } from "lodash";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import renderSelectField from "./renderSelectField";

const renderCheckboxes = props => {
  const { options, fields } = props;

  // fields.push(options);

  return options.map((option, index) => (
    <FormControlLabel
      key={index}
      control={
        <Checkbox
          // checked={input.value ? true : false}
          // onChange={input.onChange}
          onChange={e =>
            e.target.checked
              ? fields.insert(index, option.value)
              : fields.remove(index)
          }
          color="primary"
        />
      }
      label={option.label}
    />
  ));
};

class CheckboxFieldsGroup extends Component {
  state = {
    selected: [],
    default: null
  };

  componentDidMount() {
    if (this.props.initialValues) {
      const values = get(this.props.initialValues, this.props.name);

      const selected =
        values && this.props.fields.filter(field => values[field.value]);

      selected && this.setState({ selected });
    }
  }

  componentDidUpdate() {
    if (!this.state.selected.length) {
      this.props.change(this.props.form, this.props.name, undefined);
    }

    this.props.hidden &&
      this.props.change(
        this.props.form,
        `${this.props.name}[${this.props.hidden}]`,
        !!this.state.selected.length
      );
  }

  handleCheckboxClick = (e, field) => {
    if (e.target.checked) {
      this.setState({
        selected: [...this.state.selected, field]
      });

      return;
    }

    // If there is default select, and it's value
    // match with current unchecked field
    if (this.props.defaultSelect && this.state.default === field.value) {
      const { defaultSelect, form } = this.props;
      // Clear this field in redux-form
      this.props.clearFields(form, true, true, defaultSelect.name);
    }

    this.setState({
      selected: this.state.selected.filter(
        selectedField => selectedField.value !== field.value
      )
    });
  };

  handleDefaultSelect = e => {
    this.setState({
      default: e.target.value
    });
    return e.target.value;
  };

  render() {
    const {
      fields,
      groupLabel,
      name,
      defaultSelect,
      column,
      hidden,
      required,
      min = 1
    } = this.props;

    const { selected } = this.state;

    return (
      <StyledWrapper column={column}>
        {groupLabel && <StyledGroupLabel>{groupLabel}</StyledGroupLabel>}

        {hidden && (
          <Field
            name={`${name}[${hidden}]`}
            value={!!this.state.selected.length}
            component="input"
            type="checkbox"
            hidden
          />
        )}

        <FieldArray
          name={`${name}`}
          component={renderCheckboxes}
          options={fields}
        />

        {/* {fields.map((field, index) => (
          <Field
            key={index}
            name={`${name}[${field.value}]`}
            label={field.label}
            component={renderCheckbox}
            onChange={e => this.handleCheckboxClick(e, field)}
          />
        ))} */}

        {required && selected.length < min && (
          <StyledError>At least {min} must be selected</StyledError>
        )}

        {defaultSelect && (
          <Field
            name={defaultSelect.name}
            label={defaultSelect.label}
            component={renderSelectField}
            width="auto"
            onChange={this.handleDefaultSelect}
          >
            {this.state.selected.map(({ value, label }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </Field>
        )}
      </StyledWrapper>
    );
  }
}

CheckboxFieldsGroup.propTypes = {
  fields: PropTypes.array.isRequired,
  groupLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  required: PropTypes.bool,
  min: PropTypes.number,
  form: PropTypes.string.isRequired,

  hidden: PropTypes.string,

  defaultSelect: PropTypes.exact({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
};

export default connect(null, { clearFields, change })(CheckboxFieldsGroup);

const StyledWrapper = styled.div`
  ${props =>
    props.column &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `};
`;

const StyledGroupLabel = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
`;

const StyledError = styled.p`
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #df2a26;
`;
