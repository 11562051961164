import React, { useEffect, useState, useMemo } from 'react';
import { find } from 'lodash';
import { Field, change } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { axiosAPI as axios } from 'utils';
import { renderRadioGroup } from 'components/form-components';
import { REP } from 'consts/user-roles';

const RepField = ({ disabled, change, form, isUserRep, userId }) => {
  const [reps, setReps] = useState([]);

  const repOptions = useMemo(
    () =>
      reps.map(rep => ({
        value: rep._id,
        label: rep.firstname + ' ' + rep.lastname
      })),
    [reps]
  );

  useEffect(() => {
    axios
      .get(`/api/admin/users/list?roleAlias=${REP}`)
      .then(response => {
        setReps(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (isUserRep) {
      const currentRepOption = find(repOptions, { value: userId });
      const value = currentRepOption ? currentRepOption.value : '';

      change(form, 'repId', value);
    }
  }, [isUserRep, repOptions, userId, form, change]);

  return (
    <Field
      disabled={disabled || isUserRep}
      name="repId"
      label="Rep"
      component={renderRadioGroup}
      options={repOptions}
    />
  );
};

RepField.propTypes = {
  userId: PropTypes.string.isRequired,
  isUserRep: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth }) => ({
  isUserRep: auth.userRole.alias === REP,
  userId: auth.user._id
});

export default compose(
  connect(
    mapStateToProps,
    { change }
  )
)(RepField);
