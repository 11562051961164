import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import baseResolution from "consts/base-resolution";
import { imagesURL } from "config";

const defaultState = {
  character: null,
  properties: null,
  styles: null,
  characterSize: "",
  characterLocation: ""
};

class Character extends Component {
  state = defaultState;

  componentDidMount() {
    this.setCharacter();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentMenu, settings, solution, game } = this.props;
    const { character, characterSize, characterLocation } = this.state;

    if (
      prevProps.currentMenu !== currentMenu ||
      prevProps.settings !== settings ||
      prevProps.solution !== solution ||
      prevProps.game !== game
    ) {
      this.setCharacter();
    }

    if (
      prevState.character !== character ||
      prevState.characterSize !== characterSize ||
      prevState.characterLocation !== characterLocation
    ) {
      this.calcSizeAndPosition();
    }
  }

  clearState = () => {
    if (this.state.character) {
      this.setState(defaultState);
    }
  };

  setMenuCharacter = () => {
    const { currentMenu, settings, characters } = this.props;

    const { bySolution, selectedSolutionId } = settings;
    const selectedCharacterId =
      bySolution &&
      bySolution[selectedSolutionId] &&
      bySolution[selectedSolutionId].character;

    if (!selectedCharacterId) {
      return this.clearState();
    }

    const character = _.find(characters, { _id: selectedCharacterId });

    const { characterSize, characterLocation } = currentMenu;
    this.setState({ character, characterSize, characterLocation });
  };

  setGameCharacter = () => {
    const { game, settings, characters } = this.props;
    const { currentActivity, isPreview } = game;

    let character;

    if (isPreview) {
      character = { name: "Finn" };
    }

    if (settings && characters) {
      const { bySolution, selectedSolutionId } = settings;
      const selectedCharacterId =
        bySolution &&
        bySolution[selectedSolutionId] &&
        bySolution[selectedSolutionId].character;

      if (selectedCharacterId) {
        character = _.find(characters, {
          _id: selectedCharacterId
        });
      }
    }

    if (currentActivity && character) {
      const {
        characters,
        characterSize,
        characterLocation
      } = currentActivity.template;
      // if characters option is off then don't render character
      if (!characters) {
        return this.clearState();
      }

      this.setState({ character, characterSize, characterLocation });
    } else {
      this.clearState();
    }
  };

  setCharacter = () => {
    const { currentMenu, settings, solution, characters, game } = this.props;

    if (game && game.currentActivity) {
      this.setGameCharacter();
    } else if (currentMenu && settings && solution && characters) {
      this.setMenuCharacter();
    } else {
      return this.clearState();
    }
  };

  calcSizeAndPosition = () => {
    const { characterSize, characterLocation } = this.state;

    if (!characterSize || !characterLocation) return;

    const [originWidth, originHeight] = characterSize
      .replace(/\s/g, "")
      .split("x");

    const [originX, originY] = characterLocation.split(",");

    const properties = {
      originX,
      originY,
      originWidth,
      originHeight
    };

    const styles = {
      position: "fixed",
      width: (originWidth / baseResolution.height) * 100 + "vmin",
      left: (originX / baseResolution.width) * 100 + "%",
      bottom:
        ((baseResolution.height - originY - originHeight) /
          baseResolution.height) *
          100 +
        "%"
    };

    this.setState({ properties, styles });
  };

  render() {
    const { character, properties, styles } = this.state;

    if (!character || !properties) return null;

    const { originWidth, originHeight } = properties;

    const url = `${imagesURL}/Characters/${character.name}/${originWidth}x${originHeight}/${character.name}.png`;

    return <img draggable="false" style={styles} src={url} alt="Character" />;
  }
}

Character.propTypes = {
  game: PropTypes.object,
  currentMenu: PropTypes.object,
  characters: PropTypes.array,
  settings: PropTypes.object,
  solution: PropTypes.object
};

const mapStateToProps = ({
  flinkPlay: { currentMenu, solution, characters, learner },
  game
}) => ({
  currentMenu,
  settings: learner && learner.settings,
  solution,
  characters,
  game
});

export default connect(mapStateToProps)(Character);
