import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import { WhiteBox, CustomScrollbar, FamilyPoints } from 'components/flink-play';
import { StarIcon } from 'components/flink-play/FlinkPlayIcons/FlinkPlayIcons';
import { imagesURL } from 'config';
import classes from './MainMenuBoard.module.scss';
import SupportPhone from 'components/common/SupportPhone';

const MainMenuBoard = (props) => {
  const {
    title,
    subtitle,
    solutionMenu,
    translate,
    learners = [],
    learnerGoal,
    openMenuItem,
    scrollPosition,
    setScrollPosition,
    scrollbarRef,
    children,
    noReports = false,
    points = false,
    fromIndividualReports,
  } = props;
  const { menuGroups } = solutionMenu;

  const levels = useMemo(() => {
    return (
      _.chain(menuGroups)
        .map((g) => (isNaN(+g.menuLevel) ? g.menuLevel : +g.menuLevel))
        .uniq()
        .sort((a, b) => {
          if (isNaN(a) && isNaN(b)) return a > b ? 1 : -1;
          if (isNaN(a)) return 1;
          if (isNaN(b)) return -1;
          return b - a;
        })
        // .take(9)
        .value()
    );
  }, [menuGroups]);

  const firstCategories = useMemo(() => {
    return _.chain(menuGroups)
      .map((g) => g.firstCategory)
      .uniq()
      .map((id) => ({ id, ...solutionMenu.reports[id] }))
      .sortBy('order')
      .value();
  }, [menuGroups, solutionMenu]);

  const {
    logo,
    buttonImage,
    levelTitle: levelTitleStringNumber,
    levelNames,
  } = solutionMenu;

  let hasTitles = false;

  const levelsWithNames = levels.map((level) => {
    const translatedName =
      levelNames &&
      levelNames[level] &&
      translate(levelNames[level], level, true);

    translatedName && (hasTitles = true);
    return { level, name: translatedName };
  });

  return (
    <WhiteBox outerClass={classes.wrapper} innerClass={classes.innerWrapper}>
      <div className={classes.header}>
        <div className={classes.logoWrapper}>
          <img
            src={logo || `${imagesURL}/Images/Login/FLCLogo.png`}
            alt="Flink logo"
          />
        </div>

        {points && <FamilyPoints />}

        <div className={classes.titleAndSubtitleWrapper}>
          <h1 className={classes.menuTitle}>{title}</h1>
          <h2 className={classes.menuSubtitle}>{subtitle}</h2>
        </div>
      </div>

      <div className={classes.table}>
        <div className={classes.tableHeading}>
          <div>
            {!noReports && (
              <ul className={classes.legend}>
                <li>
                  <span
                    style={{ color: 'yellow' }}
                    className={classes.legendStar}
                  >
                    <StarIcon partial />
                  </span>
                  <span>{translate(220, 'Partial')}</span>
                </li>
                <li>
                  <span
                    style={{ color: 'green' }}
                    className={classes.legendStar}
                  >
                    <StarIcon mastered />
                  </span>
                  <span>{translate(221, 'Mastered')}</span>
                </li>
              </ul>
            )}

            <span
              className={classnames(classes.levelsIndicator, {
                [classes.hidden]: levels.length < 8,
              })}
            >
              {translate(levelTitleStringNumber || 107, 'Level')}{' '}
              {levels[levels.length - 1]} - {levels[0]}
            </span>
          </div>

          {hasTitles && <figure></figure>}

          {firstCategories.map((cat) => {
            const name = translate(cat.name);

            return (
              <figure key={cat.id}>
                <img
                  src={
                    cat.image && `${imagesURL}/ReportMenuImages/${cat.image}`
                  }
                  alt={name}
                />
                <figcaption>{name}</figcaption>
              </figure>
            );
          })}
        </div>

        <CustomScrollbar
          ref={scrollbarRef}
          style={{ height: '47vmin' }}
          initialValue={scrollPosition}
          onChange={(scroll) => setScrollPosition && setScrollPosition(scroll)}
        >
          {levelsWithNames.map(({ level, name }) => {
            return (
              <div key={level} className={classes.levelRow}>
                <span className={classes.levelTitle}>{level}</span>

                {hasTitles && (
                  <div className={classes.cell}>
                    <div className={classes.levelName}>{name}</div>
                  </div>
                )}

                {firstCategories.map((cat) => {
                  const menuGroup = _.find(menuGroups, {
                    firstCategory: cat.id,
                    menuLevel: level + '',
                  });

                  return (
                    <div key={cat.id} className={classes.cell}>
                      {menuGroup && (
                        <>
                          <button
                            onClick={() =>
                              openMenuItem &&
                              openMenuItem(menuGroup.groupId, true)
                            }
                            disabled={!openMenuItem}
                            className={classnames(classes.menuGroupBtn, {
                              [classes.highlight]:
                                learnerGoal &&
                                learnerGoal.level === level + '' &&
                                learnerGoal.subject === cat.id,
                              // [classes.partial]:
                              //   feedback.partial && !feedback.mastered,
                              // [classes.mastered]: feedback.mastered
                            })}
                          >
                            {noReports && buttonImage ? (
                              <img src={buttonImage} alt="" />
                            ) : (
                              learners.map((l, idx) => {
                                const feedback =
                                  l.reports?.[menuGroup.groupId] || {};
                                const tooltip =
                                  feedback.dateMastered &&
                                  new Date(feedback.dateMastered);

                                return (
                                  <div
                                    key={idx}
                                    title={
                                      (tooltip && 'Mastered at ' + tooltip) ||
                                      ''
                                    }
                                  >
                                    <StarIcon
                                      idx={idx}
                                      mastered={feedback.mastered}
                                      partial={feedback.partial}
                                    />
                                  </div>
                                );
                              })
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </CustomScrollbar>

        <div className={classes.footer}>
          <SupportPhone />

          {fromIndividualReports && (
            <div className={classes.timeSpentPreviousWeek}>
              <p>{translate(1012, 'Time spent during previous week:')} {props.previousWeekTime}</p>
            </div>
          )}

          {(learners.length > 1 && !fromIndividualReports) && (
            <div className={classes.learnerStarLegend}>
              {learners.map((learner, idx) => (
                <div
                  key={learner._id}
                  className={classes.learnerStarLegendItem}
                >
                  <span>{learner.username}</span>
                  <StarIcon idx={idx} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {children}
    </WhiteBox>
  );
};

MainMenuBoard.propTypes = {
  solutionMenu: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  learners: PropTypes.array,
  scrollPosition: PropTypes.object,
  setScrollPosition: PropTypes.func,
  openMenuItem: PropTypes.func,
  learnerGoal: PropTypes.object,
  fromIndividualReports: PropTypes.bool,
};

export default MainMenuBoard;
