import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

const Translate = ({ stringNumber, defaultText, strings, currentLang }) => {
  const string = _.find(strings, { stringNumber: +stringNumber });
  const translate = string?.data[currentLang.code];

  if (!translate) {
    return defaultText || <span>Translate not found</span>;
  }

  return translate.prettyValue ? (
    <span dangerouslySetInnerHTML={{ __html: translate.prettyValue }} />
  ) : (
    <span>{translate.stringValue}</span>
  );
};

Translate.propTypes = {
  stringNumber: PropTypes.string.isRequired,
  defaultText: PropTypes.string,
  strings: PropTypes.array.isRequired,
  currentLang: PropTypes.object.isRequired
};

const mapStateToProps = ({ common, status }) => ({
  strings: common.strings,
  currentLang: status.lang
});

export default connect(mapStateToProps)(Translate);
