import { find, filter } from 'lodash';

/**
 * Summary. (use period)
 *
 * Get students who assigned to current teacher
 *
 * @param {Array}     users         Array of users
 * @param {String}    teacherId     Teacher ID
 * @param {String}    productId     Product ID
 *
 * @return {Array} Array of students assigned to this teacher and this product
 */

export default (users, teacherId, productId) => {
  const studentsOfCurrentTeacher = filter(users, user => {
    if (!user.teachers || !user.teachers.length) return false;

    // Get object of current teacher from student teachers
    const teacherObj = find(user.teachers, {
      teacherId
    });

    // if student don't have assigned to this teacher then reject
    if (!teacherObj) return false;

    // check if student have assigned to current product
    return teacherObj.products && teacherObj.products.includes(productId);
  });

  return studentsOfCurrentTeacher;
};
