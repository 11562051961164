import { axiosAPI, withLoading } from 'utils';
import { SET_CLIPART_IMAGES, SET_ACTIVE_CATEGORY } from 'actions/types';

const filter = 'flinkMake/clipart';

export const fetchClipartImages = () => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axiosAPI.get('api/flink-make/clipart');

      dispatch({
        filter,
        type: SET_CLIPART_IMAGES,
        payload: res.data
      });
    } catch (err) {
      console.log(err);
    }
  });

export const setActiveCategory = category => ({
  filter,
  type: SET_ACTIVE_CATEGORY,
  payload: category
});
