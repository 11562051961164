import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { getFileByUrl, getSignedUrl } from "utils";
import { DraggableModal } from "components/flink-components";
import {
  fetchClipartImages,
  setActiveCategory
} from "actions/flinkMakeActions/clipart";
import ClipartList from "./ClipartList";

const onPickClipartImage = async ({ key, filename }, cb) => {
  const url = await getSignedUrl({ key, method: "get" });
  const file = await getFileByUrl(url, filename);

  cb(file);
};

const Clipart = ({
  show,
  returnFile,
  translate,
  onClose,
  onPick,
  activeCategory,
  setActiveCategory,
  fetchClipartImages,
  clipart
}) => {
  useEffect(() => {
    if (show && !clipart) {
      fetchClipartImages();
    }
  }, [show, clipart, fetchClipartImages]);

  return (
    <DraggableModal
      withOverlay
      title={translate(435, "Clipart Dialog")}
      show={show}
      onClose={onClose}
      size={{
        height: "60vmin",
        width: "75vmin"
      }}
    >
      {clipart ? (
        <ClipartList
          translate={translate}
          clipart={clipart}
          activeCategory={activeCategory}
          changeCategoryHandler={setActiveCategory}
          onPick={imageInfo =>
            returnFile
              ? onPickClipartImage(imageInfo, onPick)
              : onPick(imageInfo)
          }
        />
      ) : (
        "Loading..."
      )}
    </DraggableModal>
  );
};

Clipart.propTypes = {
  returnFile: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = ({
  flinkMake,
  status,
  flinkPlay: { solutionTranslate }
}) => ({
  translate: solutionTranslate || status.translate,
  clipart: flinkMake.clipart.list,
  activeCategory: flinkMake.clipart.activeCategory
});

export default compose(
  connect(mapStateToProps, { fetchClipartImages, setActiveCategory })
)(Clipart);
