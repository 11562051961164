export const ALL_TOGETHER_NOW = "ALL_TOGETHER_NOW";
export const CONCENTRATION = "CONCENTRATION";
export const DIRECTIONS_GRID = "DIRECTIONS_GRID";
export const EBOOK = "EBOOK";
export const FIND_THE_ONE = "FIND_THE_ONE";
export const MATCHING_DEFINITIONS = "MATCHING_DEFINITIONS";
export const MATCH_IT = "MATCH_IT";
export const MOVING_ANSWERS = "MOVING_ANSWERS";
export const MISSING_ELEMENTS = "MISSING_ELEMENTS";
export const NGELI = "NGELI";
export const ORDER_IT = "ORDER_IT";
export const READING_DELUXE = "READING_DELUXE";
export const SENTENCE_CONNECTABLES = "SENTENCE_CONNECTABLES";
export const SPELLING_CONNECTABLES = "SPELLING_CONNECTABLES";
export const SPELLING_KEYBOARD = "SPELLING_KEYBOARD";
export const STORY_STUDIO = "STORY_STUDIO";
export const WRITING_ASSISTANT = "WRITING_ASSISTANT";
export const WORD_CONNECTABLES = "WORD_CONNECTABLES";
export const WORD_SEARCH = "WORD_SEARCH";
