import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Draggable from '../../flink-components/Draggable/Draggable';
import WhiteBox from '../WhiteBox/WhiteBox';

import classes from './GameModal.module.scss';

const GameModal = ({
  title,
  children,
  buttons,
  centerButtons,
  draggable = false,
  buttonsColumn = false,
  draggableProps = {},
  innerClassnames = [],
}) => {
  const content = (
    <WhiteBox
      outerClass={classnames(classes.modal, {
        [classes.fixedPosition]: !draggable,
      })}
      innerClass={classnames(classes['modal-inner'], ...innerClassnames)}
    >
      <div className={classes['title']}>{title}</div>
      {children && <div className={classes['content']}>{children}</div>}
      <div
        className={classnames(classes.buttons, {
          [classes.column]: buttonsColumn,
        })}
        style={centerButtons ? { justifyContent: 'center' } : {}}
      >
        {buttons.map(
          (btn, idx) =>
            !btn.dontShow && (
              <button
                key={idx}
                disabled={btn.disabled}
                onClick={btn.clickHandler}
              >
                {btn.icon && <img src={btn.icon} alt="" />}
                {btn.title}
              </button>
            )
        )}
      </div>
    </WhiteBox>
  );

  if (draggable) {
    return (
      <Draggable withOverlay {...draggableProps}>
        {content}
      </Draggable>
    );
  }

  return (
    <>
      <div className={classes['overlay']}></div>
      {content}
    </>
  );
};

GameModal.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array.isRequired,
  innerClassnames: PropTypes.array,
};

export default GameModal;
