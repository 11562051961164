import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { DataList } from 'components/flink-components';
import { withTranslate } from 'components/hocs';

const SearchResultsList = ({
  items,
  active,
  changeActive,
  translate,
  showNameFn,
  postTitle = '',
  validateFn
}) => {
  return (
    <Fragment>
      <h4>
        {translate(393, 'Search Results')}{postTitle}
      </h4>
      <div>
        <DataList
          data={items || []}
          clickHandler={changeActive}
          active={active}
          showName={showNameFn}
          validate={validateFn}
          big
          sort
        />
      </div>
    </Fragment>
  );
};

SearchResultsList.propTypes = {
  items: PropTypes.array,
  active: PropTypes.object,
  changeActive: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  showNameFn: PropTypes.func.isRequired,
  validateFn: PropTypes.func.isRequired
};

export default withTranslate(SearchResultsList);
