import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Toggle extends Component {
  state = {
    on: false
  };

  toggle = () => {
    this.setState({ on: !this.state.on });
  };

  render() {
    const { children } = this.props;

    return children(this.state.on, this.toggle);
  }
}

Toggle.propTypes = {
  children: PropTypes.func.isRequired
};
