import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import shortid from 'shortid';

import { uploading, showError } from 'actions/statusActions';
import {
  changeActivityData,
  saveEditingActivity,
} from 'actions/flinkMakeActions';
import { imagesURL, activitiesKey } from 'config';
import API from 'api';

import classes from './AddVideoBlock.module.scss';

class AddVideoBlock extends Component {
  getPath = () =>
    `${activitiesKey}/${this.props.editingActivity.activity._id}/gamedata`;

  getCurrent = () => {
    const { editingActivity, pathToProp } = this.props;

    return _.get(editingActivity.data, pathToProp);
  };

  deleteHandler = async () => {
    const path = this.getPath();
    const current = this.getCurrent();

    this.saveChanges(null);

    const key = `${path}/${current}`;

    await API.storage.deleteFiles([key]);
  };

  uploadHandler = async (file) => {
    if (!file) return null;

    const { uploading, fileName, withId } = this.props;

    const path = this.getPath();

    const [, originFileName, extension] = file.name.match(/\/?([^/]+)\.(\S+)$/);

    const fileNameWithExt = `${fileName ? fileName : originFileName}${
      withId ? '_' + shortid.generate() : ''
    }.${extension}`;

    const key = `${path}/${fileNameWithExt}`;

    const success = await API.storage.uploadFile(key, file, {
      onUploadProgress: uploading,
    });

    uploading(null);

    return success ? fileNameWithExt : null;
  };

  onChangeInput = async (e) => {
    const { maxSize, showError } = this.props;

    const file = e.target.files[0];

    if (!file) return;

    if (maxSize && file.size > maxSize) {
      return showError({
        message: 'Max size is ' + parseFloat(maxSize / 1000 / 1000) + 'mb',
      });
    }

    const current = this.getCurrent();

    // New file, need to upload
    const filename = await this.uploadHandler(file);

    if (current && current !== filename) {
      // remove previous image
      await this.deleteHandler();
    }

    this.saveChanges(filename);
  };

  saveChanges = (filename) => {
    const {
      changeActivityData,
      onChangeHandler,
      saveEditingActivity,
      pathToProp,
    } = this.props;

    changeActivityData(pathToProp, filename);
    onChangeHandler && onChangeHandler(filename);
    saveEditingActivity();
  };

  render() {
    const { translate, showFilename, disabled } = this.props;

    const current = this.getCurrent();

    return (
      <>
        <div className={classes.wrapper}>
          <div
            className={`${classes.addBtnWrapper} ${
              disabled ? classes.disabled : ''
            }`}
          >
            <span className={classes.btnTitle}>
              {translate(0, 'Add Video')}
            </span>

            <label
              className={disabled ? '' : 'btn-effect'}
              title={translate(0, 'Add Video', true)}
            >
              <input
                disabled={disabled}
                type="file"
                onChange={this.onChangeInput}
                accept="video/*"
              />
              <img src={`${imagesURL}/FrontendUtilities/addimage.png`} alt="" />
            </label>
          </div>

          {current && (
            <>
              {showFilename && <span className={classes.text}>{current}</span>}

              <button
                className={`btn-effect ${classes['delete-button']}`}
                onClick={this.deleteHandler}
                title={translate(153, 'Delete Image')}
              >
                <img
                  src={`${imagesURL}/FrontendUtilities/delete1.png`}
                  alt=""
                />
              </button>
            </>
          )}
        </div>
      </>
    );
  }
}

AddVideoBlock.propTypes = {
  editingActivity: PropTypes.object.isRequired,
  changeActivityData: PropTypes.func.isRequired,
  pathToProp: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  saveEditingActivity: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  showFilename: PropTypes.bool,
  withId: PropTypes.bool,
};

const mapStateToProps = ({
  status: { translate },
  flinkMake: {
    activity: { editingActivity },
  },
}) => ({
  translate,
  editingActivity,
});

export default compose(
  connect(mapStateToProps, {
    uploading,
    changeActivityData,
    saveEditingActivity,
    showError,
  })
)(AddVideoBlock);
