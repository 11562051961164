/* eslint no-eval: 0 */
/* eslint eqeqeq: 0 */

import _ from 'lodash';
import shortId from 'shortid';
import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {
    ProblemOrder: 1,
    ShowAnswer: 2,
    LengthOfTime: 3,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const {
    Concentration: { MinCardCnt, MidCardCnt, MaxCardCnt, Item, Group },
  } = gameData;

  const matchTypes = _.chain(Item)
    .take(3)
    .map((i) => {
      const { Type, Title, CommonItem } = i;

      let type = '';

      switch (Type) {
        case 'Text':
          type = 'text';
          break;
        case 'Audio':
          type = 'audio';
          break;
        case 'Graphic':
          type = 'image';
          break;
        default:
          type = 'text';
      }

      return {
        title: Title,
        type,
        isCommon: CommonItem && type === 'text',
      };
    })
    .value();

  const matches = Group.map((oldMatch) => {
    const { GroupItemData } = oldMatch;

    const items = _.chain(GroupItemData)
      .take(3)
      .map((val, idx) =>
        matchTypes[idx] ? { [matchTypes[idx].type]: val } : null
      )
      .value();

    return {
      id: shortId.generate(),
      items,
    };
  });

  const cardsPerLevel = [MinCardCnt || 12, MidCardCnt || '', MaxCardCnt || ''];

  result.gameData = {
    problems: matches,
    matchTypes,
    cardsPerLevel,
  };

  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
