import React from 'react';
import _ from 'lodash';
import { imagesURL } from 'config';

export default (buttons, globalButtons, translate) => {
  const preparedButtons = _.fill(Array(7), null);

  buttons.forEach((btn) => {
    const btnObject = typeof btn === 'string' ? { type: btn } : btn;
    const btnDataFromDB = _.find(globalButtons, { alias: btnObject.type });

    if (!btnDataFromDB) {
      console.log('Button not found', btn);
      return;
    }

    if (btnObject.dontShow) {
      return;
    }

    const backgroundImage = `url(${imagesURL}/GlobalButtons/${btnDataFromDB.imageFilename})`;

    const buttonHtml = (
      <button
        type='button'
        onClick={() => {
          if (btn.beforeClickHandler) {
            btn.beforeClickHandler(btn.onClick);
          } else {
            btn.onClick();
          }
        }}
        disabled={btnObject.disabled}
        title={translate(btnDataFromDB.stringNumber, btnDataFromDB.name, true)}
        style={{
          backgroundImage,
        }}
      />
    );

    preparedButtons[btnDataFromDB.position - 1] = buttonHtml;
  });

  return preparedButtons;
};
