import { findIndex } from 'lodash';

import {
  SET_FAMILIES_AND_MEMBERS,
  ADD_FAMILY,
  CLEAR_FAMILIES,
  EDIT_FAMILY,
  DELETE_FAMILY,
  DELETE_FAMILY_MEMBER
} from 'actions/types';

const INITIAL_STATE = {
  families: null,
  familyMembers: null
};

const filter = 'families';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_FAMILIES_AND_MEMBERS:
      return {
        ...state,
        families: action.payload.families,
        familyMembers: action.payload.familyMembers
      };
    case ADD_FAMILY:
      return {
        ...state,
        families: [...state.families, action.payload.family],
        familyMembers: [...state.familyMembers, ...action.payload.familyMembers]
      };
    case EDIT_FAMILY:
      const {
        family: updatedFamily,
        familyMembers: updatedFamilyMembers,
        deletedMembersIDs
      } = action.payload;

      return {
        ...state,
        families:
          state.families &&
          state.families.map(family =>
            family._id === updatedFamily._id ? updatedFamily : family
          ),
        familyMembers: state.familyMembers && [
          ...state.familyMembers.filter(
            member =>
              findIndex(updatedFamilyMembers, { _id: member._id }) === -1 &&
              !deletedMembersIDs.includes(member._id)
          ),
          ...updatedFamilyMembers
        ]
      };
    case DELETE_FAMILY:
      return {
        ...state,
        families: state.families.filter(
          family => action.payload !== family._id
        ),
        familyMembers: state.familyMembers.filter(
          member => action.payload !== member.familyId
        )
      };
    case DELETE_FAMILY_MEMBER:
      return {
        ...state,
        familyMembers: state.familyMembers.filter(
          member => action.payload !== member._id
        )
      };
    case CLEAR_FAMILIES:
      return INITIAL_STATE;
    default:
      return state;
  }
}
