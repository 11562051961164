import _ from 'lodash';

import {
  SET_EDITING_ACTIVITY,
  SET_DELETED_PROBLEMS,
  CHANGE_ACTIVITY_DATA,
  EDITING_ACTIVITY_SAVED,
  ADD_CONTENT_FILES_TO_DELETE,
  RESTORE_CONTENT_FILES
} from 'actions/types';

const INITIAL_STATE = {
  editingActivity: null,
  activityWasChanged: false,
  filesToDelete: [],
  deletedProblems: []
};

const filter = 'flinkMake/activity';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_EDITING_ACTIVITY:
      return {
        ...INITIAL_STATE,
        editingActivity: action.payload
      };
    case CHANGE_ACTIVITY_DATA: {
      const changedActivity = JSON.parse(JSON.stringify(state.editingActivity));
      _.set(
        changedActivity,
        'data.' + action.payload.propPath,
        action.payload.val
      );

      return {
        ...state,
        activityWasChanged: true,
        editingActivity: changedActivity
      };
    }
    case EDITING_ACTIVITY_SAVED: {
      return {
        ...state,
        activityWasChanged: false,
        deletedProblems: [],
        filesToDelete: []
      };
    }
    case SET_DELETED_PROBLEMS:
      return {
        ...state,
        deletedProblems: action.payload
      };
    case ADD_CONTENT_FILES_TO_DELETE: {
      return {
        ...state,
        filesToDelete: [...state.filesToDelete, ...action.payload]
      };
    }
    case RESTORE_CONTENT_FILES: {
      return {
        ...state,
        filesToDelete: _.without(state.filesToDelete, ...action.payload)
      };
    }
    default:
      return state;
  }
}
