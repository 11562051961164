/* eslint eqeqeq: 0 */

import shortid from 'shortid';
import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {
    LengthOfTime: 3,
    ProblemOrder: 1,
    ShowAnswer: 2,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const {
    Worksheet: { SegmentData },
  } = gameData;

  const problems = SegmentData.map((data) => {
    const {
      InstrText,
      FontColor,
      InstrFontName,
      InstrAudio,
      IncorrectMaxDisplay,
      GroupData,
    } = data;

    const incorrectItemsShown =
      IncorrectMaxDisplay && !isNaN(+IncorrectMaxDisplay)
        ? +IncorrectMaxDisplay
        : 2;

    const [questions, correctAnswers, incorrectAnswers] = GroupData.map(
      (group) => {
        return group.ItemData.map(({ ItemType, ItemData, ItemAudio }) => {
          return {
            text: ItemType == 1 ? ItemData : '',
            image: ItemType == 0 ? ItemData : '',
            audio: ItemAudio,
          };
        });
      }
    );

    return {
      id: shortid.generate(),
      questions,
      correctAnswers,
      incorrectAnswers,
      instructionText: InstrText,
      instructionAudio: InstrAudio,
      incorrectItemsShown,
      fontFamily:
        InstrFontName && InstrFontName != '0' ? InstrFontName : 'Arial',
      fontColor: FontColor && FontColor != '0' ? FontColor : '#000',
    };
  });

  result.gameData = { problems };
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
