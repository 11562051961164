import React, { useState } from 'react';
import PropTypes from 'prop-types';

import WhiteBox from '../WhiteBox/WhiteBox';
import classes from './PlayRecorder.module.scss';

import { RecordAudio } from 'utils';
import { imagesURL } from 'config';

const PlayRecorder = ({
  currentUrl,
  deleteHandler,
  uploadHandler,
  classes: propsClasses,
}) => {
  const [deleting, setDeleting] = useState(false);
  const wrapperClasses = `${classes.wrapper} ${propsClasses.wrapper || ''}`;
  const innerClasses = `${classes.inner} ${propsClasses.inner || ''}`;
  const buttonClasses = `${classes.button} ${propsClasses.button || ''}`;

  return (
    <WhiteBox outerClass={wrapperClasses} innerClass={innerClasses}>
      <RecordAudio onEndRecord={(url, blob) => uploadHandler(url, blob)}>
        {({
          recorder,
          processing,
          isRecording,
          isPlaying,
          stopRecordHandler,
          startRecordHandler,
          stopPlayHandler,
          playHandler,
          resetHandler,
        }) => {
          if (!recorder) {
            return null;
          }

          function togglePlayHandler() {
            if (isPlaying) {
              stopPlayHandler();
            } else {
              playHandler(currentUrl);
            }
          }

          async function trashClickHandler() {
            setDeleting(true);
            deleteHandler && (await deleteHandler());
            resetHandler();
            setDeleting(false);
          }

          return (
            <>
              {isRecording ? (
                <Button
                  onClick={stopRecordHandler}
                  disabled={processing}
                  image="stop.png"
                  className={`${buttonClasses} ${
                    processing ? classes.processing : ''
                  }`}
                />
              ) : (
                <Button
                  onClick={startRecordHandler}
                  disabled={processing || currentUrl || deleting}
                  image="microphone.png"
                  className={buttonClasses}
                />
              )}

              <Button
                onClick={togglePlayHandler}
                disabled={processing || deleting}
                hidden={isRecording || !currentUrl}
                image={isPlaying ? 'stop.png' : 'play.png'}
                className={buttonClasses}
              />

              <Button
                onClick={trashClickHandler}
                disabled={processing || deleting}
                hidden={!currentUrl}
                image="trash.png"
                className={`${buttonClasses} ${
                  deleting ? classes.processing : ''
                }`}
              />
            </>
          );
        }}
      </RecordAudio>
    </WhiteBox>
  );
};

const Button = ({ image, ...otherProps }) => {
  return (
    <button {...otherProps}>
      <img src={`${imagesURL}/Images/Recorder/${image}`} alt="" />
    </button>
  );
};

PlayRecorder.defaultProps = {
  classes: {},
};

PlayRecorder.propTypes = {
  classes: PropTypes.object,
  uploadHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
};

export default PlayRecorder;
