import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  > * {
    margin-right: 15px!important;
    margin-left: 15px!important;
    margin-bottom: 15px!important;

    ${({ xs, sm, md, lg, xl }) =>
      css`
        width: ${xs && xs === 'auto'
          ? 'auto'
          : `calc(${xs ? xs : '100%'} - 30px)!important`};

        @media only screen and (min-width: 576px) {
          ${sm
            ? sm === 'auto'
              ? `width: auto;`
              : `width: calc(${sm} - 30px)!important;`
            : ''};
        }

        @media only screen and (min-width: 768px) {
          ${md
            ? md === 'auto'
              ? `width: auto;`
              : `width: calc(${md} - 30px)!important;`
            : ''};
        }

        @media only screen and (min-width: 992px) {
          ${lg
            ? lg === 'auto'
              ? `width: auto;`
              : `width: calc(${lg} - 30px)!important;`
            : ''};
        }

        @media only screen and (min-width: 1366px) {
          ${xl
            ? xl === 'auto'
              ? `width: auto;`
              : `width: calc(${xl} - 30px)!important;`
            : ''};
        }
      `}
`;
