import { take } from "lodash";

export const defaultData = {
  pagesCount: 4,
  images: [],
  words: [],
  autoInsertArt: false,
  importClipart: true
};

export const validateWords = (words, templateDBrecord) => {
  return { success: true, filteredWords: words };
};

export const generateActivityFromWords = ({ words }) => {
  const textWords = take(
    words.map(w => w.word),
    10
  );

  const gameData = { ...defaultData, words: textWords };
  return { gameData };
};
