import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Modal from './Modal';

const styles = {
  btnWrapper: { textAlign: 'center', marginTop: '2rem' },
  container: { minWidth: '200px' },
  okBtn: { fontSize: '1.4rem' },
};

const InfoDialog = ({ info, showInfo, classes, translate }) => {
  const resetInfo = showInfo.bind(null, null);

  return (
    info && (
      <Modal on={!!info} toggle={resetInfo} bigZIndex={info.bigZIndex}>
        <div
          className={classes.container}
          style={{ textAlign: info.align || 'center' }}
        >
          {info.html ? (
            <div dangerouslySetInnerHTML={{ __html: info.message }} />
          ) : (
            <p>{info.message}</p>
          )}

          <div className={classes.btnWrapper}>
            <Button
              onClick={resetInfo}
              className={classes.okBtn}
              variant="contained"
              color="primary"
            >
              {translate(191, 'Ok', true)}
            </Button>
          </div>
        </div>
      </Modal>
    )
  );
};

InfoDialog.propTypes = {
  info: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showInfo: PropTypes.func.isRequired,
};

export default withStyles(styles)(InfoDialog);
