import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { WithPageTitle } from 'components/hocs';
import checkPermissions from './checkPermissions';
// import { setCurrentPage } from 'actions/statusActions';

const CustomRoute = ({
  auth,
  children,
  permissions,
  // setCurrentPage,
  component: Component,
  redirectTo = '/',
  ...rest
}) => {
  const hasPermissions = checkPermissions(auth, permissions);

  if (!hasPermissions) {
    auth.user && alert("You don't have permissions to access this page");
    if (redirectTo instanceof URL) {
      /*
      If redirectTo is of URL class (not string - app route), we redirect user to the given
      URL.

      This is currently intended for clever, where users must be redirected
      back to their dashboard when they have logged out.
      */

      window.location.replace(redirectTo);
      return null;
    }

    /*
    This handles navigation (not redirects) to the React app routes - we cannot put custom website URL
    there
    */
    return <Redirect to={redirectTo} />;
  }

  return (
    <Route {...rest}>
      {Component ? <WithPageTitle Component={Component} /> : children}
    </Route>
  );
};

CustomRoute.propTypes = {
  auth: PropTypes.object,
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(CustomRoute);
