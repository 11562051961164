import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from 'Root';
import App from 'App';
import reportWebVitals from './reportWebVitals';

import 'react-quill/dist/quill.snow.css';
import './scss/scaffolding.scss';
import './scss/utils.scss';
import { ErrorBoundary } from 'components/hocs';

const renderApp = () =>
  ReactDOM.render(
    <Root>
      <ErrorBoundary>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    </Root>,
    document.getElementById('root')
  );

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
