const getFileByUrl = async (url, filename) =>
  fetch(url)
    .then(data => {
      return data.blob();
    })
    .then(file => {
      file.name = filename;
      return file;
    })
    .catch(err => {
      console.log(err);
      return null;
    });

export default getFileByUrl;
