import SpellingKeyboard from './SpellingKeyboard';
import {
  validateActivity,
  validateWords,
  generateActivityFromWords,
} from './spellingKeyboardHelpers';

export default {
  Template: SpellingKeyboard,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'fontFamily',
    'fontColor',
    'fontSize',
    'randomOrder',
    'showAnswer',
    'questionsLimit',
    'displayWords',
  ],
  generateActivityFromWords,
  validateWords,
};
