import { useEffect, useState } from 'react';
import { match } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classes from './Register.module.scss';
import RegisterForm from './RegisterForm/RegisterForm';
import RegisterHeader from './RegisterHeader';
import api from 'api';

const langToFont: Record<string, string> = {
  din: 'din',
};

type RegisterProps = { history: any; match: match<{ code: string }> };

const Register = ({ match, history }: RegisterProps) => {
  const currentLanguage = useSelector((state: any) => state.status.lang);
  const [checkingCode, setCheckingCode] = useState<boolean>(true);
  const [locations, setLocations] = useState<Partial<string[]>>();

  useEffect(() => {
    const validateCode = async (code: string) => {
      const result = await api.organizationPurchase.checkCodeAvailability(code);

      if (result.success) {
        setCheckingCode(false);
        setLocations(result.locations);
      } else {
        alert(result?.error || 'Something goes wrong');
        history.goBack();
      }
    };

    validateCode(match.params.code);
  }, [history, match.params.code]);

  const fontFamily = `var(--font-${
    langToFont[currentLanguage?.code] || 'base'
  })`;
  return (
    <div style={{ fontFamily }} className={classes.wrapper}>
      <RegisterHeader />

      <main className={classes.main}>
        {checkingCode ? (
          <p>Checking you code...</p>
        ) : (
          <RegisterForm
            initialValues={{
              messageLocale: 'en',
            }}
            submit={(values) =>
              api.organizationPurchase.registerByCode(match.params.code, values)
            }
            locations={locations}
          />
        )}
      </main>
    </div>
  );
};

export default Register;
