import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Modal from './Modal';

const styles = {
  btnWrapper: { textAlign: 'center', marginTop: '2rem' },
  btn: { fontSize: '1.4rem', margin: '0 1rem' },
  customControls: { },
  input: {
    marginTop: '1rem',
    padding: '1rem',
    border: '1px solid #e8e8e8',
    borderRadius: '2px',
    width: '100%',
  },
};

const PromptDialog = (props) => {
  const { prompt, classes, showPrompt } = props;
  const [value, setValue] = useState(prompt.initialVal || '');

  const translate = prompt.translate || props.translate;
  const inputType = prompt.type || 'text';
  const input = useRef(null);

  const inputChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const okClickHandler = (e) => {
    e.preventDefault();

    prompt.cb && prompt.cb(value.trim());
    showPrompt(null);
  };

  useEffect(() => {
    input.current.focus();
  });

  const inputProps = {
    value,
    ref: input,
    className: classes.input,
    onChange: inputChangeHandler,
    ...(prompt.inputProps || {}),
  };

  return (
    prompt && (
      <Modal
        on={!!prompt}
        toggle={() => {
          showPrompt(null);
          prompt.cb(false);
        }}
        bigZIndex={prompt.bigZIndex}
      >
        <form onSubmit={okClickHandler}>
          {prompt.html ? (
            <div dangerouslySetInnerHTML={{ __html: prompt.message }} />
          ) : (
            <p>{prompt.message}</p>
          )}

          {prompt.textarea ? (
            <textarea rows={5} {...inputProps} />
          ) : (
            <input type={inputType} {...inputProps} />
          )}

          <div className={classes.customControls}>
            {prompt.controls}
          </div>

          <div className={classes.btnWrapper}>
            <Button
              onClick={() => {
                prompt.cb && prompt.cb(null);
                showPrompt(null);
              }}
              className={classes.btn}
              variant="contained"
              color="inherit"
            >
              {translate(52, 'Cancel', true)}
            </Button>
            <Button
              disabled={!value}
              type="submit"
              className={classes.btn}
              variant="contained"
              color="primary"
            >
              {translate(0, 'Submit', true)}
            </Button>
          </div>
        </form>
      </Modal>
    )
  );
};

PromptDialog.propTypes = {
  prompt: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showPrompt: PropTypes.func.isRequired,
};

export default withStyles(styles)(PromptDialog);
