/* eslint eqeqeq: 0 */

import { convert, convertOldHelp } from 'activity-templates/utils';

import { createProblem, createText } from './ebookHelpers';

import {
  EBOOK_WIDTH,
  EBOOK_HEIGHT,
  PRESENTATION,
  MANUAL,
  BOTH,
} from 'consts/ebook-consts';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {};

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const pages = (gameData.pages || []).map((oldPage) => {
    const {
      image,
      imageFnm,
      audioFnm,
      audio,
      background,
      // images,
      // textBlock,
      text,
    } = oldPage || {};

    const imageToUse = image || imageFnm || background;
    const audioToUse = audio || audioFnm;

    const textToUse = (text || []).map((oldTextObj) => {
      const { dim, text } = oldTextObj;

      // console.log('origin text', text);

      const formattedText = convertOldHelp(text);

      // console.log('formatted text', formattedText);

      const newTextObj = {
        ...createText(),
        dim: {
          width: dim.width + 30 + 'px', // 30px padding
          height: dim.height + 24 + 'px', // 24px padding
          left: (EBOOK_WIDTH / 960) * dim.left + 'px',
          top: (EBOOK_HEIGHT / 710) * dim.top + 'px',
        },
        text: formattedText,
      };

      return newTextObj;
    });

    const newPage = {
      ...createProblem(),
      image: imageToUse,
      audio: audioToUse,
      text: textToUse,
    };

    return newPage;
  });

  result.gameData = { problems: pages };

  const { PresentationModes } = options;

  let ebookMode;

  if (PresentationModes == '0') {
    ebookMode = PRESENTATION;
  } else if (PresentationModes == '1') {
    ebookMode = MANUAL;
  } else if (PresentationModes == '2') {
    ebookMode = BOTH;
  } else {
    ebookMode = MANUAL;
  }

  result.options = { ebookMode };
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
