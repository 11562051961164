import { ADD_LEARNING_CENTER } from '../types';
import { axiosAPI as axios, withLoading } from 'utils';

const filter = 'admin';

export const addLearningCenter = (type, data) => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    try {
      const res = await axios.post(`/api/admin/learningCenters/add`, data);

      dispatch({
        filter,
        type: ADD_LEARNING_CENTER,
        payload: res.data
      });

      res.success = true;

      return res;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { data: { _error: 'No response from server' } };
      }

      console.log(result);

      return result;
    }
  });

export const getLearningCenterPlansStatus = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(
        `/api/admin/learningCenters/get-plans-status/${id}`
      );

      return res.data;
    } catch (err) {
      console.log(err);
    }
  });
