import React from 'react';
import styled, { css } from 'styled-components';

const FieldSet = ({ title, children, border, flex }) => {
  return (
    <StyledFieldset>
      {title && <StyledHeadline border={border}>{title}</StyledHeadline>}
      <StyledContainer border={border} flex={flex}>
        {children}
      </StyledContainer>
    </StyledFieldset>
  );
};

export default FieldSet;

const StyledFieldset = styled.div`
  margin: 30px 0 20px;
`;

const StyledContainer = styled.div`
  ${props =>
    props.flex &&
    css`
      display: flex;
      flex-wrap: wrap;
    `};

  ${props =>
    props.border &&
    css`
      padding: 1.5rem;
      border: 1px solid #e5e5e5;
    `};
`;

const StyledHeadline = styled.span`
  ${props =>
    !props.border &&
    css`
      border-bottom: 1px solid #e5e5e5;
    `} display: block;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.35417em;
`;
