import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GameModal from "../GameModal/GameModal";

class Modals extends Component {
  state = {
    error: null,
    confirm: null
  };

  componentDidMount() {
    window.Modals = this;
  }

  componentWillUnmount() {
    delete window.Modals;
  }

  showInfo = opts => {
    opts = typeof opts === "string" ? { message: opts } : opts;

    this.setState({ info: opts });
  };

  showError = opts => {
    opts = typeof opts === "string" ? { message: opts } : opts;

    this.setState({ error: opts });
  };

  showConfirm = opts => {
    return new Promise(resolve => {
      opts = typeof opts === "string" ? { message: opts } : opts;

      opts.okHandler = () => {
        this.closeModals();
        resolve(true);
      };

      opts.cancelHandler = () => {
        this.closeModals();
        resolve(false);
      };

      this.setState({ confirm: opts });
    });
  };

  closeModals = () => {
    this.setState({ error: null, confirm: null, info: null });
  };

  renderInfo = () => {
    const { translate } = this.props;
    const {
      info: { title, okHandler, message }
    } = this.state;

    const buttons = [
      {
        title: translate(191, "OK"),
        clickHandler: () => {
          this.closeModals();
          okHandler && okHandler();
        }
      }
    ];

    return (
      <GameModal title={title} buttons={buttons}>
        {message}
      </GameModal>
    );
  };

  renderError = () => {
    const { translate } = this.props;
    const {
      error: { title, okHandler, message }
    } = this.state;

    const buttons = [
      {
        title: translate(191, "OK"),
        clickHandler: () => {
          this.closeModals();
          okHandler && okHandler();
        }
      }
    ];

    return (
      <GameModal title={title} buttons={buttons}>
        {message}
      </GameModal>
    );
  };

  renderConfirm = () => {
    const { translate } = this.props;
    const {
      confirm: { title, okHandler, cancelHandler, message }
    } = this.state;

    const buttons = [
      {
        title: translate(52, "Cancel"),
        clickHandler: cancelHandler
      },
      {
        title: translate(191, "OK"),
        clickHandler: okHandler
      }
    ];

    return (
      <GameModal title={title} buttons={buttons}>
        {message}
      </GameModal>
    );
  };

  render() {
    const { error, confirm, info } = this.state;

    return (
      <>
        {info && this.renderInfo()}
        {error && this.renderError()}
        {confirm && this.renderConfirm()}
      </>
    );
  }
}

Modals.propTypes = {
  translate: PropTypes.func.isRequired
};

const mapStateToProps = ({ flinkPlay, status }) => ({
  translate: flinkPlay.solutionTranslate || status.translate
});

export default connect(mapStateToProps)(Modals);
