import _ from 'lodash';

export default function stringProblemsMapper({ 
  translate, 
  getStringAudio, 
  textKey = 'text', 
  audioKey = 'audio', 
  translateArgs = [],
}) {
  return (item) => {
    const text = _.get(item, textKey);
    if (typeof text !== 'number') // if not a string
      return item;

    const translated = translate(text, ...translateArgs);
    const { audio, path } = getStringAudio(text, true);

    return { 
      ...item, 
      [textKey]: translated, 
      [audioKey]: audio,
      audioPath: path,
    };
  }
}
