import shortid from 'shortid';

export const validateProblem = problem => {
  return !!(
    problem.syllables &&
    problem.syllables[0] &&
    problem.syllables[0].trim() &&
    problem.syllables[1] &&
    problem.syllables[1].trim() &&
    problem.mask &&
    problem.mask.indexOf('0') !== -1
  );
};

export const createProblem = () => ({
  id: shortid.generate(),
  syllables: [],
  options: [],
  mask: ''
});

export const getContentFilesFromProblem = problem => {
  const files = [];
  const { audio, image } = problem;

  audio && files.push(audio);
  image && files.push(image);

  return files;
};
