import shortid from 'shortid';
import { addIdToFilename } from 'utils';

export const validateProblem = (problem) => {
  if (!(problem.definition || problem.audio)) {
    return false;
  }

  return !!(problem.word && problem.letters.indexOf('0') !== -1);
};

const validProblemExists = (problems) => {
  for (let i = 0; i < problems.length; i++) {
    if (validateProblem(problems[i])) return true;
  }

  return false;
};

export const validateActivity = ({ options, gameData }) => {
  return !(
    !options ||
    !gameData ||
    !gameData.problems ||
    !gameData.problems.length ||
    !validProblemExists(gameData.problems)
  );
};

export const createProblem = () => ({
  id: shortid.generate(),
  options: [],
  letters: '',
  word: '',
});

export const getContentFilesFromProblem = (problem) => {
  const files = [];
  const { audio } = problem;

  audio && files.push(audio);

  return files;
};

export const validateWords = (words, templateDBrecord) => {
  return { success: true, filteredWords: words };
};

export const generateActivityFromWords = ({
  words,
  includeDefinitionAudio,
}) => {
  const definitionsAudio = [];

  const problems = words.map((w) => {
    let audio = null;

    if (includeDefinitionAudio) {
      audio = w.definitionAudio && addIdToFilename(w.definitionAudio);

      audio &&
        definitionsAudio.push({
          src: w.definitionAudio,
          filename: audio,
        });
    }

    const letters = w.word.replace(/\S/, '0').replace(/\s/, '1');

    return {
      id: shortid.generate(),
      word: w.word,
      definition: w.definition,
      letters,
      audio,
    };
  });

  const gameData = { problems };

  return { gameData, definitionsAudio };
};
