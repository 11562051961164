import React from "react";

// lodash
import _ from "lodash";

// redux
import { connect } from "react-redux";
import { toggleAudio } from "actions/audioActions";

// config
import { audioURL, imagesURL } from "config";

// scss
import classes from "./AudioString.module.scss";

// prop types
import PropTypes from "prop-types";

const AudioString = ({ string, locale, locales, isPlaying, audioSrc }) => {

  const stringLocale = React.useMemo(() => {
    return _.find(locales, { code: locale });
  }, [locale, locales]);

  const audioStringUrl = React.useMemo(() => {
    const { stringAudio } = string;

    if (!stringLocale)
      return console.warn('Locale not found', stringLocale);

    return `${audioURL}/Generic/Strings/${stringLocale.name}/${stringAudio}`;
  }, [string, stringLocale]);

  const play = React.useCallback((event) => {
    event.stopPropagation();
    toggleAudio(audioStringUrl);
  }, [audioStringUrl]);

  const audioIcon = `${imagesURL}/Images/Audio/${audioSrc === audioStringUrl && isPlaying ? 'audiostop' : 'audio_icon1'
    }.png`;

  return (
    <span className={classes.audioString}>
      <button onClick={play} type="button">
        <img src={audioIcon} alt={string.stringValue} />
      </button>
      {string.stringValue}
    </span>
  );
}

const mapStateToProps = ({ common, audio }) => ({
  locales: common.locales,
  isPlaying: audio.isPlaying,
  audioSrc: audio.audioSrc,
});

AudioString.propTypes = {
  string: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  locales: PropTypes.array.isRequired,
  isPlaying: PropTypes.bool,
  audioSrc: PropTypes.string,
}

export default connect(mapStateToProps)(AudioString);
