export default (
  problem,
  solutionLocale,
  textPath = 'instructionText',
  audioPath = 'instructionAudio',
  stringPath = 'instructionString',
  {
    translate,
    getStringAudio,
  },
) => {
  if (!problem) return {};

  const { multiLocaleInstructions } = problem;
  const instructionString = problem[stringPath];

  if (instructionString) {
    const { audio, path } = getStringAudio(instructionString, true);
    return { 
      audio,
      audioPath: path, 
      text: translate(instructionString) 
    };
  }

  const locale =
    (solutionLocale && solutionLocale.code) ||
    (multiLocaleInstructions && multiLocaleInstructions.defaultLocale) ||
    'en';

  let audio = multiLocaleInstructions
    ? multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][audioPath]
    : problem[audioPath];

  if (audio && audio.match(/^0+$/)) {
    audio = '';
  }

  const text = multiLocaleInstructions
    ? multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][textPath]
    : problem[textPath];

  return { audio, text };
};
