import replaceTokensWithData from './replaceTokensWithData';

const fallbackDivider = 7.68;

export default ({ html, data = {}, calc, units = 'vmin', coef }) => {
  const convertFontSize = (fontSizeWithUnits, fontSize) => {
    let convertedSize;

    if (calc) {
      convertedSize = calc(fontSize);
    } else if (coef) {
      convertedSize = fontSize * coef;
    } else {
      convertedSize = fontSize / fallbackDivider;
    }

    return `: ${convertedSize}${units}`;
  };

  let formattedHtml = html
    ? html
        .replace(/:\s?(\d+(?:.\d+)?)(?:px)/gm, convertFontSize)
        .replace(/width="(\d+(?:.\d+)?)"/gm, (whole, size) => {
          return calc ? `style="width: ${calc(size)}${units}"` : whole;
        })
        .replace(/font-size:\s?(?:large|small|medium|larger|smaller)/gm, '')
    : '';
  // .replace(/<br>/gm, '<br><span style="font-size: 20em;">&nbsp;</span>');

  // Replace variables
  const withData = replaceTokensWithData(formattedHtml, data);
  return withData;
};
