import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';

// import { viewDocumentById } from 'actions/adminActions';
import { imagesURL } from 'config';
import classes from './SettingsBigButtons.module.scss';
import { useTrackFamilyPrepaidTime } from '../../PrepaidTracker/PrepaidTracker';
// import { QuickStartVideos } from 'components/flink-play';

const iconsSrc = `${imagesURL}/SettingsDialog/icons/`;

const SettingsBigButtons = ({
  openFamilyForm,
  settings,
  // viewDocumentById,
  solutions,
  changeHandler,
  // isTeam,
  learner,
  showAccountButton,
  translate,
  openAccount,
  currentLocaleCode = 'en',
  currentLocaleName = 'English',
  // quickStartVideos,
  openCharacters,
  quickStartVideos,
  showQuickstartVideos,
  openThemes,
  openSolutions,
}) => {
  // const [QSVideosModalOpen, setQSVideosModalOpen] = React.useState(false);
  const { data: familyPrepaidTime } = useTrackFamilyPrepaidTime(
    learner.familyId
  );

  const hasAnimations = useMemo(() => {
    if (
      !solutions ||
      !solutions.length ||
      !settings ||
      !settings.selectedSolutionId
    ) {
      return false;
    }

    const selectedSolution = _.find(solutions, {
      _id: settings.selectedSolutionId,
    });

    if (!selectedSolution) return false;

    const { animations } = selectedSolution;

    if (
      !animations ||
      (!animations.introduction && !animations.activityBuilder)
    ) {
      return;
    }

    const { introduction = {}, activityBuilder = {} } = animations;

    const hasIntroAnimation = !!introduction[currentLocaleCode];
    const hasBuilderAnimation = !!activityBuilder[currentLocaleCode];

    return hasIntroAnimation || hasBuilderAnimation;
  }, [solutions, settings, currentLocaleCode]);

  const hasQuickstartVideos = useMemo(() => {
    return !!quickStartVideos[currentLocaleCode]?.length;
  }, [quickStartVideos, currentLocaleCode]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.buttonWrapper}>
          {openSolutions && (
            <Button
              onClick={openSolutions}
              title={translate(954, 'Modules', true)}
              icon={iconsSrc + 'modules.png'}
            />
          )}
        </div>

        <div className={classes.buttonWrapper}>
          {openCharacters && (
            <Button
              onClick={openCharacters}
              title={translate(955, 'Characters', true)}
              icon={iconsSrc + 'characters.png'}
            />
          )}
        </div>

        <div className={classes.buttonWrapper}>
          {openThemes && (
            <Button
              onClick={openThemes}
              title={translate(956, 'Themes', true)}
              icon={iconsSrc + 'themes.png'}
            />
          )}
        </div>

        <div className={classes.buttonWrapper}>
          {hasAnimations && (
            <Button
              onClick={() => {
                changeHandler('autoAnimation', !settings.autoAnimation);
              }}
              off={!settings.autoAnimation}
              title={translate(45, 'Auto Animation', true)}
              icon={iconsSrc + 'animation.jpg'}
            />
          )}
        </div>

        <div className={classes.buttonWrapper}>
          <Button
            // disabled={isTeam && settings.teamHelp}
            onClick={() => {
              changeHandler('activityHelp', !settings.activityHelp);
            }}
            off={!settings.activityHelp}
            // off={!settings.activityHelp || (isTeam && settings.teamHelp)}
            title={translate(44, 'Auto Activity Help', true)}
            icon={iconsSrc + 'activity.jpg'}
          />
        </div>

        <div className={classes.buttonWrapper}>
          {
            <Button
              onClick={openFamilyForm}
              title={translate(201, 'Family Members', true)}
              icon={iconsSrc + 'team.jpg'}
            />
          }
        </div>

        <div className={classes.buttonWrapper}>
          {showAccountButton &&
            (!familyPrepaidTime || !familyPrepaidTime.fullyPaid) && (
              <Button
                onClick={openAccount}
                title={translate(852, 'Manage', true)}
                icon={iconsSrc + 'account.jpg'}
              />
            )}
        </div>

        <div className={classes.buttonWrapper}>
          {hasQuickstartVideos && (
            <Button
              onClick={showQuickstartVideos}
              title={translate(200, "Quick Start", true)}
              icon={iconsSrc + "quickstart.png"}
            />
          )}
        </div>

        {/* <div className={classes.buttonWrapper}>
          {isTeam && (
            <Button
              onClick={() => {
                changeHandler("teamHelp", !settings.teamHelp);
              }}
              off={!settings.teamHelp}
              title={translate(245, "Team Help", true)}
              icon={iconsSrc + "team.jpg"}
            />
          )}
        </div> */}
      </div>
    </>
  );
};

SettingsBigButtons.propTypes = {
  openFamilyForm: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  quickStartVideos: PropTypes.object.isRequired,
  showQuickstartVideos: PropTypes.func.isRequired,
  // viewDocumentById: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  learner: PropTypes.object.isRequired,
  isTeam: PropTypes.bool.isRequired,
  showAccountButton: PropTypes.bool,
  solutions: PropTypes.array,
  currentLocaleCode: PropTypes.string,
  currentLocaleName: PropTypes.string,
};

const mapStateToProps = ({ auth, flinkPlay, common: { config } }) => ({
  isTeam: auth.isTeam,
  learner: flinkPlay.learner,
  showAccountButton:
    flinkPlay.family.isCustomer || flinkPlay.family.isPrepaidCustomer,
  solutions: flinkPlay.product.solutions,
  quickStartVideos: config.loginPageDocuments.quickStart || {},
});

export default connect(mapStateToProps, /* { viewDocumentById } */)(
  SettingsBigButtons
);

const Button = ({ title, icon, off, ...props }) => {
  return (
    <button
      title={title}
      className={classnames(classes.button, { [classes.off]: off })}
      {...props}
    >
      <img src={icon} alt={title} />

      <span>{title}</span>
    </button>
  );
};
