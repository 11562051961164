import Ebook from './Ebook';
import convertOldActivity from './convertOldEbook'; // if needed for converting old activities
import validateActivity from './validateEbook';

export default {
  Template: Ebook,
  convertOldActivity,
  validateActivity,
  optionsArray: ['ebook', 'parentVideos'],
};
