import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { MaterialFaIcon } from 'utils';
import styled from 'styled-components';
import _ from 'lodash';

const customSelectStyles = {
  container: base => ({
    ...base,
    width: '200px'
  })
};

const SelectOrganization = ({
  label,
  emptyValue,
  organizations,
  selectedId,
  changeSelectedHandler,
  editLink
}) => {
  let organizationsOptions = useMemo(
    () =>
      organizations.map(organization => ({
        label: organization.name,
        value: organization._id
      })),
    [organizations]
  );

  const emptyOption = { label: emptyValue, value: '' };

  if (emptyValue) {
    organizationsOptions = [emptyOption, ...organizationsOptions];
  }

  const selectedOption = _.find(organizationsOptions, { value: selectedId });

  return (
    <Fragment>
      {label && <StyledLabel>{label}</StyledLabel>}
      <Container>
        <Select
          styles={customSelectStyles}
          options={organizationsOptions}
          onChange={option => changeSelectedHandler(option.value)}
          value={selectedOption || ''}
          defaultValue={emptyValue ? emptyOption : undefined}
        />
        {selectedId && (
          <Link to={`${editLink}/${selectedId}`}>
            <Tooltip title="Edit">
              <IconButton aria-label="Edit">
                <MaterialFaIcon icon="pencil-alt" />
              </IconButton>
            </Tooltip>
          </Link>
        )}
      </Container>
    </Fragment>
  );
};

SelectOrganization.propTypes = {
  organizations: PropTypes.array,
  selectedId: PropTypes.string,
  changeSelectedHandler: PropTypes.func.isRequired,
  editLink: PropTypes.string,
  label: PropTypes.string
};

export default SelectOrganization;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledLabel = styled.p`
  margin-top: 2rem;
  margin-bottom: .5rem;
  font-weight: bold;
`;
