import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleAudio, stopAudio } from 'actions/audioActions';
import { imagesURL } from 'config';

export class PlayAudioButton extends Component {
  clickHandler = e => {
    e.preventDefault();

    const { beforeHandler, url } = this.props;

    if (typeof beforeHandler === 'function') {
      beforeHandler(e);
    }

    toggleAudio(url);
  };

  componentWillUnmount() {
    const { src } = this.props;
    stopAudio(src);
  }

  render() {
    const { className, disabled } = this.props;

    return (
      <button
        disabled={disabled}
        className={className}
        onClick={this.clickHandler}
      >
        <img src={`${imagesURL}/Images/Audio/audio_play.png`} alt="" />
      </button>
    );
  }
}

PlayAudioButton.defaultProps = {
  className: '',
  disabled: false
};

PlayAudioButton.propTypes = {
  url: PropTypes.string.isRequired,
  beforeHandler: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  // From redux
  audio: PropTypes.object
};

const mapStateToProps = ({ audio }) => ({ audio: audio.audio });

export default connect(mapStateToProps)(PlayAudioButton);
