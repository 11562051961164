import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withData, withTranslate } from 'components/hocs';
import { renderSelect } from 'components/form-components';
import { getAnimationsList } from 'actions/solutionsActions';

const LoginPageDocumentsControls = ({
  locales,
  documents,
  translate,
  name,
  title,
  isQuickStart,
  getAnimationsList,
}) => {
  const [videosOptions, setVideosOptions] = useState({});
  useEffect(() => {
    if (!isQuickStart) return;
    getAnimationsList(['QuickStart']).then((result) => {
      const quickStartVideos = Object
        .entries(result.QuickStart)
        .reduce((accumulator, [language, options]) => ({
          ...accumulator,
          [language]: options.map((option) => ({
            label: option.name,
            value: option.filename,
          }))
        }), {});
      setVideosOptions(quickStartVideos);
    });
  }, [getAnimationsList, isQuickStart]);

  const documentsOptions = useMemo(() => {
    return documents?.map((doc) => ({
      label: doc.filename,
      value: doc._id,
    }));
  }, [documents]);

  const getOptionLabel = useCallback((option) => {
    if (isQuickStart)
      return translate(option.label, '', true);
    return option.label;
  }, [translate, isQuickStart]);

  return (
    <div>
      <div style={{ margin: '2rem 0' }}>
        <b>{title}</b>
      </div>

      {documents?.length &&
        locales.map((loc) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 15,
              }}
              key={loc._id}
            >
              <div style={{ width: '80px', marginRight: '2rem' }}>
                <p style={{ textAlign: 'right', margin: 0 }}>
                  {loc.name}
                </p>
              </div>

              <div style={{ width: '200px' }}>
                <Field
                  name={`${name}[${loc.code}]`}
                  label={isQuickStart ? "Videos" : "Document"}
                  component={renderSelect}
                  isClearable
                  isMulti={isQuickStart}
                  options={isQuickStart ? (videosOptions[loc.name] || []) : documentsOptions}
                  getOptionLabel={getOptionLabel}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  locales: state.common.locales,
});

export default compose(
  withTranslate,
  withData(['documents']),
  connect(mapStateToProps, {
    getAnimationsList,
  }),
)(LoginPageDocumentsControls);
