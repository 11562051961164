import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";

import { WhiteBox } from "components/flink-play";
import ItemSlot from "./ItemSlot/ItemSlot";
import classes from "./ItemSlots.module.scss";

const ItemSlots = ({
  refs,
  count,
  items,
  options,
  animation,
  slotClassname,
  fixedSlotClassname,
  isResolved,
  joinOnResolve,
  onDrop,
  animationSpeed
}) => {
  const { delayBeforeNext } = options;

  const delayBeforeExit = delayBeforeNext * 1000 - 1000;

  return (
    <div className={classes.positionedBox}>
      <WhiteBox
        pixels
        outerClass={classes.wrapper}
        innerClass={classes.innerWrapper}
      >
        {_.times(count, idx => {
          const itemInThatBox = _.find(items, { slotIdx: idx });

          return (
            <CSSTransition
              appear
              mountOnEnter
              in={!isResolved}
              key={idx}
              timeout={{
                appear: animationSpeed,
                enter: animationSpeed,
                exit: animationSpeed + delayBeforeExit
              }}
              classNames={{
                appearActive: animation.in,
                enterActive: animation.in,
                // exitActive: animation.out
              }}
            >
              {state => (
                <div
                  className={classnames("animated")}
                  style={{
                    animationDelay:
                      (state === "exiting" ? delayBeforeExit : 0) + "ms",
                    animationDuration: animationSpeed + "ms"
                  }}
                >
                  <ItemSlot
                    first={idx === 0}
                    last={idx === count - 1}
                    isResolved={isResolved}
                    joinOnResolve={joinOnResolve}
                    slotRef={refs[idx]}
                    className={classnames(slotClassname, {
                      [fixedSlotClassname]:
                        itemInThatBox && itemInThatBox.fixed,
                      [classes.fadeIn]: state === "entering",
                      [classes.fadeOut]:
                        state === "exiting" || state === "exited"
                    })}
                    onDropHandler={onDrop}
                    key={idx}
                    item={itemInThatBox}
                    slotIdx={idx}
                  />
                </div>
              )}
            </CSSTransition>
          );
        })}
      </WhiteBox>
    </div>
  );
};

ItemSlots.propTypes = {
  items: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  slotClassname: PropTypes.string.isRequired,
  joinOnResolve: PropTypes.bool
};

export default ItemSlots;
