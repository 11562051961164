const responseHandler = async (request) => {
  try {
    const response = await request;
    return response.data;
  } catch (err) {
    if (err && err.response && err.response.data) {
      return err.response.data;
    } else {
      return { success: false, error: err };
    }
  }
};

export default responseHandler;
