// import PropTypes from 'prop-types';
import useGetStringAudio from 'utils/hooks/useGetStringAudio';

const withGetStringAudio = (WrappedComponent) => {
  const Wrapper = (props) => {
    const getStringAudio = useGetStringAudio();
    return <WrappedComponent {...props} getStringAudio={getStringAudio} />;
  };
  return Wrapper;
};

export default withGetStringAudio;
