import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTranslate } from 'components/hocs';

import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
    setPlayerPowerLevel,
} from 'actions/gameActions';

import classes from './PowerLevelControls.module.scss';

const PowerLevelControls = ({ powerLevels, playerId, setPlayerPowerLevel, translate, solutionTranslate }) => {
    const translateToUse = useMemo(() => {
        return solutionTranslate || translate;
    }, [solutionTranslate, translate]);

    return (
        <Fragment>
            <small>
                {translateToUse(1315, 'Power level')}
            </small>
            <RadioGroup
            name={`power-level-${playerId}`}
            options={[
                {label: '1x', value: '1'},
                {label: '2x', value: '2'},
                {label: '3x', value: '3'},
                {label: '4x', value: '4'},
            ]}
            value={(powerLevels[playerId]).toString()}
            onChange={(_event, value) => setPlayerPowerLevel(playerId, parseInt(value))}
        >

            <Grid container style={{ margin: 0 }}>
                <Grid item xs={6}>
                    <FormControlLabel
                        value='1'
                        control={<Radio size='small' color='default' />}
                        label={
                            <Box component='div' fontSize={12}>
                                1x
                            </Box>
                        }
                        className={classes.smallRadioButton}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        value='3'
                        control={<Radio size='small' color='default' />}
                        label={
                            <Box component='div' fontSize={12}>
                                3x
                            </Box>
                        }
                        className={classes.smallRadioButton}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        value='2'
                        control={<Radio size='small' color='default' />}
                        label={
                            <Box component='div' fontSize={12}>
                                2x
                            </Box>
                        }
                        className={classes.smallRadioButton}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        value='4'
                        control={<Radio size='small' color='default' />}
                        label={
                            <Box component='div' fontSize={12}>
                                4x
                            </Box>
                        }
                        className={classes.smallRadioButton}
                    />
                </Grid>
            </Grid>
        </RadioGroup>
      </Fragment>
    );
};

PowerLevelControls.propTypes = {
    playerId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ game, flinkPlay: { solutionTranslate } }) => ({
    powerLevels: game.powerLevels,
    solutionTranslate,
});

export default compose(
    withTranslate,
    connect(mapStateToProps, {
        setPlayerPowerLevel,
    })
)(PowerLevelControls);
