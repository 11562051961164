import styled from 'styled-components';

const FieldError = (props) => {
  return <StyledError {...props} />;
};

export default FieldError;

const StyledError = styled.p`
  font-size: 1.2rem;
  margin-top: 2px;
  margin-bottom: 0;
  color: #df2a26;
`;
