import React from "react";
import PropTypes from "prop-types";

import { times } from "lodash";
import classes from "./PagesListBox.module.scss";

const PagesListBox = ({ pagesCount = 12, activePage, onChange }) => {
  return (
    <ul className={classes.list}>
      {times(pagesCount).map(i => (
        <li
          onClick={onChange.bind(null, i)}
          className={activePage === i ? classes.active : ""}
          key={i}
        >
          {i + 1}
        </li>
      ))}
    </ul>
  );
};

PagesListBox.propTypes = {
  pagesCount: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default PagesListBox;
