import SentenceConnectables from './SentenceConnectables';
import convertOldActivity from './convertOldSentenceConnectables';
import validateActivity from './validateSentenceConnectables';

export default {
  Template: SentenceConnectables,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'fontFamily',
    'fontColor',
    'fontSize',
    'randomOrder',
    'showAnswer',
    'questionsLimit',
    'audioBefore'
  ]
};
