import shortid from 'shortid';

import { addIdToFilename } from 'utils';

export const validateProblem = (problem) => {
  if (!(problem.definition || problem.audio || problem.image)) {
    return false;
  }

  if (problem.useName && problem.useNameMode) return true;

  return !!(problem.word && problem.letters.indexOf('0') !== -1);
};

export const createProblem = () => ({
  id: shortid.generate(),
  options: [],
  letters: '',
  useName: false,
  useNameMode: ''
});

export const getContentFilesFromProblem = (problem) => {
  const files = [];
  const { audio, image } = problem;

  audio && files.push(audio);
  image && files.push(image);

  return files;
};

export const validateWords = (words, templateDBrecord) => {
  return { success: true, filteredWords: words };
};

export const generateActivityFromWords = ({
  words,
  includeDefinitionAudio,
}) => {
  const definitionsAudio = [];

  const problems = words.map((w) => {
    let audio = null;

    if (includeDefinitionAudio) {
      audio = w.definitionAudio && addIdToFilename(w.definitionAudio);

      audio &&
        definitionsAudio.push({
          src: w.definitionAudio,
          filename: audio,
        });
    }

    const letters = w.word.replace(/\S/, '0').replace(/\s/, '1');

    return {
      id: shortid.generate(),
      word: w.word,
      options: [],
      definition: w.definition,
      letters,
      audio,
    };
  });

  const gameData = { problems };

  return { gameData, definitionsAudio };
};
