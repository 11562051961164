import { TEACHER } from "consts/user-roles";

export default auth => {
  if (auth.user?.roleAlias === TEACHER && auth.cleverLogin) {
    return '/flink-admin'; // take clever teacher directly to the report app
  }

  if (auth.user && auth.user.isTempPassword) {
    return '/admin/my-account';
  }

  if (auth.learner) {
    return '/play';
  }

  switch (auth.userRole.redirect) {
    case 'flinkMake':
      return '/flink-make';
    case 'flinkAdmin':
      return '/flink-admin';
    case 'adminPages':
      return '/admin/my-account';
    default:
      return '/admin/my-account';
  }
};
