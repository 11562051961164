import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faBook,
  faArrowAltCircleDown,
  faDatabase,
  faChalkboardTeacher,
  faUsers,
  faUsersCog,
  faUserCheck,
  faEye,
  faWrench,
  faFileAlt,
  faList,
  faGlobe,
  faFont,
  faGamepad,
  faChild,
  faPalette,
  faSchool,
  faCity,
  faGraduationCap,
  faBriefcase,
  faLayerGroup,
  faFilePdf,
  faPencilAlt,
  faTrashAlt,
  faSave,
  faEraser,
  faBan,
  faCheck,
  faFolderOpen,
  faBars,
  faUserCircle,
  faAngleDown,
  faUserTie,
  faAngleUp,
  faTools,
  faKeyboard,
  faFileSignature,
  faSpellCheck,
  faBuilding,
  faClipboardList,
  faCogs,
  faColumns,
  faDownload,
  faCopy,
  faGift,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBook,
  faUserTie,
  faArrowAltCircleDown,
  faDatabase,
  faChalkboardTeacher,
  faUsers,
  faUsersCog,
  faUserCheck,
  faEye,
  faWrench,
  faFileAlt,
  faList,
  faGlobe,
  faFont,
  faGamepad,
  faChild,
  faPalette,
  faSchool,
  faCity,
  faGraduationCap,
  faBriefcase,
  faLayerGroup,
  faFilePdf,
  faPencilAlt,
  faTrashAlt,
  faSave,
  faEraser,
  faBan,
  faCheck,
  faFolderOpen,
  faBars,
  faUserCircle,
  faAngleDown,
  faAngleUp,
  faTools,
  faKeyboard,
  faFileSignature,
  faSpellCheck,
  faBuilding,
  faClipboardList,
  faCogs,
  faColumns,
  faDownload,
  faCopy,
  faGift,
);
