import _ from 'lodash';
import {
  prepareMultiplayerState,
} from 'actions/gameActions';

import {
  SHOW_FEEDBACK,
  CLOSE_FEEDBACK,
  SHOW_STATS,
  SET_CURRENT_ACTIVITY,
  OPEN_ACTIVITY_HELP,
  CLOSE_ACTIVITY_HELP,
  GAME_INITIALIZED,
  RESTART_ACTIVITY,
  SHOW_GAME_PARENT_ADVICE,
  SHOW_GAME_MULTIPLAYER_ADVICE,
  OPEN_TEAM_ACTIVITY_HELP,
  CLOSE_TEAM_ACTIVITY_HELP,
  ADD_IDLE_TIME,
  CHANGE_PLAYER_TURN,
  INCREMENT_PLAYER_POINTS,
  SET_PLAYER_POWER_LEVEL,
} from 'actions/types';

const INITIAL_STATE = {
  isPreview: false,
  goBackHandler: null,
  currentActivity: null,
  shouldGameInit: false,
  initialTeamActivityHelpShown: false,
  isTeamActivityHelpOpened: false,
  initialActivityHelpShown: false,
  isActivityHelpOpened: false,
  stats: null,
  gameParentAdvice: false,
  gameMultiplayerAdvice: false,
  numberOfPlays: 0,
  gameInitialized: false,
  feedback: null,
  idleTime: 0,
  players: [],
  multiplayerModeEnabled: false,
  turningPlayerId: null,
  points: {},
  powerLevels: {},
};

const filter = 'game';

export default function (state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_CURRENT_ACTIVITY:
      const { payload } = action;

      if (!payload) return INITIAL_STATE;

      return {
        ...INITIAL_STATE,
        ...payload,
      };
    case RESTART_ACTIVITY: {
      const newState = {
        ...INITIAL_STATE,
        isPreview: state.isPreview,
        goBackHandler: state.goBackHandler,
        currentActivity: { ...state.currentActivity },
        gameStartedAt: state.gameStartedAt,
        numberOfPlays: state.numberOfPlays,
        idleTime: state.idleTime,
        players: state.players,
        multiplayerModeEnabled: state.multiplayerModeEnabled,
      };

      if (state.multiplayerModeEnabled) {
        Object.assign(
          newState, 
          prepareMultiplayerState(state.players),
        );
      };

      return newState;
    }
    case GAME_INITIALIZED:
      return {
        ...state,
        gameInitialized: true,
        gameStartedAt: state.gameStartedAt || Date.now(),
        numberOfPlays: state.numberOfPlays + 1,
      };
    case OPEN_ACTIVITY_HELP:
      return { ...state, isActivityHelpOpened: true };
    case OPEN_TEAM_ACTIVITY_HELP:
      return { ...state, isTeamActivityHelpOpened: true };
    case SHOW_FEEDBACK:
      return { ...state, feedback: action.payload };
    case CLOSE_FEEDBACK:
      return { ...state, feedback: null };
    case SHOW_STATS:
      return { ...state, stats: action.payload };
    case CLOSE_ACTIVITY_HELP:
      if (!state.initialActivityHelpShown) {
        return {
          ...state,
          isActivityHelpOpened: false,
          initialActivityHelpShown: true,
          shouldGameInit: true,
        };
      } else {
        return {
          ...state,
          isActivityHelpOpened: false,
        };
      }
    case CLOSE_TEAM_ACTIVITY_HELP:
      if (!state.initialTeamActivityHelpShown) {
        return {
          ...state,
          isTeamActivityHelpOpened: false,
          initialTeamActivityHelpShown: true,
          shouldGameInit: true,
        };
      } else {
        return {
          ...state,
          isTeamActivityHelpOpened: false,
        };
      }
    case SHOW_GAME_PARENT_ADVICE:
      return {
        ...state,
        gameParentAdvice: action.payload,
        gameMultiplayerAdvice: false,
      };
    case SHOW_GAME_MULTIPLAYER_ADVICE:
      return {
        ...state,
        gameMultiplayerAdvice: action.payload,
        gameParentAdvice: false,
      };
    case ADD_IDLE_TIME:
      return {
        ...state,
        idleTime: state.idleTime + action.payload,
      };
    default:
      return state;
    case CHANGE_PLAYER_TURN: {
      const { players, turningPlayerId } = state;

      let nextPlayerIndex = players.indexOf(_.find(players, { _id: turningPlayerId })) + 1;
      const maxIndex = players.length - 1;

      if (nextPlayerIndex > maxIndex) {
        nextPlayerIndex = 0;
      }

      return {
        ...state,
        turningPlayerId: players[nextPlayerIndex]._id,
      };
    };
    case INCREMENT_PLAYER_POINTS: {
      const { turningPlayerId, powerLevels } = state;
      if (turningPlayerId === null) {
        console.log('incremented points of a player (no current turn)');
        return state;
      }

      const { numGuesses } = action.payload;
      console.log(`Player has guessed the right answer in ${numGuesses} tries`);

      const pointsMax = 500;
      const pointsPenalty = numGuesses > 0 ?
        (100 * _.clamp(numGuesses, 1, 4)) :
        0;
      
      // ^
      // for each missed guess subtract 100 pts,
      // also clamping to 4 max because player needs to get minimum of 100 points

      const pointMultiplier = powerLevels[turningPlayerId] ?? 1;

      const playerPoints = state.points[turningPlayerId];
      const pointsGain = (pointsMax - pointsPenalty) * pointMultiplier;

      return {
        ...state,
        points: {
          ...state.points,
          [turningPlayerId]: playerPoints + pointsGain,
        },
      };
    };
    case SET_PLAYER_POWER_LEVEL: {
      const { id, powerLevel } = action.payload;
      return {
        ...state,
        powerLevels: {
          ...state.powerLevels,
          [id]: powerLevel,
        },
      };
    };
  }
}
