import React from 'react';
import styled, { css } from 'styled-components';

export default ({
  input,
  label,
  children,
  options = [],
  meta: { touched, error, dirty },
  width
}) => (
  <StyledInputGroup>
    <label>
      <StyledLabel error={error} touched={touched} dirty={dirty}>
        {label}
      </StyledLabel>
      <StyledSelect
        width={width}
        {...input}
        error={error}
        touched={touched}
        dirty={dirty}
      >
        <option />
        {children ||
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </StyledSelect>
    </label>
    {touched && error && <StyledError>{error}</StyledError>}
  </StyledInputGroup>
);

const StyledInputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const StyledLabel = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;

  ${({ touched, error, dirty }) =>
    touched &&
    (error
      ? css`
          color: #df2a26;
        `
      : dirty &&
        css`
          color: green;
        `)};
`;

const StyledError = styled.p`
  font-size: 1.2rem;
  margin-top: 2px;
  margin-bottom: 0;
  color: #df2a26;
`;

const StyledSelect = styled.select`
  display: block;
  height: 3.8rem;
  padding: 0.6rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  width: ${props => (props.width ? props.width : '100%')};

  ${({ touched, error, dirty }) =>
    touched &&
    (error
      ? css`
          border-color: #df2a26;
          outline-color: #df2a26;
        `
      : dirty &&
        css`
          border-color: green;
          outline-color: green;
        `)};
`;
