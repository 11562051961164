import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Draggable from '../Draggable/Draggable';
import classes from './DraggableModal.module.scss';

const DraggableModal = ({
  noGutters,
  title,
  onClose,
  show,
  children,
  ...otherProps
}) => {
  return (
    show && (
      <Draggable
        resizeHandleClasses="draggable-modal"
        dragHandleClassName="draggable-bar"
        onClose={onClose}
        {...otherProps}
      >
        <div
          className={classnames('draggable-modal', classes['modal'], {
            [classes['no-gutters']]: noGutters,
          })}
        >
          <div className={`draggable-bar ${classes['bar']}`}>
            {title}
            <button
              className={classes['close-btn']}
              title="Close"
              onClick={onClose}
            >
              &times;
            </button>
          </div>

          <div className={`draggable-content ${classes['content']}`}>
            {children}
          </div>
        </div>
      </Draggable>
    )
  );
};

DraggableModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DraggableModal;
