import ReadingDeluxe from "./ReadingDeluxe";
import convertOldActivity from "./convertOldReadingDeluxe"; // if needed for converting old activities
import validateActivity from "./validateReadingDeluxe";

export default {
  Template: ReadingDeluxe,
  convertOldActivity,
  validateActivity,
  optionsArray: []
};
