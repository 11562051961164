import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DatePicker } from 'components/admin';
import classes from './FromToPicker.module.scss';

const FromToPicker = ({
  translate,
  startDate,
  endDate,
  changeHandler,
  disabled,
}) => {
  return (
    <div className={classes.datePickers}>
      <div>
        <label>{translate(702, 'From:')}</label>

        <div>
          <DatePicker
            disabled={disabled}
            selected={startDate}
            onChange={(date) => {
              if (date) {
                date.setHours(0, 0, 0, 0);
              }
              changeHandler({ startDate: date, endDate });
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate || new Date()}
          />
        </div>
      </div>
      <div>
        <label>{translate(703, 'To:')}</label>

        <div>
          <DatePicker
            disabled={disabled}
            selected={endDate}
            onChange={(date) => {
              if (date) {
                date.setHours(23, 59, 59, 999);
                date.setTime(
                  date.getTime() + date.getTimezoneOffset() * 60 * 1000
                );
              }
              changeHandler({ startDate, endDate: date });
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
          />
        </div>
      </div>
    </div>
  );
};

FromToPicker.propTypes = {
  translate: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

const mapStateToProps = ({ status: { translate } }) => ({ translate });

export default connect(mapStateToProps)(FromToPicker);
