import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";
import shortid from "shortid";

import { getFileByUrl } from "utils";
import WritingAssistantEditorToolbar from "./WritingAssistantEditorToolbar";
import { EDITOR_WIDTH } from "../writingAssistantHelpers";
import { imagesURL } from "config";
import "./WritingAssistantEditor.scss";
import { Clipart } from "components/flink-components";
import { WritingFeedbackButton } from "components/flink-play";
import { Portal } from "components/common";

const templateGraphicsFolder = `${imagesURL}/TemplateGraphics/WritingAssistant/`;

const formats = [
  "align",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "width",
  "list",
  "bullet",
  "indent",
  "color",
  "background",
  "style",
  "imageResize",
  "image"
];

const availableFonts = [
  "Arial",
  "Arial Black",
  "Bookman",
  "Comic Sans MS",
  "Courier",
  "Courier New",
  "Garamond",
  "Georgia",
  "Helvetica",
  "Impact",
  "Palatino",
  "Roboto",
  "Times New Roman",
  "Times",
  "Tahoma",
  "Verdana"
];

const generateBlockClass = Prototype => {
  class CustomBlock extends Prototype {
    constructor(domNode, value) {
      super(domNode, value);
      this.format("size", "18px");
    }

    static tagName = "P";

    format(name, value) {
      if (name === "size") {
        this.domNode.style.fontSize = value;
      } else {
        super.format(name, value);
      }
    }
  }

  Quill.register(CustomBlock, true);
};

class WritingAssistantEditor extends Component {
  constructor(props) {
    super(props);

    Quill.register(Quill.import("attributors/style/background"), true);
    Quill.register(Quill.import("attributors/style/color"), true);
    Quill.register(Quill.import("attributors/style/align"), true);

    const fonts = Quill.import("attributors/style/font");
    fonts.whitelist = availableFonts;
    Quill.register(fonts, true);

    const sizes = Quill.import("attributors/style/size");
    delete sizes.whitelist;
    Quill.register(sizes, true);

    this.Block = Quill.import("blots/block");
    generateBlockClass(this.Block);

    this.ImageFormat = Quill.import("formats/image");

    // Custom icon for import art
    const icons = Quill.import("ui/icons");
    delete icons.image; // unset default image icon

    class CustomImageFormat extends this.ImageFormat {
      static create(value) {
        const node = super.create(value);
        node.style.display = "block";
        node.style.margin = "0 auto";
        node.style.maxWidth = "100%";
        return node;
      }
    }
    Quill.register(CustomImageFormat, true);

    const toolbarID = shortid.generate();

    const formatsToUse = [...formats];

    const modules = {
      clipboard: {
        matchers: [["image", null]],
        matchVisual: false
      },
      toolbar: {
        container: `[data-toolbar-id="${toolbarID}"]`
      }
      // imageResize: {
      //   parchment: Quill.import("parchment")
      // }
    };

    this.state = {
      editorHtml: "",
      toolbarID,
      formats: formatsToUse,
      modules,
      showClipart: false
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;

    if (initialValue) {
      this.setState({
        editorHtml: initialValue
      });
    }
  }

  componentWillUnmount() {
    Quill.register(this.ImageFormat, true);
  }

  editorRef = React.createRef();

  getEditor = () => {
    const editor = this.editorRef.current && this.editorRef.current.getEditor();
    return editor;
  };

  getText = () => {
    const editor = this.getEditor();
    return editor.getText();
  };

  pasteHtml = html => {
    const { saveChanges } = this.props;
    const editor = this.getEditor();
    const range = editor.getSelection(true);
    editor.clipboard.dangerouslyPasteHTML(range.index, html);

    saveChanges && setTimeout(saveChanges, 1);
  };

  insertImageByUrl = url => {
    getFileByUrl(url).then(file => {
      this.insertImage(file);
    });
  };

  insertImage = file => {
    const { saveChanges } = this.props;
    const editor = this.getEditor();
    const range = editor.getSelection(true);

    const Delta = Quill.import("delta");

    const reader = new FileReader();
    reader.onload = e => {
      editor.updateContents(
        new Delta()
          .retain(range.index)
          .delete(range.length)
          .insert({ image: e.target.result }),
        Quill.sources.USER
      );

      editor.setSelection(range.index + 1, Quill.sources.SILENT);

      saveChanges && setTimeout(saveChanges, 1);
    };

    reader.readAsDataURL(file);
  };

  insertWord = word => {
    const editor = this.getEditor();
    const range = editor.getSelection(true);

    editor.insertText(range.index, " " + word);
    editor.setSelection(range.index + word.length + 1);
  };

  handleChange = html => {
    const { editorHtml } = this.state;
    if (editorHtml === html) return;

    this.setState({ editorHtml: html });
    this.props.changeHandler(html);
  };

  toggleClipart = state => {
    this.setState({ showClipart: state });
  };

  getWritingAssistantContent = () => {
    const editor = this.editorRef.current.getEditor();
    return editor.getText();
  }

  render() {
    const { editorHtml, toolbarID, modules, formats, showClipart } = this.state;
    const {
      words,
      images,
      reading,
    } = this.props;

    const clipartImg = templateGraphicsFolder + "clipArtLibrary.png";
    const importArtImg = templateGraphicsFolder + "importArt.png";

    const editorStyle = {
      width: EDITOR_WIDTH + "px"
    };

    const hasWords = !!(words && words.length);
    const hasImages = !!(images && images.length);

    return (
      <div className="writing-assistant-editor">
        <WritingAssistantEditorToolbar
          id={toolbarID}
          clipartButton={
            <button onClick={() => this.toggleClipart(true)}>
              <img src={clipartImg} alt="" />
            </button>
          }
          importArtButton={
            <button className="ql-image">
              <img src={importArtImg} alt="" />
            </button>
          }
          writingFeedbackButton={
            <WritingFeedbackButton getContent={this.getWritingAssistantContent} />
          }
        />

        {hasWords && (
          <div className="wordsWrapper">
            {words.map((w, idx) => (
              <span key={idx} onClick={() => this.insertWord(w)}>
                {w}
              </span>
            ))}
          </div>
        )}

        {hasImages && (
          <div className="imagesWrapper">
            {images.map((src, idx) => (
              <span
                style={{ backgroundImage: `url("${src}")` }}
                key={idx}
                onClick={() => this.insertImageByUrl(src)}
              >
                {/* <img src={src} alt="" /> */}
              </span>
            ))}
          </div>
        )}

        {reading && (
          <ReactQuill
            style={editorStyle}
            theme="snow"
            value={reading}
            readOnly
            modules={{ toolbar: null }}
            formats={formats}
            className={`reading-area ${hasImages ? "small" : ""}`}
            preserveWhitespace
          />
        )}

        <ReactQuill
          ref={this.editorRef}
          style={editorStyle}
          theme="snow"
          onChange={this.handleChange}
          value={editorHtml}
          modules={modules}
          formats={formats}
          className="editor"
          preserveWhitespace
        />

        <Portal>
          <Clipart
            returnFile
            show={showClipart}
            onClose={() => this.toggleClipart(false)}
            onPick={file => {
              this.toggleClipart(false);
              this.insertImage(file);
            }}
          />
        </Portal>
      </div>
    );
  }
}

WritingAssistantEditor.propTypes = {
  reading: PropTypes.string,
  images: PropTypes.array,
  words: PropTypes.array,
  initialValue: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default WritingAssistantEditor;
