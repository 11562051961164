import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import toggleAssign from './toggleAssign';

const ScopeAssign = ({ data, classes, changeHandler }) => {
  const {
    firstCategory,
    secondCategory,
    thirdCategory,
    grades,
    levels,
    firstMenu
  } = data;
  const [selected, setSelected] = useState({});
  const menuLevels = firstMenu === 'levels' ? levels : grades;

  const secondCategoryList = useMemo(() => {
    const list = _.find(secondCategory.lists, {
      firstCategoryId: selected.firstCategory
    });

    return list || { items: [] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, data]);

  const thirdCategoryList = useMemo(() => {
    const list = _.find(thirdCategory.lists, {
      firstCategoryId: selected.firstCategory,
      secondCategoryId: selected.secondCategory
    });

    return list || { items: [] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, data]);

  return (
    <div className={classes.columnsWrapper}>
      <div className={classes.firstColumn}>
        <span className={classes.colTitle}>{_.capitalize(firstMenu)}:</span>
        <List className={classes.list}>
          {menuLevels.map(level => (
            <ListItem
              key={level}
              classes={{ selected: classes.selectedItem }}
              dense
              button
              selected={level === selected.menuLevel}
              onClick={() => setSelected({ ...selected, menuLevel: level })}
            >
              {level}
            </ListItem>
          ))}
        </List>
      </div>

      <div className={classes.column}>
        <span className={classes.colTitle}>{firstCategory.title}:</span>
        <List className={classes.list}>
          {firstCategory.items.map(item => (
            <ListItem
              dense
              button
              key={item.id}
              classes={{ selected: classes.selectedItem }}
              selected={item.id === selected.firstCategory}
              onClick={() =>
                setSelected({ ...selected, firstCategory: item.id })
              }
            >
              <Checkbox
                classes={{ root: classes.checkbox }}
                checked={
                  !!(
                    item[firstMenu] &&
                    item[firstMenu].includes(selected.menuLevel)
                  )
                }
                onClick={e =>
                  toggleAssign(
                    data,
                    item.id,
                    selected,
                    'firstCategory',
                    e.target.checked,
                    changeHandler
                  )
                }
              />
              {item.title}
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.column}>
        <span className={classes.colTitle}>{secondCategory.title}:</span>
        <List className={classes.list}>
          {secondCategoryList.items.map(item => (
            <ListItem
              key={item.id}
              classes={{ selected: classes.selectedItem }}
              dense
              button
              selected={item.id === selected.secondCategory}
              onClick={() =>
                setSelected({ ...selected, secondCategory: item.id })
              }
            >
              <Checkbox
                classes={{ root: classes.checkbox }}
                checked={
                  !!(
                    item[firstMenu] &&
                    item[firstMenu].includes(selected.menuLevel)
                  )
                }
                onClick={e =>
                  toggleAssign(
                    data,
                    item.id,
                    selected,
                    'secondCategory',
                    e.target.checked,
                    changeHandler
                  )
                }
              />
              {item.title}
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.column}>
        <span className={classes.colTitle}>{thirdCategory.title}:</span>
        <List className={classes.list}>
          {thirdCategoryList.items.map(item => (
            <ListItem dense key={item.id}>
              <Checkbox
                classes={{ root: classes.checkbox }}
                checked={
                  !!(
                    item[firstMenu] &&
                    item[firstMenu].includes(selected.menuLevel)
                  )
                }
                onClick={e =>
                  toggleAssign(
                    data,
                    item.id,
                    selected,
                    'thirdCategory',
                    e.target.checked,
                    changeHandler
                  )
                }
              />
              {item.title}
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

ScopeAssign.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object
};

export default ScopeAssign;
