import React, { useEffect, useState } from 'react';
import API from 'api';
import { connect } from 'react-redux';
import _ from 'lodash';

import { filesURL } from 'config';
import classes from './CharacterAnimation.module.scss';

const imgPrefix = 'Files/animations/ActivityResults/';

const CharacterAnimation = ({
  game,
  settings,
  characters,
  onFinish,
  onNoCharacter,
  onReady,
  onError,
  duration = 4000,
}) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    const setSrcAndTimeout = (src) => {
      setSrc(src);

      setTimeout(() => {
        onFinish && onFinish();
      }, duration);
    };

    const fetch = async (character) => {
      const src = imgPrefix + character.toLowerCase() + '.gif';
      const isExists = await API.storage.checkIfFileExists(src);

      if (isExists) {
        setSrcAndTimeout(src);
        return true;
      } else {
        return false;
      }
    };

    const init = async () => {
      const DEFAULT_CHARACTER = 'Fletcher';
      const { isPreview } = game;

      let character;

      if (settings && characters) {
        const { bySolution, selectedSolutionId } = settings;
        const selectedCharacterId =
          bySolution &&
          bySolution[selectedSolutionId] &&
          bySolution[selectedSolutionId].character;

        if (selectedCharacterId) {
          character = _.find(characters, {
            _id: selectedCharacterId,
          });

          character = character && character.name;
        }
      } else if (isPreview) {
        character = DEFAULT_CHARACTER;
      }

      if (!character) {
        return onNoCharacter && onNoCharacter();
      }

      // Try fetch user's character animation
      let isSuccess = await fetch(character);

      if (isSuccess) {
        onReady && onReady();
        return;
      }

      // Try to fetch default character "fletcher.gif"
      isSuccess = await fetch(DEFAULT_CHARACTER);

      if (isSuccess) {
        onReady && onReady();
        return;
      }

      // Character animation not exists
      onError && onError();
    };

    init();
  }, [
    game,
    settings,
    characters,
    onFinish,
    onNoCharacter,
    onError,
    onReady,
    duration,
  ]);

  if (!src) return null;

  return (
    <div className={classes.wrapper}>
      <img src={`${filesURL}/${src}`} alt="" />
    </div>
  );
};

const mapStateToProps = ({
  flinkPlay: { solution, characters, learner },
  game,
}) => ({
  game,
  settings: learner && learner.settings,
  solution,
  characters,
});

export default connect(mapStateToProps)(CharacterAnimation);
