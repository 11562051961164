import { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQuery } from 'react-query';

import { DEFAULT, PREPAID } from 'consts/plan-types';
import classes from './ProductsField.module.scss';
import api from 'api';

const DEFAULT_PREPAID_CONFIG = {
  totalHours: 0,
  hoursInIncrement: 0,
  costPerHour: 0,
  discountPercents: 0,
};

const generateDefaultPurchase = (productId) => {
  return {
    productId,
    plans: 0,
    addons: 0,
    prepaidConfig: DEFAULT_PREPAID_CONFIG,
  };
};

const ProductsField = ({
  products = [],
  value = [],
  onChange,
  usingPlans = {},
  disabled,
  plansType = DEFAULT,
  assignedProducts = {},
  showOnlyPurchased = false,
}) => {
  const { data } = useQuery('currencies', api.currencies.getList, {
    staleTime: Infinity,
  });

  const currencies = useMemo(() => {
    if (!data) return [];
    return data.data;
  }, [data]);

  const changePurchase = (productId, updates) => {
    const purchasedProduct =
      _.find(value, {
        productId,
      }) || generateDefaultPurchase(productId);

    const changedPurchasedProduct = {
      ...purchasedProduct,
      ...updates,
    };

    const updatedValue = _.reject(value, { productId });
    const isProductHavePlans =
      changedPurchasedProduct.plans || changedPurchasedProduct.unlimited;

    if (isProductHavePlans) {
      updatedValue.push(changedPurchasedProduct);
    }

    onChange(updatedValue);
  };

  return (
    <div>
      {products.map((product) => {
        let purchasedProduct = _.find(value, { productId: product._id });

        if (showOnlyPurchased && !purchasedProduct) {
          return null;
        }

        purchasedProduct =
          purchasedProduct || generateDefaultPurchase(product._id);

        const assignedProduct = assignedProducts[product._id] || {};
        const usingProduct = usingPlans[product._id] || {};

        const plansPurchased = purchasedProduct.plans;
        const plansAvailable = plansPurchased - (usingProduct.plans || 0);
        const plansAssigned = assignedProduct.plans || 0;
        const plansAssignable = plansPurchased - plansAssigned || 0;

        // const addonsPurchased = purchasedProduct.addons
        // const addonsAvailable =
        //   addonsPurchased - ((usingProduct && usingProduct.addons) || 0);
        // const addonsAssigned = (assignedProduct && assignedProduct.addons) || 0;
        // const addonsAssignable = addonsPurchased - addonsAssigned || 0;

        const changePurchasedPlans = (e) => {
          let newVal = +e.target.value;

          // Dont let to purchase less than already assigned to regions and learning centers
          if (newVal < plansAssigned) {
            alert(
              'You cant purchase less than already assigned to regions and learning centers'
            );
            newVal = plansAssigned;
          }

          changePurchase(product._id, { plans: newVal });
        };

        const prepaidConfig =
          purchasedProduct.prepaidConfig || DEFAULT_PREPAID_CONFIG;

        let totalHourPrice =
          prepaidConfig.totalHours * prepaidConfig.costPerHour;

        totalHourPrice = isNaN(totalHourPrice) ? 0 : totalHourPrice.toFixed(2);

        return (
          <div className={classes.wrapper} key={product._id}>
            <b className={classes.productName}>{product.name}</b>

            <div className={classes.planColumn}>
              <div className={classes.fieldGroup}>
                <label>
                  <input
                    type="checkbox"
                    disabled={disabled}
                    checked={purchasedProduct.unlimited || false}
                    onChange={(e) => {
                      changePurchase(product._id, {
                        unlimited: e.target.checked,
                      });
                    }}
                  />
                  Unlimited Plans
                </label>
              </div>

              {!purchasedProduct.unlimited && (
                <>
                  <div className={classes.fieldGroup}>
                    <label>Plans Purchased:</label>
                    <input
                      type="number"
                      min={plansAssigned}
                      disabled={disabled}
                      value={plansPurchased}
                      onChange={changePurchasedPlans}
                    />
                  </div>
                  <div className={classes.fieldGroup}>
                    <label>Plans assigned:</label>
                    <input disabled type="number" value={plansAssigned} />
                  </div>
                  <div className={classes.fieldGroup}>
                    <label>Plans Assignable:</label>
                    <input disabled type="number" value={plansAssignable} />
                  </div>
                  <div className={classes.fieldGroup}>
                    <label>Plans Available:</label>
                    <input disabled type="number" value={plansAvailable} />
                  </div>
                </>
              )}
            </div>

            {plansType === PREPAID && (
              <div className={classes.planColumn}>
                <div className={classes.fieldGroup}>
                  <label>Total Hours:</label>
                  <input
                    disabled={plansPurchased === 0}
                    min="0"
                    step="0.01"
                    type="number"
                    value={prepaidConfig.totalHours}
                    onChange={(e) =>
                      changePurchase(product._id, {
                        prepaidConfig: {
                          ...prepaidConfig,
                          totalHours: e.target.valueAsNumber || 0,
                        },
                      })
                    }
                  />
                </div>
                <div className={classes.fieldGroup}>
                  <label>Increments:</label>
                  <input
                    disabled={plansPurchased === 0}
                    type="number"
                    min="1"
                    max={prepaidConfig.totalHours}
                    value={prepaidConfig.hoursInIncrement}
                    onChange={(e) =>
                      changePurchase(product._id, {
                        prepaidConfig: {
                          ...prepaidConfig,
                          hoursInIncrement: e.target.valueAsNumber || 0,
                        },
                      })
                    }
                  />
                </div>

                <div className={classes.fieldGroup}>
                  <label>Currency:</label>
                  <select
                    value={prepaidConfig.currencyIsoCode}
                    onChange={(e) =>
                      changePurchase(product._id, {
                        prepaidConfig: {
                          ...prepaidConfig,
                          currencyIsoCode: e.target.value,
                        },
                      })
                    }
                    disabled={plansPurchased === 0}
                  >
                    <option value="" />

                    {currencies.map((c) => (
                      <option key={c.isoCode} value={c.isoCode}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={classes.fieldGroup}>
                  <label>Cost per hour:</label>
                  <input
                    disabled={plansPurchased === 0}
                    type="number"
                    min="0"
                    step="0.01"
                    value={prepaidConfig.costPerHour}
                    onChange={(e) =>
                      changePurchase(product._id, {
                        prepaidConfig: {
                          ...prepaidConfig,
                          costPerHour: e.target.valueAsNumber || 0,
                        },
                      })
                    }
                  />
                </div>
                <div className={classes.fieldGroup}>
                  <label>Total Hour Price:</label>
                  <input
                    disabled
                    readOnly
                    type="number"
                    value={totalHourPrice}
                  />
                </div>
                <div className={classes.fieldGroup}>
                  <label>Discount (percent):</label>
                  <input
                    disabled={plansPurchased === 0}
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    value={prepaidConfig.discountPercents}
                    onChange={(e) =>
                      changePurchase(product._id, {
                        prepaidConfig: {
                          ...prepaidConfig,
                          discountPercents: e.target.valueAsNumber || 0,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}

            {/* <div className={classes.planColumn}>
              <div className={classes.fieldGroup}>
                <label>Add-ons Purchased:</label>
                <input
                  type="number"
                  min={addonsAssigned}
                  disabled={disabled}
                  value={addonsPurchased}
                  onChange={e => {
                    let newVal = +e.target.value;

                    // Dont let to purchase less than already assigned to regions and learning centers
                    if (newVal < addonsAssigned) {
                      alert(
                        'You cant purchase less than already assigned to regions and learning centers'
                      );
                      newVal = addonsAssigned;
                    }

                    changePurchase(product._id, { "addons": newVal })
                  }}
                />
              </div>
              <div className={classes.fieldGroup}>
                <label>Addons Assignable:</label>
                <input disabled type="number" value={addonsAssignable} />
              </div>
              <div className={classes.fieldGroup}>
                <label>Add-ons Available:</label>
                <input disabled type="number" value={addonsAvailable} />
              </div>
            </div> */}
          </div>
        );
      })}
    </div>
  );
};

ProductsField.propTypes = {
  products: PropTypes.array,
  value: PropTypes.array,
  available: PropTypes.array,
  plansType: PropTypes.string.isRequired,
  showOnlyPurchased: PropTypes.bool,
};

export default ProductsField;
