import React, { useState, useCallback } from 'react';
import ReactPlayer from 'react-player';

const browsersInteractErrors = [
  'not allowed by the user agent',
  "user didn't interact",
];

const VideoPlayer = React.forwardRef(
  ({ playing, onError, onPlay, ...otherProps }, ref) => {
    const [userNotInteractError, setUserNotInteractError] = useState(false);

    const customOnError = useCallback(
      (error) => {
        try {
          const isInteractError = browsersInteractErrors.reduce(
            (isError, substr) =>
              isError || error.message.indexOf(substr) !== -1,
            false
          );

          if (isInteractError) return setUserNotInteractError(true);
        } catch (err) {}

        onError && onError();
      },
      [onError]
    );

    const customOnPlay = useCallback(() => {
      setUserNotInteractError(false);
      onPlay && onPlay();
    }, [onPlay]);

    return (
      <ReactPlayer
        light={userNotInteractError && 'i_dont_want_image_here'}
        playing={playing}
        ref={ref}
        onPlay={customOnPlay}
        onError={customOnError}
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              controlsList: 'nodownload nofullscreen',
            },
          },
        }}
        {...otherProps}
      />
    );
  }
);

export default VideoPlayer;
