/* eslint eqeqeq: 0 */

import shortid from 'shortid';
import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {
    ProblemOrder: 1,
    ShowAnswer: 2,
    LengthOfTime: 3,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const {
    Worksheet: { SegmentData },
  } = gameData;

  const problems = SegmentData.map((data) => {
    const { InstrText, FontColor, InstrFontName, InstrAudio, GroupData } = data;

    const itemsData = Array.isArray(GroupData)
      ? GroupData[0].ItemData
      : GroupData.ItemData;

    const items = itemsData
      ? itemsData.map(({ ItemType, ItemData, ItemAudio }) => {
          return {
            text: ItemType == 1 ? ItemData : '',
            image: ItemType == 0 ? ItemData : '',
            audio: ItemAudio,
          };
        })
      : [];

    return {
      id: shortid.generate(),
      items,
      instructionText: InstrText,
      instructionAudio: InstrAudio,
      fontFamily:
        InstrFontName && InstrFontName != '0' ? InstrFontName : 'Arial',
      fontColor: FontColor && FontColor != '0' ? FontColor : '#000',
    };
  });

  result.gameData = { problems };
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
