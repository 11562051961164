import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  toggleSequence,
  playCorrectSound,
  playIncorrectSound,
  playWatch,
} from 'actions/audioActions';
import { prepareProblems } from 'activity-templates/utils';
import {
  validateProblem,
  DIRECTIONS,
  REQUIRED_ANSWERS,
  INSTRUCTION_TYPES,
  HELPERS,
  ASSETS,
} from './directionsGridHelpers';
import {
  InstructionsTextBlock,
  ActivityButtons,
  ProblemsProgress,
  MultiplayerOverlay,
} from 'components/flink-play';
import API from 'api';
import Grid from './Grid/Grid';
import classes from './DirectionsGrid.module.scss';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

class GridElement {
  constructor(gridX, gridY) {
    this.grid = _.fill(Array(gridY)).map(() => _.fill(Array(gridX), null));
    this.correctAnswersCoordinates = [];
    this.incorrectAnswersCoordinates = [];
    this.questionsCoordinates = [];
  }

  getGridArray() {
    return this.grid;
  }

  _placeItem(item, coords) {
    const [x, y] = coords;
    this.grid[y][x] = item;
  }

  placeCorrectAnswer(item, coords) {
    this.correctAnswersCoordinates.push(coords.toString());
    this._placeItem(item, coords);
  }

  placeIncorrectAnswer(item, coords) {
    this.incorrectAnswersCoordinates.push(coords.toString());
    this._placeItem(item, coords);
  }

  placeQuestion(item, coords) {
    this.questionsCoordinates.push(coords.toString());
    this._placeItem(item, coords);
  }

  getCorrectAnswersCoordinates() {
    return this.correctAnswersCoordinates;
  }

  getIncorrectAnswersCoordinates() {
    return this.correctAnswersCoordinates;
  }

  getQuestionsCoordinates() {
    return this.correctAnswersCoordinates;
  }

  isEmptyCoords(coords) {
    const [x, y] = coords;
    return !this.grid[y][x];
  }

  checkIsAllSelectedAreCorrect(selected) {
    const xorElements = _.xor(this.correctAnswersCoordinates, selected);
    const allElementsMatch = xorElements.length === 0;
    return allElementsMatch;
  }

  getItemByCoords(coords) {
    const [x, y] = coords;
    return this.grid[y][x];
  }
}

const INITIAL_STATE = {
  currentIndex: 0,
  isResolved: false,
  attemptsOnCurrentProblem: 0,
  answeredCorrectly: 0,
  answeredIncorrectly: 0,
  problems: [],
  selected: [],
};

class DirectionsGrid extends Component {
  state = INITIAL_STATE;

  componentDidMount() {
    this.getHelpers();
    this.getAssets();
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.delayedStartGameTimeout);
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('startGame', this.startGame);
  }

  getHelpers = async () => {
    const { solutionLocale } = this.props;
    const helpers = await API.templates.directionsGrid.getHelpers(
      solutionLocale.code
    );

    this.helpers = helpers;
  };

  getAssets = async () => {
    const { solutionLocale } = this.props;
    const assets = await API.templates.directionsGrid.getAssets(
      solutionLocale.code
    );

    this.assets = assets;
  };

  generateProblems = (gridOptions) => {
    const {
      gridSize,
      direction,
      questionsNumber,
      requiredAnswers,
      assetsType,
    } = gridOptions;

    const isSingleAnswer = requiredAnswers === REQUIRED_ANSWERS.SINGLE;
    const isBetween =
      direction === DIRECTIONS.BETWEEN_HORIZONTAL ||
      direction === DIRECTIONS.BETWEEN_VERTICAL;

    const [gridX, gridY] = gridSize.split(',').map((v) => +v);

    const generateProblem = () => {
      const problem = {
        isSingleAnswer,
        isBetween,
        gridOptions,
        instructions: [],
        postInstructionsPart: [],
      };

      const shuffledAssets = _.shuffle(
        this.assets[assetsType].map((a) => JSON.parse(JSON.stringify(a)))
      );

      const getInstructionsPreHelper = () => {
        switch (assetsType) {
          case ASSETS.FARM:
          case ASSETS.FOOD:
            return isSingleAnswer
              ? HELPERS.CLICK_ONE_PICTURE
              : HELPERS.CLICK_SOME_PICTURES;
          case ASSETS.LETTERS:
            return isSingleAnswer
              ? HELPERS.CLICK_ONE_LETTER
              : HELPERS.CLICK_SOME_LETTERS;
          case ASSETS.SHAPES:
            return isSingleAnswer
              ? HELPERS.CLICK_ONE_SHAPE
              : HELPERS.CLICK_SOME_SHAPES;
          default:
        }
      };

      problem.instructions.push(this.helpers[getInstructionsPreHelper()]);

      const directionItem = this.helpers[DIRECTIONS[direction]];
      problem.postInstructionsPart.push(directionItem);

      // Get answer
      const answer = shuffledAssets.shift();

      // Get relative item
      const relativeItem = {
        ...shuffledAssets.shift(),
        isRelative: true,
      };
      problem.postInstructionsPart.push(relativeItem);

      const secondRelativeItem = isBetween && {
        ...shuffledAssets.shift(),
        isRelative: true,
      };

      if (isBetween) {
        problem.postInstructionsPart.push(
          this.helpers[HELPERS.AND],
          secondRelativeItem
        );
      }

      problem.instructions.push(...problem.postInstructionsPart);

      const grid = new GridElement(gridX, gridY);
      const gridSlotsNumber = gridX * gridY;

      const getRandomNumber = (min, max) => _.random(min, max);

      const getRandomX = (min = 0, max = gridX - 1) =>
        getRandomNumber(min, max >= 0 ? max : gridX - 1 + max);

      const getRandomY = (min = 0, max = gridY - 1) =>
        getRandomNumber(min, max >= 0 ? max : gridY - 1 + max);

      const getRandomXFromEnd = (min = 0, max = gridX - 1) =>
        Math.abs(getRandomNumber(min, max) + 1 - gridX);

      const getRandomYFromEnd = (min = 0, max = gridY - 1) =>
        Math.abs(getRandomNumber(min, max) + 1 - gridY);

      let getRandomCoordinatesForCorrectAnswer,
        getRandomCoordinatesForIncorrectAnswer;

      const generateForAboveDirection = () => {
        const questionX = getRandomX();
        const questionY = getRandomY(
          1,
          isSingleAnswer ? 2 : Math.max(3, gridY - Math.round(gridY * 0.3)) - 1
        );
        const answerX = isSingleAnswer ? questionX : getRandomX();
        const answerY = isSingleAnswer
          ? questionY - 1
          : getRandomY(0, questionY - 1);

        grid.placeQuestion(relativeItem, [questionX, questionY]);
        grid.placeCorrectAnswer(answer, [answerX, answerY]);

        getRandomCoordinatesForCorrectAnswer = () => [
          getRandomX(),
          getRandomY(0, questionY - 1),
        ];
        getRandomCoordinatesForIncorrectAnswer = () => [
          getRandomX(),
          getRandomY(questionY),
        ];
      };

      const generateForBelowDirection = () => {
        const questionX = getRandomX();
        const questionY = getRandomYFromEnd(
          1,
          isSingleAnswer ? 2 : Math.max(3, gridY - Math.round(gridY * 0.3)) - 1
        );
        const answerX = isSingleAnswer ? questionX : getRandomX();
        const answerY = isSingleAnswer
          ? questionY + 1
          : getRandomY(questionY + 1);

        grid.placeQuestion(relativeItem, [questionX, questionY]);
        grid.placeCorrectAnswer(answer, [answerX, answerY]);

        getRandomCoordinatesForCorrectAnswer = () => [
          getRandomX(),
          getRandomY(questionY + 1),
        ];
        getRandomCoordinatesForIncorrectAnswer = () => [
          getRandomX(),
          getRandomY(0, questionY),
        ];
      };

      const generateForLeftDirection = () => {
        const questionX = getRandomX(
          1,
          isSingleAnswer ? 2 : Math.max(3, gridX - Math.round(gridX * 0.3)) - 1
        );
        const questionY = getRandomY();

        const answerX = isSingleAnswer
          ? questionX - 1
          : getRandomX(0, questionX - 1);
        const answerY = isSingleAnswer ? questionY : getRandomY();

        grid.placeQuestion(relativeItem, [questionX, questionY]);
        grid.placeCorrectAnswer(answer, [answerX, answerY]);

        getRandomCoordinatesForCorrectAnswer = () => [
          getRandomX(0, questionX - 1),
          getRandomY(),
        ];
        getRandomCoordinatesForIncorrectAnswer = () => [
          getRandomX(questionX),
          getRandomY(),
        ];
      };

      const generateForRightDirection = () => {
        const questionX = getRandomXFromEnd(
          1,
          isSingleAnswer ? 2 : Math.max(3, gridX - Math.round(gridX * 0.3)) - 1
        );
        const questionY = getRandomY();
        const answerX = isSingleAnswer
          ? questionX + 1
          : getRandomX(questionX + 1);
        const answerY = isSingleAnswer ? questionY : getRandomY();

        grid.placeQuestion(relativeItem, [questionX, questionY]);
        grid.placeCorrectAnswer(answer, [answerX, answerY]);

        getRandomCoordinatesForCorrectAnswer = () => [
          getRandomX(questionX + 1),
          getRandomY(),
        ];
        getRandomCoordinatesForIncorrectAnswer = () => [
          getRandomX(0, questionX),
          getRandomY(),
        ];
      };

      const generateForBetweenHorizontal = () => {
        const y = getRandomY();

        const questionX = getRandomX(0, -2);
        const answerX = getRandomX(questionX + 1, -1);
        const secondQuestionX = getRandomX(answerX + 1);

        grid.placeQuestion(relativeItem, [questionX, y]);
        grid.placeQuestion(secondRelativeItem, [secondQuestionX, y]);
        grid.placeCorrectAnswer(answer, [answerX, y]);

        getRandomCoordinatesForCorrectAnswer = () => [
          getRandomX(questionX + 1, secondQuestionX - 1),
          y,
        ];

        getRandomCoordinatesForIncorrectAnswer = () => {
          const options = [];

          const randomXInSameYOptions = [];
          if (questionX > 0)
            randomXInSameYOptions.push(getRandomX(0, questionX - 1));
          if (secondQuestionX < gridX - 1) getRandomX(secondQuestionX + 1);

          if (randomXInSameYOptions.length) {
            const randomXInSameY = _.shuffle(randomXInSameYOptions)[0];
            options.push([randomXInSameY, y]);
          }

          const randomYInAnotherXOptions = [];
          if (y > 0) randomYInAnotherXOptions.push(getRandomY(0, y - 1));
          if (y < gridY - 1) randomYInAnotherXOptions.push(getRandomY(y + 1));

          if (randomYInAnotherXOptions.length) {
            const randomYInAnotherX = _.shuffle(randomYInAnotherXOptions)[0];
            options.push([getRandomX(), randomYInAnotherX]);
          }

          return _.shuffle(options)[0];
        };
      };

      const generateForBetweenVertical = () => {
        const x = getRandomX();

        const questionY = getRandomY(0, -2);
        const answerY = getRandomY(questionY + 1, -1);
        const secondQuestionY = getRandomY(answerY + 1);

        grid.placeQuestion(relativeItem, [x, questionY]);
        grid.placeQuestion(secondRelativeItem, [x, secondQuestionY]);
        grid.placeCorrectAnswer(answer, [x, answerY]);

        getRandomCoordinatesForCorrectAnswer = () => [
          x,
          getRandomY(questionY + 1, secondQuestionY - 1),
        ];

        getRandomCoordinatesForIncorrectAnswer = () => {
          const options = [];
          const randomYInSameXOptions = [];
          if (questionY > 0)
            randomYInSameXOptions.push(getRandomY(0, questionY - 1));
          if (secondQuestionY < gridX - 1) getRandomY(secondQuestionY + 1);

          if (randomYInSameXOptions.length) {
            const randomYInSameX = _.shuffle(randomYInSameXOptions)[0];
            options.push([x, randomYInSameX]);
          }

          const randomXInAnotherYOptions = [];
          if (x > 0) randomXInAnotherYOptions.push(getRandomX(0, x - 1));
          if (x < gridX - 1) randomXInAnotherYOptions.push(getRandomX(x + 1));

          if (randomXInAnotherYOptions.length) {
            const randomXInAnotherY = _.shuffle(randomXInAnotherYOptions)[0];
            options.push([randomXInAnotherY, getRandomY()]);
          }

          return _.shuffle(options)[0];
        };
      };

      switch (direction) {
        case DIRECTIONS.ABOVE:
          generateForAboveDirection();
          break;
        case DIRECTIONS.BELOW:
          generateForBelowDirection();
          break;
        case DIRECTIONS.LEFT:
          generateForLeftDirection();
          break;
        case DIRECTIONS.RIGHT:
          generateForRightDirection();
          break;
        case DIRECTIONS.BETWEEN_HORIZONTAL:
          generateForBetweenHorizontal();
          break;
        case DIRECTIONS.BETWEEN_VERTICAL:
          generateForBetweenVertical();
          break;
        default:
      }

      const placeIncorrectAnswers = () => {
        const wrongAnswers = shuffledAssets.splice(
          0,
          Math.round(gridSlotsNumber * 0.3) + 1
        );

        let attempts = 0;
        while (wrongAnswers.length && attempts < 100) {
          attempts++;
          const coords = getRandomCoordinatesForIncorrectAnswer();

          if (coords && grid.isEmptyCoords(coords)) {
            grid.placeIncorrectAnswer(wrongAnswers.shift(), coords);
          }
        }
      };

      placeIncorrectAnswers();

      const placeAdditionalCorrectAnswers = () => {
        let maxAdditionalCorrectAnswersNumber;

        if (gridSlotsNumber >= 9 && gridSlotsNumber <= 16) {
          maxAdditionalCorrectAnswersNumber = 2;
        } else if (gridSlotsNumber > 16 && gridSlotsNumber <= 25) {
          maxAdditionalCorrectAnswersNumber = 3;
        } else if (gridSlotsNumber > 25 && gridSlotsNumber <= 36) {
          maxAdditionalCorrectAnswersNumber = 4;
        } else {
          maxAdditionalCorrectAnswersNumber = 10;
        }

        const correctAnswers = shuffledAssets.splice(
          0,
          getRandomNumber(1, maxAdditionalCorrectAnswersNumber)
        );

        let attempts = 0;
        while (correctAnswers.length && attempts < 100) {
          attempts++;
          const coords = getRandomCoordinatesForCorrectAnswer();

          if (coords && grid.isEmptyCoords(coords)) {
            grid.placeCorrectAnswer(correctAnswers.shift(), coords);
          }
        }
      };

      if (!isSingleAnswer) {
        placeAdditionalCorrectAnswers();
      }

      problem.grid = grid;
      return problem;
    };

    const problems = _.range(0, questionsNumber).map(generateProblem);
    return problems;
  };

  startGame = async () => {
    if (!this.helpers || !this.assets) {
      this.delayedStartGameTimeout = setTimeout(() => this.startGame(), 500);
      return;
    }

    const { options, gameData } = this.props.activity.data;

    const grids = prepareProblems(gameData.problems, options, validateProblem);
    const problemsSets = grids.map((grid) => this.generateProblems(grid));
    const problems = _.flatten(problemsSets);

    const currentProblem = problems[0];

    this.setState(
      {
        ...INITIAL_STATE,
        problems,
        currentProblem,
        problemsCount: problems.length,
        problemsLeft: problems.length,
      },
      this.playInstructions
    );
  };

  playCorrectSound = () => {
    const { currentProblem, selected } = this.state;
    if (!currentProblem) return;

    const { grid, postInstructionsPart } = currentProblem;
    const sequence = [this.helpers[HELPERS.CORRECT_ANSWER]];

    selected.forEach((coordsString) => {
      sequence.push(grid.getItemByCoords(coordsString.split(',')));
    });

    if (selected.length > 1) {
      sequence.splice(sequence.length - 1, 0, this.helpers[HELPERS.AND]);
      sequence.push(this.helpers[HELPERS.ARE], ...postInstructionsPart);
    } else {
      sequence.push(this.helpers[HELPERS.IS], ...postInstructionsPart);
    }

    toggleSequence(
      sequence.map((i) => i.audioUrl),
      { onEnd: this.setupNextQuestion }
    );
  };

  playIncorrectSound = () => {
    const { currentProblem, selected } = this.state;
    if (!currentProblem) return;

    const { grid, postInstructionsPart } = currentProblem;
    const sequence = [this.helpers[HELPERS.INCORRECT_ANSWER]];

    const correctAnswersCoordinates = grid.getCorrectAnswersCoordinates();
    let incorrectNumber = 0;

    selected.forEach((coordsString) => {
      if (!correctAnswersCoordinates.includes(coordsString)) {
        incorrectNumber++;
        sequence.push(grid.getItemByCoords(coordsString.split(',')));
      }
    });

    if (incorrectNumber) {
      if (incorrectNumber > 1) {
        sequence.splice(sequence.length - 1, 0, this.helpers[HELPERS.AND]);
        sequence.push(this.helpers[HELPERS.ARE_NOT], ...postInstructionsPart);
      } else {
        sequence.push(this.helpers[HELPERS.IS_NOT], ...postInstructionsPart);
      }
    }

    toggleSequence(sequence.map((i) => i.audioUrl));
  };

  playWatch = () => {
    const { currentProblem, selected } = this.state;
    if (!currentProblem) return;

    const { grid, postInstructionsPart } = currentProblem;
    const sequence = [this.helpers[HELPERS.SHOW_ANSWER]];

    selected.forEach((coordsString) => {
      sequence.push(grid.getItemByCoords(coordsString.split(',')));
    });

    if (selected.length > 1) {
      sequence.splice(sequence.length - 1, 0, this.helpers[HELPERS.AND]);
      sequence.push(this.helpers[HELPERS.ARE], ...postInstructionsPart);
    } else {
      sequence.push(this.helpers[HELPERS.IS], ...postInstructionsPart);
    }

    toggleSequence(
      sequence.map((i) => i.audioUrl),
      { onEnd: this.setupNextQuestion }
    );
  };

  checkAnswer = () => {
    const { currentProblem, selected, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const isCorrect = currentProblem.grid.checkIsAllSelectedAreCorrect(
      selected
    );

    if (isCorrect) {
      this.setState({ isResolved: true, shouldChangeTurn: true });
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      this.playCorrectSound();
      return;
    }

    this.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState((state) => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly,
    }));
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;
    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }
    let newProblemsLeft = problemsLeft - 1;
    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft,
      });
      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);
      return;
    }
    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState((state) => {
        const nextProblem = state.problems[state.currentIndex + 1];

        return {
          selected: [],
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1,
        };
      }, this.playInstructions);

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;
    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });
    this.setState({ currentProblem: null });
  };

  itemClickHandler = (item, coordinates) => {
    const { currentProblem, selected, isResolved } = this.state;
    if (isResolved) return;

    let newSelected;

    const stringCoordinates = coordinates.toString();
    const isSelectedAlready = selected.indexOf(stringCoordinates) !== -1;

    if (currentProblem.isSingleAnswer) {
      newSelected = isSelectedAlready ? [] : [stringCoordinates];
    } else {
      newSelected = isSelectedAlready
        ? selected.filter((coords) => coords !== stringCoordinates)
        : [...selected, stringCoordinates];
    }

    this.setState({ selected: newSelected });
  };

  showAnswer = () => {
    const { currentProblem } = this.state;
    if (!currentProblem) return;

    const { grid } = currentProblem;

    this.setState(
      (state) => ({
        selected: grid.getCorrectAnswersCoordinates(),
        isResolved: true,
        shouldChangeTurn: false,
        attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      }),
      this.playWatch
    );
  };

  playInstructions = () => {
    const { currentProblem } = this.state;
    if (!currentProblem) return;

    const { instructions } = currentProblem;
    const { instructionType } = this.props.activity.data.options.directionsGrid;
    if (
      instructionType === INSTRUCTION_TYPES.AUDIO_AND_TEXT ||
      instructionType === INSTRUCTION_TYPES.AUDIO
    ) {
      toggleSequence(instructions.map((i) => i.audioUrl));
    }
  };

  render() {
    const {
      selected,
      isResolved,
      problems,
      problemsLeft,
      currentProblem,
      attemptsOnCurrentProblem,
    } = this.state;

    const { activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    return (
      <div>
        {this.renderProblem()}

        <ProblemsProgress
          problemsNumber={problems.length}
          problemsLeft={problemsLeft}
        />

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CHECK_ANSWER,
              onClick: this.checkAnswer,
              dontShow: isResolved || !selected.length,
            },
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: this.showAnswer,
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem,
            },
            {
              type: buttonsTypes.SPEAK,
              onClick: this.playInstructions,
              dontShow:
                isResolved ||
                !currentProblem ||
                options.directionsGrid.instructionType ===
                  INSTRUCTION_TYPES.TEXT,
            },
            buttonsTypes.HELP,
          ]}
        />
      </div>
    );
  }

  renderProblem = () => {
    const { currentProblem, selected, isResolved } = this.state;
    if (!currentProblem) return null;

    const { instructionType } = this.props.activity.data.options.directionsGrid;
    const { instructions, grid, gridOptions } = currentProblem;
    const instructionText = instructions.map((i) => i.text).join(' ');

    const showInstructionsText =
      instructionType === INSTRUCTION_TYPES.AUDIO_AND_TEXT ||
      instructionType === INSTRUCTION_TYPES.TEXT;

    return (
      <>
        {showInstructionsText && (
          <InstructionsTextBlock text={instructionText} />
        )}

        <div className={classes.blocksWrapper}>
          <Grid
            isResolved={isResolved}
            grid={grid.getGridArray()}
            showGrid={gridOptions.showGrid}
            highlightRelative={gridOptions.highlightItems}
            selected={selected}
            clickHandler={this.itemClickHandler}
          />
        </div>
      </>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(DirectionsGrid);
