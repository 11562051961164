import React from 'react';
import PropTypes from 'prop-types';

import CategorySelect from '../../CategorySelect/CategorySelect';
import classes from './EduColumn.module.scss';
import { withTranslate } from "components/hocs";

const setEduProp = (edu, changeHandler, prop, value) => {
  const eduCopy = JSON.parse(JSON.stringify(edu));

  eduCopy[prop] = value;

  switch (prop) {
    case 'grade':
      delete eduCopy.firstCategory;
      delete eduCopy.secondCategory;
      delete eduCopy.thirdCategory;
      break;
    case 'level':
      delete eduCopy.firstCategory;
      delete eduCopy.secondCategory;
      delete eduCopy.thirdCategory;
      break;
    case 'firstCategory':
      delete eduCopy.secondCategory;
      delete eduCopy.thirdCategory;
      break;
    case 'secondCategory':
      delete eduCopy.thirdCategory;
      break;
    default:
  }
  changeHandler(eduCopy);
};

const EduColumn = ({ scope, edu, changeHandler, notRemovable, translate }) => {
  const singleFirstMenu = scope.getSingleFirstMenuTitle();
  const firstMenu = scope.getFirstMenu();

  const bindedSetEduProp = setEduProp.bind(null, edu, changeHandler);

  let firstCategoryItems = [];
  let secondCategoryItems = [];
  let thirdCategoryItems = [];

  if (edu[singleFirstMenu]) {
    firstCategoryItems = scope.getFirstCategoryItems(edu[singleFirstMenu]);
    if (edu.firstCategory) {
      secondCategoryItems = scope.getSecondCategoryItems(
        { firstCategoryId: edu.firstCategory },
        edu[singleFirstMenu]
      );
    }

    if (edu.secondCategory) {
      thirdCategoryItems = scope.getThirdCategoryItems(
        { secondCategoryId: edu.secondCategory },
        edu[singleFirstMenu]
      );
    }
  }

  return (
    <div className={classes.wrapper}>
      {!notRemovable && (
        <button
          type="button"
          className={classes.removeBtn}
          onClick={() => changeHandler(null)}
        >
          &#10006;
        </button>
      )}

      <CategorySelect
        label={firstMenu.charAt(0).toUpperCase() + firstMenu.slice(1)}
        value={edu[singleFirstMenu]}
        changeHandler={bindedSetEduProp.bind(null, singleFirstMenu)}
        defaultText={`Select ${singleFirstMenu}`}
        items={scope.getFirstMenuItems()}
      />

      <CategorySelect
        label={scope.getFirstCategoryTitle()}
        value={edu.firstCategory}
        changeHandler={bindedSetEduProp.bind(null, 'firstCategory')}
        defaultText={translate(486, '- All -')}
        items={firstCategoryItems || []}
      />

      <CategorySelect
        label={scope.getSecondCategoryTitle()}
        value={edu.secondCategory}
        changeHandler={bindedSetEduProp.bind(null, 'secondCategory')}
        defaultText={translate(486, '- All -')}
        items={secondCategoryItems || []}
      />

      <CategorySelect
        label={scope.getThirdCategoryTitle()}
        value={edu.thirdCategory}
        changeHandler={bindedSetEduProp.bind(null, 'thirdCategory')}
        defaultText={translate(486, '- All -')}
        items={thirdCategoryItems || []}
      />
    </div>
  );
};

EduColumn.propTypes = {
  scope: PropTypes.object.isRequired,
  edu: PropTypes.object
};

export default withTranslate(EduColumn);
