import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import GameModal from '../GameModal/GameModal';
import { closeActivityTeamHelp } from 'actions/gameActions';
import { toggleAudio, stopAudio } from 'actions/audioActions';
import { audioURL } from 'config';
import { formatHtmlForPlay, getTranslateFunc } from 'utils';

const ActivityHelp = ({
  activityTemplates,
  strings,
  locales,
  currentActivity,
  closeActivityTeamHelp,
  solutionLocale,
  generalTranslate,
  solutionTranslate
}) => {
  const {
    data,
    activity: { templateId }
  } = currentActivity;

  const template = useMemo(() => {
    return _.find(activityTemplates, { _id: templateId });
  }, [activityTemplates, templateId]);

  console.log(template);

  const { multiLocaleHelp, helpLocale } = data;

  const translate = useMemo(() => {
    // Determine which translate function to use for Title and Buttons in dialog
    // If Activity has attribute "helpLocale", then generate translate function
    // based on that, if no - use solutionTranslate in priority
    // (will be in Flink Play app), if no - then use generalTranslate (login screen)
    if (helpLocale) {
      return getTranslateFunc(strings, helpLocale);
    }

    return solutionTranslate || generalTranslate;
  }, [helpLocale, strings, solutionTranslate, generalTranslate]);

  const localeCode =
    (solutionLocale && solutionLocale.code) ||
    (multiLocaleHelp && multiLocaleHelp.defaultLocale) ||
    'en';

  const locale = _.find(locales, { code: localeCode });

  const helpHtml = translate(template.helpStrings.team, '', false, true);

  const helpAudio = template.activityTemplateName.split(' ').join('') + '.mp3';

  const audioHelpUrl = `${audioURL}/Generic/TeamHelp/${locale.name}/${helpAudio}`;

  useEffect(() => {
    toggleAudio(audioHelpUrl);
  }, [audioHelpUrl]);

  const formattedHtml = useMemo(() => formatHtmlForPlay({ html: helpHtml }), [
    helpHtml
  ]);

  return (
    <GameModal
      title={translate(245, 'Team Help')}
      buttons={[
        {
          title: translate(224, 'Listen'),
          clickHandler: e => toggleAudio(audioHelpUrl),
          dontShow: !audioHelpUrl
        },
        {
          title: translate(80, 'Close'),
          clickHandler: () => {
            closeActivityTeamHelp();
            stopAudio();
          }
        }
      ]}
    >
      <div
        style={{ fontSize: '2vmin' }}
        dangerouslySetInnerHTML={{ __html: formattedHtml }}
      />
    </GameModal>
  );
};

ActivityHelp.propTypes = {
  strings: PropTypes.array.isRequired,
  solutionLocale: PropTypes.object,
  currentActivity: PropTypes.object.isRequired,
  generalTranslate: PropTypes.func.isRequired,
  solutionTranslate: PropTypes.func
};

const mapStateToProps = ({
  game,
  common,
  status,
  flinkPlay: { solutionLocale, solutionTranslate }
}) => ({
  strings: common.strings,
  locales: common.locales,
  activityTemplates: common.activityTemplates,
  solutionLocale,
  currentActivity: game.currentActivity,
  generalTranslate: status.translate,
  solutionTranslate
});

export default compose(connect(mapStateToProps, { closeActivityTeamHelp }))(
  ActivityHelp
);
