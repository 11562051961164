import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { PlayAudioButton } from 'components/flink-play';
import classes from './Item.module.scss';
import { s3bucketPublicURL } from 'config';

const Item = ({ data, contentFolder }) => {
  const { text, image, audio, audioPath } = data;
  const contentPath = `${s3bucketPublicURL}/${contentFolder}/gamedata/`;

  return (
    <div className={classes.item}>
      {audio && (
        <PlayAudioButton
          url={(audioPath || contentPath) + audio}
          className={classnames({
            [classes.playAudioBtn]: !text && !image,
            [classes.playAudioBtnSmall]: text || image,
          })}
        />
      )}

      {text ? (
        <span>{text}</span>
      ) : image ? (
        <img className={classes.itemImg} src={contentPath + image} alt="" />
      ) : null}
    </div>
  );
};

Item.propTypes = {
  text: PropTypes.string,
  contentFolder: PropTypes.string.isRequired,
};

const mapStateToProps = ({ game }) => ({
  contentFolder: game.currentActivity.activity.contentFolder,
});

export default connect(mapStateToProps)(Item);
