import React from 'react';
import PropTypes from 'prop-types';

import classes from './Item.module.scss';

const Item = ({ data }) => {
  const { text } = data;

  return text ? <span className={classes.item}>{text}</span> : null;
};

Item.propTypes = {
  text: PropTypes.string
};

export default Item;
