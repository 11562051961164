import React, { Fragment } from "react";
import { Field, change } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FlexContainer } from "components/common";
import renderSelectField from "./renderSelectField";
import DateField from "./DateField";

const validatePeriods = val => (val ? undefined : "Required");

const MONTHLY = "Monthly",
  QUARTERLY = "Quarterly",
  EVERY_SIX_WEEKS = "Every Six Weeks",
  EACH_SEMESTER = "Each Semester",
  ONCE_A_YEAR = "Once a Year";

const reportsRateOptions = [
  MONTHLY,
  QUARTERLY,
  EVERY_SIX_WEEKS,
  EACH_SEMESTER,
  ONCE_A_YEAR
];

const renderDateFields = reportsRate => {
  switch (reportsRate) {
    case MONTHLY:
      return null;
    case QUARTERLY:
      return [1, 2, 3, 4].map(num => (
        <DateField
          key={num}
          name={`config.reportsDates[${num - 1}]`}
          label={`Quarter ${num} End`}
          validate={validatePeriods}
        />
      ));
    case EVERY_SIX_WEEKS:
      return [1, 2, 3, 4, 5, 6].map(num => (
        <DateField
          key={num}
          name={`config.reportsDates[${num - 1}]`}
          label={`Period ${num} End`}
          validate={validatePeriods}
        />
      ));
    case EACH_SEMESTER:
      return [1, 2].map(num => (
        <DateField
          key={num}
          name={`config.reportsDates[${num - 1}]`}
          label={`Semester ${num} End`}
          validate={validatePeriods}
        />
      ));
    case ONCE_A_YEAR:
      return (
        <DateField
          name={`config.reportsDates[0]`}
          label="Year End"
          validate={validatePeriods}
        />
      );
    default:
      return null;
  }
};

const ReportRateFields = ({ change, formName, reportsRate }) => (
  <Fragment>
    <Field
      name="config.reportsRate"
      label="Reports every *"
      component={renderSelectField}
      onChange={() => change(formName, "config.reportsDates", "")}
    >
      {reportsRateOptions.map((rate, index) => (
        <option key={index} value={rate}>
          {rate}
        </option>
      ))}
    </Field>

    <FlexContainer xs="50%" md="33.33%" lg="25%" xl="20%">
      {renderDateFields(reportsRate)}
    </FlexContainer>
  </Fragment>
);

ReportRateFields.propTypes = {
  reportsRate: PropTypes.string,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired
};

export default connect(null, { change })(ReportRateFields);
