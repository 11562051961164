import { imagesURL } from 'config';

const flinkPlayAssets = `${imagesURL}/FlinkPlay`;

export const StarIcon = ({ mastered = false, partial = false, idx = 0 }) => {
  const type = mastered ? 3 : partial ? 2 : 1;
  return <img src={`${flinkPlayAssets}/stars/${idx + 1}-${type}.png`} alt="" />;
};

export const MenuIcon = () => (
  <img src={flinkPlayAssets + '/menu.png'} alt="" />
);

export const PlayAgainIcon = () => (
  <img src={flinkPlayAssets + '/PlayAgain150.png'} alt="" />
);
