import React, { useRef, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { VideoPlayer } from 'components/common';
import classes from './VideoAnimation.module.scss';

const VideoAnimation = ({ video, loginTranslate, playTranslate }) => {
  const translate = playTranslate || loginTranslate;
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef();

  const { src, callback } = video;

  const callCallbackIfVideoStarted = useCallback(() => {
    if (
      videoRef.current &&
      videoRef.current.player &&
      videoRef.current.player.isPlaying
    ) {
      callback();
    }
  }, [callback, videoRef]);

  useEffect(() => {
    if (!video) return;
    document.addEventListener('keydown', callCallbackIfVideoStarted);
    document.addEventListener('click', callCallbackIfVideoStarted);

    return () => {
      document.removeEventListener('keydown', callCallbackIfVideoStarted);
      document.removeEventListener('click', callCallbackIfVideoStarted);
    };
  }, [video, callback, callCallbackIfVideoStarted]);

  if (!video) return null;
  const boundCallback = callback.bind(this, videoRef);

  return (
    <div
      className={classes.videoOverlay + (loaded ? ` ${classes.loaded}` : '')}
    >
      <p className={classes.overlayText}>
        {translate(78, 'Click Here to Stop')}
      </p>

      <VideoPlayer
        ref={videoRef}
        playing
        width="100%"
        height="100%"
        url={src}
        onReady={() => setLoaded(true)}
        onError={boundCallback}
        onEnded={boundCallback}
      />
    </div>
  );
};

VideoAnimation.propTypes = {
  video: PropTypes.object,
  playTranslate: PropTypes.func,
  loginTranslate: PropTypes.func,
};

const mapStateToProps = ({
  flinkPlay: { video, solutionTranslate: playTranslate },
  status: { translate: loginTranslate },
}) => ({
  playTranslate,
  loginTranslate,
  video,
});

export default connect(mapStateToProps)(VideoAnimation);
