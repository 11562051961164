import React from "react";
import PropTypes from "prop-types";

import { imagesURL } from "config";
import classes from "./EbookTimer.module.scss";

const timerAssetsFolder = `${imagesURL}/Images/Timer/`;

const EbookTimer = ({
  showStart,
  showStop,
  spentTime,
  startHandler,
  stopHandler
}) => {
  return (
    <div className={classes.timer}>
      <div className={classes.timerInner}>
        {showStart && (
          <button onClick={startHandler}>
            <img src={timerAssetsFolder + "timer_play.png"} alt="" />
          </button>
        )}

        {showStop && (
          <button onClick={stopHandler}>
            <img src={timerAssetsFolder + "timer_stop.png"} alt="" />
          </button>
        )}

        <span>{spentTime}</span>
      </div>
    </div>
  );
};

EbookTimer.defaultProps = {
  spentTime: 0
};

EbookTimer.propTypes = {
  showStart: PropTypes.bool.isRequired,
  showStop: PropTypes.bool.isRequired,
  spentTime: PropTypes.number,
  startHandler: PropTypes.func.isRequired,
  stopHandler: PropTypes.func.isRequired
};

export default EbookTimer;
