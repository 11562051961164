import React, { Component } from 'react';
import { axiosAPI as axios } from 'utils';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cs from 'classnames';

import { imagesURL } from 'config';
import { getMasterOrganizationById } from 'actions/commonActions';
import {
  changeLocale,
  showError,
  showInfo,
  showConfirmPromise,
} from 'actions/statusActions';
import LoginHelp from './login/LoginHelp';
import LoginForm from './login/LoginForm';
import {
  redirect,
  getTranslateFunc,
  formatHtmlForPlay,
  parseQueryString,
} from 'utils';
import { Spinner } from 'components/common';
import API from 'api';
import classes from './Login.module.scss';

const langToFont = {
  din: 'din',
};

export class Login extends Component {
  constructor(props) {
    super(props);

    const { match, location } = props;

    const { learningCenterUrl } = match.params;
    const query = parseQueryString(location.search);

    const randomImgNumber = Math.floor(Math.random() * Math.floor(4)) + 1; // from 1 to 4

    this.state = {
      learningCenter: null,
      family: query.family && decodeURI(query.family),
      availableLocales: [],
      familyMembers: [],
      learningCenterUrl,
      imgNumber: randomImgNumber,
      processing: false,
    };
  }

  async fetchFamilyMembers(familyId) {
    const { showError } = this.props;

    // Fetch family members (first name + username pair) having family id
    try {
      const response = await axios.get(`/api/auth/get-family-logins-by-family-id/${familyId}`);
      this.setState({ familyMembers: response.data });

    } catch (error) {
      showError({
        message: `Failed to fetch family members: ${error.message}`,
      })
    }
  }

  async componentDidMount() {
    const {
      auth,
      common,
      changeLocale,
      getMasterOrganizationById,
      history,
      location,
      showError,
    } = this.props;

    const query = parseQueryString(location.search);

    if (auth && auth.isAuthenticated) {
      return history.push(redirect(auth));
    }

    const { locales } = common;

    const { learningCenterUrl, family } = this.state;

    const initialValues = {};

    if (query.autoLogin) initialValues.autoLogin = query.autoLogin === '1';
    if (query.username) initialValues.username = decodeURI(query.username);
    if (query.password) initialValues.password = decodeURI(query.password);
    if (query.username2) initialValues.username2 = decodeURI(query.username2);
    if (query.password2) initialValues.password2 = decodeURI(query.password2);
    if (query.username3) initialValues.username3 = decodeURI(query.username3);
    if (query.password3) initialValues.password3 = decodeURI(query.password3);

    if (family) {
      this.fetchFamilyMembers(family);
    }

    if (!learningCenterUrl) {
      // Set english by default
      this.setState({ initialized: true, initialValues });
      return changeLocale('en');
    }

    const learningCenter = _.find(common.learningCenters, {
      url: learningCenterUrl,
    });

    if (!learningCenter) {
      return history.push('/');
    }

    // Get master organization
    const masterOrganization = await getMasterOrganizationById(
      learningCenter.masterOrganizationId
    );

    if (!masterOrganization) {
      return history.push('/login');
    }

    const {
      config: { locales: learningCenterLocales, defaultLocale, offline },
    } = learningCenter;

    if (offline) {
      initialValues.username =
        initialValues.username || localStorage.getItem('flc-username') || '';
      initialValues.username2 =
        initialValues.username2 || localStorage.getItem('flc-username2') || '';
      initialValues.username3 =
        initialValues.username3 || localStorage.getItem('flc-username3') || '';
    }

    const availableLocales = learningCenterLocales
      .map((loc) => _.find(locales, { code: loc }))
      .sort((a, b) => {
        if (a.code === 'en') {
          return -1;
        }
        if (b.code === 'en') {
          return 1;
        }
        return a.displayName < b.displayName ? -1 : 1;
      });

    const localeCodeToSet =
      query.locale || defaultLocale || availableLocales[0] || 'en';
    changeLocale(localeCodeToSet);

    // Check if Learning Center not expired
    const { expirationDate } = learningCenter;
    if (expirationDate && new Date(expirationDate) < new Date()) {
      const currentTranslate = getTranslateFunc(
        common.strings,
        localeCodeToSet
      );

      showError({
        message: currentTranslate(482, 'Learning Center is expired'),
        cb: () => history.push('/'),
      });
    }

    this.setState(
      {
        masterOrganization,
        learningCenter,
        availableLocales,
        initialized: true,
        initialValues,
      },
      () => {
        const { showInfo, translate } = this.props;

        if (query.showInstructions) {
          const instructionsData =
            query.instructionsData &&
            JSON.parse(decodeURIComponent(query.instructionsData));

          const html = translate(
            query.showInstructions,
            'Instructions',
            false,
            true
          );

          showInfo({
            html: true,
            align: 'left',
            message: formatHtmlForPlay({
              data: instructionsData,
              html,
            }),
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { auth, history, location } = this.props;
    const query = parseQueryString(location.search);

    if (prevProps.auth !== auth && auth.isAuthenticated) {
      history.push({
        pathname: redirect(auth),
        state: query,
      });
    }
  }

  onChangeLocale = (e) => {
    const { value: code } = e.target;

    this.props.changeLocale(code);
    return code;
  };

  loginErrorHandler = async (data) => {
    if (!data) return;

    const { showError, showInfo, translate, showConfirmPromise } = this.props;
    const { initialValues } = this.state;

    if (initialValues.autoLogin) {
      this.setState({
        initialValues: { ...initialValues, autoLogin: false },
      });
    }

    const { familyAccess, family, familyMember } = data;

    if (!familyAccess) return;

    const {
      resumeSubscription,
      retryTransaction,
      product,
      purchase,
      purchaseInfo,
    } = familyAccess;

    const productName = (product && product.name) || '';

    if (resumeSubscription || retryTransaction) {
      showResumeDialog();
    } else {
      resolvePurchaseStatus();
    }

    async function showResumeDialog() {
      if (!familyMember.isAdmin) {
        return showError({
          html: true,
          align: 'left',
          message: formatHtmlForPlay({
            data: { productName },
            html: translate(
              771,
              `You cannot use ${productName} until your family admin (probably your parent) resumes payments`,
              false,
              true
            ),
          }),
        });
      }

      const isConfirmed = await showConfirmPromise({
        html: true,
        align: 'left',
        message: formatHtmlForPlay({
          data: {
            productName,
            successfulTransactionsNumber:
              purchaseInfo.successfulTransactionsNumber,
            numberOfBillingCycles: purchaseInfo.numberOfBillingCycles,
          },
          html: translate(
            772,
            `Your account is inactive, although all your record-keeping data has been saved. You have paid ${purchaseInfo.successfulTransactionsNumber}  of the ${purchaseInfo.numberOfBillingCycles} installments. Click OK to make a payment and resume using ${productName}.`,
            false,
            true
          ),
        }),
      });

      if (!isConfirmed) return;

      this.setState({ processing: true });

      let result;

      if (resumeSubscription) {
        result = await API.purchase.resumeSubscription(purchase._id, {
          familyId: family._id,
        });
      } else if (retryTransaction) {
        result = await API.purchase.retryPastDueTransaction(purchase._id, {
          familyId: family._id,
        });
      }
      this.setState({ processing: false });

      if (result.success) {
        showInfo({
          message: translate(0, 'Success. You can now try to log in again'),
        });
      } else {
        showError({
          message: translate(
            0,
            `Failed to resume your account. Please re-check your billing card amount and try again`
          ),
        });
      }
    }

    async function resolvePurchaseStatus() {
      const { isPending, isRefunded, isRejected } = purchaseInfo;

      if (isRejected) {
        return showError({
          html: true,
          align: 'left',
          message:
            'Your payment was rejected. Please <a href="mailto:support@familylearningcompany.com">contact us</a> if you have some questions',
        });
      }

      if (isRefunded) {
        return showError({
          html: true,
          align: 'left',
          message:
            'Your payment was refunded. Please <a href="mailto:support@familylearningcompany.com">contact us</a> if you have some questions',
        });
      }

      if (isPending) {
        return showInfo({
          html: true,
          align: 'left',
          message:
            'Your payment is pending. Try to login later. Please <a href="mailto:support@familylearningcompany.com">contact us</a> if you have some questions',
        });
      }
    }
  };

  render() {
    const { lang, isLandscape } = this.props;
    const {
      learningCenter,
      family,
      familyMembers,
      availableLocales,
      initialValues,
      imgNumber,
      processing,
      initialized,
    } = this.state;

    if (!initialized) {
      return null;
    }

    const ellipseProps = isLandscape
      ? {
          cx: '63%',
          cy: '60%',
          rx: '60%',
          ry: '60%',
        }
      : {
          cx: '63%',
          cy: '60%',
          rx: '60%',
          ry: '60%',
          // cx: '50%',
          // cy: '50%',
          // rx: '50%',
          // ry: '50%',
        };

    const bgImg = `${imagesURL}/Images/Login/background${imgNumber}.jpg`;

    return (
      <>
        {processing && <Spinner />}

        <div
          style={{
            fontFamily: `var(--font-${langToFont[lang?.code] || 'base'})`,
          }}
          className={cs(classes.wrapper, {
            [classes.hidden]: initialValues && initialValues.autoLogin,
          })}
          // style={{
          //   backgroundImage: `url(${imagesURL}/Images/Login/background_play.jpg)`,
          // }}
        >
          <div className={classes.inner}>
            <div className={classes.bgImg}>
              <svg width="1200" height="960" viewBox="0 0 1200 960">
                <defs>
                  <filter id="filter">
                    <feGaussianBlur stdDeviation="40" />
                  </filter>

                  <mask id="mask">
                    <ellipse
                      {...ellipseProps}
                      fill="white"
                      filter="url(#filter)"
                    ></ellipse>
                  </mask>
                </defs>

                <image
                  xlinkHref={bgImg}
                  width="1200"
                  height="960"
                  mask="url(#mask)"
                ></image>
              </svg>

              {/* <img src={bgImg} alt=""/> */}
            </div>
            <div className={classes.top}>
              <div className={classes.logo}>
                <a
                  href="https://familylearningcompany.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${imagesURL}/Images/Login/FLCLogo.png`}
                    alt="Flink Learning logo"
                  />
                </a>
              </div>

              {learningCenter && (
                <div className={classes.helpBlock}>
                  <LoginHelp lang={lang} classes={classes} />
                </div>
              )}

              {learningCenter && (
                <h1 className={classes.centerName}>
                  {learningCenter.name}
                </h1>
              )}
            </div>

            <LoginForm
              initialValues={initialValues}
              family={{
                id: family,
                members: familyMembers,
              }}
              learningCenter={learningCenter}
              availableLocales={availableLocales}
              onChangeLocale={this.onChangeLocale}
              onError={this.loginErrorHandler}
            />
          </div>
        </div>
      </>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  lang: PropTypes.object,
  common: PropTypes.object.isRequired,
  changeLocale: PropTypes.func.isRequired,
  getMasterOrganizationById: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, status, common }) => ({
  auth,
  lang: status.lang,
  common,
  translate: status.translate,
  isLandscape: status.aspectRatio > 1,
});

export default compose(
  connect(mapStateToProps, {
    changeLocale,
    getMasterOrganizationById,
    showInfo,
    showConfirmPromise,
    showError,
  })
)(Login);
