import removeParenthesis from './removeParenthesis';

export const validateWordlist = (wordlist) => {
  if (!wordlist) return;
  const { name, educationalCategories, words } = wordlist;

  if (!name) return false;
  if (!educationalCategories || !educationalCategories.length) return false;
  if (!words || words.length < 5) return false;

  return true;
};

export const getWordPictureFilename = (word) => {
  if (!word || !word.hasPicture) return null;
  if (word.pictureFilename) return word.pictureFilename;

  let pictureBase = word.locale === 'en' ? word.word : word.relatedWord;

  pictureBase = removeParenthesis(pictureBase.split(' ').join('_'));

  return pictureBase.trim() + '.jpg';
};
