import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Modal from './Modal';

const styles = {
  btnWrapper: { textAlign: 'center', marginTop: '2rem' },
  okBtn: {
    fontSize: '1.4rem',
    backgroundColor: '#df2a26;',
    color: '#fff',
    '&:hover': { backgroundColor: '#a11b18;' }
  }
};

const ErrorDialog = ({ error, showError, classes, translate }) => {
  if (!error) return null;

  const { cb, message, html, bigZIndex } = error;

  return (
    <Modal
      on={!!error}
      toggle={() => {
        showError(null);
        cb && cb();
      }}
      bigZIndex={bigZIndex}
    >
      <div>
        {html ? (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          <p>{message}</p>
        )}

        <div className={classes.btnWrapper}>
          <Button
            onClick={() => {
              showError(null);
              cb && cb();
            }}
            className={classes.okBtn}
            variant="contained"
            color="secondary"
          >
            {translate(191, 'Ok', true)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ErrorDialog.propTypes = {
  error: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showError: PropTypes.func.isRequired
};

export default withStyles(styles)(ErrorDialog);
