import shortid from 'shortid';

import { addIdToFilename, removeIdFromFilename } from 'utils';
import type { NgeliProblem } from './ngeli.types';

export const createProblem = (): NgeliProblem => ({
  id: shortid.generate(),
  noun: {
    text: '',
    definition: '',
    image: '',
    audio: '',
    categories: [],
    correctCategory: '',
  },
  adjective: {
    text: '',
    definition: '',
    image: '',
    audio: '',
    prefixes: [],
  },
  verb: {
    text: '',
    definition: '',
    image: '',
    audio: '',
    prefixes: [],
  },
  sentenceAudio: '',
  sentenceText: '',
});

export const validateProblem = (problem: NgeliProblem) => {
  const { noun, adjective, verb, sentenceAudio } = problem;
  return (
    noun.text &&
    (noun.definition || noun.image) &&
    noun.categories.length &&
    noun.correctCategory &&
    adjective.text &&
    (adjective.definition || adjective.image) &&
    !!adjective.prefixes[0] &&
    verb.text &&
    (verb.definition || verb.image) &&
    !!verb.prefixes[0] &&
    sentenceAudio
  );
};

export const getContentFilesFromProblem = (problem: NgeliProblem) => {
  let files = [];
  const { noun, adjective, verb, sentenceAudio } = problem;

  noun.image && files.push(noun.image);
  noun.audio && files.push(noun.audio);
  adjective.image && files.push(adjective.image);
  adjective.audio && files.push(adjective.audio);
  verb.image && files.push(verb.image);
  verb.audio && files.push(verb.audio);
  sentenceAudio && files.push(sentenceAudio);

  return files;
};

export const copyProblem = (problem: any) => {
  const files: { src: string; dest: string }[] = [];
  const copy = JSON.parse(JSON.stringify(problem));
  copy.id = shortid.generate();

  ['noun', 'adjective', 'verb'].forEach((type) => {
    ['image', 'audio'].forEach((prop) => {
      const filename = problem[type][prop];
      if (filename) {
        const newFilename = addIdToFilename(removeIdFromFilename(filename));
        files.push({ src: filename, dest: newFilename });
        copy[type][prop] = newFilename;
      }
    });
  });

  return [copy, files];
};
