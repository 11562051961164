import shortid from 'shortid';

export const validateProblem = problem => {
  return !!(
    problem.sentence &&
    problem.sentence.trim().split(' ').length >= 3 &&
    problem.wordsMask &&
    problem.wordsMask.indexOf('0') !== -1
  );
};

export const createProblem = () => ({
  id: shortid.generate(),
  options: [],
  wordsMask: ''
});

export const getContentFilesFromProblem = problem => {
  const files = [];
  const { audio } = problem;

  audio && files.push(audio);

  return files;
};
