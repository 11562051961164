import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { get } from "lodash";

const RadioGroup = ({
  name,
  numeric,
  changeHandler,
  options = [],
  current
}) => {
  return (
    <StyledWrapper>
      {options.map(({ value, label }) => (
        <StyledRadioLabel key={value}>
          <input
            type="radio"
            name={name}
            onChange={e => {
              changeHandler(name, numeric ? +e.target.value : e.target.value);
            }}
            value={value}
            checked={get(current, name) === value}
          />{" "}
          {label}
        </StyledRadioLabel>
      ))}
    </StyledWrapper>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  options: PropTypes.array
};

export default RadioGroup;

const StyledRadioLabel = styled.label`
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 20px !important;
`;

const StyledWrapper = styled.div`
  margin-bottom: 20px;
`;
