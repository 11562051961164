import { axiosAPI, responseHandler } from 'utils';

const apiPoint = '/api/config/';

export default {
  get() {
    return responseHandler(axiosAPI.get(apiPoint));
  },
  update(data) {
    return responseHandler(axiosAPI.put(apiPoint, data));
  },
};
