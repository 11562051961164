import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

import itemType from '../draggable-item-type';
import { Transition } from 'react-transition-group';
import Item from '../Item/Item';

const itemSource = {
  beginDrag(props) {
    return props;
  },
  canDrag(props) {
    return !props.itemData.fixed && !props.isResolved;
  },
  endDrag(props, monitor) {
    const { onDropHandler, itemData } = props;
    const itemId = itemData.id;
    const didDrop = monitor.didDrop();
    const dropResult = monitor.getDropResult();

    if (
      (!didDrop && !itemData.inSlot) ||
      (didDrop && !itemData.inSlot && dropResult.isBoard)
    ) {
      return;
    }

    if (!didDrop && itemData.inSlot) {
      return onDropHandler({ itemId });
    }

    if (dropResult.isBoard) {
      return onDropHandler({ itemId });
    }

    if (dropResult.isSlot) {
      if (itemData.slotIdx === dropResult.slotIdx) return;
      return onDropHandler({ itemId, slotIdx: dropResult.slotIdx });
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function DraggableItem({ itemData, connectDragSource, isDragging, className }) {
  const itemRef = useRef(null);
  const { moveFrom } = itemData;
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  let styles = {
    default: {
      zIndex: '1',
      maxWidth: '100%',
      overflow: 'hidden',
      textAlign: 'center',
      position: 'relative',
      opacity: isDragging && '0.5'
    }
  };

  if (moveFrom && didMount) {
    const { current } = itemRef;

    const fromRect = moveFrom.getBoundingClientRect();
    const currentRect = current.getBoundingClientRect();

    const from = {
      x: fromRect.x + fromRect.width / 2 - currentRect.width / 2,
      y: fromRect.y + fromRect.height / 2 - currentRect.height / 2
    };

    const deltaX = from.x - currentRect.x + 'px';
    const deltaY = from.y - currentRect.y + 'px';

    styles = {
      ...styles,
      entering: {
        transform: `translate(${deltaX}, ${deltaY})`
      },
      entered: {
        transform: 'translate(0 ,0)',
        transition: 'transform 700ms ease-in-out 0s'
      }
    };
  }

  return (
    <Transition in={!!(moveFrom && itemRef.current)} timeout={0}>
      {state =>
        connectDragSource(
          <div
            ref={itemRef}
            style={{ ...styles.default, ...styles[state] }}
            className={className}
          >
            <Item data={itemData.item} />
          </div>
        )
      }
    </Transition>
  );
}

DraggableItem.propTypes = {
  itemData: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  onDropHandler: PropTypes.func.isRequired,
  isDragging: PropTypes.bool
};

export default DragSource(itemType, itemSource, collect)(DraggableItem);
