const validateWord = word => {
  if (!word) return false;

  const trimmedWord = word.trim();
  const length = trimmedWord.length;
  return !!(length >= 3 && length <= 12);
};

export const validateProblem = problem => {
  return !!(problem.words && problem.words.filter(validateWord).length >= 2);
};

const validProblemExists = problems => {
  for (let i = 0; i < problems.length; i++) {
    if (validateProblem(problems[i])) return true;
  }

  return false;
};

export default ({ options, gameData }) => {
  return !(
    !options ||
    !gameData ||
    !gameData.problems ||
    !gameData.problems.length ||
    !validProblemExists(gameData.problems)
  );
};
