import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";

import { filesURL } from "config";
import classes from "./ClipartList.module.scss";

const selectStyles = {
  control: styles => ({
    ...styles,
    width: "200px"
  })
};

const ClipartList = ({
  clipart,
  onPick,
  translate,
  activeCategory,
  changeCategoryHandler
}) => {
  const categories = useMemo(
    () =>
      _.chain(clipart)
        .keys()
        .map(cat => {
          const [catName, catStringNumber] = cat.split("-");
          return { label: translate(catStringNumber, catName), value: cat };
        })
        .sortBy("name")
        .value(),
    [clipart, translate]
  );

  const activeOption = activeCategory || categories[0];

  let items = _.sortBy(clipart[activeOption.value], item =>
    item.name.toLowerCase()
  );

  return (
    <div className={classes.clipartWrapper}>
      <div className={classes.filterBlock}>
        <p>{translate(433, "Choose a clipart set:")}</p>
        <Select
          value={activeOption}
          onChange={category => changeCategoryHandler(category)}
          options={categories}
          styles={selectStyles}
        />
      </div>

      <div className={classes.clipartGrid}>
        {items.map(item => {
          return _.isObject(item) ? (
            <figure
              key={item.key}
              className={classes.clipartItem}
              onClick={() => onPick(item)}
            >
              <img src={`${filesURL}/${item.key}`} alt="" />
              <figcaption>{item.name}</figcaption>
            </figure>
          ) : (
            <div key={item} className={classes.clipartItem} />
          );
        })}
      </div>
    </div>
  );
};

ClipartList.propTypes = {
  clipart: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired
};

export default ClipartList;
