import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SelectGroup = ({
  label,
  options,
  current,
  name,
  translate,
  changeHandler,
  numeric
}) => {
  useEffect(() => {
    if (current[name] === undefined || current[name] === null) {
      const [firstOption] = options;

      const value =
        typeof firstOption === "object" ? firstOption.value : firstOption;

      changeHandler(name, numeric && !isNaN(+value) ? +value : value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current[name]]);

  return (
    <StyledSelectGroup>
      <label>{label}</label>
      <select
        name={name}
        onChange={e =>
          changeHandler(
            name,
            numeric && !isNaN(+e.target.value)
              ? +e.target.value
              : e.target.value
          )
        }
        value={current[name] || ""}
      >
        {options.map((opt, idx) =>
          typeof opt === "object" ? (
            <option key={opt.value} value={opt.value}>
              {opt.stringNumber
                ? translate(opt.stringNumber, opt.label)
                : opt.label}
            </option>
          ) : (
            <option key={opt} value={opt}>
              {opt}
            </option>
          )
        )}
      </select>
    </StyledSelectGroup>
  );
};

SelectGroup.propTypes = {
  translate: PropTypes.func.isRequired,
  options: PropTypes.array
};

export default SelectGroup;

const StyledSelectGroup = styled.p`
  display: flex;
  align-items: center;
`;
