import WordSearch from "./WordSearch";
import validateActivity from "./validateWordSearch";
import convertOldActivity from "./convertOldWordSearch";
import { validateWords, generateActivityFromWords } from "./wordSearchHelpers";

export default {
  Template: WordSearch,
  convertOldActivity,
  validateActivity,
  optionsArray: ["fontFamily", "fontColor", "wordSearch"],
  generateActivityFromWords,
  validateWords
};
