import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    fontSize: '120%',
    fontWeight: 500,
    textAlign: 'left',
    padding: '20px 8px 8px',
    borderBottom: '1px solid #d8d8d8',
  },
  thSmall: {
    fontSize: '90%',
    fontWeight: 500,
    textAlign: 'left',
    padding: '20px 8px 8px',
    borderBottom: '1px solid #d8d8d8',
  },
  td: {
    padding: 8,
  },
  b: {
    fontWeight: 500,
  },
};

const ReportsTable = ({ data }) => {
  if (!data) return null;

  const columnsNumber = Math.max(
    ...data.map((chunk) =>
      chunk && chunk.rows
        ? Math.max(...chunk.rows.map((row) => (row ? row.length : 0)))
        : 0
    )
  );
  return (
    <table style={styles.table}>
      <tbody>
        {data.map((chunk, idx) => {
          if (!chunk) return null;

          return (
            <Fragment key={idx}>
              {chunk.header && (
                <tr>
                  <th colSpan={columnsNumber} style={styles.th}>
                    {chunk.header}
                  </th>
                </tr>
              )}

              {chunk.headers && (
                <tr>
                  {chunk.headers.map((header, idx) => (
                    <th style={styles.thSmall} key={idx}>
                      {header}
                    </th>
                  ))}
                </tr>
              )}

              {chunk.rows &&
                chunk.rows.map(
                  (row, idx) =>
                    row && (
                      <tr key={idx}>
                        {row.map((val, idx) => {
                          const colSpan = val.colSpan || 1;
                          const content =
                            val.content !== undefined ? val.content : val;
                          const align = val.align || 'left';

                          return (
                            <td
                              key={idx}
                              style={styles.td}
                              colSpan={colSpan}
                              align={align}
                            >
                              {idx ? (
                                <b style={styles.b}>{content}</b>
                              ) : (
                                content
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    )
                )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

ReportsTable.propTypes = {
  data: PropTypes.array,
};

export default ReportsTable;
