function addLeadingZero(val) {
  if (val < 10) {
    val = "0" + val;
  }

  return val;
}

export default ms => {
  // 1- Convert to seconds:
  let seconds = Math.floor(ms / 1000);

  // 2- Extract hours:
  let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  hours = addLeadingZero(hours);

  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  let minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  minutes = addLeadingZero(minutes);

  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;
  seconds = addLeadingZero(seconds);

  return hours + ":" + minutes + ":" + seconds;
};
