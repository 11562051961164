import SpellingConnectables from './SpellingConnectables';
import convertOldActivity from './convertOldSpellingConnectables';
import validateActivity from './validateSpellingConnectables';
import {
  validateWords,
  generateActivityFromWords
} from './spellingConnectablesHelpers';

export default {
  Template: SpellingConnectables,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'fontFamily',
    'fontColor',
    'fontSize',
    'randomOrder',
    'showAnswer',
    'questionsLimit'
  ],
  validateWords,
  generateActivityFromWords
};
