import { axiosAPI as axios } from 'utils';
import jwt_decode from 'jwt-decode';

import { withLoading, setAuthToken, dream } from 'utils';
import { LOGIN, LOGOUT } from './types';

const filter = 'auth';

export const getLoginScreenByEmail = (email) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const response = await axios.get(
        `/api/auth/login-screen-by-email/${email}`
      );
      return response.data;
    } catch (err) {
      return (err.response && err.response.data) || { status: false };
    }
  });

export const login = (loginData) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.post('/api/auth/login', loginData);

      dispatch(setAuthFromToken(res.data.token));

      return { success: true };
    } catch (err) {
      return { success: false, data: err.response.data };
    }
  });

export const cleverLogin = (code, url) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.post('/api/auth/clever-login', { code, url });
      dispatch(setAuthFromToken(res.data.token));

      return { success: true };
    } catch (err) {
      return { success: false, data: err.response.data };
    }
  });

export const updateToken = (token) => (dispatch) => {
  withLoading(dispatch, async () => {
    try {
      dispatch(setAuthFromToken(token));

      const res = await axios.get('/api/auth/updateToken');

      dispatch(setAuthFromToken(res.data.token));
    } catch (err) {
      console.log(err);
      dispatch(logout());
    }
  });
};

export const forgotPassword = (email) => (dispatch) =>
  withLoading(dispatch, async () => {
    try {
      await axios.post('/api/users/forgot-password', { email });
      return { success: true };
    } catch (err) {
      return { success: false, data: err.response.data };
    }
  });

export const setAuthFromToken = (token) => {
  try {
    // Set token to local storage
    localStorage.setItem('jwtToken', token);
    // Decode token to get user data
    const decoded = jwt_decode(token);

    // Set token to Auth header
    setAuthToken(token);

    // If user not learner, it should have userRole object
    if (decoded.user && !dream.isObject(decoded.userRole)) {
      throw new Error('User Role should be an object');
    }

    return {
      filter,
      type: LOGIN,
      payload: decoded,
    };
  } catch (e) {
    console.log(e.message);
    localStorage.removeItem('jwtToken');
    return logout();
  }
};

export const logout = () => {
  // Remove token from ls
  localStorage.removeItem('jwtToken');

  // Remove auth header for future requests
  setAuthToken();

  return {
    type: LOGOUT,
  };
};
