import { ADD_DATA, DELETE_DATA, EDIT_DATA, IMPORT_TEACHERS } from '../types';
import { axiosAPI as axios, withLoading } from 'utils';

import { showError, showInfo } from '../statusActions';

const filter = 'admin';

export const createTeacher = data => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    try {
      const res = await axios.post(`/api/admin/teachers/add`, data);

      dispatch({
        filter,
        type: ADD_DATA,
        payload: res.data,
        payloadType: 'users'
      });

      res.success = true;

      return res;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { data: { _error: 'No response from server' } };
      }

      console.log(result);

      return result;
    }
  });

export const editTeacher = data => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    try {
      const res = await axios.post(`/api/admin/teachers/edit`, data);

      dispatch({
        filter,
        payloadType: 'users',
        type: EDIT_DATA,
        payload: res.data
      });

      result.success = true;

      return result;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { data: { _error: 'No response from server' } };
      }

      return result;
    }
  });

export const importTeachers = data => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.post(`/api/admin/teachers/import`, data);

      const { teachers, alreadyInUse = [] } = res.data;

      dispatch({
        filter,
        type: IMPORT_TEACHERS,
        payload: teachers
      });

      if (alreadyInUse.length) {
        dispatch(
          showError({
            message: `
            Some usernames are already in use: 
            <ol>${alreadyInUse.map(s => `<li>${s}</li>`).join('')}</ol>
            ${
              teachers.length
                ? 'All others were imported'
                : 'None of the teachers were imported'
            }
            
          `,
            html: true
          })
        );
      } else {
        dispatch(showInfo({ message: 'Success' }));
      }
    } catch (err) {
      console.log(err);
      dispatch(
        showError({
          message: 'Something goes wrong, try again please'
        })
      );
    }
  });

export const deleteTeacher = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      await axios.delete(`/api/admin/teachers/${id}`);

      dispatch({
        filter,
        type: DELETE_DATA,
        payload: id,
        payloadType: 'users'
      });
    } catch (err) {
      console.log(err);
    }
  });
