import React from 'react';
import classes from './WhiteBox.module.scss';
import classnames from 'classnames';

type Props = {
  children: React.ReactNode;
  pixels?: boolean;
  outerClass?: string;
  innerClass?: string;
  outerStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
};

const WhiteBox = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      pixels = false,
      outerClass = '',
      innerClass = '',
      outerStyle = {},
      innerStyle = {},
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={outerStyle}
        className={classnames(classes.outer, outerClass, {
          [classes.pixels]: pixels,
        })}
      >
        <div
          style={innerStyle}
          className={classnames(classes.inner, innerClass)}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default WhiteBox;
