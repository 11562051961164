import { useDispatch, useSelector } from 'react-redux';

import { changeLocale } from 'actions/statusActions';
import { imagesURL } from 'config';
import useRegisterLocales from './use-register-locales';
import classes from './Register.module.scss';

type RegisterHeaderProps = {};

const RegisterHeader = (props: RegisterHeaderProps) => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state: any) => state.status.lang);
  const localesOptions = useRegisterLocales();

  return (
    <header className={classes.header}>
      <div className={classes.headerTop}>
        <div className={classes.logoWrapper}>
          <img src={`${imagesURL}/Images/Login/FLCLogo.png`} alt="Flink logo" />
        </div>
        {localesOptions.length > 1 && (
          <select
            value={currentLanguage?.code}
            onChange={(e) => dispatch(changeLocale(e.target.value))}
          >
            {localesOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        )}
      </div>
    </header>
  );
};

export default RegisterHeader;
