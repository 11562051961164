import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    width: '0.9em!important',
    height: '0.9em!important',
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    fontSize: 24,
    transition: theme.transitions.create('fill', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

function MaterialFaIcon(props) {
  const classes = useStyles();

  return (
    <FontAwesomeIcon
      {...props}
      className={classNames(classes.root, props.className)}
    />
  );
}

export default MaterialFaIcon;
