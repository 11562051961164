import baseResolution from 'consts/base-resolution';

export default () => {
  const baseWidth = baseResolution.width / 2;
  const baseHeight = baseResolution.height / 2;
  const diffWidth = window.innerWidth / baseWidth;
  const diffHeight = window.innerHeight / baseHeight;

  return Math.min(diffWidth, diffHeight);
};
