import * as templates from 'consts/activity-templates';

import AllTogetherNow from '../all-together-now/convertOldAllTogetherNow';
import Concentration from '../concentration/convertOldConcentration';
import FindTheOne from '../find-the-one/convertOldFindTheOne';
import MatchingDefinitions from '../matching-definitions/convertOldMatchingDefinitions';
import MissingElements from '../missing-elements/convertOldMissingElements';
import MatchIt from '../match-it/convertOldMatchIt';
import OrderIt from '../order-it/convertOldOrderIt';
import MovingAnswers from '../moving-answers/convertOldMovingAnswers';
import ReadingDeluxe from '../reading-deluxe/convertOldReadingDeluxe';
import StoryStudio from '../story-studio/convertOldStoryStudio';
import SpellingConnectables from '../spelling-connectables/convertOldSpellingConnectables';
import SentenceConnectables from '../sentence-connectables/convertOldSentenceConnectables';
import WordConnectables from '../word-connectables/convertOldWordConnectables';
import WordSearch from '../word-search/convertOldWordSearch';
import Ebook from '../ebook/convertOldEbook';
import Ngeli  from '../ngeli/convertOldNgeli';

export default {
  [templates.ALL_TOGETHER_NOW]: AllTogetherNow,
  [templates.CONCENTRATION]: Concentration,
  [templates.EBOOK]: Ebook,
  [templates.FIND_THE_ONE]: FindTheOne,
  [templates.MATCHING_DEFINITIONS]: MatchingDefinitions,
  [templates.MISSING_ELEMENTS]: MissingElements,
  [templates.NGELI]: Ngeli,
  [templates.MATCH_IT]: MatchIt,
  [templates.ORDER_IT]: OrderIt,
  [templates.MOVING_ANSWERS]: MovingAnswers,
  [templates.READING_DELUXE]: ReadingDeluxe,
  [templates.STORY_STUDIO]: StoryStudio,
  [templates.SPELLING_CONNECTABLES]: SpellingConnectables,
  [templates.SENTENCE_CONNECTABLES]: SentenceConnectables,
  [templates.WORD_CONNECTABLES]: WordConnectables,
  [templates.WORD_SEARCH]: WordSearch,
};
