import _ from 'lodash';

export default (problems, options, validateFn, mapFn = (item) => item) => {
  // Remove invalid problems
  let filteredProblems = problems.filter(validateFn).map(mapFn);

  const { questionsLimit, randomOrder } = options;

  filteredProblems = randomOrder
    ? _.shuffle(filteredProblems)
    : filteredProblems;

  // Need to trim?
  if (
    questionsLimit &&
    questionsLimit > 0 &&
    questionsLimit < filteredProblems.length
  ) {
    filteredProblems = _.take(filteredProblems, questionsLimit);
  }

  return filteredProblems;
};
