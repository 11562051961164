import * as React from 'react';

// classes
import classes from './AuthenticationForm.module.scss';

// redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslate } from 'components/hocs';

// history
import { useHistory } from 'react-router';

// auth actions
import { login, forgotPassword } from 'actions/authActions';

// status actions
import { showInfo } from 'actions/statusActions';

const AuthenticationForm = (props) => {
    const history = useHistory();
    const { 
        emailRef, 
        translate, 
        showInfo, 
        forgotPassword, 
        login, 
        toggleHandler,
        familyLoginUrl,
    } = props;

    const [error, setError] = React.useState('');
    const passwordRef = React.useRef();

    React.useEffect(() => {
        function modalCloseHandler(event) {
            if (event.keyCode !== 27) return;
            toggleHandler(false); 
        }

        document.addEventListener('keydown', modalCloseHandler);
        return () => document.removeEventListener('keydown', modalCloseHandler);
    }, [toggleHandler]);

    const signIn = React.useCallback(async () => {
        const username = emailRef.current.value;
        const password = passwordRef.current.value;

        const response = await login({ username, password });
        if (!response.success) {
            const error = response.data.errors?.password;
            const errorMessage =
              typeof error === 'object'
                ? translate(error.stringNumber, error.message)
                : error;
            setError(errorMessage ?? 'Unknown error');
        }

    }, [setError, translate, emailRef, login]);

    const handleFamilyLogin = React.useCallback(() => {
        return history.push(familyLoginUrl);
    }, [history, familyLoginUrl]);

    const resetPassword = React.useCallback(async () => {
        const response = await forgotPassword(emailRef.current.value);
        if (!response.success) {
            return setError('There was a problem with password reset');
        }

        const message = translate(834, 'The email has been sent. Please check your email and follow the instructions.');
        showInfo({ message });
    }, [forgotPassword, emailRef, translate, showInfo]);
    
    return (
        <form className={classes.form} onSubmit={(event) => {
            event.preventDefault();
            signIn();
        }}>

            <input
                placeholder={translate(103, 'Password')}
                type="password"
                required
                ref={passwordRef}
            />

            {error && <p className={classes.formError}>{error}</p>}

            <button type="button" className={classes.signIn} onClick={signIn}>
                {translate(1010, 'Login')}
            </button>

            {!!familyLoginUrl && (
                <button type="button" className={classes.signIn} onClick={handleFamilyLogin}>
                    {translate(1011, 'Sign in as a family')}
                </button>
            )}

            <button type="button" className={classes.forgotPassword} onClick={resetPassword}>
                {translate(105, 'Forgot Password')}
            </button>

        </form>
    );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    login,
    showInfo,
    forgotPassword,
};

export default compose(
    withTranslate,
    connect(mapStateToProps, mapDispatchToProps),
)(AuthenticationForm);
