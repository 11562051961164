import {
  LOADING,
  UPLOADING,
  SET_CURRENT_PAGE,
  CHANGE_LOCALE,
  SET_ASPECT_RATIO,
  START_REQUEST,
  END_REQUEST,
  SHOW_ERROR,
  SHOW_INFO,
  SHOW_PROMPT,
  SHOW_CONFIRM,
  TOGGLE_HELP,
  CHANGE_CURRENT_PAGE_TITLE,
  SET_HELP_COORDS
} from "actions/types";
import { getPageTitle } from "utils";
import _ from "lodash";

export const INITIAL_STATE = {
  aspectRatio: null,
  isLoading: false,
  uploading: null,
  pendingRequests: [],
  currentPage: null,
  prevPages: [],
  lang: null,
  error: null,
  info: null,
  prompt: null,
  confirm: null,
  showHelp: false,
  helpCoords: null,
  translate: null
};

const filter = "status";

export default (state = INITIAL_STATE, action) => {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        translate: action.payload.translate,
        lang: action.payload.locale
      };
    case START_REQUEST:
      return {
        ...state,
        pendingRequests: [...state.pendingRequests, action.payload],
        isLoading: true
      };
    case SHOW_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SHOW_INFO:
      return {
        ...state,
        info: action.payload
      };
    case SHOW_PROMPT:
      return {
        ...state,
        prompt: action.payload
      };
    case SHOW_CONFIRM:
      return {
        ...state,
        confirm: action.payload
      };
    case END_REQUEST:
      const filteredPendingRequests = state.pendingRequests.filter(
        req => req !== action.payload
      );

      if (!filteredPendingRequests.length) {
        // If no pnding requests
        document.dispatchEvent(new CustomEvent("allRequestsCompleted"));
      }

      return {
        ...state,
        pendingRequests: filteredPendingRequests,
        isLoading: filteredPendingRequests.length !== 0
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case UPLOADING:
      return {
        ...state,
        uploading: action.payload
      };
    case CHANGE_CURRENT_PAGE_TITLE:
      document.title = action.payload;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          title: action.payload
        }
      };
    case SET_CURRENT_PAGE:
      const { payload: match } = action;
      if (!match.isExact) return state;

      const pageTitle = getPageTitle(match);

      document.title = pageTitle;

      const prevPages = state.currentPage
        ? _.takeRight([...state.prevPages, state.currentPage], 5)
        : state.prevPages;

      return {
        ...state,
        prevPages,
        currentPage: {
          match: match,
          title: pageTitle
        }
      };
    case TOGGLE_HELP:
      return {
        ...state,
        showHelp: !state.showHelp
      };
    case SET_HELP_COORDS:
      return {
        ...state,
        helpCoords: action.payload
      };
    case SET_ASPECT_RATIO:
      return {
        ...state,
        aspectRatio: action.payload
      };
    default:
      return state;
  }
};
