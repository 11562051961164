import _ from 'lodash';

import {
  SET_COMMON_DATA,
  ADD_DATA,
  EDIT_DATA,
  DELETE_DATA,
  UPDATE_CONFIG,
} from 'actions/types';

const INITIAL_STATE = null;

const filter = 'common';

export default function (state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_COMMON_DATA:
      return {
        ...action.payload,
      };
    case UPDATE_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case ADD_DATA:
      return {
        ...state,
        [action.payloadType]: state[action.payloadType] && [
          ...state[action.payloadType],
          action.payload,
        ],
      };
    case EDIT_DATA:
      return {
        ...state,
        [action.payloadType]: state[action.payloadType] && [
          ...state[action.payloadType].map((data) =>
            data._id === action.payload._id ? action.payload : data
          ),
        ],
      };
    case DELETE_DATA:
      if (typeof action.payload === 'string') {
        return {
          ...state,
          [action.payloadType]: state[action.payloadType].filter(
            (data) => data._id !== action.payload
          ),
        };
      }

      const deletedData = action.payload;
      const stateCopy = { ...state };

      _.map(deletedData, (deletedIDs, dataName) => {
        if (!stateCopy[dataName]) return;

        stateCopy[dataName] = stateCopy[dataName].filter(
          (data) => !deletedIDs.includes(data._id)
        );
      });

      return stateCopy;

    default:
      return state;
  }
}
