import { Howl, Howler } from 'howler';
import store from 'store';
import { setPlaying } from 'actions/audioActions';

const generateEvent = (detail) => {
  const event = new CustomEvent('audioEnded', { detail });
  document.dispatchEvent(event);
};

const changeStoreState = (...params) => {
  store.dispatch(setPlaying(...params));
};

class AudioPlayer {
  constructor() {
    const objectURL = URL.createObjectURL(new Blob([]));

    const sound = new Howl({
      src: [objectURL],
      format: 'mp3',
    });

    if (Howler.ctx && Howler.ctx.state && Howler.ctx.state === 'suspended') {
      Howler.ctx.resume().then(() => sound.unload());
    }
  }

  play() {
    // console.log('Started', this._src)
    this.sound.play();
    changeStoreState(this._src, true);
  }

  playSrc(src, options = {}) {
    // console.log('Setup', src)
    if (this._src === src) {
      // SRC already set
      this.sound.stop();
      this.play();
    } else {
      // Remove previous sound
      this.sound && this.sound.unload();

      this._src = src;

      const howlOptions = {
        src: Array.isArray(src) ? src : [src],
        onload: () => {
          this.play();
        },
        onend: () => {
          generateEvent({ src });
          options.onSuccessEnd && options.onSuccessEnd();
          options.onEnd && options.onEnd();

          changeStoreState(this._src, false);
        },
        onloaderror: (soundId, event) => {
          console.log('Load audio error', src, event);

          generateEvent({
            src,
            errorEvent: event,
            message: 'Error while loading audio file',
          });
          options.onEnd && options.onEnd();
          changeStoreState(this._src, false);
        },
        onplayerror: (soundId, event) => {
          console.log('Play audio error', event);
          generateEvent({
            src,
            errorEvent: event,
            message: 'Error while playing audio file',
          });
          options.onEnd && options.onEnd();
          changeStoreState(this._src, false);
        },
      };

      if (options.format) {
        howlOptions.format = options.format;
      }

      // Create new sound
      this.sound = new Howl(howlOptions);
    }
  }

  toggleSequence(array, options = {}) {
    const lastIdx = array.length - 1;

    let currentIdx = 0;

    const setupNext = () => {
      if (currentIdx === lastIdx) {
        this.togglePlaySrc(array[currentIdx++], options);
      } else {
        this.togglePlaySrc(array[currentIdx++], {
          ...options,
          onEnd: undefined,
          onSuccessEnd: setupNext,
        });
      }
    };

    setupNext();
  }

  togglePlaySrc(src, options) {
    if (this._src === src) {
      this.togglePlay();
    } else {
      this.playSrc(src, options);
    }
  }

  togglePlay() {
    if (!this.sound) return;
    this.sound.playing() ? this.stop() : this.play();
  }

  stop(src) {
    if (!this.sound) return;

    // If src was passed then stop audio only if src is match
    if (!src || src === this._src) {
      this.sound.stop();
      changeStoreState(this._src, false);
    }
  }

  getCurrentSrc() {
    return this._src;
  }

  getCurrentlyPlaying() {
    if (!this.sound) return false;

    return this.sound.playing();
  }
}

window.audioPlayer = new AudioPlayer();
