import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import { Scope } from "utils";
import classes from "./CategoriesFilter.module.scss";
import CategoryColumn from "./CategoryColumn/CategoryColumn";
import { withTranslate } from "components/hocs";
import { SUPER, MASTER_ADMIN } from "consts/user-roles";

class CategoriesFilter extends Component {
  state = {
    selectedScope: null,
    sortedScopes: []
  };

  componentDidMount() {
    const { scopes, auth } = this.props;

    const {
      user: { available },
      userRole
    } = auth;

    let sortedScopes = [];

    if (userRole.alias !== MASTER_ADMIN && userRole.alias !== SUPER) {
      sortedScopes = _.chain(scopes)
        .filter(s => available && available.scopes && available.scopes[s._id])
        .sortBy("name")
        .value();
    } else {
      sortedScopes = _.sortBy(scopes, "name");
    }

    this.setState({ sortedScopes });
  }

  componentDidUpdate() {
    if (
      (!this.props.filter || !this.props.filter.scopeId) &&
      this.state.selectedScope
    ) {
      this.setState({ selectedScope: null });
    }
  }

  setScope = scopeId => {
    const { changeHandler } = this.props;
    const { sortedScopes } = this.state;

    if (!scopeId) {
      changeHandler({});
      return this.setState({ selectedScope: null });
    }

    const scopeData = _.find(sortedScopes, { _id: scopeId });
    const filter = { scopeId: scopeData._id };

    changeHandler(filter);

    this.setState({
      selectedScope: new Scope(scopeData)
    });
  };

  localChangeHandler = (prop, val) => {
    const { changeHandler, filter = {} } = this.props;
    let changedFilter = { ...filter };

    if (val) {
      changedFilter[prop] = val;
    } else {
      delete changedFilter[prop];
    }

    switch (prop) {
      case "grade":
        delete changedFilter.firstCategory;
        delete changedFilter.secondCategory;
        delete changedFilter.thirdCategory;
        break;
      case "level":
        delete changedFilter.firstCategory;
        delete changedFilter.secondCategory;
        delete changedFilter.thirdCategory;
        break;
      case "firstCategory":
        delete changedFilter.secondCategory;
        delete changedFilter.thirdCategory;
        break;
      case "secondCategory":
        delete changedFilter.thirdCategory;
        break;
      default:
    }

    changeHandler(changedFilter);
  };

  render() {
    const { translate, filter = {} } = this.props;
    const { selectedScope, sortedScopes } = this.state;

    let menuCategoryTitle = "";
    let firstCategoryTitle = "";
    let secondCategoryTitle = "";
    let thirdCategoryTitle = "";

    let menuCategoryItems = [];
    let firstCategoryItems = [];
    let secondCategoryItems = [];
    let thirdCategoryItems = [];

    let singleFirstMenu = "";

    if (selectedScope) {
      singleFirstMenu = selectedScope.getSingleFirstMenuTitle();
      menuCategoryTitle = _.capitalize(selectedScope.getFirstMenu());
      firstCategoryTitle = selectedScope.getFirstCategoryTitle();
      secondCategoryTitle = selectedScope.getSecondCategoryTitle();
      thirdCategoryTitle = selectedScope.getThirdCategoryTitle();

      menuCategoryItems = selectedScope.getFirstMenuItems();

      const {
        [singleFirstMenu]: selectedFirstMenu,
        firstCategory: selectedFirstCategory,
        secondCategory: selectedSecondCategory
      } = filter;

      if (selectedFirstMenu) {
        firstCategoryItems = selectedScope.getFirstCategoryItems(
          selectedFirstMenu
        );
      }

      if (selectedFirstCategory) {
        secondCategoryItems = selectedScope.getSecondCategoryItems(
          { firstCategoryId: selectedFirstCategory },
          selectedFirstMenu
        );
      }

      if (selectedSecondCategory) {
        thirdCategoryItems = selectedScope.getThirdCategoryItems(
          { secondCategoryId: selectedSecondCategory },
          selectedFirstMenu
        );
      }
    }

    return (
      <div className={classes.wrapper}>
        <div className={classes.categorySelectBox}>
          <label>{translate(368, "Educational Categories:")}</label>

          <select
            onChange={e => this.setScope(e.target.value)}
            value={filter.scopeId || ""}
          >
            <option value="">{translate(486, "Select Categories")}</option>
            {sortedScopes.map(scope => (
              <option key={scope._id} value={scope._id}>
                {scope.name}
              </option>
            ))}
          </select>
        </div>

        <div className={classes.categoriesColumnsWrapper}>
          <CategoryColumn
            title={menuCategoryTitle}
            classes={classes}
            items={menuCategoryItems}
            changeHandler={val => this.localChangeHandler(singleFirstMenu, val)}
            value={filter[singleFirstMenu]}
          />
          <CategoryColumn
            title={firstCategoryTitle}
            classes={classes}
            items={firstCategoryItems}
            changeHandler={val => this.localChangeHandler("firstCategory", val)}
            value={filter.firstCategory}
          />
          <CategoryColumn
            title={secondCategoryTitle}
            classes={classes}
            items={secondCategoryItems}
            changeHandler={val =>
              this.localChangeHandler("secondCategory", val)
            }
            value={filter.secondCategory}
          />
          <CategoryColumn
            title={thirdCategoryTitle}
            classes={classes}
            items={thirdCategoryItems}
            changeHandler={val => this.localChangeHandler("thirdCategory", val)}
            value={filter.thirdCategory}
          />
        </div>
      </div>
    );
  }
}

CategoriesFilter.propTypes = {
  auth: PropTypes.object.isRequired,
  scopes: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth, flinkMake }) => ({
  auth,
  scopes: flinkMake.common.scopes
});

export default compose(
  withTranslate,
  connect(mapStateToProps)
)(CategoriesFilter);
