import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store';
import { redirect } from 'utils';
import { showError } from 'actions/statusActions';
import { cleverLogin } from 'actions/authActions';
import { useCallback } from 'react';

const LoginClever = (
  { 
    cleverLogin,
    showError,
  }: { 
    cleverLogin: (code: String, url: String) => Promise<any>,
    showError: (error: object) => any,
  }
) => {
  const history = useHistory();
  const auth = useAppSelector((state) => state.auth);

  const cleverSignIn = useCallback(
    async () => {
      const query = new URLSearchParams(window.location.search);
      
      const url = 'https://test.flclogin.com/login/clever'; // window.location.origin + window.location.pathname,
      const code = query.get('code')!;
      
      const result = await cleverLogin(code, url);

      if (!result.success) {
        showError({
          message: `Clever sign-in failed: ${result.data.error}`,
          cb: () => window.location.replace('https://clever.com'),
        });
      }
    },
    [cleverLogin, showError],
  );

  useEffect(() => { 
    if (auth.isAuthenticated) {
      history.push({ pathname: redirect(auth) });
    } else {
      cleverSignIn();
    }
  }, [cleverSignIn, auth, history]);

  return null;
};

export default connect(null, {
  cleverLogin,
  showError,
})(LoginClever);
