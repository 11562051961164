import {
  SET_TTS_VOICE,
  SET_TTS_LOCALE,
} from 'actions/types';

const INITIAL_STATE = {
  locale: null,
  voice: 'male',
};

const filter = 'flinkMake/tts';

export default function(state = INITIAL_STATE, action) {
  if (action.filter !== filter) {
    return state;
  }

  switch (action.type) {
    case SET_TTS_VOICE:
      return {
        ...state,
        voice: action.payload,
      }
    case SET_TTS_LOCALE:
      return {
        ...state,
        locale: action.payload,
      }
    default:
      return state;
  }
}
