import _ from 'lodash';
import shortid from 'shortid';
import { convert, findFileEndWith } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {};

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  let oldTemplate = null;

  findFileEndWith(s3FilesList, '/zap.xml') && (oldTemplate = 'zap');
  findFileEndWith(s3FilesList, '/picturezap.xml') &&
    (oldTemplate = 'pictureZap');
  findFileEndWith(s3FilesList, '/capturedata.xml') && (oldTemplate = 'capture');
  findFileEndWith(s3FilesList, '/gotit.xml') && (oldTemplate = 'gotIt');

  if (!oldTemplate) {
    throw new Error('Moving template not recognized');
  }

  gameData.problems = gameData.record || gameData.entry;
  delete gameData.record;
  delete gameData.entry;

  // Get activity Image
  const { article: filename } = gameData;

  result.activityImage = filename;

  delete gameData.article;

  gameData.problems = gameData.problems.map((rec) => {
    const formattedQuestion = {
      ...rec,
      id: shortid.generate(),
    };

    formattedQuestion.incorrectAnswers = [];

    switch (oldTemplate) {
      case 'zap':
        _.forOwn(rec, (value, key, obj) => {
          if (_.startsWith(key, 'incorrect') && key !== 'incorrectAnswers') {
            value && formattedQuestion.incorrectAnswers.push({ text: value });
            delete formattedQuestion[key];
          }

          if (key === 'correct') {
            formattedQuestion.correct = { text: value };
          }
        });
        break;
      case 'capture':
        _.forOwn(rec, (value, key, obj) => {
          if (_.startsWith(key, 'incorrect') && key !== 'incorrectAnswers') {
            value && formattedQuestion.incorrectAnswers.push({ text: value });
            delete formattedQuestion[key];
            return;
          }

          switch (key) {
            case 'type1':
              // fill-in question if type1 === true
              (value === true || value === 'true') &&
                (formattedQuestion.fillIn = true);
              delete formattedQuestion[key];
              break;
            case 'begin1':
            case 'end1':
            case 'label1':
              delete formattedQuestion[key];
              break;
            case 'correct':
              formattedQuestion.correct = { text: value };
              break;
            default:
          }
        });
        break;
      case 'pictureZap':
        _.forOwn(rec, (value, key, obj) => {
          if (_.startsWith(key, 'incorrect') && key !== 'incorrectAnswers') {
            value && formattedQuestion.incorrectAnswers.push({ image: value });
            delete formattedQuestion[key];
          }

          if (key === 'correct') {
            formattedQuestion.correct = { image: value };
          }
        });
        break;
      case 'gotIt':
        _.forOwn(rec, (value, key, obj) => {
          if (_.startsWith(key, 'incorrect') && key !== 'incorrectAnswers') {
            value && formattedQuestion.incorrectAnswers.push(value);
            delete formattedQuestion[key];
          }

          if (key === 'correct') {
            formattedQuestion.correct = value;
          }
        });
        break;
      default:
    }

    return formattedQuestion;
  });

  switch (oldTemplate) {
    // These are the possible combinattions:
    // 1. Long answers with audio
    // 2. Long answers with no audio
    // 3. Images with audio
    // 4. Images without audio
    // 5. Nothing selected
    // 6. only audio selected
    // The first two are Got It.
    // The second two are Picture Zap (with the new feature of possible audio).
    // The fifth is Capture and the sixth is Zap (which CAN have audio, but doesn't need to but will have Stop It animations.
    case 'zap':
      gameData.withAudio = true;

      options.coloredAnswerBoxes = true;
      break;
    case 'pictureZap':
      gameData.withAudio = true;
      gameData.useImages = true;
      break;
    case 'capture':
      options.coloredAnswerBoxes = true;
      break;
    case 'gotIt':
      gameData.longAnswers = true;
      gameData.withAudio = true;

      options.coloredAnswerBoxes = true;
      !options.fontSize && (options.fontSize = 32);
      !options.fontFamily && (options.fontFamily = 'Arial');
      break;
    default:
  }

  result.gameData = gameData;
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
