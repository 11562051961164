import Template from './Ngeli';
import validateActivity from './validateNgeli';
import convertOldActivity from './convertOldNgeli';

export default {
  Template,
  validateActivity,
  convertOldActivity,
  optionsArray: [
    'delayBeforeNext',
    'randomOrder',
  ],
};
