import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { WhiteBox } from 'components/flink-play';
import ItemSlot from './ItemSlot/ItemSlot';
import classes from './ItemSlots.module.scss';

const ItemSlots = ({
  refs,
  count,
  items,
  ItemComponent,
  options,
  animation,
  isResolved,
  joinOnResolve,
  customClasses = {},
  onDrop,
  questions,
  questionBefore,
  questionAfter,
  animationSpeed
}) => {
  const { delayBeforeNext } = options;

  const delayBeforeExit = delayBeforeNext * 1000 - 1000;

  return (
    <div className={customClasses.positionedBox}>
      <WhiteBox
        outerClass={customClasses.wrapper}
        innerClass={customClasses.inner}
      >
        {_.times(count, idx => {
          const itemInThatBox = _.find(items, { slotIdx: idx });

          const question = questions && questions[idx];

          return (
            <CSSTransition
              appear
              mountOnEnter
              in={!isResolved}
              key={idx}
              timeout={{
                appear: animationSpeed,
                enter: animationSpeed,
                exit: animationSpeed + delayBeforeExit
              }}
              classNames={{
                appearActive: animation.in,
                enterActive: animation.in,
                exitActive: animation.out
              }}
            >
              {state => (
                <div
                  className={`animated ${customClasses.slotWrapper}`}
                  style={{
                    animationDelay:
                      (state === 'exiting' ? delayBeforeExit : 0) + 'ms',
                    animationDuration: animationSpeed + 'ms'
                  }}
                >
                  {questionBefore && question && (
                    <ItemComponent
                      data={question.data}
                      className={classnames(customClasses.question, {
                        [classes.fadeIn]: state === 'entering',
                        [classes.fadeOut]:
                          state === 'exiting' || state === 'exited'
                      })}
                      style={{
                        animationDelay:
                          (state === 'exiting' ? delayBeforeExit : 0) + 'ms',
                        animationDuration: animationSpeed + 'ms'
                      }}
                    />
                  )}
                  <ItemSlot
                    first={idx === 0}
                    last={idx === count - 1}
                    isResolved={isResolved}
                    joinOnResolve={joinOnResolve}
                    slotRef={refs[idx]}
                    className={classnames(customClasses.slot, {
                      [customClasses.fixedSlot]:
                        itemInThatBox && itemInThatBox.fixed,
                      [classes.fadeIn]: state === 'entering',
                      [classes.fadeOut]:
                        state === 'exiting' || state === 'exited'
                    })}
                    onDropHandler={onDrop}
                    key={idx}
                    item={itemInThatBox}
                    slotIdx={idx}
                    ItemComponent={ItemComponent}
                  />
                  {questionAfter && question && (
                    <ItemComponent
                      data={question.data}
                      className={classnames(customClasses.question, {
                        [classes.fadeIn]: state === 'entering',
                        [classes.fadeOut]:
                          state === 'exiting' || state === 'exited'
                      })}
                      style={{
                        animationDelay:
                          (state === 'exiting' ? delayBeforeExit : 0) + 'ms',
                        animationDuration: animationSpeed + 'ms'
                      }}
                    />
                  )}
                </div>
              )}
            </CSSTransition>
          );
        })}
      </WhiteBox>
    </div>
  );
};

ItemSlots.propTypes = {
  items: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  customClasses: PropTypes.object,
  joinOnResolve: PropTypes.bool,
  questions: PropTypes.array
};

export default ItemSlots;
