import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAnimationsList } from 'actions/solutionsActions';

const ParentVideosControls = ({
  locales,
  getAnimationsList,
  value,
  onChange,
}) => {
  const [parentVideos, setParentVideos] = useState(null);
  useEffect(() => {
    const getList = async () => {
      const result = await getAnimationsList(['ParentVideos']);
      setParentVideos(result.ParentVideos);
    };

    getList();
  }, [getAnimationsList]);

  return (
    <div style={{ width: 300 }}>
      <div style={{ margin: '2rem 0 1rem' }}>
        <b>Parent Videos:</b>
      </div>

      <div style={{ marginBottom: '1.5rem' }}>
        <label>
          <input
            type="radio"
            checked={!value}
            onChange={(e) => onChange(undefined)}
          />{' '}
          Use default
        </label>
        <label style={{ marginLeft: 15 }}>
          <input
            type="radio"
            checked={!!value}
            onChange={(e) => onChange({})}
          />{' '}
          Use specific
        </label>
      </div>

      {value &&
        parentVideos &&
        locales.map((loc) => {
          const byLocale = parentVideos[loc.name] || [];

          const parentVideosOptions = byLocale.map((a) => ({
            label: a.filename,
            value: a.filename,
          }));

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 15,
              }}
              key={loc._id}
            >
              <div style={{ width: '80px', marginRight: '2rem' }}>
                <p style={{ textAlign: 'right', margin: 0 }}>
                  {loc.name}
                </p>
              </div>

              <div style={{ width: '200px' }}>
                <select
                  style={{ width: '100%' }}
                  value={value[loc.code] || ''}
                  onChange={(e) =>
                    onChange({ ...value, [loc.code]: e.target.value })
                  }
                >
                  <option value=""></option>
                  {parentVideosOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          );
        })}
    </div>
  );
};

ParentVideosControls.propTypes = {
  getAnimationsList: PropTypes.func.isRequired,
  locales: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  locales: state.common.locales,
});

export default connect(mapStateToProps, { getAnimationsList })(
  ParentVideosControls
);
