import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import { imagesURL } from 'config';
import { printElem } from 'utils';

const styles = {
  wrapper: {
    margin: '30px auto',
    width: '100%',
    maxWidth: 700,
    fontFamily: 'Roboto, sans-serif',
  },
  header: {
    padding: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 20,
    fontSize: 16,
  },
  title: {
    fontSize: 30,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 15,
  },
  info: {
    marginRight: 20,
  },
  logo: {
    width: '20%',
    flexShrink: 0,
  },
  printViewport: {},
};

const ReportsView = ({ title, subtitle, afterSubtitle, ultimateSubtitle, children }) => {
  const printViewportRef = useRef();

  const print = useCallback(
    () => printViewportRef && printElem(printViewportRef.current),
    []
  );

  const open = useCallback(() => {
    const newWindowContent = printViewportRef.current.outerHTML;
    const newWindow = window.open('', 'Report', 'width=700,height=500');
    newWindow.document.write(newWindowContent);
  }, []);

  return (
    <div style={{ marginTop: 30 }}>
      <Button
        style={{ marginRight: 10 }}
        variant="contained"
        color="primary"
        onClick={open}
      >
        <label>Open in new window</label>
      </Button>

      <Button variant="contained" color="primary" onClick={print}>
        <label>Print</label>
      </Button>

      <div ref={printViewportRef}>
        <div style={styles.wrapper}>
          <div style={styles.header}>
            <div style={styles.info}>
              {title && <h3 style={styles.title}>{title}</h3>}
              {subtitle && <h4 style={styles.subtitle}>{subtitle}</h4>}

              {afterSubtitle}
              {ultimateSubtitle}
            </div>

            <img
              style={styles.logo}
              src={`${imagesURL}/Images/Login/FLCLogo.png`}
              alt="Flink logo"
            />
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default ReportsView;
