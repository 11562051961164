export default activity => {
  !activity.data && (activity.data = {});
  !activity.data.gameData && (activity.data.gameData = {});
  !activity.data.gameData.problems && (activity.data.gameData.problems = []);

  !activity.data.options && (activity.data.options = {});

  const defaultOptions = { ...activity.template.defaultOptions };
  delete defaultOptions.maxFontSize;

  activity.data.options = {
    ...defaultOptions,
    ...activity.data.options
  };

  return activity;
};
