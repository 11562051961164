import WritingFeedbackButton from "./Button";

// actions
import { checkWriting } from "actions/flinkPlayActions";

// redux
import { connect } from "react-redux";

const mapStateToProps = ({ status, flinkPlay: { writingFeedback, solutionTranslate } }) => ({
  isOpen: writingFeedback.open,
  translate: solutionTranslate || status.translate,
});

const mapDispatchToProps = {
  checkWriting,
}

export default connect(mapStateToProps, mapDispatchToProps)(WritingFeedbackButton);
