import { debounce } from 'lodash';
import { useQuery } from 'react-query';

import { usePaginationParams } from 'utils/hooks/usePaginationParams';
import MaterialTableNew, {
  MaterialTableNewProps,
} from '../MaterialTableNew/MaterialTableNew';

type PaginatedTableWithQueryProps<T extends object> = {
  queryKey: unknown[];
  queryFn(params: any): Promise<{ data: T[]; total: number }>;
} & Omit<
  MaterialTableNewProps<T>,
  | 'data'
  | 'loading'
  | 'rowCount'
  | 'onPageSizeChange'
  | 'onPageChange'
  | 'order'
  | 'orderBy'
  | 'filters'
  | 'onRequestSort'
  | 'onFilterChange'
>;

const PaginatedTableWithQuery = <T extends {}>({
  queryFn,
  queryKey,
  ...tableProps
}: PaginatedTableWithQueryProps<T>) => {
  const { apiParams, ...params } = usePaginationParams<T>(tableProps.type);

  const { isLoading, data: result, isFetching } = useQuery(
    [...queryKey, apiParams],
    () => queryFn(apiParams),
    { keepPreviousData: true, refetchOnMount: 'always' }
  );

  return (
    <MaterialTableNew
      data={result?.data || []}
      loading={isLoading || isFetching}
      rowCount={result?.total || 0}
      onPageSizeChange={params.setLimit}
      onPageChange={params.setPage}
      order={params.order}
      orderBy={params.orderBy}
      filters={params.filters}
      onRequestSort={params.toggleOrderBy}
      onFilterChange={debounce(params.setFilters, 1000)}
      {...tableProps}
    />
  );
};

export default PaginatedTableWithQuery;
