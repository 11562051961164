import React from 'react';

import { WhiteBox } from 'components/flink-play';
import classes from './ProblemImage.module.scss';

const ProblemImage = ({ url, customClasses = {} }) => {
  if (!url) return null;
  const { box } = customClasses;

  return (
    <div className={classes.positionedBox + ' ' + box}>
      <WhiteBox outerClass={classes.wrapper} innerClass={classes.innerWrapper}>
        <img  src={url} alt="" />
      </WhiteBox>
    </div>
  );
};

export default ProblemImage;
