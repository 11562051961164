import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { imagesURL } from 'config';
import { desc } from 'utils/getSorting';
import './DataList.scss';

const redStarUrl = `${imagesURL}/FlinkMake/starred.png`;
const greenStarUrl = `${imagesURL}/FlinkMake/stargreen.png`;

const defaultValidate = item => !!item._id;

const DataList = ({
  withoutStars,
  data,
  clickHandler,
  active,
  showName,
  validate = defaultValidate,
  sort,
  small,
  big
}) => {
  const sortedData = sort
    ? data.sort((a, b) => -desc(showName(a), showName(b)))
    : data;

  const list = useRef();

  useEffect(() => {
    const keyPressListener = e => {
      if (e.target !== list.current) return;
      if (e.keyCode !== 40 && e.keyCode !== 38) return;
      e.preventDefault();

      const dataLength = sortedData.length;
      const currentIndex = _.findIndex(sortedData, active);
      let nextIndex;
      if (e.keyCode === 40) {
        // Arrow down
        nextIndex = currentIndex + 1;
        nextIndex = nextIndex === dataLength ? 0 : nextIndex;
      } else if (e.keyCode === 38) {
        // Arrow Up
        nextIndex = currentIndex - 1;
        nextIndex = nextIndex < 0 ? dataLength - 1 : nextIndex;
      }

      clickHandler(sortedData[nextIndex]);
    };

    document.addEventListener('keydown', keyPressListener);

    return () => {
      document.removeEventListener('keydown', keyPressListener);
    };
  }, [active, clickHandler, sortedData]);

  return (
    <ul
      tabIndex={1}
      ref={list}
      className={classNames('data-list', {
        'data-list--big': big,
        'data-list--small': small
      })}
    >
      {sortedData.map((item, index) => {
        const styles = {
          backgroundImage: `url(${validate(item) ? greenStarUrl : redStarUrl})`
        };

        return (
          <li
            key={index}
            style={styles}
            className={classNames('data-list__item', {
              'data-list__item--without-star': withoutStars,
              'data-list__item--active':
                (!item._id && !active) || item._id === (active && active._id)
            })}
            onClick={clickHandler.bind(null, item)}
          >
            {showName(item)}
          </li>
        );
      })}
    </ul>
  );
};

DataList.propTypes = {
  data: PropTypes.array.isRequired,
  clickHandler: PropTypes.func.isRequired,
  showName: PropTypes.func.isRequired,
  active: PropTypes.object,
  validate: PropTypes.func
};

export default DataList;
