import shortid from 'shortid';
import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {};

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  gameData.problems = gameData.record || gameData.entry;
  delete gameData.record;
  delete gameData.entry;

  gameData.problems = gameData.problems.map((problem) => {
    const { letters, word, options } = problem;

    let lettersMask = '';

    for (let i = 1; i <= word.length; i++) {
      lettersMask += letters.split(',').includes(i + '') ? 0 : 1;
    }

    const formattedProblem = {
      ...problem,
      id: shortid.generate(),
      letters: lettersMask,
      options: options ? options.split(',') : [],
    };

    return formattedProblem;
  });

  result.gameData = gameData;
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
