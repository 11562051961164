const replaceTokensWithData = (
  string: string,
  data: Record<string, string | number>
): string => {
  const match = string.match(/\{\{([\s\S]+?)\}\}/g);

  if (match) {
    match.forEach((m) => {
      const variable = m.replace(/\{\{(\s+)?/, '').replace(/(\s+)?\}\}/, '');
      const value =
        data[variable] !== undefined ? data[variable] : 'Value not found';

      const regExp = new RegExp(m, 'g');
      string = string.replace(regExp, String(value));
    });
  }

  return string;
};

export default replaceTokensWithData;
