import { ADD_MASTER_ORGANIZATION } from '../types';
import { axiosAPI as axios, withLoading } from 'utils';

const filter = 'admin';

export const addMasterOrganization = (type, data) => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    try {
      const res = await axios.post(`/api/admin/masterOrganizations/add`, data);

      dispatch({
        filter,
        type: ADD_MASTER_ORGANIZATION,
        payload: res.data
      });

      res.success = true;

      return res;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { data: { _error: 'No response from server' } };
      }

      console.log(result);

      return result;
    }
  });

export const getMasterOrganizationById = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(`/api/admin/masterOrganizations/${id}`);

      return res.data;
    } catch (err) {
      console.log(err);
    }
  });

export const getMasterOrganizationPlansStatus = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(
        `/api/admin/masterOrganizations/get-plans-status/${id}`
      );

      return res.data;
    } catch (err) {
      console.log(err);
    }
  });
