import { ADD_DATA, EDIT_DATA } from "../types";
import { axiosAPI as axios, withLoading } from "utils";

const filter = "admin";

export const addDocument = data => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    const file = data.file;

    if (!file) return;

    try {
      const res = await axios.post(`/api/admin/documents/add`, {
        ...data,
        file: undefined
      });

      await fetch(res.data.uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type
        },
        body: file
      });

      dispatch({
        filter,
        type: ADD_DATA,
        payloadType: "documents",
        payload: res.data.document
      });

      result.success = true;

      return result;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { data: { _error: "No response from server" } };
      }

      console.log(result);

      return result;
    }
  });

export const viewDocument = filename => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(`/api/admin/documents/view/${filename}`);
      window.open(res.data);
    } catch (err) {
      console.log(err);
    }
  });

export const viewDocumentById = id => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(`/api/admin/documents/view-by-id/${id}`);
      window.open(res.data);
    } catch (err) {
      console.log(err);
    }
  });

export const downloadDocument = filename => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axios.get(`/api/admin/documents/download/${filename}`);
      window.open(res.data);
    } catch (err) {
      console.log(err);
    }
  });

export const editDocument = data => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    const file = data.file;
    const isNewFileProvided = !!file;

    try {
      const res = await withLoading(
        dispatch,
        async () =>
          await axios.post(`/api/admin/documents/edit`, {
            ...data,
            file: undefined,
            isNewFileProvided
          })
      );

      if (isNewFileProvided) {
        // Upload file to S3
        await fetch(res.data.uploadUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type
          },
          body: file
        });
      }

      dispatch({
        filter,
        type: EDIT_DATA,
        payloadType: "documents",
        payload: res.data.document
      });

      result.success = true;

      return result;
    } catch (err) {
      result.success = false;
      if (err && err.response && err.response.data) {
        result.err = err.response.data;
      } else {
        result.err = { data: { _error: "No response from server" } };
      }

      console.log(result);

      return result;
    }
  });
