import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { showStats } from 'actions/gameActions';
import { GO_BACK, HELP, SPEAK, GO, STOP, PARENT_ADVICE } from 'consts/buttons';
import { setActivityMastered } from 'actions/flinkPlayActions';
import { toggleAudio, stopAudio } from 'actions/audioActions';
import { activitiesURL } from 'config';
import { validateProblem } from './ebookHelpers';
import {
  AdaptiveContainer,
  ActivityButtons,
  EbookComponent,
  EbookTimer,
} from 'components/flink-play';
import { PRESENTATION, MANUAL, BOTH } from 'consts/ebook-consts';

const NO_MEDIA_DELAY = 5 * 1000; // 5 seconds
const WITH_MEDIA_DELAY = 2 * 1000; // 2 seconds

class Ebook extends Component {
  state = {};

  componentDidMount() {
    document.addEventListener('startGame', this.init);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPageIdx !== this.state.currentPageIdx) {
      this.onPageChange();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.presentationTimeout);
    clearInterval(this.timerInterval);

    document.removeEventListener('startGame', this.init);
    document.removeEventListener('audioEnded', this.onPresentationMediaEnded);
  }

  init = () => {
    const {
      activity: {
        activity: { _id },
        data: {
          gameData: { problems, totalWords, benchmark },
          options: { displayTimer, ebookMode },
        },
      },
    } = this.props;

    const validPages = _.filter(problems, validateProblem);
    const assetsFolder = `${activitiesURL}/${_id}/gamedata/`;

    const hasVideo = !!validPages.filter((p) => !!p.video).length;
    const hasAudio = !!validPages.filter((p) => !!p.audio).length;

    const timerCanBeDisplayed =
      totalWords &&
      benchmark &&
      displayTimer &&
      !hasVideo &&
      ebookMode !== PRESENTATION;

    this.setState({
      hasAudio,
      hasVideo,
      videoPlaying: true,
      presentationPaused: false,
      presentationFinished: false,
      timerStarted: false,
      spentTime: 0,
      currentMode: ebookMode === PRESENTATION ? PRESENTATION : MANUAL,
      displayTimer: timerCanBeDisplayed,
      assetsFolder,
      currentPageIdx: 0,
      pages: validPages,
    });
  };

  showPrevPage = () => {
    const { currentPageIdx } = this.state;
    const isFirstPage = currentPageIdx === 0;

    if (!isFirstPage) {
      this.setState({ currentPageIdx: currentPageIdx - 1 });
    }
  };

  showNextPage = () => {
    const {
      activity: {
        data: {
          options: { ebookMode },
        },
      },
    } = this.props;

    const { currentPageIdx } = this.state;
    const isLastPage = this.getIsLastPage();

    if (!isLastPage) {
      this.setState({ currentPageIdx: currentPageIdx + 1 });
    } else if (ebookMode === PRESENTATION || ebookMode === BOTH) {
      this.setState({
        presentationPaused: true,
        presentationFinished: true,
        videoPlaying: false, // Stop video
      });
    }
  };

  getIsLastPage = () => {
    const { currentPageIdx, pages } = this.state;
    const isLastPage = pages.length === currentPageIdx + 1;
    return isLastPage;
  };

  onPageChange = () => {
    const { autoPlayAudioInManual } = this.props.activity.data.options;
    const { currentMode, hasVideo, displayTimer } = this.state;

    const isLastPage = this.getIsLastPage();

    // If page is last one and activity has no timer and no video,
    // Then Learner masters the activity
    if (isLastPage && !hasVideo && !displayTimer) {
      this.props.setActivityMastered();
    }

    this.toggleVideo(true);

    clearTimeout(this.presentationTimeout);
    stopAudio();

    if (currentMode === PRESENTATION) {
      this.playMediaAndSetupInterval();
    } else if (autoPlayAudioInManual) {
      this.playPageAudio();
    }
  };

  getCurrentPage = () => {
    const { pages, currentPageIdx } = this.state;
    if (!pages) return null;
    return pages[currentPageIdx];
  };

  playPageAudio = () => {
    const { assetsFolder } = this.state;

    const currentPage = this.getCurrentPage();
    if (!currentPage) return;

    const { audio } = currentPage;

    if (!audio) return;

    toggleAudio(assetsFolder + audio, { onEnd: this.onAudioEnded });
  };

  // speakButtonHandler = () => {
  //   const currentPage = this.getCurrentPage();
  //   if (!currentPage || !currentPage.audio) return;

  //   const { audio } = this.props;
  //   this.playPageAudio();

  //   toggleAudio()
  //   if (audio && !audio.ended && audio.src.indexOf(currentPage.audio) !== -1) {
  //     stopAudio();
  //   } else {
  //   }
  // };

  startPresentation = () => {
    const { presentationFinished } = this.state;

    if (presentationFinished) {
      this.setState(
        {
          videoPlaying: true,
          currentMode: PRESENTATION,
          currentPageIdx: 0,
          presentationPaused: false,
          presentationFinished: false,
        },
        this.playMediaAndSetupInterval
      );
    } else {
      this.setState(
        {
          videoPlaying: true,
          currentMode: PRESENTATION,
          presentationPaused: false,
        },
        this.playMediaAndSetupInterval
      );
    }
  };

  stopPresentaion = () => {
    const {
      activity: {
        data: {
          options: { ebookMode },
        },
      },
    } = this.props;

    // Stop media
    stopAudio();

    clearTimeout(this.presentationTimeout);
    this.setState({ videoPlaying: false });
    document.removeEventListener('audioEnded', this.onPresentationMediaEnded);

    if (ebookMode === BOTH) {
      // If both, then switch mode to manual
      this.setState({ currentMode: MANUAL });
    } else if (ebookMode === PRESENTATION) {
      // Set Pause
      this.setState({ presentationPaused: true });
    }
  };

  onAudioEnded = () => {
    const { displayTimer, hasVideo, hasAudio } = this.state;

    if (!displayTimer && hasVideo && hasAudio) {
      // If activity hasn't timer and has video and audio,
      // and learner finished to listen at least
      // one audio, then he(she) mastered the activity
      this.props.setActivityMastered();
    }
  };

  onVideoEnded = () => {
    const {
      currentMode,
      presentationPaused,
      displayTimer,
      hasAudio,
    } = this.state;

    this.setState({ videoPlaying: false });

    const currentPage = this.getCurrentPage();

    if (
      currentMode === PRESENTATION &&
      !presentationPaused &&
      !currentPage.audio
    ) {
      this.onPresentationMediaEnded();
    }

    if (!displayTimer && !hasAudio) {
      // If activity hasn't timer and audio, and learner finished to watch at least
      // one video, then he(she) mastered the activity
      this.props.setActivityMastered();
    }
  };

  toggleVideo = (state) => {
    this.setState({ videoPlaying: state });
  };

  onParentAdviceToggle = (isOpening) => {
    if (isOpening) {
      this.stopPresentaion();
    }
  };

  playMediaAndSetupInterval = () => {
    const currentPage = this.getCurrentPage();
    const { audio, video } = currentPage;

    clearInterval(this.presentationTimeout);
    document.removeEventListener('audioEnded', this.onPresentationMediaEnded);

    if (audio) {
      this.playPageAudio();
      document.addEventListener('audioEnded', this.onPresentationMediaEnded);
    }

    if (!audio && !video) {
      this.presentationTimeout = setTimeout(this.showNextPage, NO_MEDIA_DELAY);
    }
  };

  onPresentationMediaEnded = (e) => {
    const delay = e && e.detail.error ? NO_MEDIA_DELAY : WITH_MEDIA_DELAY;

    // Stop video
    this.toggleVideo(false);

    this.presentationTimeout = setTimeout(this.showNextPage, delay);
  };

  startTimer = () => {
    const { displayTimer } = this.state;

    if (!displayTimer) return;

    this.setState({ timerStarted: true });
    this.timerInterval = setInterval(this.increaseTimer, 1000);
  };

  stopTimer = () => {
    const { timerStarted } = this.state;
    if (!timerStarted) return;

    clearInterval(this.timerInterval);
    this.setState({ timerStarted: false }, this.showResults);
  };

  increaseTimer = () => {
    this.setState((state) => ({
      spentTime: state.spentTime + 1,
    }));
  };

  showResults = () => {
    const { showStats } = this.props;
    const { spentTime, displayTimer } = this.state;

    if (!displayTimer) return;

    const {
      activity: {
        data: {
          gameData: { totalWords, benchmark },
        },
      },
    } = this.props;
    const speed = Math.round((totalWords / spentTime) * 60);
    showStats({
      data: { speed, benchmark },
      ebookBenchmark: {
        benchmark,
        totalWords,
        speed,
        spentTime,
      },
    });
  };

  render() {
    const {
      displayTimer,
      assetsFolder,
      currentPageIdx,
      pages,
      videoPlaying,
      spentTime,
      timerStarted,
      currentMode,
      presentationPaused,
    } = this.state;
    const {
      activity: {
        data: {
          options: { ebookMode },
        },
      },
    } = this.props;

    const currentPage = this.getCurrentPage();

    const { audio } = currentPage || {};
    const isLastPage = pages && pages.length === currentPageIdx + 1;

    return (
      <>
        {displayTimer && currentMode === MANUAL && (
          <AdaptiveContainer
            // smallWidth={100}
            // largeWidth={114}
            style={{
              position: 'absolute',
              left: '50%',
              top: '1%',
              display: 'flex',
              justifyContent: 'center',
              transform: 'translateX(-50%)',
            }}
          >
            <EbookTimer
              showStart={currentPageIdx === 0 && !timerStarted}
              startHandler={this.startTimer}
              showStop={isLastPage && timerStarted}
              stopHandler={this.stopTimer}
              spentTime={spentTime}
            />
          </AdaptiveContainer>
        )}

        {currentPage && (
          <EbookComponent
            videoPlaying={videoPlaying}
            showVideReplayBtn={!videoPlaying && currentMode !== PRESENTATION}
            replayHandler={this.toggleVideo.bind(this, true)}
            onVideoEnded={this.onVideoEnded}
            showPrev={currentPageIdx !== 0 && currentMode !== PRESENTATION}
            showNext={
              currentPageIdx !== pages.length - 1 &&
              currentMode !== PRESENTATION
            }
            prevClickHandler={this.showPrevPage}
            nextClickHandler={this.showNextPage}
            page={currentPage}
            assetsFolder={assetsFolder}
          />
        )}

        <ActivityButtons
          buttons={[
            {
              type: GO_BACK,
              onClick: isLastPage && timerStarted ? this.stopTimer : undefined,
            },
            {
              type: GO,
              dontShow:
                ebookMode === MANUAL ||
                (currentMode === PRESENTATION && !presentationPaused) ||
                !currentPage ||
                timerStarted,
              onClick: this.startPresentation,
            },
            {
              type: STOP,
              dontShow:
                ebookMode === MANUAL ||
                (currentMode === PRESENTATION && presentationPaused) ||
                !currentPage ||
                timerStarted ||
                currentMode === MANUAL,
              onClick: this.stopPresentaion,
            },
            HELP,
            {
              type: SPEAK,
              dontShow: !audio || currentMode === PRESENTATION,
              onClick: this.playPageAudio,
            },
            {
              type: PARENT_ADVICE,
              preClick: this.onParentAdviceToggle,
            },
          ]}
        />
      </>
    );
  }
}

Ebook.propTypes = {
  showStats: PropTypes.func.isRequired,
  setActivityMastered: PropTypes.func.isRequired,
};

export default connect(null, {
  showStats,
  setActivityMastered,
})(Ebook);
