import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const renderRadioGroup = ({ input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    valueselected={input.value}
    onChange={(event, value) => input.onChange(value)}
  >
    <FormControlLabel
      value="adminPages"
      control={<Radio />}
      label="Admin Pages"
    />
    <FormControlLabel
      value="flinkReports"
      control={<Radio />}
      label="Flink Reports"
    />
    <FormControlLabel value="flinkMake" control={<Radio />} label="Make" />
  </RadioGroup>
);

const RadioButtons = ({ name }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Gender</FormLabel>

      <Field name={name} component={renderRadioGroup} />
    </FormControl>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default RadioButtons;
