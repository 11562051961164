import classes from "./FamilyPoints.module.scss";

// redux
import { connect } from "react-redux";

// points history modal
import PointsHistoryModal from "./PointsHistory/History";

// actions
import { showFamilyPointsHistory } from "actions/flinkPlayActions";

const { format: formatNumber } = new Intl.NumberFormat('en-US');
const FamilyPoints = ({ family, pointsConfig, showFamilyPointsHistory, translate }) => (
    <>
        <div className={classes.wrapper}>
            <p className={classes.title}>
                {translate(1002, 'Family Points')}
            </p>
            <button className={classes.points} onClick={showFamilyPointsHistory}>
                {formatNumber(family.points.cumulative)}
            </button>
            <p className={classes.reward}>
                {translate(1003, 'Reward')} = {formatNumber(pointsConfig.neededForRewards)} {translate(989, 'points')}
            </p>
        </div>
        <PointsHistoryModal />
    </>
);

const mapStateToProps = ({ 
    flinkPlay: { 
        learningCenter, 
        solutionTranslate,
        family,
    },
    status: { translate },
}) => ({
    pointsConfig: learningCenter.points,
    translate: solutionTranslate || translate,
    family: family,
});

export default connect(mapStateToProps, {
    showFamilyPointsHistory,
})(FamilyPoints);
