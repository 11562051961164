import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AdaptiveContainer = ({
  smallWidth,
  largeWidth,
  aspectRatio,
  style = {},
  children
}) => {
  const width = useMemo(() => {
    return aspectRatio >= 1.25 ? largeWidth : smallWidth;
  }, [smallWidth, largeWidth, aspectRatio]);

  return <div style={{ width: width + "vmin", ...style }}>{children}</div>;
};

AdaptiveContainer.propTypes = {
  aspectRatio: PropTypes.number,
  style: PropTypes.object
};

AdaptiveContainer.defaultProps = {
  smallWidth: 100,
  largeWidth: 119
};

const mapStateToProps = ({ status: { aspectRatio } }) => ({ aspectRatio });

export default connect(mapStateToProps)(AdaptiveContainer);
