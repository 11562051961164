import React from 'react';
import classNames from 'classnames';

import SettingsSlider from '../SettingsSlider/SettingsSlider';
import { imagesURL } from 'config';
import classes from '../Settings.module.scss';

const themesFolder = `${imagesURL}/SettingsDialog/themes`;

const SelectTheme = ({ value, onChange, options }) => {
  return (
    <SettingsSlider slidesToShow={3}>
      {options.map((opt) => {
        const filename = opt.label.replace(/\s/g, '') + '.png';

        return (
          <div key={opt.value}>
            <button
              className={classNames(classes.itemButton, {
                [classes.selectedItem]: value === opt.value,
              })}
              onClick={() => onChange(opt.value)}
              title={opt.label}
            >
              <div
                className={classes.themeImgWrapper}
                style={{
                  backgroundImage: `url(${themesFolder}/${filename})`,
                }}
              />
            </button>
          </div>
        );
      })}
    </SettingsSlider>
  );
};

export default SelectTheme;
