
import API from 'api';

import { withLoading } from 'utils';
import { SET_TTS_VOICE, SET_TTS_LOCALE } from 'actions/types';
import { showError } from 'actions/statusActions';

const filter = 'flinkMake/tts';

export const generateTTS = (params) => (dispatch) => {
  const { voice, locale, text, path, useFileBucket, fileToDelete } = params;
  return withLoading(dispatch, async () => {
    const { filename, error } = await API.polly.generateTTS({ 
      path, 
      voice, 
      locale, 
      text,
      useFileBucket,
    });
  
    if (!filename) {
      dispatch(showError({
        message: error,
      }));
      return false;
    }

    if (fileToDelete) {
      const key = `${path}/${fileToDelete}`;
      await API.storage.deleteFiles([key]);
    }

    return filename;
  });
}

export const setTTSVoice = (voice) => ({
  filter,
  type: SET_TTS_VOICE,
  payload: voice,
});

export const setTTSLocale = (locale) => ({
  filter,
  type: SET_TTS_LOCALE,
  payload: locale,
});
