import React from 'react';
import classnames from 'classnames';
import classes from './FeedbackConfigurator.module.scss';
import { translateSelector } from 'selectors';
import { useSelector } from 'react-redux';
import { imagesURL } from 'config';
import { isUsingStrings } from 'utils';
import useGetStringAudio from 'utils/hooks/useGetStringAudio';
import { ActivityAddAudio } from 'components/flink-components';
import { StringAssignField } from 'components/form-components';

const FeedbackConfigurator = ({
  withLabels = true,
  text: feedbackText,
  audio: feedbackAudio,
  vertical,
  changeAudioHandler,
  changeTextHandler,
}) => {
  let stringNumber, audioPath; // for strings
  let [text, audio] = [feedbackText, feedbackAudio];

  const translate = useSelector(translateSelector);
  const getStringAudio = useGetStringAudio();

  const usingStrings = isUsingStrings(text);
  if (usingStrings) {
    const stringAudio = getStringAudio(text);
    text = translate(text, '', true);
    
    stringNumber = stringAudio.stringNumber;
    audio = stringAudio.audio;
    audioPath = stringAudio.path;
  }

  return (
    <div
      className={classnames(classes.wrapper, { [classes.vertical]: vertical })}
    >
      <div className={classes.textareaWrapper}>
        {withLabels && (
          <label className={classes.label}>
            {translate(947, 'Feedback Text')}
          </label>
        )}
        <textarea
          tabIndex="-1"
          className={classes.textarea}
          value={text || ''}
          onChange={(e) => changeTextHandler(e.target.value)}
          readOnly={usingStrings}
        />
        {!usingStrings && text && (
          <button
            className={classes.deleteBtn}
            onClick={() => changeTextHandler(null)}
          >
            <img
              src={`${imagesURL}/FrontendUtilities/delete1.png`}
              alt="delete"
            />
          </button>
        )}
      </div>

      <StringAssignField
        value={stringNumber}
        changeAudioHandler={changeAudioHandler}
        changeTextHandler={changeTextHandler}
        isTooltip
      />

      <div className={classes.audioWrapper}>
        <ActivityAddAudio
          title={withLabels ? translate(948, 'Feedback Audio') : ''}
          current={audio}
          path={audioPath}
          onChangeHandler={(filename) => changeAudioHandler(filename)}
          associatedText={text}
          readOnly={usingStrings} /* disallow adding / removing audio when custom string number is set */
          useFileBucket={usingStrings}
        />
      </div>
    </div>
  );
};

export default FeedbackConfigurator;
