import { Field } from "redux-form";
import { renderTextField, renderCheckbox } from "components/form-components";

import classes from "./FamilyPointsField.module.scss";

import { compose } from "redux";
import { formValues } from "redux-form";

const pointFields = [
    { name: "points.reversalTriggerTime", label: "Reversal trigger time", unit: "days" },
    { name: "points.reversalNormalUse", label: "Reversal normal usage", unit: "points" },
    { name: "points.reversalMinimumUse", label: "Minimum use for reversal points", unit: "minutes" },
    { name: "points.adultNormalUse", label: "Adult normal usage", unit: "points" },
    { name: "points.adultMinimumUse", label: "Adult minimum use for points", unit: "minutes" },
    { name: "points.childNormalUse", label: "Child normal usage", unit: "points" },
    { name: "points.childMinimumUse", label: "Child minimum use for points", unit: "minutes" },
    { name: "points.togetherNormalUse", label: "Together time normal usage", unit: "points" },
    { name: "points.togetherMinimumUse", label: "Together time minimum use for points", unit: "minutes" },
    { name: "points.neededForRewards", label: "Points needed for rewards", unit: "points" },
    { name: "points.rewardValue", label: "Reward value" },
];

const parseNumericValue = (value) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? value : parsed;
}

const FamilyPointsField = ({ points }) => (
    <div>
        <p className={classes.familyPointsTitle}>
            Family Points for Rewards
        </p>

        <Field
            name="points.enable"
            label="Enable Family Points?"
            component={renderCheckbox}
        />

        {points && pointFields.map(({ name, label, unit }) => (
            <LabeledField
                key={`field.${name}`}
                name={name}
                label={label}
                component={renderTextField}
                unit={unit}
                width="5em" 
                type="number"
                parse={parseNumericValue}
                required
            />
        ))}
    </div>
);

const LabeledField = ({ unit, label, ...props }) => (
    <div className={classes.field}>
        <label>{label}</label>
        <div className={classes.inputField}>
            <Field {...props} />
        </div>
        {unit}
    </div>
);

export default compose(
    formValues({
        points: 'points.enable'
    })
)(FamilyPointsField);
