import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

import "./IndentStyle";
import "./ImageFormat";

Quill.register("modules/imageResize", ImageResize);

export const fonts = [
  "Arial",
  "Arial Black",
  "Bookman",
  "Comic Sans MS",
  "Courier",
  "Courier New",
  "Garamond",
  "Georgia",
  "Helvetica",
  "Impact",
  "Palatino",
  "Roboto",
  "Times New Roman",
  "Times",
  "Tahoma",
  "Verdana"
];

export const formats = [
  "align",
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "width",
  "strike",
  "list",
  "bullet",
  "indent",
  "color",
  "background",
  "style"
];

export const sizes = [
  "8px",
  "10px",
  "12px",
  "13px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "30px",
  "32px",
  "36px",
  "48px"
];
