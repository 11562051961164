import { axiosAPI, getSignedUrl } from 'utils';

const axiosConf = {
  transformRequest: [
    (data, headers) => {
      delete headers.common.Authorization;
      return data;
    },
  ],
};

export default {
  listFiles(key, uploadBucket) {
    return axiosAPI
      .post('/api/utils/list-files-in-folder', {
        key,
        uploadBucket,
      })
      .then((response) => {
        return response.data;
      });
  },
  checkIfFileExists(key, uploadBucket) {
    return getSignedUrl({
      key,
      method: 'head',
      uploadBucket,
    })
      .then((url) => {
        return axiosAPI.head(url, axiosConf);
      })
      .then((response) => {
        return response.status === 200;
      })
      .catch((err) => {
        return false;
      });
  },
  getFile(key, uploadBucket, additionalAxiosParams = {}) {
    return getSignedUrl({
      key,
      method: 'get',
      uploadBucket,
    })
      .then((url) => {
        return axiosAPI.get(url, { ...axiosConf, ...additionalAxiosParams });
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  },
  openPdf(key, uploadBucket) {
    return getSignedUrl({
      key,
      method: 'get',
      uploadBucket,
      viewPdf: true,
    })
      .then((url) => window.open(url))
      .catch((err) => {
        console.log('Error opening pdf file', key, err);
      });
  },

  saveActivityData(id, data) {
    return axiosAPI
      .put(`/api/flink-make/activities/${id}/save-data`, data)
      .then((response) => {
        return response.status === 200;
      })
      .catch((err) => {
        console.log('Saving activity data error', err);
        console.log('Saving activity data error: activityId', id);
        console.log('Saving activity data error: data', data);
        return false;
      });
  },

  uploadFile(key, file, { onUploadProgress } = {}, uploadBucket = true) {
    return getSignedUrl({
      key,
      method: 'put',
      uploadBucket,
    })
      .then((url) => {
        return axiosAPI.put(url, file, {
          ...axiosConf,
          onUploadProgress,
        });
      })
      .then((response) => {
        return response.status === 200;
      })
      .catch((err) => {
        console.log('Uploading error', err);
        console.log('Uploading error: key', key);
        console.log('Uploading error: file', file);
        return false;
      });
  },
  uploadFiles(array) {
    // array of { key: String, file: File }
    return Promise.all(array.map((d) => this.uploadFile(d.key, d.file)));
  },
  copyFiles(array) {
    // array of {
    //   src: { uploadBucket: Boolean, key: String },
    //   dest: { uploadBucket: Boolean, key: String }
    // }
    return axiosAPI
      .post('/api/utils/copy-files', array)
      .then((response) => {
        console.log('Copy Response', response);
        return response.data;
      })
      .catch((err) => {
        console.log('Copy Error', err);
        return false;
      });
  },
  deleteFiles(keys, uploadBucket = true) {
    return axiosAPI
      .post('/api/utils/delete-files', { keys, uploadBucket })
      .then((results) => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  },
};
