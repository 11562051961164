export const apiURL =
  process.env.REACT_APP_API_URL ||
  window.location.origin.replace('5050', '3000');

// FS Bucket
export const filesURL = process.env.REACT_APP_FILE_BUCKET;
export const imagesURL = `${filesURL}/Images`;
export const audioURL = `${filesURL}/Audio`;

// Uploads Bucket
export const uploadsURL = process.env.REACT_APP_UPLOADS_BUCKET;

export const publicKey = 'public';
export const activitiesKey = `${publicKey}/Activities`;
export const usersKey = `${publicKey}/Users`;

export const s3bucketPublicURL = `${uploadsURL}/${publicKey}`;
export const activitiesURL = `${uploadsURL}/${activitiesKey}`;
