import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';
import cs from 'classnames';

import { WhiteBox } from 'components/flink-play';
import itemType from '../draggable-item-type';
import DraggableItem from '../DraggableItem/DraggableItem';
import classes from './BoardWithDraggableItems.module.scss';

const boardTarget = {
  drop(props, monitor) {
    return { isBoard: true };
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const BoardWithDraggableItems = ({
  refs,
  items,
  onDrop,
  boxesCount,
  connectDropTarget,
}) => {
  return connectDropTarget(
    <div>
      <WhiteBox
        outerClass={cs(classes.wrapper, {
          [classes.large]: boxesCount <= 16,
          [classes.medium]: boxesCount > 16 && boxesCount <= 25,
          [classes.small]: boxesCount > 25,
        })}
        innerClass={classes.innerWrapper}
      >
        {_.times(boxesCount, (idx) => {
          const itemInThatBox = _.find(items, {
            boxIdx: idx,
            inSlot: false,
          });

          return (
            <div className={classes.box} key={idx} ref={refs[idx]}>
              {itemInThatBox && (
                <DraggableItem
                  className={classes.itemWrapper}
                  onDropHandler={onDrop}
                  key={itemInThatBox.id}
                  itemData={itemInThatBox}
                />
              )}
            </div>
          );
        })}
      </WhiteBox>
    </div>
  );
};

BoardWithDraggableItems.propTypes = {
  boxesCount: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  refs: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};

export default DropTarget(
  itemType,
  boardTarget,
  collect
)(BoardWithDraggableItems);
