import { axiosAPI, responseHandler } from 'utils';

export default {
  getAuthTeacherAssignedFamilies() {
    return responseHandler(axiosAPI.get('api/teachers/get-assigned-families'));
  },
  getFamiliesInLearningCenter() {
    return responseHandler(
      axiosAPI.get('api/teachers/get-families-in-learning-center')
    );
  },
  updateFamiliesAssignmentsToTeacher(changes) {
    return responseHandler(
      axiosAPI.put('api/teachers/update-families-assignments-to-teacher', changes)
    );
  },
  getAvailableProducts() {
    return responseHandler(
      axiosAPI.get('api/teachers/available-products')
    );
  }
};
