import { axiosAPI } from 'utils';

export default {
  generateTTS(params) {
    return axiosAPI
      .post(`api/polly/generate-tts`, params)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return err.response.data;
      });
  },
}