import React, { useMemo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import PropTypes from "prop-types";
import { CategoriesFilter } from "components/flink-components";
import classes from "./ActivitiesFilter.module.scss";

const ActivitiesFilter = ({
  eduFilter,
  filter,
  hideEduFilters,
  translate,
  withoutTemplate,
  onChangeCategories,
  activityTemplates,
  onChangeFilter,
  children = null
}) => {
  const activityTemplateOptions = useMemo(
    () =>
      activityTemplates
        .map(templ => ({
          value: templ._id,
          label: templ.activityTemplateName
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [activityTemplates]
  );

  return (
    <div>
      <div style={{ visibility: hideEduFilters ? "hidden" : "visible" }}>
        <CategoriesFilter
          filter={eduFilter}
          changeHandler={onChangeCategories}
        />
      </div>

      <div className={classes.bottomWrapper}>
        <div className={classes.filtersWrapper}>
          {!withoutTemplate && (
            <div className={classes.inputGroup}>
              <label>{translate(399, "Template")}</label>
              <select
                value={(filter && filter.templateId) || ""}
                onChange={e =>
                  onChangeFilter({ ...filter, templateId: e.target.value })
                }
              >
                <option value="">{translate(590, "Select Template")}</option>

                {activityTemplateOptions.map(opt => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className={classes.inputGroup}>
            <label>{translate(372, "Keyword")}</label>
            <input
              type="text"
              value={(filter && filter.keyword) || ""}
              onChange={e =>
                onChangeFilter({ ...filter, keyword: e.target.value })
              }
            />
          </div>
        </div>

        <div className={classes.children}>{children}</div>
      </div>
    </div>
  );
};

ActivitiesFilter.propTypes = {
  eduFilter: PropTypes.object,
  filter: PropTypes.object,
  hideEduFilters: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  withoutTemplate: PropTypes.bool,
  activityTemplates: PropTypes.array.isRequired,
  onChangeCategories: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

const mapStateToProps = ({
  common: { activityTemplates },
  status: { translate }
}) => ({
  activityTemplates,
  translate
});

export default compose(connect(mapStateToProps))(ActivitiesFilter);
