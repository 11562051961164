import storageAPI from './storageAPI';
import Papa from 'papaparse';
import _ from 'lodash';
import { filesURL } from 'config';

export default {
  directionsGrid: {
    getHelpers: async (localeCode) => {
      const helpersPath = `Images/TemplateGraphics/DirectionsGrid/Helpers`;
      const filePath = helpersPath + '/helpers.csv';
      const file = await storageAPI.getFile(filePath, false, {
        responseType: 'blob',
      });

      return new Promise((resolve) => {
        Papa.parse(file, {
          encoding: 'ISO-8859-1',
          header: true,
          complete: (results) => {
            console.log(results);
            const helpers = _.chain(results.data)
              .map((helper) => ({
                ...helper,
                text: helper['text_' + localeCode] || helper['text_en'],
                audioUrl: `${filesURL}/${helpersPath}/Audio/${localeCode}/${helper.alias}.mp3`,
              }))
              .keyBy('alias')
              .value();

            resolve(helpers);
          },
        });
      });
    },
    getAssets: async (localeCode) => {
      const assetsPath = `Images/TemplateGraphics/DirectionsGrid/Assets`;
      const filePath = assetsPath + '/assets.csv';
      const file = await storageAPI.getFile(filePath, false, {
        responseType: 'blob',
      });

      return new Promise((resolve) => {
        Papa.parse(file, {
          encoding: 'ISO-8859-1',
          header: true,
          complete: (results) => {
            const assets = _.chain(results.data)
              .map((asset) => ({
                ...asset,
                text: asset['text_' + localeCode] || asset['text_en'],
                audioUrl: `${filesURL}/${assetsPath}/${asset.type}/Audio/${localeCode}/${asset.alias}.mp3`,
                imageUrl: `${filesURL}/${assetsPath}/${asset.type}/Images/${asset.alias}.jpg`,
              }))
              .groupBy('type')
              .value();

            resolve(assets);
          },
        });
      });
    },
  },
};
