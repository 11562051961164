import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { InstructionsTextBlock } from 'components/flink-play';
import { toggleAudio } from 'actions/audioActions';
import { getInstruction } from 'activity-templates/utils';
import { withGetStringAudio } from 'components/hocs';
import { activitiesURL } from 'config';

class Instructions extends Component {
  state = {};

  componentDidMount() {
    this.setCurrentInstruction();

    document.addEventListener(
      'playInstructionsAudio',
      this.playInstructionsAudio
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.problem !== this.props.problem) {
      this.setCurrentInstruction();
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'playInstructionsAudio',
      this.playInstructionsAudio
    );
  }

  setCurrentInstruction = () => {
    const {
      problem,
      solutionLocale,
      textPath,
      audioPath,
      stringPath,
      translate,
      getStringAudio,
      autoPlay
    } = this.props;

    const instruction = getInstruction(
      problem,
      solutionLocale,
      textPath,
      audioPath,
      stringPath,
      { translate, getStringAudio },
    );

    this.setState(
      { instruction },
      autoPlay ? this.playInstructionsAudio : undefined
    );
  };

  playInstructionsAudio = e => {
    const { activityId } = this.props;
    const { instruction } = this.state;

    if (!instruction || !instruction.audio) return null;

    const audioUrl = (instruction.audioPath || `${activitiesURL}/${activityId}/gamedata/`) + instruction.audio;
    toggleAudio(audioUrl);
  };

  render() {
    const { textStyle, className, wrapperStyle } = this.props;
    const { instruction } = this.state;

    if (!instruction || !instruction.text) {
      return null;
    }

    return (
      <InstructionsTextBlock
        wrapperStyle={wrapperStyle}
        text={instruction.text}
        className={className}
        textStyle={textStyle}
      />
    );
  }
}

Instructions.defaultProps = {
  autoPlay: true
};

Instructions.propTypes = {
  autoPlay: PropTypes.bool,
  activityId: PropTypes.string.isRequired,
  solutionLocale: PropTypes.object,
  translate: PropTypes.func.isRequired,
  getStringAudio: PropTypes.func.isRequired,
  textStyle: PropTypes.object,
  problem: PropTypes.object
};

const mapStateToProps = ({
  flinkPlay: { solutionLocale, solutionTranslate },
  status: { translate },
  game: { currentActivity }
}) => ({
  solutionLocale,
  translate: solutionTranslate || translate,
  activityId: currentActivity.activity._id
});

export default compose(
  withGetStringAudio,
  connect(mapStateToProps)
)(Instructions);
