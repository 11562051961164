import _ from 'lodash';
import { assign, createMachine } from 'xstate';
import { choose } from 'xstate/lib/actions';
import { NgeliProblem } from './ngeli.types';

export enum NgeliStep {
  IDLE = 'IDLE',
  NOUN_INTRO = 'NOUN_INTRO',
  NOUN_CHOICE = 'NOUN_CHOICE',
  NOUN_ANSWERED = 'NOUN_ANSWERED',
  ADJECTIVE_INTRO = 'ADJECTIVE_INTRO',
  ADJECTIVE_CHOICE = 'ADJECTIVE_CHOICE',
  ADJECTIVE_ANSWERED = 'ADJECTIVE_ANSWERED',
  VERB_INTRO = 'VERB_INTRO',
  VERB_CHOICE = 'VERB_CHOICE',
  VERB_ANSWERED = 'VERB_ANSWERED',
  REVIEW = 'REVIEW',
}

export type NgeliContext = {
  problems: NgeliProblem[];
  problemsAnsweredCorrectly: number;
  currentProblem: NgeliProblem | null;
  currentIdx: number;
  selectedAnswer: string | null;
  isCorrectAnswer: boolean;
  nounAnswers: string[];
  adjectiveAnswers: string[];
  verbAnswers: string[];
  nounAnswersTries: number;
  adjectiveAnswersTries: number;
  verbAnswersTries: number;
  delay: number;
};

const initialState = {
  problems: [],
  problemsAnsweredCorrectly: 0,
  currentIdx: 0,
  selectedAnswer: null,
  currentProblem: null,
  isCorrectAnswer: false,
  nounAnswers: [],
  adjectiveAnswers: [],
  verbAnswers: [],
  nounAnswersTries: 0,
  adjectiveAnswersTries: 0,
  verbAnswersTries: 0,
  delay: 1000,
};

export const createNgeliMachine = () =>
  createMachine<NgeliContext>(
    {
      predictableActionArguments: true,
      id: 'ngeli-problem',
      initial: NgeliStep.IDLE,
      schema: {
        context: {} as NgeliContext,
      },
      context: initialState,
      states: {
        [NgeliStep.IDLE]: {
          on: {
            START: {
              target: NgeliStep.NOUN_INTRO,
              actions: ['start', 'prepareAnsweres'],
            },
          },
        },
        [NgeliStep.NOUN_INTRO]: {
          on: { NEXT: NgeliStep.NOUN_CHOICE },
        },
        [NgeliStep.NOUN_CHOICE]: {
          on: {
            ANSWER: {
              target: NgeliStep.NOUN_ANSWERED,
              actions: ['setSelectedAnswer', 'checkNounAnswer'],
            },
          },
        },
        [NgeliStep.NOUN_ANSWERED]: {
          exit: 'resetAnswer',
          entry: choose([
            {
              cond: 'isCorrectAnswer',
              actions: 'correctAnswerFeedback',
            },
            {
              cond: 'isIncorrectAnswer',
              actions: 'incorrectAnswerFeedback',
            },
          ]),
          after: [
            {
              delay: (context) => context.delay,
              target: NgeliStep.ADJECTIVE_INTRO,
              cond: 'isCorrectAnswer',
            },
            {
              delay: 1000,
              target: NgeliStep.NOUN_CHOICE,
              cond: 'isIncorrectAnswer',
            },
          ],
        },
        [NgeliStep.ADJECTIVE_INTRO]: {
          on: { NEXT: NgeliStep.ADJECTIVE_CHOICE },
        },
        [NgeliStep.ADJECTIVE_CHOICE]: {
          on: {
            ANSWER: {
              target: NgeliStep.ADJECTIVE_ANSWERED,
              actions: ['setSelectedAnswer', 'checkAdjectiveAnswer'],
            },
          },
        },
        [NgeliStep.ADJECTIVE_ANSWERED]: {
          exit: 'resetAnswer',
          entry: choose([
            {
              cond: 'isCorrectAnswer',
              actions: 'correctAnswerFeedback',
            },
            {
              cond: 'isIncorrectAnswer',
              actions: 'incorrectAnswerFeedback',
            },
          ]),
          after: [
            {
              delay: (context) => context.delay,
              target: NgeliStep.VERB_INTRO,
              cond: 'isCorrectAnswer',
            },
            {
              delay: 1000,
              target: NgeliStep.ADJECTIVE_CHOICE,
              cond: 'isIncorrectAnswer',
            },
          ],
        },
        [NgeliStep.VERB_INTRO]: {
          on: { NEXT: NgeliStep.VERB_CHOICE },
        },
        [NgeliStep.VERB_CHOICE]: {
          on: {
            ANSWER: {
              target: NgeliStep.VERB_ANSWERED,
              actions: ['setSelectedAnswer', 'checkVerbAnswer'],
            },
          },
        },
        [NgeliStep.VERB_ANSWERED]: {
          exit: 'resetAnswer',
          entry: choose([
            {
              cond: 'isCorrectAnswer',
              actions: 'correctAnswerFeedback',
            },
            {
              cond: 'isIncorrectAnswer',
              actions: 'incorrectAnswerFeedback',
            },
          ]),
          after: [
            {
              delay: (context) => context.delay,
              target: NgeliStep.REVIEW,
              cond: 'isCorrectAnswer',
            },
            {
              delay: 1000,
              target: NgeliStep.VERB_CHOICE,
              cond: 'isIncorrectAnswer',
            },
          ],
        },
        [NgeliStep.REVIEW]: {
          entry: ['playSentence', 'problemResults'],
          on: {
            PLAY_SENTENCE: { actions: 'playSentence' },
            NEXT: [
              {
                target: NgeliStep.NOUN_INTRO,
                cond: 'problemsLeft',
                actions: ['setupNextProblem', 'prepareAnsweres'],
              },
              {
                target: NgeliStep.IDLE,
                actions: 'showResults',
                cond: 'noProblemsLeft',
              },
            ],
          },
        },
      },
    },
    {
      actions: {
        start: assign((context, event) => ({
          ...initialState,
          delay: context.delay, // delay comes from outside
          problems: event.data,
          currentIdx: 0,
          currentProblem: event.data[0],
        })),
        setupNextProblem: assign((context) => ({
          currentIdx: context.currentIdx + 1,
          currentProblem: context.problems[context.currentIdx + 1],
        })),
        prepareAnsweres: assign((context) => {
          const problem = context.currentProblem;
          if (!problem) return {};
          return {
            nounAnswers: _.shuffle(problem.noun.categories),
            adjectiveAnswers: _.shuffle(
              problem.adjective.prefixes.filter((v) => !!v)
            ),
            verbAnswers: _.shuffle(problem.verb.prefixes.filter((v) => !!v)),
          };
        }),
        problemResults: assign((context) => {
          const answeredCorrectly =
            context.nounAnswersTries === 1 &&
            context.adjectiveAnswersTries === 1 &&
            context.verbAnswersTries === 1;

          return {
            problemsAnsweredCorrectly:
              context.problemsAnsweredCorrectly + (answeredCorrectly ? 1 : 0),
            nounAnswersTries: 0,
            adjectiveAnswersTries: 0,
            verbAnswersTries: 0,
          };
        }),

        checkNounAnswer: assign((context, event) => ({
          nounAnswersTries: context.nounAnswersTries + 1,
          isCorrectAnswer:
            context.selectedAnswer ===
            context.currentProblem?.noun.correctCategory,
        })),
        checkAdjectiveAnswer: assign((context, event) => ({
          adjectiveAnswersTries: context.adjectiveAnswersTries + 1,
          isCorrectAnswer:
            context.selectedAnswer ===
            context.currentProblem?.adjective.prefixes[0],
        })),
        checkVerbAnswer: assign((context, event) => ({
          verbAnswersTries: context.verbAnswersTries + 1,
          isCorrectAnswer:
            context.selectedAnswer === context.currentProblem?.verb.prefixes[0],
        })),
        resetAnswer: assign((context, event) => ({
          selectedAnswer: null,
          isCorrectAnswer: false,
        })),
        setSelectedAnswer: assign((context, event) => ({
          selectedAnswer: event.data,
        })),
      },
      guards: {
        problemsLeft: (context, event) =>
          !!context.problems &&
          context.problems.length - 1 > context.currentIdx,
        noProblemsLeft: (context, event) =>
          !!context.problems &&
          context.problems.length - 1 <= context.currentIdx,
        isCorrectAnswer: (context) => context.isCorrectAnswer,
        isIncorrectAnswer: (context) => !context.isCorrectAnswer,
      },
    }
  );
