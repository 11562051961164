import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { get } from "lodash";

const Checkboxes = ({
  options,
  current,
  changeHandler,
  name,
  legend,
  required
}) => {
  const currentValues = get(current, name) || [];

  return (
    <div>
      {legend && <StyledLegend>{legend}</StyledLegend>}
      {options.map(opt => {
        const label = typeof opt === "object" ? opt.label : opt;
        const value = typeof opt === "object" ? opt.value : opt;
        const isChecked = currentValues.includes(value);
        const isDisabled = isChecked && required && currentValues.length === 1;

        return (
          <StyledLabel key={value}>
            {label}
            <input
              type="checkbox"
              disabled={isDisabled}
              checked={isChecked}
              onChange={e => {
                const newValues = e.target.checked
                  ? [...currentValues, value]
                  : currentValues.filter(val => val !== value);

                changeHandler(name, newValues);
              }}
            />
          </StyledLabel>
        );
      })}
    </div>
  );
};

Checkboxes.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  current: PropTypes.object,
  changeHandler: PropTypes.func.isRequired
};

export default Checkboxes;

const StyledLegend = styled.b`
  display: block;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;

  input {
    margin-left: 5px;
  }
`;
