import StoryStudio from './StoryStudio';
import { validateWords, generateActivityFromWords } from './storyStudioHelpers';
import convertOldActivity from './convertOldStoryStudio';
import validateActivity from './validateStoryStudio';

export default {
  Template: StoryStudio,
  convertOldActivity,
  validateActivity,
  optionsArray: ['storyStudio', 'parentVideos'],
  generateActivityFromWords,
  validateWords,
};
