import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({
  title,
  closeHandler,
  isOpen,
  children,
  btnTitle,
  btnDisabled,
  btnHandler
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={closeHandler}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeHandler}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography component="span" variant="h6" className={classes.title}>
            {title}
          </Typography>

          {btnTitle && (
            <Button
              color="inherit"
              onClick={btnHandler}
              disabled={btnDisabled}
            >
              {btnTitle}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  title: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  btnTitle: PropTypes.string,
  btnDisabled: PropTypes.bool
};

export default FullScreenDialog;
