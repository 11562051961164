import React, { useState, Fragment } from "react";

import { DraggableModal, Clipart } from "components/flink-components";
import { imagesURL } from "config";
import { withTranslate } from "components/hocs";
import classes from "./AddImageDialog.module.scss";

const AddImageDialog = ({
  onPick,
  maxSize,
  closeHandler,
  show,
  translate,
  dispatch,
  ...otherProps
}) => {
  const [showClipart, setShowClipart] = useState(false);

  return (
    <Fragment>
      <DraggableModal
        title={translate(17, "Add Image")}
        show={show}
        onClose={closeHandler}
        minWidth={400}
        {...otherProps}
      >
        <div className={classes.wrapper}>
          <label
            className={`btn-effect ${classes.btn}`}
            title={translate(438, "From Computer")}
          >
            <input
              type="file"
              onChange={e => {
                const file = e.target.files[0];

                if (!file) return;

                if (maxSize && file.size > maxSize) {
                  return alert(
                    "Max size is " + parseFloat(maxSize / 1000 / 1000) + "mb"
                  );
                }

                onPick({ file });
              }}
              accept="image/*"
            />
            <img
              src={`${imagesURL}/FrontendUtilities/FromComputer.png`}
              alt={translate(438, "From Computer", true)}
            />
            {translate(438, "From Computer")}
          </label>
          <div
            className={`btn-effect ${classes.btn}`}
            title={translate(435, "From Clipart")}
            onClick={() => setShowClipart(true)}
          >
            <img
              src={`${imagesURL}/FrontendUtilities/FromClipart.png`}
              alt={translate(435, "From Clipart", true)}
            />
            {translate(435, "From Clipart")}
          </div>
        </div>
      </DraggableModal>
      <Clipart
        returnFile
        show={showClipart}
        onClose={() => setShowClipart(false)}
        onPick={file => {
          onPick({ file });
          setShowClipart(false);
        }}
      />
    </Fragment>
  );
};

export default withTranslate(AddImageDialog);
