import WordConnectables from './WordConnectables';
import convertOldActivity from './convertOldWordConnectables';
import validateActivity from './validateWordConnectables';

export default {
  Template: WordConnectables,
  convertOldActivity,
  validateActivity,
  optionsArray: [
    'delayBeforeNext',
    'fontFamily',
    'fontColor',
    'fontSize',
    'randomOrder',
    'showAnswer',
    'questionsLimit',
    'audioBefore'
  ]
};
