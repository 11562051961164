import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import checkPermissions from './checkPermissions';

const NeedPermissions = ({ auth, permissions, children }) => {
  const hasAccess = useMemo(() => checkPermissions(auth, permissions), [
    auth,
    permissions
  ]);

  return hasAccess ? children : null;
};

NeedPermissions.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(NeedPermissions);
