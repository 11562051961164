import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { openActivityHelp, showGameParentAdvice } from 'actions/gameActions';
import { showInfo, showError } from 'actions/statusActions';

import Buttons from '../Buttons/Buttons';
import { GO_BACK, HELP, SPEAK, PARENT_ADVICE, SHARE } from 'consts/buttons';

const ActivityButtons = ({
  parentAdviceUrl,
  openActivityHelp,
  buttons,
  history,
  gameGoBackHandler,
  prevPages,
  isPreview,
  isAuthenticated,
  currentActivityId,
  showGameParentAdvice,
  showInfo,
  showError,
  gameParentAdvice,
  multiplayerAdvice,
}) => {
  const formattedButtons = useMemo(() => {
    const buttonsArray = [...buttons];

    if (
      !buttonsArray.includes(PARENT_ADVICE) &&
      !_.find(buttonsArray, { type: PARENT_ADVICE })
    ) {
      buttonsArray.push(PARENT_ADVICE);
    }

    if (isPreview && isAuthenticated) {
      buttonsArray.push(SHARE);
    }

    return buttonsArray.map((btn) => {
      const btnObj = typeof btn === 'string' ? { type: btn } : { ...btn };

      if (btnObj.onClick) {
        return btnObj;
      }

      switch (btnObj.type) {
        case GO_BACK:
          const goBackHandler =
            gameGoBackHandler ||
            (() =>
              prevPages.length
                ? history.goBack()
                : history.push(
                  history.location.pathname.split('/').slice(0, -1).join('/')
                ));

          btnObj.onClick = goBackHandler;
          break;
        case HELP:
          btnObj.onClick = openActivityHelp;
          break;
        case SPEAK:
          btnObj.onClick =
            btnObj.onClick ||
            (() => {
              const event = new Event('playInstructionsAudio');
              document.dispatchEvent(event);
            });
          break;
        case PARENT_ADVICE: {
          const trigger = !gameParentAdvice;

          btnObj.onClick = () => {
            if (btnObj.preClick) btnObj.preClick(trigger);
            showGameParentAdvice(trigger);
          };
          btnObj.dontShow = btnObj.dontShow || multiplayerAdvice || !parentAdviceUrl;
          break;
        }
        case SHARE: {
          btnObj.onClick = async () => {
            const url = `${window.location.origin}/preview-activity/${currentActivityId}`;
            try {
              await navigator.clipboard.writeText(url);
              showInfo({ message: 'URL copied to clipboard' });
            } catch (error) {
              showError({
                message: `<p>Failed to copy URL.</p><p>Please use link below to share the activity:</p><br><a target="_blank" href="${url}"><b>${url}</b></a>`,
                html: true,
              });
            }
          }
          break;
        }
        default:
          btnObj.onClick = () => console.log('No Handler');
      }

      return btnObj;
    });
  }, [
    buttons,
    gameGoBackHandler,
    showGameParentAdvice,
    history,
    openActivityHelp,
    prevPages,
    isPreview,
    isAuthenticated,
    showInfo,
    showError,
    parentAdviceUrl,
    currentActivityId,
    gameParentAdvice,
    multiplayerAdvice
  ]);

  return <Buttons buttons={formattedButtons} />;
};

ActivityButtons.propTypes = {
  buttons: PropTypes.array.isRequired,
  prevPages: PropTypes.array,
  isPreview: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object.isRequired,
  gameGoBackHandler: PropTypes.func,
  openActivityHelp: PropTypes.func.isRequired,
  showGameParentAdvice: PropTypes.func.isRequired,
  parentAdviceUrl: PropTypes.string,
};

const mapStateToProps = ({ auth, common, status, game, flinkPlay }) => ({
  parentAdviceUrl: flinkPlay.parentAdviceUrl,
  globalButtons: common.globalButtons,
  prevPages: status.prevPages,
  isPreview: game.isPreview,
  isAuthenticated: auth.isAuthenticated,
  currentActivityId: game.currentActivity.activity._id,
  gameGoBackHandler: game.goBackHandler,
  gameParentAdvice: game.gameParentAdvice,
  multiplayerAdvice: game.gameMultiplayerAdvice,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    openActivityHelp,
    showGameParentAdvice,
    showInfo,
    showError,
  })
)(ActivityButtons);
