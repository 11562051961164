import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ScopeDataColumn from './ScopeDataColumn/ScopeDataColumn';
import ScopeAssign from './ScopeAssign/ScopeAssign';
import classes from './ScopeBuilder.module.scss';
import { validateScope } from 'validation';

const LEVELS = 'levels';
const GRADES = 'grades';


class ScopeBuilder extends Component {

  state = {
    selected: {},
    mode: 'edit'
  };

  componentDidMount() {
    // if (this.props.isEdit && validateScope(this.props.data)) {
    //   this.setState({mode: 'assign'})
    // }
  }

  changeMode = mode => this.setState({ mode });

  changeGrades = e => {
    const {
      options: { gradesOptions },
      data: { grades, levels, firstMenu, assignedLevels }
    } = this.props;

    const { checked, value: grade } = e.target;
    const newGrades = [...grades];

    checked ? newGrades.push(grade) : _.pull(newGrades, grade);

    const newAssignedLevels = checked
      ? assignedLevels
      : assignedLevels.filter(lev => lev.grade !== grade);

    const sortedGrades = newGrades.sort(
      (a, b) =>
        _.findIndex(gradesOptions, { value: a }) -
        _.findIndex(gradesOptions, { value: b })
    );

    const newFirstMenu = this.getFirstMenu(sortedGrades, levels, firstMenu);

    this.props.changeHandler({
      grades: sortedGrades,
      firstMenu: newFirstMenu,
      assignedLevels: newAssignedLevels
    });
  };

  changeLevels = e => {
    const {
      options: { levelsOptions },
      data: { assignedLevels, levels, grades, firstMenu }
    } = this.props;

    const { checked, value: level } = e.target;
    const newLevels = [...levels];

    checked ? newLevels.push(level) : _.pull(newLevels, level);

    const newAssignedLevels = checked
      ? assignedLevels
      : assignedLevels.filter(lev => lev.level !== level);

    const sortedLevels = newLevels.sort(
      (a, b) =>
        _.findIndex(levelsOptions, { value: a }) -
        _.findIndex(levelsOptions, { value: b })
    );

    const newFirsMenu = this.getFirstMenu(grades, sortedLevels, firstMenu);

    this.props.changeHandler({
      levels: sortedLevels,
      firstMenu: newFirsMenu,
      assignedLevels: newAssignedLevels
    });
  };

  getFirstMenu = (grades, levels, current) => {
    if (grades.length && levels.length) {
      return current || GRADES;
    }

    if (grades.length && !levels.length) {
      return GRADES;
    }

    if (levels.length && !grades.length) {
      return LEVELS;
    }
  };

  assignLevels = (options, info) => {
    const {
      data: { assignedLevels = [] }
    } = this.props;

    const { action, name: grade } = info;
    let newAssociatedLevels = [...assignedLevels];

    if (action === 'select-option') {
      const { value: selectedLevel } = info.option;
      newAssociatedLevels.push({ level: selectedLevel, grade });
    }

    if (action === 'clear') {
      newAssociatedLevels = _.reject(newAssociatedLevels, { grade });
    }

    if (action === 'remove-value') {
      const { value: removedLevel } = info.removedValue;
      newAssociatedLevels = _.reject(newAssociatedLevels, {
        level: removedLevel
      });
    }

    this.props.changeHandler({ assignedLevels: newAssociatedLevels });
  };

  changeFirstMenu = firstMenu => {
    this.props.changeHandler({ firstMenu });
  };

  handleSelect = (category, id) => {
    const { selected } = this.state;

    let newSelected;

    if (category === 'firstCategory') {
      newSelected = { [category]: id };
    } else if (category === 'secondCategory') {
      newSelected = { firstCategory: selected.firstCategory, [category]: id };
    } else {
      newSelected = { ...selected, [category]: id };
    }

    this.setState({ selected: newSelected });
  };

  changeTitleHandler = (category, value) => {
    this.props.changeHandler({ [`${category}.title`]: value });
  };

  changeCategoryItems = (category, items) => {
    const {
      selected
      // data: { firstCategory, secondCategory, thirdCategory }
    } = this.state;
    const {
      data: { firstCategory, secondCategory, thirdCategory }
    } = this.props;

    if (category === 'firstCategory') {
      return this.props.changeHandler({ firstCategory: { ...firstCategory, items } });
    }

    if (category === 'secondCategory') {
      const listIndex = _.findIndex(secondCategory.lists, {
        firstCategoryId: selected.firstCategory
      });

      const listsCopy = JSON.parse(JSON.stringify(secondCategory.lists));

      if (listIndex !== -1) {
        listsCopy[listIndex].items = items;
      } else {
        const newList = { firstCategoryId: selected.firstCategory, items };
        listsCopy.push(newList);
      }
      this.props.changeHandler({
        secondCategory: { ...secondCategory, lists: listsCopy }
      });
    }

    if (category === 'thirdCategory') {
      const listIndex = _.findIndex(thirdCategory.lists, {
        firstCategoryId: selected.firstCategory,
        secondCategoryId: selected.secondCategory
      });

      const listsCopy = JSON.parse(JSON.stringify(thirdCategory.lists));

      if (listIndex !== -1) {
        listsCopy[listIndex].items = items;
      } else {
        const newList = {
          firstCategoryId: selected.firstCategory,
          secondCategoryId: selected.secondCategory,
          items
        };
        listsCopy.push(newList);
      }
      this.props.changeHandler({
        thirdCategory: { ...thirdCategory, lists: listsCopy }
      });
    }
  };

  deleteCategoryItem = category => {
    const { selected } = this.state;
    const {
      data: { firstCategory, secondCategory, thirdCategory }
    } = this.props;

    if (category === 'firstCategory') {
      const changedFirstCategory = firstCategory.items.filter(
        item => item.id !== selected.firstCategory
      );
      const changedSecondCategoryLists = secondCategory.lists.filter(
        list => list.firstCategoryId !== selected.firstCategory
      );
      const changedThirdCategoryLists = thirdCategory.lists.filter(
        list => list.firstCategoryId !== selected.firstCategory
      );

      return this.props.changeHandler({
        'firstCategory.items': JSON.parse(JSON.stringify(changedFirstCategory)),
        'secondCategory.lists': JSON.parse(
          JSON.stringify(changedSecondCategoryLists)
        ),
        'thirdCategory.lists': JSON.parse(
          JSON.stringify(changedThirdCategoryLists)
        )
      });
    }

    if (category === 'secondCategory') {
      const changedSecondCategoryLists = secondCategory.lists.map(list => {
        return {
          ...list,
          items: _.reject(list.items, { id: selected.secondCategory })
        };
      });
      const changedThirdCategoryLists = thirdCategory.lists.filter(
        list => list.secondCategoryId !== selected.secondCategory
      );

      return this.props.changeHandler({
        'secondCategory.lists': JSON.parse(
          JSON.stringify(changedSecondCategoryLists)
        ),
        'thirdCategory.lists': JSON.parse(
          JSON.stringify(changedThirdCategoryLists)
        )
      });
    }

    if (category === 'thirdCategory') {
      const changedThirdCategoryLists = thirdCategory.lists.map(list => {
        return {
          ...list,
          items: _.reject(list.items, { id: selected.thirdCategory })
        };
      });

      return this.props.changeHandler({
        'thirdCategory.lists': JSON.parse(
          JSON.stringify(changedThirdCategoryLists)
        )
      });
    }

    this.handleSelect(category, null);
  };

  render() {
    const {
      data,
      options: { gradesOptions, levelsOptions }
    } = this.props;

    if (!data) return null;

    const {
      grades,
      levels,
      firstCategory,
      secondCategory,
      thirdCategory
    } = data;

    const { selected, mode } = this.state;

    if (mode === 'assign') {
      return this.renderAssign();
    }

    const secondCategoryList = _.find(secondCategory.lists, {
      firstCategoryId: selected.firstCategory
    });

    const thirdCategoryList = _.find(thirdCategory.lists, {
      firstCategoryId: selected.firstCategory,
      secondCategoryId: selected.secondCategory
    });

    return (
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={this.changeMode.bind(null, 'assign')}
          disabled={!validateScope(data)}
        >
          Assign Categories
        </Button>

        <span className={classes.formLabel}>Select Grades:</span>
        {gradesOptions &&
          gradesOptions.map((opt, idx) => (
            <FormControlLabel
              key={opt.value}
              control={
                <Checkbox
                  color="primary"
                  checked={grades.includes(opt.value)}
                  onChange={this.changeGrades}
                  value={opt.value}
                />
              }
              label={opt.label}
            />
          ))}

        <span className={classes.formLabel}>Select Levels:</span>
        {levelsOptions &&
          levelsOptions.map(opt => (
            <FormControlLabel
              key={opt.value}
              control={
                <Checkbox
                  color="primary"
                  checked={levels.includes(opt.value)}
                  onChange={this.changeLevels}
                  value={opt.value}
                />
              }
              label={opt.label}
            />
          ))}

        {this.renderAssociateLevels()}

        <div className={classes.columnsWrapper}>
          <ScopeDataColumn
            classes={classes}
            categoryName="firstCategory"
            title={firstCategory.title}
            changeTitleHandler={this.changeTitleHandler}
            data={firstCategory.items}
            selected={selected.firstCategory}
            handleSelect={this.handleSelect}
            changeDataHandler={this.changeCategoryItems}
            deleteHandler={this.deleteCategoryItem}
          />
          <ScopeDataColumn
            classes={classes}
            categoryName="secondCategory"
            title={secondCategory.title}
            changeTitleHandler={this.changeTitleHandler}
            data={(secondCategoryList && secondCategoryList.items) || []}
            selected={selected.secondCategory}
            handleSelect={this.handleSelect}
            changeDataHandler={this.changeCategoryItems}
            disabled={!selected.firstCategory}
            deleteHandler={this.deleteCategoryItem}
          />
          <ScopeDataColumn
            classes={classes}
            categoryName="thirdCategory"
            title={thirdCategory.title}
            changeTitleHandler={this.changeTitleHandler}
            data={(thirdCategoryList && thirdCategoryList.items) || []}
            selected={selected.thirdCategory}
            handleSelect={this.handleSelect}
            changeDataHandler={this.changeCategoryItems}
            disabled={!selected.firstCategory || !selected.secondCategory}
            deleteHandler={this.deleteCategoryItem}
          />
        </div>
      </div>
    );
  }

  renderAssign = () => {
    const { data, changeHandler } = this.props;
    return (
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={this.changeMode.bind(null, 'edit')}
        >
          Edit Categories
        </Button>
        <ScopeAssign changeHandler={changeHandler} data={data} classes={classes} />
      </div>
    );
  };

  renderAssociateLevels = () => {
    const {
      data: { grades, levels, assignedLevels, firstMenu }
    } = this.props;
    if (!grades.length || !levels.length) return null;

    return (
      <Fragment>
        <span className={classes.formLabel}>Associate grades with levels:</span>
        <div className={classes.gradeGroups}>
          {grades.map(grade => {
            const options = levels
              .filter(lev => {
                const associatedLevel = _.find(assignedLevels, {
                  level: lev
                });
                return !associatedLevel || associatedLevel.grade === grade;
              })
              .map(lev => ({ value: lev, label: lev }));

            const selectedOptions = options.filter(
              opt =>
                _.findIndex(assignedLevels, { level: opt.value, grade }) !== -1
            );

            return (
              <div key={grade}>
                <span className={classes.formLabel}>{grade}:</span>
                <Select
                  value={selectedOptions}
                  closeMenuOnSelect={false}
                  isMulti
                  name={grade}
                  components={makeAnimated()}
                  options={options}
                  onChange={this.assignLevels}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            );
          })}
        </div>

        <span className={classes.formLabel}>First Menu:</span>
        <RadioGroup
          name="firstMenu"
          value={firstMenu}
          onChange={(e, val) => this.changeFirstMenu(val)}
          options={[]}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value={GRADES}
            control={<Radio color="primary" />}
            label="Grades"
          />
          <FormControlLabel
            value={LEVELS}
            control={<Radio color="primary" />}
            label="Levels"
          />
        </RadioGroup>
      </Fragment>
    );
  };
}

ScopeBuilder.propTypes = {
  options: PropTypes.object.isRequired,
  initialData: PropTypes.object,
  changeHandler: PropTypes.func.isRequired
};

export default ScopeBuilder;
