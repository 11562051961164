import { validateProblem } from "./ebookHelpers";

export default ({ options, gameData }) => {
  return !(
    !options ||
    !gameData ||
    !gameData.problems ||
    !gameData.problems.filter(validateProblem).length
  );
};
