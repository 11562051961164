import React, { useMemo } from 'react';
import classes from '../AnswersBlock.module.scss';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import _ from 'lodash';

const INITIAL_DELAY = 0;

const getDelays = (num, speed) =>
  _.range(INITIAL_DELAY, speed * num + INITIAL_DELAY, speed);

const KeepGoingAnswers = ({
  useImages,
  animation,
  question,
  isResolved,
  isMultiplayer,
  animationSpeed,
  coloredAnswerBoxes,
  contentPath,
  colors,
  textStyle,
  clickHandler,
  visibleAfterResolvedTime
}) => {
  const answersDelays = useMemo(
    () => getDelays(question.answers.length, animationSpeed),
    [question, animationSpeed]
  );

  const isHorizontalAnimation =
    animation === 'leftToRight' || animation === 'rightToLeft';

  return (
    <div
      className={classnames({
        [classes.horizontalKeepGoingBlock]: isHorizontalAnimation,
        [classes.verticalKeepGoingBlock]: !isHorizontalAnimation,
        [classes.multiplayer]: isMultiplayer,
      })}
    >
      {question.answers.map((answer, index) => {
        const animationDuration = animationSpeed;
        const animationDelay = answersDelays[index];

        const answerPostition = useImages
          ? {
              left: isHorizontalAnimation
                ? 'auto'
                : (100 / question.answers.length) * index +
                  100 / (question.answers.length * 2) +
                  '%',
              top: isHorizontalAnimation
                ? (100 / (question.answers.length * 2)) * index +
                  100 / (question.answers.length * 2) +
                  '%'
                : 'auto',
              transform: 'translateX(-1000%)'
            }
          : {
              left: isHorizontalAnimation
                ? 'auto'
                : (100 / question.answers.length) * index +
                  100 / (question.answers.length * 2) +
                  '%',
              top: isHorizontalAnimation
                ? (100 / question.answers.length) * index +
                  100 / (question.answers.length * 2) +
                  '%'
                : 'auto',
              transform: 'translateX(-1000%)'
            };

        return (
          <CSSTransition
            appear
            unmountOnExit
            mountOnEnter
            in={!isResolved}
            key={answer.id}
            timeout={{
              appear: animationDelay,
              exit:
                animationDuration +
                (answer.correct && !question.fillIn
                  ? visibleAfterResolvedTime * 1000
                  : 0)
            }}
            classNames={{
              appearActive: classes.fadeIn,
              enterDone: classes[animation],
              exit: classes[animation],
              exitActive: classes[animation]
            }}
          >
            {state => {
              const animationProps = {
                animationDuration:
                  (state === 'entering'
                    ? animationDuration / 1000
                    : (animationDuration * 10) / 1000) + 's',
                animationDelay:
                  (state === 'entering'
                    ? animationDelay / 4 / 1000
                    : animationDelay / 1000) + 's',
                animationPlayState: isResolved ? 'paused' : 'running'
              };

              return (
                <div
                  className={classnames(classes.keepGoingWrapper, {
                    [classes.imageAnswerWrapper]: useImages,
                    [classes.textAnswerWrapper]: !useImages
                  })}
                  style={{
                    ...answerPostition,
                    ...animationProps
                  }}
                >
                  <button
                    className={classnames(classes.answerBtn, {
                      [classes.fadeOut]: state === 'exiting'
                    })}
                    disabled={isResolved || state === 'entering'}
                    style={{
                      ...textStyle,
                      ...(coloredAnswerBoxes ? colors[index] : {}),
                      animationDuration: animationDuration / 1000 + 's',
                      animationDelay:
                        (answer.correct && !question.fillIn
                          ? visibleAfterResolvedTime
                          : 0) + 's'
                    }}
                    onClick={clickHandler.bind(null, answer)}
                  >
                    {useImages ? (
                      <img  src={contentPath + answer.image} alt="" />
                    ) : (
                      answer.text
                    )}
                  </button>
                </div>
              );
            }}
          </CSSTransition>
        );
      })}
    </div>
  );
};

export default KeepGoingAnswers;
