import React from 'react';

import Item from '../Item/Item';
import { WhiteBox } from 'components/flink-play';
import classes from './QuestionItem.module.scss';

const QuestionItem = ({ data }) => {
  return (
    <WhiteBox outerClass={classes.box} innerClass={classes.inner}>
      <Item data={data} bigger />
    </WhiteBox>
  );
};

export default QuestionItem;
