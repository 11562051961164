import styled from 'styled-components';

export const StyledError = styled.p`
  font-size: 1.4rem;
  color: #df2a26;
`;

export const StyledSuccess = styled.p`
  font-size: 1.4rem;
  color: green;
`;
