import { connect } from 'react-redux';
import { compose } from 'redux';
import { useMemo } from 'react';

import { withTranslate } from 'components/hocs';
import { prepareButtons } from 'utils';

// components
import WhiteBox from 'components/flink-play/WhiteBox/WhiteBox';
import { QuickStartVideos, VideoAnimation } from 'components/flink-play';

// button types
import {
  CREDITS,
  QUICKSTART_VIDEOS,
  HELP,
} from 'consts/buttons';

// css
import classes from './HelpControls.module.scss';

// actions
import { viewLoginPageDocument } from "actions/commonActions";
import { showLoginFlinkPlayHelp, showQuickstartVideos } from "actions/flinkPlayActions";

const getDocumentWithFallback = (fileMap, locale) => {
  /*
  Filemap:
  {locale: fileId}
  */
  const localePriority = [locale.code]; // [locale.code, 'en'];
  for (const localeCode of localePriority) {
    const documentId = fileMap[localeCode];
    if (documentId) {
      return { id: documentId, locale: localeCode };
    }
  }
  return null;
}

const HelpControls = ({
  translate,
  video,
  quickStartVideos,
  globalButtons,
  lang,
  documents,
  viewLoginPageDocument,
  showLoginFlinkPlayHelp,
  showQuickstartVideos,
}) => {
  const quickStartHelpVideos = documents.quickStart[lang?.code];

  const buttons = useMemo(() => {
    const creditsHelpDoc = getDocumentWithFallback(documents.credits, lang);
    return prepareButtons(
      [
        {
          type: CREDITS,
          onClick: () => viewLoginPageDocument(
            'credits',
            creditsHelpDoc?.locale,
            creditsHelpDoc?.id,
          ),
          dontShow: !creditsHelpDoc,
        },
        {
          type: QUICKSTART_VIDEOS,
          onClick: () => showQuickstartVideos(true),
          dontShow: !quickStartHelpVideos?.length,
        },
        {
          type: HELP,
          onClick: showLoginFlinkPlayHelp,
        },
      ],
      globalButtons,
      translate,
    ).filter(button => button);
  },
    [
      globalButtons, translate,
      documents, lang, viewLoginPageDocument,
      showLoginFlinkPlayHelp, quickStartHelpVideos,
      showQuickstartVideos,
    ]);

  return (
    <>
      <WhiteBox innerClass={classes.innerClass} outerClass={classes.outerClass}>
        {buttons.map((button, index) => <div key={index}>
          {button}
        </div>)}
      </WhiteBox>
      {/* QuickStart videos modal */}
      {quickStartVideos && <QuickStartVideos language={lang} />}
      {video && <VideoAnimation />}
    </>
  );
}

const mapStateToProps = ({ flinkPlay, status, common }) => ({
  globalButtons: common.globalButtons,
  documents: common.config.loginPageDocuments,
  video: flinkPlay.video,
  quickStartVideos: flinkPlay.quickStartVideos,
  lang: status.lang,
});

export default compose(
  withTranslate,
  connect(mapStateToProps, {
    viewLoginPageDocument,
    showLoginFlinkPlayHelp,
    showQuickstartVideos,
  }),
)(HelpControls);
