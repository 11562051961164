import React from 'react';
import { connect } from 'react-redux';
import { TemplateWrapper } from "components/flink-play";

const GuestActivityPreview = ({ translate, match }) => {
    const { activityId } = match.params;
    return (activityId && translate) && (
        <TemplateWrapper
            activity={activityId}
            preview
        />
    );
};

const mapStateToProps = ({ status }) => ({
    translate: status.translate,
});

export default connect(mapStateToProps)(GuestActivityPreview);
