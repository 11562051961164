import { withLoading } from 'utils';
import api from 'api';

export const getCleverIntegration = (masterOrganizationId) => dispatch =>
    withLoading(dispatch, async () => {
        return await api.masterOrganizations.getCleverIntegration(masterOrganizationId);
    });

export const integrateWithClever = (masterOrganizationId, data) => dispatch =>
    withLoading(dispatch, async () => {
        return await api.masterOrganizations.integrateWithClever(masterOrganizationId, data);
    });

export const deleteCleverIntegration = (masterOrganizationId) => dispatch =>
    withLoading(dispatch, async () => {
        return await api.masterOrganizations.deleteCleverIntegration(masterOrganizationId);
    });

export const syncClever = (masterOrganizationId) => dispatch =>
    withLoading(dispatch, async () => {
        return await api.masterOrganizations.syncClever(masterOrganizationId);
    });

