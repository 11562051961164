import { useEffect, useState } from 'react';
import { match } from 'react-router-dom';

import classes from './Register.module.scss';
import RegisterForm from './RegisterForm/RegisterForm';
import RegisterHeader from './RegisterHeader';
import api from 'api';

type FinishPaidRegistrationProps = {
  history: any;
  match: match<{ pendingRegistration: string }>;
};

const FinishPaidRegistration = ({
  match,
  history,
}: FinishPaidRegistrationProps) => {
  const [processing, setProcessing] = useState(true);
  const [pendingRegistration, setPendingRegistration] = useState<{
    email: string;
  }>();
  const [locations, setLocations] = useState<Partial<string[]>>();

  useEffect(() => {
    const validate = async (id: string) => {
      const result = await api.pendingRegistration.getById(id);

      if (!result.success) {
        alert(result?.error || 'Something goes wrong');
        return history.push('/');
      }

      if (!result.data.isPaid) {
        alert('Not paid');
        return history.push('/');
      }

      if (result.data.isFinished) {
        alert('Already completed');
        return history.push('/');
      }

      setProcessing(false);
      setPendingRegistration(result.data);
      setLocations(result.locations);
    };

    validate(match.params.pendingRegistration);
  }, [history, match.params.pendingRegistration]);

  return (
    <div className={classes.wrapper}>
      <RegisterHeader />

      <main className={classes.main}>
        {processing && <p>Processing...</p>}

        {pendingRegistration && (
          <RegisterForm
            initialValues={{
              email: pendingRegistration.email,
              messageLocale: 'en',
            }}
            disabledFields={{ email: true }}
            submit={(values) => {
              return api.pendingRegistration.finishRegistration(
                match.params.pendingRegistration,
                values
              );
            }}
            locations={locations}
          />
        )}
      </main>
    </div>
  );
};

export default FinishPaidRegistration;
