import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import GameModal from '../GameModal/GameModal';
import CharacterAnimation from '../CharacterAnimation/CharacterAnimation';
import classes from './ActivityResults.module.scss';
import {
  setActivityPartial,
  setActivityMastered,
} from 'actions/flinkPlayActions';
import { restartActivity, showStats } from 'actions/gameActions';
import { playApplause, stopAudio } from 'actions/audioActions';
import { formatHtmlForPlay } from 'utils';
import {
  PlayAgainIcon,
  MenuIcon,
  StarIcon,
} from 'components/flink-play/FlinkPlayIcons/FlinkPlayIcons';

const DEFAULT_STATE = {
  title: '',
  translate: null,
  wrapperStyles: {},
  formattedHtml: '',
  dialogVisible: false,
  characterAnimationVisible: false,
};

class ActivityResults extends Component {
  state = { DEFAULT_STATE };

  componentDidMount() {
    if (this.props.stats) {
      this.initStats();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { stats } = this.props;

    if (!prevProps.stats && stats) {
      this.initStats();
    } else if (prevProps.stats && !stats) {
      stopAudio();
      this.setState(DEFAULT_STATE);
    }
  }

  initStats = () => {
    const {
      isPreview,
      stats,
      learningCenter,
      solutionTranslate,
      generalTranslate,
      setActivityPartial,
      setActivityMastered,
      isWordlistActivity,
    } = this.props;

    const translate = solutionTranslate || generalTranslate;

    const {
      isCongratulations = false,
      predefinedMastered = false,
      withScore = false,
      html: originHtml,
      data = {},
      ebookBenchmark,
    } = stats;

    // Title
    // const title = isCongratulations
    //   ? translate(225, "Congratulations")
    //   : translate(10, "Activity Results");
    const title = translate(10, 'Activity Results');

    let score, percentAnswersForMaster;

    if (withScore) {
      // Get percent for Master
      const percent =
        learningCenter &&
        learningCenter.config &&
        learningCenter.config.percentAnswers;

      percentAnswersForMaster = parseInt(percent || '50%');

      // Calculate Score
      const { allProblemsCount, problemsAnsweredCorrectly } = data;

      score = Math.ceil((problemsAnsweredCorrectly / allProblemsCount) * 100);
    }

    const isMastered =
      (withScore && score >= percentAnswersForMaster) ||
      (ebookBenchmark && ebookBenchmark.speed >= ebookBenchmark.benchmark) ||
      isCongratulations ||
      predefinedMastered;

    if (isMastered) {
      !isPreview && setActivityMastered();
    } else {
      !isPreview && setActivityPartial();
    }

    // WrapperStyles
    const wrapperStyles = {
      fontSize: !originHtml && '2vmin',
    };

    // Congratulations: Mastered - 351
    // Congratulations: No mention - 353
    // Activity Results: Mastered - 346
    // Activity Results: Not Mastered - 347
    // Activity Results: No mention - 348
    // Activity Results: Vocabulary - 349
    // Activity Results Ebook Mastered: 633
    // Activity Results Ebook NOT Mastered: 503

    let stringNumber;

    if (!originHtml) {
      if (typeof score === 'number') {
        stringNumber = isWordlistActivity ? 349 : isMastered ? 346 : 347;
      } else if (isCongratulations) {
        stringNumber = 351;
      } else if (ebookBenchmark) {
        stringNumber = isMastered ? 633 : 503;
      }
    }

    const html = stringNumber
      ? translate(stringNumber, undefined, undefined, true)
      : originHtml;

    const formattedHtml = formatHtmlForPlay({ html, data: { ...data, score } });

    this.setState(
      {
        title,
        translate,
        wrapperStyles,
        formattedHtml,
        isMastered,
        score,
      },
      isMastered ? this.showCharacterAnimation : this.showDialog
    );
  };

  showDialog = () =>
    this.setState({ dialogVisible: true, characterAnimationVisible: false });

  showCharacterAnimation = () =>
    this.setState({ characterAnimationVisible: true });

  onCharacterReady = () => {
    if (this.state.isMastered) {
      this.props.playApplause(true); // true - for character animation
    }
  };

  onNoCharacter = () => {
    if (this.state.isMastered) {
      this.props.playApplause();
    }

    this.showDialog();
  };

  render() {
    const {
      history,
      gameGoBackHandler,
      restartActivity,
      stats,
      showStats,
    } = this.props;

    const {
      title,
      translate,
      wrapperStyles,
      formattedHtml,
      isMastered,
      score,
      dialogVisible,
      characterAnimationVisible,
    } = this.state;

    if (characterAnimationVisible) {
      return (
        <CharacterAnimation
          onReady={this.onCharacterReady}
          onFinish={this.showDialog}
          onError={this.showDialog}
          onNoCharacter={this.onNoCharacter}
        />
      );
    }

    if (!dialogVisible) return null;

    const { onlyCloseButton, data = {}, withScore } = stats || {};
    const { allProblemsCount, problemsAnsweredCorrectly } = data;

    return (
      <GameModal
        // title={title}
        centerButtons
        buttons={
          onlyCloseButton
            ? [
                {
                  title: translate(80, 'Close'),
                  clickHandler: () => showStats(null),
                },
              ]
            : []
        }
        // buttons={
        //   onlyCloseButton
        //     ? [
        //         {
        //           title: translate(80, "Close"),
        //           clickHandler: () => showStats(null)
        //         }
        //       ]
        //     : [
        //         {
        //           title: translate(304, "Play Again"),
        //           clickHandler: restartActivity
        //         },
        //         {
        //           title: translate(174, "Menu"),
        //           clickHandler: gameGoBackHandler || history.goBack
        //         }
        //       ]
        // }
      >
        <div className={classes.wrapper} style={wrapperStyles}>
          <div className={classes.title}>
            <span>{title}:</span>
            <StarIcon mastered={isMastered} partial />
          </div>

          {!withScore && (
            <div dangerouslySetInnerHTML={{ __html: formattedHtml }}></div>
          )}

          {withScore && (
            <>
              <div className={classes.activityScoreProgress}>
                {_.times(allProblemsCount, Number).map((num, idx) => (
                  <span
                    key={num}
                    className={
                      idx < problemsAnsweredCorrectly
                        ? classes.progressFilled
                        : ''
                    }
                  />
                ))}
              </div>

              <div className={classes.stats}>
                {translate(678, 'Score: ')} {problemsAnsweredCorrectly}/
                {allProblemsCount} ({score})%
              </div>
            </>
          )}

          {!onlyCloseButton && (
            <div className={classes.imageButtons}>
              <button
                onClick={restartActivity}
                // title={translate(304, 'Play Again', true)}
              >
                <PlayAgainIcon />
                <span>{translate(304, 'Play Again', true)}</span>
              </button>
              <button
                onClick={gameGoBackHandler || history.goBack}
                // title={translate(174, 'Menu', true)}
              >
                <MenuIcon />
                <span>{translate(174, 'Menu', true)}</span>
              </button>
            </div>
          )}
        </div>
      </GameModal>
    );
  }
}

ActivityResults.propTypes = {
  isPreview: PropTypes.bool,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  playApplause: PropTypes.func.isRequired,
  restartActivity: PropTypes.func.isRequired,
  solutionTranslate: PropTypes.func,
  generalTranslate: PropTypes.func.isRequired,
  gameGoBackHandler: PropTypes.func,
  showStats: PropTypes.func.isRequired,
  setActivityPartial: PropTypes.func.isRequired,
  setActivityMastered: PropTypes.func.isRequired,
};

const mapStateToProps = ({ game, flinkPlay, status }) => ({
  isPreview: game.isPreview,
  isWordlistActivity: !!game.currentActivity.activity.generatedFrom,
  stats: game.stats,
  gameGoBackHandler: game.goBackHandler,
  generalTranslate: status.translate,
  solutionTranslate: flinkPlay.solutionTranslate,
  learningCenter: flinkPlay.learningCenter,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    restartActivity,
    playApplause,
    showStats,
    setActivityPartial,
    setActivityMastered,
  })
)(ActivityResults);
