import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  showPrompt,
  showError,
  showInfo,
  showConfirm
} from "actions/statusActions";

import InfoDialog from "./InfoDialog";
import ErrorDialog from "./ErrorDialog";
import PromptDialog from "./PromptDialog";
import ConfirmDialog from "./ConfirmDialog";

const Dialogs = ({
  error,
  showError,
  info,
  showInfo,
  prompt,
  confirm,
  showPrompt,
  showConfirm,
  translate
}) => (
  <Fragment>
    {info && (
      <InfoDialog translate={translate} info={info} showInfo={showInfo} />
    )}
    {error && (
      <ErrorDialog translate={translate} error={error} showError={showError} />
    )}
    {prompt && (
      <PromptDialog
        translate={translate}
        prompt={prompt}
        showPrompt={showPrompt}
      />
    )}
    {confirm && (
      <ConfirmDialog
        translate={translate}
        confirm={confirm}
        showConfirm={showConfirm}
      />
    )}
  </Fragment>
);

const mapStateToProps = ({
  status: { error, info, prompt, confirm, translate }
}) => ({
  error,
  info,
  prompt,
  confirm,
  translate
});

export default compose(
  connect(mapStateToProps, { showPrompt, showError, showInfo, showConfirm })
)(Dialogs);
