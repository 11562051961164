import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const withTranslate = (WrappedComponent) => {
  const Wrapper = ({ translate, ...otherProps }) => {
    return <WrappedComponent {...otherProps} translate={translate} />;
  };

  Wrapper.propTypes = {
    translate: PropTypes.func.isRequired,
  };

  const mapStateToProps = ({ status }) => ({
    translate: status.translate,
  });

  return connect(mapStateToProps)(Wrapper);
};

export default withTranslate;
