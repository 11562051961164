import React, { useCallback, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';

import { getLoginScreenByEmail } from 'actions/authActions';
import { changeLocale } from 'actions/statusActions';

import { imagesURL } from 'config';
import { redirect } from 'utils';
import classes from './Home.module.scss';

// Authentication form (for admins & staff)
import { Modal } from 'components/common';
import AuthenticationForm from './AuthenticationForm';

const Home = ({ auth, translate, getLoginScreenByEmail, ...props }) => {
  const history = useHistory();
  const { locales, currentLocale, changeLocale } = props;

  const [error, setError] = useState('');
  const [authModal, setAuthModal] = useState({
    open: false,
    familyLoginUrl: undefined,
  });

  const closeAuthModal = React.useCallback(() => setAuthModal({ open: false }), [setAuthModal]);
  const handleLoginScreen = React.useCallback((data) => {
    if (data.dialogLoginFlow) {
      const { familyLoginUrl } = data;
      return setAuthModal({ open: true, familyLoginUrl });
    }
    return history.push(data.data);
  }, [history, setAuthModal]);

  const submitHandler = useCallback(
    async (e) => {
      if (authModal.open) return e.preventDefault();

      e.preventDefault();
      setError('');

      const emailValue = emailRef.current.value;

      const result = await getLoginScreenByEmail(emailValue);

      if (result.success) {
        for (const ref of [emailRef, buttonRef]) {
          ref.current?.blur();
        }
        handleLoginScreen(result);
      } else {
        setError(result.error || 'Something goes wrong');
      }
    },
    [authModal, getLoginScreenByEmail, handleLoginScreen]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email');
    if (email) {
      getLoginScreenByEmail(email).then((result) => {
        if (result.success) {
          handleLoginScreen(result);
        } else {
          history.replace(window.location.pathname);
        }
      });
    }
  }, [getLoginScreenByEmail, history, handleLoginScreen, submitHandler]);

  const emailRef = useRef();
  const buttonRef = useRef();

  // const openCodePrompt = () => {
  //   const code = window.prompt('Enter your code:');

  //   if (code) {
  //     history.push(`/register-by-code/${code}`);
  //   }
  // };

  if (auth.isAuthenticated) {
    return <Redirect to={redirect(auth)} />;
  }

  return (
    <div className={classes.wrapper}>
      <header className={classes.header}>
        <div className={classes.spaceBetween}>
          <div className={classes.logoWrapper}>
            <img src={`${imagesURL}/Images/Login/FLCLogo.png`} alt="Flink logo" />
          </div>
          <div className={classes.langSelect}>
            <select value={currentLocale?.code} onChange={({ target }) => changeLocale(target.value)}>
              {locales.map(locale => (
                <option key={locale._id} value={locale.code}>
                  {locale.displayName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <h1 className={classes.pageTitle}>{translate(104, 'Login')}</h1>
      </header>

      <main className={classes.main}>
        <p>
          {translate(1013, 'Enter your email then click OK.')}
        </p>

        <form className={classes.form} onSubmit={submitHandler}>
          <input
            placeholder={translate(911, 'Email')}
            type="email"
            required
            ref={emailRef}
          />
          <button ref={buttonRef} type="submit">{translate(191, 'OK')}</button>
          {error && <p className={classes.formError}>{error}</p>}
        </form>

        <Modal on={authModal.open} toggle={closeAuthModal}>
          <AuthenticationForm 
            emailRef={emailRef}
            familyLoginUrl={authModal.familyLoginUrl}
            toggleHandler={closeAuthModal}
          />
        </Modal>

        {/* <div className={classes.registerBtnWrapper}>
          <button className={classes.registerBtn} onClick={openCodePrompt}>
            Register by code
          </button>
        </div> */}
      </main>
    </div>
  );
};

Home.propTypes = {
  auth: PropTypes.object,
  getLoginScreenByEmail: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, common, status }) => ({
  auth,
  translate: status.translate || ((_, fallback) => fallback),
  locales: common.locales,
  currentLocale: status.lang,
});

export default connect(mapStateToProps, { getLoginScreenByEmail, changeLocale })(Home);
