/* eslint eqeqeq: 0 */

import shortid from 'shortid';
import { convert } from 'activity-templates/utils';

export default async (s3FilesList) => {
  const result = {};

  const defaultOldOptions = {
    ProblemOrder: 1,
    ShowAnswer: 2,
    LengthOfTime: 3,
  };

  const { gameData, options, helpHtml, helpAudio } = await convert(
    s3FilesList,
    defaultOldOptions
  );

  const {
    Worksheet: { SegmentData },
  } = gameData;

  const problems = SegmentData.map((data) => {
    const { InstrText, FontColor, InstrFontName, InstrAudio, GroupData } = data;

    const questionsMax = GroupData[0].GroupMaxDisplay;

    const questionItemsShown =
      questionsMax && !isNaN(+questionsMax) ? +questionsMax : 2;

    const [questions, correctAnswers] = GroupData.map((group) => {
      return group.ItemData.map(({ ItemType, ItemData, ItemAudio }) => {
        return {
          text: ItemType == 1 ? ItemData : '',
          image: ItemType == 0 ? ItemData : '',
          audio: ItemAudio,
        };
      });
    });

    return {
      id: shortid.generate(),
      questions,
      correctAnswers,
      incorrectAnswers: [],
      instructionText: InstrText,
      instructionAudio: InstrAudio,
      questionItemsShown,
      fontFamily:
        InstrFontName && InstrFontName != '0' ? InstrFontName : 'Arial',
      fontColor: FontColor && FontColor != '0' ? FontColor : '#000',
    };
  });

  result.gameData = { problems };
  result.options = options;
  result.helpHtml = helpHtml;
  result.helpAudio = helpAudio;

  return result;
};
