import { axiosAPI, withLoading } from 'utils';
import { SET_FLINK_MAKE_COMMON_DATA } from 'actions/types';

const filter = 'flinkMake/common';

export const fetchFlinkMakeCommonData = () => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axiosAPI.get('api/flink-make/common');

      dispatch(setFlinkMakeCommonData(res.data));
    } catch (err) {
      console.log(err);
    }
  });

export const setFlinkMakeCommonData = data => {
  return {
    filter,
    type: SET_FLINK_MAKE_COMMON_DATA,
    payload: data
  };
};
