// import validateEmail from "./validate-email";

let usernames = [];

const checkUsername = (username) => {
  let error = '';

  if (usernames.includes(username)) {
    error = 'Usernames must be different!';
  } else {
    usernames.push(username);
  }

  // if (!username.match(/^[a-zA-Z0-9]+$/) && validateEmail(username)) {
  //   error = { message: "Incorrect username!"};
  // }

  if (username.trim() === '') {
    error = 'Username must be filled!';
  }

  return error;
};

export default (values) => {
  usernames = [];

  const errors = {};

  if (values['username']) {
    errors['username'] = checkUsername(values['username']);
  } else {
    errors['username'] = { stringNumber: 763, message: 'Username is required' };
  }

  if (values['username2']) {
    errors['username2'] = checkUsername(values['username2']);
  }

  if (values['username3']) {
    errors['username3'] = checkUsername(values['username3']);
  }

  if (values['username4']) {
    errors['username4'] = checkUsername(values['username4']);
  }

  return errors;
};
