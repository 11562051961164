import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { Portal } from 'components/common';
import { GameModal } from 'components/flink-play';
import { playApplause } from 'actions/audioActions';
import { updateLearner } from 'actions/flinkPlayActions';
import classes from './SetGoal.module.scss';
import './CalendarStyles.scss';

export class SetGoal extends Component {
  state = {
    selectedLevel: '',
    selectedSubject: '',
    selectedDate: '',
    approvedBy: '',
    modalOpened: false,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.learner.reports !== this.props.learner.reports) {
      this.checkIfGoalIsAccomplished();
    }
  }

  init = async () => {
    const {
      familyMembers,
      team = [],
      learner: { familyId },
      menuGroups,
    } = this.props;

    const isSingleMember = familyMembers.length === 1;

    const familyMembersInTeam = team.filter(
      (member) => member.familyId === familyId
    );

    const levels = _.chain(menuGroups)
      .map((g) => g.menuLevel)
      .uniq()
      .sort((a, b) => b - a)
      .reverse()
      .value();

    this.setState(
      {
        isSingleMember,
        familyMembersInTeam,
        levels,
        selectedLevel: levels[0],
      },
      () => {
        this.checkIfGoalIsAccomplished();
        this.onLevelChanged();
      }
    );
  };

  onLevelChanged = () => {
    const { menuGroups, reports, translate } = this.props;
    const { selectedLevel, selectedSubject: currentSubject } = this.state;

    if (!selectedLevel) {
      return;
    }

    const subjects = _.chain(menuGroups)
      .filter((g) => g.menuLevel === selectedLevel)
      .map((g) => {
        const subj = reports[g.firstCategory];

        // subj.name is translate string number
        return { label: translate(subj && subj.name), value: g.firstCategory };
      })
      .uniqBy('value')
      .sortBy('label')
      // .reverse()
      .value();

    let selectedSubject = subjects[0] ? subjects[0].value : '';

    if (currentSubject) {
      const hasInLevel = !!_.find(subjects, { value: currentSubject });
      hasInLevel && (selectedSubject = currentSubject);
    }

    this.setState({ subjects, selectedSubject });
  };

  getCurrentGoal = () => {
    const {
      learner: { learningGoals },
      solutionId,
    } = this.props;

    return _.find(learningGoals, { solution: solutionId });
  };

  checkIfGoalIsAccomplished = () => {
    const {
      learner: { reports },
      menuGroups,
      translate,
      playApplause,
    } = this.props;

    let currentGoal = this.getCurrentGoal();

    if (!currentGoal) return;

    // Check if expired
    const isExpired = Date.now() > new Date(currentGoal.expiredAt);

    if (isExpired) {
      return this.deleteGoal();
    }

    // Check if accomplished
    // 1. Get all menu groups of current level
    const groupsAtLevel = menuGroups
      .filter(
        (g) =>
          g.menuLevel === currentGoal.level &&
          g.firstCategory === currentGoal.subject
      )
      .map((g) => g.groupId);

    // 2. Check if all mastered
    const allMastered = groupsAtLevel.reduce(
      (isMastered, groupId) =>
        isMastered && reports && reports[groupId] && reports[groupId].mastered,
      true
    );

    if (!allMastered) return;

    // 3. Show Congratulations Dialog
    window.Modals.showInfo({
      message: translate(679, 'You have accompished your learning goal'),
      title: translate(225, 'Congratulations'),
      okHandler: this.deleteGoal,
    });

    playApplause();
  };

  openModal = () => {
    const { translate } = this.props;
    const { isSingleMember, familyMembersInTeam } = this.state;

    // If family member is single in family, then he/she needs to
    // login with someone else from his/her family
    if (!isSingleMember && !familyMembersInTeam.length) {
      return window.Modals.showError(
        translate(
          501,
          'You must log in with another family member to set your learning goal'
        )
      );
    }

    this.setState({ modalOpened: true });
  };

  closeModal = () => this.setState({ modalOpened: false });

  setGoal = async () => {
    const { solutionId, updateLearner, learner } = this.props;
    const {
      selectedLevel,
      selectedSubject,
      selectedDate,
      approvedBy,
    } = this.state;

    const goal = {
      solution: solutionId,
      level: selectedLevel,
      subject: selectedSubject,
      expiredAt: selectedDate,
      approvedBy: approvedBy ? approvedBy : undefined,
    };

    const result = await updateLearner(learner._id, {
      learningGoals: [...learner.learningGoals, goal],
    });

    if (result.success) {
      this.closeModal();
    }
  };

  deleteGoal = async () => {
    const { learner, solutionId, updateLearner } = this.props;

    const filteredGoals = learner.learningGoals.filter(
      (g) => g.solution !== solutionId
    );

    await updateLearner(learner._id, {
      learningGoals: filteredGoals,
    });
  };

  deleteGoalButtonHandler = async () => {
    const { translate } = this.props;

    const isConfirmed = await window.Modals.showConfirm(
      translate(680, 'You sure you want to delete your learning goal?')
    );

    if (isConfirmed) this.deleteGoal();
  };

  selectLevel = (selectedLevel) => {
    this.setState({ selectedLevel }, this.onLevelChanged);
  };

  selectSubject = (selectedSubject) => {
    this.setState({ selectedSubject });
  };

  selectDate = (selectedDate) => {
    // Set end of day
    selectedDate.setHours(23, 59, 59, 999);

    this.setState({ selectedDate });
  };

  selectApprovedBy = (approvedBy) => {
    this.setState({ approvedBy });
  };

  render() {
    const { translate } = this.props;
    const {
      modalOpened,
      selectedLevel,
      selectedSubject,
      selectedDate,
      subjects,
      approvedBy,
      isSingleMember,
      familyMembersInTeam,
      levels,
    } = this.state;

    let formattedDate;

    const currentGoal = this.getCurrentGoal();

    if (currentGoal) {
      const date = new Date(currentGoal.expiredAt);
      formattedDate = `${date.toLocaleString('en-US', {
        month: 'long',
      })} ${date.getDate()}, ${date.getFullYear()}`;
    }

    return (
      <>
        <div className={classes.buttonWrapper}>
          {currentGoal ? (
            <>
              <button
                className={classes.button}
                onClick={this.deleteGoalButtonHandler}
              >
                {translate(500, 'Delete Goal')}
              </button>
              <span className={classes.date}>{formattedDate}</span>
            </>
          ) : (
            <button className={classes.button} onClick={this.openModal}>
              {translate(467, 'Set Goal')}
            </button>
          )}
        </div>

        <Portal>
          {modalOpened && (
            <GameModal
              title={translate(448, 'Set Learning Goal')}
              buttons={[
                {
                  title: translate(52, 'Cancel'),
                  clickHandler: this.closeModal,
                },
                {
                  title: translate(467, 'Set Goal'),
                  clickHandler: this.setGoal,
                  disabled:
                    selectedLevel === '' ||
                    selectedSubject === '' ||
                    !selectedDate ||
                    (!isSingleMember && !approvedBy),
                },
              ]}
            >
              <div className={classes.modalContent}>
                <div className={classes.fieldsWrapper}>
                  <div>
                    <label className={classes.label}>
                      {translate(452, 'Select a Level')}
                    </label>

                    <select
                      className={classes.levelSelect}
                      onChange={(e) => this.selectLevel(e.target.value)}
                      value={selectedLevel}
                      size={12}
                    >
                      {levels &&
                        levels.map((l) => (
                          <option key={l} value={l}>
                            {l}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <label className={classes.label}>
                      {translate(732, 'Select a Subject')}
                    </label>

                    <select
                      className={classes.subjectSelect}
                      onChange={(e) => this.selectSubject(e.target.value)}
                      value={selectedSubject}
                      size={12}
                    >
                      {subjects &&
                        subjects.map((s) => (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className={classes.calendarBlock}>
                    <label className={classes.label}>
                      {translate(459, 'Select a Date')}
                    </label>

                    <DatePicker
                      inline
                      calendarClassName="set-goal-calendar"
                      minDate={new Date()}
                      onChange={this.selectDate}
                      selected={selectedDate}
                    />
                  </div>
                </div>

                {!isSingleMember && (
                  <div className={classes.approveWrapper}>
                    <label className={classes.label}>
                      {translate(449, 'Approve By Family Member')}
                    </label>

                    <div className={classes.radioGroup}>
                      {familyMembersInTeam.map((member) => (
                        <label key={member._id}>
                          <input
                            type="radio"
                            checked={member._id === approvedBy}
                            onChange={() => this.selectApprovedBy(member._id)}
                          />
                          <span>{`${member.firstname} ${member.lastname}`}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </GameModal>
          )}
        </Portal>
      </>
    );
  }
}

SetGoal.propTypes = {
  updateLearner: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  playApplause: PropTypes.func.isRequired,
  familyMembers: PropTypes.array,
  team: PropTypes.array,
  learner: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired,
  menuGroups: PropTypes.array.isRequired,
  solutionId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ flinkPlay }) => ({
  menuGroups: flinkPlay.solutionMenu.menuGroups,
  translate: flinkPlay.solutionTranslate,
  familyMembers: flinkPlay.familyMembers,
  team: flinkPlay.team,
  learner: flinkPlay.learner,
  reports: flinkPlay.solutionMenu.reports,
  solutionId: flinkPlay.solution._id,
});

export default connect(mapStateToProps, { updateLearner, playApplause })(
  SetGoal
);
