import React from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { withTranslate } from 'components/hocs';

const findOptionByValue = (value, options) => {
  return options.filter(option => option.value === value)[0] || {};
};

const renderSelect = ({
  input,
  options,
  label,
  width = '100%',
  marginBottom = '1.5rem',
  disabled = false,
  isClearable = false,
  isMulti = false,
  translate,
  styles,
  meta: { touched, error, dirty } = {},
  ...props
}) => {
  const errorMessage =
    typeof error === 'object'
      ? translate(error.stringNumber, error.message)
      : error;

  return (
    <StyledInputGroup width={width} marginBottom={marginBottom}>
      <label>
        <StyledLabel error={errorMessage} touched={touched} dirty={dirty}>
          {label}
        </StyledLabel>

        <Select
          value={isMulti ? input.value : findOptionByValue(input.value, options)}
          isDisabled={disabled}
          isClearable={isClearable}
          isMulti={isMulti}
          onChange={option => input.onChange(isMulti ? option : option && option.value)}
          onBlur={() => input.onBlur(input.value)}
          options={options}
          styles={styles}
          {...props}
        />
      </label>
      {touched && errorMessage && <StyledError>{errorMessage}</StyledError>}
    </StyledInputGroup>
  )
};

export default withTranslate(renderSelect); // in case translate fn was not passed to the component

const StyledInputGroup = styled.div`
  ${({ marginBottom }) => marginBottom && css`margin-bottom: ${marginBottom};`}
  ${({ width }) =>
    width &&
    css`
      width: ${width}!important;
    `};
`;

const StyledLabel = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 0;

  ${({ touched, error, dirty }) =>
    touched &&
    (error
      ? css`
          color: #df2a26;
        `
      : dirty &&
        css`
          color: green;
        `)};
`;

const StyledError = styled.p`
  font-size: 1.2rem;
  margin-top: 2px;
  margin-bottom: 0;
  color: #df2a26;
`;
