export const FACEBOOK = 'FACEBOOK';
export const GO_BACK = 'GO_BACK';
export const VOCABULARY_NOTEBOOK = 'VOCABULARY_NOTEBOOK';
export const REPORTS = 'REPORTS';
export const SETTINGS = 'SETTINGS';
export const SHARE = 'SHARE';
export const PARENT_ADVICE = 'PARENT_ADVICE';

export const LOGOUT = 'LOGOUT';

// Activity Buttons
export const ACTIVITY_IMAGE = 'ACTIVITY_IMAGE';
export const CORRECT_ANSWER = 'CORRECT_ANSWER';
export const SPEAK = 'SPEAK';
export const HELP = 'HELP';

export const CHECK_ANSWER = 'CHECK_ANSWER';
export const GO = 'GO';
export const STOP = 'STOP';
export const NEXT = 'NEXT';
export const PRINT = 'PRINT';
export const CLUE = 'CLUE';
export const EBOOK_BUTTON = 'EBOOK_BUTTON';

export const CREDITS = 'CREDITS';
export const QUICKSTART_VIDEOS = 'QUICKSTART_VIDEOS';
