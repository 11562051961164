import { connect } from 'react-redux';
import { compose } from 'redux';
import { useMemo } from 'react';

import { withTranslate } from 'components/hocs';
import { prepareButtons } from 'utils';

// components
import WhiteBox from 'components/flink-play/WhiteBox/WhiteBox';

// button types
import { 
    HELP as MULTIPLAYER_HELP, 
    PARENT_ADVICE as MULTIPLAYER_ADVICE
} from 'consts/buttons';

// css
import classes from './HelpControls.module.scss';

// actions
import { showMultiplayerHelp } from 'actions/flinkPlayActions';
import { showGameMultiplayerAdvice } from 'actions/gameActions';

const HelpControls = ({ 
    translate, 
    globalButtons, 
    showMultiplayerHelp,
    showGameMultiplayerAdvice,
    isGameMultiplayerAdviceOpened
}) => {
    const buttons = useMemo(() => prepareButtons(
        [
            { type: MULTIPLAYER_HELP, onClick: showMultiplayerHelp },
            { 
                type: MULTIPLAYER_ADVICE, 
                onClick: () => showGameMultiplayerAdvice(!isGameMultiplayerAdviceOpened),
            },
        ],
        globalButtons,
        translate,
    ).filter(button => button), 
    [
        globalButtons, translate, showMultiplayerHelp,
        showGameMultiplayerAdvice, isGameMultiplayerAdviceOpened,
    ]);

    return (
        <WhiteBox innerClass={classes.innerClass} outerClass={classes.outerClass}>
            {buttons.map((button, index) => <div key={index}>
                {button}
            </div>)}
        </WhiteBox>
    );
}

const mapStateToProps = ({ common, game }) => ({
    globalButtons: common.globalButtons,
    isGameMultiplayerAdviceOpened: game.gameMultiplayerAdvice,
});

export default compose(
    withTranslate,
    connect(mapStateToProps, {
        showMultiplayerHelp,
        showGameMultiplayerAdvice
    })
)(HelpControls);
