import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

import { WhiteBox } from 'components/flink-play';
import classes from './PuzzleBlock.module.scss';

const getBoundRectsOfPath = (path) => {
  const stringifiedPath = JSON.stringify(path);
  const pathEle = document.querySelector(`[datapath='${stringifiedPath}']`);

  return pathEle.getBoundingClientRect();
};

const PuzzleBlock = ({
  puzzle,
  onStartSelect,
  onMouseEnter,
  resolvedPaths = [],
  selectedPaths = [],
  resolvedWrappers,
  hintedPaths = [],
}) => {
  const styles = useMemo(() => {
    const ratio = 20 / puzzle.grid.length;
    return {
      width: (ratio * 2.4).toFixed(2) + 'vmin',
      height: (ratio * 2.4).toFixed(2) + 'vmin',
      fontSize: (ratio * 2.4).toFixed(2) + 'vmin',
      margin: (ratio * 0.5).toFixed(2) + 'vmin',
    };
  }, [puzzle]);

  const wrapperRef = useRef();

  const strokes = useMemo(() => {
    if (!resolvedWrappers || !wrapperRef.current) return [];

    const wrapperBounds = wrapperRef.current.getBoundingClientRect();

    return resolvedWrappers.map((paths) => {
      const startEleBounds = getBoundRectsOfPath(_.first(paths));
      const endEleBounds = getBoundRectsOfPath(_.last(paths));

      let top, left, width;

      const minSide = Math.min(window.innerHeight, window.innerWidth);

      let direction;

      if (startEleBounds.top === endEleBounds.top) {
        // horizontal
        direction = 'x';
        top = startEleBounds.top - wrapperBounds.top;
        left = startEleBounds.left - wrapperBounds.left;
        width = endEleBounds.right - startEleBounds.left;
      } else if (startEleBounds.left === endEleBounds.left) {
        // vertical
        direction = 'y';
        top = startEleBounds.top - wrapperBounds.top;
        left = startEleBounds.left - wrapperBounds.left + startEleBounds.width;
        width = endEleBounds.bottom - startEleBounds.top;
      } else {
        // diagonal
        direction = 'xy';
        top = startEleBounds.top - wrapperBounds.top - startEleBounds.width / 2;
        left = startEleBounds.left - wrapperBounds.left;

        width = Math.sqrt(
          Math.pow(endEleBounds.bottom - startEleBounds.top, 2) +
            Math.pow(endEleBounds.right - startEleBounds.left, 2)
        );
      }

      const getAdaptiveSize = (size) => (size / minSide) * 100 + 'vmin';

      const styles = {
        top: getAdaptiveSize(top),
        left: getAdaptiveSize(left),
        width: getAdaptiveSize(width),
        height: getAdaptiveSize(startEleBounds.height),
      };

      return (
        <div
          style={styles}
          className={classnames(classes.stroke, {
            [classes.horizontal]: direction === 'x',
            [classes.vertical]: direction === 'y',
            [classes.diagonal]: direction === 'xy',
          })}
          key={JSON.stringify(paths)}
        />
      );
    });
  }, [resolvedWrappers, wrapperRef]);

  const handleTouchMove = useCallback(
    (e) => {
      const touchInfo = e.changedTouches[0];

      const ele = document.elementFromPoint(
        touchInfo.clientX,
        touchInfo.clientY
      );

      const path = ele.getAttribute('datapath');

      if (!path) return;

      onMouseEnter(JSON.parse(path));
    },
    [onMouseEnter]
  );

  const handleTouchStart = useCallback(
    (e) => {
      const touchInfo = e.changedTouches[0];

      const ele = document.elementFromPoint(
        touchInfo.clientX,
        touchInfo.clientY
      );
      const path = ele.getAttribute('datapath');

      if (!path) return;

      e.preventDefault();
      onStartSelect(JSON.parse(path));
    },
    [onStartSelect]
  );

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart, {
      passive: false,
    });
    document.addEventListener('touchmove', handleTouchMove);
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleTouchStart, handleTouchMove]);

  return (
    <WhiteBox outerClass={classes.wrapperOuter}>
      <div ref={wrapperRef} className={classes.grid}>
        {puzzle.grid.map((row, rowIdx) => (
          <div className={classes.row} key={rowIdx}>
            {row.map((cell, cellIdx) => {
              const path = { x: cellIdx, y: rowIdx };

              const isSelected = _.findIndex(selectedPaths, path) !== -1;
              const isResolved = _.findIndex(resolvedPaths, path) !== -1;
              const isHinted = _.findIndex(hintedPaths, path) !== -1;

              return (
                <button
                  datapath={JSON.stringify(path)}
                  onMouseDown={(e) => onStartSelect(path)}
                  onMouseEnter={(e) => onMouseEnter(path)}
                  style={styles}
                  className={classnames(classes.letterButton, {
                    [classes.selected]: isSelected,
                    [classes.resolved]: isResolved,
                    [classes.hinted]: isHinted,
                  })}
                  key={cellIdx}
                >
                  {cell}
                </button>
              );
            })}
          </div>
        ))}

        {strokes}
      </div>
    </WhiteBox>
  );
};

PuzzleBlock.propTypes = {
  selectedPaths: PropTypes.array,
  resolvedPaths: PropTypes.array,
  puzzle: PropTypes.object.isRequired,
  onStartSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
};

export default PuzzleBlock;
