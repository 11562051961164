import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ColorPicker from './ColorPicker';

const ColorPickerField = ({ currentColor, label, changeHandler }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <StyledWrapper>
      {label && <label>{label}</label>}
      <StyledColorBtn
        color={currentColor || '#000'}
        onClick={() => setShowColorPicker(!showColorPicker)}
      />
      <StyledOverlay
        show={showColorPicker}
        onClick={e => setShowColorPicker(false)}
      />
      {showColorPicker && (
        <StyledColorWrapper>
          <ColorPicker
            currentValue={currentColor || '#000'}
            changeHandler={color => {
              changeHandler(color);
              setShowColorPicker(false);
            }}
            show={showColorPicker}
          />
        </StyledColorWrapper>
      )}
    </StyledWrapper>
  );
};

ColorPickerField.propTypes = {
  currentColor: PropTypes.string,
  label: PropTypes.string,
  changeHandler: PropTypes.func.isRequired
};

export default ColorPickerField;

const StyledWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
`;

const StyledColorBtn = styled.button`
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  border: 1px solid #e8e8e8;
  transition: transform 0.1s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const StyledColorWrapper = styled.div`
  position: absolute;
  z-index: 20;
  right: 0;
  top: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 14px;
  border-radius: 10px;
`;

const StyledOverlay = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
`;
