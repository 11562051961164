import _ from 'lodash';
import shortid from 'shortid';

import { addIdToFilename, isUsingStrings } from 'utils';
import { getWordPictureFilename } from 'utils/wordlistHelpers';
import { getInstructionsAudioFilesFromProblem } from 'activity-templates/utils';

export const validateItemHasFeedback = (item) => {
  return Boolean(item.feedbackAudio || item.feedbackText);
};

export const validateItem = (item) => {
  return Boolean(item && (item.text || item.image || item.audio));
};

export const getValidItemsWithoutFeedback = (problem) => {
  const { correctAnswers, incorrectAnswers } = problem || {};
  const itemsWithoutFeedback = [
    ...(correctAnswers || []),
    ...(incorrectAnswers || []),
  ].filter((item) => validateItem(item) && !validateItemHasFeedback(item));
  return itemsWithoutFeedback;
};

export const validateProblem = (problem) => {
  const {
    specificFeedback,
    questions,
    correctAnswers,
    incorrectAnswers,
  } = problem;
  const validQuestions = _.filter(questions || [], validateItem);
  const validCorrectAnswers = _.filter(correctAnswers || [], validateItem);
  const validIncorrectAnswers = _.filter(incorrectAnswers || [], validateItem);

  if (specificFeedback && getValidItemsWithoutFeedback(problem).length > 0) {
    return false;
  }

  return !!(
    validQuestions.length &&
    validCorrectAnswers.length &&
    validIncorrectAnswers.length
  );
};

export const prepareProblem = mapper => problem => ({
  ...problem,
  questions: problem.questions?.map(mapper),
  correctAnswers: problem.correctAnswers?.map(mapper),
  incorrectAnswers: problem.incorrectAnswers?.map(mapper),
});


export const createProblem = () => ({
  id: shortid.generate(),
  questions: [],
  correctAnswers: [],
  incorrectAnswers: [],
  fontColor: '#000',
  fontFamily: 'Arial',
  incorrectItemsShown: 2,
});

export const getContentFilesFromItem = (item) => {
  const files = [];
  if (!item) return files;

  const { text, image, audio, feedbackAudio } = item;
  !isUsingStrings(text) && image && files.push(image);
  !isUsingStrings(text) && audio && files.push(audio);
  feedbackAudio && files.push(feedbackAudio);

  return files;
};

export const getContentFilesFromProblem = (problem) => {
  let files = [];
  const { questions, correctAnswers, incorrectAnswers } = problem;

  const instructionsAudioFiles = getInstructionsAudioFilesFromProblem(problem);
  files.push(...instructionsAudioFiles);

  _.concat(questions, correctAnswers, incorrectAnswers).forEach((item) => {
    files = _.concat(files, getContentFilesFromItem(item));
  });
  console.log(files);
  return files;
};

export const validateWords = (words, templateDBrecord) => {
  const { audioRequired } = templateDBrecord;

  const filteredWords = words.filter((w) => w.hasPicture);

  if (filteredWords.length < 3) {
    return { success: false, error: 'Should be minimum 3 words with pictures' };
  }

  if (audioRequired) {
    const wordsWithMissingAudio = filteredWords.filter((w) => !w.wordAudio);

    if (wordsWithMissingAudio.length) {
      return {
        success: false,
        filteredWords,
        error: `Audio required for the template and following words don't have it - ${wordsWithMissingAudio
          .map((w) => w.word)
          .join(',')}`,
      };
    }
  }

  return { success: true, filteredWords };
};

export const generateActivityFromWords = ({ words, template }) => {
  const wordsImages = [];
  const wordsAudio = [];

  const { audioRequired } = template;

  const filteredWords = words.filter((w) => w.hasPicture);

  const problems = filteredWords.map((w) => {
    const incorrectWords = _.chain(words)
      .filter((word) => word._id !== w._id)
      .shuffle()
      .take(2)
      .value();

    const questionImageSrc = getWordPictureFilename(w);
    const questionImage = addIdToFilename(questionImageSrc);

    questionImage &&
      wordsImages.push({
        src: questionImageSrc,
        filename: questionImage,
      });

    const question = { image: questionImage };

    let correctAnswers;

    if (audioRequired) {
      const correctAnswerAudio = addIdToFilename(w.wordAudio);
      correctAnswers = [{ audio: correctAnswerAudio }];
      wordsAudio.push({ src: w.wordAudio, filename: correctAnswerAudio });
    } else {
      correctAnswers = [{ text: w.word }];
    }

    const incorrectAnswers = incorrectWords.map((w) => {
      if (!audioRequired) {
        return { text: w.word };
      }

      const filename = addIdToFilename(w.wordAudio);
      wordsAudio.push({ src: w.wordAudio, filename });

      return { audio: filename };
    });

    return {
      id: shortid.generate(),
      questions: [question],
      correctAnswers,
      incorrectAnswers,
      fontColor: '#000',
      fontFamily: 'Roboto',
      incorrectItemsShown: 2,
    };
  });

  const gameData = { problems };

  return { gameData, wordsImages, wordsAudio };
};
